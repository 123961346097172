import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SelectOptionsStoreActions } from '.';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { SelectOptionResponse } from '../../models/dto/responses/select-option-response.dto';
import {
	AdminSelectOptionsResponse,
	SelectOptionsResponse,
} from '../../models/dto/responses/select-options-response.dto';
import { SelectOptionsService } from '../../services/select-options/select-options.service';
import { CreateSelectOption, FetchAdminSelectOptions, FetchSelectOptions, UpdateSelectOption } from './actions';

@Injectable()
export class SelectOptionsStoreEffects {
	constructor(private actions$: Actions, private selectOptionsService: SelectOptionsService) {}

	fetchSelectOptionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SelectOptionsStoreActions.FetchSelectOptions>(SelectOptionsStoreActions.ActionTypes.FETCH_SELECT_OPTIONS),
			switchMap((_: FetchSelectOptions) =>
				this.selectOptionsService.fetchSelectOptions().pipe(
					map((result: SelectOptionsResponse) => new SelectOptionsStoreActions.FetchSelectOptionsSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SelectOptionsStoreActions.FetchSelectOptionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchAdminSelectOptionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SelectOptionsStoreActions.FetchAdminSelectOptions>(
				SelectOptionsStoreActions.ActionTypes.FETCH_ADMIN_SELECT_OPTIONS,
			),
			switchMap((_: FetchAdminSelectOptions) =>
				this.selectOptionsService.fetchAdminSelectOptions().pipe(
					map(
						(result: AdminSelectOptionsResponse[]) =>
							new SelectOptionsStoreActions.FetchAdminSelectOptionsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new SelectOptionsStoreActions.FetchAdminSelectOptionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateSelectOptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SelectOptionsStoreActions.UpdateSelectOption>(SelectOptionsStoreActions.ActionTypes.UPDATE_SELECT_OPTION),
			switchMap((action: UpdateSelectOption) =>
				this.selectOptionsService.updateSelectOption(action.payload.selectOption).pipe(
					map((result: SelectOptionResponse) => new SelectOptionsStoreActions.UpdateSelectOptionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SelectOptionsStoreActions.UpdateSelectOptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	createSelectOptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SelectOptionsStoreActions.CreateSelectOption>(SelectOptionsStoreActions.ActionTypes.CREATE_SELECT_OPTION),
			switchMap((action: CreateSelectOption) =>
				this.selectOptionsService.createSelectOptions(action.payload.selectOption).pipe(
					map((result: SelectOptionResponse) => new SelectOptionsStoreActions.CreateSelectOptionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SelectOptionsStoreActions.CreateSelectOptionError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
