import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { OfferStatus } from 'projects/flink-app/src/app/enums/offer-status.enum';
import { getSelectOptionIdByCode } from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { OfferStoreActions, OfferStoreSelectors } from 'projects/flink-app/src/app/root-store/offer-store';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-accept-offer-dialog',
	templateUrl: './accept-offer-dialog.component.html',
	styleUrls: ['./accept-offer-dialog.component.scss'],
})
export class AcceptOfferDialogComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();

	updateOfferStatusHttpPackage$ = this.store.select(OfferStoreSelectors.selectUpdateOfferStatusHttpPackage);

	constructor(
		public dialogRef: MatDialogRef<AcceptOfferDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.updateOfferStatusHttpPackage$.subscribe((updateOfferStatusHttpPackage) => {
				if (updateOfferStatusHttpPackage.result !== null) {
					this.notifierService.notify('success', 'Offer accepted successfully');
					this.dialogRef.close();
				}
			}),
		);
	}

	cancel(): void {
		this.dialogRef.close();
	}

	accept(): void {
		this.dialogRef.close();

		this.store.dispatch(
			new OfferStoreActions.UpdateOfferStatus({
				offerStatusRequest: {
					offerId: this.data.offerId,
					offerStatus: OfferStatus.Accepted,
				},
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
