import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FlinkerJobsReducer } from '@root-store/flinker/jobs/reducer';
import { EffectsModule } from '@ngrx/effects';
import { FlinkerJobsEffects } from '@root-store/flinker/jobs/effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinker-jobs', FlinkerJobsReducer),
		EffectsModule.forFeature([FlinkerJobsEffects]),
	],
	providers: [FlinkerJobsEffects],
})
export class FlinkerJobsModule {}
