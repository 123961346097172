import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { QualificationType } from '../../../enums/qualification-type.enum';
import Qualification from '../../../models/dto/qualification.dto';
import FlinkerResponse from '../../../models/dto/responses/flinker-response.dto';
import { getSelectOptionName, SelectOptionsResponse } from '../../../models/dto/responses/select-options-response.dto';
import { RootStoreState } from '../../../root-store';
import { FlinkerTakeOnStoreSelectors } from '../../../root-store/flinker-take-on-store';
import { SelectOptionsStoreSelectors } from '../../../root-store/select-options-store';

@Component({
	selector: 'app-qualifications-profile',
	templateUrl: './qualifications-profile.component.html',
	styleUrls: ['./qualifications-profile.component.scss'],
})
export class QualificationsProfileComponent implements OnInit {
	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	getSelectOptionName = getSelectOptionName;
	QualificationType = QualificationType;

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				if (selectOptionsResponse.result !== null) {
					this.selectOptions = selectOptionsResponse.result;
				}
			}),
		);
	}

	getInstitutionName(qualification: Qualification): string {
		return this.selectOptions?.institutions.find((institution) => institution.id === qualification.institutionId)
			?.code === 'OTH' || qualification.type === QualificationType.Other
			? qualification.otherInstitution
			: this.selectOptions?.institutions.find((institution) => institution.id === qualification.institutionId) !==
			  undefined
			? this.selectOptions?.institutions.find((institution) => institution.id === qualification.institutionId)?.name
			: this.selectOptions?.schools.find((school) => school.code === qualification.institution)?.name;
	}

	getCompletionYear(qualification: Qualification): string {
		return qualification.completionYear
			? `In progress - expected to complete in ${qualification.completionYear}`
			: qualification.yearObtained;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
