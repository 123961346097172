<div class="container" [ngClass] = "{ 'container-shadow' : showAdditionalFields }">
  <div id="modalHeaderImage" class='ml-n3 mr-n3'></div>
  <br>
  <br>
  <br>
  <br>
  <app-page-title title="Make someone's dream come true"></app-page-title>

  <section>
    <div class="row">
      <p class="col-12 text-center front-sub-5">
        Invite a flinker!
      </p>
    </div>

    <form class="row my-4" [formGroup]="form">
      <div class = "col-12 col-md-6 inputs">
        <app-outlined-input
          class="col-12 col-md-3 jobTitles-input"
          labelText="Name"
          formControlName="name"
          [errorMessage]="getErrorMessage(form.controls.name, 'Name', shouldValidate)"
        >
        </app-outlined-input>

        <app-outlined-input
          class="col-6 col-md-3 jobTitles-input"
          labelText="Surname"
          formControlName="surname"
          [errorMessage]="getErrorMessage(form.controls.surname, 'Surname', shouldValidate)"
        >
        </app-outlined-input>
      </div>

      <div class = "col-12 col-md-6 inputs">
        <app-outlined-input
          class="col-12 col-md-3 jobTitles-input"
          labelText="Email Address"
          formControlName="emailAddress"
          [errorMessage]="getErrorMessage(form.controls.emailAddress, 'Email Address', shouldValidate)"
        >
        </app-outlined-input>

        <app-outlined-input
          class="col-12 col-md-3 jobTitles-input"
          labelText="Contact Number"
          formControlName="contactNumber"
          [errorMessage]="getErrorMessage(form.controls.contactNumber, 'Contact Number', shouldValidate)"
        >
        </app-outlined-input>
      </div>

      <div class = "col-12 col-md-6 inputs">
        <app-outlined-input
          *ngIf='showAdditionalFields'
          class="col-12 col-md-3 jobTitles-input"
          labelText="Your Name"
          formControlName="refererName"
          [errorMessage]="getErrorMessage(form.controls.refererName, 'Your Name', shouldValidate)"
        >
        </app-outlined-input>

        <app-outlined-input
          *ngIf='showAdditionalFields'
          class="col-12 col-md-3 jobTitles-input"
          labelText="Your Surname"
          formControlName="refererSurname"
          [errorMessage]="getErrorMessage(form.controls.refererSurname, 'Your Surname', shouldValidate)"
        >
        </app-outlined-input>
      </div>

      <div class = "col-12 col-md-6 inputs">
        <app-outlined-input
          *ngIf='showAdditionalFields'
          class="col-12 col-md-3 jobTitles-input"
          labelText="Your Email Address"
          formControlName="refererEmail"
          [errorMessage]="getErrorMessage(form.controls.refererEmail, 'Your Email Address', shouldValidate)"
        >
        </app-outlined-input>
      </div>

      <div class="col-12 col-sm-6 offset-sm-3 mt-5">
        <app-square-button
          matStepperNext
          theme="m1"
          value="Share Job Advert "
          type="button"
          class="d-flex justify-content-center w-auto"
          (click)="submit()"
        >
        </app-square-button>
      </div>
      <div class="col-12 col-sm-6 offset-sm-3 mt-5">
        <app-square-button
          matStepperNext
          theme="m8"
          value="Close"
          type="button"
          class="d-flex justify-content-center w-auto"
          (click)="closeDialog()"
        >
        </app-square-button>
      </div>
    </form>
  </section>
</div>
