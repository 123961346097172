<div class="wrapper-container">
	<div class="take-on-container">
		<app-popup-notification class="front-sub-1">
			[Disclaimer] - By using this Website and registering for our Services, you acknowledge and accept these Terms of
			Use and agree to be bound by them. If you do not accept these Terms of Use, you must exit the Website immediately,
			thereby indicating your non-acceptance of such Terms.
		</app-popup-notification>
		<div class="container py-5">
			<app-page-title [title]="'Flink SA (Pty) Ltd Terms of Use'"></app-page-title>
			<mat-card>
				<div class="front-sub-3 font-size-11">
					<app-page-header-toolbar heading="1. 1.	INTRODUCTION"> </app-page-header-toolbar>

					1.1. The Website provides access to the information and software necessary for Flink SA (Pty) Ltd and its
					affiliates (hereinafter referred to as “Flink”, “we”, “our” or “us”) to provide streamlined recruitment and
					related Services to Users.

					<br /><br />

					1.2. The access, use and registration on Flink’s Website are governed by the following terms and conditions
					(hereinafter referred to as the “Terms of Use”), which must be read together with our Privacy Policy and,
					where applicable, our Disclosure Consent Terms (hereinafter collectively referred to as the “Terms”). The
					Privacy Policy and Disclosure Consent Terms must be read as if specifically incorporated herein.

					<br /><br />

					1.3. These Terms apply to any person who enters, browses, views or uses our Website (hereinafter referred to
					as “User” or “Browser”, as the case may be, and “you” or “your”).

					<br /><br />

					<app-page-header-toolbar heading="2.	INTERPRETATION AND DEFINITIONS"> </app-page-header-toolbar>

					2.1. “Application” means the Flink software application, accessed through the Website, to which the User has
					registered and the Company has subscribed, and through which we provide the Services to the User.

					<br /><br />

					2.2. “Browser” means a person who visits our Website, through any means, for the purposes of viewing and
					browsing, but who has not yet used our Services or our Application, and may or may not have the intention to
					do so in future.

					<br /><br />

					2.3. “Company” means an entity, organisation or person who has registered with Flink for the purpose of using
					the Services to fulfil its recruitment-related needs through the Web-based Application.

					<br /><br />

					2.4. “Flink” means Flink SA (Pty) Ltd and its lawful successors, assigns and affiliates, as the case may be.

					<br /><br />

					2.5. “Flinker” means the person who uses the Services to secure possible recruitment positions with Companies.

					<br /><br />

					2.6. “Party” or “Parties” means Flink, the User or a Browser, as the context may indicate.

					<br /><br />

					2.7. “Personal Information” shall have the meaning ascribed to it in Chapter 1 of Protection of Personal
					Information Act 4 of 2013 and Article 4(1) of the General Data Protection Regulation 2016/679 and includes,
					but is not limited to a name, address, email address, telephone or fax number, fingerprints, criminal history
					and education or other personal credentials provided, or which is collected from the Browser or the User or
					other third parties.

					<br /><br />

					2.8. “Services” means the streamlined recruitment and related services provided by Flink to the User through
					the Website and, more specifically, the Application.

					<br /><br />

					2.9. “Terms” means these Terms of Use read in conjunction with our Privacy Policy and, where applicable, the
					Disclosure Consent Terms.

					<br /><br />

					2.10. “User” means the Company or the Flinker or both, as the context may indicate.

					<br /><br />

					2.11. “Website” means the website owned by Flink through which the Services are offered, comprising of the
					Website and the Application.

					<br /><br />

					<app-page-header-toolbar heading="3.	ACCEPTANCE OF THESE TERMS AND CONDITIONS"> </app-page-header-toolbar>

					3.1. The Terms constitute a binding agreement between Flink and the User or Browser, as the case may be, in
					terms of section 11(3) of the Electronic Communications and Transactions Act 25 of 2002. Such agreement shall
					be construed to have been concluded in Johannesburg, South Africa.

					<br /><br />

					3.2. By viewing, accessing and using our Website, you acknowledge that you have read through, understand, and
					agree to be bound by the Terms.

					<br /><br />

					3.3. The current version of the Terms applicable at the time you visit, access or use the Website becomes
					binding each time you visit, access or use the Website. You must familiarise yourself with the current Terms
					each time you visit, access or use the Website.

					<br /><br />

					3.4. If you do not agree with any terms and conditions set out in the Terms, you must exit the Website
					immediately, thereby indicating your non-acceptance of such Terms.

					<br /><br />

					3.5. Should you proceed to visit, view, access or use the Website, you acknowledge and agree that such act
					will be construed as expressed acceptance of all terms and conditions set out in the Terms, whether expressed
					or implied in such Terms.

					<br /><br />

					<app-page-header-toolbar heading="4.	REGISTRATION PROCESS"> </app-page-header-toolbar>

					4.1. In order to access and make use of our Services, you must proceed to register as either a Company or a
					Flinker, as the case may be.
					<br /><br />
					4.2. While registering:
					<br /><br />
					4.2.1. You will be requested to provide login details, which you will have the sole responsibility to protect
					and keep confidential. We will not have access to your login details and shall under no circumstances be held
					liable for any loss or damage arising from the disclosure, whether accidental, unlawful or otherwise, of your
					login details.
					<br /><br />
					4.2.2. You will be requested to submit Personal Information. We will be entitled to process your Personal
					Information as further set out in and regulated by our Privacy Policy and, where applicable, the Disclosure
					Consent Terms.
					<br /><br />
					4.3. Once registered, any Browser will no longer be construed as such and will immediately on registration
					constitute a User, and the Services to the User shall commence.
					<br /><br />
					4.4. The further terms governing the registration of the Company are included in the Terms and Conditions and
					the Service Level Agreement entered into between Flink and the Company.

					<br /><br />

					<app-page-header-toolbar heading="5.	CONSENT DISCLOSURE REQUIREMENTS BY FLINKERS"> </app-page-header-toolbar>

					5.1. In order to provide our Services to you as the Flinker, we may be required to conduct screening checks
					and psychometric assessments on you (“the Assessments”).
					<br /><br />
					5.2. You as the Flinker specifically consent that such Assessments may be conducted by third party service
					providers at any time during the use of our Services following registration. The further terms and conditions
					relating to such consent is set out in the Disclosure Consent Terms, which are specifically incorporated
					herein.
					<br /><br />
					5.3. You undertake to cooperate with us and the Assessors to ensure that such Assessments are completed.
					<br /><br />
					5.4. Should you not wish to proceed with the Assessments, you agree that we may terminate your use of our
					Services with immediate effect.

					<br /><br />

					<app-page-header-toolbar heading="6.	UNDERTAKINGS BY THE USER"> </app-page-header-toolbar>

					6.1. You acknowledge and agree that we cannot provide any Services to you unless you provide all the
					information necessary and in such a format as we may require from time to time. Accordingly, you undertake to
					provide all necessary and accurate information when requested.
					<br /><br />
					6.2. You agree to comply with all applicable laws when using the Services and you may only use the Services
					for lawful purposes. You will not in your use of the Services cause nuisance, annoyance, inconvenience, or
					property damage to Flink, a User or a third party.
					<br /><br />
					6.3. You undertake to cooperate with any reasonable and lawful request by Flink throughout the recruitment
					process.
					<br /><br />
					6.4. You undertake to communicate promptly with Flink and/or any other User, where applicable.
					<br /><br />
					7.5 Should the Flinker’s engagement or employment opportunity arise in a foreign jurisdiction, you as the
					Flinker undertake to ensure that you are entitled to work in that country and that you satisfy or will attend
					to the necessary work permit or immigration requirements.

					<br /><br />

					<app-page-header-toolbar heading="7.	WARRANTIES BY THE USER"> </app-page-header-toolbar>

					You as a User represent and warrant that:
					<br /><br />
					7.1. The information provided to us, including Personal Information, is true and accurate. Should the Personal
					Information change for any reason, you undertake to amend such information, including Personal Information,
					submitted to us as soon as possible to reflect the most updated information and Personal Information.
					<br /><br />
					7.2. The information provided to us does not contain any obscene, defamatory, illegal, discriminatory or
					confidential content, or any content which infringes on the intellectual property or confidentiality rights of
					any individual or organisation in any way.
					<br /><br />
					7.3. You are not falsely impersonating any other person and you are not violating any applicable law regarding
					the use of Personal Information or other relevant information.
					<br /><br />
					7.4. Your login details are for personal and official use only and shall not be disclosed to a third party.

					<br /><br />

					<app-page-header-toolbar heading="8.	WARRANTIES BY FLINK"> </app-page-header-toolbar>

					8.1. Flink does not warrant, whether expressed or implied, that:
					<br /><br />
					8.1.1. The Website or Services shall be without defect or free from any virus or other malware;
					<br /><br />
					8.1.2. The Website or Services shall meet the needs and requirements of the User;
					<br /><br />
					8.1.3. The Website or Services shall be accessible at all times.
					<br /><br />
					8.2. Except for any express warranties in the Terms, the Services are provided “as is”. Flink makes no other
					warranties, express or implied, of whatsoever nature, including but not limited to warranties of title,
					fitness for a particular purpose or merchantability. We do not provide any warranties against viruses, malware
					or spyware that may be installed on your electronic device as a result of you accessing or using the Website
					or the Services.
					<br /><br />
					8.3. We do not warrant that the use of the Website or the Services will be uninterrupted or free from any
					error.
					<br /><br />
					8.4. We do not warrant that we will review any information, including Personal Information, provided to us for
					purposes of establishing the accuracy of such information. We further do not warrant that the processing of
					such information, including Personal Information and data, will not be without loss.
					<br /><br />
					8.5. We shall not be liable for delays, interruptions, service failures or other issues experienced in and
					during the use of internet and electronic communications or other systems outside of our reasonable control.
					While you as a User may have statutory rights, the duration of any such statutorily warranties, will be
					limited to the shortest period to the extent permitted by required law.
					<br /><br />
					8.6. We do not warrant that by submitting your information, details, and Personal Information as a Flinker
					does not guarantee that you will secure suitable (or any) employment.

					<br /><br />

					<app-page-header-toolbar heading="9.	UNAUTHORISED USE OF THE WEBSITE AND SERVICES"> </app-page-header-toolbar>

					9.1. The Browser or User may not visit, access or use the Website or Services for any unlawful, unauthorised,
					fraudulent or objectionable purpose.
					<br /><br />
					9.2. You shall take the necessary steps to ensure that your login details are secure and remain secure.
					<br /><br />
					9.3. You shall not permit the access or use, whether authorised or otherwise, of our Services, including your
					login details, to a third party unless such third party is duly authorised to represent you.
					<br /><br />
					9.4. You shall not upload or introduce any virus, spyware, malware or programme to the Website which may cause
					harm, loss or damage to our software or hardware systems.
					<br /><br />
					9.5. You shall not use, replicate or redistribute any information contained on the Website or our Services for
					commercial purposes without our prior written consent.

					<br /><br />

					<app-page-header-toolbar heading="10.	LIMITATION OF LIABILITY"> </app-page-header-toolbar>

					10.1. You hereby confirm that your visiting, viewing, accessing and usage of the Website and our Services is
					voluntary and entirely at your own risk.
					<br /><br />
					10.2. You hereby agree that we are in no way liable or responsible for any party’s conduct when viewing,
					accessing and usage of our Website or Services.
					<br /><br />
					10.3. You hereby acknowledge, understand and agree that we do not warrant that the viewing, accessing or use
					of our Website will be uninterrupted or free from any error, nor do we warrant that our Website or Services
					will be free from any virus, malware or spyware. Accordingly, you understand and agree that we will not be
					held liable for any loss arising from the viewing, accessing and use of our Website or Services. You as a
					Browser or User are responsible for implementing appropriate procedures and systems to protect yourself from
					such issues.
					<br /><br />
					10.4. Notwithstanding anything to the contrary which may be contained in the Terms, we shall not be liable for
					any direct or indirect (including consequential, punitive and special) loss, damage, loss of income, goodwill,
					costs and / or expenses of any nature whatsoever, whether in delict, contract or otherwise, which may arise
					from or in any way connected to the Website or the Services, including but not limited to the access and use
					thereof and any interruption to the Services which may occur.
					<br /><br />
					10.5. You hereby indemnify us and hold us harmless in respect of any loss, damage, liability, costs and / or
					expenses of whatsoever nature which we may suffer or incur as a consequence of or arising from or attributable
					to any acts or omissions on your part, including without limitation any loss, damage, costs and / or expenses
					of any nature whatsoever suffered or incurred by us as a result of any claims brought against Flink, any other
					User or Browser, or yourself by any third party. Your liability under this indemnification shall be in
					addition to your liability to Flink on account of any breach of contract or in delict.
					<br /><br />
					10.6. All implied representations and warranties which may apply to the Browser or User, in relation to the
					viewing, accessing and usage of the Website or Services, are herewith expressly excluded to the maximum extent
					permitted by the law.
					<br /><br />
					10.7. To the extent that our liability cannot be excluded by law, our maximum liability, whether in contract
					or delict or otherwise, to a User or Browser will be limited to the minimum amount imposed by such law.

					<br /><br />

					<app-page-header-toolbar heading="11.	INTELLECTUAL PROPERTY AND COPYRIGHT"> </app-page-header-toolbar>

					11.1. The Website, including the Application, the domain name and the content of the Website is the exclusive
					property of Flink.
					<br /><br />
					11.2. Flink shall at all times retain the right, title and interest in the Website, the Application, the
					Services, all copyright, patents, trade secrets, trademarks and other intellectual property rights, trade
					names, logos, slogans, custom graphics, button icons, scripts, videos, text, images, software, code, files,
					content, agreements, policies, information and other material available on the Website (“intellectual
					property”).
					<br /><br />
					11.3. You acknowledge and agree that the intellectual property is protected by South African and international
					property and copyright laws.
					<br /><br />
					11.4. You shall under no circumstances copy, reproduce, replicate, redistribute, download or otherwise
					transfer the information and intellectual property from the Website or our Services, in any manner or form,
					including but not limited to electronic or mechanical means, without our prior written consent.
					<br /><br />
					11.5. You understand and unequivocally agree that your viewing, access and use of the Website, the Application
					and our Services will be limited to the purpose for which it is intended and that you will not use our
					intellectual property for any purpose other than for the use of our Services which are expressly and
					unequivocally authorised by us.
					<br /><br />

					<app-page-header-toolbar heading="12.	GENERAL"> </app-page-header-toolbar>

					12.1. The Browser and User and Flink choose as their respective domicilium citandi et executandi for the
					purpose of legal proceedings and for the purpose of giving or sending any notice provided for or necessary for
					the Terms:
					<br /><br />
					12.1.1. Flink: The Club Retail Center, Cnr Pinaster & 18th Avenue, Hazelwood, Pretoria, 0081
					<br /><br />
					12.1.2. User:
					<br /><br />
					The address and email address provided to us on registration.
					<br /><br />
					12.2. All notices shall be in writing and shall be deemed to have been duly given (i) when delivered by hand,
					courier or other messenger (including registered mail), during normal business hours of the recipient party;
					or (ii) when sent by e-mail, at the time such email enters the recipient party’s mailbox.
					<br /><br />
					12.3. Nothing in the Terms shall constitute or be deemed to constitute a partnership or agency between the
					Browser or User and Flink.
					<br /><br />
					12.4. We may assign, delegate, novate or otherwise transfer any of our rights or obligations under the Terms
					without notice to the Browser or User and without the Browser or User’s consent. The Browser or User may not
					assign, delegate, novate or otherwise transfer any of her or her or its rights under the Terms.
					<br /><br />
					12.5. If at any time any provision of the Terms is or becomes invalid, illegal or unenforceable in any
					respect, that provision shall be deemed severed from the Terms, but the validity, legality and enforceability
					of the remaining provisions of the Terms shall not be affected or impaired by the severance.
					<br /><br />
					12.6. Any waiver by us of a breach of any provision of the Terms will not be deemed a waiver of any subsequent
					breach of the same or another provision.
					<br /><br />
					12.7. The Terms shall be governed by and be construed in accordance with South African law. The Browser and
					User consents and submits to the jurisdiction of the Gauteng Local Division of the High Court of the Republic
					of South Africa, Johannesburg, in any dispute arising from or in connection with the Terms.
				</div>
			</mat-card>
		</div>
	</div>
</div>
