import { createFeatureSelector, createSelector } from '@ngrx/store';
import FlinkerAdminResponse from '@flinkadmin-dashboard/models/response/flinker-admin-response.dto';
import ReactivationRequestResponse from '@flinkadmin-dashboard/models/response/reactivation-requests-response.dto';
import FlinkerReferenceScore from '@models/dto/responses/flinker-reference-score.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import HttpPackage from '@models/http-package';
import { FlinkerAdminState } from './state';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';

const getFetchAllFlinkersHttpPackage = (
	state: FlinkerAdminState,
): HttpPackage<PaginatedResponse<FlinkerAdminResponse>> => state.fetchAllFlinkersHttpPackage;

const getUpdateReactivationRequestHttpPackage = (state: FlinkerAdminState): HttpPackage<string> =>
	state.updateReactivationRequestStatusHttpPackage;

const fetchAllReactivationRequestsHttpPackage = (
	state: FlinkerAdminState,
): HttpPackage<PaginatedResponse<ReactivationRequestResponse>> => state.fetchAllReactivationRequestsHttpPackage;

const getUpdateFlinkersHttpPackage = (state: FlinkerAdminState): HttpPackage<string> => state.updateFlinkerHttpPackage;

const getDetailedFlinkerPackage = (state: FlinkerAdminState): HttpPackage<DetailedFlinkerAdminResponse> =>
	state.getDetailedFlinkerPackage;
const fetchFlinkerReferenceScore = (state: FlinkerAdminState): HttpPackage<FlinkerReferenceScore> =>
	state.fetchFlinkerReferenceScorePackage;

const getLoginAsFlinkerPackage = (state: FlinkerAdminState): HttpPackage<SignInResponse> => state.loginAsFlinkerPackage;

const fetchFlinkerDocument = (state: FlinkerAdminState): HttpPackage<any> => state.fetchFlinkerDocumentsPackage;

const deleteFlinker = (state: FlinkerAdminState): HttpPackage<any> => state.deleteFlinkerPackage;

const downloadFlinkerCv = (state: FlinkerAdminState): HttpPackage<any> => state.downloadFlinkerCv;

const downloadAdminReport = (state: FlinkerAdminState): HttpPackage<any> => state.downloadAdminReport;

const downloadObfuscatedFlinkerCv = (state: FlinkerAdminState): HttpPackage<any> => state.downloadObfuscatedFlinkerCv;

const downloadFlinkerCvLoadingFlinkers = (state: FlinkerAdminState): { [flinkerId: string]: boolean } =>
	state.downloadFlinkerCvLoadingFlinkers;

const downloadObfuscatedFlinkerCvLoadingFlinkers = (state: FlinkerAdminState): { [flinkerId: string]: boolean } =>
	state.downloadObfuscatedFlinkerCvLoadingFlinkers;

export const selectFlinkerAdminState = createFeatureSelector<FlinkerAdminState>('flinker-admin');

export const selectFetchAllFlinkersHttpPackage = createSelector(
	selectFlinkerAdminState,
	getFetchAllFlinkersHttpPackage,
);

export const selectFetchAllReactivationRequestsHttpPackage = createSelector(
	selectFlinkerAdminState,
	fetchAllReactivationRequestsHttpPackage,
);

export const selectUpdateReactivationRequestHttpPackage = createSelector(
	selectFlinkerAdminState,
	getUpdateReactivationRequestHttpPackage,
);

export const selectUpdateFlinkerHttpPackage = createSelector(selectFlinkerAdminState, getUpdateFlinkersHttpPackage);

export const selectGetDetailedFlinker = createSelector(selectFlinkerAdminState, getDetailedFlinkerPackage);

export const selectFetchFlinkerReferenceScore = createSelector(selectFlinkerAdminState, fetchFlinkerReferenceScore);

export const selectLoginAsFlinkerPackage = createSelector(selectFlinkerAdminState, getLoginAsFlinkerPackage);

export const selectFetchFlinkerDocument = createSelector(selectFlinkerAdminState, fetchFlinkerDocument);

export const selectDeleteFlinker = createSelector(selectFlinkerAdminState, deleteFlinker);

export const selectDownloadFlinkerCv = createSelector(selectFlinkerAdminState, downloadFlinkerCv);

export const selectDownloadAdminReport = createSelector(selectFlinkerAdminState, downloadAdminReport);

export const selectDownloadObfuscatedFlinkerCv = createSelector(selectFlinkerAdminState, downloadObfuscatedFlinkerCv);

export const selectDownloadFlinkerCvLoadingFlinkers = createSelector(
	selectFlinkerAdminState,
	downloadFlinkerCvLoadingFlinkers,
);

export const selectDownloadObfuscatedFlinkerCvLoadingFlinkers = createSelector(
	selectFlinkerAdminState,
	downloadObfuscatedFlinkerCvLoadingFlinkers,
);
