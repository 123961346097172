import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
} from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	ControlContainer,
	AbstractControl,
	FormControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { GenericOption, getGenericOptionName } from '@models/generic-option.model';

export const _filter = (opt: string[], value: string): string[] => {
	const filterValue = value.toLowerCase();

	return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
	selector: 'app-outlined-autocomplete-generic',
	templateUrl: './outlined-autocomplete-generic.component.html',
	styleUrls: ['./outlined-autocomplete-generic.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedAutocompleteGenericComponent),
			multi: true,
		},
	],
})
export class OutlinedAutocompleteGenericComponent implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() labelText = '';
	@Input() errorMessage = '';
	@Input() formControlName = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';

	private optionsChanged = new BehaviorSubject<void>(null);
	private _options: GenericOption[] = [];
	@Input() set options(options: GenericOption[]) {
		this._options = options;
		this.writeValue(this.value);
		this.optionsChanged.next();
	}
	get options(): GenericOption[] {
		return this._options;
	}

	@Input() hint = '';
	@Input() toolTip = '';
	@Input() showTip = false;
	@Input() allowOther = false;
	@Input() otherOptionQuestion = 'Cannot find the correct option?';

	value: string;
	required = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;
	myControl = new FormControl();
	filteredOptions: Observable<GenericOption[]>;
	@Output() selectionChange = new EventEmitter();
	@Output() onOtherClicked = new EventEmitter<void>();

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.filteredOptions = combineLatest([
			this.myControl.valueChanges.pipe(startWith(''), takeUntil(this.destroy$)),
			this.optionsChanged.pipe(takeUntil(this.destroy$)),
		]).pipe(
			map(([value, _]) => this._filter(value)),
			takeUntil(this.destroy$),
		);

		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;
		if (validator && validator.required) {
			this.required = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const options: SimpleChange = changes.options;
		if (options?.currentValue !== null && options?.previousValue === null) {
			this.myControl.setValue(getGenericOptionName(this.options, this.value));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private _filter(value: string): GenericOption[] {
		const filterValue = value?.toLowerCase();
		return this.options?.filter((option) => option.displayName?.toLowerCase().includes(filterValue));
	}

	public writeValue(value: string): void {
		this.value = value;
		this.myControl.setValue(getGenericOptionName(this.options, value));
	}

	input(event: MatAutocompleteSelectedEvent): void {
		this.value = this.options?.find((option) => option.displayName === event.option.value)?.value;
		this.selectionChange.emit(this.value);
		this.onChange(this.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.myControl.disable() : this.myControl.enable();
		this.disabled = isDisabled;
	}

	clearOnEmptyString() {
		this.onChange('');
	}

	clearValue() {
		this.value = '';
		this.onChange('');
		this.myControl.setValue('');
	}

	onBlur(): void {
		this.onTouched();
	}

	onOther(): void {
		this.onOtherClicked.emit();
	}
}
