import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { AuthService } from './services/auth/auth.service';
import { GoogleTagManagerTrackerService } from './services/google-tag-manager/google-tag-manager-tracker.service';
import { NGXLogger } from 'ngx-logger';
import { safeStorage } from '@app/utils/safe-storage';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'flink-app';
	showSessionExpiry = false;

	// NOTE: We need to inject services into here if they are not injected anywhere
	// else in order to trigger their constructors.
	constructor(
		private authService: AuthService,
		private googleTagManagerTrackerService: GoogleTagManagerTrackerService,
		private readonly logger: NGXLogger,
	) {}

	ngOnInit(): void {
		// TODO: Add this back again when session expiry is reworked
		// this.checkSessionExpiry();
		// setInterval(() => {
		// 	this.checkSessionExpiry();
		// }, 1000);
		if (window.location.search) {
			this.setReturnUrl(window.location.search);
		}
	}

	setReturnUrl(queryParams: string) {
		const urlParams = new URLSearchParams(queryParams);
		this.logger.debug('Checking for return url.', queryParams);
		this.authService.setReturnUrl(urlParams.get('ReturnUrl'));
		this.logger.debug('Return url in storage.', this.authService.getReturnUrl());
	}

	showCookiePreferences(): boolean {
		return safeStorage.getItem('doNotShowCookiePreferences') === 'true';
	}

	checkSessionExpiry(): void {
		if (safeStorage.getItem('refresh_expires_at') && this.authService.getExpiration('refresh_expires_at') < moment()) {
			this.showSessionExpiry = false;
			this.authService.logout(false).subscribe();
		}
		if (
			safeStorage.getItem('refresh_expires_at') &&
			this.authService.getExpiration('refresh_expires_at') < moment().add(1, 'minute')
		) {
			this.showSessionExpiry = true;
		} else {
			this.showSessionExpiry = false;
		}
	}
}
