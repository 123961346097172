import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlinkCoJobAdvertsActions } from '.';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { JobAdvertService } from '@app/services/job-adverts/job-advert.service';
import {
	CreateAuthedJobAdvertReferral,
	CreateFlinkCoJobAdvert,
	CreateJobAdvertReferral,
	FetchCareersPageJobAdverts,
	FetchFlinkCoIframeLink,
	FetchFlinkCoJobAdvert,
	FetchFlinkCoJobAdverts,
	FetchFlinkCoJobApplications,
	FetchJobApplicationQuestionAnswers,
	FetchPublicFlinkCoJobAdvert,
	UpdateFlinkCoJobAdvert,
} from './actions';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { JobApplicationService } from '@app/services/job-application/job-application.service';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { JobApplicationQuestionAnswerResponse } from '@models/dto/responses/job-application-question-answer-response.dto';

@Injectable()
export class FlinkCoJobAdvertsStoreEffects {
	constructor(
		private actions$: Actions,
		private flinkcoJobAdvertsService: JobAdvertService,
		private jobApplicationService: JobApplicationService,
	) {}

	fetchFlinkCoJobAdvertsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchFlinkCoJobAdverts>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_FLINKCO_JOB_ADVERTS,
			),
			switchMap((action: FetchFlinkCoJobAdverts) =>
				this.flinkcoJobAdvertsService.getFlinkCoJobAdverts(action.payload.pageSize, action.payload.pageNumber).pipe(
					map(
						(result: PaginatedResponse<JobAdvertResponseDto>) =>
							new FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvertsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvertsError({ errorResponse: error })),
					),
				),
			),
		);
	});
	fetchFlinkCoIframeLinkEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchFlinkCoIframeLink>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_FLINKCO_IFRAME_LINK,
			),
			switchMap((action: FetchFlinkCoIframeLink) =>
				this.flinkcoJobAdvertsService.getFlinkCoIframeLink().pipe(
					map((result: string) => new FlinkCoJobAdvertsActions.FetchFlinkCoIframeLinkSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.FetchFlinkCoIframeLinkError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchFlinkCoCareersPageJobAdvertsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchCareersPageJobAdverts>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS,
			),
			switchMap((action: FetchCareersPageJobAdverts) =>
				this.flinkcoJobAdvertsService
					.getFlinkCoCareerPageJobAdverts(action.payload.flinkCoId, action.payload.filterJobAdvertsRequest)
					.pipe(
						map(
							(result: PaginatedResponse<JobAdvertResponseDto>) =>
								new FlinkCoJobAdvertsActions.FetchCareersPageJobAdvertsSucess({ result }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoJobAdvertsActions.FetchCareersPageJobAdvertsError({ errorResponse: error })),
						),
					),
			),
		);
	});

	fetchFlinkCoJobApplicationsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchFlinkCoJobApplications>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_FLINKCO_JOB_ADPPLICATIONS,
			),
			switchMap((action: FetchFlinkCoJobApplications) =>
				this.jobApplicationService
					.getFlinkCoJobApplication(action.payload.searchId, action.payload.searchFilterDto)
					.pipe(
						map(
							(result: JobApplicationResponse[]) =>
								new FlinkCoJobAdvertsActions.FetchFlinkCoJobApplicationsSuccess({ result }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoJobAdvertsActions.FetchFlinkCoJobApplicationsError({ errorResponse: error })),
						),
					),
			),
		);
	});

	fetchJobApplicationQuestionAnswersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchJobApplicationQuestionAnswers>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS,
			),
			switchMap((action: FetchJobApplicationQuestionAnswers) =>
				this.jobApplicationService
					.getJobApplicationQuestionAnswers(action.payload.flinkerId, action.payload.searchId)
					.pipe(
						map(
							(result: JobApplicationQuestionAnswerResponse[]) =>
								new FlinkCoJobAdvertsActions.FetchJobApplicationQuestionAnswersSuccess({ result }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoJobAdvertsActions.FetchJobApplicationQuestionAnswersError({ errorResponse: error })),
						),
					),
			),
		);
	});

	fetchFlinkCoJobAdvertEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvert>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_FLINKCO_JOB_ADVERT,
			),
			switchMap((action: FetchFlinkCoJobAdvert) =>
				this.flinkcoJobAdvertsService.getFlinkCoJobAdvert(action.payload.jobAdvertId).pipe(
					map((result: JobAdvertResponseDto) => new FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvertSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvertError({ errorResponse: error })),
					),
				),
			),
		);
	});
	fetchPublicFlinkCoJobAdvertEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.FetchPublicFlinkCoJobAdvert>(
				FlinkCoJobAdvertsActions.ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT,
			),
			switchMap((action: FetchPublicFlinkCoJobAdvert) =>
				this.flinkcoJobAdvertsService.getPublicFlinkCoJobAdvert(action.payload.jobAdvertId).pipe(
					map(
						(result: JobAdvertResponseDto) =>
							new FlinkCoJobAdvertsActions.FetchPublicFlinkCoJobAdvertSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.FetchPublicFlinkCoJobAdvertError({ errorResponse: error })),
					),
				),
			),
		);
	});

	createJobAdvertEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.CreateFlinkCoJobAdvert>(
				FlinkCoJobAdvertsActions.ActionTypes.CREATE_FLINKCO_JOB_ADVERT,
			),
			switchMap((action: CreateFlinkCoJobAdvert) =>
				this.flinkcoJobAdvertsService.createFlinkCoJobAdverts(action.payload).pipe(
					map((result: JobAdvertResponseDto) => new FlinkCoJobAdvertsActions.CreateFlinkCoJobAdvertSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.CreateFlinkCoJobAdvertError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateJobAdvertEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.UpdateFlinkCoJobAdvert>(
				FlinkCoJobAdvertsActions.ActionTypes.UPDATE_FLINKCO_JOB_ADVERT,
			),
			switchMap((action: UpdateFlinkCoJobAdvert) =>
				this.flinkcoJobAdvertsService.updateFlinkCoJobAdverts(action.payload).pipe(
					switchMap((result: JobAdvertResponseDto) =>
						of(
							new FlinkCoJobAdvertsActions.UpdateFlinkCoJobAdvertSuccess({ result }),
							new FlinkCoJobAdvertsActions.FetchFlinkCoJobAdvert({ jobAdvertId: result.id }),
						),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.UpdateFlinkCoJobAdvertError({ errorResponse: error })),
					),
				),
			),
		);
	});

	createJobAdvertReferralEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.CreateJobAdvertReferral>(
				FlinkCoJobAdvertsActions.ActionTypes.CREATE_JOB_ADVERT_REFERRAL,
			),
			switchMap((action: CreateJobAdvertReferral) =>
				this.flinkcoJobAdvertsService.referJobAdvertPublic(action.payload).pipe(
					map(
						() =>
							new FlinkCoJobAdvertsActions.CreateJobAdvertReferralSuccess({
								result: 'Job advert has been successfully referred.',
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.CreateJobAdvertReferralError({ errorResponse: error })),
					),
				),
			),
		);
	});
	createAuthedJobAdvertReferralEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoJobAdvertsActions.CreateAuthedJobAdvertReferral>(
				FlinkCoJobAdvertsActions.ActionTypes.CREATE_AUTHED_JOB_ADVERT_REFERRAL,
			),
			switchMap((action: CreateAuthedJobAdvertReferral) =>
				this.flinkcoJobAdvertsService.referJobAdvert(action.payload).pipe(
					map(
						() =>
							new FlinkCoJobAdvertsActions.CreateJobAdvertReferralSuccess({
								result: 'Job advert has been successfully referred.',
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoJobAdvertsActions.CreateJobAdvertReferralError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
