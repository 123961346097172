<mat-card class="panel d-flex flex-column align-items-center p-4 pb-0">
	<h2>Your session will expire soon.</h2>
	<div class="timer">
		<mat-progress-spinner
			class="timer-progress"
			[diameter]="150"
			[mode]="'determinate'"
			[value]="(countDown / 60) * 100"
		>
		</mat-progress-spinner>
		<h5 class="timer-text">00:{{ countDown < 10 ? '0' + countDown : countDown }}</h5>
	</div>
	<p class="my-4 px-4 text-center font-size-12">
		You can either log out or refresh your session, you will automatically be logged out once the time runs out.
	</p>
	<app-square-button theme="m1 fs1" class="m-0" value="Keep me logged in" (click)="refresh()"> </app-square-button>
	<app-square-button-with-icon
		theme="front-main-1"
		value="Logout"
		class="mt-4 pr-3 float-right"
		type="button"
		icon="logout"
		[disabled]="false"
		(click)="logout()"
	>
	</app-square-button-with-icon>
</mat-card>
