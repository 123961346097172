import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-page-header-toolbar',
	templateUrl: './page-header-toolbar.component.html',
	styleUrls: ['./page-header-toolbar.component.scss'],
})
export class PageHeaderToolbarComponent implements OnInit {
	@Input() theme = '';
	@Input() heading = '';
	@Input() icon = '';
	@Input() toolTip = '';
	@Input() showTip = false;

	constructor() {}

	ngOnInit(): void {}
}
