<div class="wrapper-container">
	<div class="take-on-container">
		<div class="container py-5">
			<app-page-title [title]="'Cookies'"></app-page-title>
			<mat-card>
				<div class="front-sub-3 font-size-11">
					<app-page-header-toolbar heading="Your Cookie Choices:"> </app-page-header-toolbar>

					When viewing or visiting our Website, information may be retrieved or collected from your browser and stored,
					mostly in the form of cookies. A cookie is a file containing an identifier that is sent by a web server to a
					web browser and is stored by that browser.

					<br /><br />

					The personal information that we store about you may be linked to the information stored in and obtained from
					cookies. It does not usually directly identify you, but it can give you a more personalised web experience.

					<br /><br />

					By clicking on the different category headings, you are able to find out more about the cookies and change the
					default settings. However, by not permitting specific types of cookies may impact your experience of the site
					and the services we are able to offer.

					<br /><br />

					<app-page-header-toolbar heading="Manage Consent Preferences:"> </app-page-header-toolbar>

					<span class="font-demi-bold"> Strictly Necessary Cookies </span>

					<br /><br />

					These cookies are necessary for the website to function and cannot be blocked or switched off on our systems.
					They are usually only set in response to actions made by you which amount to a request for services, such as
					setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert
					you about these cookies, but some parts of the site will not then work. These cookies do not store any
					personally identifiable markers ors information.

					<br /><br />

					<span class="font-demi-bold"> Performance Cookies </span>

					<br /><br />

					These cookies allow us to count visits and traffic sources so we can measure and improve the performance of
					our site. They help us to know which pages are the most and least popular and see how visitors move around the
					site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these
					cookies we will not know when you have visited our site, and will not be able to monitor its performance.

					<br /><br />

					<span class="font-demi-bold"> Functional Cookies </span>

					<br /><br />

					These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us
					or by third party providers whose services we have added to our pages. If you do not allow these cookies then
					some or all of these services may not function properly.

					<br /><br />

					<span class="font-demi-bold"> Targeting Cookies </span>

					<br /><br />

					These cookies may be set through our site by our advertising partners. They may be used by those companies to
					build a profile of your interests and show you relevant adverts on other sites. They do not store directly
					personal information, but are based on uniquely identifying your browser and internet device. If you do not
					allow these cookies, you will experience less targeted advertising.

					<br /><br />

					<span class="font-demi-bold"> Social Media Cookies </span>

					<br /><br />

					These cookies are set by a range of social media services that we have added to the site to enable you to
					share our content with your friends and networks. They are capable of tracking your browser across other sites
					and building up a profile of your interests. This may impact the content and messages you see on other
					websites you visit. If you do not allow these cookies you may not be able to use or see these sharing tools.
				</div>
			</mat-card>
		</div>
	</div>
</div>
