import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { SelectOptionsResponse } from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import {
	FlinkerTakeOnStoreActions,
	FlinkerTakeOnStoreSelectors,
} from 'projects/flink-app/src/app/root-store/flinker-take-on-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-churning-status',
	templateUrl: './churning-status.component.html',
	styleUrls: ['./churning-status.component.scss'],
})
export class ChurningStatusComponent implements OnInit, OnDestroy {
	@Input() pending = false;

	subscriptions = new Subscription();
	form: FormGroup;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	createReactivationRequestHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectCreateReactivationRequestHttpPackage,
	);
	selectOptions: SelectOptionsResponse;
	getErrorMessage = getErrorMessage;
	shouldValidate = false;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
	) {
		this.form = this.formBuilder.group({
			reactivationReason: [''],
			otherReactivationReason: [null],
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result !== null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);

		this.subscriptions.add(
			this.createReactivationRequestHttpPackage$.subscribe((createReactivationRequestHttpPackage) => {
				if (createReactivationRequestHttpPackage.result !== null) {
					this.store.dispatch(new FlinkerTakeOnStoreActions.GetReactivationRequest());
				}
			}),
		);
	}

	submit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			return;
		}

		this.store.dispatch(
			new FlinkerTakeOnStoreActions.CreateReactivationRequest({
				createReactivationRequestDto: {
					reactivationReasonId: this.selectOptions.reactivationReasons.find(
						(x) => this.form.controls.reactivationReason.value === x.code,
					).id,
					otherReactivationReason: this.form.controls.otherReactivationReason.value,
				},
			}),
		);
		this.notifierService.notify('success', 'Reactivation request submitted');
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
