import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import HttpPackage from '@models/http-package';
import { defaultHttpPackage } from '../../helpers';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { JobApplicationQuestionAnswerResponse } from '@models/dto/responses/job-application-question-answer-response.dto';

export interface FlinkCoJobAdvertState {
	fetchFlinkCoJobAdvertsHttpPackage: HttpPackage<PaginatedResponse<JobAdvertResponseDto>>;
	fetchFlinkCoJobAdvertHttpPackage: HttpPackage<JobAdvertResponseDto>;
	fetchPublicFlinkCoJobAdvertHttpPackage: HttpPackage<JobAdvertResponseDto>;
	createJobAdvertHttpPackage: HttpPackage<JobAdvertResponseDto>;
	updateJobAdvertHttpPackage: HttpPackage<JobAdvertResponseDto>;
	fetchFlinkCoJobApplicationHttpPackage: HttpPackage<JobApplicationResponse[]>;
	fetchJobApplicationQuestionAnswersHttpPackage: HttpPackage<JobApplicationQuestionAnswerResponse[]>;
	referJobAdvertHttpPackage: HttpPackage<string>;
	fetchCareersPageJobAdvert: HttpPackage<PaginatedResponse<JobAdvertResponseDto>>;
	fetchFlinkCoIframeLink: HttpPackage<string>;
}

export const initialState: FlinkCoJobAdvertState = {
	fetchFlinkCoJobAdvertsHttpPackage: defaultHttpPackage(),
	fetchFlinkCoJobAdvertHttpPackage: defaultHttpPackage(),
	fetchPublicFlinkCoJobAdvertHttpPackage: defaultHttpPackage(),
	createJobAdvertHttpPackage: defaultHttpPackage(),
	updateJobAdvertHttpPackage: defaultHttpPackage(),
	fetchFlinkCoJobApplicationHttpPackage: defaultHttpPackage(),
	fetchJobApplicationQuestionAnswersHttpPackage: defaultHttpPackage(),
	referJobAdvertHttpPackage: defaultHttpPackage(),
	fetchCareersPageJobAdvert: defaultHttpPackage(),
	fetchFlinkCoIframeLink: defaultHttpPackage(),
};
