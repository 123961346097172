<div class="text-center" *ngIf="isLoading">
	<app-loader></app-loader>
</div>

<form class="container" [formGroup]="form" (ngSubmit)="signUp()" *ngIf="!isLoading">
	<div class="container-row container-row-less-spacing">
    <app-heading class="pr-2" theme="f4-10" heading="REGISTER"></app-heading>
		<app-heading theme="f4-3" heading="I want to..."></app-heading>
	</div>

	<app-switch-toggle [additionalClass]="'green'" (optionSelected)="toggleSwitch($event)" [selectedOption]="0" [options]="toggleSwitchOptions">
	</app-switch-toggle>

	<div class="container-row container-row-less-spacing">
		<app-square-input
			type="text"
			placeholder="Name"
			formControlName="name"
			[errorMessage]="getErrorMessage(form.controls.name, 'Name', shouldValidate)"
		></app-square-input>
	</div>
	<div class="container-row container-row-less-spacing">
		<app-square-input
			type="text"
			placeholder="Surname"
			formControlName="surname"
			[errorMessage]="getErrorMessage(form.controls.surname, 'Surname', shouldValidate)"
		>
		</app-square-input>
	</div>
	<div class="container-row container-row-less-spacing">
		<app-square-input
			type="email"
			placeholder="Email"
			formControlName="email"
			[errorMessage]="getErrorMessage(form.controls.email, 'Email', shouldValidate)"
		></app-square-input>
	</div>
  <div class="container-row container-row-less-spacing">
    <app-square-input
      type="text"
      placeholder="Cellphone Number"
      formControlName="cellphoneNumber"
      [errorMessage]="getErrorMessage(form.controls.cellphoneNumber, 'Cellphone number', shouldValidate)"
    ></app-square-input>
  </div>
	<div class="container-row container-row-less-spacing">
		<app-square-input
			placeholder="Password"
			type="password"
			formControlName="password"
			[errorMessage]="getErrorMessage(form.controls.password, 'Password', shouldValidate)"
		></app-square-input>
	</div>
	<div class="container-row container-row-less-spacing">
		<span>Password requires one uppercase, one digit, one special character and be atleast 8 characters.</span>
	</div>

	<div class="container-row pb-0">
		<app-sign-in-button-group
			submitValue="Register"
      theme="m2"
			[submitDisabled]="!form.valid"
      [isSignUp]="true"
			(onGoogleSignIn)="onGoogleSignIn()"
      (onMicrosoftSignIn)="onMicrosoftSignIn()"
			(onSubmit)="signUp()"
		>
		</app-sign-in-button-group>
		<app-link routerLink="flinker-registration" theme="s3" value="Already have an account? Sign In" class="my-2">
		</app-link>
	</div>
</form>
