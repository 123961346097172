import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlinkCoTakeOnStoreActions } from '.';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FlinkCoTakeOnService } from '../../services/take-on/flinkco-take-on.service';
import {
	SubmitFlinkCoCompanyInformation,
	UploadCompanyLogo,
	UploadCIPCCompanyDocuments,
	SubmitFlinkCoSubscription,
	SubmitFlinkCoRetainerInformation,
	SubmitFlinkCoAdHocInformation,
	DeleteFlinkCoUser,
	GetFlinkCo,
	SubmitFlinkCoTermsAndConditions,
	AddFlinkCoUsers,
	GetRetainerSubscription,
	GetFlinkCoWithSelf,
} from './actions';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { FlinkCoCompanyInformationResponse } from '../../models/dto/responses/flinkco-company-information-response.dto';
import { FlinkCoSubscriptionResponse } from '../../models/dto/responses/flink-co-subscription-response';
import { FlinkCoService } from '../../services/flinkCo/flink-co.service';
import FlinkCoResponse from '../../models/dto/responses/flink-co-response.dto';
import { RetainerInformationResponse } from '../../models/dto/responses/retainer-information-response.dto';
import { AdHocInformationResponse } from '../../models/dto/responses/ad-hoc-information-response.dto';
import FlinkCoUser from '../../models/dto/flink-co-user.dto';
import RetainerSubscriptionResponse from '../../models/dto/responses/retainer-subscription-response.dto';

@Injectable()
export class FlinkCoTakeOnStoreEffects {
	constructor(
		private actions$: Actions,
		private takeOnService: FlinkCoTakeOnService,
		private flinkCoService: FlinkCoService,
	) {}

	submitFlinkCoCompanyInformationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoCompanyInformation>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION,
			),
			switchMap((action: SubmitFlinkCoCompanyInformation) =>
				this.takeOnService.submitCompanyInformation(action.payload.flinkCoCompanyInformationRequest).pipe(
					map(
						(result: FlinkCoCompanyInformationResponse) =>
							new FlinkCoTakeOnStoreActions.SubmitFlinkCoCompanyInformationSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.SubmitFlinkCoCompanyInformationError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkCoTermsAndConditionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoTermsAndConditions>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS,
			),
			switchMap((action: SubmitFlinkCoTermsAndConditions) =>
				this.takeOnService.submitTermsAndConditions(action.payload.flinkCoTermsAndConditionsRequest).pipe(
					map((_: void) => new FlinkCoTakeOnStoreActions.SubmitFlinkCoTermsAndConditionsSuccess()),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.SubmitFlinkCoTermsAndConditionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkCoRetainerInformationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoRetainerInformation>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION,
			),
			switchMap((action: SubmitFlinkCoRetainerInformation) =>
				this.takeOnService.submitRetainerInformation(action.payload.retainerInformationRequest).pipe(
					map(
						(result: RetainerInformationResponse) =>
							new FlinkCoTakeOnStoreActions.SubmitFlinkCoRetainerInformationSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.SubmitFlinkCoRetainerInformationError({ errorResponse: error })),
					),
				),
			),
		);
	});

	addRetainerUsersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.AddFlinkCoUsers>(FlinkCoTakeOnStoreActions.ActionTypes.ADD_FLINKCO_USERS),
			switchMap((action: AddFlinkCoUsers) =>
				this.flinkCoService.addFlinkCoUsers(action.payload.flinkCoUsers).pipe(
					map(
						(result: FlinkCoUser[]) =>
							new FlinkCoTakeOnStoreActions.AddRetainerUsersSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.AddRetainerUsersError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkCoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.GetFlinkCo>(FlinkCoTakeOnStoreActions.ActionTypes.GET_FLINKCO),
			switchMap((_: GetFlinkCo) =>
				this.flinkCoService.getFlinkCo().pipe(
					map(
						(result: FlinkCoResponse) =>
							new FlinkCoTakeOnStoreActions.GetFlinkCoSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.GetFlinkCoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkCoAdHocInformationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoAdHocInformation>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION,
			),
			switchMap((action: SubmitFlinkCoAdHocInformation) =>
				this.takeOnService.submitAdHocInformation(action.payload.adHocInformation).pipe(
					map(
						(result: AdHocInformationResponse) =>
							new FlinkCoTakeOnStoreActions.SubmitFlinkCoAdHocInformationSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.SubmitFlinkCoAdHocInformationError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkCoSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoSubscription>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION,
			),
			switchMap((action: SubmitFlinkCoSubscription) =>
				this.takeOnService.submitSubscriptionChoice(action.payload.flinkCoSubscriptionRequest).pipe(
					map(
						(result: FlinkCoSubscriptionResponse) =>
							new FlinkCoTakeOnStoreActions.SubmitFlinkCoSubscriptionSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.SubmitFlinkCoSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadCompanyLogoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.UploadCompanyLogo>(FlinkCoTakeOnStoreActions.ActionTypes.UPLOAD_COMPANY_LOGO),
			switchMap((action: UploadCompanyLogo) =>
				this.takeOnService.uploadCompanyLogo(action.payload.formData).pipe(
					map(
						(result: string) =>
							new FlinkCoTakeOnStoreActions.UploadCompanyLogoSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.UploadCompanyLogoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadCIPCDocumentsLogoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.UploadCIPCCompanyDocuments>(
				FlinkCoTakeOnStoreActions.ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS,
			),
			switchMap((action: UploadCIPCCompanyDocuments) =>
				this.takeOnService.uploadCIPCDocuments(action.payload.formData).pipe(
					map((_) => new FlinkCoTakeOnStoreActions.UploadCIPCCompanyDocumentsSuccess()),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.UploadCIPCCompanyDocumentError({ errorResponse: error })),
					),
				),
			),
		);
	});

	deleteFlinkCoUserEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.DeleteFlinkCoUser>(FlinkCoTakeOnStoreActions.ActionTypes.DELETE_FLINKCO_USER),
			switchMap((action: DeleteFlinkCoUser) =>
				this.takeOnService.deleteFlinkCoUser(action.payload.userId).pipe(
					map(
						(_) =>
							new FlinkCoTakeOnStoreActions.DeleteFlinkCoUserSuccess({ result: { userId: action.payload.userId } }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.DeleteFlinkCoUserError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getRetainerSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.GetRetainerSubscription>(
				FlinkCoTakeOnStoreActions.ActionTypes.GET_RETAINER_SUBSCRIPTION,
			),
			switchMap((_: GetRetainerSubscription) =>
				this.takeOnService.getRetainerSubscription().pipe(
					map(
						(result: RetainerSubscriptionResponse) =>
							new FlinkCoTakeOnStoreActions.GetRetainerSubscriptionSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.GetRetainerSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkCoWithSelfEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.GetFlinkCoWithSelf>(FlinkCoTakeOnStoreActions.ActionTypes.GET_FLINKCO_WITH_SELF),
			switchMap((action: GetFlinkCoWithSelf) =>
				this.flinkCoService.getFlinkCoWithSelf().pipe(
					map((result: FlinkCoResponse) => new FlinkCoTakeOnStoreActions.GetFlinkCoWithSelfSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoTakeOnStoreActions.GetFlinkCoWithSelfError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
