<div class="text-center" *ngIf="isLoading">
	<app-loader></app-loader>
</div>
<form class="container" [formGroup]="form" *ngIf="!isLoading">
	<div class="container-row">
		<app-heading theme="m1" heading="Login"></app-heading>
	</div>

	<app-switch-toggle (optionSelected)="toggleSwitch($event)" [selectedOption]="2" [options]="toggleSwitchOptions">
	</app-switch-toggle>

	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			type="email"
			placeholder="Email"
			icon="supervisor_account"
			formControlName="email"
			[errorMessage]="getErrorMessage(form.controls.email, 'Email', shouldValidate)"
		></app-rounded-input>
	</div>
	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			placeholder="Password"
			icon="lock"
			type="password"
			formControlName="password"
			[errorMessage]="getErrorMessage(form.controls.password, 'Password', shouldValidate)"
		></app-rounded-input>
	</div>
	<div class="d-flex justify-content-end mb-2">
		<div class="text-right pt-1">
			<app-link routerLink="../forgot-password" theme="s5" value="Forgot Password?"></app-link>
		</div>
	</div>
	<div class="container-row pb-2">
		<app-sign-in-button-group
			submitValue="Login"
			[submitDisabled]="!form.valid"
			(onGoogleSignIn)="onGoogleSignIn()"
			(onMicrosoftSignIn)="onMicrosoftSignIn()"
			(onSubmit)="signIn()"
		>
		</app-sign-in-button-group>
	</div>
	<div class="d-block text-center position-relative my-2">
		<span class="pr-1">Don't have an account?</span>
		<app-link routerLink="../flinker-registration" theme="s3" value="Register as a flinker" class="pr-1"> </app-link>
		<app-link routerLink="../flinkco-registration" theme="s3" value="Register as a company"> </app-link>
	</div>
</form>
