import HttpPackage from '../models/http-package';
import { isNil } from '@app/utils/functions.util';

export function defaultHttpPackage(): HttpPackage<any> {
	return {
		error: null,
		loading: false,
		result: null,
	};
}

export function updateListWithLatestItem<T extends Record<string, any>>(
	listToUpdate: T[],
	updatedItem: T,
	uniqueKeyName: string,
	uniqueKey: string,
): T[] {
	if (isNil(listToUpdate)) {
		return [updatedItem];
	}
	const indexOfItemInList = listToUpdate.findIndex((item) => item[uniqueKeyName] === uniqueKey);

	return [
		...listToUpdate.slice(0, indexOfItemInList),
		{
			...listToUpdate[indexOfItemInList],
			...updatedItem,
		},
		...listToUpdate.slice(indexOfItemInList + 1),
	];
}
