import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '../enums/role.enum';
import { AuthGuardService } from '../services/auth/auth-guard.service';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FlinkerOffersComponent } from './components/offers/flinker-offers/flinker-offers.component';
import { InterviewDetailedComponent } from './components/interviews/interview-detailed/interview-detailed.component';
import { InterviewsComponent } from './components/interviews/interviews/interviews.component';
import { FlinkerOfferDetailedComponent } from './components/offers/flinker-offer-detailed/flinker-offer-detailed.component';
import { ProfileComponent } from './components/profile/profile.component';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { FlinkerCvDownloadComponent } from '@flinker-dashboard/components/cv/flinker-cv-download/flinker-cv-download.component';
import { JobApplicationComponent } from '@flinker-dashboard/components/jobs/job-application/job-application.component';
import { ViewFlinkerJobAdvertsComponent } from '@flinker-dashboard/components/jobs/view-flinker-job-adverts/view-flinker-job-adverts.component';
import { ViewJobAdvertComponent } from '@flinker-dashboard/components/jobs/view-job-advert/view-job-advert.component';
import { VideoRecorderComponent } from '@flinker-dashboard/components/jobs/video-recorder/video-recorder.component';

const routes: Routes = [
	{
		path: '',
		component: WrapperComponent,
		canActivate: [AuthGuardService],
		data: { allowedRoles: [Role.Flinker, Role.FlinkAdmin, Role.FlinkAdminMaster] },
		children: [
			{
				path: 'profile',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker, Role.FlinkAdmin, Role.FlinkAdminMaster],
				},
				component: ProfileComponent,
			},
			{
				path: 'calendar',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker, Role.FlinkAdmin, Role.FlinkAdminMaster],
					requireActive: true,
				},
				component: CalendarComponent,
			},
			{
				path: 'cv',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker, Role.FlinkAdmin, Role.FlinkAdminMaster],
					requireActive: false,
				},
				component: FlinkerCvDownloadComponent,
			},
			{
				path: 'offers',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: FlinkerOffersComponent,
			},
			{
				path: 'offer/:offerId',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: FlinkerOfferDetailedComponent,
			},
			{
				path: 'interviews',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: InterviewsComponent,
			},
			{
				path: 'interview/:interviewId',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: InterviewDetailedComponent,
			},
			{
				path: 'job-application/:jobAdvertId',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: JobApplicationComponent,
			},
			{
				path: 'job-adverts',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: ViewFlinkerJobAdvertsComponent,
			},
			{
				path: 'job-advert/:jobAdvertId',
				canActivate: [AuthGuardService],
				data: {
					allowedRoles: [Role.Flinker],
					requireActive: true,
				},
				component: ViewJobAdvertComponent,
			},
		],
	},
	{
		path: '**',
		redirectTo: '/profile',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class FlinkerDashboardRoutingModule {}
