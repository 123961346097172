import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { QualificationType } from '@app/enums/qualification-type.enum';
import Qualification from '@models/dto/qualification.dto';
import {
	getSelectOptionById,
	getSelectOptionName,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from '@models/dto/responses/select-options-response.dto';
import { RootStoreState } from '@root-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { InterviewStatus } from '@app/enums/interview-status.enum';
import { InterviewStoreActions } from '@root-store/interview-store';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-qualifications-summary',
	templateUrl: './qualifications-summary.component.html',
	styleUrls: ['./qualifications-summary.component.scss'],
})
export class QualificationsSummaryComponent implements OnInit {
	@Input() qualifications: Qualification[];
	@Input() interviewId: string;
	@Input() interviewAccepted: boolean;

	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	getSelectOptionName = getSelectOptionName;
	InterviewStatus = InterviewStatus;
	QualificationType = QualificationType;
	flinkerDocuments = FlinkerDocumentType;

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				this.selectOptions = selectOptionsResponse.result;
			}),
		);
	}

	getCompletionYear(qualification: Qualification): string {
		return qualification.completionYear
			? `In progress - expected to complete in ${qualification.completionYear}`
			: qualification.yearObtained;
	}

	getInstitution(qualification: Qualification): string {
		return qualification.type === QualificationType.Other ||
			getSelectOptionById(this.selectOptions?.institutions, qualification.institutionId)?.code === 'OTH'
			? qualification.otherInstitution
			: getSelectOptionNameById(this.selectOptions?.institutions, qualification.institutionId);
	}
	downloadQualificationDocument(qualificationId: string) {
		this.store.dispatch(
			new InterviewStoreActions.GetFlinkerDocument({
				interviewId: this.interviewId,
				documentId: qualificationId,
				documentType: this.flinkerDocuments.QualificationDocuments,
			}),
		);
	}
}
