import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { DownloadFlinkerDocumentDto } from '@models/dto/download-flinker-document.dto';

@Component({
	selector: 'app-flinker-documents',
	templateUrl: './flinker-documents.component.html',
	styleUrls: ['./flinker-documents.component.scss'],
})
export class FlinkerDocumentsComponent implements OnInit {
	@Input() flinkerId: string;
	@Input() isIdDocumentUploaded: boolean;
	@Input() isASouthAfricanCitizen: boolean;
	flinkerDocuments = FlinkerDocumentType;

	@Output() downloadFlinkerDocument: EventEmitter<DownloadFlinkerDocumentDto> =
		new EventEmitter<DownloadFlinkerDocumentDto>();

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {}

	downloadDocument(name: string, documentType: FlinkerDocumentType) {
		this.downloadFlinkerDocument.emit({
			documentId: name,
			documentType,
		});
	}
}
