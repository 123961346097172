import { Component, Inject, OnInit } from '@angular/core';
import { getErrorMessage } from '@app/utils/form.util';
import { RootStoreState } from '@root-store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { CreateAuthedJobAdvertReferral, CreateJobAdvertReferral } from '@root-store/flinkco/job-adverts/actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { FlinkCoJobAdvertsSelectors } from '@root-store/flinkco/job-adverts';
import { NotificationType } from '@app/enums/notification-type.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-job-adverts-referral',
	templateUrl: './job-adverts-referral.component.html',
	styleUrls: ['./job-adverts-referral.component.scss'],
})
export class JobAdvertsReferralComponent implements OnInit {
	getErrorMessage = getErrorMessage;
	form: FormGroup;
	jobAdvertId: string;
	referJobAdvertHttpPackage$ = this.store.select(FlinkCoJobAdvertsSelectors.selectJobAdvertReferral);
	shouldValidate = false;
	showAdditionalFields = true;

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(
		public dialogRef: MatDialogRef<JobAdvertsReferralComponent>,
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private route: ActivatedRoute,
		private notifierService: NotifierService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			surname: ['', Validators.required],
			emailAddress: ['', [Validators.required, Validators.email]],
			contactNumber: ['', Validators.required],
			refererName: ['', Validators.required],
			refererSurname: ['', Validators.required],
			refererEmail: ['', [Validators.required, Validators.email]],
		});
	}

	ngOnInit(): void {
		if (this.data.length === 0) {
			this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
				this.jobAdvertId = paramMap.get('jobAdvertId');
			});
		} else {
			this.jobAdvertId = this.data.jobAdvertId;
			this.showAdditionalFields = !this.data.referredByAuthedUser;
			this.updateFormValidity();
		}

		this.referJobAdvertHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((referJobAdvertHttpPackage) => {
			if (referJobAdvertHttpPackage.result !== null) {
				this.notifierService.notify(NotificationType.Success, referJobAdvertHttpPackage.result);
				this.dialogRef.close();
			}
		});
	}

	updateFormValidity() {
		this.form.controls.refererName.setValidators(null);
		this.form.controls.refererSurname.setValidators(null);
		this.form.controls.refererEmail.setValidators(null);
		this.form.updateValueAndValidity();
	}

	submit() {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			this.shouldValidate = true;

			return;
		}
		if (!this.data.referredByAuthedUser) {
			this.store.dispatch(
				new CreateJobAdvertReferral({
					jobAdvertId: this.jobAdvertId,
					...this.form.value,
				}),
			);
		} else {
			this.store.dispatch(
				new CreateAuthedJobAdvertReferral({
					jobAdvertId: this.data.jobAdvertId,
					...this.form.value,
				}),
			);
		}
	}

	closeDialog() {
		return this.dialogRef.close();
	}
}
