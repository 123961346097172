import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-profile-picture',
	templateUrl: './profile-picture.component.html',
	styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit {
	@Input() src = '';
	@Input() alt = '';
	@Input() validExtensions = '.jpg,.jpeg,.png,.svg,.svgz';
	@Input() loading = false;
	file: File;

	@Output() fileSelected = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	onFileSelected(event: any): void {
		this.file = event?.target?.files[0];
		this.fileSelected.emit(this.file);
	}
}
