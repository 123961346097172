import { Action } from '@ngrx/store';

export enum ActionTypes {
	UPDATE_LAST_REQUEST = '[App] Update last request',
}

export class UpdateLastRequest implements Action {
	readonly type = ActionTypes.UPDATE_LAST_REQUEST;
	constructor() {}
}

export type Actions = UpdateLastRequest;
