import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FlinkerTakeOnStoreEffects } from './effects';
import { flinkerTakeOnReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinkerTakeOn', flinkerTakeOnReducer),
		EffectsModule.forFeature([FlinkerTakeOnStoreEffects]),
	],
	providers: [FlinkerTakeOnStoreEffects],
})
export class FlinkerTakeOnStoreModule {}
