/**
 * It looks like session storage is not supported in all cases, especially in iFrames.
 * So we need to cater for this by using local storage as a fallback.
 */
export class SafeStorage {
	local: any = {};
	getItem(item: string) {
		try {
			return sessionStorage.getItem(item);
		} catch (e) {
			return this.local[item];
		}
	}

	removeItem(item: string) {
		try {
			sessionStorage.removeItem(item);
		} catch (e) {
			delete this.local[item];
		}
	}

	setItem(item: string, value: string) {
		try {
			sessionStorage.setItem(item, value);
		} catch (e) {
			delete this.local[item];
		}
	}
}

export const safeStorage = new SafeStorage();
