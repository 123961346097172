import { initialState, InterviewQuestionnaireState } from '@root-store/interview-questionnaire-store/state';
import { Actions, ActionTypes } from '@root-store/interview-questionnaire-store/actions';

export function InterviewQuestionnaireReducer(state = initialState, action: Actions): InterviewQuestionnaireState {
	switch (action.type) {
		case ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES: {
			return {
				...state,
				getPaginatedInterviewQuestionnairesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_SUCCESS: {
			return {
				...state,
				getPaginatedInterviewQuestionnairesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_ERROR: {
			return {
				...state,
				getPaginatedInterviewQuestionnairesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONNAIRE: {
			return {
				...state,
				getInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONNAIRE_SUCCESS: {
			return {
				...state,
				getInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONNAIRE_ERROR: {
			return {
				...state,
				getInterviewQuestionnaireHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES: {
			return {
				...state,
				interviewQuestionCategoriesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES_SUCCESS: {
			return {
				...state,
				interviewQuestionCategoriesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES_ERROR: {
			return {
				...state,
				interviewQuestionCategoriesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY: {
			return {
				...state,
				filteredDefaultInterviewQuestions: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_SUCCESS: {
			return {
				...state,
				filteredDefaultInterviewQuestions: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_ERROR: {
			return {
				...state,
				filteredDefaultInterviewQuestions: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS: {
			return {
				...state,
				allDefaultInterviewQuestions: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_SUCCESS: {
			return {
				...state,
				allDefaultInterviewQuestions: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_ERROR: {
			return {
				...state,
				allDefaultInterviewQuestions: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE: {
			return {
				...state,
				createInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE_SUCCESS: {
			return {
				...state,
				createInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE_ERROR: {
			return {
				...state,
				createInterviewQuestionnaireHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE: {
			return {
				...state,
				updateInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE_SUCCESS: {
			return {
				...state,
				updateInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE_ERROR: {
			return {
				...state,
				updateInterviewQuestionnaireHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE: {
			return {
				...state,
				deleteInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE_SUCCESS: {
			return {
				...state,
				deleteInterviewQuestionnaireHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.id,
				},
			};
		}
		case ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE_ERROR: {
			return {
				...state,
				deleteInterviewQuestionnaireHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES: {
			return {
				...state,
				getAllInterviewQuestionnairesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES_SUCCESS: {
			return {
				...state,
				getAllInterviewQuestionnairesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES_ERROR: {
			return {
				...state,
				getAllInterviewQuestionnairesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		default:
			return state;
	}
}
