import { Action } from '@ngrx/store';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import FlinkCoResponse from '../../models/dto/responses/flink-co-response.dto';

export enum ActionTypes {
	CANCEL_RETAINER_SUBSCRIPTION = '[FlinkcoDashboard] Cancel retainer subscription',
	CANCEL_RETAINER_SUBSCRIPTION_SUCCESS = '[FlinkcoDashboard] Cancel retainer subscription success',
	CANCEL_RETAINER_SUBSCRIPTION_ERROR = '[FlinkcoDashboard] Cancel retainer subscription error',

	REQUEST_ADHOC_SUBCRIPTION_UPGRADE = '[FlinkcoDashboard] Request Adhoc subscription upgrade',
	REQUEST_ADHOC_SUBCRIPTION_UPGRADE_SUCCESS = '[FlinkcoDashboard] Request Adhoc subscription upgrade success',
	REQUEST_ADHOC_SUBCRIPTION_UPGRADE_ERROR = '[FlinkcoDashboard] Request Adhoc subscription upgrade error',

	REQUEST_DEMO = '[FlinkcoDashboard] Request demo',
	REQUEST_DEMO_SUCCESS = '[FlinkcoDashboard] Request demo success',
	REQUEST_DEMO_ERROR = '[FlinkcoDashboard] Request demo error',
}

export class CancelRetainerSubscription implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBSCRIPTION;
	constructor() {}
}

export class CancelRetainerSubscriptionSuccess implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CancelRetainerSubscriptionError implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class RequestAdhocSubscriptionUpgrade implements Action {
	readonly type = ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE;
	constructor() {}
}

export class RequestAdhocSubscriptionUpgradeSuccess implements Action {
	readonly type = ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class RequestAdhocSubscriptionUpgradeError implements Action {
	readonly type = ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class RequestDemo implements Action {
	readonly type = ActionTypes.REQUEST_DEMO;
	constructor() {}
}

export class RequestDemoSuccess implements Action {
	readonly type = ActionTypes.REQUEST_DEMO_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class RequestDemoError implements Action {
	readonly type = ActionTypes.REQUEST_DEMO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CancelRetainerSubscription
	| CancelRetainerSubscriptionSuccess
	| CancelRetainerSubscriptionError
	| RequestAdhocSubscriptionUpgrade
	| RequestAdhocSubscriptionUpgradeSuccess
	| RequestAdhocSubscriptionUpgradeError
	| RequestDemo
	| RequestDemoSuccess
	| RequestDemoError;
