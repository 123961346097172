import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlinkCoNamesResponse } from '../../models/dto/responses/flinkCo-names-response.dto';
import FlinkerResponse from '../../models/dto/responses/flinker-response.dto';
import ProfileCompletionItem from '../../models/dto/responses/profile-completion-item.dto';
import ReactivationRequestResponse from '../../models/dto/responses/reactivation-request-response.dto';
import HttpPackage from '../../models/http-package';
import { FlinkerTakeOnState } from './state';

const getFlinker = (state: FlinkerTakeOnState): FlinkerResponse => state.flinker;

const getFlinkerHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> => state.flinkerHttpPackage;

const getFlinkerPersonalDetailsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.flinkerPersonalDetailsHttpPackage;

const getFlinkerEmploymentDetailsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.flinkerEmploymentDetailsHttpPackage;

const getFlinkerEducationalDetailsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.flinkerEducationalDetailsHttpPackage;

const getFlinkerCriminalDeclarationHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.flinkerCriminalDeclarationHttpPackage;

const getFlinkerTermsAndConditionsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.flinkerTermsAndConditionsHttpPackage;

const getUploadIdDocumentHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.uploadIdDocumentHttpPackage;

const getUploadPassportDocumentHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.uploadPassportDocumentHttpPackage;

const getUploadEmploymentDocumentsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.uploadEmploymentDocumentsHttpPackage;

const getUploadQualificationDocumentsHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkerResponse> =>
	state.uploadQualificationDocumentsHttpPackage;

const getUploadProfilePictureHttpPackage = (state: FlinkerTakeOnState): HttpPackage<string> =>
	state.uploadProfilePictureHttpPackage;

const getProfileCompletionHttpPackage = (state: FlinkerTakeOnState): HttpPackage<ProfileCompletionItem[]> =>
	state.profileCompletionHttpPackage;

const getCreateReactivationRequestHttpPackage = (state: FlinkerTakeOnState): HttpPackage<string> =>
	state.createReactivationRequestHttpPackage;

const getReactivationRequestHttpPackage = (state: FlinkerTakeOnState): HttpPackage<ReactivationRequestResponse> =>
	state.getReactivationRequestHttpPackage;

const getFlinkCosListHttpPackage = (state: FlinkerTakeOnState): HttpPackage<FlinkCoNamesResponse[]> =>
	state.getFlinkCosListHttpPackage;

export const selectTakeOnState = createFeatureSelector<FlinkerTakeOnState>('flinkerTakeOn');

export const selectFlinker = createSelector(selectTakeOnState, getFlinker);

export const selectFlinkerHttpPackage = createSelector(selectTakeOnState, getFlinkerHttpPackage);

export const selectFlinkerPersonalDetailsHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkerPersonalDetailsHttpPackage,
);

export const selectFlinkerEmploymentDetailsHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkerEmploymentDetailsHttpPackage,
);

export const selectFlinkerEducationalDetailsHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkerEducationalDetailsHttpPackage,
);

export const selectFlinkerCriminalDeclarationHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkerCriminalDeclarationHttpPackage,
);

export const selectFlinkerTermsAndConditionsHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkerTermsAndConditionsHttpPackage,
);

export const selectUploadIdDocumentHttpPackage = createSelector(selectTakeOnState, getUploadIdDocumentHttpPackage);

export const selectUploadPassportDocumentHttpPackage = createSelector(
	selectTakeOnState,
	getUploadPassportDocumentHttpPackage,
);

export const selectUploadEmploymentDocumentsHttpPackage = createSelector(
	selectTakeOnState,
	getUploadEmploymentDocumentsHttpPackage,
);

export const selectUploadQualificationDocumentsHttpPackage = createSelector(
	selectTakeOnState,
	getUploadQualificationDocumentsHttpPackage,
);

export const selectUploadProfilePictureHttpPackage = createSelector(
	selectTakeOnState,
	getUploadProfilePictureHttpPackage,
);

export const selectProfileCompletionHttpPackage = createSelector(selectTakeOnState, getProfileCompletionHttpPackage);

export const selectCreateReactivationRequestHttpPackage = createSelector(
	selectTakeOnState,
	getCreateReactivationRequestHttpPackage,
);

export const selectGetReactivationRequestHttpPackage = createSelector(
	selectTakeOnState,
	getReactivationRequestHttpPackage,
);

export const selectGetFlinkCosListHttpPackage = createSelector(selectTakeOnState, getFlinkCosListHttpPackage);
