import { Injectable } from '@angular/core';
import {
	CreateInitialJobApplication,
	CreateJobApplication,
	FavouriteJobAdvert,
	FetchJobAdvertQuestions,
	GetFilteredJobAdverts,
	GetJobAdvertForFlinker,
} from '@root-store/flinker/jobs/action';
import { JobApplicationService } from '@app/services/job-application/job-application.service';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { of } from 'rxjs';
import { FlinkerJobsAction } from '@root-store/flinker/jobs/index';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { JobAdvertService } from '@app/services/job-adverts/job-advert.service';
import QuestionResponse from '@models/dto/responses/question-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';

@Injectable()
export class FlinkerJobsEffects {
	constructor(
		private actions$: Actions,
		private jobApplicationService: JobApplicationService,
		private jobAdvertService: JobAdvertService,
	) {}

	createJobApplicationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.CreateJobApplication>(FlinkerJobsAction.ActionTypes.CREATE_JOB_APPLICATION),
			switchMap((action: CreateJobApplication) =>
				this.jobApplicationService
					.createJobApplication(action.payload.jobAdvertId, action.payload.createJobApplicationDto)
					.pipe(
						map((result: JobApplicationResponse) => new FlinkerJobsAction.CreateJobApplicationSuccess({ result })),
						catchError((error: ErrorResponse) =>
							of(new FlinkerJobsAction.CreateJobApplicationError({ errorResponse: error })),
						),
					),
			),
		);
	});

	createInitialJobApplicationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.CreateInitialJobApplication>(
				FlinkerJobsAction.ActionTypes.CREATE_INITIAL_JOB_APPLICATION,
			),
			switchMap((action: CreateInitialJobApplication) =>
				this.jobApplicationService.createInitialJobApplication(action.payload.jobAdvertId).pipe(
					map((result: JobApplicationResponse) => new FlinkerJobsAction.CreateInitialJobApplicationSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkerJobsAction.CreateInitialJobApplicationError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getJobAdvertQuestions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.FetchJobAdvertQuestions>(FlinkerJobsAction.ActionTypes.FETCH_JOB_ADVERT_QUESTIONS),
			switchMap((action: FetchJobAdvertQuestions) =>
				this.jobAdvertService.getJobAdvertQuestions(action.payload.jobAdvertId).pipe(
					map(
						(result: QuestionAnswerResponseDto[]) => new FlinkerJobsAction.FetchJobAdvertQuestionsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerJobsAction.FetchJobAdvertQuestionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFilteredJbAdverts$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.GetFilteredJobAdverts>(FlinkerJobsAction.ActionTypes.GET_FILTERED_JOB_ADVERTS),
			switchMap((action: GetFilteredJobAdverts) =>
				this.jobAdvertService.getFilteredJobAdverts(action.payload.filteredJobAdvertsDto).pipe(
					map(
						(result: PaginatedResponse<JobAdvertResponseDto>) =>
							new FlinkerJobsAction.GetFilteredJobAdvertsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerJobsAction.GetFilteredJobAdvertsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getJobAdvertForFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.GetJobAdvertForFlinker>(FlinkerJobsAction.ActionTypes.GET_JOB_ADVERT_FOR_FLINKER),
			switchMap((action: GetJobAdvertForFlinker) =>
				this.jobAdvertService.getJobAdvertForFlinker(action.payload.jobAdvertId).pipe(
					map((result: FlinkerJobAdvertResponse) => new FlinkerJobsAction.GetJobAdvertForFlinkerSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkerJobsAction.GetJobAdvertForFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	favouriteJobAdvertEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerJobsAction.FavouriteJobAdvert>(FlinkerJobsAction.ActionTypes.FAVOURITE_JOB_ADVERT),
			switchMap((action: FavouriteJobAdvert) =>
				this.jobAdvertService.favouriteJobAdvert(action.payload.jobAdvertId).pipe(
					map(
						(response: FlinkerJobAdvertResponse) =>
							new FlinkerJobsAction.FavouriteJobAdvertSuccess({ result: response.jobAdvert }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerJobsAction.FavouriteJobAdvertError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
