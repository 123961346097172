<div class="d-flex justify-content-center flex-row m-4 m-md-5 row">
  <app-page-heading class="heading" heading="{{ 'Your CV' }}" [theme]="'pt-4 font-family-2 font-size-14 m11 font-bold w-100'"></app-page-heading>
</div>
<ng-container *ngIf="flinker && !flinker?.psychAssessmentResultId">
  <app-notification-banner>
    <div class="d-flex justify-content-center w-100">
      <p class="front-main-5 font-regular font-size-11 d-none d-md-flex pt-2">
        Please complete your Psychometric Assessment to unlock your CV.
      </p>
    </div>
  </app-notification-banner>
</ng-container>

<div class="container mw-100 mh-100 p-4 p-md-5">
  <mat-card>
    <mat-card-title class="m11 font-bold font-family-2 fs-4 m-4">Template</mat-card-title>
    <div class="d-flex flex-column justify-content-center flex-wrap flex-grow-1 align-content-center">
      <mat-card class="cv-template-page m-4" *ngIf="activeStep === 0">
        <img src="assets/cv/flink-cv-template-example-page-1.webp" alt="flinker-cv-page-1"/>
      </mat-card>
      <mat-card class="cv-template-page m-4" *ngIf="activeStep === 1">
        <img  src="assets/cv/flink-cv-template-example-page-2.webp" alt="flinker-cv-page-2"/>
      </mat-card>
      <mat-card class="cv-template-page m-4" *ngIf="activeStep === 2">
        <img src="assets/cv/flink-cv-template-example-page-3.webp" alt="flinker-cv-page-3"/>
      </mat-card>

      <mat-radio-group class="d-flex justify-content-center">
        <mat-radio-button class="m1" (click)="activeStep = 0" value="1" [checked]="activeStep === 0"></mat-radio-button>
        <mat-radio-button class="m1" (click)="activeStep = 1" value="2" [checked]="activeStep === 2"></mat-radio-button>
        <mat-radio-button class="m1" (click)="activeStep = 2" value="3" [checked]="activeStep === 3"></mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card>
  <div class="d-flex justify-content-center flex-row m-4 m-md-5 row">
    <div class="p-2 m-3 font-bold font-family-2 fs-100 cursor-pointer">
      <a class="m1" [href]="flinkerDashboard">Update Profile</a>
    </div>
    <div class="p-2">
      <app-rounded-button *ngIf="flinker.psychAssessmentResultId" [theme]="'m1'" [value]="'Download my CV'"
                          [loading]="(cvDownloadHttpPackage$ | async).loading || paymentSuccessReceived" [progressDiameter]="20"
                          [disabled]="(payForCvHttPackage$ | async).loading || paymentSuccessReceived" (click)='onDownloadMyCv()'>
      </app-rounded-button>
    </div>
  </div>
</div>
