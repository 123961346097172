import {
	Component,
	Input,
	OnInit,
	forwardRef,
	Output,
	EventEmitter,
	ViewEncapsulation,
	ViewChild,
	ElementRef,
	Renderer2,
	OnDestroy,
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-outlined-input',
	templateUrl: './outlined-input.component.html',
	styleUrls: ['./outlined-input.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedInputComponent),
			multi: true,
		},
	],
})
export class OutlinedInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() placeholder = '';
	@Input() type = 'text';
	@Input() errorMessage = '';
	@Input() labelText = '';
	@Input() value = '';
	@Input() disabled = false;
	@Input() formControlName = '';
	@Input() pattern = '';
	@Input() hasTransformation = false;
	@Input() bottomText = '';
	@Input() maxLength: number = null;

	@Output() onUpdate: EventEmitter<any> = new EventEmitter();

	@ViewChild('inputElement') inputElement: ElementRef;

	required = false;
	localType = '';
	onChange: any = () => {};
	onTouched: any = () => {};

	private destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer, private readonly renderer2: Renderer2) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}

		if (this.hasTransformation) {
			this.controlContainer?.control
				?.get(this.formControlName)
				?.valueChanges?.pipe(takeUntil(this.destroy$))
				?.subscribe(() => {
					if (this.value) {
						this.renderer2.setProperty(this.inputElement.nativeElement, 'value', this.value);
					}
				});
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
		this.onUpdate.emit(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
