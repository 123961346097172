import { Action } from '@ngrx/store';
import CreateSearchRequest from '@models/dto/requests/create-search-request.dto';
import SearchResponse from '@models/dto/responses/search-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import SearchResultResponse from '@models/dto/responses/search-result-response.dto';
import UpdateSearchRequest from '@models/dto/requests/update-search-request.dto';
import SearchFlinkerResponse from '@models/dto/responses/search-flinker-response.dto';
import { AdHocSubscriptionResponse } from '@models/dto/responses/adhoc-subscription-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import { FilterSearchResponse } from '@models/dto/responses/filter-search-response.dto';
import { SearchFilterRequest } from '@models/dto/requests/search-filter-request.dto';

export enum ActionTypes {
	CREATE_SEARCH = '[Search] Create a new search',
	CREATE_SEARCH_SUCCESS = '[Search] Create a new search success',
	CREATE_SEARCH_ERROR = '[Search] Create a new search error',

	UPDATE_SEARCH = '[Search] Update search',
	UPDATE_SEARCH_SUCCESS = '[Search] Update search success',
	UPDATE_SEARCH_ERROR = '[Search] Update search error',

	FETCH_ACTIVE_SEARCHES = '[Search] Fetch active searches',
	FETCH_ACTIVE_SEARCHES_SUCCESS = '[Search] Fetch active searches success',
	FETCH_ACTIVE_SEARCHES_ERROR = '[Search] Fetch active searches error',

	FETCH_ACTIVE_FILTERED_SEARCHES = '[Search] Fetch active filtered searches',
	FETCH_ACTIVE_FILTERED_SEARCHES_SUCCESS = '[Search] Fetch active filtered searches success',
	FETCH_ACTIVE_FILTERED_SEARCHES_ERROR = '[Search] Fetch active filtered searches error',

	FETCH_ACTIVE_SEARCH = '[Search] Fetch active search',
	FETCH_ACTIVE_SEARCH_SUCCESS = '[Search] Fetch active search success',
	FETCH_ACTIVE_SEARCH_ERROR = '[Search] Fetch active search error',

	GET_SEARCH_RESULT = '[Search] Get search result',
	GET_SEARCH_RESULT_SUCCESS = '[Search] Get search result success',
	GET_SEARCH_RESULT_ERROR = '[Search] Get search result error',

	GET_FLINKER_MATCH = '[Search] Get flinker match',
	GET_FLINKER_MATCH_SUCCESS = '[Search] Get flinker match success',
	GET_FLINKER_MATCH_ERROR = '[Search] Get flinker match error',

	PIN_FLINKER_MATCH = '[Search] Pin flinker match',
	PIN_FLINKER_MATCH_SUCCESS = '[Search] Pin flinker match success',
	PIN_FLINKER_MATCH_ERROR = '[Search] Pin flinker match error',

	GET_ADHOC_SUBSCRIPTION = '[Search] Get adhoc subcription',
	GET_ADHOC_SUBSCRIPTION_SUCCESS = '[Search] Get adhoc subcription success',
	GET_ADHOC_SUBSCRIPTION_ERROR = '[Search] Get adhoc subcription error',

	UPDATE_SEARCH_FOR_SCHEDULED_INTERVIEW = '[Search] Update search results for scheduled interview',
	UPDATE_SEARCH_FOR_OFFER_MADE = '[Search] Update search results for offer made',

	SHORTLIST_FLINKER = '[Search] Shortlist flinker',
	SHORTLIST_FLINKER_SUCCESS = '[Search] Shortlist flinker success',
	SHORTLIST_FLINKER_ERROR = '[Search] Shortlist flinker error',

	REMOVE_SHORTLISTED_FLINKER = '[Search] Remove shortlisted flinker',
	REMOVE_SHORTLISTED_FLINKER_SUCCESS = '[Search] Remove shortlisted flinker success',
	REMOVE_SHORTLISTED_FLINKER_ERROR = '[Search] Remove shortlisted flinker error',

	THUMBS_UP_FLINKER = '[Search] Thumbs up flinker',
	THUMBS_UP_FLINKER_SUCCESS = '[Search] Thumbs up flinker success',
	THUMBS_UP_FLINKER_ERROR = '[Search] Thumbs up flinker error',

	THUMBS_DOWN_FLINKER = '[Search] Thumbs down flinker',
	THUMBS_DOWN_FLINKER_SUCCESS = '[Search] Thumbs down flinker success',
	THUMBS_DOWN_FLINKER_ERROR = '[Search] Thumbs down flinker error',
}

export class CreateSearch implements Action {
	readonly type = ActionTypes.CREATE_SEARCH;
	constructor(public payload: { createSearchRequest: CreateSearchRequest }) {}
}

export class CreateSearchSuccess implements Action {
	readonly type = ActionTypes.CREATE_SEARCH_SUCCESS;
	constructor(public payload: { result: SearchResponse }) {}
}

export class CreateSearchError implements Action {
	readonly type = ActionTypes.CREATE_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateSearch implements Action {
	readonly type = ActionTypes.UPDATE_SEARCH;
	constructor(public payload: { searchId: string; updateSearchRequest: UpdateSearchRequest }) {}
}

export class UpdateSearchSuccess implements Action {
	readonly type = ActionTypes.UPDATE_SEARCH_SUCCESS;
	constructor(public payload: { result: SearchResponse }) {}
}

export class UpdateSearchError implements Action {
	readonly type = ActionTypes.UPDATE_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateSearchForScheduledInterview implements Action {
	readonly type = ActionTypes.UPDATE_SEARCH_FOR_SCHEDULED_INTERVIEW;
	constructor(public payload: { flinkerId: string }) {}
}

export class UpdateSearchForOfferMade implements Action {
	readonly type = ActionTypes.UPDATE_SEARCH_FOR_OFFER_MADE;
	constructor(public payload: { flinkerId: string }) {}
}

export class FetchActiveSearches implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCHES;
	constructor() {}
}

export class FetchActiveSearchesSuccess implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCHES_SUCCESS;
	constructor(public payload: { result: SearchResponse[] }) {}
}

export class FetchActiveSearchesError implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCHES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchActiveFilteredSearches implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES;
	constructor(public payload: { searchFilter: SearchFilterRequest }) {}
}

export class FetchActiveFilteredSearchesSuccess implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES_SUCCESS;
	constructor(public payload: { result: FilterSearchResponse }) {}
}

export class FetchActiveFilteredSearchesError implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchActiveSearch implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH;
	constructor(public payload: { searchId: string }) {}
}

export class FetchActiveSearchSuccess implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH_SUCCESS;
	constructor(public payload: { result: SearchResponse }) {}
}

export class FetchActiveSearchError implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetSearchResult implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT;
	constructor(
		public payload: {
			filterFlinkerRequest: FilterFlinkerRequestDto;
		},
	) {}
}

export class GetSearchResultSuccess implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT_SUCCESS;
	constructor(public payload: { result: SearchResultResponse }) {}
}

export class GetSearchResultError implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerMatch implements Action {
	readonly type = ActionTypes.GET_FLINKER_MATCH;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class GetFlinkerMatchSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_MATCH_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class GetFlinkerMatchError implements Action {
	readonly type = ActionTypes.GET_FLINKER_MATCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class PinFlinkerMatch implements Action {
	readonly type = ActionTypes.PIN_FLINKER_MATCH;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class PinFlinkerMatchSuccess implements Action {
	readonly type = ActionTypes.PIN_FLINKER_MATCH_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class PinFlinkerMatchError implements Action {
	readonly type = ActionTypes.PIN_FLINKER_MATCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetAdhocSubscription implements Action {
	readonly type = ActionTypes.GET_ADHOC_SUBSCRIPTION;
	constructor() {}
}

export class GetAdhocSubscriptionSuccess implements Action {
	readonly type = ActionTypes.GET_ADHOC_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: AdHocSubscriptionResponse }) {}
}

export class GetAdhocSubscriptionError implements Action {
	readonly type = ActionTypes.GET_ADHOC_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class ShortlistFlinker implements Action {
	readonly type = ActionTypes.SHORTLIST_FLINKER;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class ShortlistFlinkerSuccess implements Action {
	readonly type = ActionTypes.SHORTLIST_FLINKER_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class ShortlistFlinkerError implements Action {
	readonly type = ActionTypes.SHORTLIST_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class RemoveShortlistedFlinker implements Action {
	readonly type = ActionTypes.REMOVE_SHORTLISTED_FLINKER;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class RemoveShortlistedFlinkerSuccess implements Action {
	readonly type = ActionTypes.REMOVE_SHORTLISTED_FLINKER_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class RemoveShortlistedFlinkerError implements Action {
	readonly type = ActionTypes.REMOVE_SHORTLISTED_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class ThumbsUpFlinker implements Action {
	readonly type = ActionTypes.THUMBS_UP_FLINKER;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class ThumbsUpFlinkerSuccess implements Action {
	readonly type = ActionTypes.THUMBS_UP_FLINKER_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class ThumbsUpFlinkerError implements Action {
	readonly type = ActionTypes.THUMBS_UP_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class ThumbsDownFlinker implements Action {
	readonly type = ActionTypes.THUMBS_DOWN_FLINKER;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class ThumbsDownFlinkerSuccess implements Action {
	readonly type = ActionTypes.THUMBS_DOWN_FLINKER_SUCCESS;
	constructor(public payload: { result: SearchFlinkerResponse }) {}
}

export class ThumbsDownFlinkerError implements Action {
	readonly type = ActionTypes.THUMBS_DOWN_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateSearch
	| CreateSearchSuccess
	| CreateSearchError
	| UpdateSearch
	| UpdateSearchSuccess
	| UpdateSearchError
	| UpdateSearchForScheduledInterview
	| UpdateSearchForOfferMade
	| FetchActiveSearches
	| FetchActiveSearchesSuccess
	| FetchActiveSearchesError
	| FetchActiveSearch
	| FetchActiveSearchSuccess
	| FetchActiveSearchError
	| GetSearchResult
	| GetSearchResultSuccess
	| GetSearchResultError
	| GetFlinkerMatch
	| GetFlinkerMatchSuccess
	| GetFlinkerMatchError
	| PinFlinkerMatch
	| PinFlinkerMatchSuccess
	| PinFlinkerMatchError
	| GetAdhocSubscription
	| GetAdhocSubscriptionSuccess
	| GetAdhocSubscriptionError
	| ShortlistFlinker
	| ShortlistFlinkerSuccess
	| ShortlistFlinkerError
	| RemoveShortlistedFlinker
	| RemoveShortlistedFlinkerSuccess
	| RemoveShortlistedFlinkerError
	| ThumbsUpFlinker
	| ThumbsUpFlinkerSuccess
	| ThumbsUpFlinkerError
	| ThumbsDownFlinker
	| ThumbsDownFlinkerSuccess
	| ThumbsDownFlinkerError
	| FetchActiveFilteredSearches
	| FetchActiveFilteredSearchesSuccess
	| FetchActiveFilteredSearchesError;
