import { Actions, ActionTypes } from './actions';
import { QuoteState, initialState } from './state';

export function quoteReducer(state = initialState, action: Actions): QuoteState {
	switch (action.type) {
		case ActionTypes.GET_RETAINER_QUOTE:
			return {
				...state,
				getRetainerQuoteHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_RETAINER_QUOTE_SUCCESS:
			return {
				...state,
				getRetainerQuoteHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_RETAINER_QUOTE_ERROR:
			return {
				...state,
				getRetainerQuoteHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_AD_HOC_QUOTE:
			return {
				...state,
				getAdHocQuoteHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_AD_HOC_QUOTE_SUCCESS:
			return {
				...state,
				getAdHocQuoteHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_AD_HOC_QUOTE_ERROR:
			return {
				...state,
				getRetainerQuoteHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_ACTIVE_QUOTE:
			return {
				...state,
				getActiveQuoteHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_ACTIVE_QUOTE_SUCCESS:
			return {
				...state,
				getActiveQuoteHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_ACTIVE_QUOTE_ERROR:
			return {
				...state,
				getActiveQuoteHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.GET_ACTIVE_SUBSCRIPTION:
			return {
				...state,
				getActiveSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				getActiveSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR:
			return {
				...state,
				getActiveSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
