import HttpPackage from '@models/http-package';
import { FlinkCoCompanyInformationResponse } from '@models/dto/responses/flinkco-company-information-response.dto';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import FlinkCoResponse from '@models/dto/responses/flink-co-response.dto';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import FlinkCoUser from '@models/dto/flink-co-user.dto';
import RetainerSubscriptionResponse from '@models/dto/responses/retainer-subscription-response.dto';

export interface FlinkCoTakeOnState {
	flinkCo: FlinkCoResponse;
	flinkCoCompanyInformationHttpPackage: HttpPackage<FlinkCoCompanyInformationResponse>;
	uploadCompanyLogoHttpPackage: HttpPackage<string>;
	uploadCIPCCompanyDocumentsHttpPackage: HttpPackage<string>;
	flinkCoSubscriptionHttpPackage: HttpPackage<FlinkCoSubscriptionResponse>;
	flinkCoRetainerInformationHttpPackage: HttpPackage<RetainerInformationResponse>;
	flinkCoAdHocInformationHttpPackage: HttpPackage<AdHocInformationResponse>;
	deleteFlinkCoUserHttpPackage: HttpPackage<string>;
	flinkCoHttpPackage: HttpPackage<FlinkCoResponse>;
	flinkCoTermsAndConditionsPackage: HttpPackage<string>;
	addFlinkCoUsersHttpPackage: HttpPackage<FlinkCoUser[]>;
	getRetainerSubscriptionHttpPackage: HttpPackage<RetainerSubscriptionResponse>;
}

export const initialState: FlinkCoTakeOnState = {
	flinkCo: null,
	flinkCoCompanyInformationHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadCompanyLogoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadCIPCCompanyDocumentsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkCoSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkCoRetainerInformationHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkCoAdHocInformationHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteFlinkCoUserHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkCoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkCoTermsAndConditionsPackage: {
		error: null,
		loading: false,
		result: null,
	},
	addFlinkCoUsersHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getRetainerSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
