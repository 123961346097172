import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import SignInRequest from '@models/dto/requests/sign-in-request.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import { tap } from 'rxjs/operators';
import SignUpRequest from '@models/dto/requests/sign-up-request.dto';
import RefreshTokenRequest from '@models/dto/requests/refresh-token-request.dto';
import RefreshTokenResponse from '@models/dto/responses/refresh-token-response.dto';
import { Router } from '@angular/router';
import SsoRequest from '@models/dto/requests/sso-request.dto';
import ResetPasswordRequestRequest from '@models/dto/requests/reset-password-request-request.dto';
import ResetPasswordRequest from '@models/dto/requests/reset-password-request.dto';
import VerifyOtpRequest from '@models/dto/requests/verify-otp-request.dto';
import { RootStoreState } from '@root-store';
import { Store } from '@ngrx/store';
import SendEmailOtpRequest from '@models/dto/requests/send-email-otp-request.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { NGXLogger } from 'ngx-logger';
import JobApplicationSignUpRequest from '@models/dto/requests/job-application-sign-up-request.dto';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { JobApplicationSignUpResponse } from '@models/dto/responses/job-application-sign-up-response.dto';
import { safeStorage } from '@app/utils/safe-storage';

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	apiAuthEndpoint = 'auth';

	constructor(
		private readonly httpV2Service: HttpV2Service,
		private router: Router,
		private store: Store<RootStoreState.State>,
		private readonly logger: NGXLogger,
	) {
		if (this.canRefresh()) {
			this.stopRefreshTokenTimer();
			this.startRefreshTokenTimer();
		}
	}

	verifyOtp(verifyOtpRequest: VerifyOtpRequest) {
		return this.httpV2Service
			.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/otp/verify`, verifyOtpRequest)
			.pipe(tap((result) => this.setSession(result, true)));
	}

	sendEmailOtp(sendEmailOtpRequest: SendEmailOtpRequest) {
		return this.httpV2Service.postSecure<void>(`${this.apiAuthEndpoint}/otp/email/send`, sendEmailOtpRequest);
	}

	signIn(signInRequest: SignInRequest) {
		return this.httpV2Service.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/signIn`, signInRequest);
	}

	getFlinkerSession() {
		return this.httpV2Service
			.getSecure<SignInResponse>(`${this.apiAuthEndpoint}/loginAsFlinkerSession`)
			.pipe(tap((result) => this.setSession(result, true)));
	}

	getFlinkCoSession() {
		return this.httpV2Service
			.getSecure<SignInResponse>(`${this.apiAuthEndpoint}/loginAsFlinkCoSession`)
			.pipe(tap((result) => this.setSession(result, true)));
	}

	sso(ssoRequest: SsoRequest) {
		return this.httpV2Service
			.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/sso`, ssoRequest)
			.pipe(tap((result) => this.setSession(result, ssoRequest.rememberMe)));
	}

	signUp(signUpRequest: SignUpRequest) {
		return this.httpV2Service.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/signUp`, signUpRequest);
	}

	signUpForJobApplication(signUpRequest: JobApplicationSignUpRequest, jobAdvertId: string) {
		return this.httpV2Service.postSecure<JobApplicationSignUpResponse>(
			`${this.apiAuthEndpoint}/signup/${jobAdvertId}`,
			signUpRequest,
		);
	}

	refresh(refreshTokenRequest: RefreshTokenRequest) {
		return this.httpV2Service
			.postSecure<RefreshTokenResponse>(`${this.apiAuthEndpoint}/refresh`, refreshTokenRequest)
			.pipe(tap((result) => this.setSession(result, refreshTokenRequest.rememberMe)));
	}

	resetPasswordRequest(resetPasswordRequestRequest: ResetPasswordRequestRequest) {
		return this.httpV2Service.postSecure<void>(
			`${this.apiAuthEndpoint}/resetPasswordRequest`,
			resetPasswordRequestRequest,
		);
	}

	resetPassword(resetPasswordRequest: ResetPasswordRequest) {
		return this.httpV2Service.postSecure<void>(`${this.apiAuthEndpoint}/resetPassword`, resetPasswordRequest);
	}

	setPassword(resetPasswordRequest: ResetPasswordRequest) {
		return this.httpV2Service.postSecure<void>(`${this.apiAuthEndpoint}/setPassword`, resetPasswordRequest);
	}

	getReturnUrl(): string {
		return JSON.parse(safeStorage.getItem('returnUrl'));
	}

	setReturnUrl(returnUrl: any): void {
		safeStorage.setItem('returnUrl', JSON.stringify(returnUrl));
	}

	clearReturnUrl(): void {
		safeStorage.removeItem('returnUrl');
	}

	// TODO: integrate logout
	logout(removeCookiePreferences = true) {
		return this.httpV2Service.postSecure<void>(`${this.apiAuthEndpoint}/logout`, {}).pipe(
			tap((result) => {
				safeStorage.removeItem('remember_me');
				safeStorage.removeItem('jwt_token');
				safeStorage.removeItem('jwt_expires_at');
				safeStorage.removeItem('refresh_expires_at');

				if (removeCookiePreferences) {
					safeStorage.removeItem('doNotShowCookiePreferences');
				}

				this.stopRefreshTokenTimer();
				this.router.navigate(['auth/sign-in']);
				location.reload();
			}),
		);
	}

	public setSession(signInResponse: SignInResponse | RefreshTokenResponse, rememberMe: boolean) {
		this.stopRefreshTokenTimer();
		safeStorage.setItem('remember_me', JSON.stringify(rememberMe));
		safeStorage.setItem('jwt_token', signInResponse.jwtToken);
		safeStorage.setItem('jwt_expires_at', JSON.stringify(signInResponse.jwtExpiry.valueOf()));
		safeStorage.setItem('refresh_expires_at', JSON.stringify(signInResponse.refreshExpiry.valueOf()));
		this.startRefreshTokenTimer();
	}

	isLoggedIn() {
		return moment().isBefore(this.getExpiration('jwt_expires_at'));
	}

	canRefresh() {
		return moment().isBefore(this.getExpiration('refresh_expires_at'));
	}

	getExpiration(key: string) {
		const expiration = safeStorage.getItem(key);

		if (!expiration) {
			return moment().subtract(5, 'minute');
		}

		const expiresAt = JSON.parse(expiration ?? '');
		return moment(expiresAt);
	}

	getJwtToken(): string {
		return safeStorage.getItem('jwt_token') ?? '';
	}

	setJwtToken(token: string) {
		safeStorage.setItem('jwt_token', token);
	}

	setExpiration(jwtExpiry: moment.Moment) {
		safeStorage.setItem('jwt_expires_at', JSON.stringify(jwtExpiry.valueOf()));
	}

	getRememberMe(): boolean {
		return JSON.parse(safeStorage.getItem('remember_me') ?? 'false');
	}

	private refreshTokenTimeout: any;

	private startRefreshTokenTimer() {
		const expires = this.getExpiration('refresh_expires_at');
		const sixMinutes: number = 6 * 60 * 1000;
		const timeout = expires.valueOf() - moment().valueOf() - sixMinutes;
		this.logger.debug(
			'Refresh token to start at.',
			moment(new Date().setMilliseconds(new Date().getMilliseconds() + timeout)).toISOString(),
		);
		this.refreshTokenTimeout = setTimeout(() => {
			this.logger.debug('Starting refresh request.');
			this.refresh({ jwtToken: this.getJwtToken(), rememberMe: true }).subscribe(() => {});
			// TODO: Re-add this once session expiry is reworked
			// this.store
			// 	.select(AppStoreSelectors.selectLastRequestDateTime)
			// 	.pipe(take(1))
			// 	.subscribe((lastRequestDateTime) => {
			// 		if (lastRequestDateTime > moment().subtract(5, 'minute')) {
			// 			this.refresh({ jwtToken: this.getJwtToken(), rememberMe: true }).subscribe();
			// 		}
			// 	});
		}, timeout);
	}

	private stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}

	ngOnDestroy() {
		this.stopRefreshTokenTimer();
	}
}
