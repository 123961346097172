import { initialState, OfferState } from './state';
import { Actions, ActionTypes } from './actions';

export function offerReducer(state = initialState, action: Actions): OfferState {
	switch (action.type) {
		case ActionTypes.CREATE_OFFER:
			return {
				...state,
				createOfferHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_OFFER_SUCCESS:
			return {
				...state,
				createOfferHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_OFFER_ERROR:
			return {
				...state,
				createOfferHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_OFFERS:
			return {
				...state,
				getFlinkerOffersHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_OFFERS_SUCCESS:
			return {
				...state,
				getFlinkerOffersHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_OFFERS_ERROR:
			return {
				...state,
				getFlinkerOffersHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_OFFER:
			return {
				...state,
				getFlinkerOfferHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_OFFER_SUCCESS:
			return {
				...state,
				getFlinkerOfferHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_OFFER_ERROR:
			return {
				...state,
				getFlinkerOfferHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFERS:
			return {
				...state,
				getFlinkCoOffersHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFERS_SUCCESS:
			return {
				...state,
				getFlinkCoOffersHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFERS_ERROR:
			return {
				...state,
				getFlinkCoOffersHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFER:
			return {
				...state,
				getFlinkCoOfferHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFER_SUCCESS:
			return {
				...state,
				getFlinkCoOfferHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_OFFER_ERROR:
			return {
				...state,
				getFlinkCoOfferHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_OFFER:
			return {
				...state,
				updateOfferHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_OFFER_SUCCESS:
			return {
				...state,
				updateOfferHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				getFlinkCoOfferHttpPackage: {
					...state.getFlinkCoOfferHttpPackage,
					result: {
						...state.getFlinkCoOfferHttpPackage.result,
						salary: action.payload.result.salary,
						startDate: action.payload.result.startDate,
						position: action.payload.result.position,
						probationPeriod: action.payload.result.probationPeriod,
						benefits: action.payload.result.benefits,
					},
				},
				getFlinkCoOffersHttpPackage: {
					...state.getFlinkCoOffersHttpPackage,
					result: !state.getFlinkCoOffersHttpPackage.result
						? null
						: {
								...state.getFlinkCoOffersHttpPackage.result,
								results: [
									...state.getFlinkCoOffersHttpPackage.result?.results.map((offer) =>
										offer.id === action.payload.result.id
											? {
													...offer,
													salary: action.payload.result.salary,
													startDate: action.payload.result.startDate,
													position: action.payload.result.position,
													probationPeriod: action.payload.result.probationPeriod,
													benefits: action.payload.result.benefits,
											  }
											: offer,
									),
								],
						  },
				},
			};
		case ActionTypes.UPDATE_OFFER_ERROR:
			return {
				...state,
				updateOfferHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_OFFER_STATUS:
			return {
				...state,
				updateOfferStatusHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_OFFER_STATUS_SUCCESS:
			return {
				...state,
				getFlinkerOfferHttpPackage: {
					...state.getFlinkerOfferHttpPackage,
					result: {
						...state.getFlinkerOfferHttpPackage.result,
						offerStatus: action.payload.result.offerStatus,
						rejectionReasonId: action.payload.result?.rejectionReasonId,
					},
				},
				getFlinkerOffersHttpPackage: {
					...state.getFlinkerOffersHttpPackage,
					result: state.getFlinkerOffersHttpPackage.result?.map((offer) =>
						offer.id === action.payload.result.id
							? {
									...offer,
									offerStatus: action.payload.result.offerStatus,
									rejectionReasonId: action.payload.result?.rejectionReasonId,
							  }
							: offer,
					),
				},
				updateOfferStatusHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPDATE_OFFER_STATUS_ERROR:
			return {
				...state,
				updateOfferStatusHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CANCEL_OFFER:
			return {
				...state,
				cancelOfferHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CANCEL_OFFER_SUCCESS:
			return {
				...state,
				cancelOfferHttpPackage: {
					error: null,
					loading: false,
					result: 'Success',
				},
			};
		case ActionTypes.CANCEL_OFFER_ERROR:
			return {
				...state,
				cancelOfferHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_OFFER_DOCUMENTS:
			return {
				...state,
				uploadOfferDocumentsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_OFFER_DOCUMENTS_SUCCESS:
			return {
				...state,
				uploadOfferDocumentsHttpPackage: {
					error: null,
					loading: false,
					result: 'Success',
				},
			};
		case ActionTypes.UPLOAD_OFFER_DOCUMENTS_ERROR:
			return {
				...state,
				uploadOfferDocumentsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_OFFER_DOCUMENTS:
			return {
				...state,
				downloadOfferDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_OFFER_DOCUMENTS_SUCCESS:
			return {
				...state,
				downloadOfferDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_OFFER_DOCUMENTS_ERROR:
			return {
				...state,
				downloadOfferDocumentHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
