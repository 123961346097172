import { Component, Input, OnInit, forwardRef, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SelectOptionResponse } from 'projects/flink-app/src/app/models/dto/responses/select-option-response.dto';

@Component({
	selector: 'app-outlined-dropdown',
	templateUrl: './outlined-dropdown.component.html',
	styleUrls: ['./outlined-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedDropdownComponent),
			multi: true,
		},
	],
})
export class OutlinedDropdownComponent implements OnInit, ControlValueAccessor {
	@Input() errorMessage = '';
	@Input() options: SelectOptionResponse[];
	@Input() labelText = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';
	@Input() disabled = false;
	@Input() formControlName = '';
	@Input() selectedValue!: string;
	@Input() value = '';
	@Input() bottomText = '';
	@Input() toolTip = '';
	@Input() showTip = false;

	required = false;

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: MatSelectChange): void {
		this.value = event.value;
		this.onChange(event.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	clearValue() {
		this.value = '';
		this.onChange('');
	}

	onBlur(): void {
		this.onTouched();
	}
}
