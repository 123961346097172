import { Injectable } from '@angular/core';
import { InterviewQuestionnaireService } from '@app/services/interview-questionnaire/interview-questionnaire.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InterviewQuestionnaireStoreActions } from '@root-store/interview-questionnaire-store/index';
import {
	GetPaginatedInterviewQuestionnaires,
	GetAllDefaultInterviewQuestions,
	GetDefaultInterviewQuestionsByCategory,
	GetInterviewQuestionnaire,
} from '@root-store/interview-questionnaire-store/actions';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import InterviewQuestionnaireResponse from '@models/dto/responses/interview-questionnaire-response.dto';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import { DefaultInterviewQuestionCategoriesResponse } from '@models/dto/responses/default-interview-question-categories-response.dto';
import DefaultInterviewQuestionResponse from '@models/dto/responses/default-interview-question-response.dto';

@Injectable()
export class InterviewQuestionnaireStoreEffects {
	constructor(private actions$: Actions, private interviewQuestionnaireService: InterviewQuestionnaireService) {}

	getPaginatedFlinkCoInterviewQuestionnaires$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES),
			switchMap((action: GetPaginatedInterviewQuestionnaires) =>
				this.interviewQuestionnaireService.getPaginatedInterviewQuestionnaires(
					action.payload.pageSize,
					action.payload.pageNumber,
				),
			),
			map(
				(result: PaginatedResponse<InterviewQuestionnaireResponse>) =>
					new InterviewQuestionnaireStoreActions.GetPaginatedInterviewQuestionnairesSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetPaginatedInterviewQuestionnairesError({ errorResponse })),
			),
		),
	);

	getInterviewQuestionnaire$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_INTERVIEW_QUESTIONNAIRE),
			switchMap((action: GetInterviewQuestionnaire) =>
				this.interviewQuestionnaireService.getInterviewQuestionnaire(action.payload.interviewQuestionnaireId),
			),
			map(
				(result: InterviewQuestionnaireResponse) =>
					new InterviewQuestionnaireStoreActions.GetInterviewQuestionnaireSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetInterviewQuestionnaireError({ errorResponse })),
			),
		),
	);

	getInterviewQuestionCategories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES),
			switchMap(() => this.interviewQuestionnaireService.getInterviewQuestionCategories()),
			map(
				(result: DefaultInterviewQuestionCategoriesResponse) =>
					new InterviewQuestionnaireStoreActions.GetInterviewQuestionCategoriesSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetInterviewQuestionCategoriesError({ errorResponse })),
			),
		),
	);

	getDefaultInterviewQuestionsByCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY),
			switchMap((action: GetDefaultInterviewQuestionsByCategory) =>
				this.interviewQuestionnaireService.getDefaultInterviewQuestionByCategory(action.payload.category),
			),
			map(
				(result: DefaultInterviewQuestionResponse[]) =>
					new InterviewQuestionnaireStoreActions.GetDefaultInterviewQuestionsByCategorySuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetDefaultInterviewQuestionsByCategoryError({ errorResponse })),
			),
		),
	);

	getAllDefaultInterviewQuestions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS),
			switchMap((action: GetAllDefaultInterviewQuestions) =>
				this.interviewQuestionnaireService.getDefaultInterviewQuestionByCategory('all'),
			),
			map(
				(result: DefaultInterviewQuestionResponse[]) =>
					new InterviewQuestionnaireStoreActions.GetAllDefaultInterviewQuestionsSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetAllDefaultInterviewQuestionsError({ errorResponse })),
			),
		),
	);

	createInterviewQuestionnaire$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE),
			switchMap((action: InterviewQuestionnaireStoreActions.CreateInterviewQuestionnaire) =>
				this.interviewQuestionnaireService.createInterviewQuestionnaire(action.payload),
			),
			map(
				(result: InterviewQuestionnaireResponse) =>
					new InterviewQuestionnaireStoreActions.CreateInterviewQuestionnaireSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.CreateInterviewQuestionnaireError({ errorResponse })),
			),
		),
	);

	updateInterviewQuestionnaire$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE),
			switchMap((action: InterviewQuestionnaireStoreActions.UpdateInterviewQuestionnaire) =>
				this.interviewQuestionnaireService.updateInterviewQuestionnaire(
					action.payload.interviewQuestionnaireId,
					action.payload,
				),
			),
			map(
				(result: InterviewQuestionnaireResponse) =>
					new InterviewQuestionnaireStoreActions.UpdateInterviewQuestionnaireSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.UpdateInterviewQuestionnaireError({ errorResponse })),
			),
		),
	);

	deleteInterviewQuestionnaire$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE),
			switchMap((action: InterviewQuestionnaireStoreActions.DeleteInterviewQuestionnaire) =>
				this.interviewQuestionnaireService.deleteInterviewQuestionnaire(action.payload.id),
			),
			map(
				(result: InterviewQuestionnaireResponse) =>
					new InterviewQuestionnaireStoreActions.DeleteInterviewQuestionnaireSuccess({ id: result.id }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.DeleteInterviewQuestionnaireError({ errorResponse })),
			),
		),
	);

	getAllInterviewQuestionnaires$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InterviewQuestionnaireStoreActions.ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES),
			switchMap(() => this.interviewQuestionnaireService.getAllInterviewQuestionnaires()),
			map(
				(result: InterviewQuestionnaireResponse[]) =>
					new InterviewQuestionnaireStoreActions.GetAllInterviewQuestionnairesSuccess({ result }),
			),
			catchError((errorResponse: ErrorResponseV2) =>
				of(new InterviewQuestionnaireStoreActions.GetAllInterviewQuestionnairesError({ errorResponse })),
			),
		),
	);
}
