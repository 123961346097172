import { Pipe, PipeTransform } from '@angular/core';
import { getInterviewStatusName, InterviewStatus } from '@app/enums/interview-status.enum';

@Pipe({
	name: 'interviewStatusName',
})
export class InterviewStatusNamePipe implements PipeTransform {
	transform(value: InterviewStatus, isFlinker: boolean): string {
		return getInterviewStatusName(value, isFlinker);
	}
}
