import { Injectable } from '@angular/core';
import { HttpType, HttpV2Service } from '@app/services/http/http-v2.service';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';

@Injectable({
	providedIn: 'root',
})
export class FlinkCoFlinkerDocumentDownloadService {
	apiEndpoint = 'flinkco/flinker-document';
	canDownloadApiDocumentEndpoint = 'flinkco/flinker-document/can-download';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	downloadDocument(flinkerId: string, searchId: string, documentType: FlinkerDocumentType) {
		return this.httpV2Service.downloadFilePost(`${this.apiEndpoint}`, {
			flinkerId,
			searchId,
			documentType,
		});
	}

	canDownloadDocument(flinkerId: string, searchId: string, documentType: FlinkerDocumentType) {
		return this.httpV2Service.postSecure<{
			canDownload: boolean;
		}>(
			`${this.canDownloadApiDocumentEndpoint}`,
			{
				flinkerId,
				searchId,
				documentType,
			},
			{},
			HttpType.JSON,
		);
	}
}
