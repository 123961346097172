import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FlinkCoJobAdvertsActions, FlinkCoJobAdvertsSelectors } from '@root-store/flinkco/job-adverts';
import { RootStoreState } from '@root-store';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { PageEvent } from '@angular/material/paginator';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { FormControl, FormGroup } from '@angular/forms';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { getSelectOptionIdByCode, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { DictionaryWords } from '@app/enums/dictionary-words.enum';
import { FilterJobAdsRequestDto } from '@models/dto/requests/filter-job-ads-request.dto';
import { listAnimation } from '@shared/helpers/angular-animations.helpers';

@Component({
	selector: 'app-view-flinkco-job-adverts',
	templateUrl: './view-flinkco-job-adverts.component.html',
	styleUrls: ['./view-flinkco-job-adverts.component.scss'],
	animations: [listAnimation],
})
export class ViewFlinkcoJobAdvertsComponent implements OnInit, OnDestroy {
	form: FormGroup;
	fetchFlinkCoJobAdvertsHttpPackage$ = this.store.select(FlinkCoJobAdvertsSelectors.selectCareersPageJobAdverts);
	flinkCoId: string;
	pageSize = environment.pagination.pageSize;
	pageNumber = 0;
	numberOfJobAdverts: number;
	jobAdverts: JobAdvertResponseDto[];
	destroy$: Subject<void> = new Subject<void>();

	getErrorMessage = getErrorMessage;
	selectOptions: SelectOptionsResponse;
	dictionaryWords = DictionaryWords;
	filterJobAdvertsRequest: FilterJobAdsRequestDto;
	shouldValidate = false;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage).pipe(
		takeUntil(this.destroy$),
		filter((response) => !response.loading && response.result != null),
	);
	constructor(private route: ActivatedRoute, private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.form = new FormGroup({
			jobCategory: new FormControl(''),
			placement: new FormControl(''),
			provinces: new FormControl([]),
		});

		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			if (params) {
				this.flinkCoId = params.get('flinkCoId');
				this.getJobAdverts();
			}
		});

		this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
			this.selectOptions = selectOptionsHttpPackage.result;
		});

		this.fetchFlinkCoJobAdvertsHttpPackage$
			.pipe(takeUntil(this.destroy$))
			.subscribe((fetchFlinkCoJobAdvertsHttpPackage) => {
				if (fetchFlinkCoJobAdvertsHttpPackage.result === null) {
					return;
				}

				this.numberOfJobAdverts = fetchFlinkCoJobAdvertsHttpPackage.result.total;
				this.jobAdverts = fetchFlinkCoJobAdvertsHttpPackage.result.results;
			});
	}

	onPaginationEvent(pageEvent: PageEvent): void {
		this.pageSize = pageEvent.pageSize;
		this.pageNumber = pageEvent.pageIndex;
		this.getJobAdverts();
	}

	getJobAdverts() {
		const formValues = this.form.value;

		let provinces: string[] = [];

		formValues.provinces.forEach((province: any) => {
			provinces = provinces.concat(getSelectOptionIdByCode(this.selectOptions?.provinces, province));
		});

		this.filterJobAdvertsRequest = {
			onlyForMe: false,
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			salaryBandLower: '',
			salaryBandUpper: '',
			jobCategory: getSelectOptionIdByCode(this.selectOptions?.jobCategories, formValues.jobCategory) || null,
			placement: getSelectOptionIdByCode(this.selectOptions?.placements, formValues.placement) || null,
			locationIds: provinces.filter((province) => province),
		};
		this.store.dispatch(
			new FlinkCoJobAdvertsActions.FetchCareersPageJobAdverts({
				flinkCoId: this.flinkCoId,
				filterJobAdvertsRequest: this.filterJobAdvertsRequest,
			}),
		);
	}

	clearFilter() {
		this.pageNumber = 0;
		this.filterJobAdvertsRequest = {
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
			jobCategory: null,
			placement: null,
			salaryBandLower: '0',
			salaryBandUpper: '',
			onlyForMe: false,
			locationIds: null,
		};

		this.store.dispatch(
			new FlinkCoJobAdvertsActions.FetchCareersPageJobAdverts({
				flinkCoId: this.flinkCoId,
				filterJobAdvertsRequest: this.filterJobAdvertsRequest,
			}),
		);

		this.form.reset({
			provinces: [],
			jobCategory: '',
			placement: '',
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
