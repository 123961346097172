import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { videoReducer } from '@root-store/video-store/reducer';
import { VideoStoreEffects } from '@root-store/video-store/effects';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('video', videoReducer), EffectsModule.forFeature([VideoStoreEffects])],
	providers: [VideoStoreEffects],
})
export class VideoStoreModule {}
