import { initialState, AppState } from './state';
import { Actions, ActionTypes } from './actions';
import moment from 'moment';

export function appReducer(state = initialState, action: Actions): AppState {
	switch (action.type) {
		case ActionTypes.UPDATE_LAST_REQUEST:
			return {
				...state,
				lastRequestDateTime: moment(),
			};
		default: {
			return state;
		}
	}
}
