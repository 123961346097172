import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Subject, Subscription } from 'rxjs';
import { AccountType } from '@app/enums/account-type.enum';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import { RootStoreState } from '@root-store';
import { FlinkCoTakeOnStoreActions, FlinkCoTakeOnStoreSelectors } from '@root-store/flinkco-take-on-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { AuthService } from '@app/services/auth/auth.service';
import { getErrorMessage } from '@app/utils/form.util';
import * as Sentry from '@sentry/angular';
import { NotificationType } from '@app/enums/notification-type.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { flinkCoUserAccountTypes } from '@app/constants/flink-co-user-account-types.constant';
import { RoleAuthService } from '@app/services/auth/role-auth.service';

@Component({
	selector: 'app-otp-verify',
	templateUrl: './otp-verify.component.html',
	styleUrls: ['./otp-verify.component.scss'],
})
export class OtpVerifyComponent implements OnInit {
	subscriptions = new Subscription();
	email: string;
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	flinkerHttpPackage$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinkerHttpPackage);
	flinkCoHttpPackage$ = this.store.select(FlinkCoTakeOnStoreSelectors.selectGetFlinkCoHttpPackage);
	loading = false;
	shouldValidate = false;

	private readonly destroy$ = new Subject<void>();
	constructor(
		formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private authService: AuthService,
		private router: Router,
		private notifierService: NotifierService,
		private readonly logger: NGXLogger,
		private roleAuth: RoleAuthService,
	) {
		this.form = formBuilder.group({
			otp: ['', [Validators.required]],
		});
	}

	onSubmit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.loading = true;
		this.authService.verifyOtp({ email: this.email, otp: this.form.controls.otp.value }).subscribe(
			(signInResponse: SignInResponse) => {
				this.onSignInSuccess(signInResponse);
			},
			(err: ErrorResponseV2) => {
				this.notifierService.notify(
					NotificationType.Error,
					err.friendly && err.status === 401
						? err.friendlyMessage
						: 'An unknown error occurred while verifying your OTP',
				);
				this.loading = false;
			},
		);
	}

	ngOnInit(): void {
		this.email = history?.state?.email;

		if (!this.email) {
			this.router.navigate(['../sign-in']);
		}

		Sentry.setUser({
			email: this.email,
		});

		this.subscriptions.add(
			this.flinkerHttpPackage$
				.pipe(
					filter((response) => response.result != null && !response.loading),
					takeUntil(this.destroy$),
				)
				.subscribe((flinkerHttpPackage) => {
					if (!flinkerHttpPackage.result.takeOnComplete) {
						this.router.navigate(['/take-on/flinker']);
					} else if (
						flinkerHttpPackage.result.takeOnComplete &&
						flinkerHttpPackage.result.potentialJobApplication !== null
					) {
						this.router.navigate([
							`/flinker-dashboard/job-application/${flinkerHttpPackage.result.potentialJobApplication}`,
						]);
					} else {
						if (this.authService.getReturnUrl()) {
							this.router.navigate([`/${this.authService.getReturnUrl()}`]);
							this.authService.clearReturnUrl();
						} else {
							this.router.navigate(['/flinker-dashboard/profile']);
						}
					}
				}),
		);
		this.subscriptions.add(
			this.flinkCoHttpPackage$
				.pipe(
					filter((response) => response.result != null && !response.loading),
					takeUntil(this.destroy$),
				)
				.subscribe((flinkCoHttpPackage) => {
					console.log(flinkCoHttpPackage.result);
					this.logger.debug('Retrieved the flinkco');
					if (!flinkCoHttpPackage.result.takeOnComplete) {
						this.router.navigate(['/take-on/flinkco']);
					} else {
						if (this.authService.getReturnUrl()) {
							this.logger.debug('Opening return url.');
							this.router.navigate([`/${this.authService.getReturnUrl()}`]);
							this.authService.clearReturnUrl();
						} else {
							if (this.roleAuth.getRole() === AccountType.FlinkCompanyMaster) {
								this.router.navigate(['/flinkco-dashboard/profile']);
							} else {
								this.router.navigate(['/flinkco-dashboard/candidate-search']);
							}
						}
					}
				}),
		);
	}

	onSignInSuccess(signInResponse: SignInResponse): void {
		this.logger.debug('Sign in success', signInResponse);

		if (
			signInResponse.userAccounts.length == 1 &&
			signInResponse.userAccounts[0].account.accountType.name === AccountType.Flinker
		) {
			this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
		} else if (flinkCoUserAccountTypes.includes(signInResponse.userAccounts[0].account.accountType.name)) {
			this.store.dispatch(new FlinkCoTakeOnStoreActions.GetFlinkCo());
		} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkAdmin) {
			this.router.navigate(['/flinkadmin-dashboard']);
		} else {
			this.router.navigate(['/flinkco-dashboard']);
		}

		// TODO: Handle multiple user accounts and other types
	}

	resendOtp(): void {
		this.authService.sendEmailOtp({ email: this.email }).subscribe(
			(_) => {
				this.notifierService.notify(NotificationType.Success, `OTP sent to ${this.email} successfully`);
			},
			() => {
				this.notifierService.notify(
					NotificationType.Error,
					`An unknown error occurred while sending OTP to ${this.email}`,
				);
			},
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
