import { createFeatureSelector, createSelector } from '@ngrx/store';
import ReferenceQuestionsResponse from '../../models/dto/responses/reference-questions-response.dto';
import HttpPackage from '../../models/http-package';
import { ReferenceState } from './state';

const getReferenceQuestionsHttpPackage = (state: ReferenceState): HttpPackage<ReferenceQuestionsResponse> =>
	state.getReferenceQuestionsHttpPackage;

const getSubmitReferenceScoreHttpPackage = (state: ReferenceState): HttpPackage<string> =>
	state.submitReferenceScoreHttpPackage;

export const selectReferenceState = createFeatureSelector<ReferenceState>('reference');

export const selectGetReferenceQuestionsHttpPackage = createSelector(
	selectReferenceState,
	getReferenceQuestionsHttpPackage,
);

export const selectSubmitReferenceScoreHttpPackage = createSelector(
	selectReferenceState,
	getSubmitReferenceScoreHttpPackage,
);
