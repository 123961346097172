import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { QuoteService } from '../../services/quote/quote.service';
import CostResponse from '../../models/dto/responses/cost-response.dto';
import {
	ActionTypes,
	GetActiveQuote,
	GetActiveQuoteError,
	GetActiveQuoteSuccess,
	GetActiveSubscription,
	GetActiveSubscriptionError,
	GetActiveSubscriptionSuccess,
	GetAdHocQuote,
	GetAdHocQuoteError,
	GetAdHocQuoteSuccess,
	GetRetainerQuote,
	GetRetainerQuoteError,
	GetRetainerQuoteSuccess,
} from './actions';
import QuoteResponse from '../../models/dto/responses/quote-response.dto';
import { FlinkCoTakeOnStoreActions } from '../flinkco-take-on-store';
import { QuoteStoreActions } from '.';
import ActiveSubscription from '../../models/dto/responses/active-Subscription-response.dto';

@Injectable()
export class QuoteStoreEffects {
	constructor(private actions$: Actions, private quoteService: QuoteService) {}

	getRetainerQuoteEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<GetRetainerQuote>(ActionTypes.GET_RETAINER_QUOTE),
			switchMap((_: GetRetainerQuote) =>
				this.quoteService.getRetainerQuote().pipe(
					map((result: CostResponse) => new GetRetainerQuoteSuccess({ result })),
					catchError((error: ErrorResponse) => of(new GetRetainerQuoteError({ errorResponse: error }))),
				),
			),
		);
	});

	getAdHocQuoteEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<GetAdHocQuote>(ActionTypes.GET_AD_HOC_QUOTE),
			switchMap((_: GetAdHocQuote) =>
				this.quoteService.getAdHocQuote().pipe(
					map((result: CostResponse) => new GetAdHocQuoteSuccess({ result })),
					catchError((error: ErrorResponse) => of(new GetAdHocQuoteError({ errorResponse: error }))),
				),
			),
		);
	});

	getActiveQuoteEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<GetActiveQuote>(ActionTypes.GET_ACTIVE_QUOTE),
			switchMap((_: GetActiveQuote) =>
				this.quoteService.getActiveQuote().pipe(
					map((result: QuoteResponse) => new GetActiveQuoteSuccess({ result })),
					catchError((error: ErrorResponse) => of(new GetActiveQuoteError({ errorResponse: error }))),
				),
			),
		);
	});

	submitFlinkCoRetainerInformationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoRetainerInformationSuccess>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION_SUCCESS,
			),
			switchMap((action: FlinkCoTakeOnStoreActions.SubmitFlinkCoRetainerInformationSuccess) =>
				of(
					new GetRetainerQuoteSuccess({
						result: action.payload.result.quote,
					}),
				),
			),
		);
	});

	submitFlinkCoAdHocInformationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoTakeOnStoreActions.SubmitFlinkCoAdHocInformationSuccess>(
				FlinkCoTakeOnStoreActions.ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION_SUCCESS,
			),
			switchMap((action: FlinkCoTakeOnStoreActions.SubmitFlinkCoAdHocInformationSuccess) =>
				of(
					new GetAdHocQuoteSuccess({
						result: action.payload.result.quote,
					}),
				),
			),
		);
	});

	getActiveSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<GetActiveSubscription>(ActionTypes.GET_ACTIVE_SUBSCRIPTION),
			switchMap((_) =>
				this.quoteService.getActiveSubscription().pipe(
					map((result: ActiveSubscription) => new GetActiveSubscriptionSuccess({ result })),
					catchError((error: ErrorResponse) => of(new GetActiveSubscriptionError({ errorResponse: error }))),
				),
			),
		);
	});
}
