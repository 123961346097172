<h2 mat-dialog-title class="text-center">Accept offer</h2>
<div mat-dialog-content class="row w-100 px-4 m-0">
	<p class="col-12 text-center">
		You are about to accept an offer, please note that all your other pending interviews and offers will be declined.
	</p>
	<p class="col-12 text-center">
		Additionally, your profile will be deactivated for two years and can be reactivated by request with a valid reason.
	</p>
</div>

<div mat-dialog-actions class="d-flex justify-content-center mt-4 pb-3">
	<app-square-button theme="m5 ml-2" value="Cancel" type="button" (click)="cancel()"> </app-square-button>
	<app-square-button theme="m1" value="Confirm" type="button" (click)="accept()"> </app-square-button>
</div>
