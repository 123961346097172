import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Moment } from 'moment';

@Component({
	selector: 'app-outlined-date-input',
	templateUrl: './outlined-date-input.component.html',
	styleUrls: ['./outlined-date-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedDateInputComponent),
			multi: true,
		},
	],
})
export class OutlinedDateInputComponent implements OnInit, ControlValueAccessor {
	@Input() placeholder = '';
	@Input() errorMessage = '';
	@Input() labelText = '';
	@Input() formControlName = '';

	required = false;
	value: Moment;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: Moment): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
