<label [ngClass]="{ required: required }" class="pt-2">{{ labelText }}</label>
<mat-hint class="pb-2" *ngIf="hint.length > 0">{{ hint }}</mat-hint>
<div cdkDropList class="list" [ngClass]="theme" (cdkDropListDropped)="drop($event)">
  <div class="list-item-box" *ngFor="let item of value; let i = index" cdkDrag>{{ _listMap[item]?.displayName }}
    <button mat-icon-button (click)="remove(i)">
      <mat-icon class="front-main-5 font-size-6">delete</mat-icon>
    </button>
  </div>
</div>
<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage">
</app-input-error-message>
