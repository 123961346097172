import { Injectable } from '@angular/core';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { CreateJobApplicationDto } from '@models/dto/requests/create-job-application.dto';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import { JobApplicationQuestionAnswerResponse } from '@models/dto/responses/job-application-question-answer-response.dto';

@Injectable({
	providedIn: 'root',
})
export class JobApplicationService {
	apiJobApplication = 'job-applications';
	constructor(private httpV2Client: HttpV2Service) {}

	createJobApplication(jobAdvertId: string, createJobApplicationDto: CreateJobApplicationDto) {
		return this.httpV2Client.postSecure<JobApplicationResponse>(
			`${this.apiJobApplication}/${jobAdvertId}`,
			createJobApplicationDto,
		);
	}

	createInitialJobApplication(jobAdvertId: string) {
		return this.httpV2Client.getSecure<JobApplicationResponse>(`${this.apiJobApplication}/initial/${jobAdvertId}`);
	}

	getFlinkCoJobApplication(searchId: string, searchFilterDto: FilterFlinkerRequestDto) {
		return this.httpV2Client.postSecure<JobApplicationResponse[]>(
			`${this.apiJobApplication}/flinkCo/searches/${searchId}`,
			searchFilterDto,
		);
	}

	getJobApplicationQuestionAnswers(flinkerId: string, searchId: string) {
		return this.httpV2Client.getSecure<JobApplicationQuestionAnswerResponse[]>(
			`${this.apiJobApplication}/question-answers/${flinkerId}/${searchId}`,
		);
	}
}
