import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from '../root-store';
import { AppStoreActions } from '../root-store/app-store';
import { AuthService } from '../services/auth/auth.service';
import { safeStorage } from '@app/utils/safe-storage';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService, private store: Store<RootStoreState.State>) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.includes('/auth/refresh')) {
			this.store.dispatch(new AppStoreActions.UpdateLastRequest());
		}

		const cloned = request.clone({
			withCredentials: true,
		});

		const jwtToken = safeStorage.getItem('jwt_token');

		if (jwtToken) {
			const jwtCloned = cloned.clone({
				headers: request.headers.set('Authorization', 'Bearer ' + jwtToken),
			});

			return next.handle(jwtCloned);
		} else {
			return next.handle(cloned);
		}
	}
}
