<button
	mat-button
	type="button"
	class="square-button"
	[type]="type"
	[ngClass]="theme"
	[disabled]="disabled"
	[matTooltip]="tooltip"
  [class.button-disabled]="disabled || loading"
>
  <ng-template #notLoading>
    {{ value }}
  </ng-template>
  <div class="d-flex justify-content-center" *ngIf="loading else notLoading">
    <mat-progress-spinner  mode="indeterminate" class="ml-n2 mr-2"
                           [ngClass]="theme" [diameter]="progressDiameter"
                           customSpinner [circleColor]="'var(--sub-1)'">
    </mat-progress-spinner>{{ value }}
  </div>
</button>
