import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
	selector: 'app-logout-dialog',
	templateUrl: './logout-dialog.component.html',
	styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent implements OnInit {
	constructor(
		private authService: AuthService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<LogoutDialogComponent>,
	) {}

	ngOnInit(): void {}

	logout(): void {
		this.authService.logout().subscribe();
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
