import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SelectOptionsStoreEffects } from './effects';
import { selectOptionsReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('selectOptions', selectOptionsReducer),
		EffectsModule.forFeature([SelectOptionsStoreEffects]),
	],
	providers: [SelectOptionsStoreEffects],
})
export class SelectOptionsStoreModule {}
