<div class="container">
	<div class="row justify-content-center justtify-content-sm-start justify-content-xl-center">
		<mat-button-toggle-group name="Experience" value="industry" [formControl]="experienceControl">
			<mat-button-toggle value="industry">Industry</mat-button-toggle>
			<mat-button-toggle value="job">Job category</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>

<div class="ml-md-1 my-md-5 py-3 py-md-0 chart">
	<canvas
		baseChart
		[data]="experienceControl.value == 'industry' ? industryData : jobCategoryData"
		[labels]="experienceControl.value == 'industry' ? industryLabels : jobCategoryLabels"
		[chartType]="doughnutChartType"
		[height]="250"
		[width]="600"
		[colors]="doughnutChartColors"
		[options]="mobileQuery.matches ? mobileOptions : options"
	>
	</canvas>
</div>
