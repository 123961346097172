import { Action } from '@ngrx/store';
import InterviewStatusRequest from '@models/dto/requests/interview-status-request.dto';
import ScheduleInterviewRequest from '@models/dto/requests/schedule-interview-request.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import InterviewResponse from '@models/dto/responses/interview-response.dto';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';
import InterviewerWithInterviewQuestionsResponse from '@models/dto/responses/interviewer-with-interview-questions-response.dto';
import SaveInterviewerQuestionAnswersRequest from '@models/dto/requests/save-interviewer-question-answers-request.dto';
import InterviewQuestionsScoreTableResponse from '@models/dto/responses/interview-questions-score-table-response.dto';
import FlinkCoInterviewsFilterRequestDto from '@models/dto/requests/flink-co-interviews-filter-request.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

export enum ActionTypes {
	CREATE_INVITE = '[Interview] Create invite',
	CREATE_INVITE_SUCCESS = '[Interview] Create invite success',
	CREATE_INVITE_ERROR = '[Interview] Create invite error',

	GET_FLINKCO_INTERVIEWS = '[Interview] Get flinkco interviews',
	GET_FLINKCO_INTERVIEWS_SUCCESS = '[Interview] Get flinkco interviews success',
	GET_FLINKCO_INTERVIEWS_ERROR = '[Interview] Get flinkco interviews error',

	GET_FLINKER_INTERVIEWS = '[Interview] Get flinker interviews',
	GET_FLINKER_INTERVIEWS_SUCCESS = '[Interview] Get flinker interviews success',
	GET_FLINKER_INTERVIEWS_ERROR = '[Interview] Get flinker interviews error',

	GET_FLINKCO_INTERVIEW = '[Interview] Get flinkco interview',
	GET_FLINKCO_INTERVIEW_SUCCESS = '[Interview] Get flinkco interview success',
	GET_FLINKCO_INTERVIEW_ERROR = '[Interview] Get flinkco interview error',

	GET_FLINKER_INTERVIEW = '[Interview] Get flinker interview',
	GET_FLINKER_INTERVIEW_SUCCESS = '[Interview] Get flinker interview success',
	GET_FLINKER_INTERVIEW_ERROR = '[Interview] Get flinker interview error',

	CANCEL_INTERVIEW = '[Interview] Cancel interview',
	CANCEL_INTERVIEW_SUCCESS = '[Interview] Cancel interview success',
	CANCEL_INTERVIEW_ERROR = '[Interview] Cancel interview error',

	SCHEDULE_INTERVIEW = '[Interview] Schedule interview',
	SCHEDULE_INTERVIEW_SUCCESS = '[Interview] Schedule interview success',
	SCHEDULE_INTERVIEW_ERROR = '[Interview] Schedule interview error',

	UPDATE_INTERVIEW_STATUS = '[Interview] Update interview status',
	UPDATE_INTERVIEW_STATUS_SUCCESS = '[Interview] Update interview status success',
	UPDATE_INTERVIEW_STATUS_ERROR = '[Interview] Update interview status error',

	GET_FLINKER_DOCUMENTS = '[Interview] Get Flinker Documents',
	GET_FLINKER_DOCUMENTS_SUCCESS = '[Interview] Get Flinker Documents Success',
	GET_FLINKER_DOCUMENTS_ERROR = '[Interview] Get Flinker Documents Error',

	DOWNLOAD_FLINKER_CV = '[Interview] Download Flinker CV',
	DOWNLOAD_FLINKER_CV_SUCCESS = '[Interview] Download Flinker CV Success',
	DOWNLOAD_FLINKER_CV_CLEAR_FILE_SUCCESS = '[Interview] Download Flinker CV Clear File on Success',
	DOWNLOAD_FLINKER_CV_ERROR = '[Interview] Download Flinker CV Error',

	UPDATE_INTERVIEW_FOR_OFFER_MADE = '[Interview] Update interview for offer made',

	GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS = '[Interview] Get current interviewer with interview questions',
	GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_SUCCESS = '[Interview] Get current interviewer with interview questions success',
	GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_ERROR = '[Interview] Get current interviewer with interview questions error',

	UPDATE_INTERVIEWER_QUESTION_ANSWERS = '[Interview] Update interviewer question answers',
	UPDATE_INTERVIEWER_QUESTION_ANSWERS_SUCCESS = '[Interview] Update interviewer question answers success',
	UPDATE_INTERVIEWER_QUESTION_ANSWERS_ERROR = '[Interview] Update interviewer question answers error',

	GET_INTERVIEW_QUESTIONS_SCORE_TABLE = '[Interview] Get interview questions score table',
	GET_INTERVIEW_QUESTIONS_SCORE_TABLE_SUCCESS = '[Interview] Get interview questions score table success',
	GET_INTERVIEW_QUESTIONS_SCORE_TABLE_ERROR = '[Interview] Get interview questions score table error',
}

export class CreateInvite implements Action {
	readonly type = ActionTypes.CREATE_INVITE;
	constructor(public payload: { searchId: string; flinkerId: string }) {}
}

export class CreateInviteSuccess implements Action {
	readonly type = ActionTypes.CREATE_INVITE_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CreateInviteError implements Action {
	readonly type = ActionTypes.CREATE_INVITE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoInterviews implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEWS;
	constructor(public payload: FlinkCoInterviewsFilterRequestDto) {}
}

export class GetFlinkCoInterviewsSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEWS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<InterviewResponse> }) {}
}

export class GetFlinkCoInterviewsError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEWS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerInterviews implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEWS;
	constructor() {}
}

export class GetFlinkerInterviewsSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEWS_SUCCESS;
	constructor(public payload: { result: InterviewResponse[] }) {}
}

export class GetFlinkerInterviewsError implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEWS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoInterview implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEW;
	constructor(public payload: { interviewId: string }) {}
}

export class GetFlinkCoInterviewSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEW_SUCCESS;
	constructor(public payload: { result: InterviewResponse }) {}
}

export class GetFlinkCoInterviewError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_INTERVIEW_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerInterview implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEW;
	constructor(public payload: { interviewId: string }) {}
}

export class GetFlinkerInterviewSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEW_SUCCESS;
	constructor(public payload: { result: InterviewResponse }) {}
}

export class GetFlinkerInterviewError implements Action {
	readonly type = ActionTypes.GET_FLINKER_INTERVIEW_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CancelInterview implements Action {
	readonly type = ActionTypes.CANCEL_INTERVIEW;
	constructor(public payload: { interviewId: string; interviewStatusRequest: InterviewStatusRequest }) {}
}

export class CancelInterviewSuccess implements Action {
	readonly type = ActionTypes.CANCEL_INTERVIEW_SUCCESS;
	constructor(
		public payload: { interviewId: string; result: InterviewResponse; interviewStatusRequest: InterviewStatusRequest },
	) {}
}

export class CancelInterviewError implements Action {
	readonly type = ActionTypes.CANCEL_INTERVIEW_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class ScheduleInterview implements Action {
	readonly type = ActionTypes.SCHEDULE_INTERVIEW;
	constructor(public payload: { interviewId: string; scheduleInterviewRequest: ScheduleInterviewRequest }) {}
}

export class ScheduleInterviewSuccess implements Action {
	readonly type = ActionTypes.SCHEDULE_INTERVIEW_SUCCESS;
	constructor(
		public payload: { interviewId: string; result: string; scheduleInterviewRequest: ScheduleInterviewRequest },
	) {}
}

export class ScheduleInterviewError implements Action {
	readonly type = ActionTypes.SCHEDULE_INTERVIEW_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateInterviewStatus implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_STATUS;
	constructor(public payload: { interviewId: string; interviewStatusRequest: InterviewStatusRequest }) {}
}

export class UpdateInterviewStatusSuccess implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_STATUS_SUCCESS;
	constructor(
		public payload: { interviewId: string; result: InterviewResponse; interviewStatusRequest: InterviewStatusRequest },
	) {}
}

export class UpdateInterviewStatusError implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_STATUS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}
export class GetFlinkerDocument implements Action {
	readonly type = ActionTypes.GET_FLINKER_DOCUMENTS;
	constructor(public payload: { interviewId: string; documentId: string; documentType: FlinkerDocumentType }) {}
}
export class GetFlinkerDocumentSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: string }) {}
}
export class GetFlinkerDocumentError implements Action {
	readonly type = ActionTypes.GET_FLINKER_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateInterviewForOfferMade implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_FOR_OFFER_MADE;
	constructor(public payload: { flinkerId: string }) {}
}

export class DownloadFlinkerCv implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV;
	constructor(public payload: { flinkerId: string }) {}
}

export class DownloadFlinkerCvSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_SUCCESS;
	constructor(public payload: { result: any }) {}
}

export class DownloadFlinkerCvClearFileSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_CLEAR_FILE_SUCCESS;
	constructor(public payload: { flinkerId: string }) {}
}

export class DownloadFlinkerCvError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetCurrentInterviewerWithInterviewQuestions implements Action {
	readonly type = ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS;
	constructor(public payload: { interviewId: string }) {}
}

export class GetCurrentInterviewerWithInterviewQuestionsSuccess implements Action {
	readonly type = ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_SUCCESS;
	constructor(public payload: { result: InterviewerWithInterviewQuestionsResponse }) {}
}

export class GetCurrentInterviewerWithInterviewQuestionsError implements Action {
	readonly type = ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateInterviewerQuestionAnswers implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS;
	constructor(public payload: SaveInterviewerQuestionAnswersRequest) {}
}

export class UpdateInterviewerQuestionAnswersSuccess implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS_SUCCESS;
	constructor(public payload: { result: InterviewerWithInterviewQuestionsResponse }) {}
}

export class UpdateInterviewerQuestionAnswersError implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetInterviewQuestionsScoreTable implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE;
	constructor(public payload: { interviewId: string }) {}
}

export class GetInterviewQuestionsScoreTableSuccess implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE_SUCCESS;
	constructor(public payload: { result: InterviewQuestionsScoreTableResponse }) {}
}

export class GetInterviewQuestionsScoreTableError implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateInvite
	| CreateInviteSuccess
	| CreateInviteError
	| GetFlinkCoInterviews
	| GetFlinkCoInterviewsSuccess
	| GetFlinkCoInterviewsError
	| GetFlinkerInterviews
	| GetFlinkerInterviewsSuccess
	| GetFlinkerInterviewsError
	| GetFlinkCoInterview
	| GetFlinkCoInterviewSuccess
	| GetFlinkCoInterviewError
	| GetFlinkerInterview
	| GetFlinkerInterviewSuccess
	| GetFlinkerInterviewError
	| CancelInterview
	| CancelInterviewSuccess
	| CancelInterviewError
	| ScheduleInterview
	| ScheduleInterviewSuccess
	| ScheduleInterviewError
	| UpdateInterviewStatus
	| UpdateInterviewStatusSuccess
	| UpdateInterviewStatusError
	| GetFlinkerDocument
	| GetFlinkerDocumentSuccess
	| GetFlinkerDocumentError
	| UpdateInterviewForOfferMade
	| DownloadFlinkerCv
	| DownloadFlinkerCvSuccess
	| DownloadFlinkerCvClearFileSuccess
	| DownloadFlinkerCvError
	| GetCurrentInterviewerWithInterviewQuestions
	| GetCurrentInterviewerWithInterviewQuestionsSuccess
	| GetCurrentInterviewerWithInterviewQuestionsError
	| UpdateInterviewerQuestionAnswers
	| UpdateInterviewerQuestionAnswersSuccess
	| UpdateInterviewerQuestionAnswersError
	| GetInterviewQuestionsScoreTable
	| GetInterviewQuestionsScoreTableSuccess
	| GetInterviewQuestionsScoreTableError;
