<div class="row justify-content-center d-flex mt-5">
	<figure class="chart col-12 mx-0 d-flex justify-content-center" [attr.data-percent]="completionPercentage" [attr.completed-items]="numberOfCompletedItems" *ngIf="!dontShowProfilePicture">
		<div class="image-container">
			<app-profile-picture
				class="m-auto"
				[src]="(flinker$ | async)?.picture"
				[alt]="(flinker$ | async)?.name + ' ' + (flinker$ | async)?.surname"
				[loading]="(uploadProfilePictureHttpPackage$ | async)?.loading"
				(fileSelected)="uploadProfilePicture($event)"
			>
			</app-profile-picture>
			<div class="gauge-container">
				<svg class="d-flex justify-content-center" [ngClass]="completionLoaderTheme" width="250" height="255">
					<circle
						class="outer d-flex mx-auto justify-content-center"
						cx="79"
						cy="115"
						r="115"
						transform="rotate(-90, 110, 100)"
					/>
				</svg>
			</div>
		</div>
	</figure>

	<mat-list class="d-block col-12 pt-0 px-md-3 px-sm-3 px-xs-0 mt-3">
		<div mat-subheader class="completion-items-header">
			<app-highlighted-text class="pr-1" [theme]="percentCompletionTheme"
				>{{ completionPercentage }}%
			</app-highlighted-text>
			Profile Completion
		</div>
		<mat-list-item *ngFor="let completionItem of completionItems" class="completion-items">
			<mat-icon mat-list-icon *ngIf="completionItem.profileItemComplete === true" class="completed-item"
				>check_circle
			</mat-icon>
			<mat-icon mat-list-icon *ngIf="completionItem.profileItemComplete === false" class="incomplete-item"
				>cancel
			</mat-icon>
			<div mat-line>{{ completionItem.profileItemName }}</div>
		</mat-list-item>
	</mat-list>
</div>
