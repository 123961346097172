import { Injectable } from '@angular/core';
import { FlinkCoCompanyInformationRequest } from '@models/dto/requests/flink-co-company-information-request.dto';
import { FlinkCoCompanyInformationResponse } from '@models/dto/responses/flinkco-company-information-response.dto';
import { FlinkCoSubscriptionRequest } from '@models/dto/requests/flink-co-subscription-request';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import FlinkCoTermsAndConditionsRequest from '@models/dto/requests/flink-co-terms-and-conditions-request.dto';
import { RetainerInformationRequest } from '@models/dto/requests/retainer-information-request.dto';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import { AdHocInformationRequest } from '@models/dto/requests/ad-hoc-information-request.dto';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import RetainerSubscriptionResponse from '@models/dto/responses/retainer-subscription-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class FlinkCoTakeOnService {
	apiFlinkCoEndpoint = 'flinkco';
	apiSubscriptionsEndpoint = 'subscriptions';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	submitCompanyInformation(flinkcoCompanyInformationRequest: FlinkCoCompanyInformationRequest) {
		return this.httpV2Service.postSecure<FlinkCoCompanyInformationResponse>(
			`${this.apiFlinkCoEndpoint}/companyInformation`,
			flinkcoCompanyInformationRequest,
		);
	}

	uploadCompanyLogo(formData: FormData) {
		return this.httpV2Service.postFormSecure<string>(`${this.apiFlinkCoEndpoint}/upload/logo`, formData, {
			responseType: 'text' as 'json',
		});
	}

	uploadCIPCDocuments(formData: FormData) {
		return this.httpV2Service.postFormSecure(`${this.apiFlinkCoEndpoint}/upload/cipcDocumentation`, formData);
	}

	submitSubscriptionChoice(flinkCoSubscriptionRequest: FlinkCoSubscriptionRequest) {
		return this.httpV2Service.postSecure<FlinkCoSubscriptionResponse>(
			`${this.apiSubscriptionsEndpoint}/subscription`,
			flinkCoSubscriptionRequest,
		);
	}

	submitRetainerInformation(retainerInformationRequest: RetainerInformationRequest) {
		return this.httpV2Service.postSecure<RetainerInformationResponse>(
			`${this.apiSubscriptionsEndpoint}/retainerInformation`,
			retainerInformationRequest,
		);
	}

	submitAdHocInformation(adHocInformation: AdHocInformationRequest) {
		return this.httpV2Service.postSecure<AdHocInformationResponse>(
			`${this.apiSubscriptionsEndpoint}/adHocInformation`,
			adHocInformation,
		);
	}

	deleteFlinkCoUser(userId: string) {
		return this.httpV2Service.deleteSecure(`${this.apiFlinkCoEndpoint}/user/${userId}`);
	}

	submitTermsAndConditions(flinkCoTermsAndConditionsRequest: FlinkCoTermsAndConditionsRequest) {
		return this.httpV2Service.postSecure<void>(
			`${this.apiFlinkCoEndpoint}/termsAndConditions`,
			flinkCoTermsAndConditionsRequest,
		);
	}

	getRetainerSubscription() {
		return this.httpV2Service.getSecure<RetainerSubscriptionResponse>(
			`${this.apiSubscriptionsEndpoint}/RetainerSubscription`,
		);
	}
}
