import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VideoService } from '@app/services/video/video.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { of } from 'rxjs';
import { VideoStoreActions } from '@root-store/video-store/index';
import {
	CreateVideoRoom,
	DeleteCompositionRecording,
	DeleteVideoRecording,
	GetVideoComposition,
	GetVideoRecording,
} from '@root-store/video-store/actions';
import { VideoUrlResponse } from '@models/dto/responses/video-url-response.dto';

@Injectable()
export class VideoStoreEffects {
	constructor(private actions$: Actions, private videoService: VideoService) {}

	createVideoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<VideoStoreActions.CreateVideoRoom>(VideoStoreActions.ActionTypes.CREATE_VIDEO_ROOM),
			switchMap((action: CreateVideoRoom) =>
				this.videoService.createVideoRoom(action.payload.videoQuestion).pipe(
					map((result: string) => new VideoStoreActions.CreateVideoRoomSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new VideoStoreActions.CreateVideoRoomError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getVideoCompositionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<VideoStoreActions.GetVideoComposition>(VideoStoreActions.ActionTypes.GET_VIDEO_COMPOSITION),
			switchMap((action: GetVideoComposition) =>
				this.videoService.getVideoComposition(action.payload.participantSID, action.payload.roomSID).pipe(
					map((result: string) => new VideoStoreActions.GetVideoCompositionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new VideoStoreActions.GetVideoCompositionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	deleteVideoRecording = createEffect(() => {
		return this.actions$.pipe(
			ofType<VideoStoreActions.DeleteVideoRecording>(VideoStoreActions.ActionTypes.DELETE_VIDEO_RECORDING),
			switchMap((action: DeleteVideoRecording) =>
				this.videoService.deletVideoRecoding(action.payload.roomSID).pipe(
					map((_) => new VideoStoreActions.DeleteVideoRecordingSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new VideoStoreActions.DeleteVideoRecordingError({ errorResponse: error })),
					),
				),
			),
		);
	});

	deleteCompositionRecording = createEffect(() => {
		return this.actions$.pipe(
			ofType<VideoStoreActions.DeleteCompositionRecording>(VideoStoreActions.ActionTypes.DELETE_COMPOSITION_RECORDING),
			switchMap((action: DeleteCompositionRecording) =>
				this.videoService.deleteCompositionRecording(action.payload.roomSID, action.payload.compositionSID).pipe(
					map((_) => new VideoStoreActions.DeleteCompositionRecordingSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new VideoStoreActions.DeleteCompositionRecordingError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getVideoRecordingEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<VideoStoreActions.GetVideoRecording>(VideoStoreActions.ActionTypes.GET_VIDEO_RECORDING),
			switchMap((action: GetVideoRecording) =>
				this.videoService.getVideoRecording(action.payload.compositionSIDs).pipe(
					map((result: VideoUrlResponse[]) => new VideoStoreActions.GetVideoRecordingSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new VideoStoreActions.GetVideoRecordingError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
