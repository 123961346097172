import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PsychAssessmentCompletionStatusActions } from '.';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { PsychAssessmentCompletionStatusResponse } from '../../models/dto/responses/psych-assessment-completion-status-response.dtp';
import { PsychAssessmentService } from '../../services/psych-assessment/psych-assessment.service';

@Injectable()
export class PsychAssessmentEffects {
	constructor(private actions$: Actions, private psychAssessmentService: PsychAssessmentService) {}

	getPsychAssessmentCompletionStatusEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<PsychAssessmentCompletionStatusActions.GetPsychAssessmentCompletionStatus>(
				PsychAssessmentCompletionStatusActions.ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS,
			),
			switchMap(() =>
				this.psychAssessmentService.getPsychAssessmentCompletionStatus().pipe(
					map(
						(result: PsychAssessmentCompletionStatusResponse) =>
							new PsychAssessmentCompletionStatusActions.GetPsychAssessmentCompletionStatusSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(
							new PsychAssessmentCompletionStatusActions.GetPsychAssessmentCompletionStatusError({
								errorResponse: error,
							}),
						),
					),
				),
			),
		);
	});
}
