<div class="image-container d-flex" [ngClass]="round ? '' : 'p-2'">
	<ngx-avatar
		class="user-profile-picture mx-auto"
		[ngClass]="theme"
		[size]="size"
		[name]="alt"
		[src]="src"
		[round]="round"
		[cornerRadius]="round ? roundCornerRadius : squareCornerRadius"
    [initialsSize]="maxInitialCount"
	>
	</ngx-avatar>
</div>
