import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ExperienceComponent } from 'projects/flink-app/src/app/common/flinker/experience/experience.component';
import { Observable } from 'rxjs';
import HttpPackage from '@models/http-package';

@Component({
	selector: 'app-profile-experience',
	templateUrl: './experience.component.html',
	styleUrls: ['./experience.component.scss'],
})
export class ProfileExperienceComponent implements OnInit {
	editState = false;

	@ViewChild(ExperienceComponent) experienceComponent: ExperienceComponent;

	constructor(private notifierService: NotifierService) {}

	ngOnInit(): void {}

	submit(): void {
		this.experienceComponent.submit();
	}

	get formValid(): boolean {
		return this.experienceComponent?.form?.valid;
	}

	get saveLoadingObservable(): Observable<HttpPackage<any>> {
		return this.experienceComponent?.flinkerEmploymentDetailsHttpPackage$;
	}

	toggleEditState() {
		this.editState = !this.editState;
	}
}
