<app-outlined-input
	*ngIf="showInput"
	labelText="{{ labelText }}"
	type="number"
	value="{{ sliderValue }}"
	[disabled]="disabled"
	(onUpdate)="updateSlider($event)"
></app-outlined-input>
<div *ngIf="!showInput" class="d-flex">
	<mat-label class="pb-2">{{ labelText }}</mat-label>
</div>
<ngx-slider [(value)]="sliderValue" [options]="options" (valueChange)="valueChange()"></ngx-slider>
