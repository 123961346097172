import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuoteStoreEffects } from './effects';
import { quoteReducer } from './reducer';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('quote', quoteReducer), EffectsModule.forFeature([QuoteStoreEffects])],
	providers: [QuoteStoreEffects],
})
export class QuoteStoreModule {}
