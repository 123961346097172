import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { connect, LocalParticipant, LocalTrackPublication, LocalTrack, LocalDataTrack, Room } from 'twilio-video';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { VideoStoreActions, VideoStoreSelectors } from '@root-store/video-store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

@Component({
	selector: 'app-video-recorder',
	templateUrl: './video-recorder.component.html',
	styleUrls: ['./video-recorder.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VideoRecorderComponent),
			multi: true,
		},
	],
})
export class VideoRecorderComponent implements OnInit, OnDestroy, ControlValueAccessor {
	token: string;
	participant: LocalParticipant;
	room: Room;
	roomJoined = false;
	required = true;
	connectionLoading = false;
	responseLoading = false;
	participantSID: string;
	compositionSID: string;
	recordingTimedOut = false;
	roomSID: string;
	flinker: FlinkerResponse;
	value: string;
	roomName: string;
	selectCreateVideoRoomHttpPackage$ = this.store.select(VideoStoreSelectors.selectCreateVideoRoomHttpPackage);
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	selectGetVideoCompositionHttpPackage$ = this.store.select(VideoStoreSelectors.selectGetVideoCompositionHttpPackage);
	selectDeleteVideoRecordingHttpPackage$ = this.store.select(VideoStoreSelectors.selectDeleteVideoRecordingHttpPackage);
	selectDeleteCompositionRecordingHttpPackage$ = this.store.select(
		VideoStoreSelectors.selectDeleteCompositionRecordingHttpPackage,
	);

	leaveRoomTimeout: ReturnType<typeof setTimeout>;
	readonly maxRecordingDuration = 850 * 1000;

	@ViewChild('videoContainer') videoPlayer: ElementRef;
	@Output() composition: EventEmitter<any> = new EventEmitter();
	@Input() labelText = '';
	@Input() questionId = '';
	@Input() formControlName = '';
	@Input() existingRecording: string;

	onChange: any = () => {};
	onTouched: any = () => {};

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(
		private readonly renderer: Renderer2,
		private readonly store: Store<RootStoreState.State>,
		private readonly notifierService: NotifierService,
		private readonly logger: NGXLogger,
	) {}

	ngOnInit(): void {
		this.flinker$.pipe(takeUntil(this.destroy$)).subscribe((flinker) => {
			this.flinker = flinker;
		});
		this.compositionSID = this.existingRecording;
	}

	async onJoinRoom() {
		this.connectionLoading = true;
		this.roomJoined = true;
		if (!this.token) {
			this.store.dispatch(new VideoStoreActions.CreateVideoRoom({ videoQuestion: this.questionId }));
			this.responseLoading = true;
		} else {
			await this.connectToRoom();
		}

		this.selectCreateVideoRoomHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe(
				(selectCreateVideoRoomHttpPackage) => {
					if (!this.token) {
						this.token = selectCreateVideoRoomHttpPackage.result;
						this.connectToRoom();
					}
					this.responseLoading = false;
				},
				(error) => {
					this.connectionLoading = false;
				},
			);
		this.onTouched();
	}

	async connectToRoom() {
		this.roomName = `${this.flinker.id}|${this.questionId}`;

		this.room = await connect(this.token, {
			name: this.roomName,
		});
		this.roomSID = this.room.sid;

		this.handleConnectedParticipant(this.room.localParticipant);

		this.clearLeaveRoomTimeout();

		this.leaveRoomTimeout = setTimeout(() => {
			this.leaveRoom(true);
		}, this.maxRecordingDuration);
	}

	leaveRoom(fromTimeOut?: boolean) {
		this.roomJoined = false;
		this.store.dispatch(
			new VideoStoreActions.GetVideoComposition({ participantSID: this.participantSID, roomSID: this.roomSID }),
		);
		this.responseLoading = true;

		this.selectGetVideoCompositionHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe(
				(selectGetVideoCompositionHttpPackage) => {
					this.compositionSID = selectGetVideoCompositionHttpPackage.result;
					this.input(this.compositionSID);
					this.responseLoading = false;
					this.clearLeaveRoomTimeout(); // Clear timeout so that it doesn't try to get the composition again.
				},
				(error) => {
					this.reset();
					this.responseLoading = false;
					this.connectionLoading = false;
				},
			);

		this.selectGetVideoCompositionHttpPackage$
			.pipe(
				filter((response) => !response.loading && (response.result != null || response.error != null)),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe((response) => {
				this.reset();
				this.responseLoading = false;
				this.connectionLoading = false;
				if (response.error) {
					this.logger.error('Failed to leave room and get video composition. Resetting connection states.');
				}
			});

		this.participant.tracks.forEach((publication) => this.removeTracks(publication.track));
		this.participant = null;
		this.room = this.room.disconnect();

		if (fromTimeOut) {
			this.recordingTimedOut = true;
		}
	}

	cancelRecording() {
		this.roomJoined = false;
		this.participant.tracks.forEach((publication) => this.removeTracks(publication.track));
		this.room = this.room.disconnect();
		this.participant = null;
		this.store.dispatch(new VideoStoreActions.DeleteVideoRecording({ roomSID: this.roomSID }));
		this.responseLoading = true;
		this.input(null);

		this.selectDeleteVideoRecordingHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe(
				() => {
					this.notifierService.notify(
						NotificationType.Success,
						'Successfully deleted the recording, please try again.',
					);
					this.responseLoading = false;
					this.connectionLoading = false;

					this.reset();
				},
				() => {
					this.reset();
					this.responseLoading = false;
					this.connectionLoading = false;
				},
			);

		this.selectDeleteVideoRecordingHttpPackage$
			.pipe(
				filter((response) => !response.loading && (response.result != null || response.error != null)),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe((response) => {
				this.reset();
				this.responseLoading = false;
				this.connectionLoading = false;
				if (response.error) {
					this.logger.error('Failed to delete video recording. Resetting connection states.');
				}
			});
	}

	reRecordRoom() {
		if (!this.roomSID) {
			this.notifierService.notify(NotificationType.Success, 'Ready to re-record your video.');
			this.compositionSID = null;
			return;
		}

		this.store.dispatch(
			new VideoStoreActions.DeleteCompositionRecording({ roomSID: this.roomSID, compositionSID: this.compositionSID }),
		);
		this.responseLoading = true;
		this.selectDeleteCompositionRecordingHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe(
				() => {
					this.notifierService.notify(NotificationType.Success, 'Ready to re-record your video.');
					this.responseLoading = false;
					this.compositionSID = null;
					this.reset();
				},
				(error) => {
					this.reset();
					this.responseLoading = false;
				},
			);

		this.selectDeleteCompositionRecordingHttpPackage$
			.pipe(
				filter((response) => !response.loading && (response.result != null || response.error != null)),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe((response) => {
				this.reset();
				this.responseLoading = false;
				if (response.error) {
					this.logger.error('Failed to delete video composition for re-recording. Resetting connection states.');
				}
			});
	}

	handleConnectedParticipant(participant: LocalParticipant) {
		this.connectionLoading = true;
		this.participant = participant;
		this.participantSID = participant.sid;
		this.handleTrackPublication(participant);
	}

	handleTrackPublication(participant: LocalParticipant) {
		this.connectionLoading = false;
		if (participant) {
			participant.tracks.forEach((publication) => this.subscribeTolPublication(publication));
			participant.on('trackPublished', (publication) => this.subscribeTolPublication(publication));
			participant.on('trackUnpublished', (publication) => {
				if (publication && publication.track) {
					this.removeTracks(publication.track);
				}
			});
		}
	}

	displayTrack(track: LocalTrack) {
		if (!(track instanceof LocalDataTrack)) {
			const element = track.attach();
			this.renderer.data.id = 'video-recorder';
			this.renderer.setStyle(element, 'width', '40%');
			this.renderer.setStyle(element, 'margin-left', '2.5%');
			this.renderer.appendChild(this.videoPlayer.nativeElement, element);
		}
	}

	removeTracks(track: LocalTrack) {
		if (!(track instanceof LocalDataTrack)) {
			track.detach().forEach((el) => el.remove());
		}
	}

	private subscribeTolPublication(publication: LocalTrackPublication) {
		if (publication && publication.on) {
			this.displayTrack(publication.track);
			publication.on('subscribed', (track: LocalTrack) => this.displayTrack(track));
			publication.on('unsubscribed', (track: LocalTrack) => this.removeTracks(track));
		}
	}

	private clearLeaveRoomTimeout() {
		if (this.leaveRoomTimeout) {
			clearTimeout(this.leaveRoomTimeout);
		}
	}

	private reset() {
		this.recordingTimedOut = false;
		this.clearLeaveRoomTimeout();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: string): void {
		this.onChange(event);
		this.composition.emit(event);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
