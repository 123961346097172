import { Action } from '@ngrx/store';
import FlinkerCriminalDeclaration from '../../models/dto/flinker-criminal-declaration.dto';
import CreateReactivationRequestDto from '../../models/dto/requests/create-reactivation-request.dto';
import FlinkerEducationalDetailsRequest from '../../models/dto/requests/flinker-educational-details-request.dto';
import FlinkerEmploymentDetailsRequest from '../../models/dto/requests/flinker-employment-details-request.dto';
import FlinkerPersonalDetailsRequest from '../../models/dto/requests/flinker-personal-details-request.dto';
import FlinkerTermsAndConditionsRequest from '../../models/dto/requests/flinker-terms-and-conditions-request.dto';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { FlinkCoNamesResponse } from '../../models/dto/responses/flinkCo-names-response.dto';
import FlinkerResponse from '../../models/dto/responses/flinker-response.dto';
import ProfileCompletionItem from '../../models/dto/responses/profile-completion-item.dto';
import ReactivationRequestResponse from '../../models/dto/responses/reactivation-request-response.dto';

export enum ActionTypes {
	UPDATE_FLINKER = '[FlinkerTakeOn] Update Flinker',

	GET_FLINKER = '[FlinkerTakeOn] Get Flinker',
	GET_FLINKER_SUCCESS = '[FlinkerTakeOn] Get Flinker success',
	GET_FLINKER_ERROR = '[FlinkerTakeOn] Get Flinker error',

	SUBMIT_FLINKER_PERSONAL_DETAILS = '[FlinkerTakeOn] Submit Flinker personal details',
	SUBMIT_FLINKER_PERSONAL_DETAILS_SUCCESS = '[FlinkerTakeOn] Submit Flinker personal details success',
	SUBMIT_FLINKER_PERSONAL_DETAILS_ERROR = '[FlinkerTakeOn] Submit Flinker personal details error',

	SUBMIT_FLINKER_EMPLOYMENT_DETAILS = '[FlinkerTakeOn] Submit Flinker employment details',
	SUBMIT_FLINKER_EMPLOYMENT_DETAILS_SUCCESS = '[FlinkerTakeOn] Submit Flinker employment details success',
	SUBMIT_FLINKER_EMPLOYMENT_DETAILS_ERROR = '[FlinkerTakeOn] Submit Flinker employment details error',

	SUBMIT_FLINKER_EDUCATIONAL_DETAILS = '[FlinkerTakeOn] Submit Flinker educational details',
	SUBMIT_FLINKER_EDUCATIONAL_DETAILS_SUCCESS = '[FlinkerTakeOn] Submit Flinker educational details success',
	SUBMIT_FLINKER_EDUCATIONAL_DETAILS_ERROR = '[FlinkerTakeOn] Submit Flinker educational details error',

	SUBMIT_FLINKER_CRIMINAL_DECLARATION = '[FlinkerTakeOn] Submit Flinker criminal declaration',
	SUBMIT_FLINKER_CRIMINAL_DECLARATION_SUCCESS = '[FlinkerTakeOn] Submit Flinker criminal declaration success',
	SUBMIT_FLINKER_CRIMINAL_DECLARATION_ERROR = '[FlinkerTakeOn] Submit Flinker criminal declaration error',

	SUBMIT_FLINKER_TERMS_AND_CONDITIONS = '[FlinkerTakeOn] Submit Flinker terms and conditions',
	SUBMIT_FLINKER_TERMS_AND_CONDITIONS_SUCCESS = '[FlinkerTakeOn] Submit Flinker terms and conditions success',
	SUBMIT_FLINKER_TERMS_AND_CONDITIONS_ERROR = '[FlinkerTakeOn] Submit Flinker terms and conditions error',

	UPLOAD_ID_DOCUMENT = '[FlinkerTakeOn] Upload id document',
	UPLOAD_ID_DOCUMENT_SUCCESS = '[FlinkerTakeOn] Upload id document success',
	UPLOAD_ID_DOCUMENT_ERROR = '[FlinkerTakeOn] Upload id document error',

	UPLOAD_PASSPORT_DOCUMENT = '[FlinkerTakeOn] Upload passport document',
	UPLOAD_PASSPORT_DOCUMENT_SUCCESS = '[FlinkerTakeOn] Upload passport document success',
	UPLOAD_PASSPORT_DOCUMENT_ERROR = '[FlinkerTakeOn] Upload passport document error',

	UPLOAD_EMPLOYMENT_DOCUMENTS = '[FlinkerTakeOn] Upload employment documents',
	UPLOAD_EMPLOYMENT_DOCUMENTS_SUCCESS = '[FlinkerTakeOn] Upload employment documents success',
	UPLOAD_EMPLOYMENT_DOCUMENTS_ERROR = '[FlinkerTakeOn] Upload employment documents error',

	UPLOAD_QUALIFICATION_DOCUMENTS = '[FlinkerTakeOn] Upload qualification documents',
	UPLOAD_QUALIFICATION_DOCUMENTS_SUCCESS = '[FlinkerTakeOn] Upload qualification documents success',
	UPLOAD_QUALIFICATION_DOCUMENTS_ERROR = '[FlinkerTakeOn] Upload qualification documents error',

	UPLOAD_PROFILE_PICTURE = '[FlinkerTakeOn] Upload profile picture',
	UPLOAD_PROFILE_PICTURE_SUCCESS = '[FlinkerTakeOn] Upload profile picture success',
	UPLOAD_PROFILE_PICTURE_ERROR = '[FlinkerTakeOn] Upload profile picture error',

	GET_PROFILE_COMPLETION = '[FlinkerTakeOn] Get profile completion',
	GET_PROFILE_COMPLETION_SUCCESS = '[FlinkerTakeOn] Get profile completion success',
	GET_PROFILE_COMPLETION_ERROR = '[FlinkerTakeOn] Get profile completion error',

	CREATE_REACTIVATION_REQUEST = '[FlinkerTakeOn] Create reactication request',
	CREATE_REACTIVATION_REQUEST_SUCCESS = '[FlinkerTakeOn] Create reactication request success',
	CREATE_REACTIVATION_REQUEST_ERROR = '[FlinkerTakeOn] Create reactication request error',

	GET_REACTIVATION_REQUEST = '[FlinkerTakeOn] Get reactication request',
	GET_REACTIVATION_REQUEST_SUCCESS = '[FlinkerTakeOn] Get reactication request success',
	GET_REACTIVATION_REQUEST_ERROR = '[FlinkerTakeOn] Get reactication request error',

	GET_FLINKCO_LIST = '[FlinkerTakeOn] Get flinkCo list',
	GET_FLINKCO_LIST_SUCCESS = '[FlinkerTakeOn] Get flinkCo list success',
	GET_FLINKCO_LIST_ERROR = '[FlinkerTakeOn] Get flinkCo list error',

	SET_FLINKER_CV_PROMOTION_DATE = '[FlinkerTakeOn] Set Flinker CV promotion date',
	SET_FLINKER_CV_PROMOTION_DATE_SUCCESS = '[FlinkerTakeOn] Set Flinker CV promotion date success',
	SET_FLINKER_CV_PROMOTION_DATE_ERROR = '[FlinkerTakeOn] Set Flinker CV promotion date error',
}

export class UpdateFlinker implements Action {
	readonly type = ActionTypes.UPDATE_FLINKER;
	constructor(public payload: { flinker: FlinkerResponse }) {}
}

export class GetFlinker implements Action {
	readonly type = ActionTypes.GET_FLINKER;
	constructor() {}
}

export class GetFlinkerSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_SUCCESS;
	constructor(public payload: { flinkerResponse: FlinkerResponse }) {}
}

export class GetFlinkerError implements Action {
	readonly type = ActionTypes.GET_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkerPersonalDetails implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS;
	constructor(public payload: { flinkerPersonalDetailsRequest: FlinkerPersonalDetailsRequest }) {}
}

export class SubmitFlinkerPersonalDetailsSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SubmitFlinkerPersonalDetailsError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkerEmploymentDetails implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS;
	constructor(public payload: { flinkerEmploymentDetailsRequest: FlinkerEmploymentDetailsRequest }) {}
}

export class SubmitFlinkerEmploymentDetailsSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SubmitFlinkerEmploymentDetailsError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkerEducationalDetails implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS;
	constructor(public payload: { flinkerEducationalDetailsRequest: FlinkerEducationalDetailsRequest }) {}
}

export class SubmitFlinkerEducationalDetailsSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SubmitFlinkerEducationalDetailsError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkerCriminalDeclaration implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION;
	constructor(public payload: { flinkerCriminalDeclarationRequest: FlinkerCriminalDeclaration }) {}
}

export class SubmitFlinkerCriminalDeclarationSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SubmitFlinkerCriminalDeclarationError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkerTermsAndConditions implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS;
	constructor(public payload: { flinkerTermsAndConditionsRequest: FlinkerTermsAndConditionsRequest }) {}
}

export class SubmitFlinkerTermsAndConditionsSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SubmitFlinkerTermsAndConditionsError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadIdDocument implements Action {
	readonly type = ActionTypes.UPLOAD_ID_DOCUMENT;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadIdDocumentSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_ID_DOCUMENT_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class UploadIdDocumentError implements Action {
	readonly type = ActionTypes.UPLOAD_ID_DOCUMENT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadPassportDocument implements Action {
	readonly type = ActionTypes.UPLOAD_PASSPORT_DOCUMENT;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadPassportDocumentSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_PASSPORT_DOCUMENT_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class UploadPassportDocumentError implements Action {
	readonly type = ActionTypes.UPLOAD_PASSPORT_DOCUMENT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadEmploymentDocuments implements Action {
	readonly type = ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadEmploymentDocumentsSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class UploadEmploymentDocumentsError implements Action {
	readonly type = ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadQualificationDocuments implements Action {
	readonly type = ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadQualificationDocumentsSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class UploadQualificationDocumentsError implements Action {
	readonly type = ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadProfilePicture implements Action {
	readonly type = ActionTypes.UPLOAD_PROFILE_PICTURE;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadProfilePictureSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UploadProfilePictureError implements Action {
	readonly type = ActionTypes.UPLOAD_PROFILE_PICTURE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetProfileCompletion implements Action {
	readonly type = ActionTypes.GET_PROFILE_COMPLETION;
	constructor() {}
}

export class GetProfileCompletionSuccess implements Action {
	readonly type = ActionTypes.GET_PROFILE_COMPLETION_SUCCESS;
	constructor(public payload: { result: ProfileCompletionItem[] }) {}
}

export class GetProfileCompletionError implements Action {
	readonly type = ActionTypes.GET_PROFILE_COMPLETION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateReactivationRequest implements Action {
	readonly type = ActionTypes.CREATE_REACTIVATION_REQUEST;
	constructor(public payload: { createReactivationRequestDto: CreateReactivationRequestDto }) {}
}

export class CreateReactivationRequestSuccess implements Action {
	readonly type = ActionTypes.CREATE_REACTIVATION_REQUEST_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CreateReactivationRequestError implements Action {
	readonly type = ActionTypes.CREATE_REACTIVATION_REQUEST_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetReactivationRequest implements Action {
	readonly type = ActionTypes.GET_REACTIVATION_REQUEST;
	constructor() {}
}

export class GetReactivationRequestSuccess implements Action {
	readonly type = ActionTypes.GET_REACTIVATION_REQUEST_SUCCESS;
	constructor(public payload: { result: ReactivationRequestResponse | null }) {}
}

export class GetReactivationRequestError implements Action {
	readonly type = ActionTypes.GET_REACTIVATION_REQUEST_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoList implements Action {
	readonly type = ActionTypes.GET_FLINKCO_LIST;
	constructor() {}
}

export class GetFlinkCoListSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_LIST_SUCCESS;
	constructor(public payload: { result: FlinkCoNamesResponse[] }) {}
}

export class GetFlinkCoListError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_LIST_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SetFlinkerCVPromotionDate implements Action {
	readonly type = ActionTypes.SET_FLINKER_CV_PROMOTION_DATE;
	constructor() {}
}

export class SetFlinkerCVPromotionDateSuccess implements Action {
	readonly type = ActionTypes.SET_FLINKER_CV_PROMOTION_DATE_SUCCESS;
	constructor(public payload: { result: FlinkerResponse }) {}
}

export class SetFlinkerCVPromotionDateError implements Action {
	readonly type = ActionTypes.SET_FLINKER_CV_PROMOTION_DATE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| UpdateFlinker
	| GetFlinker
	| GetFlinkerSuccess
	| GetFlinkerError
	| SubmitFlinkerPersonalDetails
	| SubmitFlinkerPersonalDetailsSuccess
	| SubmitFlinkerPersonalDetailsError
	| SubmitFlinkerEmploymentDetails
	| SubmitFlinkerEmploymentDetailsSuccess
	| SubmitFlinkerEmploymentDetailsError
	| SubmitFlinkerEducationalDetails
	| SubmitFlinkerEducationalDetailsSuccess
	| SubmitFlinkerEducationalDetailsError
	| SubmitFlinkerCriminalDeclaration
	| SubmitFlinkerCriminalDeclarationSuccess
	| SubmitFlinkerCriminalDeclarationError
	| SubmitFlinkerTermsAndConditions
	| SubmitFlinkerTermsAndConditionsSuccess
	| SubmitFlinkerTermsAndConditionsError
	| UploadIdDocument
	| UploadIdDocumentSuccess
	| UploadIdDocumentError
	| UploadPassportDocument
	| UploadPassportDocumentSuccess
	| UploadPassportDocumentError
	| UploadEmploymentDocuments
	| UploadEmploymentDocumentsSuccess
	| UploadEmploymentDocumentsError
	| UploadQualificationDocuments
	| UploadQualificationDocumentsSuccess
	| UploadQualificationDocumentsError
	| UploadProfilePicture
	| UploadProfilePictureSuccess
	| UploadProfilePictureError
	| GetProfileCompletion
	| GetProfileCompletionSuccess
	| GetProfileCompletionError
	| CreateReactivationRequest
	| CreateReactivationRequestSuccess
	| CreateReactivationRequestError
	| GetReactivationRequest
	| GetReactivationRequestSuccess
	| GetReactivationRequestError
	| GetFlinkCoList
	| GetFlinkCoListSuccess
	| GetFlinkCoListError
	| SetFlinkerCVPromotionDate
	| SetFlinkerCVPromotionDateSuccess
	| SetFlinkerCVPromotionDateError;
