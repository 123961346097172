<div class="container">
	<app-page-header-toolbar
		icon="star_half"
		[heading]="
			'Reference scoring' +
			((referenceQuestionsHttpPackage$ | async).result
				? ' for ' +
				  (referenceQuestionsHttpPackage$ | async).result.flinker.name +
				  ' ' +
				  (referenceQuestionsHttpPackage$ | async).result.flinker.surname
				: '')
		"
	>
	</app-page-header-toolbar>

	<div class="d-flex justify-content-center">
		<app-loader
			*ngIf="(referenceQuestionsHttpPackage$ | async).loading || (submitReferenceScoreHttpPackage$ | async).loading"
		>
		</app-loader>
	</div>

	<section *ngIf="success">
		<div class="d-flex flex-column justify-content-center align-items-center my-5">
			<h4>Reference score successful, thank you for your time</h4>
			<mat-icon class="front-main-3 mb-3">check</mat-icon>
			<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
		</div>
	</section>

	<section *ngIf="error">
		<div class="d-flex flex-column justify-content-center align-items-center my-5">
			<h4>{{ errorMessage }}</h4>
			<mat-icon class="front-main-5 mb-3">close</mat-icon>
			<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
		</div>
	</section>

	<section
		*ngIf="
			!(referenceQuestionsHttpPackage$ | async).loading &&
			!success &&
			!error &&
			!(submitReferenceScoreHttpPackage$ | async).loading
		"
	>
		<div class="row">
			<p class="col-12 text-center front-sub-5">
				Please rate {{ (referenceQuestionsHttpPackage$ | async)?.result?.flinker.name }}
				{{ (referenceQuestionsHttpPackage$ | async)?.result?.flinker.surname }} on their performance during your working
				relationship.
			</p>
			<p class="col-12 text-center front-sub-5">
				All of the following questions should be rated on a scale of <strong>1</strong> - <strong>5</strong>, with
				<strong>1</strong> being poor and <strong>5</strong> being excellent
			</p>
		</div>

		<form class="row my-4" [formGroup]="form">
			<app-outlined-dropdown
				class="col-12 col-lg-6 offset-lg-3"
				[labelText]="
					'What kind of working relationship did you have with ' +
					(referenceQuestionsHttpPackage$ | async)?.result?.flinker.name +
					(referenceQuestionsHttpPackage$ | async)?.result?.flinker.surname +
					'?'
				"
				formControlName="relationship"
				[options]="(selectOptionsHttpPackage$ | async)?.result?.referenceRelationships"
				[errorMessage]="getErrorMessage(form.controls.relationship, 'Relationship', shouldValidate)"
			>
			</app-outlined-dropdown>

			<div
				*ngFor="let referenceQuestion of referenceQuestionsFormGroup; let i = index"
				class="col-12 col-lg-6 offset-lg-3 mt-5"
			>
				<div [formGroup]="referenceQuestion" class="row">
					<app-rating
						formControlName="question"
						[label]="referenceQuestions[i].question"
						[errorMessage]="getErrorMessage(referenceQuestion.controls.question, 'Answer', shouldValidate)"
					>
					</app-rating>
				</div>
			</div>

			<div class="col-12 col-sm-6 offset-sm-3 mt-5">
				<app-square-button
					matStepperNext
					theme="m1"
					value="Update"
					type="button"
					class="d-flex justify-content-center w-auto"
					(click)="submit()"
				>
				</app-square-button>
			</div>
		</form>
	</section>
</div>
