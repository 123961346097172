import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InterviewQuestionnaireState } from '@root-store/interview-questionnaire-store/state';

const getPaginatedInterviewQuestionnairesForFlinkCoHttpPackage = (state: InterviewQuestionnaireState) =>
	state.getPaginatedInterviewQuestionnairesHttpPackage;

const getInterviewQuestionnaireHttpPackage = (state: InterviewQuestionnaireState) =>
	state.getInterviewQuestionnaireHttpPackage;

const getInterviewQuestionCategoriesHttpPackage = (state: InterviewQuestionnaireState) =>
	state.interviewQuestionCategoriesHttpPackage;

const getFilteredDefaultInterviewQuestions = (state: InterviewQuestionnaireState) =>
	state.filteredDefaultInterviewQuestions;

const getAllDefaultInterviewQuestions = (state: InterviewQuestionnaireState) => state.allDefaultInterviewQuestions;

const getCreateInterviewQuestionnaireHttpPackage = (state: InterviewQuestionnaireState) =>
	state.createInterviewQuestionnaireHttpPackage;

const getUpdateInterviewQuestionnaireHttpPackage = (state: InterviewQuestionnaireState) =>
	state.updateInterviewQuestionnaireHttpPackage;

const getDeleteInterviewQuestionnaireHttpPackage = (state: InterviewQuestionnaireState) =>
	state.deleteInterviewQuestionnaireHttpPackage;

const getAllInterviewQuestionnairesHttpPackage = (state: InterviewQuestionnaireState) =>
	state.getAllInterviewQuestionnairesHttpPackage;

export const selectInterviewQuestionnaireState =
	createFeatureSelector<InterviewQuestionnaireState>('interview-questionnaire');

export const selectPaginatedInterviewQuestionnairesForFlinkCoHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getPaginatedInterviewQuestionnairesForFlinkCoHttpPackage,
);

export const selectInterviewQuestionnaireHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getInterviewQuestionnaireHttpPackage,
);

export const selectInterviewQuestionCategoriesHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getInterviewQuestionCategoriesHttpPackage,
);
export const selectFilteredDefaultInterviewQuestions = createSelector(
	selectInterviewQuestionnaireState,
	getFilteredDefaultInterviewQuestions,
);

export const selectAllDefaultInterviewQuestions = createSelector(
	selectInterviewQuestionnaireState,
	getAllDefaultInterviewQuestions,
);

export const selectCreateInterviewQuestionnaireHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getCreateInterviewQuestionnaireHttpPackage,
);

export const selectUpdateInterviewQuestionnaireHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getUpdateInterviewQuestionnaireHttpPackage,
);

export const selectDeleteInterviewQuestionnaireHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getDeleteInterviewQuestionnaireHttpPackage,
);

export const selectAllInterviewQuestionnairesHttpPackage = createSelector(
	selectInterviewQuestionnaireState,
	getAllInterviewQuestionnairesHttpPackage,
);
