import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-circle-icon-button',
	templateUrl: './circle-icon-button.component.html',
	styleUrls: ['./circle-icon-button.component.scss'],
})
export class CircleIconButtonComponent implements OnInit {
	@Input() theme = '';
	@Input() icon = '';
	@Input() link = '';
	@Input() iconTheme = '';
	@Input() tooltip = '';
	@Input() labelText = '';
	@Input() loading = false;
	@Input() progressDiameter = 30;

	constructor() {}

	ngOnInit(): void {}
}
