import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlinkCoJobAdvertState } from './state';
import HttpPackage from '@models/http-package';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { JobApplicationQuestionAnswerResponse } from '@models/dto/responses/job-application-question-answer-response.dto';

const getFlinkCoJobAdverts = (state: FlinkCoJobAdvertState): HttpPackage<PaginatedResponse<JobAdvertResponseDto>> =>
	state.fetchFlinkCoJobAdvertsHttpPackage;
const getFlinkCoCareerPageJobAdverts = (
	state: FlinkCoJobAdvertState,
): HttpPackage<PaginatedResponse<JobAdvertResponseDto>> => state.fetchCareersPageJobAdvert;
const getFlinkCoJobAdvert = (state: FlinkCoJobAdvertState): HttpPackage<JobAdvertResponseDto> =>
	state.fetchFlinkCoJobAdvertHttpPackage;
const getPublicFlinkCoJobAdvert = (state: FlinkCoJobAdvertState): HttpPackage<JobAdvertResponseDto> =>
	state.fetchPublicFlinkCoJobAdvertHttpPackage;

const getCreateJobAdvert = (state: FlinkCoJobAdvertState): HttpPackage<JobAdvertResponseDto> =>
	state.createJobAdvertHttpPackage;

const getUpdateJobAdvert = (state: FlinkCoJobAdvertState): HttpPackage<JobAdvertResponseDto> =>
	state.updateJobAdvertHttpPackage;

const getFlinkCoJobApplications = (state: FlinkCoJobAdvertState): HttpPackage<JobApplicationResponse[]> =>
	state.fetchFlinkCoJobApplicationHttpPackage;

const getJobApplicationQuestionAnswers = (
	state: FlinkCoJobAdvertState,
): HttpPackage<JobApplicationQuestionAnswerResponse[]> => state.fetchJobApplicationQuestionAnswersHttpPackage;

const getJobAdvertReferral = (state: FlinkCoJobAdvertState): HttpPackage<string> => state.referJobAdvertHttpPackage;
const getFlinkCoIframeLink = (state: FlinkCoJobAdvertState): HttpPackage<string> => state.fetchFlinkCoIframeLink;

export const selectFlinkCoJobAdvertsState = createFeatureSelector<FlinkCoJobAdvertState>('flinkco-job-adverts');

export const selectFlinkCoJobAdverts = createSelector(selectFlinkCoJobAdvertsState, getFlinkCoJobAdverts);
export const selectFlinkCoJobAdvert = createSelector(selectFlinkCoJobAdvertsState, getFlinkCoJobAdvert);
export const selectPublicFlinkCoJobAdvert = createSelector(selectFlinkCoJobAdvertsState, getPublicFlinkCoJobAdvert);
export const selectCreateFlinkCoAdvert = createSelector(selectFlinkCoJobAdvertsState, getCreateJobAdvert);
export const selectUpdateFlinkCoJobAdvert = createSelector(selectFlinkCoJobAdvertsState, getUpdateJobAdvert);
export const selectGetFlinkCoJobApplications = createSelector(selectFlinkCoJobAdvertsState, getFlinkCoJobApplications);
export const selectCareersPageJobAdverts = createSelector(selectFlinkCoJobAdvertsState, getFlinkCoCareerPageJobAdverts);
export const selectGetJobApplicationQuestionAnswers = createSelector(
	selectFlinkCoJobAdvertsState,
	getJobApplicationQuestionAnswers,
);
export const selectJobAdvertReferral = createSelector(selectFlinkCoJobAdvertsState, getJobAdvertReferral);
export const selectFlinkCoIframeLink = createSelector(selectFlinkCoJobAdvertsState, getFlinkCoIframeLink);
