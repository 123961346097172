import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOptionResponse } from 'projects/flink-app/src/app/models/dto/responses/select-option-response.dto';

@Component({
	selector: 'app-ranking-list',
	templateUrl: './ranking-list.component.html',
	styleUrls: ['./ranking-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RankingListComponent),
			multi: true,
		},
	],
})
export class RankingListComponent implements OnInit, ControlValueAccessor {
	@Input() labelText = '';
	@Input() rankList: Array<SelectOptionResponse> = [];
	value: string[];
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {
		this.value = this.rankList.map((a) => a.code);
	}

	getName(code: string): string {
		return this.rankList.find((item) => item.code === code).name;
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.value, event.previousIndex, event.currentIndex);
		this.onChange(this.value);
	}

	public writeValue(value: Array<string>): void {
		this.value = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur(): void {
		this.onTouched();
	}
}
