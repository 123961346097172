import { FlinkerJobsState } from '@root-store/flinker/jobs/state';
import HttpPackage from '@models/http-package';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';

const createJobApplication = (state: FlinkerJobsState): HttpPackage<JobApplicationResponse> =>
	state.createJobApplicationHttpPackage;
const fetchJobAdvertQuestions = (state: FlinkerJobsState): HttpPackage<QuestionAnswerResponseDto[]> =>
	state.fetchJobAdvertQuestionsHttpPackage;

const getJobAdvertForFlinker = (state: FlinkerJobsState): HttpPackage<FlinkerJobAdvertResponse> =>
	state.getSingleJobAdvertForFlinkerHttpPackage;

const createInitialJobApplication = (state: FlinkerJobsState): HttpPackage<JobApplicationResponse> =>
	state.createInitialJobApplicationHttpPackage;

const getFilteredJobAdverts = (state: FlinkerJobsState): HttpPackage<PaginatedResponse<JobAdvertResponseDto>> =>
	state.getFilteredJobAdvertsHttpPackage;

const getFavouriteJobAdvert = (state: FlinkerJobsState): HttpPackage<JobAdvertResponseDto> =>
	state.favouriteJobAdvertHttpPackage;

export const selectFlinkerJobsState = createFeatureSelector<FlinkerJobsState>('flinker-jobs');

export const selectCreateJobApplication = createSelector(selectFlinkerJobsState, createJobApplication);
export const selectCreateInitialJobApplication = createSelector(selectFlinkerJobsState, createInitialJobApplication);
export const selectFetchJobAdvertQuestions = createSelector(selectFlinkerJobsState, fetchJobAdvertQuestions);
export const selectGetJobAdvertForFlinker = createSelector(selectFlinkerJobsState, getJobAdvertForFlinker);
export const selectGetFilteredJobAdverts = createSelector(selectFlinkerJobsState, getFilteredJobAdverts);
export const selectFavouriteJobAdvert = createSelector(selectFlinkerJobsState, getFavouriteJobAdvert);
