import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CvStoreEffects } from './effects';
import { cvReducer } from './reducer';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('cvDashboard', cvReducer), EffectsModule.forFeature([CvStoreEffects])],
	providers: [CvStoreEffects],
})
export class CvStoreModule {}
