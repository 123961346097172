<mat-form-field>
	<label [ngClass]="{ required: required }">{{ labelText }}</label>
	<div class="ghost-input">
		<textarea
			matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="{{ minRows }}"
      cdkAutosizeMaxRows="{{ maxRows }}"
			[disabled]="disabled"
			(input)="input($event)"
      (change)="input($event)"
			(blur)="onBlur()"
      (click)="onTextAreaClicked($event)"
			[value]="value"
			[(ngModel)]="value"
			[maxLength]="maxLength"
		></textarea>
	</div>
	<div class="position-relative">
		<label *ngIf="bottomText" class="pt-2 front-sub-8">{{ bottomText }}</label>
		<span class="word-counter front-sub-8 d-none d-md-flex">Max characters: {{ value?.length }}/{{ maxLength }}</span>
	</div>
	<app-input-error-message *ngIf="errorMessage?.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
