import { Actions, ActionTypes } from './actions';
import { initialState, PsychAssessmentState } from './state';

export function psychAssessmentReducer(state = initialState, action: Actions): PsychAssessmentState {
	switch (action.type) {
		case ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS:
			return {
				...state,
				psychAssessmentCompletionStatusHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_SUCCESS:
			return {
				...state,
				psychAssessmentCompletionStatusHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_ERROR:
			return {
				...state,
				psychAssessmentCompletionStatusHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
