import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FlinkCoTakeOnStoreSelectors } from 'projects/flink-app/src/app/root-store/flinkco-take-on-store';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { UserPlanSubscription } from 'projects/flink-app/src/app/enums/user-plan-subscription';
import { SearchStoreActions, SearchStoreSelectors } from '@root-store/search-store';
import { FlinkcoDashboardStoreActions, FlinkcoDashboardStoreSelectors } from '@root-store/flinkco-dashboard-store';
import { NotifierService } from 'angular-notifier';
import { selectGetFlinkCoHttpPackage } from '@root-store/flinkco-take-on-store/selectors';

@Component({
	selector: 'app-manage-subscription',
	templateUrl: './manage-subscription.component.html',
	styleUrls: ['./manage-subscription.component.scss'],
})
export class ManageSubscriptionComponent implements OnInit, OnDestroy {
	flinkco$ = this.store.select(FlinkCoTakeOnStoreSelectors.selectFlinkCo);
	fetchAdhocSubscriptionHttpPackage$ = this.store.select(SearchStoreSelectors.selectAdhocSubscriptionHttpPackage);
	subscriptions = new Subscription();
	UserPlanSubscription = UserPlanSubscription;
	selectedSubscription: UserPlanSubscription;
	adHocPlacementLimitReached = false;
	requestDemoHttpPackage$ = this.store.select(FlinkcoDashboardStoreSelectors.selectRequestDemoHttpPackage);
	showRequestDemoBlock = true;
	flinkCoHttpPackage$ = this.store.select(selectGetFlinkCoHttpPackage);

	@Output() cancelRetainer = new EventEmitter();
	@Output() requestDemo = new EventEmitter();
	@Output() userPlanSelected = new EventEmitter();

	constructor(private store: Store<RootStoreState.State>, private notifierService: NotifierService) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.fetchAdhocSubscriptionHttpPackage$.subscribe((fetchAdhocSubscriptionHttpPackage) => {
				if (fetchAdhocSubscriptionHttpPackage.result !== null) {
					this.adHocPlacementLimitReached =
						fetchAdhocSubscriptionHttpPackage.result.numberOfAcceptedOffers ===
						fetchAdhocSubscriptionHttpPackage.result.numberOfPlacements;
				}
			}),
		);

		this.subscriptions.add(
			this.flinkCoHttpPackage$.subscribe((flinkcoHttpPackage) => {
				if (flinkcoHttpPackage.result !== null) {
					this.selectedSubscription = flinkcoHttpPackage.result?.subscription;
					if (flinkcoHttpPackage.result?.subscription === UserPlanSubscription.AdHoc) {
						this.store.dispatch(new SearchStoreActions.GetAdhocSubscription());
					}
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	requestDemoClick() {
		this.subscriptions.add(
			this.requestDemoHttpPackage$.subscribe((requestDemoHttpPackage) => {
				if (requestDemoHttpPackage.result !== null) {
					this.showRequestDemoBlock = false;
					this.notifierService.notify(
						'success',
						'Request for a demo submitted successfully, we will contact you soon.',
					);
				}
			}),
		);
		this.store.dispatch(new FlinkcoDashboardStoreActions.RequestDemo());
		this.requestDemo.emit();
	}
}
