<mat-card class="job-card p-4 position-relative">
	<div class="row position-absolute float-right mr-1 position-top-right">
		<div class="d-flex w-100 mx-0 flex-row justify-content-end">
			<mat-icon class="front-sub-11 share-button" (click)="openReferralDialog()">ios_share_two_tone</mat-icon>
			<mat-icon
				*ngIf="!jobAdvert.favourite && !flinkCoId"
				class="front-sub-11 ml-2 cursor-pointer"
				(click)="favouriteJobAdvert()"
				>favorite_border_two_tone</mat-icon
			>
			<mat-icon
				*ngIf="jobAdvert.favourite && !flinkCoId"
				class="front-main-8 ml-2 cursor-pointer"
				(click)="favouriteJobAdvert()"
				>favorite</mat-icon
			>
		</div>
	</div>
	<div class="row heading-height m-0">
		<div class="w-100 d-flex justify-content-space-between align-items-start flex-row">
			<div class="border-div d-flex justify-content-center align-items-center p-1">
				<ngx-avatar
					*ngIf="!jobAdvert?.companyLogoUrl"
					[src]="jobAdvert?.defaultSystemCompanyLogoUrl"
					class="mx-auto"
					[size]="80"
					name="Company Logo"
					[round]="true"
					[cornerRadius]="50"
				></ngx-avatar>

				<div class="company-logo-container" *ngIf="jobAdvert?.companyLogoUrl">
					<img class="company-logo-image" [src]="jobAdvert?.companyLogoUrl" alt="Company Logo" />
				</div>
			</div>

      <ng-template #publicView>
        <div class="pl-3 mt-3">
          <p class="font-bold industry-heading font-size-9 mb-1 front-fade-4">
            {{  jobAdvert?.search?.industry }}
          </p>
          <p class="font-medium font-size-11 front-fade-4">
            {{  jobAdvert?.search?.jobTitle }}
          </p>
        </div>
      </ng-template>
      <div class="pl-3 mt-3" *ngIf="!isPublic else publicView">
        <p class="font-bold industry-heading font-size-9 mb-1 front-fade-4">
          {{  jobAdvert?.search?.industryId | selectOptionNameById: selectOptions?.industries }}
        </p>
        <p class="font-medium font-size-11 front-fade-4">
          {{  (jobAdvert?.search?.jobTitleId | selectOptionNameById: selectOptions?.jobTitles: jobAdvert?.search?.otherJobTitle) }}
        </p>
      </div>
		</div>
	</div>

	<div class="row mx-0 description-height border-underline">
		<p class="max-characters font-medium mb-3 font-size-11 front-fade-4">
			{{ jobAdvert?.jobDescription }}
		</p>
	</div>

	<div class="row card-extras px-3 my-4">
		<div class="d-flex w-100 px-1 flex-row justify-content-between">
			<div class="d-flex">
				<span class="front-sub-5 font-size-11 center-icon font-medium">
					<mat-icon class="front-fade-4 font-size-9">calendar_today_outlined</mat-icon>
					<ng-container *ngIf="jobAdvert?.applicationDeadline">{{
						jobAdvert?.applicationDeadline | date: 'dd MMM, YYYY'
					}}</ng-container>
					<ng-container *ngIf="!jobAdvert?.applicationDeadline">No deadline</ng-container>
				</span>
			</div>

			<div class="d-flex">
				<span class="front-sub-5 font-size-11 font-medium center-icon">
					<mat-icon class="front-fade-4 pt-1 font-size-9">schedule_outlined</mat-icon>
					{{ calculateTimeSincePublished(jobAdvert?.datePublished) }}
				</span>
			</div>
		</div>

		<ng-container *ngIf="jobAdvert?.search?.locations.length > 0">
			<div class="d-flex px-1 mt-1" *ngIf="!isPublic else publicCardJobLocation">
				<span class="front-sub-5 font-size-11 font-medium center-icon">
					<mat-icon class="front-fade-4 pt-1 font-size-9">place_outlined</mat-icon>
					{{ jobAdvert?.search?.locations[0].cities[0] | selectOptionNameByCode:selectOptions?.cities }}, {{ jobAdvert.search.locations[0].province | selectOptionNameByCode:selectOptions?.provinces }}
				</span>
			</div>
		</ng-container>
    <ng-template #publicCardJobLocation>
      <div class="d-flex px-1 mt-1">
				<span class="front-sub-5 font-size-11 font-medium center-icon">
					<mat-icon class="front-fade-4 pt-1 font-size-9">place_outlined</mat-icon>
          {{ jobAdvert?.search?.locations[0].cities[0] }}, {{ jobAdvert.search.locations[0].province }}
				</span>
      </div>
    </ng-template>
	</div>

	<div class="row button-height mx-0 mb-2">
		<app-square-button
			class="w-100"
			(click)="viewDetailedJobAdvert()"
			theme="m1 bold"
			value="View Ad"
		></app-square-button>
	</div>
</mat-card>
