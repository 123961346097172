import { Injectable } from '@angular/core';
import FlinkCoResponse from '@flinkadmin-dashboard/models/response/flink-co-admin-response.dto';
import FlinkerAdminResponse from '@flinkadmin-dashboard/models/response/flinker-admin-response.dto';
import { FlinkerReferenceScoreRequest } from '@flinkadmin-dashboard/models/response/flinker-reference-score-request.dto';
import ReactivationRequestResponse from '@flinkadmin-dashboard/models/response/reactivation-requests-response.dto';
import { AdHocInformationRequest } from '@models/dto/requests/ad-hoc-information-request.dto';
import { RetainerInformationRequest } from '@models/dto/requests/retainer-information-request.dto';
import UpdateReactivationRequestStatusRequest from '@models/dto/requests/update-reactivation-request-status.dto';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import FlinkerReferenceScore from '@models/dto/responses/flinker-reference-score.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import ReferenceQuestion from '@models/dto/responses/reference-question.dto';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import FlinkerAdminFilterRequest from '@models/dto/requests/flinker-admin-filter-request.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import { FlinkCoSubscriptionRequest } from '@models/dto/requests/flink-co-subscription-request';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import FlinkcoAdminFilterRequest from '@models/dto/requests/flinkco-admin-filter-request.dto';
import CreateSearchResponse from '@models/dto/responses/search-response.dto';
import AdminSearchResponse from '@flinkadmin-dashboard/models/response/admin-search-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import AdminSearchResultResponseDto from '@flinkadmin-dashboard/models/response/admin-search-result-response.dto';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class FlinkAdminService {
	apiFlinkerAdminEndpoint = 'FlinkerAdmin';
	apiAuthEndpoint = 'auth';
	apiFlinkCoAdminEndpoint = 'FlinkCoAdmin';
	apiReferenceQuestionAdminEndpoint = 'ReferenceQuestionAdmin';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getFlinkCoList(flinkCoAdminFilter: FlinkcoAdminFilterRequest) {
		return this.httpV2Service.postSecure<PaginatedResponse<FlinkCoResponse>>(
			`${this.apiFlinkCoAdminEndpoint}`,
			flinkCoAdminFilter,
		);
	}

	getQuoteList(pageSize: number, pageNumber: number, quoteReference: string) {
		return this.httpV2Service.getSecure<PaginatedResponse<FlinkCoResponse>>(
			`${this.apiFlinkCoAdminEndpoint}/QuoteSearch?pageSize=${pageSize}&pageNumber=${pageNumber}&quoteReference=${quoteReference}`,
		);
	}

	updateFlinkCo(flinkco: FlinkCoResponse) {
		return this.httpV2Service.putSecure<string>(`${this.apiFlinkCoAdminEndpoint}`, flinkco);
	}

	getFlinkerList(pageSize: number, pageNumber: number, flinkerAdminFilter: FlinkerAdminFilterRequest) {
		return this.httpV2Service.postSecure<PaginatedResponse<FlinkerAdminResponse>>(
			`${this.apiFlinkerAdminEndpoint}`,
			flinkerAdminFilter,
		);
	}

	getDetailedFlinker(flinkerId: string) {
		return this.httpV2Service.getSecure<DetailedFlinkerAdminResponse>(`${this.apiFlinkerAdminEndpoint}/${flinkerId}`);
	}

	getFlinkerReferenceScore(flinkerId: string) {
		return this.httpV2Service.getSecure<FlinkerReferenceScore>(
			`${this.apiFlinkerAdminEndpoint}/ReferenceScore/${flinkerId}`,
		);
	}

	updateFlinker(flinker: FlinkerReferenceScoreRequest) {
		return this.httpV2Service.putSecure<string>(`${this.apiFlinkerAdminEndpoint}`, flinker);
	}

	getFlinkerDocuments(documentId: string, containerName: string) {
		return this.httpV2Service.downloadFileGet(
			`${this.apiFlinkerAdminEndpoint}/Documents/${documentId}/${containerName}`,
		);
	}

	deleteFLinker(flinkerId: string) {
		return this.httpV2Service.deleteSecure(`${this.apiFlinkerAdminEndpoint}/Delete/${flinkerId}`);
	}

	downloadFlinkerCvAsFlinkAdmin(flinkerId: string, obfuscate = false) {
		return this.httpV2Service.downloadFileGet(`cv/flink-admin/${flinkerId}`, { params: { obfuscate } });
	}

	downloadAdminReport(searchId: string) {
		return this.httpV2Service.downloadFileGet(`admin-reporting/${searchId}`);
	}

	getReactivationRequestsList(pageSize: number, pageNumber: number) {
		return this.httpV2Service.getSecure<PaginatedResponse<ReactivationRequestResponse>>(
			`${this.apiFlinkerAdminEndpoint}/ReactivationRequests?pageSize=${pageSize}&pageNumber=${pageNumber}`,
		);
	}

	updateReactivationRequestStatus(payload: UpdateReactivationRequestStatusRequest) {
		return this.httpV2Service.putSecure<void>(`${this.apiFlinkerAdminEndpoint}/ReactivationRequest/Status`, payload);
	}

	getAllReferenceQuestions() {
		return this.httpV2Service.getSecure<ReferenceQuestion[]>(`${this.apiReferenceQuestionAdminEndpoint}`);
	}

	createReferenceQuestion(question: ReferenceQuestion) {
		return this.httpV2Service.postSecure<ReferenceQuestion>(`${this.apiReferenceQuestionAdminEndpoint}`, question);
	}

	updateReferenceQuestion(question: ReferenceQuestion) {
		return this.httpV2Service.putSecure<ReferenceQuestion>(`${this.apiReferenceQuestionAdminEndpoint}`, question);
	}

	deleteReferenceQuestion(questionId: string) {
		return this.httpV2Service.delete<void>(`${this.apiReferenceQuestionAdminEndpoint}`, {
			params: { questionId },
		});
	}

	submitSubscriptionChoice(flinkCoId: string, flinkCoSubscriptionRequest: FlinkCoSubscriptionRequest) {
		return this.httpV2Service.postSecure<FlinkCoSubscriptionResponse>(
			`${this.apiFlinkCoAdminEndpoint}/subscription`,
			flinkCoSubscriptionRequest,
			{
				params: { flinkCoId },
			},
		);
	}

	addAdhocSubscription(flinkCoId: string, adhocInformation: AdHocInformationRequest) {
		return this.httpV2Service.postSecure<AdHocInformationResponse>(
			`${this.apiFlinkCoAdminEndpoint}/adHocInformation`,
			adhocInformation,
			{
				params: { flinkCoId },
			},
		);
	}

	addRetainerSubscription(flinkCoId: string, retainerInformation: RetainerInformationRequest) {
		return this.httpV2Service.postSecure<RetainerInformationResponse>(
			`${this.apiFlinkCoAdminEndpoint}/retainerInformation`,
			retainerInformation,
			{
				params: { flinkCoId },
			},
		);
	}

	cancelRetainerSubscription(flinkCoId: string) {
		return this.httpV2Service.postSecure<RetainerInformationResponse>(
			`${this.apiFlinkCoAdminEndpoint}/subscriptions/cancel`,
			{},
			{
				params: { flinkCoId },
			},
		);
	}

	loginAsFlinker(flinkerId: string) {
		return this.httpV2Service.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/loginAsFlinker`, {
			id: flinkerId,
		});
	}

	loginAsFlinkCo(flinkCoId: string) {
		return this.httpV2Service.postSecure<SignInResponse>(`${this.apiAuthEndpoint}/loginAsFlinkCo`, {
			id: flinkCoId,
		});
	}

	deleteFlinkCo(flinkCoId: string) {
		return this.httpV2Service.deleteSecure(`${this.apiFlinkCoAdminEndpoint}/Delete/${flinkCoId}`);
	}

	getSelectedFlinkCo(flinkCoId: string) {
		return this.httpV2Service.getSecure<FlinkCoResponse>(`${this.apiFlinkCoAdminEndpoint}/${flinkCoId}`);
	}

	fetchFlinkCoSearches(flinkCoId: string) {
		return this.httpV2Service.getSecure<CreateSearchResponse[]>(
			`${this.apiFlinkCoAdminEndpoint}/${flinkCoId}/searches`,
		);
	}

	fetchActiveSearch(flinkCoId: string, searchId: string) {
		return this.httpV2Service.getSecure<AdminSearchResponse>(
			`${this.apiFlinkCoAdminEndpoint}/${flinkCoId}/Search/${searchId}`,
		);
	}

	getSearchResults(filterFlinkerRequest: FilterFlinkerRequestDto) {
		return this.httpV2Service.postSecure<AdminSearchResultResponseDto>(
			`${this.apiFlinkCoAdminEndpoint}/Search/${filterFlinkerRequest.searchId}/results`,
			filterFlinkerRequest,
		);
	}

	addFlinkerToSearchResults(searchId: string, flinkerId: string) {
		return this.httpV2Service.postSecure<AdminMatchedFlinkerResponse>(
			`${this.apiFlinkCoAdminEndpoint}/Search/${searchId}/add-flinker/${flinkerId}`,
			{},
		);
	}

	removeFlinkerFromSearchResults(searchId: string, flinkerId: string) {
		return this.httpV2Service.postSecure<AdminMatchedFlinkerResponse>(
			`${this.apiFlinkCoAdminEndpoint}/Search/${searchId}/remove-flinker/${flinkerId}`,
			{},
		);
	}
}
