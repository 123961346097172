import HttpPackage from '@models/http-package';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { defaultHttpPackage } from '@root-store/helpers';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';

export interface FlinkerJobsState {
	createJobApplicationHttpPackage: HttpPackage<JobApplicationResponse>;
	createInitialJobApplicationHttpPackage: HttpPackage<JobApplicationResponse>;
	fetchJobAdvertQuestionsHttpPackage: HttpPackage<QuestionAnswerResponseDto[]>;
	getSingleJobAdvertForFlinkerHttpPackage: HttpPackage<FlinkerJobAdvertResponse>;
	getFilteredJobAdvertsHttpPackage: HttpPackage<PaginatedResponse<JobAdvertResponseDto>>;
	favouriteJobAdvertHttpPackage: HttpPackage<JobAdvertResponseDto>;
}

export const initialState: FlinkerJobsState = {
	createJobApplicationHttpPackage: defaultHttpPackage(),
	fetchJobAdvertQuestionsHttpPackage: defaultHttpPackage(),
	getSingleJobAdvertForFlinkerHttpPackage: defaultHttpPackage(),
	createInitialJobApplicationHttpPackage: defaultHttpPackage(),
	getFilteredJobAdvertsHttpPackage: defaultHttpPackage(),
	favouriteJobAdvertHttpPackage: defaultHttpPackage(),
};
