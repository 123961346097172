import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-button-tab',
	templateUrl: './button-tab.component.html',
	styleUrls: ['./button-tab.component.scss'],
})
export class ButtonTabComponent implements OnInit {
	@Input() values: string[] = [];
	@Input() set currentIndex(currentIndex: number) {
		this.state.fill('inactive');
		this.state[currentIndex] = 'active';
	}
	@Output() currentIndexChange = new EventEmitter<number>();
	@Output() currentValue = new EventEmitter<string>();
	state: string[] = [];
	constructor() {
		this.state[0] = 'active';
		this.state.fill('inactive', 1);
	}

	ngOnInit(): void {}

	selectTab(i: number) {
		this.state.fill('inactive');
		this.state[i] = 'active';
		this.currentIndexChange.emit(i);
		this.currentValue.emit(this.values[i]);
	}
}
