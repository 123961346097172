import OfferResponse from '../../models/dto/responses/offer-response.dto';
import HttpPackage from '../../models/http-package';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

export interface OfferState {
	createOfferHttpPackage: HttpPackage<OfferResponse>;
	getFlinkerOffersHttpPackage: HttpPackage<OfferResponse[]>;
	getFlinkerOfferHttpPackage: HttpPackage<OfferResponse>;
	getFlinkCoOffersHttpPackage: HttpPackage<PaginatedResponse<OfferResponse>>;
	getFlinkCoOfferHttpPackage: HttpPackage<OfferResponse>;
	updateOfferHttpPackage: HttpPackage<OfferResponse>;
	updateOfferStatusHttpPackage: HttpPackage<OfferResponse>;
	uploadOfferDocumentsHttpPackage: HttpPackage<string>;
	cancelOfferHttpPackage: HttpPackage<string>;
	downloadOfferDocumentHttpPackage: HttpPackage<any>;
}

export const initialState: OfferState = {
	createOfferHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkerOffersHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkerOfferHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkCoOffersHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkCoOfferHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateOfferHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateOfferStatusHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	cancelOfferHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadOfferDocumentsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	downloadOfferDocumentHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
