import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import FlinkerResponse from 'projects/flink-app/src/app/models/dto/responses/flinker-response.dto';
import { Observable, Subject, Subscription } from 'rxjs';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { SelectOptionsResponse } from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { delay, delayWhen, takeUntil } from 'rxjs/operators';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { filterCitiesByProvincesHelper } from '@shared/helpers/filter-cities-by-provinces.helper';
import { DictionaryHints } from '@app/enums/dictionary-words.enum';
import { provinceHasCityValidator } from '@app/utils/custom-validators.util';
import { plainToInstance } from 'class-transformer';
import FlinkerPersonalDetailsRequest from '@models/dto/requests/flinker-personal-details-request.dto';

@Component({
	selector: 'app-applying-to',
	templateUrl: './applying-to.component.html',
	styleUrls: ['./applying-to.component.scss'],
})
export class ApplyingToComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	form: FormGroup;
	flinker: FlinkerResponse;
	selectOptionsHttpPackage: SelectOptionsResponse;
	flinkerPersonalDetailsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerPersonalDetailsHttpPackage,
	);
	getErrorMessage = getErrorMessage;
	shouldValidate = false;
	shouldValidateCities = false;
	expectUpdate = false;
	cities: string[] = [];
	filteredCitiesInProvinces$: Observable<SelectOptionResponse[]>;
	provinces: string[] = [];
	selectOptions: SelectOptionsResponse;
	dictionaryHints = DictionaryHints;

	@Output() save = new EventEmitter();

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
	) {
		this.form = this.formBuilder.group({
			idealWorkProvince: [[], [Validators.required]],
			idealWorkCities: [[], [Validators.required, provinceHasCityValidator(this.selectOptionsHttpPackage$)]],
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result != null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);

		this.filteredCitiesInProvinces$ = filterCitiesByProvincesHelper(
			this.selectOptionsHttpPackage$,
			this.form.controls.idealWorkProvince.valueChanges,
		).pipe(takeUntil(this.destroy$));

		this.subscriptions.add(
			this.form.controls.idealWorkProvince.valueChanges.subscribe(() => {
				this.shouldValidateCities = true;
				this.form.controls.idealWorkCities.updateValueAndValidity();
				this.form.updateValueAndValidity();
			}),
		);

		this.subscriptions.add(
			this.flinker$
				.pipe(
					delayWhen(() => this.selectOptionsHttpPackage$),
					delay(500),
				)
				.subscribe((flinker) => {
					if (flinker?.idealWorkLocations != null) {
						this.flinker = flinker;
						let cities: string[] = [];
						flinker?.idealWorkLocations?.forEach((location) => (cities = cities.concat(location.city)));
						this.form.patchValue({
							...flinker,
							idealWorkProvince: flinker?.idealWorkLocations
								?.map((x) => x.province)
								.filter((value, index, self) => self.indexOf(value) === index),
							idealWorkCities: cities,
						});
					}
				}),
		);

		this.subscriptions.add(
			this.flinkerPersonalDetailsHttpPackage$.subscribe((flinkerPersonalDetailsHttpPackage) => {
				if (flinkerPersonalDetailsHttpPackage.result && this.expectUpdate) {
					this.expectUpdate = false;
					this.notifierService.notify('success', 'Location updated successfully');
				}
			}),
		);
	}

	submit(): void {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}
			return;
		}
		this.expectUpdate = true;
		const formValues = this.form.value;
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetails({
				flinkerPersonalDetailsRequest: plainToInstance(
					FlinkerPersonalDetailsRequest,
					{
						...this.flinker,
						...formValues,
						preferredMethodOfContact: this.flinker.methodOfContact,
						idealWorkLocations: this.form.get('idealWorkProvince').value.map((province: string) => {
							return {
								province,
								city: this.form
									.get('idealWorkCities')
									.value.filter(
										(city: string) =>
											this.selectOptions.cities.find((citySelectOption) => citySelectOption.code === city)
												.parentSelectOption.code === province,
									),
							};
						}),
					},
					{ excludeExtraneousValues: true },
				),
			}),
		);
	}

	filterCities(provinces: string[]) {
		const citiesList: string[] = Object.assign([], this.form.controls.idealWorkCities.value);
		const newCitiesList: string[] = [];
		citiesList.forEach((element) => {
			const citySelectOption = this.selectOptions.cities.find((x) => x.code === element);
			if (!(provinces || []).some((x) => x === citySelectOption.parentSelectOption?.code)) {
				const index = this.cities.indexOf(element);
				this.cities.splice(index, 1);
			} else {
				newCitiesList.push(citySelectOption.code);
			}
		});
		this.form.controls.idealWorkCities.reset();
		this.form.controls.idealWorkCities.setValue(newCitiesList);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
