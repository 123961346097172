import { Injectable } from '@angular/core';
import FlinkCoResponse from '../../models/dto/responses/flink-co-response.dto';
import FlinkCoUser from '../../models/dto/flink-co-user.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class FlinkCoService {
	apiFlinkCosEndpoint = 'flinkco';
	apiSubscriptionsEndpoint = 'subscriptions';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getFlinkCo() {
		return this.httpV2Service.getSecure<FlinkCoResponse>(`${this.apiFlinkCosEndpoint}`);
	}

	getFlinkCoWithSelf() {
		return this.httpV2Service.getSecure<FlinkCoResponse>(`${this.apiFlinkCosEndpoint}/with-self`);
	}

	addFlinkCoUsers(flinkCoUsers: FlinkCoUser[]) {
		return this.httpV2Service.postSecure<FlinkCoUser[]>(`${this.apiFlinkCosEndpoint}/addUsers`, flinkCoUsers);
	}

	upgradeAdhocSubscription() {
		return this.httpV2Service.postSecure<void>(`${this.apiSubscriptionsEndpoint}/upgrade/adhoc`, {});
	}

	requestDemo() {
		return this.httpV2Service.postSecure<void>(`${this.apiSubscriptionsEndpoint}/demo`, {});
	}

	cancelRetainerSubscription() {
		return this.httpV2Service.postSecure<void>(`${this.apiSubscriptionsEndpoint}/cancel`, {});
	}
}
