import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-notification-banner',
	templateUrl: './notification-banner.component.html',
	styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {
	@Input() theme = 'm2';
	open = true;

	constructor() {}

	ngOnInit(): void {}
}
