<div class="p-1 p-md-4">
	<app-dashboard-section-header icon="school" title="Qualifications" [editIcon]="true" (edit)="editState = !editState">
	</app-dashboard-section-header>

	<div>
		<app-qualifications-profile *ngIf="!editState"></app-qualifications-profile>
		<app-qualifications *ngIf="editState" [allowSkillsUpdate]="false" (success)="onSuccess()"> </app-qualifications>
		<div class="row my-4" *ngIf="editState">
			<app-square-button
				theme="m1"
				value="Update"
				type="button"
				class="d-flex justify-content-center col-6 offset-3"
				(click)="submit()"
			>
			</app-square-button>
		</div>
	</div>
</div>
