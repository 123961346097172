import { PsychAssessmentCompletionStatusResponse } from '../../models/dto/responses/psych-assessment-completion-status-response.dtp';
import HttpPackage from '../../models/http-package';

export interface PsychAssessmentState {
	psychAssessmentCompletionStatusHttpPackage: HttpPackage<PsychAssessmentCompletionStatusResponse>;
}

export const initialState: PsychAssessmentState = {
	psychAssessmentCompletionStatusHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
