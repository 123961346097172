import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InterviewStoreEffects } from './effects';
import { interviewReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('interview', interviewReducer),
		EffectsModule.forFeature([InterviewStoreEffects]),
	],
	providers: [InterviewStoreEffects],
})
export class InterviewStoreModule {}
