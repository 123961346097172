import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { ReferenceStoreActions } from '.';
import { GetReferenceQuestions, SubmitReferenceScore } from './actions';
import { ReferenceService } from '../../services/reference/reference.service';
import ReferenceQuestionsResponse from '../../models/dto/responses/reference-questions-response.dto';

@Injectable()
export class ReferenceStoreEffects {
	constructor(private actions$: Actions, private referenceService: ReferenceService) {}

	getReferenceQuestionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceStoreActions.GetReferenceQuestions>(ReferenceStoreActions.ActionTypes.GET_REFERENCE_QUESTIONS),
			switchMap((action: GetReferenceQuestions) =>
				this.referenceService.getReferenceQuestions(action.payload.referenceId).pipe(
					map(
						(result: ReferenceQuestionsResponse) => new ReferenceStoreActions.GetReferenceQuestionsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new ReferenceStoreActions.GetReferenceQuestionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitReferenceScoreEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceStoreActions.SubmitReferenceScore>(ReferenceStoreActions.ActionTypes.SUBMIT_REFERENCE_SCORE),
			switchMap((action: SubmitReferenceScore) =>
				this.referenceService.score(action.payload.referenceScoreRequest).pipe(
					map(() => new ReferenceStoreActions.SubmitReferenceScoreSuccess({ result: 'Success' })),
					catchError((error: ErrorResponse) =>
						of(new ReferenceStoreActions.SubmitReferenceScoreError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
