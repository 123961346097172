import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-highlighted-text',
	templateUrl: './highlighted-text.component.html',
	styleUrls: ['./highlighted-text.component.scss'],
})
export class HighlightedTextComponent implements OnInit {
	@Input() theme = '';

	constructor() {}

	ngOnInit(): void {}
}
