import { Action } from '@ngrx/store';
import ReferenceScoreRequest from '../../models/dto/requests/reference-score-request.dto';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import ReferenceQuestionsResponse from '../../models/dto/responses/reference-questions-response.dto';

export enum ActionTypes {
	GET_REFERENCE_QUESTIONS = '[Reference] Get reference questions',
	GET_REFERENCE_QUESTIONS_SUCCESS = '[Reference] Get reference questions success',
	GET_REFERENCE_QUESTIONS_ERROR = '[Reference] Get reference questions error',

	SUBMIT_REFERENCE_SCORE = '[Reference] Submit reference score',
	SUBMIT_REFERENCE_SCORE_SUCCESS = '[Reference] Submit reference score success',
	SUBMIT_REFERENCE_SCORE_ERROR = '[Reference] Submit reference score error',

	RESET_REFERENCE_STATE = '[REFERENCE] Resetting reference state',
	RESET_REFERENCE_STATE_SUCCESS = '[REFERENCE] Resetting reference state success',
	RESET_REFERENCE_STATE_ERROR = '[REFERENCE] Resetting reference state error',
}

export class GetReferenceQuestions implements Action {
	readonly type = ActionTypes.GET_REFERENCE_QUESTIONS;
	constructor(public payload: { referenceId: string }) {}
}

export class GetReferenceQuestionsSuccess implements Action {
	readonly type = ActionTypes.GET_REFERENCE_QUESTIONS_SUCCESS;
	constructor(public payload: { result: ReferenceQuestionsResponse }) {}
}

export class GetReferenceQuestionsError implements Action {
	readonly type = ActionTypes.GET_REFERENCE_QUESTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitReferenceScore implements Action {
	readonly type = ActionTypes.SUBMIT_REFERENCE_SCORE;
	constructor(public payload: { referenceScoreRequest: ReferenceScoreRequest }) {}
}

export class SubmitReferenceScoreSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_REFERENCE_SCORE_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class SubmitReferenceScoreError implements Action {
	readonly type = ActionTypes.SUBMIT_REFERENCE_SCORE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class ResetReferenceState implements Action {
	readonly type = ActionTypes.RESET_REFERENCE_STATE;
}
export class ResetReferenceStateSuccess implements Action {
	readonly type = ActionTypes.RESET_REFERENCE_STATE_SUCCESS;
}
export class ResetReferenceStateError implements Action {
	readonly type = ActionTypes.RESET_REFERENCE_STATE_ERROR;
}

export type Actions =
	| GetReferenceQuestions
	| GetReferenceQuestionsSuccess
	| GetReferenceQuestionsError
	| SubmitReferenceScore
	| SubmitReferenceScoreSuccess
	| SubmitReferenceScoreError
	| ResetReferenceState
	| ResetReferenceStateSuccess
	| ResetReferenceStateError;
