import { initialState, ReferenceState } from './state';
import { Actions, ActionTypes } from './actions';

export function referenceReducer(state = initialState, action: Actions): ReferenceState {
	switch (action.type) {
		case ActionTypes.GET_REFERENCE_QUESTIONS:
			return {
				...state,
				getReferenceQuestionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_REFERENCE_QUESTIONS_SUCCESS:
			return {
				...state,
				getReferenceQuestionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_REFERENCE_QUESTIONS_ERROR:
			return {
				...state,
				getReferenceQuestionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_REFERENCE_SCORE:
			return {
				...state,
				submitReferenceScoreHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_REFERENCE_SCORE_SUCCESS:
			return {
				...state,
				submitReferenceScoreHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_REFERENCE_SCORE_ERROR:
			return {
				...state,
				submitReferenceScoreHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.RESET_REFERENCE_STATE:
			return {
				...state,
				submitReferenceScoreHttpPackage: {
					error: null,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
