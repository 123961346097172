import HttpPackage from '@models/http-package';
import { VideoUrlResponse } from '@models/dto/responses/video-url-response.dto';

export interface VideoState {
	createVideoHttpPackage: HttpPackage<string>;
	getVideoRecordingHttpPackage: HttpPackage<VideoUrlResponse[]>;
	getVideoCompositionHttpPackage: HttpPackage<string>;
	deleteVideoRecordingHttpPackage: HttpPackage<string>;
	deleteCompositionRecordingHttpPackage: HttpPackage<string>;
}

export const initialState: VideoState = {
	createVideoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getVideoRecordingHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getVideoCompositionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteVideoRecordingHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteCompositionRecordingHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
