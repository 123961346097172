import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { GenericOption } from '@models/generic-option.model';
import { DictionaryMap } from '@app/interfaces/dictionary-map.interface';
import { isNil, isObject } from '@app/utils/functions.util';

@Component({
	selector: 'app-chips-input-generic',
	templateUrl: './chips-input-generic.component.html',
	styleUrls: ['./chips-input-generic.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChipsInputGenericComponent),
			multi: true,
		},
	],
})
export class ChipsInputGenericComponent implements OnInit, ControlValueAccessor, OnDestroy {
	selectable = true;
	removable = true;
	addOnBlur = true;
	filteredChips: Observable<GenericOption[]>;
	optionsChanged: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	chipControl = new FormControl();
	readonly separatorKeysCodes = [ENTER, COMMA] as const;

	optionsMap: DictionaryMap<GenericOption> = {};
	private _options: GenericOption[];
	@Input() set options(options: GenericOption[]) {
		this._options = options;
		this.optionsChanged.next();
		this.optionsMap = {
			...this._options.reduce((acc, option) => {
				return {
					...acc,
					[option.value]: option,
				};
			}, {}),
		};
	}

	get options(): GenericOption[] {
		return this._options;
	}

	@Input() values: string[] = [];
	@Input() labelText: string;
	@Input() prefill: string[] = [];
	@Input() theme = 'chip-input';
	@Input() formControlName = '';
	@Input() errorMessage = '';
	@Input() hint = '';
	@Input() toolTip = '';
	@Input() showTip = false;

	@Output() chipsChange = new EventEmitter();

	readonly allowOther = false;
	required = false;
	localType = '';
	onChange: any = () => {};
	onTouched: any = () => {};

	@ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer) {
		this.filteredChips = combineLatest([
			this.chipControl.valueChanges.pipe(startWith(null), takeUntil(this.destroy$)),
			this.optionsChanged,
		]).pipe(
			map(([chip, _]: [GenericOption | string, ...any]) => (chip ? this._filter(chip) : this.options)),
			takeUntil(this.destroy$),
		);
	}

	ngOnInit(): void {
		this.prefill?.forEach((identifier: string) => {
			this.values.push(this.options?.find((option: GenericOption) => option.value == identifier)?.value ?? identifier);
		});
		this.writeValue(this.values);
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const options: SimpleChange = changes.options;
		if (options?.currentValue !== null && options?.previousValue === null) {
			this.values = [...this.values];
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (this.values.indexOf(event.option.value?.value) > -1) {
			return;
		}

		this.values = [...this.values, event.option.value?.value];
		this.chipInput.nativeElement.value = '';
		this.chipsChange.emit(this.values);
		this.chipControl.setValue(null);
		this.onChange(this.values);
	}

	onManualInput(event: any) {
		if (this.allowOther && this.values.indexOf(event.target.value) < 0 && event.target.value != '') {
			this.values = [...this.values, event.target.value];
			this.chipInput.nativeElement.value = '';
			this.chipsChange.emit(this.values);
			this.chipControl.setValue(null);
			this.onChange(this.values);
		}
	}

	remove(itemIndex: number): void {
		const identifiers = [...this.values];
		identifiers.splice(itemIndex, 1);
		this.writeValue(identifiers);
		this.chipsChange.emit(this.values);
		this.onChange(this.values);
	}

	private _filter(value: GenericOption | string): GenericOption[] {
		return this.options.filter(
			(option) =>
				isNil(value) ||
				(isObject(value) &&
					option.displayName.toLowerCase().includes((value as GenericOption)?.displayName.toLowerCase())) ||
				(!isObject(value) && option.displayName.toLowerCase().includes((value as string).toLowerCase())),
		);
	}

	public writeValue(value: string[]): void {
		this.values = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur(): void {
		this.onTouched();
	}
}
