import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { ReferenceScoreFormComponent } from '@app/references/components/reference-score-form/reference-score-form.component';
import { MatDialog } from '@angular/material/dialog';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';
import { DictionaryMap } from '@app/interfaces/dictionary-map.interface';
import ReferencePoints from '@models/dto/reference-points.dto';

@Component({
	selector: 'app-reference-summary',
	templateUrl: './reference-summary.component.html',
	styleUrls: ['./reference-summary.component.scss'],
})
export class ReferenceSummaryComponent implements OnInit {
	@Input() flinker: DetailedFlinkerAdminResponse;

	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	referenceScoreExistsMap: DictionaryMap<boolean> = {};
	referenceScoreMap: DictionaryMap<number> = {};
	constructor(private store: Store<RootStoreState.State>, public dialog: MatDialog) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				this.selectOptions = selectOptionsResponse.result;
			}),
		);
		this.flinker.referencePoints?.forEach((referencePoint: ReferencePoints) => {
			if (referencePoint.pointsTotal == 0) {
				this.referenceScoreMap[referencePoint.reference] = 0;
			} else {
				this.referenceScoreMap[referencePoint.reference] = Math.round(
					(referencePoint.pointsScored / (referencePoint.pointsTotal || 1)) * 100,
				);
			}
			this.referenceScoreExistsMap[referencePoint.reference] = true;
		});
	}

	openRefDialog(index: number): void {
		const dialogRef = this.dialog.open(ReferenceScoreFormComponent, {
			autoFocus: false,
			height: '500px',
			width: '1000px',
			data: {
				refId: this.flinker.references[index].id,
			},
		});
	}
}
