import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';

const routes: Routes = [
	{
		path: 'flinker-dashboard',
		canActivate: [AuthGuard],
		loadChildren: () => import('./flinker-dashboard/flinker-dashboard.module').then((m) => m.FlinkerDashboardModule),
	},
	{
		path: 'flinkco-dashboard',
		canActivate: [AuthGuard],
		loadChildren: () => import('./flinkco-dashboard/flinkco-dashboard.module').then((m) => m.FlinkCoDashboardModule),
	},
	{
		path: 'flinkadmin-dashboard',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./flinkadmin-dashboard/flinkadmin-dashboard.module').then((m) => m.FlinkAdminDashboardModule),
	},
	{
		path: 'auth',
		loadChildren: () => import('./authorization/authorization.module').then((m) => m.AuthorizationModule),
	},
	{
		path: 'refer',
		loadChildren: () => import('./job-adverts/job-adverts.module').then((m) => m.JobAdvertsModule),
	},
	{
		path: 'legal',
		loadChildren: () => import('./legal/legal.module').then((m) => m.LegalModule),
	},
	{
		path: 'take-on',
		loadChildren: () => import('./take-on/take-on.module').then((m) => m.TakeOnModule),
	},
	{
		path: 'references',
		loadChildren: () => import('./references/references.module').then((m) => m.ReferencesModule),
	},
	{
		path: 'careers',
		loadChildren: () => import('./careers/careers.module').then((m) => m.CareersModule),
	},
	{
		path: '**',
		redirectTo: '/auth/sign-in',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
