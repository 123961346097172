export enum InterviewStatus {
	Accepted = 'Accepted',
	Rejected = 'Rejected',
	WaitingForFlinker = 'WaitingForFlinker',
	RescheduleRequested = 'RescheduleRequested',
	Expired = 'Expired',
	Cancelled = 'Cancelled',
	Confirmed = 'Confirmed',
	VerificationPending = 'VerificationPending',
	VerificationFailed = 'VerificationFailed',
	OfferAccepted = 'OfferAccepted',
}

export function getInterviewStatusName(status: InterviewStatus, isFlinker: boolean): string {
	switch (status) {
		case InterviewStatus.WaitingForFlinker:
			return isFlinker ? 'FlinkCo is waiting for your response' : 'Waiting for flinker';
		case InterviewStatus.RescheduleRequested:
			return isFlinker ? 'Waiting for interview reschedule' : 'Reschedule requested';
		case InterviewStatus.VerificationPending:
			return isFlinker ? 'Verification pending' : 'Flinker verification pending';
		case InterviewStatus.VerificationFailed:
			return isFlinker ? 'Verification failed' : 'Flinker verification failed';
		case InterviewStatus.Rejected:
			return isFlinker ? 'Rejected' : 'Rejected by flinker';
		case InterviewStatus.OfferAccepted:
			return isFlinker ? 'You have accepted the offer' : 'Flinker has accepted the offer';
		default:
			return status;
	}
}

export function getInterviewStatusColour(status: InterviewStatus): string {
	switch (status) {
		case InterviewStatus.Accepted:
		case InterviewStatus.Confirmed:
		case InterviewStatus.OfferAccepted:
			return 'faded2';
		case InterviewStatus.RescheduleRequested:
			return 'faded10';
		case InterviewStatus.WaitingForFlinker:
			return 'faded10';
		case InterviewStatus.Rejected:
		case InterviewStatus.Cancelled:
		case InterviewStatus.Expired:
		case InterviewStatus.VerificationFailed:
			return 'faded5';
		case InterviewStatus.VerificationPending:
			return 'faded10';
		default:
			return 'faded2';
	}
}

export function getInterviewStatusBackgroundColour(status: InterviewStatus): string {
	switch (status) {
		case InterviewStatus.Accepted:
		case InterviewStatus.Confirmed:
		case InterviewStatus.OfferAccepted:
			return '#54c2803f';
		case InterviewStatus.RescheduleRequested:
			return '#ffe3a13f';
		case InterviewStatus.WaitingForFlinker:
			return '#ffe3a13f';
		case InterviewStatus.Rejected:
		case InterviewStatus.Cancelled:
		case InterviewStatus.Expired:
		case InterviewStatus.VerificationFailed:
			return '#ff454818';
		case InterviewStatus.VerificationPending:
			return 'ffe3a13f';
		default:
			return '#54c2803f';
	}
}

export function getInterviewStatusTextColour(status: InterviewStatus): string {
	switch (status) {
		case InterviewStatus.Accepted:
		case InterviewStatus.Confirmed:
		case InterviewStatus.OfferAccepted:
			return '#54c280';
		case InterviewStatus.RescheduleRequested:
			return '#ffb200';
		case InterviewStatus.WaitingForFlinker:
			return '#ffb200';
		case InterviewStatus.Rejected:
		case InterviewStatus.Cancelled:
		case InterviewStatus.Expired:
		case InterviewStatus.VerificationFailed:
			return '#ff4548';
		case InterviewStatus.VerificationPending:
			return 'ffb200';
		default:
			return '#54c280';
	}
}
