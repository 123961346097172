<div class="container cookies m-4" *ngIf="toggleCookies">
	<div class="row">
		<div class="col-12">
			<span class="font-size-10 front-sub-3 font-medium d-flex mx-auto px-1 pt-4">We use cookies!</span>
			<hr />
			<div>
				<span class="front-sub-3 font-medium cookies-text mx-auto px-1 pb-2"
					>This site uses cookies to function, please accept our
					<span class="front-main-1 font-medium" routerLink="../../legal/cookies">cookies services</span>
				</span>
			</div>
			<form [formGroup]="form">
				<app-checkbox class="d-block py-2" text="Do not show this again" formControlName="doNotShowAgain">
				</app-checkbox>
			</form>
			<div class="d-flex justify-content-between pb-3">
				<button mat-button class="d-flex front-main-1 px-5 w-100 justify-content-center" (click)="onToggleCookies()">
					Accept all
				</button>
			</div>
		</div>
	</div>
</div>
