import { Action } from '@ngrx/store';
import { CreateJobApplicationDto } from '@models/dto/requests/create-job-application.dto';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import { FilterJobAdsRequestDto } from '@models/dto/requests/filter-job-ads-request.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';

export enum ActionTypes {
	CREATE_JOB_APPLICATION = '[Flinker] create a job application',
	CREATE_JOB_APPLICATION_SUCCESS = '[Flinker] create a job application success',
	CREATE_JOB_APPLICATION_ERROR = '[Flinker] create a job application error',

	CREATE_INITIAL_JOB_APPLICATION = '[Flinker] create initial job application',
	CREATE_INITIAL_JOB_APPLICATION_SUCCESS = '[Flinker] create initial job application success',
	CREATE_INITIAL_JOB_APPLICATION_ERROR = '[Flinker] create initial job application error',

	FETCH_JOB_ADVERT_QUESTIONS = '[Flinker] fetch job advert questions',
	FETCH_JOB_ADVERT_QUESTIONS_SUCCESS = '[Flinker] fetch job advert questions success',
	FETCH_JOB_ADVERT_QUESTIONS_ERROR = '[Flinker] fetch job advert questions error',

	GET_JOB_ADVERT_FOR_FLINKER = '[Flinker] get job advert for a flinker',
	GET_JOB_ADVERT_FOR_FLINKER_SUCCESS = '[Flinker] get job advert for a flinker success',
	GET_JOB_ADVERT_FOR_FLINKER_ERROR = '[Flinker] get job advert for a flinker error',

	GET_FILTERED_JOB_ADVERTS = '[Flinker] get filtered job adverts',
	GET_FILTERED_JOB_ADVERTS_SUCCESS = '[Flinker] get filtered job adverts success',
	GET_FILTERED_JOB_ADVERTS_ERROR = '[Flinker] get filtered job adverts error',

	FAVOURITE_JOB_ADVERT = '[Flinker] Favourite job advert',
	FAVOURITE_JOB_ADVERT_SUCCESS = '[Flinker] Favourite job advert success',
	FAVOURITE_JOB_ADVERT_ERROR = '[Flinker] Favourite job advert error',
}

export class CreateJobApplication implements Action {
	readonly type = ActionTypes.CREATE_JOB_APPLICATION;
	constructor(public payload: { jobAdvertId: string; createJobApplicationDto: CreateJobApplicationDto }) {}
}

export class CreateJobApplicationSuccess implements Action {
	readonly type = ActionTypes.CREATE_JOB_APPLICATION_SUCCESS;
	constructor(public payload: { result: JobApplicationResponse }) {}
}

export class CreateJobApplicationError implements Action {
	readonly type = ActionTypes.CREATE_JOB_APPLICATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateInitialJobApplication implements Action {
	readonly type = ActionTypes.CREATE_INITIAL_JOB_APPLICATION;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class CreateInitialJobApplicationSuccess implements Action {
	readonly type = ActionTypes.CREATE_INITIAL_JOB_APPLICATION_SUCCESS;
	constructor(public payload: { result: JobApplicationResponse }) {}
}

export class CreateInitialJobApplicationError implements Action {
	readonly type = ActionTypes.CREATE_INITIAL_JOB_APPLICATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchJobAdvertQuestions implements Action {
	readonly type = ActionTypes.FETCH_JOB_ADVERT_QUESTIONS;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class FetchJobAdvertQuestionsSuccess implements Action {
	readonly type = ActionTypes.FETCH_JOB_ADVERT_QUESTIONS_SUCCESS;
	constructor(public payload: { result: QuestionAnswerResponseDto[] }) {}
}

export class FetchJobAdvertQuestionsError implements Action {
	readonly type = ActionTypes.FETCH_JOB_ADVERT_QUESTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetJobAdvertForFlinker implements Action {
	readonly type = ActionTypes.GET_JOB_ADVERT_FOR_FLINKER;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class GetJobAdvertForFlinkerSuccess implements Action {
	readonly type = ActionTypes.GET_JOB_ADVERT_FOR_FLINKER_SUCCESS;
	constructor(public payload: { result: FlinkerJobAdvertResponse }) {}
}

export class GetJobAdvertForFlinkerError implements Action {
	readonly type = ActionTypes.GET_JOB_ADVERT_FOR_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFilteredJobAdverts implements Action {
	readonly type = ActionTypes.GET_FILTERED_JOB_ADVERTS;
	constructor(public payload: { filteredJobAdvertsDto: FilterJobAdsRequestDto }) {}
}

export class GetFilteredJobAdvertsSuccess implements Action {
	readonly type = ActionTypes.GET_FILTERED_JOB_ADVERTS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<JobAdvertResponseDto> }) {}
}

export class GetFilteredJobAdvertsError implements Action {
	readonly type = ActionTypes.GET_FILTERED_JOB_ADVERTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FavouriteJobAdvert implements Action {
	readonly type = ActionTypes.FAVOURITE_JOB_ADVERT;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class FavouriteJobAdvertSuccess implements Action {
	readonly type = ActionTypes.FAVOURITE_JOB_ADVERT_SUCCESS;
	constructor(public payload: { result: JobAdvertResponseDto }) {}
}

export class FavouriteJobAdvertError implements Action {
	readonly type = ActionTypes.FAVOURITE_JOB_ADVERT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateJobApplication
	| CreateJobApplicationSuccess
	| CreateJobApplicationError
	| CreateInitialJobApplication
	| CreateInitialJobApplicationSuccess
	| CreateInitialJobApplicationError
	| FetchJobAdvertQuestions
	| FetchJobAdvertQuestionsSuccess
	| FetchJobAdvertQuestionsError
	| GetJobAdvertForFlinker
	| GetJobAdvertForFlinkerSuccess
	| GetJobAdvertForFlinkerError
	| GetFilteredJobAdverts
	| GetFilteredJobAdvertsSuccess
	| GetFilteredJobAdvertsError
	| FavouriteJobAdvert
	| FavouriteJobAdvertSuccess
	| FavouriteJobAdvertError;
