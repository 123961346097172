import { Component, Input, OnInit, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SelectOptionResponse } from 'projects/flink-app/src/app/models/dto/responses/select-option-response.dto';

@Component({
	selector: 'app-thin-outlined-dropdown',
	templateUrl: './thin-outlined-dropdown.component.html',
	styleUrls: ['./thin-outlined-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ThinOutlinedDropdownComponent),
			multi: true,
		},
	],
})
export class ThinOutlinedDropdownComponent implements OnInit {
	@Input() errorMessage = '';
	@Input() options: SelectOptionResponse[];
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';

	value = '';
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;
	selectedValue!: string;
	selectedCar!: string;

	constructor() {}

	ngOnInit(): void {}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: MatSelectChange): void {
		this.onChange(event.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
