import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-user-picture',
	templateUrl: './user-picture.component.html',
	styleUrls: ['./user-picture.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class UserPictureComponent implements OnInit {
	@Input() theme = '';
	@Input() src = '';
	@Input() alt = '';
	@Input() size = 120;
	@Input() round = false;

	readonly maxInitialCount: number = 4;
	readonly roundCornerRadius: number = 50;
	readonly squareCornerRadius: number = 10;

	constructor() {}

	ngOnInit(): void {}
}
