<div class="d-flex align-items-center" [ngClass]="theme" [class.button-container]="labelText" [class.p-2]="labelText">
  <ng-template #loadingSpinner>
    <div class="pl-2">
      <mat-progress-spinner  mode="indeterminate" class="loading"  [ngClass]="iconTheme" [diameter]="progressDiameter" *ngIf="loading"></mat-progress-spinner>
    </div>
  </ng-template>
	<button class="mt-0 rounded-button" *ngIf="!loading else loadingSpinner" [disabled]="loading">
		<mat-icon [ngClass]="iconTheme" class="icon" [matTooltip]="tooltip">{{ icon }}</mat-icon>
	</button>
	<span class="label-text font-size-11 font-medium pl-2">
		{{ labelText }}
	</span>
</div>
