import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FlinkerTakeOnStoreActions } from '.';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { FlinkCoNamesResponse } from '@models/dto/responses/flinkCo-names-response.dto';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import ProfileCompletionItem from '@models/dto/responses/profile-completion-item.dto';
import ReactivationRequestResponse from '@models/dto/responses/reactivation-request-response.dto';
import { FlinkerService } from '@app/services/flinker/flinker.service';
import { FlinkerTakeOnService } from '@app/services/take-on/flinker-take-on.service';
import {
	CreateReactivationRequest,
	SubmitFlinkerCriminalDeclaration,
	SubmitFlinkerEducationalDetails,
	SubmitFlinkerEmploymentDetails,
	SubmitFlinkerPersonalDetails,
	SubmitFlinkerTermsAndConditions,
	UploadEmploymentDocuments,
	UploadIdDocument,
	UploadPassportDocument,
	UploadProfilePicture,
	UploadQualificationDocuments,
} from './actions';

@Injectable()
export class FlinkerTakeOnStoreEffects {
	constructor(
		private actions$: Actions,
		private takeOnService: FlinkerTakeOnService,
		private flinkerService: FlinkerService,
	) {}

	submitFlinkerPersonalDetailsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetails>(
				FlinkerTakeOnStoreActions.ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS,
			),
			switchMap((action: SubmitFlinkerPersonalDetails) =>
				this.takeOnService.submitPersonalDetails(action.payload.flinkerPersonalDetailsRequest).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetailsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetailsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.GetFlinker>(FlinkerTakeOnStoreActions.ActionTypes.GET_FLINKER),
			switchMap(() =>
				this.flinkerService.getFlinker().pipe(
					map(
						(flinkerResponse: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.GetFlinkerSuccess({
								flinkerResponse,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.GetFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	setFlinkerCVPromotionDateEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SetFlinkerCVPromotionDate>(
				FlinkerTakeOnStoreActions.ActionTypes.SET_FLINKER_CV_PROMOTION_DATE,
			),

			switchMap(() =>
				this.flinkerService.setCvPromotionDate().pipe(
					map(
						(flinkerResponse: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SetFlinkerCVPromotionDateSuccess({
								result: flinkerResponse,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SetFlinkerCVPromotionDateError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getProfileCompletionItemsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.GetProfileCompletion>(
				FlinkerTakeOnStoreActions.ActionTypes.GET_PROFILE_COMPLETION,
			),
			switchMap(() =>
				this.flinkerService.getProfileCompletionItems().pipe(
					map(
						(result: ProfileCompletionItem[]) =>
							new FlinkerTakeOnStoreActions.GetProfileCompletionSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.GetProfileCompletionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkerEmploymentDetailsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SubmitFlinkerEmploymentDetails>(
				FlinkerTakeOnStoreActions.ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS,
			),
			switchMap((action: SubmitFlinkerEmploymentDetails) =>
				this.takeOnService.submitEmploymentDetails(action.payload.flinkerEmploymentDetailsRequest).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SubmitFlinkerEmploymentDetailsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SubmitFlinkerEmploymentDetailsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkerEducationalDetailsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SubmitFlinkerEducationalDetails>(
				FlinkerTakeOnStoreActions.ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS,
			),
			switchMap((action: SubmitFlinkerEducationalDetails) =>
				this.takeOnService.submitEducationalDetails(action.payload.flinkerEducationalDetailsRequest).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SubmitFlinkerEducationalDetailsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SubmitFlinkerEducationalDetailsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkerCriminalDeclarationEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SubmitFlinkerCriminalDeclaration>(
				FlinkerTakeOnStoreActions.ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION,
			),
			switchMap((action: SubmitFlinkerCriminalDeclaration) =>
				this.takeOnService.submitCriminalDeclaration(action.payload.flinkerCriminalDeclarationRequest).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SubmitFlinkerCriminalDeclarationSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SubmitFlinkerCriminalDeclarationError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkerTermsAndConditionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.SubmitFlinkerTermsAndConditions>(
				FlinkerTakeOnStoreActions.ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS,
			),
			switchMap((action: SubmitFlinkerTermsAndConditions) =>
				this.takeOnService.submitFlinkerTermsAndConditions(action.payload.flinkerTermsAndConditionsRequest).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.SubmitFlinkerTermsAndConditionsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.SubmitFlinkerTermsAndConditionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadIdDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.UploadIdDocument>(FlinkerTakeOnStoreActions.ActionTypes.UPLOAD_ID_DOCUMENT),
			switchMap((action: UploadIdDocument) =>
				this.takeOnService.uploadIdDocument(action.payload.formData).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.UploadIdDocumentSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.UploadIdDocumentError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadPassportDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.UploadPassportDocument>(
				FlinkerTakeOnStoreActions.ActionTypes.UPLOAD_PASSPORT_DOCUMENT,
			),
			switchMap((action: UploadPassportDocument) =>
				this.takeOnService.uploadPassportDocument(action.payload.formData).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.UploadPassportDocumentSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.UploadPassportDocumentError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadEmploymentDocumentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.UploadEmploymentDocuments>(
				FlinkerTakeOnStoreActions.ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS,
			),
			switchMap((action: UploadEmploymentDocuments) =>
				this.takeOnService.uploadEmploymentDocuments(action.payload.formData).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.UploadEmploymentDocumentsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.UploadEmploymentDocumentsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadQualificationDocumentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.UploadQualificationDocuments>(
				FlinkerTakeOnStoreActions.ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS,
			),
			switchMap((action: UploadQualificationDocuments) =>
				this.takeOnService.uploadQualificationDocuments(action.payload.formData).pipe(
					map(
						(result: FlinkerResponse) =>
							new FlinkerTakeOnStoreActions.UploadQualificationDocumentsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.UploadQualificationDocumentsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	uploadProfilePictureEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.UploadProfilePicture>(
				FlinkerTakeOnStoreActions.ActionTypes.UPLOAD_PROFILE_PICTURE,
			),
			switchMap((action: UploadProfilePicture) =>
				this.takeOnService.uploadProfilePicture(action.payload.formData).pipe(
					map(
						(result: string) =>
							new FlinkerTakeOnStoreActions.UploadProfilePictureSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.UploadProfilePictureError({ errorResponse: error })),
					),
				),
			),
		);
	});

	createReactivationRequestEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.CreateReactivationRequest>(
				FlinkerTakeOnStoreActions.ActionTypes.CREATE_REACTIVATION_REQUEST,
			),
			switchMap((action: CreateReactivationRequest) =>
				this.flinkerService.createReactivationRequest(action.payload.createReactivationRequestDto).pipe(
					map(
						(_) =>
							new FlinkerTakeOnStoreActions.CreateReactivationRequestSuccess({
								result: 'Success',
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.CreateReactivationRequestError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getReactivationRequestEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.GetReactivationRequest>(
				FlinkerTakeOnStoreActions.ActionTypes.GET_REACTIVATION_REQUEST,
			),
			switchMap(() =>
				this.flinkerService.getReactivationRequest().pipe(
					map(
						(result: ReactivationRequestResponse) =>
							new FlinkerTakeOnStoreActions.GetReactivationRequestSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.GetReactivationRequestError({ errorResponse: error })),
					),
				),
			),
		);
	});
	getFlinkCosListEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerTakeOnStoreActions.GetFlinkCoList>(FlinkerTakeOnStoreActions.ActionTypes.GET_FLINKCO_LIST),
			switchMap(() =>
				this.flinkerService.getFlinkCoNames().pipe(
					map((result: FlinkCoNamesResponse[]) => {
						return new FlinkerTakeOnStoreActions.GetFlinkCoListSuccess({
							result,
						});
					}),
					catchError((error: ErrorResponse) =>
						of(new FlinkerTakeOnStoreActions.GetFlinkCoListError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
