import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
	@Input() labelText = '';
	@Input() theme = '';

	constructor() {}

	ngOnInit(): void {}
}
