<div class="d-flex my-5 justify-content-center flex-column-reverse flex-md-row">
	<div class="col-12 col-md-4 mt-4 mt-md-0">
		<app-square-button theme="m5" (click)="cancel.emit()" value="Cancel">
		</app-square-button>
	</div>
	<div class="col-12 col-md-4">
		<app-square-button theme="m1" [value]="submitText" (click)="submit.emit()" [disabled]="disabled">
		</app-square-button>
	</div>
</div>
