import { Injectable } from '@angular/core';
import InterviewStatusRequest from '@models/dto/requests/interview-status-request.dto';
import ScheduleInterviewRequest from '@models/dto/requests/schedule-interview-request.dto';
import InterviewResponse from '@models/dto/responses/interview-response.dto';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import InterviewerWithInterviewQuestionsResponse from '@models/dto/responses/interviewer-with-interview-questions-response.dto';
import SaveInterviewerQuestionAnswersRequest from '@models/dto/requests/save-interviewer-question-answers-request.dto';
import InterviewQuestionsScoreTableResponse from '@models/dto/responses/interview-questions-score-table-response.dto';
import FlinkCoInterviewsFilterRequestDto from '@models/dto/requests/flink-co-interviews-filter-request.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

@Injectable({
	providedIn: 'root',
})
export class InterviewService {
	apiInterviewEndpoint = 'interview';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	createInvite(flinkerId: string, searchId: string) {
		return this.httpV2Service.postSecure<void>(`${this.apiInterviewEndpoint}/invite/${flinkerId}/${searchId}`, {});
	}

	getFlinkCoInterviews(filterInterviewsListDto: FlinkCoInterviewsFilterRequestDto) {
		return this.httpV2Service.postSecure<PaginatedResponse<InterviewResponse>>(
			`${this.apiInterviewEndpoint}/flinkCo`,
			filterInterviewsListDto,
		);
	}

	getFlinkerInterviews() {
		return this.httpV2Service.getSecure<InterviewResponse[]>(`${this.apiInterviewEndpoint}/flinker`);
	}

	getFlinkCoInterview(interviewId: string) {
		return this.httpV2Service.getSecure<InterviewResponse>(`${this.apiInterviewEndpoint}/flinkCo/${interviewId}`);
	}

	getFlinkerInterview(interviewId: string) {
		return this.httpV2Service.getSecure<InterviewResponse>(`${this.apiInterviewEndpoint}/flinker/${interviewId}`);
	}

	cancelInterview(interviewId: string, interviewStatusRequest: InterviewStatusRequest) {
		return this.httpV2Service.postSecure<InterviewResponse>(
			`${this.apiInterviewEndpoint}/${interviewId}/cancel`,
			interviewStatusRequest,
		);
	}

	scheduleInterview(interviewId: string, scheduleInterviewRequest: ScheduleInterviewRequest) {
		return this.httpV2Service.putSecure<string>(
			`${this.apiInterviewEndpoint}/${interviewId}`,
			scheduleInterviewRequest,
		);
	}

	updateInterviewStatus(interviewId: string, interviewStatusRequest: InterviewStatusRequest) {
		return this.httpV2Service.postSecure<InterviewResponse>(
			`${this.apiInterviewEndpoint}/${interviewId}/status`,
			interviewStatusRequest,
		);
	}
	getFlinkerDocuments(interviewId: string, documentId: string, documentType: FlinkerDocumentType) {
		switch (documentType) {
			case FlinkerDocumentType.IdDocument:
			case FlinkerDocumentType.PassportDocument: {
				return this.httpV2Service.downloadFileGet(
					`${this.apiInterviewEndpoint}/${interviewId}/flinker-identification-document`,
				);
			}
			case FlinkerDocumentType.QualificationDocuments:
			case FlinkerDocumentType.Matric: {
				const qualificationId: string = documentId;
				return this.httpV2Service.downloadFileGet(
					`${this.apiInterviewEndpoint}/${interviewId}/flinker-qualification-documents/${qualificationId}`,
				);
			}
		}
	}

	downloadFlinkerCv(flinkerId: string) {
		return this.httpV2Service.downloadFileGet(`cv/flink-co/${flinkerId}`);
	}

	getCurrentInterviewerWithInterviewQuestions(interviewId: string) {
		return this.httpV2Service.getSecure<InterviewerWithInterviewQuestionsResponse>(
			`${this.apiInterviewEndpoint}/flinkCo/${interviewId}/questions`,
		);
	}

	updateInterviewerQuestionAnswers(
		interviewId: string,
		interviewerQuestionAnswersRequest: SaveInterviewerQuestionAnswersRequest,
	) {
		return this.httpV2Service.postSecure<InterviewerWithInterviewQuestionsResponse>(
			`${this.apiInterviewEndpoint}/flinkCo/${interviewId}/questions`,
			interviewerQuestionAnswersRequest,
		);
	}

	getInterviewQuestionsScoreTable(interviewId: string) {
		return this.httpV2Service.getSecure<InterviewQuestionsScoreTableResponse>(
			`${this.apiInterviewEndpoint}/flinkCo/${interviewId}/questions/scores`,
		);
	}
}
