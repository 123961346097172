<div class="d-flex" [ngClass]="theme">
	<div
		class="circle d-flex justify-content-center align-items-center"
		[style.height]="getBackgroundSize()"
		[style.width]="getBackgroundSize()"
	>
		<mat-icon [style.font-size]="getIconSize()" [style.height]="getIconSize()" [style.width]="getIconSize()"
			>{{ icon }}
		</mat-icon>
	</div>
	<span class="label-text font-size-11 font-medium pl-2">
		{{ labelText }}
	</span>
</div>
