<app-loader *ngIf="loading"></app-loader>
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!loading">
	<div class="container-row">
		<app-heading theme="m1" heading="Verify OTP"></app-heading>
	</div>
	<div class="container-row">
		<p>We've sent an email with an OTP to {{ email }}</p>
	</div>
	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			type="text"
			placeholder="OTP"
			icon="lock"
			formControlName="otp"
			[errorMessage]="getErrorMessage(form.controls.email, 'OTP', shouldValidate)"
		></app-rounded-input>
	</div>
	<div class="container-row">
		<p>Didn't receive an OTP? <a href="" (click)="$event.preventDefault(); resendOtp()">Resend</a></p>
	</div>
	<div class="container-row">
		<app-rounded-button theme="m1" type="submit" value="Verify" class="my-2" [disabled]="!form.valid">
		</app-rounded-button>
		<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
	</div>
</form>
