import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
})
export class ArrayJoinPipe implements PipeTransform {
	transform(input: string[], seperator: string): any {
		return input.join(seperator);
	}
}
