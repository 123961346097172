import FlinkerAdminResponse from '@flinkadmin-dashboard/models/response/flinker-admin-response.dto';
import ReactivationRequestResponse from '@flinkadmin-dashboard/models/response/reactivation-requests-response.dto';
import FlinkerReferenceScore from '@models/dto/responses/flinker-reference-score.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import HttpPackage from '@models/http-package';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';

export interface FlinkerAdminState {
	fetchAllFlinkersHttpPackage: HttpPackage<PaginatedResponse<FlinkerAdminResponse>>;
	updateFlinkerHttpPackage: HttpPackage<string>;
	fetchAllReactivationRequestsHttpPackage: HttpPackage<PaginatedResponse<ReactivationRequestResponse>>;
	updateReactivationRequestStatusHttpPackage: HttpPackage<string>;
	getDetailedFlinkerPackage: HttpPackage<DetailedFlinkerAdminResponse>;
	fetchFlinkerReferenceScorePackage: HttpPackage<FlinkerReferenceScore>;
	loginAsFlinkerPackage: HttpPackage<SignInResponse>;
	fetchFlinkerDocumentsPackage: HttpPackage<any>;
	deleteFlinkerPackage: HttpPackage<any>;
	downloadFlinkerCv: HttpPackage<any>;
	downloadObfuscatedFlinkerCv: HttpPackage<any>;
	downloadFlinkerCvLoadingFlinkers: { [flinkerId: string]: boolean };
	downloadObfuscatedFlinkerCvLoadingFlinkers: { [flinkerId: string]: boolean };
	downloadAdminReport: HttpPackage<any>;
}

export const initialState: FlinkerAdminState = {
	fetchAllFlinkersHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	updateFlinkerHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	fetchAllReactivationRequestsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	updateReactivationRequestStatusHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getDetailedFlinkerPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchFlinkerReferenceScorePackage: {
		error: null,
		loading: false,
		result: null,
	},
	loginAsFlinkerPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchFlinkerDocumentsPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteFlinkerPackage: {
		error: null,
		loading: false,
		result: null,
	},
	downloadFlinkerCv: {
		error: null,
		loading: false,
		result: null,
	},

	downloadAdminReport: {
		error: null,
		loading: false,
		result: null,
	},

	downloadObfuscatedFlinkerCv: {
		error: null,
		loading: false,
		result: null,
	},

	downloadFlinkerCvLoadingFlinkers: {},

	downloadObfuscatedFlinkerCvLoadingFlinkers: {},
};
