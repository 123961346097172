<app-loader *ngIf="loading"></app-loader>
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!loading">
	<div class="container-row">
		<app-heading theme="m1" heading="Forgot Password"></app-heading>
	</div>
	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			type="email"
			placeholder="Email"
			icon="supervisor_account"
			formControlName="email"
			[errorMessage]="getErrorMessage(form.controls.email, 'Email', shouldValidate)"
		></app-rounded-input>
	</div>
	<div class="container-row">
		<app-rounded-button theme="m1" type="submit" value="Reset Password" class="my-2" [disabled]="!form.valid">
		</app-rounded-button>
		<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
	</div>
	<div class="d-flex flex-column justify-content-center align-items-center mt-3" *ngIf="resetRequestSuccessful">
		<h4>Password reset email sent to {{ form.controls.email.value }}</h4>
		<mat-icon class="front-main-3">check</mat-icon>
	</div>
</form>
