import FlinkCoResponse from '@flinkadmin-dashboard/models/response/flink-co-admin-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import HttpPackage from '@models/http-package';
import SearchResponse from '@models/dto/responses/search-response.dto';
import AdminSearchResponse from '@flinkadmin-dashboard/models/response/admin-search-response.dto';
import AdminSearchResultResponseDto from '@flinkadmin-dashboard/models/response/admin-search-result-response.dto';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';

export interface FlinkCoAdminState {
	fetchAllFlinkCosHttpPackage: HttpPackage<PaginatedResponse<FlinkCoResponse>>;
	fetchAllQuotesHttpPackage: HttpPackage<PaginatedResponse<FlinkCoResponse>>;
	updateFlinkCoHttpPackage: HttpPackage<string>;
	cancelRetainerSubscriptionHttpPackage: HttpPackage<string>;
	loginAsFlinkCoPackage: HttpPackage<SignInResponse>;
	deleteFlinkCoHttpPackage: HttpPackage<any>;
	getSelectedFlinkCoPackage: HttpPackage<FlinkCoResponse>;
	fetchFlinkCoSearchesHttpPackage: HttpPackage<SearchResponse[]>;
	fetchActiveSearchHttpPackage: HttpPackage<AdminSearchResponse>;
	getActiveSearchResultsPackage: HttpPackage<AdminSearchResultResponseDto>;
	addFlinkerToSearchResultsPackage: HttpPackage<AdminMatchedFlinkerResponse>;
	removeFlinkerFromSearchResultsPackage: HttpPackage<AdminMatchedFlinkerResponse>;
}

export const initialState: FlinkCoAdminState = {
	fetchAllFlinkCosHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchAllQuotesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateFlinkCoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	cancelRetainerSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	loginAsFlinkCoPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteFlinkCoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getSelectedFlinkCoPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchFlinkCoSearchesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchActiveSearchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getActiveSearchResultsPackage: {
		error: null,
		loading: false,
		result: null,
	},
	addFlinkerToSearchResultsPackage: {
		error: null,
		loading: false,
		result: null,
	},
	removeFlinkerFromSearchResultsPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
