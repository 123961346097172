import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CvAdminStoreEffects } from './effects';
import { cvAdminReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('cvAdminDashboard', cvAdminReducer),
		EffectsModule.forFeature([CvAdminStoreEffects]),
	],
	providers: [CvAdminStoreEffects],
})
export class CvAdminStoreModule {}
