import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import { NotifierService } from 'angular-notifier';
import { Injectable } from '@angular/core';
import { NotificationType } from '@app/enums/notification-type.enum';
import { AuthService } from '@app/services/auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private readonly notifierService: NotifierService, private readonly authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
					// https://github.com/angular/angular/issues/19888
					// When request of type Blob, the error is also in Blob instead of object of the json data
					return new Promise<any>((resolve, reject) => {
						const reader: FileReader = new FileReader();
						reader.onload = (e: Event) => {
							try {
								const errmsg = JSON.parse((<any>e.target).result);
								reject(
									new HttpErrorResponse({
										error: errmsg,
										headers: err.headers,
										status: err.status,
										statusText: err.statusText,
										url: err.url,
									}),
								);
							} catch (e) {
								reject(err);
							}
						};
						reader.onerror = (e) => {
							reject(err);
						};
						reader.readAsText(err.error);
					});
				}
				return throwError(err);
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status !== 401) {
					this.notifierService.notify(
						NotificationType.Error,
						(error.error as ErrorResponseV2)?.friendlyMessage || 'An unknown error occurred',
					);
				} else {
					if (this.authService.getJwtToken() != null && this.authService.getJwtToken() !== '') {
						this.notifierService.notify(NotificationType.Error, 'Your session has expired, please log in again.');
					}
				}
				return throwError(error.error as ErrorResponseV2);
			}),
		);
	}
}
