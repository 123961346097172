import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import { AuthService } from '@app/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { FlinkerTakeOnStoreActions } from '@root-store/flinker-take-on-store';
import { Subject, Subscription } from 'rxjs';
import { FlinkCoTakeOnStoreActions } from '@root-store/flinkco-take-on-store';
import { AccountType } from '@app/enums/account-type.enum';
import { delay } from 'rxjs/operators';

@Component({
	selector: 'app-token-sign-in',
	templateUrl: './token-sign-in.component.html',
	styleUrls: ['./token-sign-in.component.scss'],
})
export class TokenSignInComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();

	private readonly destroy$ = new Subject<void>();
	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private store: Store,
		private router: Router,
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			const request: SignInResponse = {
				refreshExpiry: params.refresh_expires_at,
				jwtExpiry: params.jwt_expires_at,
				jwtToken: params.jwt_token,
				userAccounts: [],
			};
			this.authService.setSession(request, true);
			const type = params.type;

			switch (type) {
				case 'flinker':
					this.authService
						.getFlinkerSession()
						.pipe(delay(2000))
						.subscribe(
							(signInResponse) => {
								if (
									signInResponse.userAccounts.length == 1 &&
									signInResponse.userAccounts[0].account.accountType.name === AccountType.Flinker
								) {
									this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
								} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkCompanyMaster) {
									this.store.dispatch(new FlinkCoTakeOnStoreActions.GetFlinkCo());
								} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkAdmin) {
									this.router.navigate(['/flinkadmin-dashboard']);
								} else {
									this.router.navigate(['/flinker-dashboard']);
								}
							},
							(error) => console.error(error),
						);
					break;
				case 'flinkco':
					this.authService
						.getFlinkCoSession()
						.pipe(delay(2000))
						.subscribe(
							(signInResponse) => {
								if (
									signInResponse.userAccounts.length == 1 &&
									signInResponse.userAccounts[0].account.accountType.name === AccountType.Flinker
								) {
									this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
								} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkCompanyMaster) {
									this.store.dispatch(new FlinkCoTakeOnStoreActions.GetFlinkCo());
								} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkAdmin) {
									this.router.navigate(['/flinkadmin-dashboard']);
								} else {
									this.router.navigate(['/flinkco-dashboard']);
								}
							},
							(error) => console.error(error),
						);
					break;
			}
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
