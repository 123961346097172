<div class="image-container mb-4 d-flex justify-content-center mx-auto">
	<div class="overlay justify-content-center align-items-center front-sub-1 font-size-10" (click)="fileUpload.click()">
		Upload
	</div>
	<app-user-picture
		class="avatar"
		*ngIf="!loading"
		[src]="src"
		[size]="200"
		[alt]="alt"
		[round]="true"
		(click)="fileUpload.click()"
	>
	</app-user-picture>
	<ngx-skeleton-loader
		*ngIf="loading"
		class="m-auto"
		count="1"
		appearance="circle"
		[theme]="{ width: '200px', height: '200px', margin: 'auto' }"
	>
	</ngx-skeleton-loader>
	<input type="file" [accept]="validExtensions" hidden #fileUpload (change)="onFileSelected($event)" />
</div>
