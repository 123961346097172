import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { NewFlinkCoJobAdvertDto } from '@flinkco-dashboard/models/new-flinkco-job-advert.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import { FilterJobAdsRequestDto } from '@models/dto/requests/filter-job-ads-request.dto';
import { JobAdvertReferralRequestDto } from '@models/dto/requests/job-advert-referral-request.dto';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';

@Injectable({
	providedIn: 'root',
})
export class JobAdvertService {
	apiVersion = environment.apiVersion;
	apiBaseUrl = `${environment.apiBaseUrl}/${this.apiVersion}`;
	apiJobAdvertEndpoint = 'job-adverts';

	constructor(private httpClient: HttpClient, private httpV2Service: HttpV2Service) {}

	getJobAdvertForFlinker(jobAdvertId: string) {
		return this.httpV2Service.getSecure<FlinkerJobAdvertResponse>(
			`${this.apiJobAdvertEndpoint}/flinker/${jobAdvertId}`,
		);
	}

	getFlinkCoJobAdverts(pageSize: number, pageNumber: number) {
		return this.httpV2Service.getSecure<PaginatedResponse<JobAdvertResponseDto>>(
			`${this.apiJobAdvertEndpoint}/flinkCo?pageSize=${pageSize}&pageNumber=${pageNumber}`,
		);
	}

	getFlinkCoCareerPageJobAdverts(flinkCoId: string, filterJobAdvertsRequest: FilterJobAdsRequestDto) {
		return this.httpV2Service.postSecure<PaginatedResponse<JobAdvertResponseDto>>(
			`${this.apiJobAdvertEndpoint}/careersPage/${flinkCoId}`,
			filterJobAdvertsRequest,
		);
	}

	getFlinkCoJobAdvert(jobAdvertId: string) {
		return this.httpV2Service.getSecure<JobAdvertResponseDto>(`${this.apiJobAdvertEndpoint}/${jobAdvertId}`);
	}
	getPublicFlinkCoJobAdvert(jobAdvertId: string) {
		return this.httpV2Service.getSecure<JobAdvertResponseDto>(`${this.apiJobAdvertEndpoint}/${jobAdvertId}/public`);
	}
	getFlinkCoIframeLink() {
		return this.httpV2Service.getSecure<string>(`${this.apiJobAdvertEndpoint}/iframe`);
	}

	createFlinkCoJobAdverts(newJobAdvert: NewFlinkCoJobAdvertDto) {
		return this.httpV2Service.postSecure<JobAdvertResponseDto>(`${this.apiJobAdvertEndpoint}`, newJobAdvert);
	}

	getFilteredJobAdverts(filterJobAdvertsDto: FilterJobAdsRequestDto) {
		return this.httpV2Service.postSecure<PaginatedResponse<JobAdvertResponseDto>>(
			`${this.apiJobAdvertEndpoint}/filter`,
			filterJobAdvertsDto,
		);
	}

	updateFlinkCoJobAdverts(jobAdvert: JobAdvertResponseDto) {
		return this.httpV2Service.putSecure<JobAdvertResponseDto>(
			`${this.apiJobAdvertEndpoint}/${jobAdvert.id}`,
			jobAdvert,
		);
	}

	getJobAdvertQuestions(jobAdvertId: string) {
		return this.httpV2Service.getSecure<QuestionAnswerResponseDto[]>(
			`${this.apiJobAdvertEndpoint}/${jobAdvertId}/questions`,
		);
	}

	referJobAdvertPublic(jobAdvertReferral: JobAdvertReferralRequestDto) {
		return this.httpV2Service.postSecure<string>(
			`${this.apiJobAdvertEndpoint}/${jobAdvertReferral.jobAdvertId}/public/share`,
			jobAdvertReferral,
		);
	}

	referJobAdvert(jobAdvertReferral: JobAdvertReferralRequestDto) {
		return this.httpV2Service.postSecure<string>(
			`${this.apiJobAdvertEndpoint}/${jobAdvertReferral.jobAdvertId}/share`,
			jobAdvertReferral,
		);
	}

	favouriteJobAdvert(jobAdvertId: string) {
		return this.httpV2Service.postSecure<FlinkerJobAdvertResponse>(
			`${this.apiJobAdvertEndpoint}/${jobAdvertId}/favourite`,
			{},
		);
	}
}
