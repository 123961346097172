import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getInterviewStatusBackgroundColour, getInterviewStatusTextColour } from '@app/enums/interview-status.enum';
import InterviewResponse from '@models/dto/responses/interview-response.dto';
import { RootStoreState } from '@root-store';
import { InterviewStoreActions, InterviewStoreSelectors } from '@root-store/interview-store';

@Component({
	selector: 'app-flinker-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
	events: any[] = [];

	subscriptions = new Subscription();
	getFlinkerInterviewsHttpPackage$ = this.store.select(InterviewStoreSelectors.selectGetFlinkerInterviewsHttpPackage);
	flinkerInterviews: InterviewResponse[];
	getInterviewStatusBackgroundColour = getInterviewStatusBackgroundColour;
	getInterviewStatusTextColour = getInterviewStatusTextColour;

	constructor(private store: Store<RootStoreState.State>, private router: Router) {}

	ngOnInit(): void {
		this.store.dispatch(new InterviewStoreActions.GetFlinkerInterviews());

		this.subscriptions.add(
			this.getFlinkerInterviewsHttpPackage$.subscribe((getFlinkerInterviewsHttpPackage) => {
				if (getFlinkerInterviewsHttpPackage.result !== null) {
					this.flinkerInterviews = getFlinkerInterviewsHttpPackage.result;
					this.events = [];
					this.flinkerInterviews.forEach((interview) => {
						this.events.push({
							title: 'Interview with ' + interview.flinkCo.registeredName,
							start: interview.startDate,
							end: interview.endDate,
							color: this.getInterviewStatusBackgroundColour(interview.status),
							textColor: this.getInterviewStatusTextColour(interview.status),
							extendedProps: {
								interviewId: interview.id,
							},
						});
					});
				}
			}),
		);
	}

	viewDetailedInterview(e: any) {
		this.router.navigateByUrl('flinker-dashboard/interview/' + e.event.extendedProps.interviewId);
	}
}
