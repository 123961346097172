import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-circle-icon',
	templateUrl: './circle-icon.component.html',
	styleUrls: ['./circle-icon.component.scss'],
})
export class CircleIconComponent implements OnInit {
	@Input() theme = 'font-size-11';
	@Input() icon = '';
	@Input() size = '1x';
	@Input() active = false;
	@Input() labelText = '';

	constructor() {}

	ngOnInit(): void {}

	getBackgroundSize(): string {
		switch (this.size) {
			case '1x':
				return '28px';
			default:
				return '28px';
		}
	}

	getIconSize(): string {
		switch (this.size) {
			case '1x':
				return '16px';
			default:
				return '16px';
		}
	}
}
