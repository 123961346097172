<div class='p-1 p-md-4'>
  <app-dashboard-section-header
    icon='supervisor_account'
    title='Profile'
    [editIcon]='true'
    (edit)='editState = !editState'
  >
  </app-dashboard-section-header>

  <ng-container *ngIf='(flinker$ | async) as flinker'>
    <div class='justify-content-center profile-details' *ngIf='!editState'>
      <div class='col-12 pt-4'>
        <div class='row'>
          <div class='col-12 col-xl-6'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Name & Surname:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.name }}
                {{ flinker?.surname }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Email address:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.emailAddress }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Contact number:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.cellphoneNumber }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0' *ngIf='flinker?.southAfricanCitizen'>
              <app-bold-label label='ID number:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.idNumber }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0' *ngIf='!flinker?.southAfricanCitizen'>
              <app-bold-label label='Passport number:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.passportNumber }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Date of Birth:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ moment(flinker?.dateOfBirth).format('Do MMM YYYY') }}
						</span>
            </div>
          </div>

          <div class='col-12 col-xl-6'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Drivers license:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.driversLicenseTypes,
                  flinker?.driversLicense
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Own a vehicle:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                flinker?.ownsVehicle | yesOrNo
                }}
						</span>
            </div>


            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Gender:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName((selectOptionsHttpPackage$ | async)?.result?.genders, flinker?.gender)
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Race:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ getSelectOptionName((selectOptionsHttpPackage$ | async)?.result?.races, flinker?.race) }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Disability:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.disabled | yesOrNo }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Vaccinated:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.hasTakenCovidVaccine | yesOrNo }}
						</span>
            </div>
          </div>

          <div class='col-12 col-xl-6 mt-4'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Language:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.languages,
                  flinker?.languageProficiency1
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0' *ngIf='flinker?.languageProficiency2'>
              <app-bold-label label='Second language:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.languages,
                  flinker?.languageProficiency2
                )
                }}
						</span>
            </div>
          </div>
        </div>
      </div>

      <div class='col-12 pt-2'>
        <div class='row'>
          <div class='col-12 col-xl-6 mt-4'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Employment type:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.placements,
                  flinker?.placement
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Work space preference:' class='d-block font-bold font-size-11 col-6 px-0'>
              </app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.workspaces,
                  flinker?.workspacePreference
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Willing to relocate:' class='d-block font-bold font-size-11 col-6 px-0'>
              </app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.willingToRelocate | yesOrNo }}
						</span>
            </div>
          </div>

          <div class='col-12 col-md-6 mt-4'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Ideal work province:' class='d-block font-bold font-size-11 col-6 px-0'>
              </app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ provinces.join(', ') }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Ideal work city:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ cities.join(', ') }}
						</span>
            </div>
          </div>
        </div>
      </div>

      <div class='col-12 pt-2'>
        <div class='row'>
          <div class='col-12 col-xl-6 mt-4'>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Seeking status:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.availabilityStatuses,
                  flinker?.availabilityStatus
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Visiblity status:' class='d-block font-bold font-size-11 col-6 px-0'>
              </app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.profileVisibilities,
                  flinker?.visibilityStatus
                )
                }}
						</span>
            </div>
            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Current CTC:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							R{{ flinker?.currentSalary }} p/m
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='{{dictionaryWords.Salary}} bracket:'
                              class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							R{{ flinker?.salaryBandLower }} - R{{ flinker?.salaryBandUpper }} p/m
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Notice period:' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{
                getSelectOptionName(
                  (selectOptionsHttpPackage$ | async)?.result?.noticePeriods,
                  flinker?.noticePeriod
                )
                }}
						</span>
            </div>

            <div class='col-12 d-flex pb-1 px-0'>
              <app-bold-label label='Additional leave benefits:' class='d-block font-bold font-size-11 col-6 px-0'>
              </app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.additionalLeaveBenefits | yesOrNo }}
						</span>
            </div>
          </div>
        </div>
      </div>

      <div class='col-12 pt-2'>
        <div class='row'>
          <div class='col-12 xl-4'>
            <div class='col-12 pb-1 px-0'>
              <app-bold-label label='Biography' class='d-block font-bold font-size-11 col-6 px-0'></app-bold-label>
              <span class='front-sub-5 font-size-11 font-regular center-icon col-6 px-0'>
							{{ flinker?.nextJobRequirement }}
						</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template>

  </ng-template>
  <div>
    <div class='row pt-2'>
      <div class='col-12 d-md-flex d-block px-0'>
        <div class='w-100'>
          <div class='container-fluid'>
            <form class='container mt-4' *ngIf='editState' [formGroup]='form'>
              <app-page-header-toolbar icon='work' heading='Personal Information'></app-page-header-toolbar>

              <div class='row'>
                <app-outlined-input
                  class='col-12 mb-1'
                  formControlName='name'
                  labelText='Name'
                  type='text'
                  [errorMessage]="getErrorMessage(form.controls.name, 'Name', shouldValidate)"
                >
                </app-outlined-input>

                <app-outlined-input
                  class='col-12 mb-1'
                  formControlName='surname'
                  labelText='Surname'
                  type='text'
                  [errorMessage]="getErrorMessage(form.controls.surname, 'Surname', shouldValidate)"
                >
                </app-outlined-input>

                <app-outlined-input
                  class='col-12'
                  labelText='Email'
                  formControlName='email'
                  type='email'
                  [errorMessage]="getErrorMessage(form.controls.email, 'Email', shouldValidate)"
                >
                </app-outlined-input>

                <app-outlined-input
                  class='col-12 mb-1'
                  formControlName='cellphoneNumber'
                  type='text'
                  labelText='Cell phone number'
                  [errorMessage]="getErrorMessage(form.controls.cellphoneNumber, 'Cell phone number', shouldValidate)"
                >
                </app-outlined-input>

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Are you a South African citizen'
                  formControlName='southAfricanCitizen'
                  [errorMessage]="getErrorMessage(form.controls.southAfricanCitizen, 'Citizenship', shouldValidate)"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-input
                  class='col-12'
                  labelText='ID number'
                  formControlName='idNumber'
                  type='number'
                  [errorMessage]="getErrorMessage(form.controls.idNumber, 'ID number', shouldValidate)"
                  *ngIf="form.controls.southAfricanCitizen.value !== 'N'"
                >
                </app-outlined-input>

                <app-outlined-input
                  class='col-12'
                  labelText='Passport number'
                  formControlName='passportNumber'
                  type='text'
                  [errorMessage]="getErrorMessage(form.controls.passportNumber, 'Passport number', shouldValidate)"
                  *ngIf="form.controls.southAfricanCitizen.value === 'N'"
                >
                </app-outlined-input>

                <app-outlined-file-input
                  class='col-12'
                  labelText='Upload your ID'
                  buttonInput='Upload'
                  formControlName='idUpload'
                  [uploaded]='idDocumentUploaded'
                  [toolTip]="
				'Upload a form of Identity document like: \n South African ID \n Passport \n Drivers License \n' +
				fileTypeMessage"
                  [showTip]='true'
                  [errorMessage]="getErrorMessage(form.controls.idUpload, 'ID upload', shouldValidate)"
                  (fileSelected)='uploadIdDocument($event)'
                  *ngIf="form.controls.southAfricanCitizen.value !== 'N'"
                >
                </app-outlined-file-input>

                <app-outlined-file-input
                  class='col-12'
                  labelText='Upload your passport'
                  buttonInput='Upload'
                  formControlName='passportUpload'
                  [uploaded]='idDocumentUploaded'
                  [toolTip]="'Upload your Passport document' + fileTypeMessage"
                  [errorMessage]="getErrorMessage(form.controls.passportUpload, 'Passport upload', shouldValidate)"
                  (fileSelected)='uploadPassportDocument($event)'
                  *ngIf="form.controls.southAfricanCitizen.value === 'N'"
                >
                </app-outlined-file-input>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='What type of drivers license do you have?'
                  formControlName='driversLicense'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.driversLicenseTypes'
                  [errorMessage]="getErrorMessage(form.controls.driversLicense, 'Drivers license type', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Do you own a vehicle?'
                  formControlName='ownsVehicle'
                  [errorMessage]="getErrorMessage(form.controls.ownsVehicle, 'Do you a own vehicle', shouldValidate)"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-date-input
                  class='col-12'
                  labelText='Date of Birth'
                  formControlName='dateOfBirth'
                  [errorMessage]="getErrorMessage(form.controls.dateOfBirth, 'Date of Birth', shouldValidate)"
                >
                </app-outlined-date-input>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Gender'
                  formControlName='gender'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.genders'
                  [errorMessage]="getErrorMessage(form.controls.gender, 'Gender', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Race'
                  formControlName='race'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.races'
                  [errorMessage]="getErrorMessage(form.controls.race, 'Race', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Do you have a disability?'
                  formControlName='disabled'
                  [errorMessage]="getErrorMessage(form.controls.disabled, 'Disability', shouldValidate)"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-autocomplete
                  class='col-12'
                  labelText='Language proficiency 1'
                  formControlName='languageProficiency1'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.languages'
                  [errorMessage]="
										getErrorMessage(form.controls.languageProficiency2, 'Language proficiency 1', shouldValidate)
									"
                >
                </app-outlined-autocomplete>

                <app-outlined-autocomplete
                  class='col-12'
                  labelText='Language proficiency 2'
                  formControlName='languageProficiency2'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.languages'
                  [errorMessage]="
										getErrorMessage(form.controls.languageProficiency2, 'Language proficiency 2', shouldValidate)
									"
                >
                </app-outlined-autocomplete>

                <!-- TODO: Add back preferred Method of contact -->

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Are you registered with a professional body?'
                  [showTip]='true'
                  [toolTip]="
										'What is a professional body: A Professional Body is an organisation with individual members practicing a profession or occupation in which the organisation maintains an oversight of the knowledge, skills, conduct and practice of that profession or occupation.\n'
									"
                  formControlName='registeredWithProfessionalBody'
                  [errorMessage]="
										getErrorMessage(
											form.controls.registeredWithProfessionalBody,
											'Professional body registration',
											shouldValidate
										)
									"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-autocomplete
                  class='col-12'
                  labelText='Professional body 1'
                  formControlName='professionalBody1'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.professionalBodies'
                  [errorMessage]="
										getErrorMessage(form.controls.professionalBody1, 'Professional body registration', shouldValidate)
									"
                  *ngIf="form.controls.registeredWithProfessionalBody.value === 'Y'"
                >
                </app-outlined-autocomplete>

                <app-outlined-autocomplete
                  class='col-12'
                  labelText='Professional body 2'
                  formControlName='professionalBody2'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.professionalBodies'
                  [errorMessage]="
										getErrorMessage(form.controls.professionalBody2, 'Professional body registration', shouldValidate)
									"
                  *ngIf="form.controls.registeredWithProfessionalBody.value === 'Y'"
                >
                </app-outlined-autocomplete>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Placement type'
                  formControlName='placement'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.placements'
                  [errorMessage]="getErrorMessage(form.controls.placement, 'Placement type', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Work space preference'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.workspaces'
                  formControlName='workspacePreference'
                  [errorMessage]="
										getErrorMessage(form.controls.workspacePreference, 'Work space preference', shouldValidate)
									"
                >
                </app-outlined-dropdown>

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Willing to relocate?'
                  formControlName='willingToRelocate'
                  [errorMessage]="getErrorMessage(form.controls.willingToRelocate, 'Relocation', shouldValidate)"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-input
                  class='col-12'
                  labelText='Your current monthly {{dictionaryWords.Salary}}'
                  formControlName='currentSalary'
                  type='number'
                  [bottomText]='dictionaryHints.CurrentSalary'
                >

                </app-outlined-input>

                <app-outlined-input
                  class='col-12'
                  labelText='{{dictionaryWords.Salary}} bracket lower'
                  type='number'
                  formControlName='salaryBandLower'
                  [errorMessage]="
										getErrorMessage(form.controls.salaryBandLower, dictionaryWords.Salary + ' bracket lower', shouldValidate)
									"
                >
                </app-outlined-input>

                <app-outlined-input
                  class='col-12'
                  labelText='{{dictionaryWords.Salary}} bracket upper'
                  type='number'
                  formControlName='salaryBandUpper'
                  [errorMessage]="
										getErrorMessage(form.controls.salaryBandUpper, dictionaryWords.Salary + ' bracket upper', shouldValidate)
									"
                >
                </app-outlined-input>

                <app-outlined-text-area
                  class='col-12'
                  labelText='Biography'
                  maxLength='{{entityConfig?.flinker?.maxNextJobRequirement}}'
                  formControlName='jobRequirement'
                  bottomText='Tell us about your personality, any interesting facts about yourself, your goals and aspirations'
                  [errorMessage]="getErrorMessage(form.controls.jobRequirement, 'Biography', shouldValidate)"
                >
                </app-outlined-text-area>

                <app-outlined-boolean-dropdown
                  class='col-12'
                  labelText='Do you need additional leave benefits?'
                  [showTip]='true'
                  [toolTip]="
										'This is to ensure that any prospective employers will take these benefits into consideration when viewing your profile versus what they have to offer\n'
									"
                  formControlName='additionalLeaveBenefits'
                  [errorMessage]="
										getErrorMessage(form.controls.additionalLeaveBenefits, 'Leave benefits', shouldValidate)
									"
                >
                </app-outlined-boolean-dropdown>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Notice period'
                  formControlName='noticePeriod'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.noticePeriods'
                  [errorMessage]="getErrorMessage(form.controls.noticePeriod, 'Notice period', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-outlined-dropdown
                  class='col-12'
                  labelText='Where did you hear about us?'
                  formControlName='marketingMethod'
                  [options]='(selectOptionsHttpPackage$ | async)?.result?.marketingMethods'
                  [errorMessage]="getErrorMessage(form.controls.marketingMethod, 'Marketing method', shouldValidate)"
                >
                </app-outlined-dropdown>

                <app-checkbox
                  class='col-12 covid-vaccination-status'
                  text='Have you been vaccinated or received a booster in the last 12 months?'
                  formControlName='hasTakenCovidVaccine'
                  [errorMessage]="getErrorMessage(form.controls.hasTakenCovidVaccine, 'Vaccination status', shouldValidate)"
                >
                </app-checkbox>
              </div>

              <div class='mt-5'>
                <app-page-header-toolbar icon='turned_in' heading='Applying To'></app-page-header-toolbar>

                <div class='row'>
                  <app-chips-input
                    class='col-12 mb-m-4'
                    labelText='Ideal work province'
                    formControlName='idealWorkProvince'
                    theme='outlined-input'
                    [options]='(selectOptionsHttpPackage$ | async)?.result?.provinces'
                    hint='{{dictionaryHints.ProvinceSelection}}'
                    (chipsChange)='filterCities($event)'
                    [errorMessage]="getErrorMessage(form.controls.idealWorkProvince, 'Province', shouldValidate)"
                  >
                  </app-chips-input>

                  <app-chips-input
                    class='col-12 top-padding'
                    labelText='Ideal work locations (Cities)'
                    formControlName='idealWorkCities'
                    theme='outlined-input'
                    #citiesChipInput
                    [values]='cities'
                    [options]='(filteredCitiesInProvinces$ | async) || (selectOptionsHttpPackage$ | async)?.result?.cities'
                    [errorMessage]="getErrorMessage(form.controls.idealWorkCities, 'Cities', shouldValidateCities)"
                  >
                  </app-chips-input>
                </div>
              </div>

              <div class='mt-5'>
                <app-page-header-toolbar icon='work' heading='Job Status'></app-page-header-toolbar>

                <div class='row'>
                  <app-outlined-dropdown
                    class='col-12'
                    labelText='Seeking status'
                    formControlName='availabilityStatus'
                    [options]='(selectOptionsHttpPackage$ | async)?.result?.availabilityStatuses'
                    [errorMessage]="getErrorMessage(form.controls.availabilityStatus, 'Seeking status', shouldValidate)"
                  >
                  </app-outlined-dropdown>

                  <app-outlined-dropdown
                    class='col-12'
                    labelText='Profile visibility'
                    formControlName='visibilityStatus'
                    bottomText='This controls your visiblity to recruiters'
                    [options]='(selectOptionsHttpPackage$ | async)?.result?.profileVisibilities'
                    [errorMessage]="
											getErrorMessage(form.controls.visibilityStatus, 'Profile visibility', shouldValidate)
										"
                  >
                  </app-outlined-dropdown>
                </div>
              </div>

              <div class='col-12 pt-4'>
                <app-square-button
                  matStepperNext
                  theme='m1'
                  value='Update'
                  type='button'
                  class='d-flex justify-content-center mw-350'
                  [disabled]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                  [loading]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                  (click)='submit()'
                >
                </app-square-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
