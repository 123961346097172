import { PageTitleComponent } from './page-title/page-title.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SquareButtonComponent } from './square-button/square-button.component';
import { RoundedButtonComponent } from './rounded-button/rounded-button.component';
import { RoundedInputComponent } from './rounded-input/rounded-input.component';
import { HeadingComponent } from './heading/heading.component';
import { MatIconModule } from '@angular/material/icon';
import { RoundedCheckboxComponent } from './rounded-checkbox/rounded-checkbox.component';
import { LinkComponent } from './link/link.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { InputErrorMessageComponent } from './input-error-message/input-error-message.component';
import { OutlinedInputComponent } from './outlined-input/outlined-input.component';
import { OutlinedDropdownComponent } from './outlined-dropdown/outlined-dropdown.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { OutlinedTextAreaComponent } from './outlined-text-area/outlined-text-area.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SafePipe } from './pipes/safe.pipe';
import { OutlinedAutocompleteComponent } from './outlined-autocomplete/outlined-autocomplete.component';
import { OutlinedBooleanDropdownComponent } from './outlined-boolean-dropdown/outlined-boolean-dropdown.component';
import { OutlinedFileInputComponent } from './outlined-file-input/outlined-file-input.component';
import { SectionHeadingComponent } from './section-heading/section-heading.component';
import { OutlinedDateInputComponent } from './outlined-date-input/outlined-date-input.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipsInputComponent } from './chips-input/chips-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { LoaderComponent } from './loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardContainerComponent } from './mat-card-container/mat-card-container.component';
import { MatCardModule } from '@angular/material/card';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardSectionHeaderComponent } from './dashboard-section-header/dashboard-section-header.component';
import { ThinOutlinedInputComponent } from './thin-outlined-input/thin-outlined-input.component';
import { ThinOutlinedDropdownComponent } from './thin-outlined-dropdown/thin-outlined-dropdown.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { PageHeaderToolbarComponent } from './page-header-toolbar/page-header-toolbar.component';
import { SliderComponent } from './slider/slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormSubmitButtonsComponent } from './form-submit-buttons/form-submit-buttons.component';
import { RankingListComponent } from './ranking-list/ranking-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { SquareButtonWithIconComponent } from './square-button-with-icon/square-button-with-icon.component';
import { CircleIconButtonComponent } from './circle-icon-button/circle-icon-button.component';
import { LabelButtonComponent } from './label-button/label-button.component';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { StatusComponent } from './status/status.component';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { HighlightedTextComponent } from './highlighted-text/highlighted-text.component';
import { UserPictureComponent } from './user-picture/user-picture.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TitleCasePipe } from './pipes/title-case.pipe';
import {
	NgxMatDateFormats,
	NgxMatDatetimePickerModule,
	NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { OutlinedDateTimeInputComponent } from './outlined-date-time-input/outlined-date-time-input.component';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin, momentPlugin, timeGridPlugin]);

export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'DD/MM/YYYY HH:mm';
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
	},
	display: {
		dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

import { CircleIconComponent } from './circle-icon/circle-icon.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PiechartComponent } from './piechart/piechart.component';
import { ChartsModule } from 'ng2-charts';
import { AvatarModule } from 'ngx-avatar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RatingComponent } from './rating/rating.component';
import { BoldLabelComponent } from './bold-label/bold-label.component';
import { SwitchToggleComponent } from './switch-toggle/switch-toggle.component';
import { PopupNotificationComponent } from './popup-notification/popup-notification.component';
import { OutlinedExperienceAutocompleteComponent } from './outlined-autocomplete/outlined-experience-autocomplete/outlined-experience-autocomplete/outlined-experience-autocomplete.component';
import { YesOrNoPipe } from './pipes/yes-or-no.pipe';
import { SortSelectOptionsPipe } from './pipes/sort-select-options.pipe';
import { InputTransformDirective } from './directives/input-transform.directive';
import { ArrayJoinPipe } from '@shared/pipes/array-join.pipe';
import { TruncateStringPipe } from '@shared/pipes/truncate-string.pipe';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment as appEnvironment } from 'projects/flink-app/src/environments/environment';
import { HasRoleDirective } from './directives/has-role.directive';
import { SelectOptionNameByCodePipe } from './pipes/select-option-name-by-code.pipe';
import { SelectOptionNameByIdPipe } from '@shared/pipes/select-option-name-by-id.pipe';
import { InterviewStatusNamePipe } from './pipes/interview-status-name.pipe';
import { HasInterviewStatusPipe } from './pipes/has-interview-status.pipe';
import { SquareInputComponent } from './square-input/square-input.component';
import { CustomSpinnerDirective } from './directives/custom-spinner.directive';
import { CvFeatureTypeUserDescriptionPipe } from './pipes/cv-feature-type-user-description.pipe';
import { ButtonTabComponent } from './button-tab/button-tab.component';
import { JobAdvertCardComponent } from './job-advert-card/job-advert-card.component';
import { OutlinedDropdownGenericComponent } from '@shared/outlined-dropdown-generic/outlined-dropdown-generic.component';
import { SearchQuestionListComponent } from './search-question-list/search-question-list.component';
import { FlinkBrandedModalComponent } from './flink-branded-modal/flink-branded-modal.component';
import { OutlinedAutocompleteGenericComponent } from '@shared/outlined-autocomplete-generic/outlined-autocomplete-generic.component';
import { OutlinedMultiSelectListComponent } from './outlined-multi-select-list/outlined-multi-select-list.component';
import { OutlinedViewDraggableListComponent } from './outlined-view-draggable-list/outlined-view-draggable-list.component';
import { DeleteConfirmationDialogComponent } from './dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ChipsInputGenericComponent } from '@shared/chips-input-generic/chips-input-generic.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { FlinkerDocumentsComponent } from '@shared/flinker-documents/flinker-documents.component';

@NgModule({
	declarations: [
		PageTitleComponent,
		RoundedButtonComponent,
		RoundedInputComponent,
		SquareButtonComponent,
		HeadingComponent,
		RoundedCheckboxComponent,
		LinkComponent,
		ErrorMessageComponent,
		InputErrorMessageComponent,
		OutlinedInputComponent,
		OutlinedDropdownComponent,
		VideoPlayerComponent,
		OutlinedTextAreaComponent,
		SafePipe,
		OutlinedFileInputComponent,
		OutlinedAutocompleteComponent,
		OutlinedBooleanDropdownComponent,
		SectionHeadingComponent,
		OutlinedDateInputComponent,
		CheckboxComponent,
		ChipsInputComponent,
		LoaderComponent,
		MatCardContainerComponent,
		DashboardHeaderComponent,
		DashboardSectionHeaderComponent,
		ThinOutlinedInputComponent,
		ThinOutlinedDropdownComponent,
		SquareButtonWithIconComponent,
		PageHeadingComponent,
		PageHeaderToolbarComponent,
		SliderComponent,
		FormSubmitButtonsComponent,
		RankingListComponent,
		RangeSliderComponent,
		CircleIconButtonComponent,
		LabelButtonComponent,
		StatusComponent,
		PhoneFormatPipe,
		DateFormatPipe,
		ProfilePictureComponent,
		HighlightedTextComponent,
		UserPictureComponent,
		TooltipComponent,
		TitleCasePipe,
		OutlinedDateTimeInputComponent,
		CircleIconComponent,
		CalendarComponent,
		NotificationBannerComponent,
		PiechartComponent,
		RatingComponent,
		BoldLabelComponent,
		SwitchToggleComponent,
		PopupNotificationComponent,
		OutlinedExperienceAutocompleteComponent,
		YesOrNoPipe,
		SortSelectOptionsPipe,
		InputTransformDirective,
		ArrayJoinPipe,
		TruncateStringPipe,
		HasRoleDirective,
		SelectOptionNameByCodePipe,
		SelectOptionNameByIdPipe,
		InterviewStatusNamePipe,
		HasInterviewStatusPipe,
		SquareInputComponent,
		CustomSpinnerDirective,
		CvFeatureTypeUserDescriptionPipe,
		ButtonTabComponent,
		JobAdvertCardComponent,
		OutlinedDropdownGenericComponent,
		SearchQuestionListComponent,
		FlinkBrandedModalComponent,
		OutlinedAutocompleteGenericComponent,
		OutlinedMultiSelectListComponent,
		OutlinedViewDraggableListComponent,
		DeleteConfirmationDialogComponent,
		ChipsInputGenericComponent,
		SlideToggleComponent,
		FlinkerDocumentsComponent,
	],
	imports: [
		CommonModule,
		MatIconModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatDatepickerModule,
		FormsModule,
		MatChipsModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatCardModule,
		MatExpansionModule,
		NgxSliderModule,
		DragDropModule,
		MatTooltipModule,
		NgxMatDatetimePickerModule,
		NgxMatMomentModule,
		FullCalendarModule,
		ChartsModule,
		AvatarModule,
		MatButtonToggleModule,
		NgxSkeletonLoaderModule,
		RouterModule,
		LoggerModule.forRoot({
			level: appEnvironment.loggingLevel,
			serverLogLevel: NgxLoggerLevel.ERROR,
		}),
		MatSlideToggleModule,
		MatDialogModule,
	],
	exports: [
		RoundedButtonComponent,
		SquareButtonComponent,
		RoundedInputComponent,
		PageTitleComponent,
		HeadingComponent,
		RoundedCheckboxComponent,
		LinkComponent,
		ErrorMessageComponent,
		VideoPlayerComponent,
		OutlinedDropdownComponent,
		OutlinedInputComponent,
		OutlinedTextAreaComponent,
		OutlinedFileInputComponent,
		OutlinedAutocompleteComponent,
		OutlinedBooleanDropdownComponent,
		SectionHeadingComponent,
		OutlinedDateInputComponent,
		CheckboxComponent,
		ChipsInputComponent,
		LoaderComponent,
		MatCardContainerComponent,
		DashboardHeaderComponent,
		DashboardSectionHeaderComponent,
		ThinOutlinedInputComponent,
		ThinOutlinedDropdownComponent,
		SquareButtonWithIconComponent,
		PageHeadingComponent,
		PageHeaderToolbarComponent,
		SliderComponent,
		FormSubmitButtonsComponent,
		RankingListComponent,
		RangeSliderComponent,
		CircleIconButtonComponent,
		LabelButtonComponent,
		RouterModule,
		StatusComponent,
		PhoneFormatPipe,
		DateFormatPipe,
		ProfilePictureComponent,
		HighlightedTextComponent,
		UserPictureComponent,
		TooltipComponent,
		TitleCasePipe,
		OutlinedDateTimeInputComponent,
		CircleIconComponent,
		CalendarComponent,
		NotificationBannerComponent,
		PiechartComponent,
		RatingComponent,
		BoldLabelComponent,
		SwitchToggleComponent,
		PopupNotificationComponent,
		OutlinedExperienceAutocompleteComponent,
		YesOrNoPipe,
		SortSelectOptionsPipe,
		InputTransformDirective,
		ArrayJoinPipe,
		TruncateStringPipe,
		LoggerModule,
		HasRoleDirective,
		SelectOptionNameByCodePipe,
		SelectOptionNameByIdPipe,
		InterviewStatusNamePipe,
		HasInterviewStatusPipe,
		SquareInputComponent,
		CustomSpinnerDirective,
		CvFeatureTypeUserDescriptionPipe,
		ButtonTabComponent,
		JobAdvertCardComponent,
		OutlinedDropdownGenericComponent,
		SearchQuestionListComponent,
		FlinkBrandedModalComponent,
		OutlinedAutocompleteGenericComponent,
		OutlinedMultiSelectListComponent,
		OutlinedViewDraggableListComponent,
		InputErrorMessageComponent,
		DeleteConfirmationDialogComponent,
		ChipsInputGenericComponent,
		SlideToggleComponent,
		FlinkerDocumentsComponent,
	],
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
		{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
		{ provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
	],
})
export class SharedModule {}
