<h2 mat-dialog-title class="text-center">Are you sure you want to cancel?</h2>
<div mat-dialog-content class="text-center">
	<span> Any unsaved progress will be lost. </span>
</div>

<div mat-dialog-actions class="justify-content-center pb-3 row d-flex flex-column-reverse flex-md-row">
	<app-square-button theme="gh-m5 small" class="col-12 col-md-6 mt-4" value="Logout" type="button" (click)="logout()">
	</app-square-button>

	<app-square-button
		theme="m1 small"
		class="col-12 col-md-6 mt-4"
		value="Continue Take-on"
		type="button"
		(click)="closeDialog()"
	>
	</app-square-button>
</div>
