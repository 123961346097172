import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FlinkCoFlinkerDocumentDownloadService } from '@app/services/flinkCo/flinker-documents/flinkco-flinker-document-download.service';
import * as fromActions from './actions';
import { saveAs } from 'file-saver';
import { DownloadedFile } from '@app/services/http/http-v2.service';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';

@Injectable()
export class FlinkCoFlinkerDocumentEffects {
	constructor(
		private actions$: Actions,
		private flinkerDocumentDownloadService: FlinkCoFlinkerDocumentDownloadService,
	) {}

	downloadFlinkerIdDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.DownloadFlinkerIdDocument>(fromActions.ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT),
			switchMap((action: fromActions.DownloadFlinkerIdDocument) =>
				this.flinkerDocumentDownloadService
					.downloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.IdDocument)
					.pipe(
						tap((response: DownloadedFile) => {
							saveAs(response.file, response.filename);
						}),
						map((response: DownloadedFile) => new fromActions.DownloadFlinkerIdDocumentSuccess(response)),
						catchError((error: ErrorResponse) => of(new fromActions.DownloadFlinkerIdDocumentError({ error }))),
					),
			),
		),
	);

	downloadFlinkerMatricDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.DownloadFlinkerMatricDocument>(fromActions.ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT),
			switchMap((action: fromActions.DownloadFlinkerMatricDocument) =>
				this.flinkerDocumentDownloadService
					.downloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.Matric)
					.pipe(
						tap((response: DownloadedFile) => {
							saveAs(response.file, response.filename);
						}),
						map((response: DownloadedFile) => new fromActions.DownloadFlinkerMatricDocumentSuccess(response)),
						catchError((error: ErrorResponse) => of(new fromActions.DownloadFlinkerMatricDocumentError({ error }))),
					),
			),
		),
	);

	downloadFlinkerHighestQualificationDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.DownloadFlinkerHighestQualificationDocument>(
				fromActions.ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT,
			),
			switchMap((action: fromActions.DownloadFlinkerHighestQualificationDocument) =>
				this.flinkerDocumentDownloadService
					.downloadDocument(
						action.payload.flinkerId,
						action.payload.searchId,
						FlinkerDocumentType.QualificationDocuments,
					)
					.pipe(
						tap((response: DownloadedFile) => {
							saveAs(response.file, response.filename);
						}),
						map(
							(response: DownloadedFile) =>
								new fromActions.DownloadFlinkerHighestQualificationDocumentSuccess(response),
						),
						catchError((error: ErrorResponse) =>
							of(new fromActions.DownloadFlinkerHighestQualificationDocumentError({ error })),
						),
					),
			),
		),
	);

	downloadFlinkerPassportDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.DownloadFlinkerPassportDocument>(fromActions.ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT),
			switchMap((action: fromActions.DownloadFlinkerPassportDocument) =>
				this.flinkerDocumentDownloadService
					.downloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.PassportDocument)
					.pipe(
						tap((response: DownloadedFile) => {
							saveAs(response.file, response.filename);
						}),
						map((response: DownloadedFile) => new fromActions.DownloadFlinkerPassportDocumentSuccess(response)),
						catchError((error: ErrorResponse) => of(new fromActions.DownloadFlinkerPassportDocumentError({ error }))),
					),
			),
		),
	);

	canDownloadFlinkerIdDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.CanDownloadFlinkerIdDocument>(fromActions.ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT),
			switchMap((action: fromActions.CanDownloadFlinkerIdDocument) =>
				this.flinkerDocumentDownloadService
					.canDownloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.IdDocument)
					.pipe(
						map((response) => new fromActions.CanDownloadFlinkerIdDocumentSuccess(response.canDownload)),
						catchError((error: ErrorResponse) => of(new fromActions.CanDownloadFlinkerIdDocumentError({ error }))),
					),
			),
		),
	);

	canDownloadFlinkerMatricDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.CanDownloadFlinkerMatricDocument>(
				fromActions.ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT,
			),
			switchMap((action: fromActions.CanDownloadFlinkerMatricDocument) =>
				this.flinkerDocumentDownloadService
					.canDownloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.Matric)
					.pipe(
						map((response) => new fromActions.CanDownloadFlinkerMatricDocumentSuccess(response.canDownload)),
						catchError((error: ErrorResponse) => of(new fromActions.CanDownloadFlinkerMatricDocumentError({ error }))),
					),
			),
		),
	);

	canDownloadFlinkerHighestQualificationDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.CanDownloadFlinkerHighestQualificationDocument>(
				fromActions.ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT,
			),
			switchMap((action: fromActions.CanDownloadFlinkerHighestQualificationDocument) =>
				this.flinkerDocumentDownloadService
					.canDownloadDocument(
						action.payload.flinkerId,
						action.payload.searchId,
						FlinkerDocumentType.QualificationDocuments,
					)
					.pipe(
						map(
							(response) => new fromActions.CanDownloadFlinkerHighestQualificationDocumentSuccess(response.canDownload),
						),
						catchError((error: ErrorResponse) =>
							of(new fromActions.CanDownloadFlinkerHighestQualificationDocumentError({ error })),
						),
					),
			),
		),
	);

	canDownloadFlinkerPassportDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType<fromActions.CanDownloadFlinkerPassportDocument>(
				fromActions.ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT,
			),
			switchMap((action: fromActions.CanDownloadFlinkerPassportDocument) =>
				this.flinkerDocumentDownloadService
					.canDownloadDocument(action.payload.flinkerId, action.payload.searchId, FlinkerDocumentType.PassportDocument)
					.pipe(
						map((response) => new fromActions.CanDownloadFlinkerPassportDocumentSuccess(response.canDownload)),
						catchError((error: ErrorResponse) =>
							of(new fromActions.CanDownloadFlinkerPassportDocumentError({ error })),
						),
					),
			),
		),
	);
}
