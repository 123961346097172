<div class="container-shadow">
  <div id="modalHeaderImage" class='ml-n3 mr-n3'></div>
	<div class="d-flex justify-content-center col-12">
		<app-heading [heading]="data.modalHeader" theme="f4"></app-heading>
	</div>
<div mat-dialog-content>
	<ng-container *ngIf="data.message">
		<div class="row py-4">
			<p [innerHTML]="data.message" class="col-12 text-center mb-0 font-size-10 front-fade-4"></p>
		</div>
	</ng-container>

	<ng-container *ngIf="data.iframe">
		<div class="row p-3 mx-5 rounded back-sub-11 text-break">
			<p class="text-center col-12 font-size-9 front-main-1">{{ iframeString }}</p>
		</div>
	</ng-container>

  <div class="d-flex justify-content-center w-100 col-12 mb-3">
    <ng-content></ng-content>
  </div>


	<ng-container *ngIf="data.link">
		<div class="col-12 my-4 d-flex justify-content-center">
			<a (click)="goToLink()" class="front-fade-4 font-size-10 font-normal center-icon"
				>{{ data.linkMessage }}
				<mat-icon *ngIf="!data.iframe" class="font-size-10 ml-2 text-center center-icon front-fade-4">launch</mat-icon>
			</a>
			<mat-icon
				class="font-size-9 text-center center-icon ml-2 front-fade-4"
				[matTooltip]="copied ? 'Link copied' : 'Copy link'"
				(click)="copyToClipboard()"
				>{{ copied ? 'checked' : 'content_copy' }}</mat-icon
			>
		</div>
	</ng-container>

	<ng-container *ngIf="data.showSocialShare">
		<div class="col-12 mb-4 d-flex justify-content-center" #socialButtonContainer>
			<div #facebookButton class="fb-share-button mr-2" data-layout="button" data-size="small">
				<a
					target="_blank"
					href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
					class="fb-xfbml-parse-ignore"
					>Facebook</a
				>
			</div>
		</div>
	</ng-container>

	<div class="row pb-5">
		<div class="col-12 w-100 d-flex justify-content-center">
			<app-square-button (click)="onClick()" theme="m1 bold" [value]="data.buttonName"></app-square-button>
		</div>
	</div>
</div>
</div>
