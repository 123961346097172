import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions } from '@root-store/flinker-take-on-store';
import { filter, takeUntil } from 'rxjs/operators';
import CvFeatureResponseDto, { CvFeatureType } from '@models/dto/responses/cv-feature-response.dto';
import { CvStoreActions, CvStoreSelectors } from '@root-store/cv-store';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-cv-purchase-modal',
	templateUrl: './cv-purchase-modal.component.html',
	styleUrls: ['./cv-purchase-modal.component.scss'],
})
export class CvPurchaseModalComponent implements OnInit, OnDestroy {
	cvFeaturesHttpPackage$ = this.store.select(CvStoreSelectors.selectCvFeatures);
	amountDueForCv: number;
	destroy$: Subject<void> = new Subject<void>();
	constructor(
		private router: Router,
		private store: Store<RootStoreState.State>,
		public dialogRef: MatDialogRef<CvPurchaseModalComponent>,
	) {}

	ngOnInit(): void {
		this.store.dispatch(new CvStoreActions.GetCvFeatures());
		this.cvFeaturesHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				takeUntil(this.destroy$),
			)
			.subscribe((cvFeaturesHttpPackage) => {
				const cvFeatures = cvFeaturesHttpPackage.result.filter(
					(cvFeature: CvFeatureResponseDto) => cvFeature.type !== CvFeatureType.MIE,
				);
				this.amountDueForCv = cvFeatures.reduce(
					(amountDue: number, cvFeature: CvFeatureResponseDto) => amountDue + Number(cvFeature.price),
					0,
				);
			});
	}

	closeModal() {
		this.dialogRef.close();
		this.store.dispatch(new FlinkerTakeOnStoreActions.SetFlinkerCVPromotionDate());
	}

	goToCvPage() {
		this.dialogRef.close();
		this.store.dispatch(new FlinkerTakeOnStoreActions.SetFlinkerCVPromotionDate());
		this.router.navigate(['flinker-dashboard/cv']);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
