import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AccountType } from '@app/enums/account-type.enum';
import { AuthService } from './auth.service';
import { RoleAuthService } from './role-auth.service';

@Injectable({
	providedIn: 'root',
})
export class LoggedInAuthGuard implements CanActivate {
	constructor(private authService: AuthService, private roleAuth: RoleAuthService, private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot): boolean {
		const userRole = this.roleAuth.getRole();

		if (this.authService.isLoggedIn()) {
			if (userRole === AccountType.Flinker) {
				this.router.navigate(['/flinker-dashboard/profile']);
				return false;
			} else if (userRole === AccountType.FlinkAdmin) {
				this.router.navigate(['/flinkadmin-dashboard/flinkco-contacts']);
				return false;
			} else {
				if (this.roleAuth.getRole() === AccountType.FlinkCompanyMaster) {
					this.router.navigate(['/flinkco-dashboard/profile']);
				} else {
					this.router.navigate(['/flinkco-dashboard/candidate-search']);
				}
				return false;
			}
		} else return true;
	}
}
