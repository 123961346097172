import { createFeatureSelector, createSelector } from '@ngrx/store';
import InterviewResponse from '@models/dto/responses/interview-response.dto';
import HttpPackage from '../../models/http-package';
import { InterviewState } from './state';
import InterviewerWithInterviewQuestionsResponse from '@models/dto/responses/interviewer-with-interview-questions-response.dto';
import InterviewQuestionsScoreTableResponse from '@models/dto/responses/interview-questions-score-table-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

const getCreateInviteHttpPackage = (state: InterviewState): HttpPackage<string> => state.createInviteHttpPackage;

const getGetFlinkCoInterviewsHttpPackage = (state: InterviewState): HttpPackage<PaginatedResponse<InterviewResponse>> =>
	state.getFlinkCoInterviewsHttpPackage;

const getGetFlinkerInterviewsHttpPackage = (state: InterviewState): HttpPackage<InterviewResponse[]> =>
	state.getFlinkerInterviewsHttpPackage;

const getGetFlinkCoInterviewHttpPackage = (state: InterviewState): HttpPackage<InterviewResponse> =>
	state.getFlinkCoInterviewHttpPackage;

const getGetFlinkerInterviewHttpPackage = (state: InterviewState): HttpPackage<InterviewResponse> =>
	state.getFlinkerInterviewHttpPackage;

const getCancelInterviewHttpPackage = (state: InterviewState): HttpPackage<string> => state.cancelInterviewHttpPackage;

const getScheduleInterviewHttpPackage = (state: InterviewState): HttpPackage<string> =>
	state.scheduleInterviewHttpPackage;

const getUpdateInterviewStatusHttpPackage = (state: InterviewState): HttpPackage<string> =>
	state.updateInterviewStatusHttpPackage;

const downloadFlinkerCv = (state: InterviewState): HttpPackage<any> => state.downloadFlinkerCv;

const getCurrentInterviewerWithInterviewQuestionsHttpPackage = (
	state: InterviewState,
): HttpPackage<InterviewerWithInterviewQuestionsResponse> =>
	state.getCurrentInterviewerWithInterviewQuestionsHttpPackage;

export const getInterviewQuestionsScoreTableHttpPackage = (
	state: InterviewState,
): HttpPackage<InterviewQuestionsScoreTableResponse> => state.getInterviewQuestionsScoreTableHttpPackage;

export const selectInterviewState = createFeatureSelector<InterviewState>('interview');

export const selectCreateInviteHttpPackage = createSelector(selectInterviewState, getCreateInviteHttpPackage);

export const selectGetFlinkCoInterviewsHttpPackage = createSelector(
	selectInterviewState,
	getGetFlinkCoInterviewsHttpPackage,
);

export const selectGetFlinkerInterviewsHttpPackage = createSelector(
	selectInterviewState,
	getGetFlinkerInterviewsHttpPackage,
);

export const selectGetFlinkCoInterviewHttpPackage = createSelector(
	selectInterviewState,
	getGetFlinkCoInterviewHttpPackage,
);

export const selectGetFlinkerInterviewHttpPackage = createSelector(
	selectInterviewState,
	getGetFlinkerInterviewHttpPackage,
);

export const selectCancelInterviewHttpPackage = createSelector(selectInterviewState, getCancelInterviewHttpPackage);

export const selectScheduleInterviewHttpPackage = createSelector(selectInterviewState, getScheduleInterviewHttpPackage);

export const selectUpdateInterviewStatusHttpPackage = createSelector(
	selectInterviewState,
	getUpdateInterviewStatusHttpPackage,
);

export const selectDownloadFlinkerCv = createSelector(selectInterviewState, downloadFlinkerCv);

export const selectGetCurrentInterviewerWithInterviewQuestionsHttpPackage = createSelector(
	selectInterviewState,
	getCurrentInterviewerWithInterviewQuestionsHttpPackage,
);

export const selectGetInterviewQuestionsScoreTableHttpPackage = createSelector(
	selectInterviewState,
	getInterviewQuestionsScoreTableHttpPackage,
);
