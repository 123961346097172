import { Action } from '@ngrx/store';
import CreateOfferRequest from '../../models/dto/requests/create-offer-request.dto';
import OfferStatusRequest from '../../models/dto/requests/offer-status-request.dto';
import UpdateOfferRequest from '../../models/dto/requests/update-offer-request.dto';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import OfferResponse from '../../models/dto/responses/offer-response.dto';
import FlinkcoCancelOfferDto from '../../models/dto/requests/flinkco-cancel-offer.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import PaginatedRequest from '@models/dto/responses/paginated-request.dto';

export enum ActionTypes {
	CREATE_OFFER = '[Offer] Create offer',
	CREATE_OFFER_SUCCESS = '[Offer] Create offer success',
	CREATE_OFFER_ERROR = '[Offer] Create offer error',

	GET_FLINKER_OFFERS = '[Offer] Get flinker offers',
	GET_FLINKER_OFFERS_SUCCESS = '[Offer] Get flinker offers success',
	GET_FLINKER_OFFERS_ERROR = '[Offer] Get flinker offers error',

	GET_FLINKER_OFFER = '[Offer] Get flinker offer',
	GET_FLINKER_OFFER_SUCCESS = '[Offer] Get flinker offer success',
	GET_FLINKER_OFFER_ERROR = '[Offer] Get flinker offer error',

	UPDATE_OFFER_STATUS = '[Offer] Update offer status',
	UPDATE_OFFER_STATUS_SUCCESS = '[Offer] Update offer status success',
	UPDATE_OFFER_STATUS_ERROR = '[Offer] Update offer status error',

	GET_FLINKCO_OFFERS = '[Offer] Get Flinkco offers',
	GET_FLINKCO_OFFERS_SUCCESS = '[Offer] Get Flinkco offers success',
	GET_FLINKCO_OFFERS_ERROR = '[Offer] Get Flinkco offers error',

	GET_FLINKCO_OFFER = '[Offer] Get Flinkco offer',
	GET_FLINKCO_OFFER_SUCCESS = '[Offer] Get Flinkco offer success',
	GET_FLINKCO_OFFER_ERROR = '[Offer] Get Flinkco offer error',

	UPDATE_OFFER = '[Offer] Update offer',
	UPDATE_OFFER_SUCCESS = '[Offer] Update offer success',
	UPDATE_OFFER_ERROR = '[Offer] Update offer error',

	CANCEL_OFFER = '[Offer] Cancel offer',
	CANCEL_OFFER_SUCCESS = '[Offer] Cancel offer success',
	CANCEL_OFFER_ERROR = '[Offer] Cancel offer error',

	UPLOAD_OFFER_DOCUMENTS = '[Offer] Upload offer documents',
	UPLOAD_OFFER_DOCUMENTS_SUCCESS = '[Offer] Upload offer documents success',
	UPLOAD_OFFER_DOCUMENTS_ERROR = '[Offer] Upload offer documents error',

	DOWNLOAD_OFFER_DOCUMENTS = '[Offer] Download offer documents',
	DOWNLOAD_OFFER_DOCUMENTS_SUCCESS = '[Offer] Download offer documents success',
	DOWNLOAD_OFFER_DOCUMENTS_ERROR = '[Offer] Download offer documents error',
}

export class CreateOffer implements Action {
	readonly type = ActionTypes.CREATE_OFFER;
	constructor(public payload: { createOfferRequest: CreateOfferRequest }) {}
}

export class CreateOfferSuccess implements Action {
	readonly type = ActionTypes.CREATE_OFFER_SUCCESS;
	constructor(public payload: { result: OfferResponse }) {}
}

export class CreateOfferError implements Action {
	readonly type = ActionTypes.CREATE_OFFER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerOffers implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFERS;
	constructor() {}
}

export class GetFlinkerOffersSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFERS_SUCCESS;
	constructor(public payload: { result: OfferResponse[] }) {}
}

export class GetFlinkerOffersError implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFERS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerOffer implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFER;
	constructor(public payload: { offerId: string }) {}
}

export class GetFlinkerOfferSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFER_SUCCESS;
	constructor(public payload: { result: OfferResponse }) {}
}

export class GetFlinkerOfferError implements Action {
	readonly type = ActionTypes.GET_FLINKER_OFFER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoOffers implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFERS;
	constructor(public payload: PaginatedRequest) {}
}

export class GetFlinkCoOffersSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFERS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<OfferResponse> }) {}
}

export class GetFlinkCoOffersError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFERS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoOffer implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFER;
	constructor(public payload: { offerId: string }) {}
}

export class GetFlinkCoOfferSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFER_SUCCESS;
	constructor(public payload: { result: OfferResponse }) {}
}

export class GetFlinkCoOfferError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_OFFER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateOffer implements Action {
	readonly type = ActionTypes.UPDATE_OFFER;
	constructor(public payload: { updateOfferRequest: UpdateOfferRequest }) {}
}

export class UpdateOfferSuccess implements Action {
	readonly type = ActionTypes.UPDATE_OFFER_SUCCESS;
	constructor(public payload: { result: OfferResponse }) {}
}

export class UpdateOfferError implements Action {
	readonly type = ActionTypes.UPDATE_OFFER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateOfferStatus implements Action {
	readonly type = ActionTypes.UPDATE_OFFER_STATUS;
	constructor(public payload: { offerStatusRequest: OfferStatusRequest }) {}
}

export class UpdateOfferStatusSuccess implements Action {
	readonly type = ActionTypes.UPDATE_OFFER_STATUS_SUCCESS;
	constructor(public payload: { result: OfferResponse }) {}
}

export class UpdateOfferStatusError implements Action {
	readonly type = ActionTypes.UPDATE_OFFER_STATUS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CancelOffer implements Action {
	readonly type = ActionTypes.CANCEL_OFFER;
	constructor(public payload: { flinkCoCancelOffer: FlinkcoCancelOfferDto }) {}
}

export class CancelOfferSuccess implements Action {
	readonly type = ActionTypes.CANCEL_OFFER_SUCCESS;
	constructor(public payload: { successMessage: string }) {}
}

export class CancelOfferError implements Action {
	readonly type = ActionTypes.CANCEL_OFFER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadOfferDocuments implements Action {
	readonly type = ActionTypes.UPLOAD_OFFER_DOCUMENTS;
	constructor(public payload: { offerId: string; formFiles: FormData }) {}
}

export class UploadOfferDocumentsSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_OFFER_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UploadOfferDocumentsError implements Action {
	readonly type = ActionTypes.UPLOAD_OFFER_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DownloadOfferDocuments implements Action {
	readonly type = ActionTypes.DOWNLOAD_OFFER_DOCUMENTS;
	constructor(public payload: { offerId: string }) {}
}
export class DownloadOfferDocumentsSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_OFFER_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: any }) {}
}
export class DownloadOfferDocumentsError implements Action {
	readonly type = ActionTypes.DOWNLOAD_OFFER_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateOffer
	| CreateOfferSuccess
	| CreateOfferError
	| GetFlinkerOffers
	| GetFlinkerOffersSuccess
	| GetFlinkerOffersError
	| GetFlinkerOffer
	| GetFlinkerOfferSuccess
	| GetFlinkerOfferError
	| GetFlinkCoOffers
	| GetFlinkCoOffersSuccess
	| GetFlinkCoOffersError
	| GetFlinkCoOffer
	| GetFlinkCoOfferSuccess
	| GetFlinkCoOfferError
	| UpdateOffer
	| UpdateOfferSuccess
	| UpdateOfferError
	| UpdateOfferStatus
	| UpdateOfferStatusSuccess
	| UpdateOfferStatusError
	| UploadOfferDocuments
	| UploadOfferDocumentsSuccess
	| UploadOfferDocumentsError
	| CancelOffer
	| CancelOfferSuccess
	| CancelOfferError
	| DownloadOfferDocuments
	| DownloadOfferDocumentsSuccess
	| DownloadOfferDocumentsError;
