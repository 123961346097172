import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'projects/shared/shared.module';
import { QualificationsComponent } from './flinker/qualifications/qualifications.component';
import { ExperienceComponent } from './flinker/experience/experience.component';
import { ExperienceProfileComponent } from './flinker/experience-profile/experience-profile.component';
import { QualificationsProfileComponent } from './flinker/qualifications-profile/qualifications-profile.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { AvatarModule } from 'ngx-avatar';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { QualificationsSummaryComponent } from './flinker/qualifications-summary/qualifications-summary.component';
import { ExperienceSummaryComponent } from './flinker/experience-summary/experience-summary.component';
import { SessionExpiryComponent } from './session-expiry/session-expiry.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { ReferenceSummaryComponent } from './flinker-admin/reference-summary/reference-summary.component';
import { DutiesDescriptionComponent } from './flinker/experience-summary/duties-description/duties-description.component';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		ReactiveFormsModule,
		MatToolbarModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		MatButtonModule,
		MatCardModule,
		MatInputModule,
		MatFormFieldModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatExpansionModule,
		MatChipsModule,
		MatTableModule,
		AvatarModule,
		MatCardModule,
		MatProgressSpinnerModule,
	],
	exports: [
		QualificationsComponent,
		ExperienceComponent,
		ExperienceProfileComponent,
		QualificationsProfileComponent,
		QualificationsSummaryComponent,
		ExperienceSummaryComponent,
		SessionExpiryComponent,
		ManageSubscriptionComponent,
		LogoutDialogComponent,
		ReferenceSummaryComponent,
	],
	declarations: [
		QualificationsComponent,
		ExperienceComponent,
		ExperienceProfileComponent,
		QualificationsProfileComponent,
		QualificationsSummaryComponent,
		ExperienceSummaryComponent,
		SessionExpiryComponent,
		ManageSubscriptionComponent,
		LogoutDialogComponent,
		ReferenceSummaryComponent,
		DutiesDescriptionComponent,
	],
	providers: [],
})
export class CommonComponentsModule {}
