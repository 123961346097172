import { createFeatureSelector, createSelector } from '@ngrx/store';
import ActiveSubscription from '../../models/dto/responses/active-Subscription-response.dto';
import CostResponse from '../../models/dto/responses/cost-response.dto';
import QuoteResponse from '../../models/dto/responses/quote-response.dto';
import HttpPackage from '../../models/http-package';
import { QuoteState } from './state';

const getRetainerQuoteHttpPackage = (state: QuoteState): HttpPackage<CostResponse> => state.getRetainerQuoteHttpPackage;

const getAdHocQuoteHttpPackage = (state: QuoteState): HttpPackage<CostResponse> => state.getAdHocQuoteHttpPackage;

const getActiveQuoteHttpPackage = (state: QuoteState): HttpPackage<QuoteResponse> => state.getActiveQuoteHttpPackage;
const getActiveSubscriptionHttpPackage = (state: QuoteState): HttpPackage<ActiveSubscription> =>
	state.getActiveSubscriptionHttpPackage;

export const selectQuoteState = createFeatureSelector<QuoteState>('quote');

export const selectRetainerQuoteHttpPackage = createSelector(selectQuoteState, getRetainerQuoteHttpPackage);

export const selectAdHocQuoteHttpPackage = createSelector(selectQuoteState, getAdHocQuoteHttpPackage);

export const selectActiveQuoteHttpPackage = createSelector(selectQuoteState, getActiveQuoteHttpPackage);

export const selectActiveSubscriptionHttpPackage = createSelector(selectQuoteState, getActiveSubscriptionHttpPackage);
