import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from '@app/utils/functions.util';

@Component({
	selector: 'app-range-slider',
	templateUrl: './range-slider.component.html',
	styleUrls: ['./range-slider.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RangeSliderComponent),
			multi: true,
		},
	],
})
export class RangeSliderComponent implements OnInit, ControlValueAccessor {
	@Input() labelText = '';
	@Input() monetary = false;
	@Input() floor = 0;
	@Input() ceil = 50;
	@Input() step = 1;
	@Input() showInput = false;
	@Input() maxRangeValue: number;

	@Input() highValue = 20000;
	@Input() lowValue = 10000;
	rangeValue = this.monetary ? 'R' + this.lowValue + ' - R' + this.highValue : this.lowValue + ' - ' + this.highValue;

	options: Options = {
		floor: this.floor,
		ceil: this.ceil,
		step: this.step,
	};

	@Output() onInitialized: EventEmitter<any> = new EventEmitter();

	localType = '';
	disabled = false;
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {
		if (this.monetary) {
			this.options = {
				floor: this.floor,
				ceil: this.ceil,
				step: this.step,
				maxRange: this.maxRangeValue ?? null,
				pushRange: !isNil(this.maxRangeValue),

				translate: (value: number): string => {
					return 'R' + value + ',00';
				},
			};
		} else {
			this.options = {
				floor: this.floor || 0,
				ceil: this.ceil || 50,
				step: this.step,
			};
		}
		this.onInitialized.emit(`${this.lowValue},${this.highValue}`);
	}

	public writeValue(value: string): void {
		const valueSplit = value.split(',');
		this.lowValue = parseInt(valueSplit[0]);
		this.highValue = parseInt(valueSplit[1]);
		this.rangeValue = this.monetary
			? 'R' + valueSplit[0] + ' - R' + valueSplit[1]
			: valueSplit[0] + ' - ' + valueSplit[1];
	}

	valueChange(): void {
		this.onChange(this.lowValue + ',' + this.highValue);
		this.rangeValue = this.monetary
			? 'R' + this.lowValue + ' - R' + this.highValue
			: this.lowValue + ' - ' + this.highValue;
	}

	highValueChange(): void {
		this.onChange(this.lowValue + ',' + this.highValue);
		this.rangeValue = this.monetary
			? 'R' + this.lowValue + ' - R' + this.highValue
			: this.lowValue + ' - ' + this.highValue;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
