import moment, { Moment } from 'moment';
import IdealWorkLocation from '../ideal-work-location.dto';
import { Expose, Transform, Type } from 'class-transformer';

export default class FlinkerPersonalDetailsRequest {
	@Expose()
	name: string;
	@Expose()
	surname: string;
	@Expose()
	email: string;
	@Expose()
	southAfricanCitizen: boolean;
	@Expose()
	idNumber: string;
	@Expose()
	passportNumber: string;
	@Transform(({ value }) => moment(value))
	@Expose()
	dateOfBirth: Moment;
	@Expose()
	driversLicense: string;
	@Expose()
	ownsVehicle: boolean;
	@Expose()
	marketingMethod: string;
	@Expose()
	gender: string;
	@Expose()
	race: string;
	@Expose()
	languageProficiency1: string;
	@Expose()
	languageProficiency2: string;
	@Expose()
	disabled: boolean;
	@Expose()
	cellphoneNumber: string;
	@Expose()
	preferredMethodOfContact: string;
	@Expose()
	registeredWithProfessionalBody: boolean;
	@Expose()
	professionalBody1: string;
	@Expose()
	professionalBody2: string;
	@Expose()
	profileStatus: string;
	@Expose()
	availabilityStatus: string;
	@Expose()
	visibilityStatus: string;
	@Expose()
	placement: string;
	@Expose()
	workspacePreference: string;
	@Expose()
	idealWorkLocations: IdealWorkLocation[];
	@Expose()
	willingToRelocate: boolean;
	@Expose()
	willingToRelocateAbroad: boolean;
	@Expose()
	salaryBandLower: number;
	@Expose()
	salaryBandUpper: number;
	@Expose()
	currentSalary: number;
	@Expose()
	jobRequirement: string;
	@Expose()
	additionalLeaveBenefits: boolean;
	@Expose()
	noticePeriod: string;
	@Expose()
	hasTakenCovidVaccine: boolean;
}
