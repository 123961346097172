import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getErrorMessage } from '@app/utils/form.util';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { RootStoreState } from '@root-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerEmploymentDetailsRequest from '@models/dto/requests/flinker-employment-details-request.dto';
import CurrentEmployer from '@models/dto/current-employer.dto';
import PreviousEmployer from '@models/dto/previous-employer.dto';
import Reference from '@models/dto/reference.dto';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import {
	filterOnParentCode,
	getSelectOptionById,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from '@models/dto/responses/select-options-response.dto';
import {
	contactNumberValidator,
	endDatevalidator,
	referencesContactNumberValidator,
	referencesEmailValidator,
	referencesEmailValidatorAsync,
	startDatevalidator,
} from '@app/utils/custom-validators.util';
import { UnemployedReason } from '@app/enums/unemployed-reason.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { isNil } from '@app/utils/functions.util';
import { isOther } from '@app/utils/is-other';
import { FlinkCoNamesResponse } from '@app/models/dto/responses/flinkCo-names-response.dto';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { DictionarySuccessMessages } from '@app/enums/dictionary-words.enum';
import { entityConfig } from '@models/constants';
import { RegexService } from '@shared/services/regex.service';
import { ValidationService } from '@app/services/validation/validation.service';

@Component({
	selector: 'app-experience',
	templateUrl: './experience.component.html',
	styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit, OnDestroy {
	@Input() allowReferenceUpdate = true;
	@Input() isTakeOn: boolean;
	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	flinkCosListHttpPackage$ = this.store.select(FlinkerTakeOnStoreSelectors.selectGetFlinkCosListHttpPackage);
	flinkCoList: FlinkCoNamesResponse[] = [];
	flinkerEmploymentDetailsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerEmploymentDetailsHttpPackage,
	);
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	uploadEmploymentDocumentsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectUploadEmploymentDocumentsHttpPackage,
	);
	form: FormGroup;
	numberOfPreviousEmployers = 0;
	getErrorMessage = getErrorMessage;
	currentJobDescription: File;
	previousJobDescriptions: File[] = [];
	filterOnParentCode = filterOnParentCode;
	getSelectOptionNameById = getSelectOptionNameById;
	selectOptions: SelectOptionsResponse;
	shouldValidate = false;

	readonly dutiesDescriptionLength: string = '2000';
	readonly maxCompanyNameLength: string = '100';
	readonly entityConfig = entityConfig;
	@Output() success = new EventEmitter();

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private readonly notifierService: NotifierService,
		private validationService: ValidationService,
	) {
		this.form = this.formBuilder.group({
			currentEmploymentId: [null],
			currentCompanyName: ['', Validators.maxLength(Number(this.maxCompanyNameLength))],
			currentIndustry: [''],
			currentEmploymentStartDate: ['', startDatevalidator('currentEmploymentStartDate')],
			currentJobCategory: [''],
			currentJobTitle: [{ value: '', disabled: true }],
			otherJobTitle: [''],
			currentDuties: ['', Validators.maxLength(Number(this.dutiesDescriptionLength))],
			currentJobDescription: [''],
			previousEmployers: new FormArray([]),
			unemployed: [false],
			student: [false],
			referenceId1: [null],
			referenceName1: ['', [Validators.required, Validators.maxLength(entityConfig.reference.maxNameLength)]],
			referenceRelationship1: ['', [Validators.required]],
			referenceJobTitle1: ['', [Validators.required]],
			referenceOtherJobTitle1: ['', [Validators.maxLength(entityConfig.flinkCo.maxOtherJobTitleLength)]],
			referenceEmail1: [
				'',
				[
					Validators.email,
					Validators.maxLength(entityConfig.flinkCo.maxEmailAddressLength),
					referencesEmailValidator,
					Validators.pattern(RegexService.validEmailAddress()),
				],
				[referencesEmailValidatorAsync(validationService)],
			],
			referenceContactNumber1: [
				'',
				[Validators.required, contactNumberValidator(), referencesContactNumberValidator()],
			],
			referenceId2: [null],
			referenceName2: ['', [Validators.required, Validators.maxLength(entityConfig.reference.maxNameLength)]],
			referenceRelationship2: ['', [Validators.required]],
			referenceJobTitle2: ['', [Validators.required]],
			referenceOtherJobTitle2: [
				'',
				[
					Validators.maxLength(entityConfig.flinkCo.maxOtherJobTitleLength),
					Validators.pattern(RegexService.validDoesNotContainOnlySpaces()),
				],
			],
			referenceEmail2: [
				'',
				[
					Validators.email,
					Validators.maxLength(entityConfig.flinkCo.maxEmailAddressLength),
					referencesEmailValidator,
					Validators.pattern(RegexService.validEmailAddress()),
				],
				[referencesEmailValidatorAsync(validationService)],
			],
			referenceContactNumber2: [
				'',
				[Validators.required, contactNumberValidator(), referencesContactNumberValidator()],
			],
			referenceId3: [null],
			referenceName3: ['', [Validators.required, Validators.maxLength(entityConfig.reference.maxNameLength)]],
			referenceRelationship3: ['', [Validators.required]],
			referenceJobTitle3: ['', [Validators.required]],
			referenceOtherJobTitle3: [
				'',
				[
					Validators.maxLength(entityConfig.flinkCo.maxOtherJobTitleLength),
					Validators.pattern(RegexService.validDoesNotContainOnlySpaces()),
				],
			],
			referenceEmail3: [
				'',
				[
					Validators.email,
					Validators.maxLength(entityConfig.flinkCo.maxEmailAddressLength),
					referencesEmailValidator,
					Validators.pattern(RegexService.validEmailAddress()),
				],
				[referencesEmailValidatorAsync(validationService)],
			],
			referenceContactNumber3: [
				'',
				[Validators.required, contactNumberValidator(), referencesContactNumberValidator()],
			],
			addReference: [false],
		});

		this.form.controls.currentJobCategory.valueChanges.subscribe((value) => {
			value === '' || value === null
				? this.form.controls.currentJobTitle.disable()
				: this.form.controls.currentJobTitle.enable();
			this.form.controls.currentJobTitle.reset();
		});

		this.form.controls.addReference.valueChanges.subscribe((value) => {
			this.updateValidity(!value); //Inverting the value specifically to allow the validation to go through
		});

		this.subscriptions.add(
			this.form.controls.unemployed.valueChanges.subscribe((value) => {
				if (!value) {
					this.form.controls.addReference.setValue(false);
				}
				this.updateValidity(value);
			}),
		);
	}

	ngOnInit(): void {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinkCoList());
		this.setupUnemployedTriggers();
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				if (selectOptionsResponse.result !== null) {
					this.selectOptions = selectOptionsResponse.result;
				}
			}),
		);
		this.subscriptions.add(
			this.flinkCosListHttpPackage$.subscribe((flinkCoList) => {
				if (flinkCoList.result === null) {
					return;
				}
				this.flinkCoList = flinkCoList.result;
			}),
		);
		this.setupReferenceFormTriggers();

		this.subscriptions.add(
			this.flinker$.subscribe((flinker) => {
				this.flinker = flinker;
				this.dynamicForm.clear();

				this.form.patchValue({
					unemployed: isNil(flinker?.unemployed) ? false : flinker?.unemployed,
					student: flinker.unemployedReason === UnemployedReason.Student ?? false,
				});

				if (this.allowReferenceUpdate === false) {
					this.disableAllReferenceInputs();
				}

				this.numberOfPreviousEmployers = 0;
				this.flinker.previousEmployers.forEach((previousEmployer) => {
					const dynamicEmployers = this.dynamicForm;
					const previousEmployerGroup = new FormGroup({
						previousEmploymentId: new FormControl(previousEmployer.id),
						previousCompanyName: new FormControl(previousEmployer.companyName, [
							Validators.required,
							Validators.maxLength(Number(this.maxCompanyNameLength)),
						]),
						previousIndustry: new FormControl(previousEmployer.industry, [Validators.required]),
						previousReasonForLeaving: new FormControl(previousEmployer.reasonForLeaving, [Validators.required]),
						previousEmploymentStartDate: new FormControl(previousEmployer.startDate, [
							Validators.required,
							startDatevalidator('previousEmploymentStartDate'),
						]),
						previousEmploymentEndDate: new FormControl(previousEmployer.endDate, [
							Validators.required,
							endDatevalidator('previousEmploymentStartDate', 'previousEmploymentEndDate'),
						]),
						previousJobCategory: new FormControl(previousEmployer.jobCategory, [Validators.required]),
						previousJobTitle: new FormControl(previousEmployer.jobTitle, [Validators.required]),
						previousOtherJobTitle: new FormControl(previousEmployer.otherJobTitle),
						previousDuties: new FormControl(previousEmployer.dutiesDescription, [
							Validators.maxLength(Number(this.dutiesDescriptionLength)),
						]),
						previousJobDescription: new FormControl(previousEmployer.jobDescriptionUploaded),
					});
					previousEmployerGroup.controls.previousJobCategory.valueChanges.subscribe((value) => {
						value === '' || value === null
							? previousEmployerGroup.controls.previousJobTitle.disable()
							: previousEmployerGroup.controls.previousJobTitle.enable();
						previousEmployerGroup.controls.previousJobTitle.reset();
					});
					dynamicEmployers.push(previousEmployerGroup);
					this.numberOfPreviousEmployers += 1;
					this.previousJobDescriptions.push(null);
				});

				this.form.patchValue({
					currentEmploymentId: flinker?.currentEmployer?.id,
					currentCompanyName: flinker.currentEmployer?.companyName ?? '',
					currentIndustry: flinker.currentEmployer?.industry ?? '',
					currentEmploymentStartDate: flinker.currentEmployer?.startDate ?? '',
					currentJobCategory: flinker.currentEmployer?.jobCategory ?? '',
					currentJobTitle: flinker.currentEmployer?.jobTitle ?? '',
					otherJobTitle: flinker.currentEmployer?.otherJobTitle ?? '',
					currentDuties: flinker.currentEmployer?.dutiesDescription ?? '',
					previousEmployers: this.flinker.previousEmployers,
					referenceId1: flinker.references && !isNil(flinker.references[0]) ? flinker.references[0]?.id : null,
					referenceName1: flinker.references && !isNil(flinker.references[0]) ? flinker.references[0].name : '',
					referenceRelationship1:
						flinker.references && !isNil(flinker.references[0]) ? flinker.references[0].referenceRelationshipCode : '',
					referenceJobTitle1:
						flinker.references && !isNil(flinker.references[0])
							? getSelectOptionById(this.selectOptions?.jobTitles, flinker.references[0].jobTitleId).code
							: '',
					referenceOtherJobTitle1:
						flinker.references && !isNil(flinker.references[0]) ? flinker.references[0].otherJobTitle : '',
					referenceContactNumber1:
						flinker.references && !isNil(flinker.references[0]) ? flinker.references[0].contactNumber : '',
					referenceEmail1:
						flinker.references && !isNil(flinker.references[0]) ? flinker.references[0].emailAddress : '',
					referenceId2: flinker.references && !isNil(flinker.references[1]) ? flinker.references[1]?.id : null,
					referenceName2: flinker.references && !isNil(flinker.references[1]) ? flinker.references[1].name : '',
					referenceRelationship2:
						flinker.references && !isNil(flinker.references[1]) ? flinker.references[1].referenceRelationshipCode : '',
					referenceJobTitle2:
						flinker.references && !isNil(flinker.references[1])
							? getSelectOptionById(this.selectOptions?.jobTitles, flinker.references[1].jobTitleId).code
							: '',
					referenceOtherJobTitle2:
						flinker.references && !isNil(flinker.references[1]) ? flinker.references[1].otherJobTitle : '',
					referenceContactNumber2:
						flinker.references && !isNil(flinker.references[1]) ? flinker.references[1].contactNumber : '',
					referenceEmail2:
						flinker.references && !isNil(flinker.references[1]) ? flinker.references[1].emailAddress : '',
					referenceId3: flinker.references && !isNil(flinker.references[2]) ? flinker.references[2]?.id : null,
					referenceName3: flinker.references && !isNil(flinker.references[2]) ? flinker.references[2].name : '',
					referenceRelationship3:
						flinker.references && !isNil(flinker.references[2]) ? flinker.references[2].referenceRelationshipCode : '',
					referenceJobTitle3:
						flinker.references && !isNil(flinker.references[2])
							? getSelectOptionById(this.selectOptions?.jobTitles, flinker.references[2].jobTitleId).code
							: '',
					referenceOtherJobTitle3:
						flinker.references && !isNil(flinker.references[2]) ? flinker.references[2].otherJobTitle : '',
					referenceContactNumber3:
						flinker.references && !isNil(flinker.references[2]) ? flinker.references[2].contactNumber : '',
					referenceEmail3:
						flinker.references && !isNil(flinker.references[2]) ? flinker.references[2].emailAddress : '',
				});

				if (!this.allReferencesAreValid()) {
					// This is so that if someone leaves out references, they can edit
					// them at a later stage.
					this.enableAllReferenceInputs();
					this.allowReferenceUpdate = true;
				}
			}),
		);

		this.subscriptions.add(
			this.form.controls.currentJobTitle.valueChanges.subscribe((value) => {
				if (value === 'OTH') {
					this.form.controls.otherJobTitle.setValidators(Validators.required);
				} else {
					this.form.controls.otherJobTitle.clearValidators();
					this.form.controls.otherJobTitle.setValue('');
				}

				this.form.controls.otherJobTitle.updateValueAndValidity();
			}),
		);

		this.subscriptions.add(
			this.flinkerEmploymentDetailsHttpPackage$.subscribe((flinkerEmploymentDetailsHttpPackage) => {
				if (flinkerEmploymentDetailsHttpPackage.result != null) {
					if (!flinkerEmploymentDetailsHttpPackage.loading && !this.isTakeOn) {
						this.notifierService.notify(NotificationType.Success, DictionarySuccessMessages.EmploymentDetailsUpdate);
					}
					this.uploadJobDescriptions(flinkerEmploymentDetailsHttpPackage.result);
				}
			}),
		);
		this.form.controls.unemployed.valueChanges.subscribe((result) => {
			if (result === false) {
				this.form.controls.student.setValue(false);
			}
		});
	}

	private setupReferenceFormTriggers(): void {
		this.subscriptions.add(
			this.form.controls.referenceEmail1.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.form.controls.referenceEmail1.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail2.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail3.updateValueAndValidity({ emitEvent: false });
			}),
		);

		this.subscriptions.add(
			this.form.controls.referenceJobTitle1.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((jobTitle: string) => {
					if (isOther(jobTitle)) {
						this.form.controls.referenceOtherJobTitle1.setValidators([
							Validators.required,
							Validators.pattern(RegexService.validDoesNotContainOnlySpaces()),
						]);
					} else {
						this.form.controls.referenceOtherJobTitle1.clearValidators();
					}
					this.form.controls.referenceOtherJobTitle1.updateValueAndValidity();
					this.form.updateValueAndValidity();
				}),
		);

		this.subscriptions.add(
			this.form.controls.referenceEmail2.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.form.controls.referenceEmail1.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail2.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail3.updateValueAndValidity({ emitEvent: false });
			}),
		);

		this.subscriptions.add(
			this.form.controls.referenceJobTitle2.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((jobTitle: string) => {
					if (isOther(jobTitle)) {
						this.form.controls.referenceOtherJobTitle2.setValidators([
							Validators.required,
							Validators.pattern(RegexService.validDoesNotContainOnlySpaces()),
						]);
					} else {
						this.form.controls.referenceOtherJobTitle2.clearValidators();
					}
					this.form.controls.referenceOtherJobTitle2.updateValueAndValidity();
					this.form.updateValueAndValidity();
				}),
		);

		this.subscriptions.add(
			this.form.controls.referenceEmail3.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.form.controls.referenceEmail1.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail2.updateValueAndValidity({ emitEvent: false });
				this.form.controls.referenceEmail3.updateValueAndValidity({ emitEvent: false });
			}),
		);

		this.subscriptions.add(
			this.form.controls.referenceJobTitle3.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((jobTitle: string) => {
					if (isOther(jobTitle)) {
						this.form.controls.referenceOtherJobTitle3.setValidators([
							Validators.required,
							Validators.pattern(RegexService.validDoesNotContainOnlySpaces()),
						]);
					} else {
						this.form.controls.referenceOtherJobTitle3.clearValidators();
					}
					this.form.controls.referenceOtherJobTitle3.updateValueAndValidity();
					this.form.updateValueAndValidity();
				}),
		);
	}

	private setupUnemployedTriggers(): void {
		this.form.controls.unemployed.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((unemployed: boolean) => {
			this.form.controls.currentCompanyName.setValidators(unemployed ? null : Validators.required);
			this.form.controls.currentEmploymentStartDate.setValidators(unemployed ? null : Validators.required);
			this.form.controls.currentIndustry.setValidators(unemployed ? null : Validators.required);
			this.form.controls.currentJobCategory.setValidators(unemployed ? null : Validators.required);
			this.form.controls.currentJobTitle.setValidators(unemployed ? null : Validators.required);

			this.form.controls.currentCompanyName.updateValueAndValidity();
			this.form.controls.currentEmploymentStartDate.updateValueAndValidity();
			this.form.controls.currentIndustry.updateValueAndValidity();
			this.form.controls.currentJobCategory.updateValueAndValidity();
			this.form.controls.currentJobTitle.updateValueAndValidity();
			this.form.updateValueAndValidity();
		});
	}

	private disableAllReferenceInputs() {
		this.form.controls.referenceName1.disable();
		this.form.controls.referenceRelationship1.disable();
		this.form.controls.referenceJobTitle1.disable();
		this.form.controls.referenceOtherJobTitle1.disable();
		this.form.controls.referenceEmail1.disable();
		this.form.controls.referenceContactNumber1.disable();
		this.form.controls.referenceName2.disable();
		this.form.controls.referenceRelationship2.disable();
		this.form.controls.referenceJobTitle2.disable();
		this.form.controls.referenceOtherJobTitle2.disable();
		this.form.controls.referenceEmail2.disable();
		this.form.controls.referenceContactNumber2.disable();
		this.form.controls.referenceName3.disable();
		this.form.controls.referenceRelationship3.disable();
		this.form.controls.referenceJobTitle3.disable();
		this.form.controls.referenceOtherJobTitle3.disable();
		this.form.controls.referenceEmail3.disable();
		this.form.controls.referenceContactNumber3.disable();
	}

	private allReferencesAreValid() {
		return (
			this.form.controls.referenceName1.valid &&
			this.form.controls.referenceRelationship1.valid &&
			this.form.controls.referenceJobTitle1.valid &&
			this.form.controls.referenceOtherJobTitle1.valid &&
			this.form.controls.referenceEmail1.valid &&
			this.form.controls.referenceContactNumber1.valid &&
			this.form.controls.referenceName2.valid &&
			this.form.controls.referenceRelationship2.valid &&
			this.form.controls.referenceJobTitle2.valid &&
			this.form.controls.referenceOtherJobTitle2.valid &&
			this.form.controls.referenceEmail2.valid &&
			this.form.controls.referenceContactNumber2.valid &&
			this.form.controls.referenceName3.valid &&
			this.form.controls.referenceRelationship3.valid &&
			this.form.controls.referenceJobTitle3.valid &&
			this.form.controls.referenceOtherJobTitle3.valid &&
			this.form.controls.referenceEmail3.valid &&
			this.form.controls.referenceContactNumber3.valid
		);
	}

	private enableAllReferenceInputs() {
		this.form.controls.referenceName1.enable();
		this.form.controls.referenceRelationship1.enable();
		this.form.controls.referenceJobTitle1.enable();
		this.form.controls.referenceOtherJobTitle1.enable();
		this.form.controls.referenceEmail1.enable();
		this.form.controls.referenceContactNumber1.enable();
		this.form.controls.referenceName2.enable();
		this.form.controls.referenceRelationship2.enable();
		this.form.controls.referenceJobTitle2.enable();
		this.form.controls.referenceOtherJobTitle2.enable();
		this.form.controls.referenceEmail2.enable();
		this.form.controls.referenceContactNumber2.enable();
		this.form.controls.referenceName3.enable();
		this.form.controls.referenceRelationship3.enable();
		this.form.controls.referenceJobTitle3.enable();
		this.form.controls.referenceOtherJobTitle3.enable();
		this.form.controls.referenceEmail3.enable();
		this.form.controls.referenceContactNumber3.enable();
	}

	updateValidity(value: boolean) {
		if (!value || this.previousEmployerFormGroup.length > 0) {
			this.form.controls.referenceName1.setValidators([
				Validators.required,
				Validators.maxLength(entityConfig.reference.maxNameLength),
			]);
			this.form.controls.referenceRelationship1.setValidators([Validators.required]);
			this.form.controls.referenceJobTitle1.setValidators(Validators.required);
			this.form.controls.referenceContactNumber1.setValidators([
				Validators.required,
				contactNumberValidator(),
				referencesContactNumberValidator(),
			]);
			this.form.controls.referenceEmail1.setValidators([
				Validators.email,
				referencesEmailValidator,
				Validators.pattern(RegexService.validEmailAddress()),
			]);
			this.form.controls.referenceEmail1.setAsyncValidators([referencesEmailValidatorAsync(this.validationService)]);

			this.form.controls.referenceName2.setValidators([
				Validators.required,
				Validators.maxLength(entityConfig.reference.maxNameLength),
			]);
			this.form.controls.referenceRelationship2.setValidators([Validators.required]);
			this.form.controls.referenceJobTitle2.setValidators(Validators.required);
			this.form.controls.referenceContactNumber2.setValidators([
				Validators.required,
				contactNumberValidator(),
				referencesContactNumberValidator(),
			]);
			this.form.controls.referenceEmail2.setValidators([
				Validators.email,
				referencesEmailValidator,
				Validators.pattern(RegexService.validEmailAddress()),
			]);
			this.form.controls.referenceEmail2.setAsyncValidators([referencesEmailValidatorAsync(this.validationService)]);

			this.form.controls.referenceName3.setValidators([
				Validators.required,
				Validators.maxLength(entityConfig.reference.maxNameLength),
			]);
			this.form.controls.referenceRelationship3.setValidators([Validators.required]);
			this.form.controls.referenceJobTitle3.setValidators(Validators.required);
			this.form.controls.referenceContactNumber3.setValidators([
				Validators.required,
				contactNumberValidator(),
				referencesContactNumberValidator(),
			]);
			this.form.controls.referenceEmail3.setValidators([
				Validators.email,
				referencesEmailValidator,
				Validators.pattern(RegexService.validEmailAddress()),
			]);
			this.form.controls.referenceEmail3.setAsyncValidators([referencesEmailValidatorAsync(this.validationService)]);
		} else {
			this.form.controls.referenceName1.setValidators(null);
			this.form.controls.referenceRelationship1.setValidators(null);
			this.form.controls.referenceJobTitle1.setValidators(null);
			this.form.controls.referenceOtherJobTitle1.clearValidators();
			this.form.controls.referenceContactNumber1.setValidators(null);
			this.form.controls.referenceEmail1.setValidators(null);

			this.form.controls.referenceName2.setValidators(null);
			this.form.controls.referenceRelationship2.setValidators(null);
			this.form.controls.referenceJobTitle2.setValidators(null);
			this.form.controls.referenceOtherJobTitle2.clearValidators();
			this.form.controls.referenceContactNumber2.setValidators(null);
			this.form.controls.referenceEmail2.setValidators(null);

			this.form.controls.referenceName3.setValidators(null);
			this.form.controls.referenceRelationship3.setValidators(null);
			this.form.controls.referenceJobTitle3.setValidators(null);
			this.form.controls.referenceOtherJobTitle3.clearValidators();
			this.form.controls.referenceContactNumber3.setValidators(null);
			this.form.controls.referenceEmail3.setValidators(null);
		}

		this.form.controls.referenceName1.updateValueAndValidity();
		this.form.controls.referenceRelationship1.updateValueAndValidity();
		this.form.controls.referenceJobTitle1.updateValueAndValidity();
		this.form.controls.referenceOtherJobTitle1.updateValueAndValidity();
		this.form.controls.referenceContactNumber1.updateValueAndValidity();
		this.form.controls.referenceEmail1.updateValueAndValidity();

		this.form.controls.referenceName2.updateValueAndValidity();
		this.form.controls.referenceRelationship2.updateValueAndValidity();
		this.form.controls.referenceJobTitle2.updateValueAndValidity();
		this.form.controls.referenceOtherJobTitle2.updateValueAndValidity();
		this.form.controls.referenceContactNumber2.updateValueAndValidity();
		this.form.controls.referenceEmail2.updateValueAndValidity();

		this.form.controls.referenceName3.updateValueAndValidity();
		this.form.controls.referenceRelationship3.updateValueAndValidity();
		this.form.controls.referenceJobTitle3.updateValueAndValidity();
		this.form.controls.referenceOtherJobTitle3.updateValueAndValidity();
		this.form.controls.referenceContactNumber3.updateValueAndValidity();
		this.form.controls.referenceEmail3.updateValueAndValidity();
		this.form.updateValueAndValidity();
	}

	get mainForm() {
		return this.form.controls;
	}

	get dynamicForm() {
		return this.mainForm.previousEmployers as FormArray;
	}

	get previousEmployerFormGroup() {
		return this.dynamicForm.controls as FormGroup[];
	}

	getFlinkCoList() {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinkCoList());
	}

	submit(): void {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');
			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		const flinkerEmploymentDetailsRequest = this.mapToFlinkerEmploymentDetailsRequest();
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerEmploymentDetails({
				flinkerEmploymentDetailsRequest,
			}),
		);
	}

	mapToFlinkerEmploymentDetailsRequest(): FlinkerEmploymentDetailsRequest {
		const formValues = this.form.value;

		let currentEmployer: CurrentEmployer = null;
		const previousEmployers: PreviousEmployer[] = [];
		if (!formValues.unemployed) {
			if (formValues.currentCompanyName != '') {
				currentEmployer = {
					id: formValues.currentEmploymentId,
					companyName: formValues.currentCompanyName,
					industry: formValues.currentIndustry,
					startDate: formValues.currentEmploymentStartDate,
					jobCategory: formValues.currentJobCategory,
					jobTitle: formValues.currentJobTitle,
					otherJobTitle: formValues.otherJobTitle,
					dutiesDescription: formValues.currentDuties,
				};
			}
		}

		formValues.previousEmployers.forEach((previousEmployer: any) => {
			previousEmployers.push({
				id: previousEmployer.previousEmploymentId,
				companyName: previousEmployer.previousCompanyName,
				industry: previousEmployer.previousIndustry,
				startDate: previousEmployer.previousEmploymentStartDate,
				endDate: previousEmployer.previousEmploymentEndDate,
				jobCategory: previousEmployer.previousJobCategory,
				jobTitle: previousEmployer.previousJobTitle,
				otherJobTitle: previousEmployer.previousOtherJobTitle,
				dutiesDescription: previousEmployer.previousDuties,
				reasonForLeaving: previousEmployer.previousReasonForLeaving,
			});
		});
		const references: Reference[] = [];
		if (this.allowReferenceUpdate) {
			const reference1: Reference =
				formValues.referenceName1 != ''
					? {
							id: formValues.referenceId1,
							name: formValues.referenceName1,
							emailAddress: formValues.referenceEmail1,
							contactNumber: formValues.referenceContactNumber1,
							referenceRelationshipCode: formValues.referenceRelationship1,
							jobTitle: formValues.referenceJobTitle1,
							otherJobTitle: formValues.referenceOtherJobTitle1,
					  }
					: null;
			references.push(reference1);
			const reference2: Reference =
				formValues.referenceName1 != ''
					? {
							id: formValues.referenceId2,
							name: formValues.referenceName2,
							emailAddress: formValues.referenceEmail2,
							contactNumber: formValues.referenceContactNumber2,
							referenceRelationshipCode: formValues.referenceRelationship2,
							jobTitle: formValues.referenceJobTitle2,
							otherJobTitle: formValues.referenceOtherJobTitle2,
					  }
					: null;
			references.push(reference2);

			const reference3: Reference =
				formValues.referenceName1 != ''
					? {
							id: formValues.referenceId3,
							name: formValues.referenceName3,
							emailAddress: formValues.referenceEmail3,
							contactNumber: formValues.referenceContactNumber3,
							referenceRelationshipCode: formValues.referenceRelationship3,
							jobTitle: formValues.referenceJobTitle3,
							otherJobTitle: formValues.referenceOtherJobTitle3,
					  }
					: null;
			references.push(reference3);
		}

		let unemployedReason: UnemployedReason = null;
		if (formValues.unemployed && formValues.student) {
			unemployedReason = UnemployedReason.Student;
		} else if (formValues.unemployed && previousEmployers.length === 0) {
			unemployedReason = UnemployedReason.NoExperience;
		}

		return {
			currentEmployer: currentEmployer,
			previousEmployers: previousEmployers,
			unemployedReason,
			references: this.allowReferenceUpdate ? references : this.flinker.references,
		};
	}

	uploadJobDescriptions(flinker: FlinkerResponse): void {
		const formData = new FormData();
		const currentEmploymentId = flinker?.currentEmployer?.id;
		const previousEmploymentIds: string[] = [];
		const previousEmploymentDocuments: File[] = [];

		if (currentEmploymentId && this.currentJobDescription) {
			formData.append('currentEmploymentId', currentEmploymentId);
			formData.append('currentEmploymentJobDescription', this.currentJobDescription);
		}

		flinker.previousEmployers?.forEach((previousEmployer, i) => {
			if (this.previousJobDescriptions[i] && previousEmployer.id) {
				previousEmploymentIds.push(previousEmployer.id);
				previousEmploymentDocuments.push(this.previousJobDescriptions[i]);
			}
		});

		formData.append('previousEmploymentIds', JSON.stringify(previousEmploymentIds));

		previousEmploymentDocuments?.forEach((previousEmploymentDocument) => {
			formData.append('previousEmploymentDocuments', previousEmploymentDocument, previousEmploymentDocument.name);
		});

		if (!formData.get('currentEmploymentId') && previousEmploymentIds.length == 0) {
			this.success.emit();
			return;
		}
		this.subscriptions.add(
			this.uploadEmploymentDocumentsHttpPackage$
				.pipe(filter((response) => response.result != null))
				.subscribe((response) => {
					this.success.emit();
					if (this.isTakeOn) {
						return;
					}
					this.notifierService.notify(
						NotificationType.Success,
						DictionarySuccessMessages.EmploymentJobDescriptionUpload,
					);
				}),
		);
		this.store.dispatch(new FlinkerTakeOnStoreActions.UploadEmploymentDocuments({ formData }));
	}

	addPreviousEmployer() {
		const dynamicUsers = this.form.controls.previousEmployers as FormArray;
		this.previousJobDescriptions.push(null);
		const previousEmployer = new FormGroup({
			previousEmploymentId: new FormControl(null),
			previousCompanyName: new FormControl('', Validators.required),
			previousIndustry: new FormControl('', Validators.required),
			previousReasonForLeaving: new FormControl('', Validators.required),
			previousEmploymentStartDate: new FormControl('', [
				Validators.required,
				startDatevalidator('previousEmploymentStartDate'),
			]),
			previousEmploymentEndDate: new FormControl('', [
				Validators.required,
				endDatevalidator('previousEmploymentStartDate', 'previousEmploymentEndDate'),
			]),
			previousJobCategory: new FormControl('', Validators.required),
			previousJobTitle: new FormControl({ value: '', disabled: true }, Validators.required),
			previousOtherJobTitle: new FormControl(''),
			previousDuties: new FormControl('', [Validators.maxLength(Number(this.dutiesDescriptionLength))]),
			previousJobDescription: new FormControl(''),
		});
		previousEmployer.controls.previousJobCategory.valueChanges.subscribe((value) => {
			value === '' || value === null
				? previousEmployer.controls.previousJobTitle.disable()
				: previousEmployer.controls.previousJobTitle.enable();
			previousEmployer.controls.previousJobTitle.reset();
		});
		dynamicUsers.push(previousEmployer);
		this.numberOfPreviousEmployers += 1;
		this.updateValidity(this.form.controls.unemployed.value);
		this.form.updateValueAndValidity();
	}

	removePreviousEmployer() {
		if (this.numberOfPreviousEmployers !== 0) {
			const previousEmployers = [...this.flinker.previousEmployers];
			previousEmployers.pop();
			this.previousEmployerFormGroup.pop();
			this.previousJobDescriptions.pop();

			this.form.patchValue({
				previousEmployers: previousEmployers,
			});

			this.numberOfPreviousEmployers -= 1;
			this.updateValidity(this.form.controls.unemployed.value);
			this.form.updateValueAndValidity();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
