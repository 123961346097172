import { DownloadedFile } from '@app/services/http/http-v2.service';
import HttpPackage from '@models/http-package';
import { defaultHttpPackage } from '@root-store/helpers';

export interface FlinkCoFlinkerDocumentsState {
	downloadFlinkerIdDocumentHttpPackage: HttpPackage<DownloadedFile>;
	downloadFlinkerMatricDocumentHttpPackage: HttpPackage<DownloadedFile>;
	downloadFlinkerHighestQualificationDocumentHttpPackage: HttpPackage<DownloadedFile>;
	downloadFlinkerPassportDocumentHttpPackage: HttpPackage<DownloadedFile>;

	canDownloadFlinkerIdDocumentHttpPackage: HttpPackage<boolean>;
	canDownloadFlinkerMatricDocumentHttpPackage: HttpPackage<boolean>;
	canDownloadFlinkerHighestQualificationDocumentHttpPackage: HttpPackage<boolean>;
	canDownloadFlinkerPassportDocumentHttpPackage: HttpPackage<boolean>;
}
export const initialState: FlinkCoFlinkerDocumentsState = {
	downloadFlinkerIdDocumentHttpPackage: defaultHttpPackage(),
	downloadFlinkerMatricDocumentHttpPackage: defaultHttpPackage(),
	downloadFlinkerHighestQualificationDocumentHttpPackage: defaultHttpPackage(),
	downloadFlinkerPassportDocumentHttpPackage: defaultHttpPackage(),

	canDownloadFlinkerIdDocumentHttpPackage: defaultHttpPackage(),
	canDownloadFlinkerMatricDocumentHttpPackage: defaultHttpPackage(),
	canDownloadFlinkerHighestQualificationDocumentHttpPackage: defaultHttpPackage(),
	canDownloadFlinkerPassportDocumentHttpPackage: defaultHttpPackage(),
};
