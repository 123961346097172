import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import ErrorResponse from '../../../models/dto/responses/error-response.dto';
import { AuthService } from '../../../services/auth/auth.service';
import { getErrorMessage } from '../../../utils/form.util';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	form: FormGroup;
	resetRequestSuccessful: boolean;
	getErrorMessage = getErrorMessage;
	loading = false;
	shouldValidate = false;

	constructor(formBuilder: FormBuilder, private authService: AuthService) {
		this.form = formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	onSubmit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.loading = true;
		this.subscriptions.add(
			this.authService.resetPasswordRequest({ emailAddress: this.form.controls.email.value }).subscribe(
				(_: any) => {
					this.resetRequestSuccessful = true;
					this.loading = false;
				},
				(err: ErrorResponse) => {
					this.loading = false;
				},
			),
		);
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.form.controls.email.valueChanges.subscribe((_) => {
				this.resetRequestSuccessful = null;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
