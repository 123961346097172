<div class="wrapper-container">
	<div class="take-on-container">
		<app-popup-notification class="front-sub-1">
			[Disclaimer] - By making use of this Website and our Services, you acknowledge that you have read, understand, and
			agree to be bound by the terms set out in this Privacy Policy, as amended from time to time. Your continued use of
			our Website and our Services also indicates that you acknowledge and agree that this Privacy Policy may be amended
			from time to time, without notice to you and agree to be bound by the amended terms.
		</app-popup-notification>
		<div class="container py-5">
			<app-page-title [title]="'Flink SA (Pty) Ltd Privacy Policy'"></app-page-title>
			<mat-card>
				<div class="front-sub-3 font-size-11">
					<app-page-header-toolbar heading="1. Overview"> </app-page-header-toolbar>

					1.1. Flink recognises the obligations imposed by the Protection of Personal Information Act 4 of 2013 (“POPI”)
					and the provisions of data protection set out in the General Data Protection Regulation 2016/679 (“the GDPR”).
					This Privacy Policy must accordingly be interpreted in accordance with the definitions set out in POPI and the
					GDPR (hereinafter collectively referred to as “Privacy Laws”).

					<br /><br />

					1.2. Given the nature of our business, we are necessarily involved in the collection and processing of
					personal information. Ensuring data privacy and the protection of your personal information is important to
					us. In the event that we collect, use or disclose your personal information, we are committed to ensuring that
					such collection, use and disclosure is lawful, reasonable and pursuant to the purpose of our business
					engagements with you, as prescribed by Privacy Laws.

					<br /><br />

					1.3. This Privacy Policy is applicable to any User, Flinker and Company using our Services or browsing our
					Website.

					<br /><br />

					1.4. This Privacy Policy sets out what personal information we collect from you when you make use of our
					services or browse our website, how we collect your personal information, the purpose for collecting such
					personal information, and how we use your personal information.

					<br /><br />

					<app-page-header-toolbar
						heading="2.	WHAT IS PERSONAL INFORMATION AND WHY DO WE COLLECT YOUR PERSONAL INFORMATION"
					>
					</app-page-header-toolbar>

					2.1. Personal information refers to information relating to an identifiable person (including individuals and
					organisations). This includes information about your name, contact details, including your phone number and
					email, address, business information and relevant financial and tax-related information.

					<br /><br />

					2.2. We will use our best endeavours to limit the processing of your personal information to that which you
					have provided your express consent. Notwithstanding this, your continued use and enjoyment of our Website and
					Services, and your agreement to this Privacy Policy is construed as consent in terms of section 69 of POPI and
					Article 7 of the GDPR, as the case may be.

					<br /><br />

					2.3. We collect your personal information in order to provide, maintain and improve the performance of our
					services. This includes collecting information to:

					<br /><br />

					2.3.1. Establish and verify your identity and business operations, including those processes which may be
					required from us by law or good industry practice;

					<br /><br />

					2.3.2. Perform the Services that you request of us;

					<br /><br />

					2.3.3. Accomplish any further purpose that is related to providing our Services;

					<br /><br />

					2.3.4. Communicate with you in the manner in which you prefer, including via email, post, SMS or
					telephonically;

					<br /><br />

					2.3.5. Develop an online User profile;

					<br /><br />

					2.3.6. Maintain and update our database of Users;

					<br /><br />

					2.3.7. Update and inform you about our Services and any changes to our Services or Website;

					<br /><br />

					2.3.8. Respond to any queries, concerns or requests that you may lodge with us or any Authority;

					<br /><br />

					2.3.9. Conduct market or client satisfaction research or for statistical analysis, including the use of
					information to understand general User trends and behaviours to ensure our Services and Website remain
					relevant;

					<br /><br />

					2.3.10. Comply with auditing or record keeping purposes;

					<br /><br />

					2.3.11. Fulfil any contractual or legal obligation that we may have to you or a third party;

					<br /><br />

					2.3.12. Comply with any law or regulation or legal request from a recognised Authority;

					<br /><br />

					2.3.13. Comply with any software, security-related or administrative purpose; and

					<br /><br />

					2.3.14. Any other activity which may be lawful, reasonable and necessary to our business activities.

					<br /><br />

					<app-page-header-toolbar heading="3.	HOW WE COLLECT YOUR PERSONAL INFORMATION"> </app-page-header-toolbar>

					3.1. We collect your information in the four ways.

					<br /><br />

					3.2. First, we collect your personal information directly from you or your representatives. We may collect
					information through electronic communication, telephonically, during meetings, through related documentation
					and in general interactions with you or your representatives, including electronic interactions.

					<br /><br />

					3.3. Secondly, we collect your personal information from your electronic profiles, including your website and
					social media platforms. Information received from your online profiles are generally used for general
					informational purposes and to obtain the information necessary to understand your business, activities and
					vision.

					<br /><br />

					3.4. Thirdly, we collect your personal information from our affiliates and third-party service providers. This
					information is used for verification purposes, general informational purposes and to supplement information
					which you have already provided or agreed to provide.

					<br /><br />

					3.5. Lastly, we may collect your personal information through IT and electronic networks to determine who is
					visiting and using our Website and other online platforms. This includes personal information like your IP
					address, the name of your Internet Service Provider, your browser, the pages of our Website, the date and time
					that you visit our Website and other information relating to your computer operating system, among other
					things.

					<br /><br />

					<app-page-header-toolbar heading="4.	HOW LONG WILL WE KEEP YOUR INFORMATION"> </app-page-header-toolbar>

					4.1. We may retain your personal information for as long as we continue to provide Services to you and for
					five years thereafter. For purposes of this section, our Services will be regarded as being terminated if you
					no longer access our Services or browse our Website for a consecutive period of two years.

					<br /><br />

					4.2. We may further retain your personal information for a longer period of time if:

					<br /><br />

					4.2.1. We are required by law or regulation to do so;

					<br /><br />

					4.2.2. We are required by a contractual obligation with you to do so or you expressly request us to retain
					such information for a longer period; or

					<br /><br />

					4.2.3. We are required to keep your personal information for a legitimate purpose related to our Services.

					<br /><br />

					<app-page-header-toolbar heading="5.	DISCLOSING YOUR PERSONAL INFORMATION"> </app-page-header-toolbar>

					5.1. We will not disclose your personal information unless it is for purposes as set out in this Privacy
					Policy or if we have received your permission to do so. We will disclose your personal information:

					<br /><br />

					5.1.1. To our employees, contractors and agents to the extent that these parties need your personal
					information in order for us to provide Services to you;

					<br /><br />

					5.1.2. To our affiliates;

					<br /><br />

					5.1.3. To authorities or governmental agencies in order to comply with any law, regulation or legal process;
					or

					<br /><br />

					5.1.4. In order to protect our rights, property or safety or those of our employees, contractors, agents,
					clients or any other third party.

					<br /><br />

					<app-page-header-toolbar heading="6.	INFORMATION SECURITY"> </app-page-header-toolbar>

					6.1. We take care to ensure that your personal information is adequately protected against unauthorised access
					and use and remains secure.

					<br /><br />

					6.2. We may store your personal information at a physical location or by electronic means, including on a
					cloud-based system. We store your personal information directly or transfer and store it on a central
					database.

					<br /><br />

					6.3. We will take reasonable, appropriate and generally accepted measures to ensure that your personal
					information is protected against unauthorised and unlawful processing, accidental loss, destruction or damage.
					However, we cannot guarantee that the collection, transmission and storage of your personal information is
					absolutely secure online. We accordingly do not warrant the security of any personal information we process.

					<br /><br />

					6.4. In order to protect your personal information, we undertake to regularly review our security controls and
					implement appropriate and generally accepted technical and organisational measures to ensure such protection
					as far as reasonably possible.

					<br /><br />

					6.5. We further impose necessary security, privacy and confidentiality obligations on those third parties that
					we have a contractual relationship with to ensure, as far as reasonably possible, that your personal
					information remains secure. We will also take the necessary measures to ensure that such third parties agree
					to treat your personal information with the same standard of care as we are obliged to do.

					<br /><br />

					6.6. If your personal information is processed in a country that does not have substantially similar laws for
					the protection of personal information, we will take the necessary measures to ensure that your personal
					information is adequately protected in terms of the minimum standards set down by the GDPR.

					<br /><br />

					<app-page-header-toolbar heading="7. CONSENT OF PROCESSING BY THIRD PARTY SERVICE PROVIDERS">
					</app-page-header-toolbar>

					7.1. We make use of third-party assessors, including Managed Integrity Evaluation (Pty) Ltd and Integrity
					International (Pty) Ltd to conduct background screening checks and psychometric assessments (“the
					Assessments”) respectively.

					<br /><br />

					7.2. The further terms regulating the processing of Personal Information for purposes of the Assessments are
					found in the Disclosure Consent Terms.

					<br /><br />

					<app-page-header-toolbar heading="8. YOUR RIGHTS"> </app-page-header-toolbar>

					You have the following rights as they relate to the collection and processing of your personal information:

					<br /><br />

					8.1. Access to information: you have the right to request a copy of the personal information that we hold
					about you. A request for such information may be subject to an administrative fee permitted by law. We may
					also require a copy of your identity or registration documentation for verification purposes.

					<br /><br />

					8.2. Right to the correction of information: you have the right to request that we update or correct the
					personal information that we hold about you. We may also require a copy of your identity or registration
					documentation for verification purposes.

					<br /><br />

					8.3. Right to the deletion of information: under certain circumstances, you have the right to request that we
					delete the personal information that we hold about you. We may also require a copy of your identity or
					registration documentation for verification purposes.

					<br /><br />

					8.4. Right to object: you have the right to object to us processing the personal information that we hold
					about you if you have not consented to the processing, the processing is not necessary to perform our
					services, or if the consent is not related to a legal purpose or for the discharge of a legal obligation.

					<br /><br />

					8.5. Right to lodge a complaint: you have the right to complain if you are concerned about the way in which we
					collect or process the personal information that we hold about you.

					<br /><br />

					8.6. If you believe that we have used your personal information in a manner which is contrary to this Privacy
					Policy, we request that you first attempt to resolve any concerns with us directly, by contacting our
					information officer at:

					<br /><br />

					Rene Schreuder and Telisha van der Merwe rene@flink-app.com OR telisha@flink-app.com

					<br /><br />

					8.7. If you are not satisfied with the process, you have the right to lodge a complaint with the Information
					Regulator at:
					<br />
					The Information Regulator (South Africa) JD House 27 Stiemens Street Braamfontein Johannesburg
					complaints.IR@justice.gov.za

					<br /><br />

					<app-page-header-toolbar heading="9. COOKIES"> </app-page-header-toolbar>

					9.1. When viewing or visiting our Website, information may be retrieved or collected from your browser and
					stored, mostly in the form of cookies. A cookie is a file containing an identifier that is sent by a web
					server to a web browser and is stored by that browser.

					<br /><br />

					9.2. The personal information that we store about you may be linked to the information stored in and obtained
					from cookies. It does not usually directly identify you, but it can give you a more personalised web
					experience.

					<br /><br />

					9.3. Insofar as such cookies are not strictly necessary for providing our online Services and related
					products, we will ask you to consent to our use of cookies when you first visit our Website and other online
					platforms.

					<br /><br />

					9.4. We use both “persistent” cookies and “session” cookies: a persistent cookie will be stored by a web
					browser and will remain valid until its set expiry date, unless you delete it before the expiry date; a
					session cookie, on the other hand, will expire at the end of your session, when the web browser is closed.

					<br /><br />

					9.5. You are entitled to review and manage your Cookie Preferences at any time.

					<br /><br />

					<app-page-header-toolbar heading="10. STATUS OF THIS PRIVACY POLICY"> </app-page-header-toolbar>

					10.1. This Privacy Policy must be read as if specifically incorporated into the Terms of Use, the Terms and
					Conditions with the Companies and the Disclosure Consent Terms.

					<br /><br />

					<app-page-header-toolbar heading="11. CHANGES TO THIS POLICY"> </app-page-header-toolbar>

					11.1. We may update this privacy policy at any time by publishing an updated version on our Website. The
					updated policy will be published on our Website. We encourage you to review this Policy on a regular basis.
				</div>
			</mat-card>
		</div>
	</div>
</div>
