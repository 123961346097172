import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { flinkCoTakeOnReducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { FlinkCoTakeOnStoreEffects } from './effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinkcoTakeOn', flinkCoTakeOnReducer),
		EffectsModule.forFeature([FlinkCoTakeOnStoreEffects]),
	],
	providers: [FlinkCoTakeOnStoreEffects],
})
export class FlinkCoTakeOnStoreModule {}
