<div class="container mw-100 min-vh-100 p-1 p-md-5">
	<mat-card class="p-2 py-md-3 px-md-5">
		<app-calendar
			class="d-none d-md-block"
			*ngIf="(getFlinkerInterviewsHttpPackage$ | async)?.result"
			[events]="events"
			(onEventClick)="viewDetailedInterview($event)"
		></app-calendar>

		<app-page-header-toolbar theme="pt-3" heading="Upcoming Interviews"> </app-page-header-toolbar>

		<div class="row mb-3">
			<div class="col-12 px-4">
				<app-flinker-upcoming-interviews></app-flinker-upcoming-interviews>
			</div>
		</div>

		<app-page-header-toolbar theme="pt-3" heading="Past Interviews"> </app-page-header-toolbar>

		<div class="row">
			<div class="col-12 px-4">
				<app-flinker-past-interviews></app-flinker-past-interviews>
			</div>
		</div>
	</mat-card>
</div>
