import HttpPackage from '@models/http-package';
import CvPaymentStatusResponseDto from '@models/dto/responses/cv-payment-status-response.dto';
import PayForCvResponseDto from '@models/dto/responses/pay-for-cv-response.dto';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

export interface CvState {
	cvFeaturesHttpPackage: HttpPackage<CvFeatureResponseDto[]>;
	cvPaymentStatusHttpPackage: HttpPackage<CvPaymentStatusResponseDto>;
	payForCvHttpPackage: HttpPackage<PayForCvResponseDto>;
	downloadFlinkerCv: HttpPackage<any>;
}

export const initialState: CvState = {
	cvFeaturesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	cvPaymentStatusHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	payForCvHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	downloadFlinkerCv: {
		error: null,
		loading: false,
		result: null,
	},
};
