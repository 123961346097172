import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OfferStoreActions } from '.';
import {
	CancelOffer,
	CreateOffer,
	DownloadOfferDocuments,
	GetFlinkCoOffer,
	GetFlinkCoOffers,
	GetFlinkerOffer,
	UpdateOffer,
	UpdateOfferStatus,
	UploadOfferDocuments,
} from './actions';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { OfferService } from '../../services/offer/offer.service';
import OfferResponse from '../../models/dto/responses/offer-response.dto';
import { SearchStoreActions } from '@root-store/search-store';
import { InterviewStoreActions } from '@root-store/interview-store';
import { DownloadedFile } from '@app/services/http/http-v2.service';
import { saveAs } from 'file-saver';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { FlinkCoJobAdvertsActions } from '@root-store/flinkco/job-adverts';

@Injectable()
export class OfferStoreEffects {
	constructor(private actions$: Actions, private offerService: OfferService) {}

	createOfferEffects$ = createEffect(() =>
		this.actions$.pipe(
			ofType<OfferStoreActions.CreateOffer>(OfferStoreActions.ActionTypes.CREATE_OFFER),
			switchMap((action: CreateOffer) => {
				return this.offerService.createOffer(action.payload.createOfferRequest).pipe(
					switchMap((result: OfferResponse) => {
						return [
							new OfferStoreActions.CreateOfferSuccess({ result }),
							new SearchStoreActions.UpdateSearchForOfferMade({
								flinkerId: action.payload.createOfferRequest.flinkerId,
							}),
							new InterviewStoreActions.UpdateInterviewForOfferMade({
								flinkerId: action.payload.createOfferRequest.flinkerId,
							}),
							new FlinkCoJobAdvertsActions.UpdateJobApplicationForOfferMade({
								flinkerId: action.payload.createOfferRequest.flinkerId,
							}),
						];
					}),
					catchError((error: ErrorResponse) => {
						return of(new OfferStoreActions.CreateOfferError({ errorResponse: error }));
					}),
				);
			}),
		),
	);

	getFlinkerOffersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.GetFlinkerOffers>(OfferStoreActions.ActionTypes.GET_FLINKER_OFFERS),
			switchMap(() =>
				this.offerService.getFlinkerOffers().pipe(
					map((result: OfferResponse[]) => new OfferStoreActions.GetFlinkerOffersSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.GetFlinkerOffersError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkerOfferEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.GetFlinkerOffer>(OfferStoreActions.ActionTypes.GET_FLINKER_OFFER),
			switchMap((action: GetFlinkerOffer) =>
				this.offerService.getFlinkerOffer(action.payload.offerId).pipe(
					map((result: OfferResponse) => new OfferStoreActions.GetFlinkerOfferSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.GetFlinkerOfferError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkCoOffersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.GetFlinkCoOffers>(OfferStoreActions.ActionTypes.GET_FLINKCO_OFFERS),
			switchMap((action: GetFlinkCoOffers) =>
				this.offerService.getFlinkCoOffers(action.payload.pageNumber, action.payload.pageSize).pipe(
					map((result: PaginatedResponse<OfferResponse>) => new OfferStoreActions.GetFlinkCoOffersSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.GetFlinkCoOffersError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkCoOfferEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.GetFlinkCoOffer>(OfferStoreActions.ActionTypes.GET_FLINKCO_OFFER),
			switchMap((action: GetFlinkCoOffer) =>
				this.offerService.getFlinkCoOffer(action.payload.offerId).pipe(
					map((result: OfferResponse) => new OfferStoreActions.GetFlinkCoOfferSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.GetFlinkCoOfferError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateOfferEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.UpdateOffer>(OfferStoreActions.ActionTypes.UPDATE_OFFER),
			switchMap((action: UpdateOffer) =>
				this.offerService.updateOffer(action.payload.updateOfferRequest).pipe(
					map((result: OfferResponse) => new OfferStoreActions.UpdateOfferSuccess({ result })),
					catchError((error: ErrorResponse) => of(new OfferStoreActions.UpdateOfferError({ errorResponse: error }))),
				),
			),
		);
	});

	updateOfferStatusEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.UpdateOfferStatus>(OfferStoreActions.ActionTypes.UPDATE_OFFER_STATUS),
			switchMap((action: UpdateOfferStatus) =>
				this.offerService.updateOfferStatus(action.payload.offerStatusRequest).pipe(
					map((result: OfferResponse) => new OfferStoreActions.UpdateOfferStatusSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.UpdateOfferStatusError({ errorResponse: error })),
					),
				),
			),
		);
	});

	cancelOfferEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.CancelOffer>(OfferStoreActions.ActionTypes.CANCEL_OFFER),
			switchMap((action: CancelOffer) =>
				this.offerService.cancelOffer(action.payload.flinkCoCancelOffer).pipe(
					map((result: string) => new OfferStoreActions.CancelOfferSuccess({ successMessage: result })),
					catchError((error: ErrorResponse) => of(new OfferStoreActions.CancelOfferError({ errorResponse: error }))),
				),
			),
		);
	});

	uploadOfferDocumentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.UploadOfferDocuments>(OfferStoreActions.ActionTypes.UPLOAD_OFFER_DOCUMENTS),
			switchMap((action: UploadOfferDocuments) =>
				this.offerService.uploadOfferDocuments(action.payload.offerId, action.payload.formFiles).pipe(
					map(
						(result: any) =>
							new OfferStoreActions.UploadOfferDocumentsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.UploadOfferDocumentsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	downloadOfferDocumentEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<OfferStoreActions.DownloadOfferDocuments>(OfferStoreActions.ActionTypes.DOWNLOAD_OFFER_DOCUMENTS),
			switchMap((action: DownloadOfferDocuments) =>
				this.offerService.downloadOfferDocuments(action.payload.offerId).pipe(
					tap((response: DownloadedFile) => {
						saveAs(response.file, response.filename);
					}),
					map(
						(result: any) =>
							new OfferStoreActions.DownloadOfferDocumentsSuccess({
								result,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new OfferStoreActions.DownloadOfferDocumentsError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
