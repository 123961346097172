import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import ErrorResponse from '../../../models/dto/responses/error-response.dto';
import { AuthService } from '../../../services/auth/auth.service';
import { passwordConfirmValidator } from '../../../utils/custom-validators.util';
import { getErrorMessage } from '../../../utils/form.util';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	form: FormGroup;
	emailForm: FormGroup;
	getErrorMessage = getErrorMessage;
	loading = false;
	resetSuccessful: boolean;
	requestSuccessful: boolean;
	userId: string;
	resetToken: string;
	error = false;
	shouldValidate = false;

	constructor(
		formBuilder: FormBuilder,
		private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.form = formBuilder.group(
			{
				password: [
					'',
					[Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*?[_#?!@$%^&*-])(?=.*[A-Z]).{8,32}')],
				],
				confirmPassword: [
					'',
					[Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*?[_#?!@$%^&*-]).{8,32}')],
				],
			},
			{ validator: passwordConfirmValidator('password', 'confirmPassword') },
		);
		this.emailForm = formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	onSubmit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.loading = true;
		this.subscriptions.add(
			this.authService
				.resetPassword({
					passwordResetToken: this.resetToken,
					userId: this.userId,
					newPassword: this.form.controls.password.value,
				})
				.subscribe(
					(_: any) => {
						this.resetSuccessful = true;
						this.loading = false;
						this.error = false;
					},
					(err: ErrorResponse) => {
						//TODO: Error handling
						this.loading = false;
						this.error = true;
					},
				),
		);
	}

	submitRequest() {
		if (!this.emailForm.valid) {
			return;
		}
		this.loading = true;
		this.subscriptions.add(
			this.authService
				.resetPasswordRequest({
					emailAddress: this.emailForm.controls.email.value,
				})
				.subscribe(
					(_: any) => {
						this.requestSuccessful = true;
						this.loading = false;
					},
					(err: ErrorResponse) => {
						//TODO: Error handling
						this.loading = false;
					},
				),
		);
	}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((params) => {
			this.userId = params.get('userId');
			this.resetToken = params.get('token');
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
