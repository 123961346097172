import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'yesOrNo',
})
export class YesOrNoPipe implements PipeTransform {
	transform(value: boolean, valueNotSetMessage = 'Not Answered Yet'): unknown {
		return value !== null && value !== undefined ? (value ? 'Yes' : 'No') : valueNotSetMessage;
	}
}
