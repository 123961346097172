import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-delete-confirmation-dialog',
	templateUrl: './delete-confirmation-dialog.component.html',
	styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent implements OnInit, OnDestroy {
	@Input() context = 'Are you sure you want to delete this item?';

	form: FormGroup;
	isDisabled = true;
	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(
		public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { name: string } & any,
		private readonly formBuilder: FormBuilder,
	) {
		this.form = this.formBuilder.group({
			confirm: [''],
		});
	}

	ngOnInit(): void {
		this.form.controls.confirm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val: string) => {
			if (val.toLocaleLowerCase() === 'yes') {
				this.isDisabled = false;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	submit(data: any) {
		this.dialogRef.close({ data });
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
