import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getErrorMessage } from '@app/utils/form.util';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { RootStoreState } from '@root-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerEducationalDetailsRequest from '@models/dto/requests/flinker-educational-details-request.dto';
import Qualification from '@models/dto/qualification.dto';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { getYearOptions, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { QualificationType } from '@app/enums/qualification-type.enum';
import { UnqualifiedReason } from '@app/enums/unqualified-reason.enum';
import { isNil } from '@app/utils/functions.util';
import { RegexService } from '@shared/services/regex.service';
import { DictionaryHints } from '@app/enums/dictionary-words.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-qualifications',
	templateUrl: './qualifications.component.html',
	styleUrls: ['./qualifications.component.scss'],
})
export class QualificationsComponent implements OnInit, OnDestroy {
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	flinkerEducationalDetailsPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerEducationalDetailsHttpPackage,
	);
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	uploadQualificationDocumentsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectUploadQualificationDocumentsHttpPackage,
	);
	form: FormGroup;
	numberOfTertiaryQualifications = 0;
	numberOfOtherQualifications = 0;
	skills: string[];
	getErrorMessage = getErrorMessage;
	highestQualificationDocument: File;
	matricCertificate: File;
	otherQualificationDocument: File;
	yearOptions = getYearOptions();
	futureYearOptions = getYearOptions(true);
	expectUpdate = false;
	shouldValidate = false;
	dictionaryHints = DictionaryHints;

	@Input() allowSkillsUpdate = true;
	@Input() allowFileUploads = true;

	@Output() success = new EventEmitter();

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: FormBuilder, private store: Store<RootStoreState.State>) {
		this.form = this.formBuilder.group({
			matricCertificateId: [null],
			noMatricCertificate: [false],
			matricCertificateInstitution: ['', Validators.required],
			matricCertificateOtherInstitution: [''],
			matricCertificateYearObtained: ['', Validators.required],
			matricCertificateUploaded: [false],
			unqualified: [false],
			student: [false],
			tertiaryQualifications: new FormArray([]),
			otherQualifications: new FormArray([]),
		});
	}

	ngOnInit(): void {
		this.selectOptionsHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((selectOptionsHttpPackage) => {
			this.selectOptions = selectOptionsHttpPackage.result;
		});

		this.initialiseForm();

		this.flinkerEducationalDetailsPackage$
			.pipe(takeUntil(this.destroy$))
			.subscribe((flinkerEducationalDetailsHttpPackage) => {
				if (flinkerEducationalDetailsHttpPackage.result != null && this.expectUpdate) {
					this.expectUpdate = false;
					this.uploadQualificationDocuments(flinkerEducationalDetailsHttpPackage.result);
				}
			});

		this.uploadQualificationDocumentsHttpPackage$
			.pipe(takeUntil(this.destroy$))
			.subscribe((uploadQualificationDocumentsHttpPackage) => {
				if (uploadQualificationDocumentsHttpPackage.result != null) {
					this.success.emit();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	get mainForm() {
		return this.form.controls;
	}

	get tertiaryQualificationsDynamicForm() {
		return this.mainForm.tertiaryQualifications as FormArray;
	}

	get tertiaryQualificationsFormGroup() {
		return this.tertiaryQualificationsDynamicForm.controls as FormGroup[];
	}

	get otherQualificationsDynamicForm() {
		return this.mainForm.otherQualifications as FormArray;
	}

	get otherQualificationsFormGroup() {
		return this.otherQualificationsDynamicForm.controls as FormGroup[];
	}

	initialiseForm(): void {
		this.form.controls.noMatricCertificate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			if (value === false) {
				this.form.controls.matricCertificateInstitution.setValue('');
				this.form.controls.matricCertificateOtherInstitution.setValue('');
				this.form.controls.matricCertificateYearObtained.setValue('');
				this.form.controls.matricCertificateUploaded.setValue('');
				this.form.controls.matricCertificateInstitution.setValidators(Validators.required);
				this.form.controls.matricCertificateOtherInstitution.setValidators(null);
				this.form.controls.matricCertificateYearObtained.setValidators(Validators.required);
				this.form.controls.tertiaryQualifications = this.formBuilder.array([]);
			} else {
				this.form.controls.matricCertificateInstitution.clearValidators();
				this.form.controls.matricCertificateOtherInstitution.clearValidators();
				this.form.controls.matricCertificateYearObtained.clearValidators();
			}

			this.form.controls.tertiaryQualifications.updateValueAndValidity();
			this.form.controls.matricCertificateInstitution.updateValueAndValidity();
			this.form.controls.matricCertificateOtherInstitution.updateValueAndValidity();
			this.form.controls.matricCertificateYearObtained.updateValueAndValidity();
			this.form.controls.matricCertificateUploaded.updateValueAndValidity();
			this.form.updateValueAndValidity();
		});

		this.updateFormWithExistingFlinkerQualifications();
	}

	updateFormWithExistingFlinkerQualifications(): void {
		this.flinker$.pipe(takeUntil(this.destroy$)).subscribe((flinker: FlinkerResponse) => {
			this.flinker = flinker;
			this.tertiaryQualificationsDynamicForm.clear();
			this.otherQualificationsDynamicForm.clear();

			this.form.patchValue({
				noMatricCertificate: isNil(flinker.noMatricCertificate) ? false : flinker.noMatricCertificate,
				unqualified: isNil(flinker.unqualifiedReason) ? false : flinker.unqualifiedReason,
				student: flinker.unqualifiedReason === UnqualifiedReason.Student,
			});
			this.flinker.qualifications.forEach((qualification) => {
				const dynamicTertiary = this.tertiaryQualificationsDynamicForm;
				const dynamicOther = this.otherQualificationsDynamicForm;
				if (qualification.type === QualificationType.Matric) {
					this.form.patchValue({
						matricCertificateId: qualification.id,
						matricCertificateInstitution: qualification.institution,
						matricCertificateOtherInstitution: qualification.otherInstitution,
						matricCertificateYearObtained: qualification.yearObtained,
						matricCertificateUploaded: qualification.uploaded,
					});
				} else if (qualification.type === QualificationType.Tertiary) {
					this.updateFormWithTertiaryQualification(dynamicTertiary, qualification);
				} else if (qualification.type === QualificationType.Other) {
					this.updateFormWithOtherQualification(dynamicOther, qualification);
				}
			});
			this.skills = flinker.skills;
		});
	}

	private setHighestQualification(tertiaryQualifications: FormGroup[], yearObtained: number) {
		let indexOfQualification = 0;

		this.tertiaryQualificationsFormGroup.forEach((qualification) => {
			qualification.controls.tertiaryQualificationHighest.setValue(false, { emitEvent: false });
		});
		let latestQualification = this.tertiaryQualificationsFormGroup[0].value;
		for (let i = 0; i < tertiaryQualifications.length; i++) {
			if (
				tertiaryQualifications[i].value.tertiaryQualificationYearObtained >= yearObtained &&
				latestQualification.tertiaryQualificationYearObtained <
					tertiaryQualifications[i].value.tertiaryQualificationYearObtained
			) {
				latestQualification = tertiaryQualifications[i].value;
				indexOfQualification = i;
			}
		}
		tertiaryQualifications[indexOfQualification].controls.tertiaryQualificationHighest.setValue(true, {
			emitEvent: true,
		});
	}

	private addTertiaryQualificationFormGroupSubscriptions(tertiaryQualificationGroup: FormGroup): void {
		tertiaryQualificationGroup.controls.tertiaryQualificationHighest.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value) {
					const tertiaryQualifications = this.form.controls.tertiaryQualifications as FormArray;

					for (const control of tertiaryQualifications.controls) {
						control.patchValue({
							tertiaryQualificationHighest: false,
						});
					}

					tertiaryQualificationGroup.controls.tertiaryQualificationHighest.setValue(true, {
						emitEvent: false,
					});
					this.highestQualificationDocument = null;
				}
			});

		tertiaryQualificationGroup.controls.tertiaryQualificationInstitution.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value === 'OTH') {
					tertiaryQualificationGroup.controls.tertiaryQualificationOtherInstitution.setValidators(Validators.required);
				} else {
					tertiaryQualificationGroup.controls.tertiaryQualificationOtherInstitution.setValidators(null);
					tertiaryQualificationGroup.controls.tertiaryQualificationOtherInstitution.setValue(null);
				}

				tertiaryQualificationGroup.controls.tertiaryQualificationOtherInstitution.updateValueAndValidity();
				tertiaryQualificationGroup.updateValueAndValidity();
			});

		tertiaryQualificationGroup.controls.tertiaryQualificationInProgress.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value) {
					tertiaryQualificationGroup.controls.tertiaryQualificationYearObtained.setValue(null);
					tertiaryQualificationGroup.controls.tertiaryQualificationYearObtained.setValidators(null);
					tertiaryQualificationGroup.controls.tertiaryQualificationCompletionYear.setValidators(Validators.required);
					tertiaryQualificationGroup.controls.tertiaryQualificationHighest.setValue(false);
				} else {
					tertiaryQualificationGroup.controls.tertiaryQualificationCompletionYear.setValidators(null);
					tertiaryQualificationGroup.controls.tertiaryQualificationCompletionYear.setValue(null);
					tertiaryQualificationGroup.controls.tertiaryQualificationYearObtained.setValidators(Validators.required);
				}

				tertiaryQualificationGroup.controls.tertiaryQualificationCompletionYear.updateValueAndValidity();
				tertiaryQualificationGroup.controls.tertiaryQualificationYearObtained.updateValueAndValidity();
				tertiaryQualificationGroup.updateValueAndValidity();
			});

		tertiaryQualificationGroup.controls.tertiaryQualificationYearObtained.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				this.setHighestQualification(this.tertiaryQualificationsFormGroup, value);
			});
	}

	private updateFormWithTertiaryQualification(dynamicTertiary: FormArray, qualification: Qualification): void {
		const tertiaryQualificationGroup = new FormGroup({
			id: new FormControl(qualification.id),
			tertiaryQualificationCategory: new FormControl(qualification.category, Validators.required),
			tertiaryQualificationName: new FormControl(qualification.name, Validators.required),
			tertiaryQualificationInstitution: new FormControl(qualification.institution, [
				Validators.required,
				Validators.pattern(RegexService.codeHasText()),
			]),
			tertiaryQualificationOtherInstitution: new FormControl(
				qualification.otherInstitution,
				qualification.institution === 'OTH' ? Validators.required : null,
			),
			tertiaryQualificationYearObtained: new FormControl(
				qualification.yearObtained,
				qualification.inProgress ? null : Validators.required,
			),
			tertiaryQualificationInProgress: new FormControl(qualification.inProgress, Validators.required),
			tertiaryQualificationCompletionYear: new FormControl(
				qualification.completionYear,
				qualification.inProgress ? Validators.required : null,
			),
			tertiaryQualificationHighest: new FormControl(qualification.highest, Validators.required),
			tertiaryQualificationUploaded: new FormControl(qualification.uploaded),
		});

		this.addTertiaryQualificationFormGroupSubscriptions(tertiaryQualificationGroup);

		dynamicTertiary.push(tertiaryQualificationGroup);
		this.numberOfTertiaryQualifications += 1;
	}

	private addOtherQualificationFormGroupSubscriptions(otherQualificationGroup: FormGroup): void {
		otherQualificationGroup.controls.otherQualificationInProgress.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value) {
					otherQualificationGroup.controls.otherQualificationYearObtained.setValue(null);
					otherQualificationGroup.controls.otherQualificationYearObtained.clearValidators();
					otherQualificationGroup.controls.otherQualificationCompletionYear.setValidators(Validators.required);
				} else {
					otherQualificationGroup.controls.otherQualificationCompletionYear.clearValidators();
					otherQualificationGroup.controls.otherQualificationCompletionYear.setValue(null);
					otherQualificationGroup.controls.otherQualificationYearObtained.setValidators(Validators.required);
				}

				otherQualificationGroup.controls.otherQualificationCompletionYear.updateValueAndValidity();
				otherQualificationGroup.controls.otherQualificationYearObtained.updateValueAndValidity();
				this.form.updateValueAndValidity();
			});
	}

	private updateFormWithOtherQualification(dynamicOther: FormArray, qualification: Qualification): void {
		const otherQualificationGroup: FormGroup = new FormGroup({
			id: new FormControl(qualification.id),
			otherQualificationName: new FormControl(qualification.name, Validators.required),
			otherQualificationInstitution: new FormControl(qualification.otherInstitution, Validators.required),
			otherQualificationYearObtained: new FormControl(
				qualification.yearObtained,
				qualification.inProgress ? [] : Validators.required,
			),
			otherQualificationInProgress: new FormControl(qualification.inProgress, Validators.required),
			otherQualificationCompletionYear: new FormControl(
				qualification.completionYear,
				qualification.inProgress ? Validators.required : [],
			),
			otherQualificationUploaded: new FormControl(qualification.uploaded),
		});

		this.addOtherQualificationFormGroupSubscriptions(otherQualificationGroup);

		dynamicOther.push(otherQualificationGroup);
		this.numberOfOtherQualifications += 1;
	}

	submit(): void {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');
			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.expectUpdate = true;
		const flinkerEducationalDetailsRequest = this.mapToFlinkerEducationalDetailsRequest();
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerEducationalDetails({
				flinkerEducationalDetailsRequest,
			}),
		);
	}

	mapToFlinkerEducationalDetailsRequest(): FlinkerEducationalDetailsRequest {
		const formValues = this.form.value;
		const tertiaryQualifications = this.form.controls.tertiaryQualifications.value;
		const otherQualifications = this.form.controls.otherQualifications.value;
		const qualifications: Qualification[] = [];

		if (formValues.noMatricCertificate === false) {
			qualifications.push({
				id: formValues.matricCertificateId,
				name: 'Matric NSC',
				category: 'MATRIC',
				institution: formValues.matricCertificateInstitution,
				otherInstitution: formValues.matricCertificateOtherInstitution,
				yearObtained: formValues.matricCertificateYearObtained,
				highest: !formValues.tertiaryQualifications.some(
					(qualification: any) => qualification.tertiaryQualificationHighest,
				),
				inProgress: false,
				type: QualificationType.Matric,
			});
		}

		tertiaryQualifications.forEach((tertiaryQualification: any) => {
			qualifications.push({
				id: tertiaryQualification.id,
				name: tertiaryQualification.tertiaryQualificationName,
				category: tertiaryQualification.tertiaryQualificationCategory,
				institution: tertiaryQualification.tertiaryQualificationInstitution,
				otherInstitution: tertiaryQualification.tertiaryQualificationOtherInstitution,
				yearObtained: tertiaryQualification.tertiaryQualificationYearObtained,
				highest: tertiaryQualification.tertiaryQualificationHighest,
				inProgress: tertiaryQualification.tertiaryQualificationInProgress,
				completionYear: tertiaryQualification.tertiaryQualificationCompletionYear,
				type: QualificationType.Tertiary,
			});
		});

		otherQualifications.forEach((otherQualification: any) => {
			qualifications.push({
				id: otherQualification.id,
				name: otherQualification.otherQualificationName,
				category: 'OTH',
				otherInstitution: otherQualification.otherQualificationInstitution,
				yearObtained: otherQualification.otherQualificationYearObtained,
				completionYear: otherQualification.otherQualificationCompletionYear,
				highest: false,
				inProgress: otherQualification.otherQualificationInProgress,
				type: QualificationType.Other,
			});
		});

		return {
			qualifications,
			skills: (this.skills || []).filter((skill) =>
				this.selectOptions?.skills?.some((skillSelectOption) => skillSelectOption.code === skill),
			),
			otherSkills: (this.skills || []).filter(
				(skill) => !this.selectOptions?.skills?.some((skillSelectOption) => skillSelectOption.code === skill),
			),
			noMatricCertificate: formValues.noMatricCertificate,
			unqualifiedReason: formValues.unqualified
				? formValues.student
					? UnqualifiedReason.Student
					: UnqualifiedReason.NoQualification
				: null,
		};
	}

	uploadQualificationDocuments(flinker: FlinkerResponse): void {
		const formData = new FormData();
		const qualificationIds: string[] = [];
		const qualificationDocuments: File[] = [];

		flinker.qualifications?.forEach((qualification, i) => {
			if (qualification.type === QualificationType.Tertiary && this.highestQualificationDocument) {
				qualificationIds.push(qualification.id);
				qualificationDocuments.push(this.highestQualificationDocument);
			} else if (qualification.type === QualificationType.Matric && this.matricCertificate) {
				qualificationIds.push(qualification.id);
				qualificationDocuments.push(this.matricCertificate);
			} else if (qualification.type === QualificationType.Other && this.otherQualificationDocument) {
				qualificationIds.push(qualification.id);
				qualificationDocuments.push(this.otherQualificationDocument);
			}
		});

		formData.append('qualificationIds', JSON.stringify(qualificationIds));

		qualificationDocuments?.forEach((qualificationDocument) => {
			formData.append('qualificationDocuments', qualificationDocument, qualificationDocument.name);
		});

		if (qualificationIds.length == 0) {
			this.success.emit();
			return;
		}

		this.store.dispatch(new FlinkerTakeOnStoreActions.UploadQualificationDocuments({ formData }));
	}

	addOtherQualification() {
		const dynamicQualifications = this.form.controls.otherQualifications as FormArray;

		this.updateFormWithOtherQualification(dynamicQualifications, {
			id: null,
			category: '',
			name: '',
			institution: '',
			otherInstitution: '',
			type: QualificationType.Other,
			highest: false,
			inProgress: false,
			completionYear: null,
			yearObtained: '',
			uploaded: false,
		});
		this.form.updateValueAndValidity();
	}

	removeOtherQualification() {
		if (this.numberOfOtherQualifications !== 0) {
			const otherQualifications = [
				...this.flinker.qualifications.filter((qualification) => qualification.type === QualificationType.Other),
			];
			otherQualifications.pop();
			this.otherQualificationsFormGroup.pop();

			this.form.patchValue({
				otherQualifications: otherQualifications,
			});

			this.numberOfOtherQualifications -= 1;
		}
		this.form.updateValueAndValidity();
	}

	addTertiaryQualification() {
		const dynamicQualifications = this.form.controls.tertiaryQualifications as FormArray;
		this.updateFormWithTertiaryQualification(dynamicQualifications, {
			id: null,
			category: '',
			name: '',
			institution: '',
			otherInstitution: '',
			type: QualificationType.Tertiary,
			highest: false,
			inProgress: false,
			completionYear: null,
			yearObtained: '',
			uploaded: false,
		});
		this.form.updateValueAndValidity();
	}

	removeTertiaryQualification() {
		if (this.numberOfTertiaryQualifications !== 0) {
			const tertiaryQualifications = [
				...this.flinker.qualifications.filter(
					(qualification: Qualification) => qualification.type === QualificationType.Tertiary,
				),
			];
			tertiaryQualifications.pop();
			this.tertiaryQualificationsFormGroup.pop();

			this.form.patchValue({
				tertiaryQualifications: tertiaryQualifications,
			});

			this.numberOfTertiaryQualifications -= 1;
		}
		this.form.updateValueAndValidity();
	}
}
