import { Injectable } from '@angular/core';
import FlinkerCriminalDeclaration from '@models/dto/flinker-criminal-declaration.dto';
import FlinkerEducationalDetailsRequest from '@models/dto/requests/flinker-educational-details-request.dto';
import FlinkerEmploymentDetailsRequest from '@models/dto/requests/flinker-employment-details-request.dto';
import FlinkerPersonalDetailsRequest from '@models/dto/requests/flinker-personal-details-request.dto';
import FlinkerTermsAndConditionsRequest from '@models/dto/requests/flinker-terms-and-conditions-request.dto';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class FlinkerTakeOnService {
	apiFlinkerEndpoint = 'flinker';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	submitPersonalDetails(flinkerPersonalDetailsRequest: FlinkerPersonalDetailsRequest) {
		return this.httpV2Service.postSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/personalDetails`,
			flinkerPersonalDetailsRequest,
		);
	}

	submitEmploymentDetails(flinkerEmploymentDetailsRequest: FlinkerEmploymentDetailsRequest) {
		return this.httpV2Service.postSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/employmentDetails`,
			flinkerEmploymentDetailsRequest,
		);
	}

	submitEducationalDetails(flinkerEducationalDetailsRequest: FlinkerEducationalDetailsRequest) {
		return this.httpV2Service.postSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/educationalDetails`,
			flinkerEducationalDetailsRequest,
		);
	}

	submitCriminalDeclaration(flinkerCriminalDeclarationRequest: FlinkerCriminalDeclaration) {
		return this.httpV2Service.postSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/criminalDeclaration`,
			flinkerCriminalDeclarationRequest,
		);
	}

	submitFlinkerTermsAndConditions(flinkerTermsAndConditionsRequest: FlinkerTermsAndConditionsRequest) {
		return this.httpV2Service.postSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/termsAndConditions`,
			flinkerTermsAndConditionsRequest,
		);
	}

	uploadIdDocument(formData: FormData) {
		return this.httpV2Service.postFormSecure<FlinkerResponse>(`${this.apiFlinkerEndpoint}/upload/id`, formData);
	}

	uploadPassportDocument(formData: FormData) {
		return this.httpV2Service.postFormSecure<FlinkerResponse>(`${this.apiFlinkerEndpoint}/upload/passport`, formData);
	}

	uploadEmploymentDocuments(formData: FormData) {
		return this.httpV2Service.postFormSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/upload/employmentDocuments`,
			formData,
		);
	}

	uploadQualificationDocuments(formData: FormData) {
		return this.httpV2Service.postFormSecure<FlinkerResponse>(
			`${this.apiFlinkerEndpoint}/upload/qualificationDocuments`,
			formData,
		);
	}

	uploadProfilePicture(formData: FormData) {
		return this.httpV2Service.postFormSecure<string>(`${this.apiFlinkerEndpoint}/upload/profilePicture`, formData, {
			responseType: 'text' as 'json',
		});
	}
}
