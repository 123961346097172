import { createFeatureSelector, createSelector } from '@ngrx/store';
import OfferResponse from '../../models/dto/responses/offer-response.dto';
import HttpPackage from '../../models/http-package';
import { OfferState } from './state';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

const getCreateOfferHttpPackage = (state: OfferState): HttpPackage<OfferResponse> => state.createOfferHttpPackage;

const getFlinkerOffersHttpPackage = (state: OfferState): HttpPackage<OfferResponse[]> =>
	state.getFlinkerOffersHttpPackage;

const getFlinkerOfferHttpPackage = (state: OfferState): HttpPackage<OfferResponse> => state.getFlinkerOfferHttpPackage;

const getGetFlinkCoOffersHttpPackage = (state: OfferState): HttpPackage<PaginatedResponse<OfferResponse>> =>
	state.getFlinkCoOffersHttpPackage;

const getGetFlinkCoOfferHttpPackage = (state: OfferState): HttpPackage<OfferResponse> =>
	state.getFlinkCoOfferHttpPackage;

const getUpdateOfferHttpPackage = (state: OfferState): HttpPackage<OfferResponse> => state.updateOfferHttpPackage;

const getUpdateOfferStatusHttpPackage = (state: OfferState): HttpPackage<OfferResponse> =>
	state.updateOfferStatusHttpPackage;

const getCancelOfferPackage = (state: OfferState): HttpPackage<string> => state.cancelOfferHttpPackage;

const getUploadOfferDocumentsHttpPackage = (state: OfferState): HttpPackage<string> =>
	state.uploadOfferDocumentsHttpPackage;

const getDownloadOfferDocumentHttpPackage = (state: OfferState): HttpPackage<any> =>
	state.downloadOfferDocumentHttpPackage;

export const selectOfferState = createFeatureSelector<OfferState>('offer');

export const selectCreateOfferHttpPackage = createSelector(selectOfferState, getCreateOfferHttpPackage);

export const selectGetFlinkerOffersHttpPackage = createSelector(selectOfferState, getFlinkerOffersHttpPackage);

export const selectGetFlinkerOfferHttpPackage = createSelector(selectOfferState, getFlinkerOfferHttpPackage);

export const selectGetFlinkCoOffersHttpPackage = createSelector(selectOfferState, getGetFlinkCoOffersHttpPackage);

export const selectGetFlinkCoOfferHttpPackage = createSelector(selectOfferState, getGetFlinkCoOfferHttpPackage);

export const selectUpdateOfferHttpPackage = createSelector(selectOfferState, getUpdateOfferHttpPackage);

export const selectUpdateOfferStatusHttpPackage = createSelector(selectOfferState, getUpdateOfferStatusHttpPackage);

export const selectCancelOfferHttpPackage = createSelector(selectOfferState, getCancelOfferPackage);

export const selectUploadOfferDocumentsHttpPackage = createSelector(
	selectOfferState,
	getUploadOfferDocumentsHttpPackage,
);

export const selectDownloadOfferDocumentHttpPackage = createSelector(
	selectOfferState,
	getDownloadOfferDocumentHttpPackage,
);
