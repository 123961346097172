import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdHocSubscriptionResponse } from '@models/dto/responses/adhoc-subscription-response.dto';
import SearchFlinkerResponse from '@models/dto/responses/search-flinker-response.dto';
import SearchResponse from '@models/dto/responses/search-response.dto';
import SearchResultResponse from '@models/dto/responses/search-result-response.dto';
import HttpPackage from '@models/http-package';
import { SearchState } from './state';
import FlinkCoUser from '@models/dto/flink-co-user.dto';
import { isNil } from '@app/utils/functions.util';
import { FilterSearchResponse } from '@models/dto/responses/filter-search-response.dto';

const getCreateSearchHttpPackage = (state: SearchState): HttpPackage<SearchResponse> => state.createSearchHttpPackage;

const getUpdateSearchHttpPackage = (state: SearchState): HttpPackage<SearchResponse> => state.updateSearchHttpPackage;

const getFetchActiveSearchesHttpPackage = (state: SearchState): HttpPackage<SearchResponse[]> =>
	state.fetchActiveSearchesHttpPackage;

const getFetchActiveFilteredSearchesHttpPackage = (state: SearchState): HttpPackage<FilterSearchResponse> =>
	state.fetchActiveFilteredSearchesHttpPackage;

const getFetchActiveSearchHttpPackage = (state: SearchState): HttpPackage<SearchResponse> =>
	state.fetchActiveSearchHttpPackage;

const getGetSearchResultHttpPackage = (state: SearchState): HttpPackage<SearchResultResponse> =>
	state.getSearchResultHttpPackage;

const getGetFlinkerMatchHttpPackage = (state: SearchState): HttpPackage<SearchFlinkerResponse> =>
	state.getFlinkerMatchHttpPackage;

const getPinFlinkerMatchHttpPackage = (state: SearchState): HttpPackage<SearchFlinkerResponse> =>
	state.pinFlinkerMatchHttpPackage;

const getAdhocSubscriptionHttpPackage = (state: SearchState): HttpPackage<AdHocSubscriptionResponse> =>
	state.getAdhocSubscriptionHttpPackage;

const getShortlistFlinkerHttpPackage = (state: SearchState): HttpPackage<SearchFlinkerResponse> =>
	state.getShortlistHttpPackage;

const getInterviewPanelists = (activeSearchPackage: HttpPackage<SearchResponse>): FlinkCoUser[] =>
	!isNil(activeSearchPackage.result) ? activeSearchPackage.result.interviewPanelists : null;

const getThumbsUpHttpPackage = (state: SearchState): HttpPackage<SearchFlinkerResponse> => state.getThumbsUpHttpPackage;

const getThumbsDownHttpPackage = (state: SearchState): HttpPackage<SearchFlinkerResponse> =>
	state.getThumbsDownHttpPackage;

export const selectSearchState = createFeatureSelector<SearchState>('search');

export const selectCreateSearchHttpPackage = createSelector(selectSearchState, getCreateSearchHttpPackage);

export const selectUpdateSearchHttpPackage = createSelector(selectSearchState, getUpdateSearchHttpPackage);

export const selectFetchActiveSearchesHttpPackage = createSelector(
	selectSearchState,
	getFetchActiveSearchesHttpPackage,
);

export const selectFetchActiveSearchHttpPackage = createSelector(selectSearchState, getFetchActiveSearchHttpPackage);

export const selectGetSearchResultHttpPackage = createSelector(selectSearchState, getGetSearchResultHttpPackage);

export const selectGetFlinkerMatchHttpPackage = createSelector(selectSearchState, getGetFlinkerMatchHttpPackage);

export const selectPinFlinkerMatchHttpPackage = createSelector(selectSearchState, getPinFlinkerMatchHttpPackage);

export const selectAdhocSubscriptionHttpPackage = createSelector(selectSearchState, getAdhocSubscriptionHttpPackage);

export const selectShortlistFlankerHttpPackage = createSelector(selectSearchState, getShortlistFlinkerHttpPackage);

export const selectInterviewPanelistsFromSearch = createSelector(
	selectFetchActiveSearchHttpPackage,
	getInterviewPanelists,
);

export const selectThumbsUpHttpPackage = createSelector(selectSearchState, getThumbsUpHttpPackage);
export const selectThumbsDownHttpPackage = createSelector(selectSearchState, getThumbsDownHttpPackage);

export const selectFetchActiveFilteredSearchHttpPackage = createSelector(
	selectSearchState,
	getFetchActiveFilteredSearchesHttpPackage,
);
