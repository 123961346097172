import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CvAdminStoreActions } from '.';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { GetCvFeatures } from './actions';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';
import { CvAdminService } from '@app/services/cv-admin/cv-admin.service';

@Injectable()
export class CvAdminStoreEffects {
	constructor(private actions$: Actions, private readonly cvAdminService: CvAdminService) {}

	getCvFeaturesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvAdminStoreActions.GetCvFeatures>(CvAdminStoreActions.ActionTypes.GET_CV_FEATURES),
			switchMap((action: GetCvFeatures) =>
				this.cvAdminService.getCvFeatures().pipe(
					map((response: CvFeatureResponseDto[]) => {
						return new CvAdminStoreActions.GetCvFeaturesSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to get CV features.', error);
						return of(new CvAdminStoreActions.GetCvFeaturesError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	updateCvFeaturePriceEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvAdminStoreActions.UpdateCvFeaturePrice>(CvAdminStoreActions.ActionTypes.UPDATE_CV_FEATURE_PRICE),
			switchMap((action: CvAdminStoreActions.UpdateCvFeaturePrice) =>
				this.cvAdminService.updateCvFeaturePrice(action.payload.cvFeatureId, action.payload).pipe(
					map((response: CvFeatureResponseDto) => {
						return new CvAdminStoreActions.UpdateCvFeaturePriceSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to update CV feature price.', error);
						return of(new CvAdminStoreActions.UpdateCvFeaturePriceError({ errorResponse: error }));
					}),
				),
			),
		);
	});
}
