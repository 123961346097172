import { Component, Input, OnInit } from '@angular/core';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { JobAdvertsReferralComponent } from '@app/job-adverts/job-adverts-referral/job-adverts-referral.component';
import { MatDialog } from '@angular/material/dialog';
import { FlinkerJobsAction, FlinkerJobsSelectors } from '@root-store/flinker/jobs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { Router } from '@angular/router';
import { isNil } from '@app/utils/functions.util';

@Component({
	selector: 'app-job-advert-card',
	templateUrl: './job-advert-card.component.html',
	styleUrls: ['./job-advert-card.component.scss'],
})
export class JobAdvertCardComponent implements OnInit {
	private readonly destroy$ = new Subject<void>();
	@Input() jobAdvert: JobAdvertResponseDto;
	@Input() flinkCoId: string;
	@Input() isPublic = false;

	selectOptions: SelectOptionsResponse;
	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage).pipe(
		takeUntil(this.destroy$),
		filter((response) => !response.loading && response.result !== null),
	);
	getFavouriteJobAdvertHttpPackage$ = this.store.select(FlinkerJobsSelectors.selectFavouriteJobAdvert).pipe(
		takeUntil(this.destroy$),
		filter((response) => !response.loading && response.result !== null),
	);

	constructor(
		private store: Store<RootStoreState.State>,
		public dialog: MatDialog,
		private readonly notifierService: NotifierService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
			this.selectOptions = selectOptionsResponse.result;
		});
	}

	calculateTimeSincePublished(datePublished: Date) {
		const currentDateTime = moment(new Date());
		const publishedDate = moment(datePublished);

		if (currentDateTime.diff(publishedDate, 'days') > 10 && currentDateTime.diff(publishedDate, 'weeks') <= 4) {
			const number = currentDateTime.diff(publishedDate, 'weeks');
			return number === 1 ? `${number} Week Ago` : `${number} Weeks Ago`;
		} else if (
			currentDateTime.diff(publishedDate, 'weeks') > 4 &&
			currentDateTime.diff(publishedDate, 'months') <= 12
		) {
			const number = currentDateTime.diff(publishedDate, 'months');
			return number === 1 ? `${number} Month Ago` : `${number} Months Ago`;
		} else if (currentDateTime.diff(publishedDate, 'months') > 12) {
			const number = currentDateTime.diff(publishedDate, 'years');
			return number === 1 ? `${number} Year Ago` : `${number} Years Ago`;
		}

		const number = currentDateTime.diff(publishedDate, 'days');
		return number === 1 ? `${number} Day Ago` : `${number} Days Ago`;
	}

	openReferralDialog() {
		const dialogRef = this.dialog.open(JobAdvertsReferralComponent, {
			maxWidth: '1000px',
			maxHeight: window.innerHeight + 'px',
			data: {
				jobAdvertId: this.jobAdvert.id,
				referredByAuthedUser: isNil(this.flinkCoId),
			},
			id: 'job-referral-dialog',
		});
	}

	favouriteJobAdvert() {
		this.store.dispatch(new FlinkerJobsAction.FavouriteJobAdvert({ jobAdvertId: this.jobAdvert.id }));
		this.getFavouriteJobAdvertHttpPackage$.pipe(take(1)).subscribe((response) => {
			if (response.result.favourite) {
				this.notifierService.notify(NotificationType.Success, 'Job advert added to favourites!');
			} else {
				this.notifierService.notify(NotificationType.Success, 'Job advert removed from favourites.');
			}
		});
	}

	viewDetailedJobAdvert() {
		if (!isNil(this.flinkCoId)) {
			this.router.navigate([`careers/${this.flinkCoId}/job-advert/${this.jobAdvert.id}`]);
		} else {
			this.router.navigate([`flinker-dashboard/job-advert/${this.jobAdvert.id}`]);
		}
	}
}
