import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-outlined-text-area',
	templateUrl: './outlined-text-area.component.html',
	styleUrls: ['./outlined-text-area.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedTextAreaComponent),
			multi: true,
		},
	],
})
export class OutlinedTextAreaComponent implements OnInit, ControlValueAccessor {
	@Input() placeholder = '';
	@Input() bottomText = '';
	@Input() errorMessage = '';
	@Input() labelText = '';
	@Input() disabled = false;
	@Input() formControlName = '';
	@Input() maxLength = '';
	@Input() minRows = '1';
	@Input() maxRows = '10';

	@Output() textAreaClicked: EventEmitter<Event> = new EventEmitter<Event>();

	required = false;
	localType = '';
	value = '';
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	onTextAreaClicked(event: Event): void {
		this.textAreaClicked.emit(event);
	}
}
