import { HttpV2Service } from '@app/services/http/http-v2.service';
import { Injectable } from '@angular/core';
import { VideoUrlResponse } from '@models/dto/responses/video-url-response.dto';

@Injectable({
	providedIn: 'root',
})
export class VideoService {
	videoApiEndpoint = 'videos';
	constructor(private readonly httpV2Client: HttpV2Service) {}

	createVideoRoom(videoQuestion: string) {
		return this.httpV2Client.postSecure<string>(`${this.videoApiEndpoint}/create-recording`, { videoQuestion });
	}

	getVideoComposition(participantSID: string, roomSID: string) {
		return this.httpV2Client.getSecure<string>(`${this.videoApiEndpoint}/${participantSID}/${roomSID}/composition`);
	}

	deletVideoRecoding(roomSID: string) {
		return this.httpV2Client.deleteSecure(`${this.videoApiEndpoint}/${roomSID}/delete-recording`);
	}

	deleteCompositionRecording(roomSID: string, compositionSID: string) {
		return this.httpV2Client.deleteSecure(
			`${this.videoApiEndpoint}/${roomSID}/${compositionSID}/composition-recording`,
		);
	}

	getVideoRecording(compositionSIDs: string[]) {
		return this.httpV2Client.postSecure<VideoUrlResponse[]>(`${this.videoApiEndpoint}/recording`, { compositionSIDs });
	}
}
