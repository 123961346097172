import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-disclosure-concent-form',
	templateUrl: './disclosure-concent-form.component.html',
	styleUrls: ['./disclosure-concent-form.component.scss'],
})
export class DisclosureConcentFormComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
