import { Actions, ActionTypes } from './actions';
import { FlinkCoJobAdvertState, initialState } from './state';

export function FlinkCoJobAdvertsReducer(state = initialState, action: Actions): FlinkCoJobAdvertState {
	switch (action.type) {
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERTS:
			return {
				...state,
				fetchFlinkCoJobAdvertsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERTS_SUCCESS:
			return {
				...state,
				fetchFlinkCoJobAdvertsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERTS_ERROR:
			return {
				...state,
				fetchFlinkCoJobAdvertsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERT:
			return {
				...state,
				fetchFlinkCoJobAdvertHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERT_SUCCESS:
			return {
				...state,
				fetchFlinkCoJobAdvertHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADVERT_ERROR:
			return {
				...state,
				fetchFlinkCoJobAdvertHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT:
			return {
				...state,
				fetchPublicFlinkCoJobAdvertHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT_SUCCESS:
			return {
				...state,
				fetchPublicFlinkCoJobAdvertHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT_ERROR:
			return {
				...state,
				fetchPublicFlinkCoJobAdvertHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CREATE_FLINKCO_JOB_ADVERT:
			return {
				...state,
				createJobAdvertHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_FLINKCO_JOB_ADVERT_SUCCESS:
			return {
				...state,
				createJobAdvertHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_FLINKCO_JOB_ADVERT_ERROR:
			return {
				...state,
				createJobAdvertHttpPackage: {
					error: action.payload.errorResponse,
					loading: null,
					result: null,
				},
			};
		case ActionTypes.UPDATE_FLINKCO_JOB_ADVERT:
			return {
				...state,
				updateJobAdvertHttpPackage: {
					result: null,
					loading: true,
					error: null,
				},
			};

		case ActionTypes.UPDATE_FLINKCO_JOB_ADVERT_SUCCESS:
			return {
				...state,
				updateJobAdvertHttpPackage: {
					result: action.payload.result,
					loading: false,
					error: null,
				},
			};

		case ActionTypes.UPDATE_FLINKCO_JOB_ADVERT_ERROR:
			return {
				...state,
				updateJobAdvertHttpPackage: {
					result: null,
					loading: false,
					error: action.payload.errorResponse,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_ADPPLICATIONS:
			return {
				...state,
				fetchFlinkCoJobApplicationHttpPackage: {
					result: null,
					loading: true,
					error: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_APPLICATIONS_SUCCESS:
			return {
				...state,
				fetchFlinkCoJobApplicationHttpPackage: {
					result: action.payload.result,
					loading: false,
					error: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_JOB_APPLICATIONS_ERROR:
			return {
				...state,
				fetchFlinkCoJobApplicationHttpPackage: {
					result: null,
					loading: false,
					error: action.payload.errorResponse,
				},
			};
		case ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS:
			return {
				...state,
				fetchJobApplicationQuestionAnswersHttpPackage: {
					result: null,
					loading: true,
					error: null,
				},
			};

		case ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS_SUCCESS:
			return {
				...state,
				fetchJobApplicationQuestionAnswersHttpPackage: {
					result: action.payload.result,
					loading: false,
					error: null,
				},
			};
		case ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS_ERROR:
			return {
				...state,
				fetchJobApplicationQuestionAnswersHttpPackage: {
					result: null,
					loading: false,
					error: action.payload.errorResponse,
				},
			};
		case ActionTypes.UPDATE_JOB_APPLICATION_FOR_SCHEDULED_INTERVIEW:
			return {
				...state,
				fetchFlinkCoJobApplicationHttpPackage: {
					...state.fetchFlinkCoJobApplicationHttpPackage,
					result: state.fetchFlinkCoJobApplicationHttpPackage?.result
						? [
								...state.fetchFlinkCoJobApplicationHttpPackage.result.slice(
									0,
									state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
										(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
									),
								),
								{
									...state.fetchFlinkCoJobApplicationHttpPackage.result[
										state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
											(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
										)
									],
									interviewScheduled: true,
								},
								...state.fetchFlinkCoJobApplicationHttpPackage.result.slice(
									state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
										(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
									) + 1,
								),
						  ]
						: null,
				},
			};

		case ActionTypes.UPDATE_JOB_APPLICATION_FOR_OFFER_MADE:
			return {
				...state,
				fetchFlinkCoJobApplicationHttpPackage: {
					...state.fetchFlinkCoJobApplicationHttpPackage,
					result: state.fetchFlinkCoJobApplicationHttpPackage?.result
						? [
								...state.fetchFlinkCoJobApplicationHttpPackage.result.slice(
									0,
									state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
										(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
									),
								),
								{
									...state.fetchFlinkCoJobApplicationHttpPackage.result[
										state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
											(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
										)
									],
									offerMade: true,
								},
								...state.fetchFlinkCoJobApplicationHttpPackage.result.slice(
									state.fetchFlinkCoJobApplicationHttpPackage.result.findIndex(
										(jobApplication) => jobApplication.flinker.id === action.payload.flinkerId,
									) + 1,
								),
						  ]
						: null,
				},
			};
		case ActionTypes.CREATE_JOB_ADVERT_REFERRAL:
			return {
				...state,
				referJobAdvertHttpPackage: {
					result: null,
					loading: true,
					error: null,
				},
			};
		case ActionTypes.CREATE_JOB_ADVERT_REFERRAL_SUCCESS:
			return {
				...state,
				referJobAdvertHttpPackage: {
					result: action.payload.result,
					loading: false,
					error: null,
				},
			};
		case ActionTypes.CREATE_JOB_ADVERT_REFERRAL_ERROR:
			return {
				...state,
				referJobAdvertHttpPackage: {
					result: null,
					loading: false,
					error: action.payload.errorResponse,
				},
			};
		case ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS:
			return {
				...state,
				fetchCareersPageJobAdvert: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS_SUCCESS:
			return {
				...state,
				fetchCareersPageJobAdvert: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS_ERROR:
			return {
				...state,
				fetchCareersPageJobAdvert: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_IFRAME_LINK:
			return {
				...state,
				fetchFlinkCoIframeLink: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_IFRAME_LINK_SUCCESS:
			return {
				...state,
				fetchFlinkCoIframeLink: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKCO_IFRAME_LINK_ERROR:
			return {
				...state,
				fetchFlinkCoIframeLink: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
