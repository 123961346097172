import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-form-submit-buttons',
	templateUrl: './form-submit-buttons.component.html',
	styleUrls: ['./form-submit-buttons.component.scss'],
})
export class FormSubmitButtonsComponent implements OnInit {
	@Input() disabled = false;
	@Input() submitText = 'Create';

	@Output() cancel = new EventEmitter();
	@Output() submit = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
}
