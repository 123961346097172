<mat-form-field class="outlined-dropdown-input">
	<div class="d-flex">
		<label [ngClass]="{ required: required }">{{ labelText }}</label>
		<app-tooltip class="tooltip-in-label" *ngIf="showTip && labelText" [tooltip]="toolTip"></app-tooltip>
	</div>
	<div class="select-container">
		<mat-select
			class="ghost-input relative"
			[disabled]="disabled"
			(selectionChange)="input($event)"
			(blur)="onBlur()"
			[value]="value"
		>
			<mat-option *ngFor="let selectOption of options" [value]="selectOption.code">
				{{ selectOption.name }}
			</mat-option>
		</mat-select>
		<mat-icon class="clear front-sub-9" *ngIf="value" (click)="clearValue()">cancel</mat-icon>
	</div>
	<label *ngIf="bottomText" class="pt-2 front-sub-8">{{ bottomText }}</label>
	<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
