import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FlinkcoDashboardStoreActions } from '.';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { FlinkCoService } from '../../services/flinkCo/flink-co.service';

@Injectable()
export class FlinkcoDashboardStoreEffects {
	constructor(private actions$: Actions, private flinkcoService: FlinkCoService) {}

	cancelRetainerSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkcoDashboardStoreActions.CancelRetainerSubscription>(
				FlinkcoDashboardStoreActions.ActionTypes.CANCEL_RETAINER_SUBSCRIPTION,
			),
			switchMap(() =>
				this.flinkcoService.cancelRetainerSubscription().pipe(
					map((_) => new FlinkcoDashboardStoreActions.CancelRetainerSubscriptionSuccess({ result: 'Success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkcoDashboardStoreActions.CancelRetainerSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	upgradeAdhocSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkcoDashboardStoreActions.RequestAdhocSubscriptionUpgrade>(
				FlinkcoDashboardStoreActions.ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE,
			),
			switchMap(() =>
				this.flinkcoService.upgradeAdhocSubscription().pipe(
					map((_) => new FlinkcoDashboardStoreActions.RequestAdhocSubscriptionUpgradeSuccess({ result: 'Success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkcoDashboardStoreActions.CancelRetainerSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	requestDemoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkcoDashboardStoreActions.RequestDemo>(FlinkcoDashboardStoreActions.ActionTypes.REQUEST_DEMO),
			switchMap(() =>
				this.flinkcoService.requestDemo().pipe(
					map((_) => new FlinkcoDashboardStoreActions.RequestDemoSuccess({ result: 'Success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkcoDashboardStoreActions.RequestDemoError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
