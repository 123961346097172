import { createFeatureSelector, createSelector } from '@ngrx/store';
import HttpPackage from '../../models/http-package';
import { FlinkcoDashboardState } from './state';

const getCancelRetainerSubscriptionHttpPackage = (state: FlinkcoDashboardState): HttpPackage<string> =>
	state.cancelRetainerSubscriptionHttpPackage;

const getUpgradeAdhocSubscriptionHttpPackage = (state: FlinkcoDashboardState): HttpPackage<string> =>
	state.upgradeAdhocSubscriptionHttpPackage;

const requestDemoHttpPackage = (state: FlinkcoDashboardState): HttpPackage<string> => state.requestDemoHttpPackage;

export const selectFlinkcoDashboard = createFeatureSelector<FlinkcoDashboardState>('flinkcoDashboard');

export const selectCancelRetainerSubscriptionHttpPackage = createSelector(
	selectFlinkcoDashboard,
	getCancelRetainerSubscriptionHttpPackage,
);

export const selectUpgradeAdhocSubscriptionHttpPackage = createSelector(
	selectFlinkcoDashboard,
	getUpgradeAdhocSubscriptionHttpPackage,
);

export const selectRequestDemoHttpPackage = createSelector(selectFlinkcoDashboard, requestDemoHttpPackage);
