import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { OfferStatus } from 'projects/flink-app/src/app/enums/offer-status.enum';
import {
	getSelectOptionIdByCode,
	SelectOptionsResponse,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { OfferStoreActions, OfferStoreSelectors } from 'projects/flink-app/src/app/root-store/offer-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { Subscription } from 'rxjs';
import { entityConfig } from '@models/constants';

@Component({
	selector: 'app-reject-offer-dialog',
	templateUrl: './reject-offer-dialog.component.html',
	styleUrls: ['./reject-offer-dialog.component.scss'],
})
export class RejectOfferDialogComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	updateOfferStatusHttpPackage$ = this.store.select(OfferStoreSelectors.selectUpdateOfferStatusHttpPackage);
	selectOptions: SelectOptionsResponse;
	shouldValidate = false;

	constants = entityConfig;

	constructor(
		public dialogRef: MatDialogRef<RejectOfferDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
	) {
		this.form = this.formBuilder.group({
			rejectionReason: ['', [Validators.required]],
			otherRejectionReason: [null, [Validators.maxLength(entityConfig.interview.maxOtherRejectionReasonLength)]],
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.form.controls.rejectionReason.valueChanges.subscribe((value) => {
				if (value == 'OTH') {
					this.form.controls.otherRejectionReason.setValidators(Validators.required);
				} else {
					this.form.controls.otherRejectionReason.setValidators(null);
					this.form.controls.otherRejectionReason.setValue(null);
				}

				this.form.controls.otherRejectionReason.updateValueAndValidity();
			}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result !== null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);

		this.subscriptions.add(
			this.updateOfferStatusHttpPackage$.subscribe((updateOfferStatusHttpPackage) => {
				if (updateOfferStatusHttpPackage.result !== null) {
					this.notifierService.notify('success', 'Offer rejected successfully');
					this.dialogRef.close();
				}
			}),
		);
	}

	cancel(): void {
		this.dialogRef.close();
	}

	submit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}

		const formValues = this.form.value;

		this.store.dispatch(
			new OfferStoreActions.UpdateOfferStatus({
				offerStatusRequest: {
					offerId: this.data.offerId,
					offerStatus: OfferStatus.Rejected,
					rejectionReasonId: getSelectOptionIdByCode(
						this.selectOptions.offerRejectionReasons,
						formValues.rejectionReason,
					),
					otherRejectionReason: formValues.otherRejectionReason,
				},
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
