import { SelectOptionResponse } from '../../models/dto/responses/select-option-response.dto';
import {
	AdminSelectOptionsResponse,
	SelectOptionsResponse,
} from '../../models/dto/responses/select-options-response.dto';
import { Actions, ActionTypes } from './actions';
import { initialState, SelectOptionsState } from './state';

export function selectOptionsReducer(state = initialState, action: Actions): SelectOptionsState {
	switch (action.type) {
		case ActionTypes.FETCH_SELECT_OPTIONS:
			return {
				...state,
				selectOptionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_SELECT_OPTIONS_SUCCESS:
			return {
				...state,
				selectOptionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_SELECT_OPTIONS_ERROR:
			return {
				...state,
				selectOptionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ADMIN_SELECT_OPTIONS:
			return {
				...state,
				adminSelectOptionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ADMIN_SELECT_OPTIONS_SUCCESS:
			return {
				...state,
				adminSelectOptionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ADMIN_SELECT_OPTIONS_ERROR:
			return {
				...state,
				adminSelectOptionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_SELECT_OPTION:
			return {
				...state,
				adminSelectOptionsHttpPackage: {
					error: null,
					loading: false,
					result: null,
				},
				updateSelectOptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_SELECT_OPTION_SUCCESS:
			return {
				...state,
				updateSelectOptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPDATE_SELECT_OPTION_ERROR:
			return {
				...state,
				updateSelectOptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CREATE_SELECT_OPTION:
			return {
				...state,
				adminSelectOptionsHttpPackage: {
					error: null,
					loading: false,
					result: null,
				},
				createSelectOptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_SELECT_OPTION_SUCCESS:
			return {
				...state,
				createSelectOptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_SELECT_OPTION_ERROR:
			return {
				...state,
				createSelectOptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
