import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-rounded-input',
	templateUrl: './rounded-input.component.html',
	styleUrls: ['./rounded-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RoundedInputComponent),
			multi: true,
		},
	],
})
export class RoundedInputComponent implements OnInit, ControlValueAccessor {
	@Input() theme = '';
	@Input() placeholder = '';
	@Input() icon = '';
	@Input() type = 'password';
	@Input() errorMessage = '';

	localType = '';
	value = '';
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	constructor() {}

	ngOnInit(): void {
		this.localType = this.type;
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	togglePasswordVisibility(): void {
		this.localType = this.localType === this.type ? 'text' : this.type;
	}
}
