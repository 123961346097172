<mat-form-field>
	<label class="d-flex" [ngClass]="{ required: required }"
		>{{ labelText
		}}<app-tooltip
			class="tooltip-in-label align-items-baseline"
			*ngIf="showTip && labelText"
			[tooltip]="toolTip"
		></app-tooltip
	></label>
	<mat-chip-list #chipList aria-label="Skill selection" [ngClass]="theme">
		<mat-chip
			*ngFor="let chipOption of values"
			[selectable]="selectable"
			[removable]="removable"
			(removed)="remove(chipOption)"
		>
			{{ chipOption }}
			<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
		</mat-chip>
		<input
			#chipInput
      [maxlength]="maxOtherLength"
			[formControl]="chipControl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(keyup.enter)="onManualInput($event)"
		/>
	</mat-chip-list>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
		<mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
			{{ chip }}
		</mat-option>
	</mat-autocomplete>
	<app-input-error-message class="autocomplete-error" *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage">
	</app-input-error-message>
	<mat-hint *ngIf="hint.length > 0">{{ hint }}</mat-hint>
</mat-form-field>
