import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SliderComponent),
			multi: true,
		},
	],
})
export class SliderComponent implements OnInit, ControlValueAccessor {
	@Input() sliderValue = 0;
	@Input() labelText: string;
	@Input() showInput = false;
	@Input() floor = 0;
	@Input() ceil = 50;

	options: Options;
	value = '';
	localType = '';
	disabled = false;
	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {
		this.options = {
			floor: this.floor,
			ceil: this.ceil,
			step: 1,
			showSelectionBar: true,
		};
	}

	public writeValue(value: string): void {
		this.sliderValue = Number(value);
	}

	valueChange(): void {
		this.onChange(this.sliderValue);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	updateSlider(e: any) {
		this.sliderValue = e;
	}
}
