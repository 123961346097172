import { FlinkCoFlinkerDocumentsState } from '@root-store/flinkco/flinker-documents/state';
import { Actions, ActionTypes } from './actions';
import { initialState } from './state';
export function FlinkCoFlinkerDocumentsReducer(state = initialState, action: Actions): FlinkCoFlinkerDocumentsState {
	switch (action.type) {
		case ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT:
			return {
				...state,
				downloadFlinkerIdDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS:
			return {
				...state,
				downloadFlinkerIdDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR:
			return {
				...state,
				downloadFlinkerIdDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT:
			return {
				...state,
				downloadFlinkerMatricDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS:
			return {
				...state,
				downloadFlinkerMatricDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR:
			return {
				...state,
				downloadFlinkerMatricDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT:
			return {
				...state,
				downloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS:
			return {
				...state,
				downloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR:
			return {
				...state,
				downloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT:
			return {
				...state,
				downloadFlinkerPassportDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS:
			return {
				...state,
				downloadFlinkerPassportDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR:
			return {
				...state,
				downloadFlinkerPassportDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT:
			return {
				...state,
				canDownloadFlinkerIdDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS:
			return {
				...state,
				canDownloadFlinkerIdDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR:
			return {
				...state,
				canDownloadFlinkerIdDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT:
			return {
				...state,
				canDownloadFlinkerMatricDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS:
			return {
				...state,
				canDownloadFlinkerMatricDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR:
			return {
				...state,
				canDownloadFlinkerMatricDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT:
			return {
				...state,
				canDownloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS:
			return {
				...state,
				canDownloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR:
			return {
				...state,
				canDownloadFlinkerHighestQualificationDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT:
			return {
				...state,
				canDownloadFlinkerPassportDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS:
			return {
				...state,
				canDownloadFlinkerPassportDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload,
				},
			};
		case ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR:
			return {
				...state,
				canDownloadFlinkerPassportDocumentHttpPackage: {
					error: action.payload.error,
					loading: false,
					result: null,
				},
			};

		default:
			return state;
	}
}
