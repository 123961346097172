<div class='p-1 p-md-4'>
  <app-dashboard-section-header icon='book' title='Skills'></app-dashboard-section-header>
  <form [formGroup]='form'>
    <div class='row'>
      <app-chips-input
        [allowOther]='true'
        [maxOtherLength]='maxOtherSkills'
        formControlName='skills'
        class='col-12'
        [showTip]='true'
        [toolTip]="
					'Any skill or knowledge of program you have acquired throughout the course of your career \n Examples: Microsoft Excel, Microsoft Word, Sage, Power BI'
				"
        *ngIf='skills && (selectOptionsHttpPackage$ | async)?.result?.skills'
        labelText='Please select your skills'
        [options]='(selectOptionsHttpPackage$ | async)?.result?.skills'
        (chipsChange)='skills = $event'
        [prefill]='skills'
        [hint]="'You can add other skills by typing them and pressing Enter.'"
      >
      </app-chips-input>
    </div>
    <div class='row my-4'>
      <app-square-button
        theme='m1'
        value='Update Skills'
        type='button'
        class='d-flex justify-content-center col-6 offset-3'
        [disabled]='(submitEducationalDetailsHttpPackage$ | async)?.loading'
        [loading]='(submitEducationalDetailsHttpPackage$ | async)?.loading'
        (click)='submit()'
      >
      </app-square-button>
    </div>
  </form>
</div>
