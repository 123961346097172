import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-sign-in-button-group',
	templateUrl: './sign-in-button-group.component.html',
	styleUrls: ['./sign-in-button-group.component.scss'],
})
export class SignInButtonGroupComponent implements OnInit {
	@Input() submitValue = '';
	@Input() disabled = false;
	@Input() submitDisabled = false;
	@Input() isSignUp = false;
	@Input() theme = '';

	@Output() onSubmit: EventEmitter<any> = new EventEmitter();
	@Output() onGoogleSignIn: EventEmitter<any> = new EventEmitter();
	@Output() onMicrosoftSignIn: EventEmitter<any> = new EventEmitter();

	buttonActionSignInText = 'Sign in with ';
	buttonActionSignUpText = 'Sign up with ';
	constructor() {}

	ngOnInit(): void {}

	onGoogleClicked() {
		this.onGoogleSignIn.emit();
	}

	onMicrosoftClicked() {
		this.onMicrosoftSignIn.emit();
	}

	onSubmitClicked() {
		this.onSubmit.emit();
	}
}
