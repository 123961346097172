import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-input-error-message',
	templateUrl: './input-error-message.component.html',
	styleUrls: ['./input-error-message.component.scss'],
})
export class InputErrorMessageComponent implements OnInit {
	@Input() errorMessage = '';

	constructor() {}

	ngOnInit(): void {}
}
