import { createFeatureSelector, createSelector } from '@ngrx/store';
import ReferenceQuestion from '../../models/dto/responses/reference-question.dto';
import HttpPackage from '../../models/http-package';
import { ReferenceQuestionsState } from './state';

export const selectReferenceQuestionState = createFeatureSelector<ReferenceQuestionsState>('reference-questions');

const fetchAllReferenceQuestionsHttpPackage = (state: ReferenceQuestionsState): HttpPackage<ReferenceQuestion[]> =>
	state.fetchAllReferenceQuestionsHttpPackage;

const getCreateReferenceQuestionsHttpPackage = (state: ReferenceQuestionsState): HttpPackage<ReferenceQuestion> =>
	state.createReferenceQuestionHttpPackage;
const getUpdateReferenceQuestionsHttpPackage = (state: ReferenceQuestionsState): HttpPackage<ReferenceQuestion> =>
	state.updateReferenceQuestionHttpPackage;
const getDeleteReferenceQuestionsHttpPackage = (state: ReferenceQuestionsState): HttpPackage<string> =>
	state.deleteReferenceQuestionHttpPackage;

export const selectFetchAllReferenceQuestionsHttpPackage = createSelector(
	selectReferenceQuestionState,
	fetchAllReferenceQuestionsHttpPackage,
);

export const selectCreateReferenceQuestionHttpPackage = createSelector(
	selectReferenceQuestionState,
	getCreateReferenceQuestionsHttpPackage,
);

export const selectUpdateReferenceQuestionHttpPackage = createSelector(
	selectReferenceQuestionState,
	getUpdateReferenceQuestionsHttpPackage,
);

export const selectDeleteReferenceQuestionHttpPackage = createSelector(
	selectReferenceQuestionState,
	getDeleteReferenceQuestionsHttpPackage,
);
