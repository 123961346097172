<div class="py-4 px-0 details-underline container" *ngFor="let reference of flinker?.references; let i = index">
	<div class="row">
		<div class="col-11 reference-title-box">
			<mat-icon class="front-main-1 font-size-11 center-icon pr-2">person</mat-icon>
			<span class="d-block font-normal font-size-10">
				{{ reference?.name }}
			</span>
			<app-highlighted-text theme="faded2 font-size-6" class="ml-2">{{
        reference?.referenceRelationshipId | selectOptionNameById:selectOptions?.referenceRelationships
			}}</app-highlighted-text>
      <app-highlighted-text class="ml-2" theme="faded2">
        {{ (referenceScoreMap[reference?.id] ? referenceScoreMap[reference?.id] : 0) | number: '.1' }}
      </app-highlighted-text>
		</div>

    <ng-template #referenceScoreComplete>
      <div class="m1 col-1">
        <mat-icon>done</mat-icon>
      </div>
    </ng-template>

    <button *ngIf="!referenceScoreExistsMap[reference?.id] else referenceScoreComplete" mat-icon-button class="m1 col-1" (click)="openRefDialog(i)">
      <mat-icon>edit</mat-icon>
    </button>

    <div class="col-12 d-flex">
			<span class="front-sub-5 font-size-11 font-medium center-icon">
				{{ reference?.contactNumber }}
			</span>
		</div>
		<div class="col-12 pt-2 d-flex">
			<span class="font-light font-size-14">{{ reference?.emailAddress }}</span>
		</div>
	</div>
</div>
