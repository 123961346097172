<div mat-dialog-content>
  <h2 mat-dialog-title class="text-center text-danger font-size-8">WARNING</h2>
  <p class="font-size-10 font-size-10-md">
    {{ context }}:
    <strong>{{ data?.name }}</strong>
  </p>
  <p class="font-size-10 font-size-10-md">
    Please type <strong>'yes'</strong> into the box below to proceed
  </p>
  <form class="container mt-4" [formGroup]="form">
    <div class="row">
      <app-outlined-input
        class="w-100"
        labelText="Confirm deletion:"
        formControlName="confirm"
      >
      </app-outlined-input>
    </div>
  </form>
</div>

<div mat-dialog-actions class="justify-content-center pb-3 row d-flex flex-column-reverse flex-md-row">
  <app-square-button theme="m5 fs1" class="col-12 col-md-6 mt-4" value="Cancel" (click)="closeDialog()">
  </app-square-button>
  <app-square-button theme="m1 fs1" class="col-12 col-md-6 mt-4 disabled" value="Confirm" [disabled]="isDisabled" (click)="submit(data)">
  </app-square-button>
</div>

