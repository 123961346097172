import { Action } from '@ngrx/store';
import SelectOptionRequest from '../../flinkadmin-dashboard/models/requests/select-option-request.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { AdminSelectOptionsResponse, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';

export enum ActionTypes {
	FETCH_SELECT_OPTIONS = '[SelectOptions] Fetch select options',
	FETCH_SELECT_OPTIONS_SUCCESS = '[SelectOptions] Fetch select options success',
	FETCH_SELECT_OPTIONS_ERROR = '[SelectOptions] Fetch select options error',

	FETCH_ADMIN_SELECT_OPTIONS = '[SelectOptions] Fetch admin select options',
	FETCH_ADMIN_SELECT_OPTIONS_SUCCESS = '[SelectOptions] Fetch admin select options success',
	FETCH_ADMIN_SELECT_OPTIONS_ERROR = '[SelectOptions] Fetch admin select options error',

	UPDATE_SELECT_OPTION = '[SelectOptions] Update select option',
	UPDATE_SELECT_OPTION_SUCCESS = '[SelectOptions] Update select option success',
	UPDATE_SELECT_OPTION_ERROR = '[SelectOptions] Update select option error',

	CREATE_SELECT_OPTION = '[SelectOptions] Create select option',
	CREATE_SELECT_OPTION_SUCCESS = '[SelectOptions] Create select option success',
	CREATE_SELECT_OPTION_ERROR = '[SelectOptions] Create select option error',
}

export class FetchSelectOptions implements Action {
	readonly type = ActionTypes.FETCH_SELECT_OPTIONS;
	constructor() {}
}

export class FetchSelectOptionsSuccess implements Action {
	readonly type = ActionTypes.FETCH_SELECT_OPTIONS_SUCCESS;
	constructor(public payload: { result: SelectOptionsResponse }) {}
}

export class FetchSelectOptionsError implements Action {
	readonly type = ActionTypes.FETCH_SELECT_OPTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchAdminSelectOptions implements Action {
	readonly type = ActionTypes.FETCH_ADMIN_SELECT_OPTIONS;
	constructor() {}
}

export class FetchAdminSelectOptionsSuccess implements Action {
	readonly type = ActionTypes.FETCH_ADMIN_SELECT_OPTIONS_SUCCESS;
	constructor(public payload: { result: AdminSelectOptionsResponse[] }) {}
}

export class FetchAdminSelectOptionsError implements Action {
	readonly type = ActionTypes.FETCH_ADMIN_SELECT_OPTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateSelectOption implements Action {
	readonly type = ActionTypes.UPDATE_SELECT_OPTION;
	constructor(public payload: { selectOption: SelectOptionRequest }) {}
}

export class UpdateSelectOptionSuccess implements Action {
	readonly type = ActionTypes.UPDATE_SELECT_OPTION_SUCCESS;
	constructor(public payload: { result: SelectOptionResponse }) {}
}

export class UpdateSelectOptionError implements Action {
	readonly type = ActionTypes.UPDATE_SELECT_OPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateSelectOption implements Action {
	readonly type = ActionTypes.CREATE_SELECT_OPTION;
	constructor(public payload: { selectOption: SelectOptionRequest }) {}
}

export class CreateSelectOptionSuccess implements Action {
	readonly type = ActionTypes.CREATE_SELECT_OPTION_SUCCESS;
	constructor(public payload: { result: SelectOptionResponse }) {}
}

export class CreateSelectOptionError implements Action {
	readonly type = ActionTypes.CREATE_SELECT_OPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| FetchSelectOptions
	| FetchSelectOptionsSuccess
	| FetchSelectOptionsError
	| FetchAdminSelectOptions
	| FetchAdminSelectOptionsSuccess
	| FetchAdminSelectOptionsError
	| UpdateSelectOption
	| UpdateSelectOptionSuccess
	| UpdateSelectOptionError
	| CreateSelectOption
	| CreateSelectOptionSuccess
	| CreateSelectOptionError;
