import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlinkAdminService } from '@app/services/flink-admin/flink-admin.service';
import { FlinkerAdminStoreActions } from '.';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import FlinkerAdminResponse from '@flinkadmin-dashboard/models/response/flinker-admin-response.dto';
import {
	DeleteFlinker,
	DownloadAdminReport,
	DownloadFlinkerCv,
	DownloadObfuscatedFlinkerCv,
	FetchAllFlinkers,
	FetchAllReactivationRequests,
	FetchFlinkerDocument,
	FetchFlinkerReferenceScore,
	GetDetailedFlinker,
	LoginAsFlinker,
	UpdateFlinker,
	UpdateReactivationRequestStatus,
} from './actions';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import ReactivationRequestResponse from '@flinkadmin-dashboard/models/response/reactivation-requests-response.dto';
import FlinkerReferenceScore from '@models/dto/responses/flinker-reference-score.dto';
import { saveAs } from 'file-saver';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';
import { DownloadedFile } from '@app/services/http/http-v2.service';

@Injectable()
export class FlinkerAdminStoreEffects {
	constructor(private actions$: Actions, private flinkAdminService: FlinkAdminService) {}

	fetchAllFlinkersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.FetchAllFlinkers>(FlinkerAdminStoreActions.ActionTypes.FETCH_ALL_FLINKERS),
			switchMap((action: FetchAllFlinkers) =>
				this.flinkAdminService
					.getFlinkerList(action.payload.pageSize, action.payload.pageNumber, action.payload.flinkerAdminFilter)
					.pipe(
						map(
							(result: PaginatedResponse<FlinkerAdminResponse>) =>
								new FlinkerAdminStoreActions.FetchAllFlinkersSuccess({ result }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkerAdminStoreActions.FetchAllFlinkersError({ errorResponse: error })),
						),
					),
			),
		);
	});

	updateFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.UpdateFlinker>(FlinkerAdminStoreActions.ActionTypes.UPDATE_FLINKER),
			switchMap((action: UpdateFlinker) =>
				this.flinkAdminService.updateFlinker(action.payload.flinker).pipe(
					map((result: string) => new FlinkerAdminStoreActions.UpdateFlinkerSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.UpdateFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchAllReactivationRequestsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.FetchAllReactivationRequests>(
				FlinkerAdminStoreActions.ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS,
			),
			switchMap((action: FetchAllReactivationRequests) =>
				this.flinkAdminService.getReactivationRequestsList(action.payload.pageSize, action.payload.pageNumber).pipe(
					map(
						(result: PaginatedResponse<ReactivationRequestResponse>) =>
							new FlinkerAdminStoreActions.FetchAllReactivationRequestsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.FetchAllReactivationRequestsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateReactivationRequestStatusEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.UpdateReactivationRequestStatus>(
				FlinkerAdminStoreActions.ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS,
			),
			switchMap((action: UpdateReactivationRequestStatus) =>
				this.flinkAdminService.updateReactivationRequestStatus(action.payload).pipe(
					map(() => new FlinkerAdminStoreActions.UpdateReactivationRequestStatusSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.FetchAllReactivationRequestsError({ errorResponse: error })),
					),
				),
			),
		);
	});
	getUpdateFlinkerMatchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.GetDetailedFlinker>(FlinkerAdminStoreActions.ActionTypes.GET_DETAILED_FLINKER),
			switchMap((action: GetDetailedFlinker) =>
				this.flinkAdminService.getDetailedFlinker(action.payload.flinkerId).pipe(
					map(
						(result: DetailedFlinkerAdminResponse) =>
							new FlinkerAdminStoreActions.GetDetailedFlinkerSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.GetDetailedFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});
	fetchFlinkerReferenceScoreEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.FetchFlinkerReferenceScore>(
				FlinkerAdminStoreActions.ActionTypes.FETCH_FLINKER_REFERENCE_SCORE,
			),
			switchMap((action: FetchFlinkerReferenceScore) =>
				this.flinkAdminService.getFlinkerReferenceScore(action.payload.flinkerId).pipe(
					map(
						(result: FlinkerReferenceScore) =>
							new FlinkerAdminStoreActions.FetchFlinkerReferenceScoreSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.FetchFlinkerReferenceScoreError({ errorResponse: error })),
					),
				),
			),
		);
	});

	loginAsFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.LoginAsFlinker>(FlinkerAdminStoreActions.ActionTypes.LOGIN_AS_FLINKER),
			switchMap((action: LoginAsFlinker) =>
				this.flinkAdminService.loginAsFlinker(action.payload.flinkerId).pipe(
					map((result: SignInResponse) => {
						return new FlinkerAdminStoreActions.LoginAsFlinkerSuccess({
							result: { ...result, flinkerId: action.payload.flinkerId },
						});
					}),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.LoginAsFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchFlinkerDocumentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.FetchFlinkerDocument>(
				FlinkerAdminStoreActions.ActionTypes.FETCH_FLINKER_DOCUMENTS,
			),
			switchMap((action: FetchFlinkerDocument) =>
				this.flinkAdminService.getFlinkerDocuments(action.payload.documentId, action.payload.documentType).pipe(
					tap((response: DownloadedFile) => {
						saveAs(response.file, response.filename);
					}),
					map(
						(response: DownloadedFile) =>
							new FlinkerAdminStoreActions.FetchFlinkerDocumentSuccess({ result: 'File loaded' }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.FetchFlinkerDocumentError({ errorResponse: error })),
					),
				),
			),
		);
	});
	deleteFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.DeleteFlinker>(FlinkerAdminStoreActions.ActionTypes.DELETE_FLINKER),
			switchMap((action: DeleteFlinker) =>
				this.flinkAdminService.deleteFLinker(action.payload.flinkerId).pipe(
					map(
						(response: any) =>
							new FlinkerAdminStoreActions.DeleteFlinkerSuccess({ result: 'Flinker deleted successfully' }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkerAdminStoreActions.DeleteFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	downloadFlinkerCvEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.DownloadFlinkerCv>(FlinkerAdminStoreActions.ActionTypes.DOWNLOAD_FLINKER_CV),
			switchMap((action: DownloadFlinkerCv) =>
				this.flinkAdminService.downloadFlinkerCvAsFlinkAdmin(action.payload.flinkerId).pipe(
					map((response: any) => {
						return new FlinkerAdminStoreActions.DownloadFlinkerCvSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to download Flinker CV', error);
						return of(new FlinkerAdminStoreActions.DownloadFlinkerCvError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	downloadAdminReportEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.DownloadAdminReport>(FlinkerAdminStoreActions.ActionTypes.DOWNLOAD_ADMIN_REPORT),
			switchMap((action: DownloadAdminReport) =>
				this.flinkAdminService.downloadAdminReport(action.payload.searchId).pipe(
					map((response: any) => {
						return new FlinkerAdminStoreActions.DownloadAdminReportSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to download Admin report', error);
						return of(new FlinkerAdminStoreActions.DownloadAdminReportError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	downloadObfuscatedFlinkerCvEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkerAdminStoreActions.DownloadObfuscatedFlinkerCv>(
				FlinkerAdminStoreActions.ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV,
			),
			switchMap((action: DownloadObfuscatedFlinkerCv) =>
				this.flinkAdminService.downloadFlinkerCvAsFlinkAdmin(action.payload.flinkerId, true).pipe(
					map((response: any) => {
						return new FlinkerAdminStoreActions.DownloadObfuscatedFlinkerCvSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to download obfuscated Flinker CV', error);
						return of(new FlinkerAdminStoreActions.DownloadObfuscatedFlinkerCvError({ errorResponse: error }));
					}),
				),
			),
		);
	});
}
