import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { QualificationType } from '@app/enums/qualification-type.enum';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { getSelectOptionName, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';

@Component({
	selector: 'app-experience-profile',
	templateUrl: './experience-profile.component.html',
	styleUrls: ['./experience-profile.component.scss'],
})
export class ExperienceProfileComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	getSelectOptionName = getSelectOptionName;
	QualificationType = QualificationType;

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				if (selectOptionsResponse.result !== null) {
					this.selectOptions = selectOptionsResponse.result;
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
