import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-flink-branded-modal',
	templateUrl: './flink-branded-modal.component.html',
	styleUrls: ['./flink-branded-modal.component.scss'],
})
export class FlinkBrandedModalComponent implements OnInit, AfterViewInit {
	redirectUrl: string;
	@ViewChild('socialButtonContainer') socialButtonContainer: ElementRef;
	@ViewChild('facebookButton') facebookButton: ElementRef;
	copied = false;
	iframeString: string;

	constructor(
		public dialogRef: MatDialogRef<FlinkBrandedModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private router: Router,
		private renderer: Renderer2,
		private elRef: ElementRef,
	) {}

	ngOnInit(): void {
		this.redirectUrl = this.data.link;
		this.iframeString = `<iframe src="${this.data.link}" width="100%" height="800"></iframe>`;
	}

	ngAfterViewInit() {
		if (!this.data.showSocialShare) {
			return;
		}
		const linkedInPlatformScript = this.renderer.createElement('script');
		linkedInPlatformScript.src = 'https://platform.linkedin.com/in.js';
		linkedInPlatformScript.type = 'text/javascript';
		linkedInPlatformScript.text = 'lang: en_US';

		this.renderer.appendChild(this.socialButtonContainer.nativeElement, linkedInPlatformScript);

		const linkedInUrlScript = this.renderer.createElement('script');
		linkedInUrlScript.type = 'IN/Share';
		linkedInUrlScript.setAttribute('data-url', this.redirectUrl);
		this.renderer.appendChild(this.socialButtonContainer.nativeElement, linkedInUrlScript);

		const facebookPluginScript = this.renderer.createElement('script');
		facebookPluginScript.crossOrigin = 'anonymous';
		facebookPluginScript.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0';
		facebookPluginScript.nonce = 'cLXEiOml';
		this.renderer.appendChild(this.socialButtonContainer.nativeElement, facebookPluginScript);

		this.renderer.setAttribute(this.facebookButton.nativeElement, 'data-href', this.redirectUrl);
	}

	copyToClipboard() {
		navigator.clipboard.writeText(this.data.link);
		this.copied = true;
	}

	goToLink() {
		if (!this.data.iframe) {
			this.dialogRef.close('link');
			this.router.navigate([this.data.link], { state: { formValue: this.data.linkData } });
		} else {
			navigator.clipboard.writeText(this.data.link);
			this.copied = true;
		}
	}

	onClick() {
		this.dialogRef.close('button');
		if (this.data.buttonLink) {
			this.router.navigate([this.data.buttonLink]);
		}
	}
}
