import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-rounded-button',
	templateUrl: './rounded-button.component.html',
	styleUrls: ['./rounded-button.component.scss'],
})
export class RoundedButtonComponent implements OnInit {
	@Input() theme = '';
	@Input() value = '';
	@Input() disabled = false;
	@Input() type = '';
	@Input() loading = false;
	@Input() progressDiameter = 30;

	constructor() {}

	ngOnInit(): void {}
}
