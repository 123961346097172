import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { InterviewStoreActions } from 'projects/flink-app/src/app/root-store/interview-store';

@Component({
	selector: 'app-interviews',
	templateUrl: './interviews.component.html',
	styleUrls: ['./interviews.component.scss'],
})
export class InterviewsComponent implements OnInit {
	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.store.dispatch(new InterviewStoreActions.GetFlinkerInterviews());
	}
}
