import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { QualificationsComponent } from 'projects/flink-app/src/app/common/flinker/qualifications/qualifications.component';
import { NotificationType } from '@app/enums/notification-type.enum';

@Component({
	selector: 'app-education',
	templateUrl: './education.component.html',
	styleUrls: ['./education.component.scss'],
})
export class EducationComponent implements OnInit {
	editState = false;

	@ViewChild(QualificationsComponent) qualificationsComponent: QualificationsComponent;

	constructor(private notifierService: NotifierService) {}

	ngOnInit(): void {}

	submit(): void {
		this.qualificationsComponent.submit();
	}

	onSuccess(): void {
		this.notifierService.notify(NotificationType.Success, 'Qualifications updated successfully');
	}
}
