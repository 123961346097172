import ReferenceQuestion from '../../models/dto/responses/reference-question.dto';
import HttpPackage from '../../models/http-package';

export interface ReferenceQuestionsState {
	fetchAllReferenceQuestionsHttpPackage: HttpPackage<ReferenceQuestion[]>;
	updateReferenceQuestionHttpPackage: HttpPackage<ReferenceQuestion>;
	createReferenceQuestionHttpPackage: HttpPackage<ReferenceQuestion>;
	deleteReferenceQuestionHttpPackage: HttpPackage<string>;
}

export const initialState: ReferenceQuestionsState = {
	fetchAllReferenceQuestionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	updateReferenceQuestionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	createReferenceQuestionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	deleteReferenceQuestionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
