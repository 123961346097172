import { Injectable } from '@angular/core';
import { PsychAssessmentCompletionStatusResponse } from '@models/dto/responses/psych-assessment-completion-status-response.dtp';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class PsychAssessmentService {
	apiPsychAssessmentEndpoint = 'psychAssessment';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getPsychAssessmentCompletionStatus() {
		return this.httpV2Service.getSecure<PsychAssessmentCompletionStatusResponse>(
			`${this.apiPsychAssessmentEndpoint}/completionStatus`,
		);
	}
}
