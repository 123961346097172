import { SelectOptionResponse } from './dto/responses/select-option-response.dto';
import { DictionaryWords } from '../enums/dictionary-words.enum';

export const sortByOptions: SelectOptionResponse[] = [
	{
		name: 'Name',
		code: 'Name',
	},
	{
		name: DictionaryWords.Salary,
		code: 'Salary',
	},
	{
		name: 'Fitness score',
		code: 'FitnessScore',
	},
	{
		name: 'Experience',
		code: 'Experience',
	},
];

export const maxFlinkerNameOrSurnameLength = 50;
export const maxPassportNumberLength = 15;
export const maxQuestionTextLength = 512;
export const maxInterviewQuestionTextLength = maxQuestionTextLength;
export const maxInterviewQuestionnaireNameLength = 100;
export const maxJobAdvertJobDescriptionLength = 4000;
export const maxJobAdvertRolesAndResponsibilitiesLength = 4000;

export const entityConfig = {
	cellphoneNumber: {
		maxInternationalCellphoneNumberLength: 21,
	},
	flinker: {
		maxNameLength: 50,
		maxSurnameLength: 50,
		maxNextJobRequirement: 215, // Custom on frontend, should remain 215 for CV bio.
		minCellphoneNumber: 10,
		maxCellphoneNumber: 12,
		maxInternationalCellphoneNumber: 21,
		maxPassportNumberLength: 15,
	},
	flinkCo: {
		maxContactNumberLength: 14,
		maxEmailAddressLength: 100,
		maxLogoLength: 200,
		maxNameLength: 50,
		maxSurnameLength: 50,
		maxOtherJobTitleLength: 200,
		maxRegisteredNameLength: 100,
		maxRegistrationNumberLength: 50,
		maxVatNumberLength: 50,
		maxWebsiteUrlLength: 100,
	},
	currentEmployment: {
		maxCompanyNameLength: 100,
		maxDutiesDescription: 2000,
		maxOtherJobTitleLength: 200,
	},
	criminalDeclaration: {
		maxCountryOfConvictionLength: 100,
		maxDetailsOfOffenceLength: 500,
		maxDigitalSignatureLength: 100,
		maxOffenceLength: 100,
		maxOffenceSentenceLength: 200,
	},
	interview: {
		maxLocationLength: 200,
		maxMeetingUrlLength: 500,
		maxOtherRejectionReasonLength: 100,
	},
	offer: {
		maxBenefitsLength: 1000,
		maxOtherRejectionReasonLength: 1000,
		maxPositionLength: 200,
		maxProbationPeriodLength: 200,
	},
	pastEmployment: {
		maxCompanyNameLength: 100,
		maxDutiesDescription: 2000,
		maxOtherJobTitleLength: 200,
	},
	qualification: {
		maxCompletionYear: 4,
		maxOtherInstitution: 200,
		maxYearObtained: 4,
		maxNameLength: 300,
		qualificationYearRegex: () => new RegExp('^[0-9]{4}$'),
	},
	reactivationRequest: {
		maxOtherReactivationReason: 200,
	},
	reference: {
		maxContactNumberLength: 12,
		maxEmailAddressLength: 100,
		maxNameLength: 80,
		maxOtherJobTitleLength: 200,
	},
	referenceQuestion: {
		maxQuestionLength: 200,
	},
	search: {
		maxSearchNameLength: 50,
		maxDutiesDescription: 500,
	},
	selectOption: {
		cacheKey: 'selectOptions',
		allSelectOptionsMapCacheKey: 'selectOptionsMap',
		cacheTimeMs: 1000 * 60 * 60 * 24,
		minNameLength: 1,
		maxNameLength: 500,
		minCodeLength: 1,
		maxCodeLength: 15,
	},
	verificationItem: {
		defaultIdVerificationSuccessNote: 'Valid SA ID number construction.',
		defaultQualificationVerificationSuccessNote: 'Qualification has been awarded to candidate.',
	},
	jobAdverts: {
		maxRolesAndResponsibilities: 4000,
		maxJobDescription: 4000,
	},
	interviewQuestionnaire: {
		maxNameLength: 100,
	},
	question: {
		maxQuestionTextLength: 512,
	},
	interviewer: {
		maxAdditionalNotesLength: 2000,
	},
	otherSkills: {
		maxSkillLength: 200,
	},
};
