<div class='p-1 p-md-4'>
  <app-dashboard-section-header icon='turned_in' title='Applying To:'></app-dashboard-section-header>
  <div>
    <div class='row pt-3'>
      <div class='col-12 d-sm-flex d-block px-0'>
        <form class='container-fluid' [formGroup]='form'>
          <div class='row'>
            <app-chips-input
              class='col-12 mb-m-4'
              labelText='Ideal work province'
              formControlName='idealWorkProvince'
              theme='outlined-input'
              [options]='(selectOptionsHttpPackage$ | async)?.result?.provinces'
              hint='{{dictionaryHints.ProvinceSelection}}'
              (chipsChange)='filterCities($event)'
              [errorMessage]="getErrorMessage(form.controls.idealWorkProvince, 'Province', shouldValidate)"
            >
            </app-chips-input>

            <app-chips-input
              class='col-12 top-padding'
              labelText='Ideal work cities'
              formControlName='idealWorkCities'
              [prefill]='cities'
              [options]='(filteredCitiesInProvinces$ | async) || (selectOptionsHttpPackage$ | async)?.result?.cities'
              [errorMessage]="getErrorMessage(form.controls.idealWorkCity, 'Ideal work city', shouldValidateCities)"
            >
            </app-chips-input>

            <div class='col-12 pt-4'>
              <app-square-button
                matStepperNext
                theme='m1'
                value='Update'
                type='button'
                class='d-flex justify-content-center'
                style='max-width: 350px'
                [disabled]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                [loading]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                (click)='submit()'
              >
              </app-square-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
