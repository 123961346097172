import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getErrorMessage } from '@app/utils/form.util';
import { AuthService } from '@app/services/auth/auth.service';
import { MicrosoftLoginProvider, SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import SignInRequest from '@models/dto/requests/sign-in-request.dto';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { Subject, Subscription } from 'rxjs';
import { AccountType } from '@app/enums/account-type.enum';
import { FlinkCoTakeOnStoreActions, FlinkCoTakeOnStoreSelectors } from '@root-store/flinkco-take-on-store';
import { SsoType } from '@app/enums/sso-type.enum';
import { NotifierService } from 'angular-notifier';
import { ToggleSwitchOption } from 'projects/shared/switch-toggle/switch-toggle.component';
import { NGXLogger } from 'ngx-logger';
import { NotificationType } from '@app/enums/notification-type.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { RoleAuthService } from '@app/services/auth/role-auth.service';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
	@Input() submitValue = '';
	isLoading = false;
	subscription = new Subscription();
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	flinkerHttpPackage$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinkerHttpPackage);
	flinkCoHttpPackage$ = this.store.select(FlinkCoTakeOnStoreSelectors.selectGetFlinkCoHttpPackage);
	shouldValidate = false;
	toggleSwitchOptions: ToggleSwitchOption[] = [
		{
			label: 'Get hired',
		},
		{
			label: 'Start hiring',
		},
	];

	private readonly destroy$ = new Subject<void>();
	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private authSocialService: SocialAuthService,
		private store: Store<RootStoreState.State>,
		private activatedRoute: ActivatedRoute,
		private notifierService: NotifierService,
		private readonly logger: NGXLogger,
		private roleAuth: RoleAuthService,
	) {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
		});
	}

	ngOnInit(): void {
		this.subscription.add(
			this.flinkerHttpPackage$
				.pipe(
					filter((response) => response.result != null && !response.loading),
					takeUntil(this.destroy$),
				)
				.subscribe((flinkerHttpPackage) => {
					if (!flinkerHttpPackage.result.takeOnComplete) {
						this.router.navigate(['/take-on/flinker']);
					} else {
						if (this.authService.getReturnUrl()) {
							this.router.navigateByUrl(`/${this.authService.getReturnUrl()}`);
							this.authService.clearReturnUrl();
						} else {
							this.router.navigate(['/flinker-dashboard/profile']);
						}
					}
				}),
		);
		this.subscription.add(
			this.flinkCoHttpPackage$.subscribe((flinkCoHttpPackage) => {
				if (flinkCoHttpPackage.result != null) {
					console.log(flinkCoHttpPackage.result);
					if (!flinkCoHttpPackage.result.takeOnComplete) {
						this.router.navigate(['/take-on/flinkco']);
					} else {
						if (this.roleAuth.getRole() === AccountType.FlinkCompanyMaster) {
							this.router.navigate(['/flinkco-dashboard/profile']);
						} else {
							this.router.navigate(['/flinkco-dashboard/candidate-search']);
						}
					}
				}
			}),
		);
	}

	onGoogleSignIn(): void {
		const formValues = this.form.value;
		this.isLoading = true;

		this.authSocialService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
			this.authService
				.sso({
					idToken: data.idToken,
					accountType: AccountType.Flinker,
					signUp: false,
					rememberMe: true,
					ssoType: SsoType.Google,
				})
				.subscribe(
					(signInResponse: SignInResponse) => {
						this.onSignInSuccess(signInResponse);
					},
					(err: ErrorResponseV2) => {
						this.isLoading = false;
						this.logger.error('Failed to login with Google.', err);
						if (err.status === 401) {
							this.notifierService.notify(
								NotificationType.Error,
								'We could not log you in. If you are already registered please contact support, otherwise please register first.',
							);
						}
					},
				);
		});
	}

	onMicrosoftSignIn(): void {
		const formValues = this.form.value;
		this.isLoading = true;

		this.authSocialService.signIn(MicrosoftLoginProvider.PROVIDER_ID).then((data) => {
			this.authService
				.sso({
					idToken: data.idToken,
					authToken: data.authToken,
					accountType: AccountType.Flinker,
					signUp: false,
					rememberMe: true,
					ssoType: SsoType.Microsoft,
				})
				.subscribe(
					(signInResponse: SignInResponse) => {
						this.onSignInSuccess(signInResponse);
					},
					(err: ErrorResponseV2) => {
						this.isLoading = false;
						this.logger.error('Failed to login with Microsoft.', err);
						if (err.status === 401) {
							this.notifierService.notify(
								NotificationType.Error,
								'We could not log you in. If you are already registered please contact support, otherwise please register first.',
							);
						}
					},
				);
		});
	}

	signIn(): void {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		const formValues = this.form.value;
		this.isLoading = true;

		this.authService.signIn(new SignInRequest(formValues.email, formValues.password, true)).subscribe(
			(signInResponse: SignInResponse) => {
				this.router.navigate(['../otp'], { relativeTo: this.activatedRoute, state: { email: formValues.email } });
			},
			(err: ErrorResponseV2) => {
				this.isLoading = false;
				this.logger.error('Failed to login user.', err);
				if (err.status === 401) {
					this.notifierService.notify(
						NotificationType.Error,
						'We could not log you in. Username or password is incorrect.',
					);
				}
			},
		);
	}

	onSignInSuccess(signInResponse: SignInResponse): void {
		if (
			signInResponse.userAccounts.length == 1 &&
			signInResponse.userAccounts[0].account.accountType.name === AccountType.Flinker
		) {
			this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
		} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkCompanyMaster) {
			this.store.dispatch(new FlinkCoTakeOnStoreActions.GetFlinkCo());
		} else if (signInResponse.userAccounts[0].account.accountType.name === AccountType.FlinkAdmin) {
			this.router.navigate(['/flinkadmin-dashboard']);
		} else {
			this.router.navigate(['/flinkco-dashboard']);
		}
		// TODO: Handle multiple user accounts and other types
	}

	toggleSwitch(value: number): void {
		switch (value) {
			case 0:
				this.router.navigate(['/auth/flinker-registration']);
				break;
			case 1:
				this.router.navigate(['/auth/flinkco-registration']);
				break;
			default:
				this.router.navigate(['/auth/flinker-registration']);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
