import { FlinkerJobsState, initialState } from '@root-store/flinker/jobs/state';
import { Actions, ActionTypes } from '@root-store/flinker/jobs/action';
import { updateListWithLatestItem } from '@root-store/helpers';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';

export function FlinkerJobsReducer(state = initialState, action: Actions): FlinkerJobsState {
	switch (action.type) {
		case ActionTypes.CREATE_JOB_APPLICATION:
			return {
				...state,
				createJobApplicationHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};

		case ActionTypes.CREATE_JOB_APPLICATION_SUCCESS:
			return {
				...state,
				createJobApplicationHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
			};

		case ActionTypes.CREATE_JOB_APPLICATION_ERROR:
			return {
				...state,
				createJobApplicationHttpPackage: {
					loading: false,
					result: null,
					error: action.payload.errorResponse,
				},
			};

		case ActionTypes.CREATE_INITIAL_JOB_APPLICATION:
			return {
				...state,
				createInitialJobApplicationHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};

		case ActionTypes.CREATE_INITIAL_JOB_APPLICATION_SUCCESS:
			return {
				...state,
				createInitialJobApplicationHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
			};

		case ActionTypes.CREATE_INITIAL_JOB_APPLICATION_ERROR:
			return {
				...state,
				createInitialJobApplicationHttpPackage: {
					loading: false,
					result: null,
					error: action.payload.errorResponse,
				},
			};

		case ActionTypes.FETCH_JOB_ADVERT_QUESTIONS:
			return {
				...state,
				fetchJobAdvertQuestionsHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};

		case ActionTypes.FETCH_JOB_ADVERT_QUESTIONS_SUCCESS:
			return {
				...state,
				fetchJobAdvertQuestionsHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
			};

		case ActionTypes.FETCH_JOB_ADVERT_QUESTIONS_ERROR:
			return {
				...state,
				fetchJobAdvertQuestionsHttpPackage: {
					loading: false,
					result: null,
					error: action.payload.errorResponse,
				},
			};

		case ActionTypes.GET_JOB_ADVERT_FOR_FLINKER:
			return {
				...state,
				getSingleJobAdvertForFlinkerHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};

		case ActionTypes.GET_JOB_ADVERT_FOR_FLINKER_SUCCESS:
			return {
				...state,
				getSingleJobAdvertForFlinkerHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
			};

		case ActionTypes.GET_JOB_ADVERT_FOR_FLINKER_ERROR:
			return {
				...state,
				getSingleJobAdvertForFlinkerHttpPackage: {
					loading: false,
					result: null,
					error: action.payload.errorResponse,
				},
			};

		case ActionTypes.GET_FILTERED_JOB_ADVERTS:
			return {
				...state,
				getFilteredJobAdvertsHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};

		case ActionTypes.GET_FILTERED_JOB_ADVERTS_SUCCESS:
			return {
				...state,
				getFilteredJobAdvertsHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
			};

		case ActionTypes.GET_FILTERED_JOB_ADVERTS_ERROR:
			return {
				...state,
				getFilteredJobAdvertsHttpPackage: {
					loading: false,
					result: null,
					error: action.payload.errorResponse,
				},
			};
		case ActionTypes.FAVOURITE_JOB_ADVERT:
			return {
				...state,
				favouriteJobAdvertHttpPackage: {
					loading: true,
					result: null,
					error: null,
				},
			};
		case ActionTypes.FAVOURITE_JOB_ADVERT_SUCCESS:
			return {
				...state,
				favouriteJobAdvertHttpPackage: {
					loading: false,
					result: action.payload.result,
					error: null,
				},
				getFilteredJobAdvertsHttpPackage: {
					...state.getFilteredJobAdvertsHttpPackage,
					result: !state.getFilteredJobAdvertsHttpPackage
						? null
						: {
								...state.getFilteredJobAdvertsHttpPackage.result,
								results: updateListWithLatestItem<JobAdvertResponseDto>(
									state.getFilteredJobAdvertsHttpPackage.result.results,
									action.payload.result,
									'id',
									action.payload.result.id,
								),
						  },
				},
			};
		default: {
			return state;
		}
	}
}
