import { Action } from '@ngrx/store';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import InterviewQuestionnaireResponse from '@models/dto/responses/interview-questionnaire-response.dto';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import { DefaultInterviewQuestionCategoriesResponse } from '@models/dto/responses/default-interview-question-categories-response.dto';
import GetDefaultInterviewQuestionsRequest from '@models/dto/requests/get-default-interview-questions-request.dto';
import DefaultInterviewQuestionResponse from '@models/dto/responses/default-interview-question-response.dto';
import CreateInterviewQuestionnaireRequest from '@models/dto/requests/create-interview-questionnaire-request.dto';
import UpdateInterviewQuestionnaireRequest from '@models/dto/requests/update-interview-questionnaire-request.dto';

export enum ActionTypes {
	GET_PAGINATED_INTERVIEW_QUESTIONNAIRES = '[INTERVIEW_QUESTIONNAIRE] GET PAGINATED INTERVIEW QUESTIONNAIRES',
	GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET PAGINATED INTERVIEW QUESTIONNAIRES SUCCESS',
	GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET PAGINATED INTERVIEW QUESTIONNAIRES ERROR',

	GET_INTERVIEW_QUESTIONNAIRE = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTIONNAIRE',
	GET_INTERVIEW_QUESTIONNAIRE_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTIONNAIRE SUCCESS',
	GET_INTERVIEW_QUESTIONNAIRE_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTIONNAIRE ERROR',

	GET_INTERVIEW_QUESTION_CATEGORIES = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTION CATEGORIES',
	GET_INTERVIEW_QUESTION_CATEGORIES_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTION CATEGORIES SUCCESS',
	GET_INTERVIEW_QUESTION_CATEGORIES_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET INTERVIEW QUESTION CATEGORIES ERROR',

	GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY = '[INTERVIEW_QUESTIONNAIRE] GET DEFAULT INTERVIEW QUESTIONS BY CATEGORY',
	GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET DEFAULT INTERVIEW QUESTIONS BY CATEGORY SUCCESS',
	GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET DEFAULT INTERVIEW QUESTIONS BY CATEGORY ERROR',

	GET_ALL_DEFAULT_INTERVIEW_QUESTIONS = '[INTERVIEW_QUESTIONNAIRE] GET ALL DEFAULT INTERVIEW QUESTIONS',
	GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET ALL DEFAULT INTERVIEW QUESTIONS SUCCESS',
	GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET ALL DEFAULT INTERVIEW QUESTIONS ERROR',

	CREATE_INTERVIEW_QUESTIONNAIRE = '[INTERVIEW_QUESTIONNAIRE] CREATE INTERVIEW QUESTIONNAIRE',
	CREATE_INTERVIEW_QUESTIONNAIRE_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] CREATE INTERVIEW QUESTIONNAIRE SUCCESS',
	CREATE_INTERVIEW_QUESTIONNAIRE_ERROR = '[INTERVIEW_QUESTIONNAIRE] CREATE INTERVIEW QUESTIONNAIRE ERROR',

	UPDATE_INTERVIEW_QUESTIONNAIRE = '[INTERVIEW_QUESTIONNAIRE] UPDATE INTERVIEW QUESTIONNAIRE',
	UPDATE_INTERVIEW_QUESTIONNAIRE_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] UPDATE INTERVIEW QUESTIONNAIRE SUCCESS',
	UPDATE_INTERVIEW_QUESTIONNAIRE_ERROR = '[INTERVIEW_QUESTIONNAIRE] UPDATE INTERVIEW QUESTIONNAIRE ERROR',

	DELETE_INTERVIEW_QUESTIONNAIRE = '[INTERVIEW_QUESTIONNAIRE] DELETE INTERVIEW QUESTIONNAIRE',
	DELETE_INTERVIEW_QUESTIONNAIRE_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] DELETE INTERVIEW QUESTIONNAIRE SUCCESS',
	DELETE_INTERVIEW_QUESTIONNAIRE_ERROR = '[INTERVIEW_QUESTIONNAIRE] DELETE INTERVIEW QUESTIONNAIRE ERROR',

	GET_ALL_INTERVIEW_QUESTIONNAIRES = '[INTERVIEW_QUESTIONNAIRE] GET ALL INTERVIEW QUESTIONNAIRES',
	GET_ALL_INTERVIEW_QUESTIONNAIRES_SUCCESS = '[INTERVIEW_QUESTIONNAIRE] GET ALL INTERVIEW QUESTIONNAIRES SUCCESS',
	GET_ALL_INTERVIEW_QUESTIONNAIRES_ERROR = '[INTERVIEW_QUESTIONNAIRE] GET ALL INTERVIEW QUESTIONNAIRES ERROR',
}

export class GetPaginatedInterviewQuestionnaires implements Action {
	readonly type = ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES;
	constructor(public payload: { pageSize: number; pageNumber: number }) {}
}

export class GetPaginatedInterviewQuestionnairesSuccess implements Action {
	readonly type = ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<InterviewQuestionnaireResponse> }) {}
}

export class GetPaginatedInterviewQuestionnairesError implements Action {
	readonly type = ActionTypes.GET_PAGINATED_INTERVIEW_QUESTIONNAIRES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class GetInterviewQuestionnaire implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONNAIRE;
	constructor(public payload: { interviewQuestionnaireId: string }) {}
}

export class GetInterviewQuestionnaireSuccess implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONNAIRE_SUCCESS;
	constructor(public payload: { result: InterviewQuestionnaireResponse }) {}
}

export class GetInterviewQuestionnaireError implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTIONNAIRE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class GetInterviewQuestionCategories implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES;
	constructor() {}
}

export class GetInterviewQuestionCategoriesSuccess implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES_SUCCESS;
	constructor(public payload: { result: DefaultInterviewQuestionCategoriesResponse }) {}
}

export class GetInterviewQuestionCategoriesError implements Action {
	readonly type = ActionTypes.GET_INTERVIEW_QUESTION_CATEGORIES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class GetDefaultInterviewQuestionsByCategory implements Action {
	readonly type = ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY;
	constructor(public payload: GetDefaultInterviewQuestionsRequest) {}
}

export class GetDefaultInterviewQuestionsByCategorySuccess implements Action {
	readonly type = ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_SUCCESS;
	constructor(public payload: { result: DefaultInterviewQuestionResponse[] }) {}
}

export class GetDefaultInterviewQuestionsByCategoryError implements Action {
	readonly type = ActionTypes.GET_DEFAULT_INTERVIEW_QUESTIONS_BY_CATEGORY_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class GetAllDefaultInterviewQuestions implements Action {
	readonly type = ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS;
	constructor() {}
}

export class GetAllDefaultInterviewQuestionsSuccess implements Action {
	readonly type = ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_SUCCESS;
	constructor(public payload: { result: DefaultInterviewQuestionResponse[] }) {}
}

export class GetAllDefaultInterviewQuestionsError implements Action {
	readonly type = ActionTypes.GET_ALL_DEFAULT_INTERVIEW_QUESTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class CreateInterviewQuestionnaire implements Action {
	readonly type = ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE;
	constructor(public payload: CreateInterviewQuestionnaireRequest) {}
}

export class CreateInterviewQuestionnaireSuccess implements Action {
	readonly type = ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE_SUCCESS;
	constructor(public payload: { result: InterviewQuestionnaireResponse }) {}
}

export class CreateInterviewQuestionnaireError implements Action {
	readonly type = ActionTypes.CREATE_INTERVIEW_QUESTIONNAIRE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class UpdateInterviewQuestionnaire implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE;
	constructor(public payload: UpdateInterviewQuestionnaireRequest) {}
}

export class UpdateInterviewQuestionnaireSuccess implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE_SUCCESS;
	constructor(public payload: { result: InterviewQuestionnaireResponse }) {}
}

export class UpdateInterviewQuestionnaireError implements Action {
	readonly type = ActionTypes.UPDATE_INTERVIEW_QUESTIONNAIRE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class DeleteInterviewQuestionnaire implements Action {
	readonly type = ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE;
	constructor(public payload: { id: string }) {}
}

export class DeleteInterviewQuestionnaireSuccess implements Action {
	readonly type = ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE_SUCCESS;
	constructor(public payload: { id: string }) {}
}

export class DeleteInterviewQuestionnaireError implements Action {
	readonly type = ActionTypes.DELETE_INTERVIEW_QUESTIONNAIRE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export class GetAllInterviewQuestionnaires implements Action {
	readonly type = ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES;
	constructor() {}
}

export class GetAllInterviewQuestionnairesSuccess implements Action {
	readonly type = ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES_SUCCESS;
	constructor(public payload: { result: InterviewQuestionnaireResponse[] }) {}
}

export class GetAllInterviewQuestionnairesError implements Action {
	readonly type = ActionTypes.GET_ALL_INTERVIEW_QUESTIONNAIRES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponseV2 }) {}
}

export type Actions =
	| GetPaginatedInterviewQuestionnaires
	| GetPaginatedInterviewQuestionnairesSuccess
	| GetPaginatedInterviewQuestionnairesError
	| GetInterviewQuestionnaire
	| GetInterviewQuestionnaireSuccess
	| GetInterviewQuestionnaireError
	| GetInterviewQuestionCategories
	| GetInterviewQuestionCategoriesSuccess
	| GetInterviewQuestionCategoriesError
	| GetDefaultInterviewQuestionsByCategory
	| GetDefaultInterviewQuestionsByCategorySuccess
	| GetDefaultInterviewQuestionsByCategoryError
	| GetAllDefaultInterviewQuestions
	| GetAllDefaultInterviewQuestionsSuccess
	| GetAllDefaultInterviewQuestionsError
	| CreateInterviewQuestionnaire
	| CreateInterviewQuestionnaireSuccess
	| CreateInterviewQuestionnaireError
	| UpdateInterviewQuestionnaire
	| UpdateInterviewQuestionnaireSuccess
	| UpdateInterviewQuestionnaireError
	| DeleteInterviewQuestionnaire
	| DeleteInterviewQuestionnaireSuccess
	| DeleteInterviewQuestionnaireError
	| GetAllInterviewQuestionnaires
	| GetAllInterviewQuestionnairesSuccess
	| GetAllInterviewQuestionnairesError;
