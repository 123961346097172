<div class="wrapper-container">
	<div class="take-on-container">
		<app-popup-notification class="front-sub-1">
			[Disclaimer] - By making use of this Website and our Services, you acknowledge that you have read, understand, and
			agree to be bound by the terms set out in this Privacy Policy, as amended from time to time. Your continued use of
			our Website and our Services also indicates that you acknowledge and agree that this Privacy Policy may be amended
			from time to time, without notice to you and agree to be bound by the amended terms.
		</app-popup-notification>
		<div class="container py-5">
			<app-page-title [title]="'Flink SA (Pty) Ltd Terms and Conditions '"></app-page-title>
			<mat-card>
				<div class="front-sub-3 font-size-11">
					<app-page-header-toolbar heading="1.	INTRODUCTION"> </app-page-header-toolbar>

					1.1. Flink SA (Pty) Ltd and its affiliates (hereinafter referred to as “Flink”, “we”, “our” or “us”) provides
					streamlined recruitment Services to Companies seeking to fulfil its recruitment-related needs.
					<br /><br />
					1.2. By completing the subscription process, including completing the registration form(s), the Company
					(hereinafter referred to as “the Company”, “you”, or “your”) accepts these terms and conditions (“Terms and
					Conditions”) and agrees to be bound by the Terms and Conditions without limitation. By entering into these
					Terms and Conditions, you further acknowledge that you have read and understand our Privacy Policy and Terms
					of Use, which must be read as if specifically incorporated herein (the Terms and Conditions, Privacy Policy
					and Terms of Use are hereinafter collectively referred to as “the Terms”).
					<br /><br />

					<app-page-header-toolbar heading="2.	INTERPRETATION"> </app-page-header-toolbar>
					2.1. In the interpretation of this Agreement, unless the context otherwise requires, headings are for
					reference and convenience only and shall not be taken into account in the interpretation of this Agreement;
					the singular shall include the plural and vice versa; and references to one gender shall include references to
					the other genders.
					<br /><br />
					2.2. If any period is referred to in these Agreement by way of reference to a number of days, the days shall
					be reckoned exclusively of the first and inclusively of the last day unless the last day falls on a day which
					is not a Business Day, in which case the day shall be next succeeding business day.
					<br /><br />
					2.3. The words “include”, “includes”, “including”, “for example”, or “such as” are not used as, and are not to
					be interpreted as, words of limitation, and when introducing an example do not limit the meaning of the words
					to which the example relates to that example or examples of a similar kin.
					<br /><br />
					2.4. References in this Agreement to any statute or statutory provision include a reference to that statute or
					statutory provision as amended, extended, consolidated or replaced from time to time (whether before or after
					the date of this Agreement) and include any order, regulation, instrument or other subordinate legislation
					made under the relevant statute or statutory provision.
					<br /><br />
					2.5. If figures are referred to in numerals and in words and if there is any conflict between the two, the
					words shall prevail.
					<br /><br />
					2.6. The rule of construction that a contract shall be interpreted against the party responsible for the
					drafting or preparation of the contract, shall not apply.
					<br /><br />
					2.7. This Agreement shall be governed by and construed and interpreted in accordance with the law of South
					Africa.
					<br /><br />
					2.8. The following expressions bear the meaning assigned to them below and cognate expressions bear
					corresponding meanings:
					<br /><br />
					2.8.1. “Ad Hoc Offering” means the once-off Service Package selected by the Company, in which case Flink is
					appointed to provide a once-off service to the Company as further defined in the Service Level Agreement.
					<br /><br />
					2.8.2. “Application” means the Flink software application, accessed through the Website, to which the Company
					has registered and subscribed, and through which we provide the Services to the Company.
					<br /><br />
					2.8.3. “Browser” means a person who visits our Website, through any means, for the purposes of viewing and
					browsing, but who has not yet made use of our Services or our Application, and may or may not have the
					intention to do so in future.
					<br /><br />
					2.8.4. “Commencement Date” means the date on which the Terms and Conditions become effective, that being the
					date on which the Company completes the Registration Form.
					<br /><br />
					2.8.5. “Company” means an entity, partnership, organisation or person (including a juristic and a natural
					person) who has registered and subscribed with Flink for the purpose of using the Services to fulfil its
					recruitment-related needs through the Web-based Application.
					<br /><br />
					2.8.6. “Flink” means Flink SA (Pty) Ltd and its lawful successors, assigns and affiliates, as the case may be.
					<br /><br />
					2.8.7. “Flinker” means the person who uses the Services to secure possible recruitment positions with
					Companies.
					<br /><br />
					2.8.8. “Party” or “Parties” means Flink and the Company, as the context may indicate.
					<br /><br />
					2.8.9. “Personal Information” shall have the meaning ascribed to it in Chapter 1 of Protection of Personal
					Information Act 4 of 2013 and Article 4(1) of the General Data Protection Regulation 2016/679 and includes,
					but is not limited to a name, address, email address, telephone or fax number, fingerprints, criminal history
					and education or other personal credentials provided, or which is collected from the Company or other third
					parties.
					<br /><br />
					2.8.10. “Registration Form” means the electronic form, containing a list of fields pertinent to the Terms, to
					which a Browser is directed to in order to complete. The information completed in the Registration Form shall
					be the information governing the relationship between Flink and the Company and shall form part of the Service
					Package provided to the Company.
					<br /><br />
					2.8.11. “Registration Process” means the process to be followed by the Browser, including completing the
					Registration Form, which, once completed and submitted online, allows the Browser to sign up as a Company and
					subscribe to the Services provided by Flink as set out more clearly in the Service Level Agreement.
					<br /><br />
					2.8.12. “Retainer Offering” means the monthly Service Package selected by the Company, in which case Flink
					grants the Company access to the platform without limitation in terms of searches and placements of suitable
					candidates found on the platform, for the duration of the retainer agreement, as further set out in the
					Service Level Agreement.
					<br /><br />
					2.8.13. “Service Package” means the service offering selected by the Company in the Registration Process,
					which may include an Ad Hoc Offering or a Retainer Offering, as the case may be, with the related service and
					billing options selected by the Company during the Registration Process.
					<br /><br />
					2.8.14. “Services” means the streamlined recruitment and related services provided by Flink to the Company
					through the Website and, more specifically, the Application, which have been selected by the Company during
					the Registration Process and incorporated into the Service Package.
					<br /><br />
					2.8.15. “Service Level Agreement” means the service level agreement executed between Flink and the Company,
					setting out the terms of Service provided to the Company.
					<br /><br />
					2.8.16. “Termination Date” means the date on which the Terms and Conditions will terminate, on 30 (thirty)
					days’ written notice by either Party or as otherwise determined in the Terms or by the Service Level
					Agreement.
					<br /><br />
					2.8.17. “Terms” means these Terms and Conditions, read in conjunction with the Privacy Policy and Terms of
					Use.
					<br /><br />
					2.8.18. “Terms and Conditions” means these Terms and Conditions.
					<br /><br />
					2.8.19. “Website” means the website owned by Flink through which the Services are offered, comprising of the
					Website and the Application.
					<br /><br />

					<app-page-header-toolbar heading="3.	APPOINTMENT AND SERVICES"> </app-page-header-toolbar>

					3.1. The Company appoints Flink to provide the Services as set out more fully in the Service Level Agreement.
					In the event that the Company has not specifically executed a Service Level Agreement, the relationship
					between the Parties shall be regulated by the Terms.
					<br /><br />
					3.2. The Company shall provide the Services to the Company from the Commencement Date until the Termination
					Date.
					<br /><br />
					3.3. The Company shall provide the relevant information as and when required by Flink in order for us to
					provide the Services to the Company.
					<br /><br />
					3.4. Flink shall not be required to provide the Services until such time as it has received payment as set out
					in the Service Package selected by the Company upon Registration.
					<br /><br />

					<app-page-header-toolbar heading="4.	ACCEPTANCE OF THESE TERMS AND CONDITIONS"> </app-page-header-toolbar>

					4.1. The Terms constitute a binding agreement between Flink and the Company in terms of section 11(3) of the
					Electronic Communications and Transactions Act 25 of 2002. Such agreement shall be construed to have been
					concluded in Johannesburg, South Africa. Notwithstanding this, should the relevant section be declared to be
					invalid or amended for any reason, the Company agrees that the Terms constitute a binding agreement between
					Flink and the Company.
					<br /><br />
					4.2. By registering for our Services, you acknowledge that you have read through, understand and agree to be
					bound by the Terms. By registering for our Services, you further acknowledge that you have read through,
					understand and agree to be bound by the terms set out in the Service Level Agreement, whether a signature
					(electronic or otherwise) is reflected on the Service Level Agreement or not.
					<br /><br />
					4.3. In the event that Flink has not provided you with a copy of the Service Level Agreement upon
					Registration, you must request a copy thereof within 5 (five) Business Days of the Registration Process being
					completed, failing which you will be deemed to have received the Service Level Agreement.
					<br /><br />
					4.4. The Terms may change from time to time at Flink’s sole discretion and without notice to you. The current
					version of the Terms applicable at the time you make use of the Services and becomes binding each time you use
					the Services. You must familiarise yourself with the current Terms each time you use the Services.
					<br /><br />

					<app-page-header-toolbar heading="5.	REGISTRATION PROCESS"> </app-page-header-toolbar>

					5.1. In order to access and make use of our Services, the Company must proceed to register using and
					completing our online Registration Form.
					<br /><br />
					5.2. While registering:
					<br /><br />
					5.2.1. You will be requested to provide login details, which you will have the sole responsibility to protect
					and keep confidential. We will not have access to your login details and shall under no circumstances be held
					liable for any loss or damage arising from the disclosure, whether accidental, unlawful or otherwise, of your
					login details.
					<br /><br />
					5.2.2. You will be requested to submit Personal Information. We will be entitled to process your Personal
					Information as further set out in and regulated by our Privacy Policy.
					<br /><br />
					5.2.3. You will select a Service Package, the terms of which shall read as if specifically incorporated in the
					Terms. The Service Package may be amended from time to time by following the process set out in clause 6.4
					below.
					<br /><br />
					5.3. Once registered, any Browser will no longer be construed as such and will immediately on registration
					constitute a Company as contemplated by the Terms, and the Services to the Company shall commence, subject to
					relevant payment terms.
					<br /><br />

					<app-page-header-toolbar heading="6.	SERVICES "> </app-page-header-toolbar>

					6.1. The Services rendered by Flink to the Company shall be provided in terms of the Service Package selected
					by the Company during the Registration Process.
					<br /><br />
					6.2. Flink shall not be obliged to render the Services until such time as the Company has made payment to
					Flink in terms of the first invoice, or as otherwise indicated by Flink in writing.
					<br /><br />
					6.3. Should the Company fail to make payment in terms as set out by the Service Package, at any time, Flink
					shall be entitled to refuse to render the Services to the Company. Any indulgence provided by Flink in terms
					hereof, shall not be construed as a waiver of any of Flink’s rights under the Terms.
					<br /><br />

					<app-page-header-toolbar heading="7.	SUPPORT SERVICES"> </app-page-header-toolbar>

					7.1. In the event that the Company experiences a difficulty with the Application, the Company must notify
					Flink in writing immediately, by logging a support query with Flink, the details of which are included here or
					by sending an email to INSERT EMAIL ADDRESS.
					<br /><br />
					7.2. The response times and further support procedures are set out in the Service Level Agreement.
					<br /><br />
					7.3. Once Flink has provided the Company with a working solution, the query will be considered resolved and
					Flink shall be under no obligation to provide any further solution.
					<br /><br />

					<app-page-header-toolbar heading="8.	GENERAL UNDERTAKINGS BY THE COMPANY"> </app-page-header-toolbar>

					8.1. The Company acknowledges and agrees that Flink cannot provide any Services to you unless you provide all
					the information necessary and in such a format as we may require from time to time. Accordingly, you undertake
					to provide all necessary and accurate information when requested and within the timeframe set out in the
					request, alternatively, if no such timeframe is provided in the request by Flink, within a reasonable time.
					<br /><br />
					8.2. The Company agrees to comply with all applicable laws when using the Services and you may only use the
					Services for lawful purposes. You will not in your use of the Services cause nuisance, annoyance,
					inconvenience, or property damage to Flink or a third party.
					<br /><br />
					8.3. The Company undertakes to cooperate with any reasonable and lawful request by Flink throughout the
					recruitment process.
					<br /><br />
					8.4. The Company undertakes to communicate promptly with Flink and/or the Flinker, where applicable.
					<br /><br />
					8.5. The Company agrees to advise Flink immediately of an infringement to Flink’s Intellectual Property
					Rights, copyright or Trademark.
					<br /><br />
					8.6. The Company’s login details are for personal and official use only and shall only be disclosed to such
					person(s) under the Company’s control who are required to use the Services (on a need-to-know basis) and shall
					not be disclosed to a third party. This will be controlled by adding users under the Company’s profile on the
					website.
					<br /><br />
					8.7. The Company agrees to inform Flink in writing of an alleged defect to the functionality of the
					Application immediately after any such alleged defect/s comes to the Company’s attention.
					<br /><br />
					8.8. The Company agrees to use and operate the Application in a proper and professional manner and in
					accordance with the instructions provided by Flink from time to time.
					<br /><br />
					8.9. The Company shall not cause any damage and/or duplicate, copy or develop any software or platform similar
					in nature to the Application.
					<br /><br />

					<app-page-header-toolbar heading="9.	FEES AND PAYMENT"> </app-page-header-toolbar>

					9.1. The Company shall pay the fees and costs as selected in the Service Package and as set out in the invoice
					by Flink.
					<br /><br />
					9.2. In the event that the Company selects an Ad Hoc Offering, the Company shall make the payment upon
					receiving the invoice from Flink, and thereafter they will be granted the necessary access.
					<br /><br />
					9.3. Should the Company select a Retainer Offering, the following terms shall apply:
					<br /><br />
					9.3.1. Flink shall provide an invoice on a monthly basis by no later than the last day of each month; and
					<br /><br />
					9.3.2. The Company shall make payment monthly in advance by no later than the 5th (fifth) day of each new
					month.
					<br /><br />
					9.4. All fees and costs set out in the invoice shall apply without deduction or set-off and shall be deemed to
					be exclusive of VAT unless otherwise stated. All amounts paid by the Company to Flink are non-refundable.
					<br /><br />
					9.5. The Company will be liable for all applicable taxes, government charges and foreign exchange fees.
					<br /><br />
					9.6. Flink reserves the right to apply an escalation rate to the Services in the event that the Terms are
					renewed.
					<br /><br />
					9.7. Interest shall be charged on all overdue amounts, calculated at the prime rate of interest plus 2% (two
					percent), applicable at the time of non-payment.
					<br /><br />

					<app-page-header-toolbar heading="10.	REPRESENTATIONS AND WARRANTIES BY THE COMPANY">
					</app-page-header-toolbar>

					The Company represents and warrants that:
					<br /><br />
					10.1. The information provided to us, including Personal Information, is true and accurate. Should the
					Personal Information change for any reason, you undertake to amend such information, including Personal
					Information, submitted to us as soon as possible to reflect the most updated information and Personal
					Information.
					<br /><br />
					10.2. The person or representative submitting the Registration Form on behalf of the Company is duly
					authorised to enter into the Terms and to bind the Company.
					<br /><br />
					10.3. It has conducted all internal requirements required to enter into the Terms and bind the Company
					thereto.
					<br /><br />
					10.4. Should the person or representative not be authorised to bind the Company, or should the Company not
					have conducted the necessary internal requirements as set out above, the Company nevertheless agrees that by
					using the Services, the Company has ratified any such lack of authority or failure to undertake the necessary
					internal requirements, whether actual or purported, as the case may be.
					<br /><br />
					10.5. The information provided to us does not contain any obscene, defamatory, illegal, discriminatory or
					confidential content, or any content which infringes on the intellectual property or confidentiality rights of
					any individual or organisation in any way.
					<br /><br />
					10.6. You are not falsely impersonating any other person and you are not violating any applicable law
					regarding the use of Personal Information or other relevant information.
					<br /><br />

					<app-page-header-toolbar heading="11.	WARRANTIES BY FLINK"> </app-page-header-toolbar>

					11.1. Flink does not warrant, whether expressed or implied, that:
					<br /><br />
					11.1.1. The Website or Services shall be without defect or free from any virus or other malware;
					<br /><br />
					11.1.2. The Website or Services shall be accessible at all times.
					<br /><br />
					11.2. Except for any express warranties in the Terms, the Services are provided “as is”. Flink makes no other
					warranties, express or implied, of whatsoever nature, including but not limited to warranties of title,
					fitness for a particular purpose or merchantability. We do not provide any warranties against viruses, malware
					or spyware that may be installed on your electronic device as a result of you accessing or using the Website
					or the Services.
					<br /><br />
					11.3. Flink does not warrant that the use of the Website or the Services will be uninterrupted or free from
					any error.
					<br /><br />
					11.4. Flink does not warrant that we will review any information, including Personal Information, provided to
					us for purposes of establishing the accuracy of such information. We further do not warrant that the
					processing of such information, including Personal Information and data, will not be without loss.
					<br /><br />
					11.5. Flink shall not be liable for delays, interruptions, service failures or other issues experienced in and
					during the use of internet and electronic communications or other systems outside of our reasonable control.
					While the Company may have statutory rights, the duration of any such statutorily warranties, will be limited
					to the shortest period to the extent permitted by required law.
					<br /><br />

					<app-page-header-toolbar heading="12.	BREACH"> </app-page-header-toolbar>

					12.1. Should either Party (“the Defaulting Party”) commit a material breach of the Terms, and fails to remedy
					such breach within 7 (seven) Business Days of having been called upon in writing by the other Party (“the
					Aggrieved Party”) to do so then the Aggrieved Party will be entitled, in its discretion and without prejudice
					to its rights in this Agreement, or in law to:
					<br /><br />
					12.1.1. To cancel the Terms and without further notice to claim damages from the Defaulting Party, provided
					that such breach is material (i.e., goes to the root of the Terms); or
					<br /><br />
					12.1.2. Proceed with a claim for specific performance of the Defaulting Party’s obligations, without prejudice
					to the Aggrieved Party’s right to claim damages.
					<br /><br />

					<app-page-header-toolbar heading="13.	TERMINATION"> </app-page-header-toolbar>

					13.1. Flink reserves the right to terminate the Terms or suspend the operation of the Services to the Company
					in whole or in part, at any time and at Flink’s sole discretion, by providing 30 (thirty) days’ written notice
					to the Company of such termination or suspension. The notice shall provide the extent of such termination
					and/or suspension.
					<br /><br />
					13.2. Either Party may terminate the Terms as set out in clause 12.1.1 above. In the event that the Company
					terminates the Terms in terms of clause 12.1.1, such termination may be subject to additional fees and costs
					as set out in the Service Level Agreement.
					<br /><br />
					13.3. In the event of termination of the Terms, the Company’s access to the Application and Services shall
					immediately terminate.
					<br /><br />

					<app-page-header-toolbar heading="14.	LIMITATION OF LIABILITY"> </app-page-header-toolbar>

					14.1. You hereby confirm that your visiting, viewing, accessing and usage of the Website and our Services is
					voluntary and entirely at your own risk. The Company further confirms that its use of the Application is
					voluntary and entirely at its own risk.
					<br /><br />
					14.2. The Company hereby acknowledges, understands and agrees that Flink does not warrant that the viewing,
					accessing or use of the Website will be uninterrupted or free from any error, nor do we warrant that our
					Website or Services will be free from any virus, malware or spyware. Accordingly, the Company understands and
					agrees that Flink will not be held liable for any loss arising from the viewing, accessing and use of our
					Website or Services. The Company is responsible for implementing appropriate procedures and systems to protect
					itself from such issues.
					<br /><br />
					14.3. Notwithstanding anything to the contrary which may be contained in the Terms, Flink shall not be liable
					for any direct or indirect (including consequential, punitive and special) loss, damage, loss of income,
					goodwill, costs and / or expenses of any nature whatsoever, whether in delict, contract or otherwise, which
					may arise from or in any way connected to the Website or the Services, including but not limited to the access
					and use thereof and any interruption to the Services which may occur.
					<br /><br />
					14.4. The Company hereby indemnifies Flink and holds Flink harmless in respect of any loss, damage, liability,
					costs and / or expenses of whatsoever nature which Flink may suffer or incur as a consequence of or arising
					from or attributable to any acts or omissions on the part of the Company, including without limitation any
					loss, damage, costs and / or expenses of any nature whatsoever suffered or incurred by Flink as a result of
					any claims brought against Flink, a Flinker, a third party, or yourself by any other third party. The
					Company’s liability under this indemnification shall be in addition to your liability to Flink on account of
					any breach of contract or in delict.
					<br /><br />
					14.5. All implied representations and warranties which may apply to the Company, in relation to the viewing,
					accessing and usage of the Website or Services, are herewith expressly excluded to the maximum extent
					permitted by the law.
					<br /><br />
					14.6. To the extent that our liability cannot be excluded by law, our maximum liability, whether in contract
					or delict or otherwise, to the Company will be limited to the minimum amount imposed by such law.
					<br /><br />
					14.7. Notwithstanding any provision in the Terms, any claim against Flink must be instated within 6 (six)
					months of the cause of action arising from such claim, failing which such claim shall by agreement between the
					Parties prescribe.
					<br /><br />

					<app-page-header-toolbar heading="15.	NON-SOLICITATION"> </app-page-header-toolbar>

					15.1. Neither Party shall knowingly, for the duration of the Terms and for a period of 12 (twelve) months
					after the termination of the Terms, for whatsoever reason, furnish any information or advice to anyone else
					which results in an employee, representative and/or agent of the other Party who was involved in the
					implementation or execution of the Terms to terminate his or her employment with that Party and/or any other
					contractual relationship, in order to become employed by, or directly or indirectly interested in, any concern
					which carries on business, directly or indirectly, in competition with any part, aspect or facet of the
					business conducted by the other Party.
					<br /><br />

					<app-page-header-toolbar heading="16.	INTELLECTUAL PROPERTY AND COPYRIGHT"> </app-page-header-toolbar>

					16.1. The Website, including the Application, the domain name and the content of the Website is the exclusive
					property of Flink.
					<br /><br />
					16.2. Flink shall at all times retain the right, title and interest in the Website, the Application, the
					Services, all copyright, patents, trade secrets, trademarks and other intellectual property rights, trade
					names, logos, slogans, custom graphics, button icons, scripts, videos, text, images, software, code, files,
					content, agreements, policies, information and other material available on the Website (“intellectual
					property”).
					<br /><br />
					16.3. The Company acknowledges and agrees that the intellectual property is protected by South African and
					international property and copyright laws.
					<br /><br />
					16.4. The Company shall under no circumstances copy, reproduce, replicate, redistribute, download or otherwise
					transfer the information and intellectual property from the Website or our Services, in any manner or form,
					including but not limited to electronic or mechanical means, without Flink’s prior written consent.
					<br /><br />
					16.5. The Company understands and unequivocally agree that your viewing, access and use of the Website, the
					Application and our Services will be limited to the purpose for which it is intended and that the Company will
					not use our intellectual property for any purpose other than for the use of our Services which are expressly
					and unequivocally authorised by Flink.
					<br /><br />

					<app-page-header-toolbar heading="17.	GENERAL"> </app-page-header-toolbar>

					17.1. The Parties choose as their respective domicilium citandi et executandi for the purpose of legal
					proceedings and for the purpose of giving or sending any notice provided for or necessary for the Terms:
					<br /><br />
					17.1.1. Flink: The Club Retail Center, Cnr Pinaster & 18th Avenue, Hazelwood, Pretoria, 0081
					<br /><br />
					17.1.2. Company:
					<br /><br />
					The address and email address provided to us on registration.
					<br /><br />
					17.2. All notices shall be in writing and shall be deemed to have been duly given (i) when delivered by hand,
					courier or other messenger (including registered mail), during normal business hours of the recipient party;
					or (ii) when sent by e-mail, at the time such email enters the recipient party’s mailbox.
					<br /><br />
					17.3. Nothing in the Terms shall constitute or be deemed to constitute a partnership, employment relationship
					or agency between the Company and Flink.
					<br /><br />
					17.4. Flink may assign, delegate, novate or otherwise transfer any of our rights or obligations under the
					Terms without notice to the Company and without the Company’s consent. The Company may not assign, delegate,
					novate or otherwise transfer any of its rights under the Terms.
					<br /><br />
					17.5. If at any time any provision of the Terms is or becomes invalid, illegal or unenforceable in any
					respect, that provision shall be deemed severed from the Terms, but the validity, legality and enforceability
					of the remaining provisions of the Terms shall not be affected or impaired by the severance.
					<br /><br />
					17.6. Any waiver by us of a breach of any provision of the Terms will not be deemed a waiver of any subsequent
					breach of the same or another provision.
					<br /><br />
					17.7. The Terms shall be governed by and be construed in accordance with South African law. The Company
					consents and submits to the jurisdiction of the Gauteng Local Division of the High Court of the Republic of
					South Africa, Johannesburg, in any dispute arising from or in connection with the Terms.
					<br /><br />
				</div>
			</mat-card>
		</div>
	</div>
</div>
