import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReferenceStoreEffects } from './effects';
import { referenceReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('reference', referenceReducer),
		EffectsModule.forFeature([ReferenceStoreEffects]),
	],
	providers: [ReferenceStoreEffects],
})
export class ReferenceStoreModule {}
