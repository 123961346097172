<div class="wrapper-container">
	<div class="take-on-container">
		<app-popup-notification class="front-sub-1">
			[Disclaimer] - By making use of this Website and our Services, you acknowledge that you have read, understand, and
			agree to be bound by the terms set out in this Privacy Policy, as amended from time to time. Your continued use of
			our Website and our Services also indicates that you acknowledge and agree that this Privacy Policy may be amended
			from time to time, without notice to you and agree to be bound by the amended terms.
		</app-popup-notification>
		<div class="container py-5">
			<app-page-title [title]="'Flink SA (Pty) Ltd Disclosure Consent Terms'"></app-page-title>
			<mat-card>
				<div class="front-sub-3 font-size-11">
					<app-page-header-toolbar heading="1.	INTRODUCTION"> </app-page-header-toolbar>

					1.1. In order to provide the Services, Flink SA (Pty) Ltd (hereinafter referred to as “Flink”, “we”, “our” or
					“us”) may be required to conduct Psychometric Assessments and Screening Checks (“Assessments”) on candidates
					(hereinafter referred to as “Flinkers”, “you” or “your”).
					<br /><br />
					1.2. By doing so, the Flinker will be required to disclose Personal Information to us, the third-party service
					providers conducting the Assessments and related parties.
					<br /><br />
					1.3. These Disclosure Consent Terms provide specifically for the consent required to process such information,
					including your Personal Information, for purposes relating to the Assessments. The further terms of the
					Privacy Policy must be read as if specifically incorporated into these Disclosure Consent Terms.

					<br /><br />
					<app-page-header-toolbar heading="2.	DEFINITIONS"> </app-page-header-toolbar>
					2.1. “Assessor” means MIE and INTEG, collectively or individually as the context may indicate.
					<br /><br />
					2.2. “Assessment” means background Screening Checks and Psychometric Assessments, either collectively or
					individually as the context may indicate.
					<br /><br />
					2.3. “Consumer Credit Information” shall have the meaning ascribed to it in section 70 of the NCA.
					<br /><br />
					2.4. “Company” means an entity, organisation or person who has registered with Flink for the purpose of using
					the Services.
					<br /><br />
					2.5. “Data Protection Legislation” means POPI and the GDPR.
					<br /><br />
					2.6. “FAIS Act” means the Financial Advisory Intermediary Services Act of 2002.
					<br /><br />
					2.7. “Flink” means Flink SA (Pty) Ltd and its lawful successors, assigns and affiliates, as the case may be.
					<br /><br />
					2.8. “Flinker” means the person who uses the Services to secure possible recruitment positions with Companies,
					and hereby provides the necessary consents as set out herein.
					<br /><br />
					2.9. “FSCA” means the Financial Sector Conduct Authority.
					<br /><br />
					2.10. “GDPR” means the General Data Protection Regulation 2016/679.
					<br /><br />
					2.11. “Industry-Specific Screening Checks” means collectively or individually as the context may indicate; any
					other screening verification offered by MIE which does not form part of the Standard Screening Checks offered
					by Flink, which may be required by a Company from time to time. These checks include (this list is not
					exhaustive); Adverse Financial History, Criminal, Deceased Status Verification, Deeds Search, Directorship
					Information, Employment History, Fraud Listing, Identity Checks, Insurance Regulation, License Checks, Marital
					Trace, PERSAL Public Sector Employment Status, PSIRA Verification, Qualification, Retail, Sanctions, Screening
					History Overview, Social Media, Vehicle Owners, Vehicle Registration, Voter Registration Status.
					<br /><br />
					2.12. “INTEG” means Integrity International (Pty) Ltd and its legal successors or assigns, the Assessor that
					is hereby authorised by the Flinker to conduct the Psychometric Assessments on the Flinker, for and on behalf
					of Flink.
					<br /><br />
					2.13. “MIE” means Managed Integrity Evaluation (Pty) Ltd and its legal successors or assigns, the Assessor
					that is hereby authorised by the Flinker to conduct Screening Checks on the Flinker, for and on behalf of
					Flink.
					<br /><br />
					2.14. “NCA” means the National Credit Act 34 of 2005, as amended from time to time, including any regulations
					made under the Act.
					<br /><br />
					2.15. “Personal Information” shall have the meaning ascribed to it in Chapter 1 of POPI and Article 4(1) of
					the GDPR and includes, but is not limited to a name, address, email address, telephone or fax number,
					fingerprints, criminal history and education or other personal credentials provided, or which is collected
					from the Company, the Flinker or other third parties, before and/or during the Assessments and/or thereafter.
					<br /><br />
					2.16. “POPI” means the Protection of Personal Information Act 4 of 2013, as amended from time to time,
					including any regulations made under the Act.
					<br /><br />
					2.17. “Privacy and Data Protection Conditions” means the 8 (eight) statutorily prescribed conditions for the
					lawful Processing of Personal Information as set out in POPI.
					<br /><br />
					2.18. “Psychometric Assessment” means the tests taken by the Flinker designed to measure the Flinker’s
					suitability for an employment or related role based on required personality characteristics and aptitude.
					<br /><br />
					2.19. “Screening Checks” means, collectively or individually as the context may indicate; Standard Screening
					Checks and Industry-Specific Screening Checks.
					<br /><br />
					2.20. “Services” means the streamlined recruitment and related services provided by Flink to the Flinker and
					the Company.
					<br /><br />
					2.21. “Standard Screening Checks” means collectively or individually as the context may indicate; ID
					verification, Matric verification, Highest Qualification verification and Employment Reference Verification.
					<br /><br />
					2.22. “Verification Information Suppliers” means third parties acting on behalf of the Assessors, including
					but not limited to criminal record bureaus, credit bureaus, governmental bodies, and any educational,
					training, and fraud prevention organisations, as the case may be.

					<br /><br />

					<app-page-header-toolbar heading="3.	ACCEPTANCE OF TERMS AND CONSENT"> </app-page-header-toolbar>

					3.1. These Disclosure Consent Terms constitute a binding agreement between Flink and you as the Flinker in
					terms of section 11(3) of the Electronic Communications and Transactions Act 25 of 2002. Such agreement shall
					be construed to have been concluded in Johannesburg, South Africa.
					<br /><br />
					3.2. By signing up and registering for our Services, you acknowledge that you have read through, understand,
					and agree to be bound by these Disclosure Consent Terms. You further acknowledge that such conduct constitutes
					a reasonable unconditional, specific and voluntary consent that such Screening Checks and Psychometric
					Assessments may be conducted by third party service providers at any time during the use of our Services
					following registration.
					<br /><br />
					3.3. You hereby specifically consent and authorise MIE and INTEG to access your Personal Information and
					conduct any such background Screening Checks and Psychometric Assessments which may be required to fulfil our
					Services.
					<br /><br />
					3.4. Notwithstanding the generality above:
					<br /><br />
					3.4.1. You understand that Standard Screening Checks will be conducted to fulfil our Services; and
					<br /><br />
					3.4.2. You specifically consent to any Industry-Specific Screening Checks which a Company may require from
					time to time.
					<br /><br />
					3.5. For purposes of the Psychometric Assessments, you understand that you must complete the Psychometric
					Assessment yourself and without any help or assistance from a third party, and agree that all answers provided
					will be your own.
					<br /><br />
					3.6. For purposes of the Screening Checks, you consent to requests for consumer credit information to be
					released for the below prescribed purposes:
					<br /><br />
					3.6.1. For employment in a position of trust and honesty; and
					<br /><br />
					3.6.2. Fraud prevention or detection.
					<br /><br />
					3.7. You understand and agree that verification requests may form part of the Assessments and:
					<br /><br />
					3.7.1. You provide us and the Assessors with the necessary authority to conduct such verification processes;
					<br /><br />
					3.7.2. That requests for credit information from Credit Bureaus will only be conducted under the regulations
					defined in the NCA; and
					<br /><br />
					3.7.3. Data obtained from the FSCA serve only for the purpose to determine the fitness and propriety as
					envisaged in the FAIS Act.
					<br /><br />
					3.8. You understand and agree that the Assessments do not infringe on any of your fundamental rights.
					<br /><br />
					3.9. You undertake to cooperate with us to ensure that the background Screening Checks and Psychometric
					Assessments are completed swiftly.
					<br /><br />
					3.10. Should you not wish to proceed with the background Screening Checks and / or Psychometric Assessments,
					you agree that we may terminate your use of our Services with immediate effect.

					<br /><br />

					<app-page-header-toolbar heading="4.	REPRESENTATIONS AND WARRANTIES"> </app-page-header-toolbar>

					4.1. By providing your Personal Information, you acknowledge that any Personal Information supplied to us or
					the Assessors is provided voluntarily and that we may not be in a position to comply with our obligations if
					you do not supply the correct Personal Information to us.
					<br /><br />
					4.2. You understand that privacy is important and that we will use reasonable efforts in order to ensure that
					any Personal Information in our possession or processed on our behalf is kept confidential, stored in a secure
					manner and processed in terms of the applicable Data Protection Legislation, for the purposes you have
					authorised.
					<br /><br />
					4.3. You hereby warrant that all information, including Personal Information, supplied to us is accurate,
					current and complete and agree to correct and update such information as soon as reasonably possible.

					<app-page-header-toolbar heading="5.	PROCESSING OF PERSONAL INFORMATION PROVIDED TO ASSESSORS">
					</app-page-header-toolbar>

					5.1. By submitting any Personal Information to us in any form you acknowledge that such conduct constitutes a
					reasonable unconditional, specific and voluntary consent to the processing of such Personal Information in the
					following manner by the us, the Assessors and any Verification Information Suppliers:
					<br /><br />
					5.1.1. Personal Information may be shared by us with the Assessors and may be further shared by the Assessors
					with the Verification Information Suppliers for verification or other legitimate purposes;
					<br /><br />
					5.1.2. Personal Information may be shared by the Verification Information Suppliers with the Assessors and be
					further stored and shared by the Assessors with us.
					<br /><br />
					5.1.3. Personal Information may be shared by the Verification Information Suppliers, the Assessors and us with
					any fraud databases or services when the information provided for verification is deemed fraudulent by the
					Verification Information Suppliers for purposes of continued or future employment or for other legitimate
					purposes as set out in the NCA or any other National or Provincial Legislation.
					<br /><br />
					5.1.4. Personal Information may be stored by the Assessors, for legislated retention periods and where such
					periods are not legislated then your personal information may be stored by the Assessors for as long as you
					may need my information to be verified, or any other period as you may expressly agree with the Assessors.
					<br /><br />
					5.1.5. Personal Information may be transferred cross-border to countries, for verification or storage
					purposes. In any cross-border transfer of Personal Information, the Assessors or Verification Information
					Suppliers will comply with the security safeguards as provided for in the POPI including but not limited to
					ensuring that the information is secured when transported to or from the recipient.

					<br /><br />

					<app-page-header-toolbar heading="6.	LIABILITIES AND INDEMNITIES"> </app-page-header-toolbar>

					6.1. You hereby agree that Flink or its legal successors, assigns and affiliates, shall not be liable for any
					loss, damage, claim, complaint, cost or action brought by you or a third party, whether direct or indirect,
					arising from any conduct by Flink, to the extent that such conduct resulted in Flink complying with the terms
					of these Disclosure Consent Terms and shall indemnify, hold harmless and defend Flink from and against any
					such claims or actions brought against Flink or its legal successors, assigns and affiliates.
					<br /><br />
					6.2. You hereby agree that the Assessors, individually and collectively, or their legal successors, assigns
					and affiliates, shall not be liable for any loss, damage, claim, complaint, cost or action brought by you or a
					third party, whether direct or indirect, arising from any conduct by the Assessors to the extent that such
					conduct resulted in the Assessors complying with the terms of these Disclosure Consent Terms and shall
					indemnify, hold harmless and defend the Assessors, individually and collectively, from and against any such
					claims or actions brought against each of them or their legal successors, assigns and affiliates.

					<br /><br />

					<app-page-header-toolbar heading="7.	CHANGES TO THESE DISCLSOURE CONSENT TERMS"> </app-page-header-toolbar>

					7.1. We will notify you of any material changes to these Disclosure Consent Terms.
				</div>
			</mat-card>
		</div>
	</div>
</div>
