import { Action } from '@ngrx/store';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { PsychAssessmentCompletionStatusResponse } from '../../models/dto/responses/psych-assessment-completion-status-response.dtp';

export enum ActionTypes {
	GET_PSYCH_ASSESSMENT_COMPLETION_STATUS = '[PsychAssessmentCompletionStatus] Get psych assessment completion status',
	GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_SUCCESS = '[PsychAssessmentCompletionStatus] Get psych assessment completion status success',
	GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_ERROR = '[PsychAssessmentCompletionStatus] Get psych assessment completion status error',
}

export class GetPsychAssessmentCompletionStatus implements Action {
	readonly type = ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS;
	constructor() {}
}

export class GetPsychAssessmentCompletionStatusSuccess implements Action {
	readonly type = ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_SUCCESS;
	constructor(public payload: { result: PsychAssessmentCompletionStatusResponse }) {}
}

export class GetPsychAssessmentCompletionStatusError implements Action {
	readonly type = ActionTypes.GET_PSYCH_ASSESSMENT_COMPLETION_STATUS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| GetPsychAssessmentCompletionStatus
	| GetPsychAssessmentCompletionStatusSuccess
	| GetPsychAssessmentCompletionStatusError;
