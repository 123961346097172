import { createFeatureSelector, createSelector } from '@ngrx/store';
import FlinkCoResponse from '@flinkadmin-dashboard/models/response/flink-co-admin-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import HttpPackage from '@models/http-package';
import { FlinkCoAdminState } from './state';
import SearchResponse from '@models/dto/responses/search-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import AdminSearchResponse from '@flinkadmin-dashboard/models/response/admin-search-response.dto';
import AdminSearchResultResponseDto from '@flinkadmin-dashboard/models/response/admin-search-result-response.dto';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';

const getFetchAllFlinkCosHttpPackage = (state: FlinkCoAdminState): HttpPackage<PaginatedResponse<FlinkCoResponse>> =>
	state.fetchAllFlinkCosHttpPackage;

const getFetchAllQuotesHttpPackage = (state: FlinkCoAdminState): HttpPackage<PaginatedResponse<FlinkCoResponse>> =>
	state.fetchAllQuotesHttpPackage;

const getUpdateFlinkCoHttpPackage = (state: FlinkCoAdminState): HttpPackage<string> => state.updateFlinkCoHttpPackage;

const cancelRetainerSubcriptionHttpPackage = (state: FlinkCoAdminState): HttpPackage<string> =>
	state.cancelRetainerSubscriptionHttpPackage;
const deleteFlinkCoHttpPackage = (state: FlinkCoAdminState): HttpPackage<any> => state.deleteFlinkCoHttpPackage;

const getLoginAsFlinkCoPackage = (state: FlinkCoAdminState): HttpPackage<SignInResponse> => state.loginAsFlinkCoPackage;

const getSelectedFlinkCoPackage = (state: FlinkCoAdminState): HttpPackage<FlinkCoResponse> =>
	state.getSelectedFlinkCoPackage;

const getFlinkCoActiveSearchesHttpPackage = (state: FlinkCoAdminState): HttpPackage<SearchResponse[]> =>
	state.fetchFlinkCoSearchesHttpPackage;

const getFetchActiveSearchHttpPackage = (state: FlinkCoAdminState): HttpPackage<AdminSearchResponse> =>
	state.fetchActiveSearchHttpPackage;

const getSearchResultHttpPackage = (state: FlinkCoAdminState): HttpPackage<AdminSearchResultResponseDto> =>
	state.getActiveSearchResultsPackage;

const getAddFlinkerFromSearchResultsHttpPackage = (
	state: FlinkCoAdminState,
): HttpPackage<AdminMatchedFlinkerResponse> => state.addFlinkerToSearchResultsPackage;

const getRemoveFlinkerFromSearchResultsHttpPackage = (
	state: FlinkCoAdminState,
): HttpPackage<AdminMatchedFlinkerResponse> => state.removeFlinkerFromSearchResultsPackage;

export const selectFlinkCoAdminState = createFeatureSelector<FlinkCoAdminState>('flinkco-admin');

export const selectFetchAllFlinkCosHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getFetchAllFlinkCosHttpPackage,
);

export const selectFetchAllQuotesHttpPackage = createSelector(selectFlinkCoAdminState, getFetchAllQuotesHttpPackage);

export const selectUpdateFlinkCoHttpPackage = createSelector(selectFlinkCoAdminState, getUpdateFlinkCoHttpPackage);

export const selectCancelRetainerSubscriptionHttpPackage = createSelector(
	selectFlinkCoAdminState,
	cancelRetainerSubcriptionHttpPackage,
);

export const selectDeleteFlinkCo = createSelector(selectFlinkCoAdminState, deleteFlinkCoHttpPackage);

export const selectLoginAsFlinkCoPackage = createSelector(selectFlinkCoAdminState, getLoginAsFlinkCoPackage);

export const selectGetSelectedFlinkCo = createSelector(selectFlinkCoAdminState, getSelectedFlinkCoPackage);

export const selectFetchFlinkCoSearchesHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getFlinkCoActiveSearchesHttpPackage,
);

export const selectGetFlinkCoActiveSearchHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getFetchActiveSearchHttpPackage,
);

export const selectGetActiveSearchResultHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getSearchResultHttpPackage,
);

export const selectAddFlinkerToSearchResultsHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getAddFlinkerFromSearchResultsHttpPackage,
);

export const selectRemoveFlinkerFromSearchResultsHttpPackage = createSelector(
	selectFlinkCoAdminState,
	getRemoveFlinkerFromSearchResultsHttpPackage,
);
