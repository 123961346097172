import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-label-button',
	templateUrl: './label-button.component.html',
	styleUrls: ['./label-button.component.scss'],
})
export class LabelButtonComponent implements OnInit {
	@Input() theme = 'm1 fs1';
	@Input() icon = 'add';
	@Input() iconTheme = 'large font-extra-bold';
	@Input() buttonText = '';
	@Input() reverseOrder = false;

	constructor() {}

	ngOnInit(): void {}
}
