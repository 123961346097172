import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { GenericOptionSelect } from '@models/generic-option.model';
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-outlined-multi-select-list',
	templateUrl: './outlined-multi-select-list.component.html',
	styleUrls: ['./outlined-multi-select-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedMultiSelectListComponent),
			multi: true,
		},
	],
})
export class OutlinedMultiSelectListComponent implements OnInit {
	@Input() labelText = '';
	@Input() formControlName = '';
	@Input() errorMessage = '';
	@Input() formValue = '';
	@Input() options: GenericOptionSelect[] = [];
	@Input() hint = '';
	@Input() toolTip = '';
	@Input() showTip = false;
	@Input() theme = '';

	value: string[];
	required = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	@Output() itemsCheckedChange = new EventEmitter();
	constructor(private readonly controlContainer: ControlContainer) {}

	ngOnInit(): void {
		this.value = this.options.filter((option) => option.checked).map((option) => option.value);
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: Array<string>): void {
		this.value = value;
		this.options = this.options.map((option) => ({ ...option, checked: this.value.indexOf(option.value) !== -1 }));
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur(): void {
		this.onTouched();
	}

	onItemChecked(item: GenericOptionSelect, checked: boolean): void {
		if (checked) {
			this.value = [...this.value, item.value];
		} else {
			this.value = [...this.value.filter((itemSelected) => itemSelected !== item.value)];
		}
		this.onChange(this.value);
		this.itemsCheckedChange.emit(this.value);
	}
}
