import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewFlinkcoJobAdvertsComponent } from './view-flinkco-job-adverts/view-flinkco-job-adverts.component';
import { CareersRoutingModule } from '@app/careers/careers-routing.module';
import { SharedModule } from '@shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewFlinkcoJobAdvertComponent } from './view-flinkco-job-advert/view-flinkco-job-advert.component';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from 'ngx-avatar';
import { MatCardModule } from '@angular/material/card';

@NgModule({
	declarations: [ViewFlinkcoJobAdvertsComponent, ViewFlinkcoJobAdvertComponent],
	imports: [
		CommonModule,
		CareersRoutingModule,
		SharedModule,
		MatPaginatorModule,
		ReactiveFormsModule,
		MatIconModule,
		AvatarModule,
		MatCardModule,
	],
})
export class CareersModule {}
