import { Action } from '@ngrx/store';
import ActiveSubscription from '../../models/dto/responses/active-Subscription-response.dto';
import CostResponse from '../../models/dto/responses/cost-response.dto';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import QuoteResponse from '../../models/dto/responses/quote-response.dto';

export enum ActionTypes {
	GET_RETAINER_QUOTE = '[Quote] Get retainer quote',
	GET_RETAINER_QUOTE_SUCCESS = '[Quote] Get retainer quote success',
	GET_RETAINER_QUOTE_ERROR = '[Quote] Get retainer quote error',

	GET_AD_HOC_QUOTE = '[Quote] Get ad hoc quote',
	GET_AD_HOC_QUOTE_SUCCESS = '[Quote] Get ad hoc quote success',
	GET_AD_HOC_QUOTE_ERROR = '[Quote] Get ad hoc quote error',

	GET_ACTIVE_QUOTE = '[Quote] Get active quote',
	GET_ACTIVE_QUOTE_SUCCESS = '[Quote] Get active quote success',
	GET_ACTIVE_QUOTE_ERROR = '[Quote] Get active quote error',

	GET_ACTIVE_SUBSCRIPTION = '[Quote] Get active subscription',
	GET_ACTIVE_SUBSCRIPTION_SUCCESS = '[Quote] Get active subscription success',
	GET_ACTIVE_SUBSCRIPTION_ERROR = '[Quote] Get active subscription error',
}

export class GetRetainerQuote implements Action {
	readonly type = ActionTypes.GET_RETAINER_QUOTE;
	constructor() {}
}

export class GetRetainerQuoteSuccess implements Action {
	readonly type = ActionTypes.GET_RETAINER_QUOTE_SUCCESS;
	constructor(public payload: { result: CostResponse }) {}
}

export class GetRetainerQuoteError implements Action {
	readonly type = ActionTypes.GET_RETAINER_QUOTE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetAdHocQuote implements Action {
	readonly type = ActionTypes.GET_AD_HOC_QUOTE;
	constructor() {}
}

export class GetAdHocQuoteSuccess implements Action {
	readonly type = ActionTypes.GET_AD_HOC_QUOTE_SUCCESS;
	constructor(public payload: { result: CostResponse }) {}
}

export class GetAdHocQuoteError implements Action {
	readonly type = ActionTypes.GET_AD_HOC_QUOTE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetActiveQuote implements Action {
	readonly type = ActionTypes.GET_ACTIVE_QUOTE;
	constructor() {}
}

export class GetActiveQuoteSuccess implements Action {
	readonly type = ActionTypes.GET_ACTIVE_QUOTE_SUCCESS;
	constructor(public payload: { result: QuoteResponse }) {}
}

export class GetActiveQuoteError implements Action {
	readonly type = ActionTypes.GET_ACTIVE_QUOTE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetActiveSubscription implements Action {
	readonly type = ActionTypes.GET_ACTIVE_SUBSCRIPTION;
	constructor() {}
}

export class GetActiveSubscriptionSuccess implements Action {
	readonly type = ActionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: ActiveSubscription }) {}
}

export class GetActiveSubscriptionError implements Action {
	readonly type = ActionTypes.GET_ACTIVE_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| GetRetainerQuote
	| GetRetainerQuoteSuccess
	| GetRetainerQuoteError
	| GetAdHocQuote
	| GetAdHocQuoteSuccess
	| GetAdHocQuoteError
	| GetActiveQuote
	| GetActiveQuoteSuccess
	| GetActiveQuoteError
	| GetActiveSubscription
	| GetActiveSubscriptionSuccess
	| GetActiveSubscriptionError;
