<label class="d-flex" [ngClass]="{ required: required }">{{ labelText }}</label>
<input type="file" (input)="input($event)" [accept]="validExtensions" hidden #fileUpload (change)="onFileSelected($event)" />
<app-square-button
	[theme]="getTheme()"
	type="submit"
	[value]="file ? file.name : uploaded ? 'Re-upload' : buttonInput"
	class="mb-2 w-100"
	(click)="fileUpload.click()"
	[tooltip]="toolTip"
>
</app-square-button>
<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
