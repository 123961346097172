import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { MatDialog } from '@angular/material/dialog';
import { CvPurchaseModalComponent } from '@flinker-dashboard/components/profile/cv-purchase-modal/cv-purchase-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoleAuthService } from '@app/services/auth/role-auth.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	private destroy$: Subject<void> = new Subject<void>();
	constructor(
		private store: Store<RootStoreState.State>,
		public dialog: MatDialog,
		private readonly roleAuthService: RoleAuthService,
	) {}

	ngOnInit(): void {
		this.flinker$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
			this.flinker = result;
		});

		if (
			this.flinker.psychAssessmentResultId &&
			this.flinker.takeOnComplete &&
			!this.flinker.cvPromotion &&
			!this.roleAuthService.getIsAdminLoggedInAsUser()
		) {
			this.dialog.open(CvPurchaseModalComponent);
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
