<div class="p-1 p-md-4">
	<div class="row justify-content-center">
		<div class="d-flex w-100">
			<span *ngIf="!pending">
				Your profile is currently disabled due to your recent placement through our platform. Should you want to join
				the race again just let us know below
			</span>
			<span *ngIf="pending" class="text-center">
				Your profile is currently disabled due to your recent placement through our platform. We are evaluating your
				reason for reactivation and will get back to you soon.
			</span>
		</div>
	</div>
	<div class="row pt-3" *ngIf="!pending">
		<form [formGroup]="form" class="col-12 px-0">
			<app-outlined-dropdown
				class="pt-2 col-12 col-md-6 px-0"
				labelText="Reactivation reason"
				[options]="(selectOptionsHttpPackage$ | async)?.result?.reactivationReasons"
				[errorMessage]="getErrorMessage(form.controls.reactivationReason, 'Reactivation reason', shouldValidate)"
				formControlName="reactivationReason"
			>
			</app-outlined-dropdown>
			<app-outlined-input
				class="pt-2 col-12 col-md-6 px-0"
				labelText="Other reason"
				formControlName="otherReactivationReason"
				[errorMessage]="
					getErrorMessage(form.controls.otherReactivationReason, 'Other reactivation reason', shouldValidate)
				"
				*ngIf="form.controls.reactivationReason.value == 'OTH'"
			>
			</app-outlined-input>
		</form>
	</div>
	<div *ngIf="!pending">
		<div class="d-flex justify-content-center mt-4 pb-3">
			<app-square-button theme="m1" value="Find my dream job" type="button" (click)="submit()"> </app-square-button>
		</div>
	</div>
</div>
