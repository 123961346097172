<div class="container mw-100 min-vh-100 p-1 p-md-5">
	<mat-card class="mb-5">
    <ng-container *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result as interview">
      <app-page-header-toolbar
        icon="question_answer"
        [heading]="
          'Interview for ' +
          (interview?.search ? getJobTitle(
            interview?.search?.jobTitleId,
            interview?.search.otherJobTitle
           ) : '')
        "
      >
        <app-highlighted-text
          [theme]="getInterviewStatusColour(interview?.status)"
          class="mt-2 mr-3 mr-md-0 d-none d-md-block"
        >
          {{ interview?.status | interviewStatusName:true }}
        </app-highlighted-text>
      </app-page-header-toolbar>
    </ng-container>

    <section *ngIf="(getFlinkerInterviewHttpPackage$ | async).loading">
			<div class="row px-5">
				<div class="col-12 col-sm-6">
					<ngx-skeleton-loader count="2" appearance="line" [theme]="{ height: '50px', 'border-radius': '12px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-6">
					<ngx-skeleton-loader count="2" appearance="line" [theme]="{ height: '50px', 'border-radius': '12px' }">
					</ngx-skeleton-loader>
				</div>
			</div>
			<div class="row px-5">
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
			</div>
		</section>

		<div class="container mw-100 px-1 px-md-5" *ngIf="!(getFlinkerInterviewHttpPackage$ | async).loading">
			<div class="row">
				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> Start date </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">event</mat-icon>
							{{
								(getFlinkerInterviewHttpPackage$ | async)?.result?.startDate
									? ((getFlinkerInterviewHttpPackage$ | async)?.result?.startDate | dateFormat: 'LLLL')
									: '-'
							}}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> End date </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">event</mat-icon>
							{{
								(getFlinkerInterviewHttpPackage$ | async)?.result?.endDate
									? ((getFlinkerInterviewHttpPackage$ | async)?.result?.endDate | dateFormat: 'LLLL')
									: '-'
							}}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> Interview space </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">apartment</mat-icon>
							{{
								(getFlinkerInterviewHttpPackage$ | async)?.result?.meetingUrl?.length > 0
									? 'Online'
									: (getFlinkerInterviewHttpPackage$ | async)?.result?.location?.length
									? 'In Person'
									: '-'
							}}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2" *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.location">
					<span class="d-block font-light font-size-10"> Location </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">location_on</mat-icon>
							{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.location }}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2" *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.meetingUrl">
					<span class="d-block font-light font-size-10"> Meeting Link </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">link</mat-icon>
							{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.meetingUrl }}
						</span>
					</div>
				</div>
			</div>
		</div>

		<div
			class="row px-1 px-md-5 mt-3 mb-5"
			*ngIf="
				!(getFlinkerInterviewHttpPackage$ | async).loading &&
				((getFlinkerInterviewHttpPackage$ | async).result?.endDate === null ||
					moment((getFlinkerInterviewHttpPackage$ | async).result?.endDate).isAfter(moment())) &&
				(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.Cancelled &&
				(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.Expired &&
				(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.VerificationFailed &&
				(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.VerificationPending
			"
		>
			<app-square-button
				matStepperNext
				theme="m5"
				value="Reject Interview"
				type="button"
				class="col-12 col-md-4 mt-2 mt-md-0"
				*ngIf="
					(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.Rejected &&
					(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.VerificationFailed
				"
				(click)="rejectInterview()"
			>
			</app-square-button>
			<app-square-button
				matStepperNext
				theme="m10"
				value="Request a different time"
				type="button"
				class="col-12 col-md-4 mt-2 mt-md-0"
				(click)="requestReschedule()"
				*ngIf="
					(getFlinkerInterviewHttpPackage$ | async).result?.endDate &&
					(getFlinkerInterviewHttpPackage$ | async).result?.status !== InterviewStatus.VerificationFailed
				"
			>
			</app-square-button>
			<app-square-button
				matStepperNext
				theme="m2"
				value="Accept interview"
				type="button"
				class="col-12 col-md-4 mt-2 mt-md-0"
				*ngIf="showAcceptInterview((getFlinkerInterviewHttpPackage$ | async).result?.status)"
				(click)="acceptInterview()"
			>
			</app-square-button>
		</div>

		<app-page-header-toolbar icon="work" heading="About the Position"> </app-page-header-toolbar>

		<div class="row" *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.loading">
			<div class="col-12 col-md-3 col-lg-4 col-xl-3 px-4 d-flex flex-column align-items-center">
				<ngx-skeleton-loader
					class="m-3"
					count="1"
					appearance="line"
					[theme]="{ 'border-radius': '10px', width: '100px', height: '100px', margin: '0px' }"
				>
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '150px', height: '20px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="my-4" count="1" appearance="line" [theme]="{ width: '130px', height: '20px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="5" appearance="line" [theme]="{ height: '20px' }">
				</ngx-skeleton-loader>
			</div>
			<div class="col-12 col-md-9 col-lg-8 col-xl-9 my-3 pr-3 pr-md-5">
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="2" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="2" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="1" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
			</div>
		</div>

		<div class="row" *ngIf="!(getFlinkerInterviewHttpPackage$ | async)?.loading">
			<div class="col-12 col-md-3 col-lg-4 col-xl-3 px-4">
				<div class="mb-3 text-center">
					<app-user-picture
						theme="m-2"
						[size]="120"
						[src]="(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.logo"
						[alt]="(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.registeredName"
					>
					</app-user-picture>

					<div class="d-block front-sub-3 font-size-9 font-bold">
						{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.registeredName }}
					</div>
					<app-highlighted-text theme="faded1" class="d-flex mt-1"
						>{{
							getSelectOptionNameById(
								(selectOptionsHttpPackage$ | async)?.result?.industries,
								(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.industryId
							)
						}}
					</app-highlighted-text>
				</div>
				<div class="pl-2">
					<div class="d-flex details-underline py-2">
						<app-bold-label label="Contact person:" class="col-6"></app-bold-label>
						<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon">
							{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.name }}
							{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.surname }}
						</span>
					</div>
					<div class="d-flex details-underline py-2">
						<app-bold-label label="Location:" class="col-6"></app-bold-label>
						<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon">
							{{
								getSelectOptionNameById(
									(selectOptionsHttpPackage$ | async)?.result?.cities,
									(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.cityId
								)
							}},
							{{
								getSelectOptionNameById(
									(selectOptionsHttpPackage$ | async)?.result?.provinces,
									(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.provinceId
								)
							}}
						</span>
					</div>
					<div
						class="d-flex details-underline py-2"
						*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.website !== ''"
					>
						<app-bold-label label="Website:" class="col-6"></app-bold-label>
						<a
							class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon"
							[href]="websitelink"
							[href]="
								pattern.test((getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.website)
									? (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.website
									: '//' + (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.website
							"
							target="_blank"
						>
							{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.flinkCo.website }}
						</a>
					</div>
				</div>
			</div>

			<div class="col-12 col-md-9 col-lg-8 col-xl-9 my-3 pr-3 pr-md-5">
				<div>
					<span class="font-bold font-size-8 pb-3 d-flex"> Description of Duties </span>
					<p class="font-size-12 text-justify front-sub-5">
						{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.dutiesDescription }}
					</p>
				</div>

				<div>
					<span class="font-bold font-size-8 pt-4 pb-2 d-flex"> Basic Requirements </span>
					<div class="row py-4 details-underline">
						<div class="col-12 col-sm-6 d-block py-2">
							<span class="d-block font-light font-size-10"> {{dictionaryWords.Salary}} </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">sell</mat-icon>
									R{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.salaryBandLower }} - R{{
										(getFlinkerInterviewHttpPackage$ | async)?.result?.search.salaryBandUpper
									}}
									pm
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.driversLicense"
						>
							<span class="d-block font-light font-size-10"> Drivers license </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">time_to_leave</mat-icon>
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.driversLicense }}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.canRelocate"
						>
							<span class="d-block font-light font-size-10"> Should be willing to relocate </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">near_me</mat-icon>
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.canRelocate ? 'Yes' : 'No' }}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.genderId"
						>
							<span class="d-block font-light font-size-10"> Gender </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">person</mat-icon>
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.genders,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.genderId
										)
									}}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.workspacePreferenceId"
						>
							<span class="d-block font-light font-size-10"> Work space preference </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">apartment</mat-icon>
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.workspaces,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.workspacePreferenceId
										)
									}}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.southAfricanCitizen"
						>
							<span class="d-block font-light font-size-10"> Should be a South African citizen</span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">assignment_ind</mat-icon>
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.southAfricanCitizen ? 'Yes' : 'No' }}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div>
					<span class="font-bold pt-4 font-size-8 pb-2 d-flex"> Work experience required </span>
					<div class="row py-4 details-underline">
						<div class="col-12 col-sm-6 d-block py-2">
							<span class="d-block font-light font-size-10">
								In
								{{
									getSelectOptionNameById(
										(selectOptionsHttpPackage$ | async)?.result?.industries,
										(getFlinkerInterviewHttpPackage$ | async)?.result?.search.industryId
									)
								}}
							</span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">hourglass_full</mat-icon>
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.industryYearsOfExperienceLower }} -
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.industryYearsOfExperienceUpper }} years
								</span>
							</div>
						</div>

						<div class="col-12 col-sm-6 d-block py-2">
							<span class="d-block font-light font-size-10"> In Total </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">hourglass_full</mat-icon>
									{{ (getFlinkerInterviewHttpPackage$ | async)?.result?.search.totalYearsOfExperience }} years
								</span>
							</div>
						</div>
					</div>
				</div>

				<div
					*ngIf="
						(getFlinkerInterviewHttpPackage$ | async)?.result?.search.matricCertificateRequired ||
						(getFlinkerInterviewHttpPackage$ | async)?.result?.search?.highestQualificationCategory?.length > 0 ||
						(getFlinkerInterviewHttpPackage$ | async)?.result?.search.additionalQualificationNameId
					"
				>
					<span class="font-bold font-size-8 pt-4 pb-2 d-flex"> Education/qualification requirements </span>
					<div class="row py-4 details-underline">
						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.matricCertificateRequired !== null"
						>
							<span class="d-block font-light font-size-10"> Matric certificate required</span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">school</mat-icon>
									{{
										(getFlinkerInterviewHttpPackage$ | async)?.result?.search.matricCertificateRequired ? 'Yes' : 'No'
									}}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search?.highestQualificationCategory?.length > 0"
						>
							<span class="d-block font-light font-size-10"> Minimum qualification required </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">school</mat-icon>
									{{
                    (getFlinkerInterviewHttpPackage$ | async)?.result?.search?.highestQualificationCategory[0] | selectOptionNameByCode:(selectOptionsHttpPackage$ | async)?.result?.qualificationCategories
                  }}
								</span>
							</div>
						</div>

						<div
							class="col-12 col-sm-6 d-block py-2"
							*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.additionalQualificationNameId"
						>
							<span class="d-block font-light font-size-10"> Additional qualification </span>
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium center-icon">
									<mat-icon class="front-main-1 font-size-11 center-icon pr-2">school</mat-icon>
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.qualificationNames,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.additionalQualificationNameId
										)
									}}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div>
					<span class="font-bold font-size-8 pt-4 pb-2 d-flex"> Language requirements </span>
					<div class="d-flex details-underline">
						<div class="w-100 py-4">
							<mat-chip-list>
								<mat-chip *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.languageProficiency1Id">
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.languages,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.languageProficiency1Id
										)
									}}
								</mat-chip>
								<mat-chip *ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.languageProficiency2Id">
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.languages,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.languageProficiency2Id
										)
									}}
								</mat-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>

				<div
					*ngIf="
						(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration1Id ||
						(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration2Id
					"
				>
					<span class="font-bold font-size-8 pt-4 pb-2 d-flex"> Professional Body of Registration </span>
					<div class="d-flex details-underline">
						<div class="w-100 py-4">
							<mat-chip-list>
								<mat-chip
									*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration1Id"
								>
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.professionalBodies,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration1Id
										)
									}}
								</mat-chip>
								<mat-chip
									*ngIf="(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration2Id"
								>
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.professionalBodies,
											(getFlinkerInterviewHttpPackage$ | async)?.result?.search.professionalBodyRegistration2Id
										)
									}}
								</mat-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
</div>
