import { Injectable } from '@angular/core';
import CreateOfferRequest from '../../models/dto/requests/create-offer-request.dto';
import OfferStatusRequest from '../../models/dto/requests/offer-status-request.dto';
import OfferResponse from '../../models/dto/responses/offer-response.dto';
import UpdateOfferRequest from '../../models/dto/requests/update-offer-request.dto';
import FlinkcoCancelOfferDto from '../../models/dto/requests/flinkco-cancel-offer.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

@Injectable({
	providedIn: 'root',
})
export class OfferService {
	apiOfferEndpoint = 'offer';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	createOffer(createOfferRequest: CreateOfferRequest) {
		return this.httpV2Service.postSecure<OfferResponse>(`${this.apiOfferEndpoint}/create`, createOfferRequest);
	}

	getFlinkerOffers() {
		return this.httpV2Service.getSecure<OfferResponse[]>(`${this.apiOfferEndpoint}/flinkerOffers`);
	}

	getFlinkerOffer(offerId: string) {
		return this.httpV2Service.getSecure<OfferResponse>(`${this.apiOfferEndpoint}/flinker/${offerId}`);
	}

	getFlinkCoOffers(pageNumber: number, pageSize: number) {
		return this.httpV2Service.getSecure<PaginatedResponse<OfferResponse>>(
			`${this.apiOfferEndpoint}/flinkCoOffers?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	getFlinkCoOffer(offerId: string) {
		return this.httpV2Service.getSecure<OfferResponse>(`${this.apiOfferEndpoint}/flinkCo/${offerId}`);
	}

	updateOffer(updateOfferRequest: UpdateOfferRequest) {
		return this.httpV2Service.postSecure<OfferResponse>(`${this.apiOfferEndpoint}/update`, updateOfferRequest);
	}

	updateOfferStatus(offerStatusRequest: OfferStatusRequest) {
		return this.httpV2Service.postSecure<OfferResponse>(
			`${this.apiOfferEndpoint}/updateOfferStatus`,
			offerStatusRequest,
		);
	}

	cancelOffer(flinkCoCancelOffer: FlinkcoCancelOfferDto) {
		return this.httpV2Service.postSecure<string>(
			`${this.apiOfferEndpoint}/${flinkCoCancelOffer.offerId}/cancel`,
			flinkCoCancelOffer,
		);
	}

	uploadOfferDocuments(offerId: string, formFiles: FormData) {
		return this.httpV2Service.postFormSecure<string>(`${this.apiOfferEndpoint}/uploadDocuments/${offerId}`, formFiles);
	}

	downloadOfferDocuments(offerId: string) {
		return this.httpV2Service.downloadFileGet(`${this.apiOfferEndpoint}/downloadDocument/${offerId}`);
	}
}
