import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { AdminSelectOptionsResponse, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import HttpPackage from '@models/http-package';
import { SelectOptionsState } from './state';

const getSelectOptionsHttpPackage = (state: SelectOptionsState): HttpPackage<SelectOptionsResponse> =>
	state.selectOptionsHttpPackage;
const getAdminSelectOptionsHttpPackage = (state: SelectOptionsState): HttpPackage<AdminSelectOptionsResponse[]> =>
	state.adminSelectOptionsHttpPackage;
const getUpdateSelectOptionHttpPackage = (state: SelectOptionsState): HttpPackage<SelectOptionResponse> =>
	state.updateSelectOptionHttpPackage;
const getCreateSelectOptionHttpPackage = (state: SelectOptionsState): HttpPackage<SelectOptionResponse> =>
	state.createSelectOptionHttpPackage;

export const selectSelectOptionsState = createFeatureSelector<SelectOptionsState>('selectOptions');

export const selectSelectOptionsHttpPackage = createSelector(selectSelectOptionsState, getSelectOptionsHttpPackage);
export const selectAdminSelectOptionsHttpPackage = createSelector(
	selectSelectOptionsState,
	getAdminSelectOptionsHttpPackage,
);
export const selectUpdateSelectOptionsHttpPackage = createSelector(
	selectSelectOptionsState,
	getUpdateSelectOptionHttpPackage,
);
export const selectCreateSelectOptionsHttpPackage = createSelector(
	selectSelectOptionsState,
	getCreateSelectOptionHttpPackage,
);
