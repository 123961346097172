import { Actions, ActionTypes } from './actions';
import { initialState, FlinkcoDashboardState } from './state';

export function flinkcoDashboardReducer(state = initialState, action: Actions): FlinkcoDashboardState {
	switch (action.type) {
		case ActionTypes.CANCEL_RETAINER_SUBSCRIPTION:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CANCEL_RETAINER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CANCEL_RETAINER_SUBSCRIPTION_ERROR:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE:
			return {
				...state,
				upgradeAdhocSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE_SUCCESS:
			return {
				...state,
				upgradeAdhocSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.REQUEST_ADHOC_SUBCRIPTION_UPGRADE_ERROR:
			return {
				...state,
				upgradeAdhocSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.REQUEST_DEMO:
			return {
				...state,
				requestDemoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.REQUEST_DEMO_SUCCESS:
			return {
				...state,
				requestDemoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.REQUEST_DEMO_ERROR:
			return {
				...state,
				requestDemoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
