<div class="d-flex py-2 px-0 details-underline container" *ngIf="isIdDocumentUploaded && isASouthAfricanCitizen">
  <div class="row w-100">
    <div class='col-11 document-title-box'>
			<span class="d-block font-light font-size-10">
        <mat-icon class="front-main-1 font-size-11 center-icon pr-2">file_copy</mat-icon>
					ID
			</span>
    </div>
    <button
      mat-icon-button
      class="m1 col-1"
      (click)="downloadDocument(flinkerId, flinkerDocuments.IdDocument)"
    >
      <mat-icon>download</mat-icon>
    </button>
    <div class="col-12 pt-2 d-flex">
      <span class="font-light font-size-14"></span>
    </div>
  </div>
</div>
<div class="d-flex py-2 px-0 details-underline container" *ngIf="isIdDocumentUploaded && !isASouthAfricanCitizen">
  <div class="row w-100">
    <div class='col-11 document-title-box'>
			<span class="d-block font-light font-size-10">
        <mat-icon class="front-main-1 font-size-11 center-icon pr-2">file_copy</mat-icon>
					Passport
			</span>
    </div>
    <button
      mat-icon-button
      class="m1 col-1"
      (click)="downloadDocument(flinkerId, flinkerDocuments.PassportDocument)"
    >
      <mat-icon>download</mat-icon>
    </button>
    <div class="col-12 pt-0 d-flex">
      <span class="font-light font-size-14"></span>
    </div>
  </div>
</div>
