<div>
	<div class="m-2 m-md-5 row">
		<div class="row mx-auto col-12 col-lg-6 col-xl-4">
			<app-mat-card-container class="col-12 px-0">
				<app-profile-completion></app-profile-completion>
			</app-mat-card-container>
		</div>
		<div class="col-12 col-lg-6 col-xl-8 mt-5 mt-md-0">
			<app-mat-card-container>
				<app-personal-container></app-personal-container>
			</app-mat-card-container>
		</div>

		<div class="col-12 col-md-6 mt-5">
			<app-mat-card-container>
				<app-job-status></app-job-status>
			</app-mat-card-container>
		</div>

		<div class="col-12 col-md-6 mt-5">
			<app-mat-card-container>
				<app-applying-to></app-applying-to>
			</app-mat-card-container>
		</div>

		<app-mat-card-container class="col-12 col-lg-6 mt-5">
			<app-profile-experience></app-profile-experience>
		</app-mat-card-container>

		<app-mat-card-container class="col-12 col-lg-6 mt-5">
			<app-education></app-education>
		</app-mat-card-container>

		<app-mat-card-container class="col-12 mt-5">
			<app-skills></app-skills>
		</app-mat-card-container>
	</div>
</div>
