<app-outlined-input
	labelText="{{ labelText }}"
	type="text"
	value="{{ rangeValue }}"
	[disabled]="true"
	*ngIf="showInput"
>
</app-outlined-input>

<div class="d-flex" *ngIf="!showInput">
	<mat-label class="pb-2">{{ labelText }}</mat-label>
</div>

<ngx-slider
	[(value)]="lowValue"
	[(highValue)]="highValue"
	[options]="options"
	(valueChange)="valueChange()"
	(highValueChange)="highValueChange()"
></ngx-slider>
