import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-rating',
	templateUrl: './rating.component.html',
	styleUrls: ['./rating.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RatingComponent),
			multi: true,
		},
	],
})
export class RatingComponent implements OnInit, ControlValueAccessor {
	ratings = [
		{
			value: 1,
			description: 'Poor',
		},
		{
			value: 2,
			description: 'Average',
		},
		{
			value: 3,
			description: 'Competent',
		},
		{
			value: 4,
			description: 'Good',
		},
		{
			value: 5,
			description: 'Excellent',
		},
	];

	value: number;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	@Input() label: string;
	@Input() errorMessage = '';
	@Input() theme = '';
	@Input() containerTheme = '';

	constructor() {}

	ngOnInit(): void {}

	public writeValue(value: number): void {
		this.value = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onSelect(index: number): void {
		this.value = index;
		this.onChange(this.value);
	}

	onArrowKey(left = false): void {
		if (left) {
			this.onSelect(this.value ? (this.value === 1 ? this.value : this.value - 1) : 1);
		} else {
			this.onSelect(this.value ? (this.value === 5 ? this.value : this.value + 1) : 1);
		}
	}
}
