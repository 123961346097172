import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FlinkcoDashboardStoreEffects } from './effects';
import { flinkcoDashboardReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinkcoDashboard', flinkcoDashboardReducer),
		EffectsModule.forFeature([FlinkcoDashboardStoreEffects]),
	],
	providers: [FlinkcoDashboardStoreEffects],
})
export class FlinkcoDashboardStoreModule {}
