<mat-form-field>
	<label [ngClass]="{ required: required }">{{ labelText }}</label>
	<div class="ghost-input">
		<input
      #inputElement
			matInput
			[type]="type"
			[placeholder]="placeholder"
			[disabled]="disabled"
			(input)="input($event)"
			(blur)="onBlur()"
			[value]="value"
      [(ngModel)]="value"
			[pattern]="pattern"
      [attr.maxlength]="maxLength"
		/>
	</div>
  <label *ngIf="bottomText" class="pt-2 front-sub-8">{{ bottomText }}</label>
  <span *ngIf="maxLength" class="word-counter front-sub-8 d-none d-md-flex">Max characters: {{ value?.length }}/{{ maxLength }}</span>
  <app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
