import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SocialLoginModule, SocialAuthServiceConfig, MicrosoftLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlinkerDashboardModule } from '@flinker-dashboard/flinker-dashboard.module';
import { AuthorizationModule } from './authorization/authorization.module';
import { SharedModule } from 'projects/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RootStoreModule, RootStoreState } from './root-store';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Store } from '@ngrx/store';
import { SelectOptionsStoreActions } from './root-store/select-options-store';
import { NotifierModule } from 'angular-notifier';
import { LegalModule } from './legal/legal.module';
import { CommonComponentsModule } from './common/common.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GoogleTagManagerTrackerService } from './services/google-tag-manager/google-tag-manager-tracker.service';
import { JobAdvertsModule } from '@app/job-adverts/job-adverts.module';
import { CareersModule } from '@app/careers/careers.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CommonModule,
		FlinkerDashboardModule,
		AuthorizationModule,
		SharedModule,
		LegalModule,
		ReactiveFormsModule,
		SocialLoginModule,
		RootStoreModule,
		MatMomentDateModule,
		CommonComponentsModule,
		JobAdvertsModule,
		CareersModule,
		NgxSkeletonLoaderModule.forRoot({}),
		NotifierModule.withConfig({
			position: {
				horizontal: {
					position: 'right',
					distance: 50,
				},
				vertical: {
					position: 'top',
					distance: 60,
				},
			},
		}),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
		}),
		GoogleTagManagerModule.forRoot({
			id: environment.googleTagManagerId,
		}),
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (store: Store<RootStoreState.State>) => {
				return () => {
					store.dispatch(new SelectOptionsStoreActions.FetchSelectOptions());
				};
			},
			multi: true,
			deps: [Store],
		},
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: MicrosoftLoginProvider.PROVIDER_ID,
						provider: new MicrosoftLoginProvider(environment.microsoftClientId, {
							redirect_uri: environment.baseUrl,
						}),
					},
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleClientId),
					},
				],
				onError: (err) => {
					console.error(err);
				},
			} as SocialAuthServiceConfig,
		},
		GoogleTagManagerTrackerService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
