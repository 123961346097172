import { createFeatureSelector, createSelector } from '@ngrx/store';
import HttpPackage from '@models/http-package';
import { CvState } from './state';
import CvPaymentStatusResponseDto from '@models/dto/responses/cv-payment-status-response.dto';
import PayForCvResponseDto from '@models/dto/responses/pay-for-cv-response.dto';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

const getCvFeaturesHttpPackage = (state: CvState): HttpPackage<CvFeatureResponseDto[]> => state.cvFeaturesHttpPackage;

const getCvPaymentStatusHttpPackage = (state: CvState): HttpPackage<CvPaymentStatusResponseDto> =>
	state.cvPaymentStatusHttpPackage;

const getPayForCvHttpPackage = (state: CvState): HttpPackage<PayForCvResponseDto> => state.payForCvHttpPackage;

const downloadFlinkerCv = (state: CvState): HttpPackage<any> => state.downloadFlinkerCv;

export const selectCvState = createFeatureSelector<CvState>('cvDashboard');

export const selectCvFeatures = createSelector(selectCvState, getCvFeaturesHttpPackage);
export const selectGetCvPaymentStatus = createSelector(selectCvState, getCvPaymentStatusHttpPackage);
export const selectPayForCv = createSelector(selectCvState, getPayForCvHttpPackage);
export const selectDownloadFlinkerCv = createSelector(selectCvState, downloadFlinkerCv);
