import { Action } from '@ngrx/store';
import { DownloadedFile } from '@app/services/http/http-v2.service';

export enum ActionTypes {
	DOWNLOAD_FLINKER_ID_DOCUMENT = '[FlinkCo Search] Download Flinker ID Document',
	DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS = '[FlinkCo Search] Download Flinker ID Document Success',
	DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR = '[FlinkCo Search] Download Flinker ID Document Error',
	DOWNLOAD_FLINKER_MATRIC_DOCUMENT = '[FlinkCo Search] Download Flinker Matric Document',
	DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS = '[FlinkCo Search] Download Flinker Matric Document Success',
	DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR = '[FlinkCo Search] Download Flinker Matric Document Error',
	DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT = '[FlinkCo Search] Download Flinker Highest Qualification Document',
	DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS = '[FlinkCo Search] Download Flinker Highest Qualification Document Success',
	DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR = '[FlinkCo Search] Download Flinker Highest Qualification Document Error',
	DOWNLOAD_FLINKER_PASSPORT_DOCUMENT = '[FlinkCo Search] Download Flinker Passport Document',
	DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS = '[FlinkCo Search] Download Flinker Passport Document Success',
	DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR = '[FlinkCo Search] Download Flinker Passport Document Error',

	CAN_DOWNLOAD_FLINKER_ID_DOCUMENT = '[FlinkCo Search] Can Download Flinker ID Document',
	CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS = '[FlinkCo Search] Can Download Flinker ID Document Success',
	CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR = '[FlinkCo Search] Can Download Flinker ID Document Error',
	CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT = '[FlinkCo Search] Can Download Flinker Matric Document',
	CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS = '[FlinkCo Search] Can Download Flinker Matric Document Success',
	CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR = '[FlinkCo Search] Can Download Flinker Matric Document Error',
	CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT = '[FlinkCo Search] Can Download Flinker Highest Qualification Document',
	CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS = '[FlinkCo Search] Can Download Flinker Highest Qualification Document Success',
	CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR = '[FlinkCo Search] Can Download Flinker Highest Qualification Document Error',
	CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT = '[FlinkCo Search] Can Download Flinker Passport Document',
	CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS = '[FlinkCo Search] Can Download Flinker Passport Document Success',
	CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR = '[FlinkCo Search] Can Download Flinker Passport Document Error',
}

export class DownloadFlinkerIdDocument implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class DownloadFlinkerIdDocumentSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS;
	constructor(public payload: DownloadedFile) {}
}

export class DownloadFlinkerIdDocumentError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class DownloadFlinkerMatricDocument implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class DownloadFlinkerMatricDocumentSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS;
	constructor(public payload: DownloadedFile) {}
}

export class DownloadFlinkerMatricDocumentError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class DownloadFlinkerHighestQualificationDocument implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class DownloadFlinkerHighestQualificationDocumentSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS;
	constructor(public payload: DownloadedFile) {}
}

export class DownloadFlinkerHighestQualificationDocumentError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class DownloadFlinkerPassportDocument implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class DownloadFlinkerPassportDocumentSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS;
	constructor(public payload: DownloadedFile) {}
}

export class DownloadFlinkerPassportDocumentError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class CanDownloadFlinkerIdDocument implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class CanDownloadFlinkerIdDocumentSuccess implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_SUCCESS;
	constructor(public payload: boolean) {}
}

export class CanDownloadFlinkerIdDocumentError implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_ID_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class CanDownloadFlinkerMatricDocument implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class CanDownloadFlinkerMatricDocumentSuccess implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_SUCCESS;
	constructor(public payload: boolean) {}
}

export class CanDownloadFlinkerMatricDocumentError implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_MATRIC_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class CanDownloadFlinkerHighestQualificationDocument implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class CanDownloadFlinkerHighestQualificationDocumentSuccess implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_SUCCESS;
	constructor(public payload: boolean) {}
}

export class CanDownloadFlinkerHighestQualificationDocumentError implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_HIGHEST_QUALIFICATION_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export class CanDownloadFlinkerPassportDocument implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class CanDownloadFlinkerPassportDocumentSuccess implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_SUCCESS;
	constructor(public payload: boolean) {}
}

export class CanDownloadFlinkerPassportDocumentError implements Action {
	readonly type = ActionTypes.CAN_DOWNLOAD_FLINKER_PASSPORT_DOCUMENT_ERROR;
	constructor(public payload: { error: any }) {}
}

export type Actions =
	| DownloadFlinkerIdDocument
	| DownloadFlinkerIdDocumentSuccess
	| DownloadFlinkerIdDocumentError
	| DownloadFlinkerMatricDocument
	| DownloadFlinkerMatricDocumentSuccess
	| DownloadFlinkerMatricDocumentError
	| DownloadFlinkerHighestQualificationDocument
	| DownloadFlinkerHighestQualificationDocumentSuccess
	| DownloadFlinkerHighestQualificationDocumentError
	| DownloadFlinkerPassportDocument
	| DownloadFlinkerPassportDocumentSuccess
	| DownloadFlinkerPassportDocumentError
	| CanDownloadFlinkerIdDocument
	| CanDownloadFlinkerIdDocumentSuccess
	| CanDownloadFlinkerIdDocumentError
	| CanDownloadFlinkerMatricDocument
	| CanDownloadFlinkerMatricDocumentSuccess
	| CanDownloadFlinkerMatricDocumentError
	| CanDownloadFlinkerHighestQualificationDocument
	| CanDownloadFlinkerHighestQualificationDocumentSuccess
	| CanDownloadFlinkerHighestQualificationDocumentError
	| CanDownloadFlinkerPassportDocument
	| CanDownloadFlinkerPassportDocumentSuccess
	| CanDownloadFlinkerPassportDocumentError;
