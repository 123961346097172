import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrls: ['./slide-toggle.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlideToggleComponent),
			multi: true,
		},
	],
})
export class SlideToggleComponent implements OnInit, ControlValueAccessor {
	@Input() text = '';
	@Input() disabled = false;
	@Input() formControlName = '';
	@Input() tooltip = '';

	@Input() checked = false;

	@Output() onUpdate: EventEmitter<any> = new EventEmitter();

	onChange = (checked: boolean) => {};

	onTouched = () => {};

	touched = false;

	constructor() {}

	ngOnInit(): void {}

	onInput(event: Event) {
		this.markAsTouched();
		this.writeValue((event.target as any).checked);
		this.onChange(this.checked);
	}

	writeValue(checked: boolean) {
		this.checked = checked;
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}
