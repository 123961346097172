import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/flink-app/src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StandardResponseDto } from '@models/dto/standard-response/standard-response.dto';

export enum HttpType {
	JSON = 'json',
	TEXT = 'text',
	ARRAY_BUFFER = 'arraybuffer',
	BLOB = 'blob',
}

export interface DownloadedFile {
	filename: string;
	file: Blob;
}

@Injectable({
	providedIn: 'root',
})
export class HttpV2Service {
	private readonly apiVersion = environment.apiVersion;
	private readonly apiBaseUrl = `${environment.apiBaseUrl}/${this.apiVersion}`;

	constructor(private http: HttpClient, private logger: NGXLogger) {}

	/**
	 * Version 2 can be specified as an override
	 * @param endpoint
	 * @param version
	 */
	public fullUrl(endpoint: string, version = 'v2'): string {
		const url = `${environment.apiBaseUrl}/${version}`;
		return `${url}/${endpoint}`;
	}

	get<T>(endpoint: string, type: string = HttpType.JSON): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`GET ${endpoint}`, { endpoint, type });
		return this.http
			.get<StandardResponseDto<T>>(endpoint, this.getOptions(type))
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	delete<T>(
		endpoint: string,
		additionalOptions: Record<string, any> = {},
		type: string = HttpType.JSON,
	): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`DELETE ${endpoint}`, { endpoint, type });
		return this.http
			.delete<StandardResponseDto<T>>(endpoint, this.getOptions(type, additionalOptions))
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	post<T>(
		endpoint: string,
		data: any,
		additionalOptions: Record<string, any> = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`POST ${endpoint}`, { endpoint, data, type });
		const body = data;
		return this.http
			.post<StandardResponseDto<T>>(endpoint, body, this.getOptions(type, additionalOptions))
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	put<T>(
		endpoint: string,
		data: any,
		additionalOptions: Record<string, any> = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`PUT ${endpoint}`, { endpoint, data, type });
		const body = data;
		return this.http
			.put<StandardResponseDto<T>>(endpoint, body, this.getOptions(type, additionalOptions))
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	patch<T>(
		endpoint: string,
		data: any,
		additionalOptions: Record<string, any> = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`PATCH ${endpoint}`, { endpoint, data, type });
		const body = data;
		return this.http
			.patch<StandardResponseDto<T>>(endpoint, body, this.getOptions(type, additionalOptions))
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	getSecure<T>(endpoint: string, type: HttpType = HttpType.JSON): Observable<T> {
		return this.get<T>(endpoint, type);
	}

	postSecure<T>(
		endpoint: string,
		data: any,
		additionalOptions: any = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		const body = JSON.stringify(data);
		return this.post<T>(endpoint, body, additionalOptions, type);
	}

	postFormSecure<T>(
		endpoint: string,
		form: FormData,
		additionalOptions: any = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		endpoint = this.fullUrl(endpoint);
		this.logger.debug(`POST ${endpoint}`, { endpoint, form, type });

		return this.http
			.post<StandardResponseDto<T>>(endpoint, form, {
				...this.getOptions(type, additionalOptions),
				headers: new HttpHeaders({
					...additionalOptions.headers,
				}),
			})
			.pipe(map((response: StandardResponseDto<T>) => response.payload));
	}

	putSecure<T>(
		endpoint: string,
		data: any,
		additionalOptions: any = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		return this.put<T>(endpoint, data, additionalOptions, type);
	}

	patchSecure<T>(
		endpoint: string,
		data: any,
		additionalOptions: any = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		const body = JSON.stringify(data);
		return this.patch<T>(endpoint, body, additionalOptions, type);
	}

	deleteSecure<T>(
		endpoint: string,
		additionalOptions: Record<string, any> = {},
		type: HttpType = HttpType.JSON,
	): Observable<T> {
		return this.delete<T>(endpoint, additionalOptions, type);
	}

	private getFileBlobFromResponse(response: HttpResponse<Blob>): DownloadedFile {
		let filename = '';
		try {
			this.logger.debug('Checking headers.', response.headers);
			const contentDispositionHeader: string =
				response.headers.get('Content-Disposition') || response.headers.get('content-disposition');
			const parts: string[] = contentDispositionHeader.split(';');
			filename = parts[1].split('=')[1].replace(/"/g, '');
			this.logger.debug('Downloaded file ', filename);
		} catch (e) {
			console.error('Failed to get filename from disposition', e);
		}
		const file: Blob = new Blob([response.body], { type: (response.body as Blob).type });
		return { filename, file };
	}

	downloadFilePost(endpoint: string, body: Record<string, any>): Observable<DownloadedFile> {
		return this.http
			.post(this.fullUrl(endpoint), body, {
				observe: 'response',
				responseType: 'blob',
			})
			.pipe(map((response: HttpResponse<Blob>) => this.getFileBlobFromResponse(response)));
	}

	downloadFileGet(endpoint: string, additionalOptions: Record<string, any> = {}): Observable<DownloadedFile> {
		return this.http
			.get(this.fullUrl(endpoint), {
				observe: 'response',
				responseType: 'blob',
				...additionalOptions,
			})
			.pipe(map((response: HttpResponse<Blob>) => this.getFileBlobFromResponse(response)));
	}

	private getOptions(
		type: string = HttpType.JSON,
		additionalOptions: any = {},
	): { headers: HttpHeaders; responseType: any } {
		switch (type) {
			case HttpType.JSON:
				return {
					headers: new HttpHeaders({ 'Response-Type': 'json', 'Content-Type': 'application/json' }),
					responseType: 'json',
					...additionalOptions,
				};
			case HttpType.ARRAY_BUFFER:
				return {
					headers: new HttpHeaders({ 'Response-Type': 'arraybuffer', 'Content-Type': 'application/json' }),
					responseType: 'arraybuffer',
					...additionalOptions,
				};
			case HttpType.BLOB:
				return {
					headers: new HttpHeaders({ 'Response-Type': 'blob', 'Content-Type': 'application/json' }),
					responseType: 'blob',
					...additionalOptions,
				};
			case HttpType.TEXT:
				return {
					headers: new HttpHeaders({ 'Response-Type': 'text', 'Content-Type': 'application/json' }),
					responseType: 'text',
					...additionalOptions,
				};
			default:
				return {
					headers: new HttpHeaders({ 'Response-Type': 'json', 'Content-Type': 'application/json' }),
					responseType: 'json',
					...additionalOptions,
				};
		}
	}
}
