<div class="row">
	<h3 class="col-12 text-center my-4" *ngIf="(flinker$ | async)?.qualifications.length === 0">
		You haven't added any qualifications.
	</h3>
	<div
		class="col-12 col-md-6 d-flex flex-column py-4 details-underline"
		*ngFor="let qualification of (flinker$ | async)?.qualifications"
	>
		<div class="row w-100">
			<div class="col-12">
				<span class="d-block font-bold font-size-11">
					{{ qualification?.name }}
				</span>
				<app-highlighted-text theme="faded2 font-size-6" class="mr-2" *ngIf="qualification?.highest">
					Highest
				</app-highlighted-text>
				<app-highlighted-text
					theme="faded10 font-size-6"
					class="mr-2"
					*ngIf="qualification?.type === QualificationType.Matric"
				>
					Matric
				</app-highlighted-text>
				<app-highlighted-text theme="faded1 font-size-6" *ngIf="qualification?.inProgress">
					In progress
				</app-highlighted-text>
			</div>
			<div class="col-12 col-md-6 pt-2 d-flex">
				<span class="front-sub-5 font-regular font-size-11 center-icon">
					{{ getInstitutionName(qualification) }}
				</span>
			</div>
			<div class="col-12 pt-2 d-flex">
				<span class="font-regular font-size-11">{{ getCompletionYear(qualification) }}</span>
			</div>
		</div>
	</div>
</div>
