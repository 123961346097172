import { Component, Input, OnInit, forwardRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { GenericOption } from '@models/generic-option.model';

@Component({
	selector: 'app-outlined-dropdown-generic',
	templateUrl: './outlined-dropdown-generic.component.html',
	styleUrls: ['./outlined-dropdown-generic.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedDropdownGenericComponent),
			multi: true,
		},
	],
})
export class OutlinedDropdownGenericComponent implements OnInit, ControlValueAccessor {
	@Input() errorMessage = '';
	@Input() options: GenericOption[];
	@Input() labelText = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';
	@Input() disabled = false;
	@Input() formControlName = '';
	@Input() selectedValue!: string;
	@Input() value = '';
	@Input() bottomText = '';
	@Input() toolTip = '';
	@Input() showTip = false;

	required = false;

	onChange: any = () => {};
	onTouched: any = () => {};

	@Output() selectionChange: EventEmitter<string> = new EventEmitter<string>();

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: MatSelectChange): void {
		this.value = event.value;
		this.onChange(event.value);
		this.selectionChange.emit(this.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	clearValue() {
		this.value = '';
		this.onChange('');
	}

	onBlur(): void {
		this.onTouched();
	}
}
