import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewFlinkcoJobAdvertsComponent } from '@app/careers/view-flinkco-job-adverts/view-flinkco-job-adverts.component';
import { ViewFlinkcoJobAdvertComponent } from '@app/careers/view-flinkco-job-advert/view-flinkco-job-advert.component';

const routes: Routes = [
	{
		path: ':flinkCoId',
		component: ViewFlinkcoJobAdvertsComponent,
	},
	{
		path: ':flinkCoId/job-advert/:jobAdvertId',
		component: ViewFlinkcoJobAdvertComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CareersRoutingModule {}
