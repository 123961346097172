import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InterviewStoreActions } from '.';
import {
	CancelInterview,
	CreateInvite,
	DownloadFlinkerCv,
	GetCurrentInterviewerWithInterviewQuestions,
	GetFlinkCoInterview,
	GetFlinkerDocument,
	GetFlinkerInterview,
	GetInterviewQuestionsScoreTable,
	ScheduleInterview,
	UpdateInterviewerQuestionAnswers,
	UpdateInterviewStatus,
} from './actions';
import ErrorResponse from '@app/models/dto/responses/error-response.dto';
import { InterviewService } from '@app/services/interview/interview.service';
import InterviewResponse from '@app/models/dto/responses/interview-response.dto';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { DownloadedFile } from '@app/services/http/http-v2.service';
import { SearchStoreActions } from '@root-store/search-store';
import { FlinkCoJobAdvertsActions } from '@root-store/flinkco/job-adverts';
import InterviewerWithInterviewQuestionsResponse from '@models/dto/responses/interviewer-with-interview-questions-response.dto';
import InterviewQuestionsScoreTableResponse from '@models/dto/responses/interview-questions-score-table-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

@Injectable()
export class InterviewStoreEffects {
	constructor(private actions$: Actions, private interviewService: InterviewService) {}

	createInviteEffects$ = createEffect(() =>
		this.actions$.pipe(
			ofType<InterviewStoreActions.CreateInvite>(InterviewStoreActions.ActionTypes.CREATE_INVITE),
			switchMap((action: CreateInvite) => {
				return this.interviewService.createInvite(action.payload.flinkerId, action.payload.searchId).pipe(
					switchMap(() => {
						return [
							new InterviewStoreActions.CreateInviteSuccess({ result: 'success' }),
							new SearchStoreActions.UpdateSearchForScheduledInterview({ flinkerId: action.payload.flinkerId }),
							new FlinkCoJobAdvertsActions.UpdateJobApplicationForInterviewScheduled({
								flinkerId: action.payload.flinkerId,
							}),
						];
					}),
					catchError((error: ErrorResponse) => {
						return of(new InterviewStoreActions.CreateInviteError({ errorResponse: error }));
					}),
				);
			}),
		),
	);
	getFlinkCoInterviewsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetFlinkCoInterviews>(InterviewStoreActions.ActionTypes.GET_FLINKCO_INTERVIEWS),
			switchMap((action) =>
				this.interviewService.getFlinkCoInterviews(action.payload).pipe(
					map(
						(result: PaginatedResponse<InterviewResponse>) =>
							new InterviewStoreActions.GetFlinkCoInterviewsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetFlinkCoInterviewsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkerInterviewsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetFlinkerInterviews>(InterviewStoreActions.ActionTypes.GET_FLINKER_INTERVIEWS),
			switchMap(() =>
				this.interviewService.getFlinkerInterviews().pipe(
					map((result: InterviewResponse[]) => new InterviewStoreActions.GetFlinkerInterviewsSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetFlinkerInterviewsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkCoInterviewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetFlinkCoInterview>(InterviewStoreActions.ActionTypes.GET_FLINKCO_INTERVIEW),
			switchMap((action: GetFlinkCoInterview) =>
				this.interviewService.getFlinkCoInterview(action.payload.interviewId).pipe(
					map((result: InterviewResponse) => new InterviewStoreActions.GetFlinkCoInterviewSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetFlinkCoInterviewError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkerInterviewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetFlinkerInterview>(InterviewStoreActions.ActionTypes.GET_FLINKER_INTERVIEW),
			switchMap((action: GetFlinkerInterview) =>
				this.interviewService.getFlinkerInterview(action.payload.interviewId).pipe(
					map((result: InterviewResponse) => new InterviewStoreActions.GetFlinkerInterviewSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetFlinkerInterviewError({ errorResponse: error })),
					),
				),
			),
		);
	});

	cancelInterviewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.CancelInterview>(InterviewStoreActions.ActionTypes.CANCEL_INTERVIEW),
			switchMap((action: CancelInterview) =>
				this.interviewService.cancelInterview(action.payload.interviewId, action.payload.interviewStatusRequest).pipe(
					map(
						(response: InterviewResponse) =>
							new InterviewStoreActions.CancelInterviewSuccess({
								result: response,
								interviewId: action.payload.interviewId,
								interviewStatusRequest: action.payload.interviewStatusRequest,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.CancelInterviewError({ errorResponse: error })),
					),
				),
			),
		);
	});

	scheduleInterviewEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.ScheduleInterview>(InterviewStoreActions.ActionTypes.SCHEDULE_INTERVIEW),
			switchMap((action: ScheduleInterview) =>
				this.interviewService
					.scheduleInterview(action.payload.interviewId, action.payload.scheduleInterviewRequest)
					.pipe(
						map(
							() =>
								new InterviewStoreActions.ScheduleInterviewSuccess({
									result: 'Success',
									interviewId: action.payload.interviewId,
									scheduleInterviewRequest: action.payload.scheduleInterviewRequest,
								}),
						),
						catchError((error: ErrorResponse) =>
							of(new InterviewStoreActions.ScheduleInterviewError({ errorResponse: error })),
						),
					),
			),
		);
	});

	updateInterviewStatusEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.UpdateInterviewStatus>(InterviewStoreActions.ActionTypes.UPDATE_INTERVIEW_STATUS),
			switchMap((action: UpdateInterviewStatus) =>
				this.interviewService
					.updateInterviewStatus(action.payload.interviewId, action.payload.interviewStatusRequest)
					.pipe(
						map(
							(response: InterviewResponse) =>
								new InterviewStoreActions.UpdateInterviewStatusSuccess({
									result: response,
									interviewId: action.payload.interviewId,
									interviewStatusRequest: action.payload.interviewStatusRequest,
								}),
						),
						catchError((error: ErrorResponse) =>
							of(new InterviewStoreActions.UpdateInterviewStatusError({ errorResponse: error })),
						),
					),
			),
		);
	});
	getFlinkerDocumentsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetFlinkerDocument>(InterviewStoreActions.ActionTypes.GET_FLINKER_DOCUMENTS),
			switchMap((action: GetFlinkerDocument) =>
				this.interviewService
					.getFlinkerDocuments(action.payload.interviewId, action.payload.documentId, action.payload.documentType)
					.pipe(
						tap((response: DownloadedFile) => {
							saveAs(response.file, response.filename);
						}),
						map(
							(response: DownloadedFile) =>
								new InterviewStoreActions.GetFlinkerDocumentSuccess({ result: 'File loaded' }),
						),
						catchError((error: ErrorResponse) =>
							of(new InterviewStoreActions.GetFlinkerDocumentError({ errorResponse: error })),
						),
					),
			),
		);
	});
	downloadFlinkerCvEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.DownloadFlinkerCv>(InterviewStoreActions.ActionTypes.DOWNLOAD_FLINKER_CV),
			switchMap((action: DownloadFlinkerCv) =>
				this.interviewService.downloadFlinkerCv(action.payload.flinkerId).pipe(
					map((response: any) => {
						return new InterviewStoreActions.DownloadFlinkerCvSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						return of(new InterviewStoreActions.DownloadFlinkerCvError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	getCurrentInterviewerWithInterviewQuestionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetCurrentInterviewerWithInterviewQuestions>(
				InterviewStoreActions.ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS,
			),
			switchMap((action: GetCurrentInterviewerWithInterviewQuestions) =>
				this.interviewService.getCurrentInterviewerWithInterviewQuestions(action.payload.interviewId).pipe(
					map(
						(response: InterviewerWithInterviewQuestionsResponse) =>
							new InterviewStoreActions.GetCurrentInterviewerWithInterviewQuestionsSuccess({
								result: response,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetCurrentInterviewerWithInterviewQuestionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateInterviewerQuestionAnswersEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.UpdateInterviewerQuestionAnswers>(
				InterviewStoreActions.ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS,
			),
			switchMap((action: UpdateInterviewerQuestionAnswers) =>
				this.interviewService.updateInterviewerQuestionAnswers(action.payload.interviewId, action.payload).pipe(
					map(
						(response: InterviewerWithInterviewQuestionsResponse) =>
							new InterviewStoreActions.UpdateInterviewerQuestionAnswersSuccess({
								result: response,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.UpdateInterviewerQuestionAnswersError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getInterviewQuestionsScoreTableEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<InterviewStoreActions.GetInterviewQuestionsScoreTable>(
				InterviewStoreActions.ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE,
			),
			switchMap((action: GetInterviewQuestionsScoreTable) =>
				this.interviewService.getInterviewQuestionsScoreTable(action.payload.interviewId).pipe(
					map(
						(response: InterviewQuestionsScoreTableResponse) =>
							new InterviewStoreActions.GetInterviewQuestionsScoreTableSuccess({
								result: response,
							}),
					),
					catchError((error: ErrorResponse) =>
						of(new InterviewStoreActions.GetInterviewQuestionsScoreTableError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
