import { initialState, VideoState } from './state';
import { Actions, ActionTypes } from './actions';

export function videoReducer(state = initialState, action: Actions): VideoState {
	switch (action.type) {
		case ActionTypes.CREATE_VIDEO_ROOM:
			return {
				...state,
				createVideoHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.CREATE_VIDEO_ROOM_SUCCESS:
			return {
				...state,
				createVideoHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.CREATE_VIDEO_ROOM_ERROR:
			return {
				...state,
				createVideoHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.GET_VIDEO_RECORDING:
			return {
				...state,
				getVideoRecordingHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.GET_VIDEO_RECORDING_SUCCESS:
			return {
				...state,
				getVideoRecordingHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.GET_VIDEO_RECORDING_ERROR:
			return {
				...state,
				getVideoRecordingHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.GET_VIDEO_COMPOSITION:
			return {
				...state,
				getVideoCompositionHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.GET_VIDEO_COMPOSITION_SUCCESS:
			return {
				...state,
				getVideoCompositionHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.GET_VIDEO_COMPOSITION_ERROR:
			return {
				...state,
				getVideoCompositionHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.DELETE_VIDEO_RECORDING:
			return {
				...state,
				deleteVideoRecordingHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};

		case ActionTypes.DELETE_VIDEO_RECORDING_SUCCESS:
			return {
				...state,
				deleteVideoRecordingHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};

		case ActionTypes.DELETE_VIDEO_RECORDING_ERROR:
			return {
				...state,
				deleteVideoRecordingHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.DELETE_COMPOSITION_RECORDING:
			return {
				...state,
				deleteCompositionRecordingHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};

		case ActionTypes.DELETE_COMPOSITION_RECORDING_SUCCESS:
			return {
				...state,
				deleteCompositionRecordingHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};

		case ActionTypes.DELETE_COMPOSITION_RECORDING_ERROR:
			return {
				...state,
				deleteCompositionRecordingHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
