<label class="pt-2">{{ labelText }}</label>
<mat-hint class="pb-2" *ngIf="hint.length > 0">{{ hint }}</mat-hint>
<div cdkDropList class="list mt-2" [ngClass]="theme" (cdkDropListDropped)="drop($event)">
  <div class="list-item-box position-relative" *ngFor="let questionSelectedForm of formGroups; let i = index" cdkDrag>
    <div class="row col-12 flex-column flex-md-row" [formGroup]="questionSelectedForm">
      <app-outlined-text-area class="col-12 col-md-8" labelText="Question Text" formControlName="question" type="text"
                              [maxLength]="maxQuestionTextLength"
                              [errorMessage]="getErrorMessage(questionSelectedForm.controls.questionText,'Question Text',shouldValidate)
                    "></app-outlined-text-area>
      <app-outlined-dropdown-generic labelText="What is the answering method?"
                                     class="col-12 col-md-4" formControlName="type"
                                     [options]="questionTypeOptions"
                                     [errorMessage]="getErrorMessage(questionSelectedForm.controls.type,'Answering Method', shouldValidate)"
      ></app-outlined-dropdown-generic>
    </div>

    <button class="position-absolute position-top-right" mat-icon-button (click)="remove(i)">
      <mat-icon class="front-main-5 font-size-6">delete</mat-icon>
    </button>
  </div>
</div>
<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage">
</app-input-error-message>

<app-input-error-message *ngIf="formGroups.length === 0 && shouldValidate" errorMessage="Please select or enter at least one question.">
</app-input-error-message>
