<div class="container mw-100 vh-100 p-1 p-md-5">
	<mat-card>
		<app-page-header-toolbar icon="question_answer" heading="Upcoming Interviews"> </app-page-header-toolbar>

		<div class="row">
			<div class="col-12 px-4">
				<app-flinker-upcoming-interviews></app-flinker-upcoming-interviews>
			</div>
		</div>
	</mat-card>

	<mat-card class="mt-1 mt-md-5">
		<app-page-header-toolbar icon="history" heading="Past Interviews"> </app-page-header-toolbar>

		<div class="row">
			<div class="col-12 px-4">
				<app-flinker-past-interviews></app-flinker-past-interviews>
			</div>
		</div>
	</mat-card>
</div>
