import { Component, OnInit } from '@angular/core';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { FlinkerTakeOnStoreSelectors } from 'projects/flink-app/src/app/root-store/flinker-take-on-store';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-reactivation-modal',
	templateUrl: './reactivation-modal.component.html',
	styleUrls: ['./reactivation-modal.component.scss'],
})
export class ReactivationModalComponent implements OnInit {
	getReactivationRequestHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectGetReactivationRequestHttpPackage,
	);

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {}
}
