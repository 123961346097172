import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FlinkCoFlinkerDocumentEffects } from '@root-store/flinkco/flinker-documents/effects';
import { FlinkCoFlinkerDocumentsReducer } from '@root-store/flinkco/flinker-documents/reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinkco-flinker-documents', FlinkCoFlinkerDocumentsReducer),
		EffectsModule.forFeature([FlinkCoFlinkerDocumentEffects]),
	],
	providers: [FlinkCoFlinkerDocumentEffects],
})
export class FlinkCoFlinkerDocumentsModule {}
