<div class="autocomplete-label d-flex p-0 justify-content-between w-100">
	<label class="d-flex" [ngClass]="{ required: required }"
		>{{ labelText
		}}<app-tooltip class="tooltip-in-label" *ngIf="showTip && labelText" [tooltip]="toolTip"></app-tooltip>
	</label>
</div>

<div class="select-container">
	<input
		type="text"
		class="ghost-input"
		(input)="clearOnEmptyString()"
		[formControl]="myControl"
		[matAutocomplete]="auto"
		autocomplete="none"
		placeholder="Search options"
    [maxlength]="maxLength"
	/>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="input($event)">
		<mat-option *ngFor="let selectOption of filteredOptions | async" [value]="selectOption.registeredName">
			{{ selectOption.registeredName }}
		</mat-option>
	</mat-autocomplete>
	<mat-icon class="clear front-sub-9" *ngIf="value" (click)="clearValue()">cancel</mat-icon>
</div>
<div class="position-relative" *ngIf="maxLength">
  <span class="word-counter front-sub-8 d-none d-md-flex pb-2">Max characters: {{ value?.length }}/{{ maxLength }}</span>
</div>
<app-input-error-message class="autocomplete-error" *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage">
</app-input-error-message>
