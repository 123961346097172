import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import ReferenceQuestionResponse from '@models/dto/responses/reference-question-response.dto';
import { RootStoreState } from '@root-store';
import { ReferenceStoreActions, ReferenceStoreSelectors } from '@root-store/reference-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { getErrorMessage } from '@app/utils/form.util';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReferenceQuestionAnswerDto } from '@models/dto/requests/reference-question-answer.dto';

export interface EditableData {
	refId: string;
}

@Component({
	selector: 'app-reference-score-form',
	templateUrl: './reference-score-form.component.html',
	styleUrls: ['./reference-score-form.component.scss'],
})
export class ReferenceScoreFormComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	form: FormGroup;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	referenceQuestionsHttpPackage$ = this.store.select(ReferenceStoreSelectors.selectGetReferenceQuestionsHttpPackage);
	submitReferenceScoreHttpPackage$ = this.store.select(ReferenceStoreSelectors.selectSubmitReferenceScoreHttpPackage);
	getErrorMessage = getErrorMessage;
	referenceId: string;
	referenceQuestions: ReferenceQuestionResponse[];
	success = false;
	error = false;
	errorMessage = '';
	shouldValidate = false;
	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private route: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data?: any,
	) {
		this.form = this.formBuilder.group({
			relationship: ['', Validators.required],
			referenceQuestions: new FormArray([]),
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.route.paramMap.subscribe((paramMap: ParamMap) => {
				if (this.data.length != 0) {
					this.referenceId = this.data.refId;
				} else {
					this.referenceId = paramMap.get('referenceId');
				}
				this.store.dispatch(new ReferenceStoreActions.GetReferenceQuestions({ referenceId: this.referenceId }));
			}),
		);

		this.subscriptions.add(
			this.referenceQuestionsHttpPackage$.subscribe((referenceQuestionsHttpPackage) => {
				if (referenceQuestionsHttpPackage.result !== null) {
					this.referenceQuestions = referenceQuestionsHttpPackage.result.referenceQuestions;
					referenceQuestionsHttpPackage.result.referenceQuestions.forEach((_) => {
						const dynamicQuestions = this.dynamicForm;
						const questionFormGroup = new FormGroup({
							question: new FormControl('', Validators.required),
						});
						dynamicQuestions.push(questionFormGroup);
					});
				}
			}),
		);

		this.subscriptions.add(
			this.referenceQuestionsHttpPackage$.subscribe((referenceQuestionsHttpPackage) => {
				if (referenceQuestionsHttpPackage.error !== null) {
					this.error = true;
					this.errorMessage = referenceQuestionsHttpPackage.error.friendly
						? referenceQuestionsHttpPackage.error.message
						: 'An unknown error occurred while fetching your reference scoring questions';
				}
			}),
		);

		this.subscriptions.add(
			this.submitReferenceScoreHttpPackage$.subscribe((submitReferenceScoreHttpPackage) => {
				if (submitReferenceScoreHttpPackage.result !== null) {
					this.success = true;
				}
			}),
		);
	}

	get mainForm() {
		return this.form.controls;
	}
	get dynamicForm() {
		return this.mainForm.referenceQuestions as FormArray;
	}
	get referenceQuestionsFormGroup() {
		return this.dynamicForm.controls as FormGroup[];
	}

	submit(): void {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			this.shouldValidate = true;
			return;
		}

		const formValues = this.form.value;
		let scoreTotal = 0;
		const referenceQuestionAnswerArray: ReferenceQuestionAnswerDto[] = [];
		formValues.referenceQuestions.forEach((referenceQuestion: any, index: number) => {
			referenceQuestionAnswerArray.push({
				question: this.referenceQuestions[index].question,
				answer: referenceQuestion.question,
			});
			scoreTotal += referenceQuestion.question;
		});

		this.store.dispatch(
			new ReferenceStoreActions.SubmitReferenceScore({
				referenceScoreRequest: {
					referenceId: this.referenceId,
					pointsScored: scoreTotal,
					pointsTotal: this.referenceQuestions.length * 5,
					referenceRelationshipCode: formValues.relationship,
					referenceQuestionAnswer: referenceQuestionAnswerArray,
				},
			}),
		);
	}

	ngOnDestroy(): void {
		this.store.dispatch(new ReferenceStoreActions.ResetReferenceState());
		this.subscriptions.unsubscribe();
	}
}
