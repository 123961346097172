<div class="container mw-100 min-vh-100 p-1 p-md-5">
  <mat-card class="p-2 py-md-3 px-md-4">
    <app-page-heading class="ml-4 mt-3" theme="f4" heading="Job Adverts"></app-page-heading>
    <p class="font-size-11 mt-3 ml-4 front-fade-4 font-light">View and search all ads</p>


    <form class="container mt-4 mw-100 px-md-4 px-2" [formGroup]="form">
      <div class="row">

        <app-range-slider
          class="col-12"
          formControlName="salaryBracket"
          labelText="Monthly {{dictionaryWords.Salary}} & benefits"
          [floor]="salaryBandLower"
          [ceil]="salaryBandUpper"
          [step]="1000"
          [monetary]="true"
        >
        </app-range-slider>

        <app-outlined-autocomplete
          class="col-12 col-md-6"
          labelText="Job title"
          formControlName="jobTitle"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.jobTitles"
          [errorMessage]="getErrorMessage(form.controls.jobTitle, 'Job title', shouldValidate)"
        >
        </app-outlined-autocomplete>

        <app-outlined-dropdown
          class="col-12 col-md-6"
          labelText="Job category of position"
          formControlName="jobCategory"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.jobCategories"
          [errorMessage]="getErrorMessage(form.controls.jobCategory, 'Job Category of Position', shouldValidate)"
        >
        </app-outlined-dropdown>

        <app-outlined-dropdown
          class="col-lg-6 col-md-12"
          labelText="Placement type"
          formControlName="placement"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.placements"
          [errorMessage]="getErrorMessage(form.controls.placement, 'Placement type', shouldValidate)"
        >
        </app-outlined-dropdown>
        <app-outlined-autocomplete
          class="col-lg-6 col-md-12"
          labelText="Industry"
          formControlName="industry"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.industries"
          [errorMessage]="getErrorMessage(form.controls.industry, 'Industry', shouldValidate)"
        >
        </app-outlined-autocomplete>

        <app-chips-input
          class="col-lg-6 col-md-12 mb-3"
          labelText="Location : City/Town"
          formControlName="cities"
          theme="outlined-input"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.cities"
          [errorMessage]="getErrorMessage(form.controls.cities, 'City/Town', shouldValidate)"
        >
        </app-chips-input>

        <app-outlined-dropdown
          class="col-lg-6 col-md-12"
          labelText="Work space preference"
          formControlName="workspacePreference"
          [options]="(selectOptionsHttpPackage$ | async)?.result?.workspaces"
          [errorMessage]="getErrorMessage(form.controls.workspacePreference, 'Work space preference', shouldValidate)"
        >
        </app-outlined-dropdown>
        <app-square-button (click)="fetchFilteredJobAdverts()" value="Filter Job Ads" theme="gh-m1 bold"></app-square-button>
        <app-square-button (click)="clearFilter()" value="Clear Filter" theme="gh-m5 bold"></app-square-button>
      </div>
    </form>

<div class="ml-md-5 pl-5 mt-5">
  <app-button-tab (currentIndexChange)="onSelectTab($event)" [values]="['Explore Ads', 'My Job Ads']"></app-button-tab>
</div>
    <ng-container *ngIf="(getFilteredJobAdvertsHttpPackage$ | async)?.loading">
      <div class="w-100 text-center col-12">
        <app-loader></app-loader>
      </div>
    </ng-container>
    <div class="overflow-hidden" *ngIf="!(getFilteredJobAdvertsHttpPackage$ | async)?.loading">
      <div class="w-100 col-12">
        <div class="w-100 col-12 grid-container" [@listAnimation]="true">
          <div *ngFor="let jobAdvert of jobAdverts">
            <app-job-advert-card [jobAdvert]="jobAdvert"  class="col-sm-12 p-0 mx-0 grid-item"></app-job-advert-card>
          </div>

        </div>
      </div>
    </div>

    <mat-paginator
      [pageSizeOptions]="[3, 12, 20, 50]"
      [pageSize]="pageSize"
      [pageIndex]="pageNumber"
      [length]="numberOfJobAdverts"
      (page)="onPaginationEvent($event)"
      showFirstLastButtons
      aria-label="Select page of job adverts's"
    >
    </mat-paginator>
  </mat-card>
</div>

