import { InterviewStatus } from '@app/enums/interview-status.enum';
import { Actions, ActionTypes } from './actions';
import { initialState, InterviewState } from './state';

export function interviewReducer(state = initialState, action: Actions): InterviewState {
	switch (action.type) {
		case ActionTypes.CREATE_INVITE:
			return {
				...state,
				createInviteHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_INVITE_SUCCESS:
			return {
				...state,
				createInviteHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_INVITE_ERROR:
			return {
				...state,
				createInviteHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEWS:
			return {
				...state,
				getFlinkCoInterviewsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEWS_SUCCESS:
			return {
				...state,
				getFlinkCoInterviewsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEWS_ERROR:
			return {
				...state,
				getFlinkCoInterviewsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEWS:
			return {
				...state,
				getFlinkerInterviewsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEWS_SUCCESS:
			return {
				...state,
				getFlinkerInterviewsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEWS_ERROR:
			return {
				...state,
				getFlinkerInterviewsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEW:
			return {
				...state,
				getFlinkCoInterviewHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEW_SUCCESS:
			return {
				...state,
				getFlinkCoInterviewHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_INTERVIEW_ERROR:
			return {
				...state,
				getFlinkCoInterviewHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEW:
			return {
				...state,
				getFlinkerInterviewHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEW_SUCCESS:
			return {
				...state,
				getFlinkerInterviewHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_INTERVIEW_ERROR:
			return {
				...state,
				getFlinkerInterviewHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CANCEL_INTERVIEW:
			return {
				...state,
				cancelInterviewHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CANCEL_INTERVIEW_SUCCESS:
			return {
				...state,
				cancelInterviewHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result.id,
				},
				getFlinkCoInterviewHttpPackage: {
					...state.getFlinkCoInterviewHttpPackage,
					result: {
						...action.payload.result,
						rejectionReasonId: action.payload.interviewStatusRequest.rejectionReasonId,
						otherRejectionReason: action.payload.interviewStatusRequest.otherRejectionReason,
						status: <InterviewStatus>action.payload.interviewStatusRequest.status,
					},
				},
				getFlinkCoInterviewsHttpPackage: {
					...state.getFlinkCoInterviewsHttpPackage,
					result: !state.getFlinkCoInterviewsHttpPackage?.result
						? null
						: {
								...state.getFlinkCoInterviewsHttpPackage.result,
								results: [
									...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
										0,
										state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
											(interview) => interview.id === action.payload.interviewId,
										),
									),
									{
										...state.getFlinkCoInterviewsHttpPackage.result.results[
											state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
												(interview) => interview.id == action.payload.interviewId,
											)
										],
										...action.payload.result,
									},
									...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
										state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
											(interview) => interview.id === action.payload.interviewId,
										) + 1,
									),
								],
						  },
				},
			};
		case ActionTypes.CANCEL_INTERVIEW_ERROR:
			return {
				...state,
				cancelInterviewHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SCHEDULE_INTERVIEW:
			return {
				...state,
				scheduleInterviewHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SCHEDULE_INTERVIEW_SUCCESS:
			return {
				...state,
				scheduleInterviewHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				getFlinkCoInterviewHttpPackage: {
					...state.getFlinkCoInterviewHttpPackage,
					result: {
						...state.getFlinkCoInterviewHttpPackage.result,
						startDate: action.payload.scheduleInterviewRequest.startDate,
						endDate: action.payload.scheduleInterviewRequest.endDate,
						location: action.payload.scheduleInterviewRequest.location,
						meetingUrl: action.payload.scheduleInterviewRequest.meetingUrl,
					},
				},
				getFlinkCoInterviewsHttpPackage: {
					...state.getFlinkCoInterviewsHttpPackage,
					result: !state.getFlinkCoInterviewsHttpPackage?.result
						? null
						: {
								...state.getFlinkCoInterviewsHttpPackage.result,
								results: state.getFlinkCoInterviewsHttpPackage.result.results
									? [
											...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
												0,
												state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
													(interview) => interview.id === action.payload.interviewId,
												),
											),
											{
												...state.getFlinkCoInterviewsHttpPackage.result.results[
													state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
														(interview) => interview.id == action.payload.interviewId,
													)
												],
												startDate: action.payload.scheduleInterviewRequest.startDate,
												endDate: action.payload.scheduleInterviewRequest.endDate,
												location: action.payload.scheduleInterviewRequest.location,
												meetingUrl: action.payload.scheduleInterviewRequest.meetingUrl,
											},
											...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
												state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
													(interview) => interview.id === action.payload.interviewId,
												) + 1,
											),
									  ]
									: null,
						  },
				},
			};
		case ActionTypes.SCHEDULE_INTERVIEW_ERROR:
			return {
				...state,
				scheduleInterviewHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_INTERVIEW_STATUS:
			return {
				...state,
				updateInterviewStatusHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_INTERVIEW_STATUS_SUCCESS:
			return {
				...state,
				updateInterviewStatusHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result.id,
				},
				getFlinkerInterviewHttpPackage: {
					...state.getFlinkerInterviewHttpPackage,
					result: {
						...state.getFlinkerInterviewHttpPackage.result,
						...action.payload.result,
					},
				},
				getFlinkerInterviewsHttpPackage: {
					...state.getFlinkerInterviewsHttpPackage,
					result: state.getFlinkerInterviewsHttpPackage?.result
						? [
								...state.getFlinkerInterviewsHttpPackage.result.slice(
									0,
									state.getFlinkerInterviewsHttpPackage.result.findIndex(
										(interview) => interview.id === action.payload.interviewId,
									),
								),
								{
									...state.getFlinkerInterviewsHttpPackage.result[
										state.getFlinkerInterviewsHttpPackage.result.findIndex(
											(interview) => interview.id == action.payload.interviewId,
										)
									],
									...action.payload.result,
								},
								...state.getFlinkerInterviewsHttpPackage.result.slice(
									state.getFlinkerInterviewsHttpPackage.result.findIndex(
										(interview) => interview.id === action.payload.interviewId,
									) + 1,
								),
						  ]
						: null,
				},
			};
		case ActionTypes.UPDATE_INTERVIEW_STATUS_ERROR:
			return {
				...state,
				updateInterviewStatusHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_INTERVIEW_FOR_OFFER_MADE:
			return {
				...state,
				getFlinkCoInterviewsHttpPackage: {
					...state.getFlinkCoInterviewsHttpPackage,
					result: !state.getFlinkCoInterviewsHttpPackage?.result
						? null
						: {
								...state.getFlinkCoInterviewsHttpPackage?.result,
								results: state.getFlinkCoInterviewsHttpPackage?.result
									? [
											...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
												0,
												state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
													(interview) => interview.flinkerId === action.payload.flinkerId,
												),
											),
											{
												...state.getFlinkCoInterviewsHttpPackage.result.results[
													state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
														(interview) => interview.flinkerId === action.payload.flinkerId,
													)
												],
												offerMade: true,
											},
											...state.getFlinkCoInterviewsHttpPackage.result.results.slice(
												state.getFlinkCoInterviewsHttpPackage.result.results.findIndex(
													(interview) => interview.flinkerId === action.payload.flinkerId,
												) + 1,
											),
									  ]
									: null,
						  },
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_SUCCESS:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_CLEAR_FILE_SUCCESS:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_ERROR:
			return {
				...state,
				downloadFlinkerCv: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_SUCCESS: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_CURRENT_INTERVIEWER_WITH_INTERVIEW_QUESTIONS_ERROR: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS_SUCCESS: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.UPDATE_INTERVIEWER_QUESTION_ANSWERS_ERROR: {
			return {
				...state,
				getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE: {
			return {
				...state,
				getInterviewQuestionsScoreTableHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE_SUCCESS: {
			return {
				...state,
				getInterviewQuestionsScoreTableHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		}
		case ActionTypes.GET_INTERVIEW_QUESTIONS_SCORE_TABLE_ERROR: {
			return {
				...state,
				getInterviewQuestionsScoreTableHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		default: {
			return state;
		}
	}
}
