<div
	class="text-center"
	*ngIf="
		(flinkerEmploymentDetailsHttpPackage$ | async)?.loading || (uploadEmploymentDocumentsHttpPackage$ | async)?.loading
	"
>
	<app-loader></app-loader>
</div>

<form
	class="container mt-5"
	[formGroup]="form"
	*ngIf="
		!(flinkerEmploymentDetailsHttpPackage$ | async)?.loading &&
		!(uploadEmploymentDocumentsHttpPackage$ | async)?.loading
	"
>
	<!-- Current Employment -->

	<app-page-header-toolbar
		icon="work"
		heading="Current employment"
		[showTip]="true"
		[toolTip]="
			'Please note that we will not make contact with your current employer unless you have put them up as a reference and granted us permission to do so. Your current employer will be shown on your profile to prospective employers.\n'
		"
	>
	</app-page-header-toolbar>

	<div class="row">
		<app-checkbox class="col-12 col-md-6 my-4" formControlName="unemployed" text="I am currently unemployed.">
		</app-checkbox>

		<app-checkbox class="col-12 col-md-6 my-4" formControlName="student" text="I am currently studying.">
		</app-checkbox>
	</div>

	<div class="row" *ngIf="!form.controls.unemployed.value">
		<app-outlined-experience-autocomplete
			class="col-12"
			labelText="Company name"
			formControlName="currentCompanyName"
			type="text"
			[options]="flinkCoList"
			[toolTip]="'You can enter a name even if it does not show up in the options'"
			[showTip]="true"
			[maxLength]="maxCompanyNameLength"
			[errorMessage]="getErrorMessage(form.controls.currentCompanyName, 'Company name', shouldValidate)"
		>
		</app-outlined-experience-autocomplete>

		<app-outlined-autocomplete
			class="col-12"
			labelText="Industry"
			formControlName="currentIndustry"
			[options]="selectOptions?.industries"
			[errorMessage]="getErrorMessage(form.controls.currentIndustry, 'Current industry', shouldValidate)"
		>
		</app-outlined-autocomplete>

		<app-outlined-date-input
			class="col-12"
			labelText="Start date"
			formControlName="currentEmploymentStartDate"
			type="date"
			[errorMessage]="getErrorMessage(form.controls.currentEmploymentStartDate, 'Start date')"
		>
		</app-outlined-date-input>

		<app-outlined-dropdown
			class="col-12"
			labelText="Job category"
			formControlName="currentJobCategory"
			[options]="selectOptions?.jobCategories"
			[errorMessage]="getErrorMessage(form.controls.currentJobCategory, 'Job category', shouldValidate)"
		>
		</app-outlined-dropdown>

		<app-outlined-autocomplete
			class="col-12"
			labelText="Job title"
			formControlName="currentJobTitle"
			[options]="selectOptions?.jobTitles"
			[errorMessage]="getErrorMessage(form.controls.currentJobTitle, 'Job title', shouldValidate)"
			[allowOther]="true"
		>
		</app-outlined-autocomplete>

		<app-outlined-input
			class="col-12"
			labelText="Please specify the job title"
			formControlName="otherJobTitle"
			*ngIf="form.controls.currentJobTitle.value === 'OTH'"
			type="text"
			[maxLength]="entityConfig.currentEmployment.maxOtherJobTitleLength"
			[errorMessage]="getErrorMessage(form.controls.otherJobTitle, 'Job title', shouldValidate)"
		>
		</app-outlined-input>

		<app-outlined-text-area
			class="col-12"
			labelText="Short description of roles & responsibilities"
			formControlName="currentDuties"
			[maxLength]="dutiesDescriptionLength"
			[errorMessage]="getErrorMessage(form.controls.currentDuties, 'Duties description', shouldValidate)"
		>
		</app-outlined-text-area>

		<app-outlined-file-input
			class="col-12"
			labelText="Detailed job description"
			buttonInput="Upload"
			[uploaded]="flinker?.currentEmployer?.jobDescriptionUploaded"
			(fileSelected)="currentJobDescription = $event"
			formControlName="currentJobDescription"
			[errorMessage]="getErrorMessage(form.controls.currentJobDescription, 'Job description', shouldValidate)"
		>
		</app-outlined-file-input>
	</div>

	<!-- Previous Employment -->
	<app-page-header-toolbar icon="work" heading="Previous employment"></app-page-header-toolbar>

	<div class="row">
		<div *ngFor="let previousEmployer of previousEmployerFormGroup; let i = index" class="col-12">
			<div [formGroup]="previousEmployer" class="row">
				<h2 class="col-12 front-sub-4 font-medium">Previous employment {{ i + 1 }}</h2>

				<app-outlined-experience-autocomplete
					class="col-12"
					labelText="Company name"
					formControlName="previousCompanyName"
					type="text"
					[options]="flinkCoList"
					[toolTip]="'You can enter a name even if it does not show up in the options'"
					[showTip]="true"
					[maxLength]="maxCompanyNameLength"
					[errorMessage]="getErrorMessage(form.controls.previousCompanyName, 'Company name', shouldValidate)"
				>
				</app-outlined-experience-autocomplete>

				<app-outlined-autocomplete
					class="col-12"
					labelText="Industry"
					formControlName="previousIndustry"
					[options]="selectOptions?.industries"
					[errorMessage]="getErrorMessage(previousEmployer.controls.previousIndustry, 'Industry', shouldValidate)"
				>
				</app-outlined-autocomplete>

				<app-outlined-date-input
					class="col-12"
					labelText="Start date"
					formControlName="previousEmploymentStartDate"
					type="date"
					[errorMessage]="
						getErrorMessage(previousEmployer.controls.previousEmploymentStartDate, 'Start date', shouldValidate)
					"
				>
				</app-outlined-date-input>

				<app-outlined-date-input
					class="col-12"
					labelText="End date"
					formControlName="previousEmploymentEndDate"
					type="date"
					[errorMessage]="
						getErrorMessage(previousEmployer.controls.previousEmploymentEndDate, 'End date', shouldValidate)
					"
				>
				</app-outlined-date-input>

				<app-outlined-autocomplete
					class="col-12"
					labelText="Job category"
					formControlName="previousJobCategory"
					[options]="selectOptions?.jobCategories"
					[errorMessage]="
						getErrorMessage(previousEmployer.controls.previousJobCategory, 'Job category', shouldValidate)
					"
				>
				</app-outlined-autocomplete>

				<app-outlined-autocomplete
					class="col-12"
					labelText="Job title"
					formControlName="previousJobTitle"
					[options]="selectOptions?.jobTitles"
					[errorMessage]="getErrorMessage(previousEmployer.controls.previousJobTitle, 'Job title', shouldValidate)"
					[allowOther]="true"
				>
				</app-outlined-autocomplete>

				<app-outlined-input
					class="col-12"
					labelText="Please specify the job title"
					formControlName="previousOtherJobTitle"
					*ngIf="previousEmployer.controls.previousJobTitle.value === 'OTH'"
					[maxLength]="entityConfig.currentEmployment.maxOtherJobTitleLength"
					type="text"
					[errorMessage]="getErrorMessage(form.controls.previousOtherJobTitle, 'Job title', shouldValidate)"
				>
				</app-outlined-input>

				<app-outlined-dropdown
					class="col-12"
					labelText="Reason for leaving"
					formControlName="previousReasonForLeaving"
					[options]="selectOptions?.reasonsForLeaving"
					[errorMessage]="
						getErrorMessage(previousEmployer.controls.previousReasonForLeaving, 'Reason for leaving', shouldValidate)
					"
				>
				</app-outlined-dropdown>

				<app-outlined-text-area
					class="col-12"
					labelText="Short description of roles & responsibilities"
					formControlName="previousDuties"
					[maxLength]="dutiesDescriptionLength"
					[errorMessage]="getErrorMessage(previousEmployer.controls.previousDuties, 'Previous duties')"
				>
				</app-outlined-text-area>
			</div>
		</div>

		<div class="col-12 mt-2 mb-4">
			<app-square-button-with-icon
				theme="m1"
				value="Add employer"
				class="w-auto pr-0 pl-2 float-right"
				type="button"
				icon="add"
				(click)="addPreviousEmployer()"
				[disabled]="false"
			>
			</app-square-button-with-icon>
			<app-square-button-with-icon
				*ngIf="numberOfPreviousEmployers > 0"
				theme="m5"
				value="Delete employer"
				class="w-auto pr-0 float-right"
				type="button"
				icon="remove"
				(click)="removePreviousEmployer()"
			>
			</app-square-button-with-icon>
		</div>

		<div
			class="row"
			*ngIf="numberOfPreviousEmployers === 0 && form.controls.unemployed.value && form.controls.student.value"
		>
			<div class="col-12 mt-2 mb-4">
				<app-checkbox formControlName="addReference" text="I would like to add references."> </app-checkbox>
			</div>
		</div>

		<!-- References -->
		<div
			class="mx-0 row"
			*ngIf="numberOfPreviousEmployers > 0 || !form.controls.unemployed.value || form.controls.addReference.value"
		>
			<app-page-header-toolbar
				class="pt-2 col-12"
				icon="supervisor_account"
				heading="References (Please add all 3 References to continue)"
				[showTip]="true"
				[toolTip]="
					'1: You are required to choose 3 different types of references from the available list being: Client, Colleague, HR, Supplier, Manager \n' +
					'2. Please note that by inputting this reference you are giving Flink permission to contact these references\n'
				"
			>
			</app-page-header-toolbar>

			<div class="col-12 mt-3">
				<h3 class="col-12 front-sub-4 font-medium p-0">Reference 1</h3>
			</div>

			<app-outlined-input
				class="col-12"
				labelText="Name & Surname"
				formControlName="referenceName1"
				type="text"
				[maxLength]="entityConfig.reference.maxNameLength"
				[errorMessage]="getErrorMessage(form.controls.referenceName1, 'Name', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-dropdown
				class="col-12"
				labelText="Relationship"
				formControlName="referenceRelationship1"
				[options]="selectOptions?.referenceRelationships"
				[errorMessage]="getErrorMessage(form.controls.referenceRelationship1, 'Relationship', shouldValidate)"
			>
			</app-outlined-dropdown>

			<app-outlined-autocomplete
				class="col-12"
				labelText="Job title"
				formControlName="referenceJobTitle1"
				[options]="selectOptions?.jobTitles"
				[allowOther]="true"
				[errorMessage]="getErrorMessage(form.controls.referenceJobTitle1, 'Job title', shouldValidate)"
			>
			</app-outlined-autocomplete>

			<app-outlined-input
				class="col-12"
				labelText="Please specify another job title"
				*ngIf="form.controls.referenceJobTitle1.value === 'OTH'"
				[maxLength]="entityConfig.currentEmployment.maxOtherJobTitleLength"
				formControlName="referenceOtherJobTitle1"
				type="text"
				[errorMessage]="getErrorMessage(form.controls.referenceOtherJobTitle1, 'Other job title', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Email address"
				formControlName="referenceEmail1"
				[maxLength]="entityConfig.flinkCo.maxEmailAddressLength"
				type="email"
				[errorMessage]="getErrorMessage(form.controls.referenceEmail1, 'Email address', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Contact number"
				formControlName="referenceContactNumber1"
				[maxLength]="entityConfig.flinkCo.maxContactNumberLength"
				type="text"
				[errorMessage]="getErrorMessage(form.controls.referenceContactNumber1, 'Contact number', shouldValidate)"
			>
			</app-outlined-input>

			<div class="col-12 mt-3">
				<h3 class="col-12 front-sub-4 font-medium p-0">Reference 2</h3>
			</div>

			<app-outlined-input
				class="col-12"
				labelText="Name & Surname"
				formControlName="referenceName2"
				type="text"
				[maxLength]="entityConfig.reference.maxNameLength"
				[errorMessage]="getErrorMessage(form.controls.referenceName2, 'Name', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-dropdown
				class="col-12"
				labelText="Relationship"
				formControlName="referenceRelationship2"
				[options]="selectOptions?.referenceRelationships"
				[errorMessage]="getErrorMessage(form.controls.referenceRelationship2, 'Relationship', shouldValidate)"
			>
			</app-outlined-dropdown>

			<app-outlined-autocomplete
				class="col-12"
				labelText="Job title"
				formControlName="referenceJobTitle2"
				[options]="selectOptions?.jobTitles"
				[allowOther]="true"
				[errorMessage]="getErrorMessage(form.controls.referenceJobTitle2, 'Job title', shouldValidate)"
			>
			</app-outlined-autocomplete>

			<app-outlined-input
				class="col-12"
				labelText="Please specify another job title"
				*ngIf="form.controls.referenceJobTitle2.value === 'OTH'"
				formControlName="referenceOtherJobTitle2"
				[maxLength]="entityConfig.currentEmployment.maxOtherJobTitleLength"
				type="text"
				[errorMessage]="getErrorMessage(form.controls.referenceOtherJobTitle2, 'Other job title', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Email address"
				formControlName="referenceEmail2"
				type="email"
				[maxLength]="entityConfig.flinkCo.maxEmailAddressLength"
				[errorMessage]="getErrorMessage(form.controls.referenceEmail2, 'Email address', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Contact number"
				formControlName="referenceContactNumber2"
				type="text"
				[maxLength]="entityConfig.flinkCo.maxContactNumberLength"
				[errorMessage]="getErrorMessage(form.controls.referenceContactNumber2, 'Contact number', shouldValidate)"
			>
			</app-outlined-input>

			<div class="col-12 mt-3">
				<h3 class="col-12 front-sub-4 font-medium p-0">Reference 3</h3>
			</div>

			<app-outlined-input
				class="col-12"
				labelText="Name & Surname"
				formControlName="referenceName3"
				type="text"
				[maxLength]="entityConfig.reference.maxNameLength"
				[errorMessage]="getErrorMessage(form.controls.referenceName3, 'Name', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-dropdown
				class="col-12"
				labelText="Relationship"
				formControlName="referenceRelationship3"
				[options]="selectOptions?.referenceRelationships"
				[errorMessage]="getErrorMessage(form.controls.referenceRelationship3, 'Relationship', shouldValidate)"
			>
			</app-outlined-dropdown>

			<app-outlined-autocomplete
				class="col-12"
				labelText="Job title"
				formControlName="referenceJobTitle3"
				[options]="selectOptions?.jobTitles"
				[allowOther]="true"
				[errorMessage]="getErrorMessage(form.controls.referenceJobTitle3, 'Job title', shouldValidate)"
			>
			</app-outlined-autocomplete>

			<app-outlined-input
				class="col-12"
				labelText="Please specify another job title"
				*ngIf="form.controls.referenceJobTitle3.value === 'OTH'"
				formControlName="referenceOtherJobTitle3"
				[maxLength]="entityConfig.currentEmployment.maxOtherJobTitleLength"
				type="text"
				[errorMessage]="getErrorMessage(form.controls.referenceOtherJobTitle3, 'Other job title', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Email address"
				formControlName="referenceEmail3"
				type="email"
				[maxLength]="entityConfig.flinkCo.maxEmailAddressLength"
				[errorMessage]="getErrorMessage(form.controls.referenceEmail3, 'Email address', shouldValidate)"
			>
			</app-outlined-input>

			<app-outlined-input
				class="col-12"
				labelText="Contact number"
				formControlName="referenceContactNumber3"
				type="text"
				[maxLength]="entityConfig.flinkCo.maxContactNumberLength"
				[errorMessage]="getErrorMessage(form.controls.referenceContactNumber3, 'Contact number', shouldValidate)"
			>
			</app-outlined-input>
		</div>
	</div>
</form>
