import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizationRoutingModule } from './authorization-routing.module';

import { SignOnContainerComponent } from './components/sign-on-container/sign-on-container.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { FlinkerRegisterComponent } from './components/flinker-register/flinker-register.component';
import { FlinkCoRegisterComponent } from './components/flink-co-register/flink-co-register.component';
import { SignInButtonGroupComponent } from './components/sign-in-button-group/sign-in-button-group.component';
import { SharedModule } from 'projects/shared/shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OtpVerifyComponent } from './components/otp-verify/otp-verify.component';
import { TokenSignInComponent } from './components/token-sign-in/token-sign-in.component';

@NgModule({
	declarations: [
		SignOnContainerComponent,
		SignInComponent,
		FlinkerRegisterComponent,
		FlinkCoRegisterComponent,
		ForgotPasswordComponent,
		SignInButtonGroupComponent,
		ResetPasswordComponent,
		OtpVerifyComponent,
		TokenSignInComponent,
	],
	imports: [
		CommonModule,
		AuthorizationRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		MatIconModule,
		HttpClientModule,
	],
})
export class AuthorizationModule {}
