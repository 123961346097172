import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlinkAdminService } from '@app/services/flink-admin/flink-admin.service';
import { FlinkCoAdminStoreActions } from '.';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import FlinkCoResponse from '@flinkadmin-dashboard/models/response/flink-co-admin-response.dto';
import {
	AddAdhocSubscription,
	AddFlinkerToSearchResult,
	AddRetainerSubscription,
	CancelRetainerSubscription,
	DeleteFlinkCo,
	FetchActiveSearch,
	FetchAllFlinkCos,
	FetchAllQuotes,
	FetchFlinkCoSearches,
	GetSearchResult,
	GetSelectedFlinkCo,
	LoginAsFlinkCo,
	RemoveFlinkerFromSearchResult,
	SubmitFlinkCoSubscription,
	UpdateFlinkCo,
} from './actions';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { QuoteStoreActions } from '@root-store/quote-store';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import AdminSearchResponse from '@flinkadmin-dashboard/models/response/admin-search-response.dto';
import AdminSearchResultResponseDto from '@flinkadmin-dashboard/models/response/admin-search-result-response.dto';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';

@Injectable()
export class FlinkCoAdminStoreEffects {
	constructor(private actions$: Actions, private flinkAdminService: FlinkAdminService) {}

	fetchAllFlinkCosEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.FetchAllFlinkCos>(FlinkCoAdminStoreActions.ActionTypes.FETCH_ALL_FLINKCOS),
			switchMap((action: FetchAllFlinkCos) =>
				this.flinkAdminService.getFlinkCoList(action.payload.flinkCoFilter).pipe(
					map(
						(result: PaginatedResponse<FlinkCoResponse>) =>
							new FlinkCoAdminStoreActions.FetchAllFlinkCosSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.FetchAllFlinkCosError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchAllQuotesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.FetchAllQuotes>(FlinkCoAdminStoreActions.ActionTypes.FETCH_ALL_QUOTES),
			switchMap((action: FetchAllQuotes) =>
				this.flinkAdminService
					.getQuoteList(action.payload.pageSize, action.payload.pageNumber, action.payload.quoteReference)
					.pipe(
						map(
							(result: PaginatedResponse<FlinkCoResponse>) =>
								new FlinkCoAdminStoreActions.FetchAllQuotesSuccess({ result }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoAdminStoreActions.FetchAllQuotesError({ errorResponse: error })),
						),
					),
			),
		);
	});

	updateFlinkCoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.UpdateFlinkCo>(FlinkCoAdminStoreActions.ActionTypes.UPDATE_FLINKCO),
			switchMap((action: UpdateFlinkCo) =>
				this.flinkAdminService.updateFlinkCo(action.payload.flinkCo).pipe(
					map((_) => new FlinkCoAdminStoreActions.UpdateFlinkCoSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.UpdateFlinkCoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	submitFlinkCoSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.SubmitFlinkCoSubscription>(
				FlinkCoAdminStoreActions.ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION,
			),
			switchMap((action: SubmitFlinkCoSubscription) =>
				this.flinkAdminService
					.submitSubscriptionChoice(action.payload.flinkCoId, action.payload.flinkCoSubscriptionRequest)
					.pipe(
						map(
							(result: FlinkCoSubscriptionResponse) =>
								new FlinkCoAdminStoreActions.SubmitFlinkCoSubscriptionSuccess({
									result,
								}),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoAdminStoreActions.SubmitFlinkCoSubscriptionError({ errorResponse: error })),
						),
					),
			),
		);
	});

	addAdhocSubscription$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.AddAdhocSubscription>(FlinkCoAdminStoreActions.ActionTypes.ADD_ADHOC_SUBCRIPTION),
			switchMap((action: AddAdhocSubscription) =>
				this.flinkAdminService.addAdhocSubscription(action.payload.flinkCoId, action.payload.adhocInformation).pipe(
					map(
						(result: AdHocInformationResponse) => new QuoteStoreActions.GetAdHocQuoteSuccess({ result: result.quote }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.AddAdhocSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	addRetainerSubscription$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.AddRetainerSubscription>(
				FlinkCoAdminStoreActions.ActionTypes.ADD_RETAINER_SUBCRIPTION,
			),
			switchMap((action: AddRetainerSubscription) =>
				this.flinkAdminService
					.addRetainerSubscription(action.payload.flinkCoId, action.payload.retainerInformation)
					.pipe(
						map(
							(result: RetainerInformationResponse) =>
								new QuoteStoreActions.GetRetainerQuoteSuccess({ result: result.quote }),
						),
						catchError((error: ErrorResponse) =>
							of(new FlinkCoAdminStoreActions.AddRetainerSubscriptionError({ errorResponse: error })),
						),
					),
			),
		);
	});

	cancelRetainerSubscription$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.CancelRetainerSubscription>(
				FlinkCoAdminStoreActions.ActionTypes.CANCEL_RETAINER_SUBCRIPTION,
			),
			switchMap((action: CancelRetainerSubscription) =>
				this.flinkAdminService.cancelRetainerSubscription(action.payload.flinkCoId).pipe(
					map((_) => new FlinkCoAdminStoreActions.CancelRetainerSubscriptionSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.CancelRetainerSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	loginAsFlinkCoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.LoginAsFlinkCo>(FlinkCoAdminStoreActions.ActionTypes.LOGIN_AS_FLINKCO),
			switchMap((action: LoginAsFlinkCo) =>
				this.flinkAdminService.loginAsFlinkCo(action.payload.flinkCoId).pipe(
					map((result: SignInResponse) => {
						return new FlinkCoAdminStoreActions.LoginAsFlinkCoSuccess({
							result: { ...result, flinkCoId: action.payload.flinkCoId },
						});
					}),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.LoginAsFlinkCoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	deleteFlinkCoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.DeleteFlinkCo>(FlinkCoAdminStoreActions.ActionTypes.DELETE_FLINKCO),
			switchMap((action: DeleteFlinkCo) =>
				this.flinkAdminService.deleteFlinkCo(action.payload.flinkCoId).pipe(
					map(
						(response: any) =>
							new FlinkCoAdminStoreActions.DeleteFlinkCoSuccess({ result: 'Flinker deleted successfully' }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.DeleteFlinkCoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getSelectedFlinkCoEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.GetSelectedFlinkCo>(FlinkCoAdminStoreActions.ActionTypes.GET_SELECTED_FLINKCO),
			switchMap((action: GetSelectedFlinkCo) =>
				this.flinkAdminService.getSelectedFlinkCo(action.payload.flinkCoId).pipe(
					map((result: FlinkCoResponse) => new FlinkCoAdminStoreActions.GetSelectedFlinkCoSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.GetSelectedFlinkCoError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchFlinkCoSearchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.FetchFlinkCoSearches>(
				FlinkCoAdminStoreActions.ActionTypes.FETCH_FLINKCO_SEARCHES,
			),
			switchMap((action: FetchFlinkCoSearches) =>
				this.flinkAdminService.fetchFlinkCoSearches(action.payload.flinkCoId).pipe(
					map((response) => new FlinkCoAdminStoreActions.FetchFlinkCoSearchesSuccess({ result: response })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.FetchFlinkCoSearchesError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchActiveSearchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.FetchActiveSearch>(FlinkCoAdminStoreActions.ActionTypes.FETCH_ACTIVE_SEARCH),
			switchMap((action: FetchActiveSearch) =>
				this.flinkAdminService.fetchActiveSearch(action.payload.flinkCoId, action.payload.searchId).pipe(
					map((result: AdminSearchResponse) => new FlinkCoAdminStoreActions.FetchActiveSearchSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.FetchActiveSearchError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getSearchResultEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.GetSearchResult>(FlinkCoAdminStoreActions.ActionTypes.GET_SEARCH_RESULT),
			switchMap((action: GetSearchResult) =>
				this.flinkAdminService.getSearchResults(action.payload.filterFlinkerRequest).pipe(
					map(
						(result: AdminSearchResultResponseDto) => new FlinkCoAdminStoreActions.GetSearchResultSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.GetSearchResultError({ errorResponse: error })),
					),
				),
			),
		);
	});

	addFlinkerToSearchResultsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.AddFlinkerToSearchResult>(
				FlinkCoAdminStoreActions.ActionTypes.ADD_FLINKER_TO_SEARCH,
			),
			switchMap((action: AddFlinkerToSearchResult) =>
				this.flinkAdminService.addFlinkerToSearchResults(action.payload.searchId, action.payload.flinkerId).pipe(
					map(
						(result: AdminMatchedFlinkerResponse) =>
							new FlinkCoAdminStoreActions.AddFlinkerToSearchResultSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.AddFlinkerToSearchResultError({ errorResponse: error })),
					),
				),
			),
		);
	});

	removeFlinkerFromSearchResultsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<FlinkCoAdminStoreActions.RemoveFlinkerFromSearchResult>(
				FlinkCoAdminStoreActions.ActionTypes.REMOVE_FLINKER_FROM_SEARCH,
			),
			switchMap((action: RemoveFlinkerFromSearchResult) =>
				this.flinkAdminService.removeFlinkerFromSearchResults(action.payload.searchId, action.payload.flinkerId).pipe(
					map(
						(result: AdminMatchedFlinkerResponse) =>
							new FlinkCoAdminStoreActions.RemoveFlinkerFromSearchResultSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new FlinkCoAdminStoreActions.RemoveFlinkerFromSearchResultError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
