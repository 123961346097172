import { createFeatureSelector, createSelector } from '@ngrx/store';
import HttpPackage from '@models/http-package';
import { CvAdminState } from './state';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

const getCvFeaturesHttpPackage = (state: CvAdminState): HttpPackage<CvFeatureResponseDto[]> =>
	state.cvFeaturesHttpPackage;

const getUpdateCvFeaturePriceHttpPackage = (state: CvAdminState): HttpPackage<CvFeatureResponseDto> =>
	state.updateCvFeaturePriceHttpPackage;

export const selectCvState = createFeatureSelector<CvAdminState>('cvAdminDashboard');

export const selectCvFeatures = createSelector(selectCvState, getCvFeaturesHttpPackage);
export const selectUpdateCvFeaturePrice = createSelector(selectCvState, getUpdateCvFeaturePriceHttpPackage);
