import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import moment from 'moment';
import { getInterviewStatusColour, InterviewStatus } from 'projects/flink-app/src/app/enums/interview-status.enum';
import InterviewResponse from 'projects/flink-app/src/app/models/dto/responses/interview-response.dto';
import {
	getSelectOptionName,
	getSelectOptionNameById,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { InterviewStoreActions, InterviewStoreSelectors } from 'projects/flink-app/src/app/root-store/interview-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RejectInterviewDialogComponent } from '../../dialogs/reject-interview-dialog/reject-interview-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
	selector: 'app-flinker-upcoming-interviews',
	templateUrl: './upcoming-interviews.component.html',
	styleUrls: ['./upcoming-interviews.component.scss'],
})
export class UpcomingInterviewsComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort) set matSort(sort: MatSort) {
		if (!this.dataSource.sort) {
			this.dataSource.sortingDataAccessor = (item, property) => {
				switch (property) {
					case 'company':
						return item.flinkCo.registeredName;
					case 'date':
						return moment(item.startDate).toISOString();
					case 'time':
						return item.startDate ? moment(item.startDate).toISOString() : '';
					default:
						return Object.prototype.hasOwnProperty.call(item, property) ? (item as Record<string, any>)[property] : '';
				}
			};
			this.dataSource.sort = sort;
		}
	}
	subscriptions = new Subscription();
	displayedColumns: string[] = ['company', 'date', 'time', 'location', 'status', 'actions'];
	getFlinkerInterviewsHttpPackage$ = this.store.select(InterviewStoreSelectors.selectGetFlinkerInterviewsHttpPackage);
	flinkerInterviews: InterviewResponse[];
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	updateInterviewHttpRequest$ = this.store.select(InterviewStoreSelectors.selectUpdateInterviewStatusHttpPackage);

	dataSource = new MatTableDataSource<InterviewResponse>();
	InterviewStatus = InterviewStatus;
	getSelectOptionName = getSelectOptionName;
	getSelectOptionNameById = getSelectOptionNameById;
	getInterviewStatusColour = getInterviewStatusColour;

	constructor(
		private store: Store<RootStoreState.State>,
		public dialog: MatDialog,
		private notifierService: NotifierService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.getFlinkerInterviewsHttpPackage$.subscribe((getFlinkerInterviewsHttpPackage) => {
				if (getFlinkerInterviewsHttpPackage.result !== null) {
					this.flinkerInterviews = getFlinkerInterviewsHttpPackage.result;
					this.dataSource = new MatTableDataSource<InterviewResponse>(
						getFlinkerInterviewsHttpPackage.result.filter(
							(interview) =>
								(moment(interview.endDate).isAfter(moment()) || interview.endDate == null) &&
								interview.status !== InterviewStatus.Rejected &&
								interview.status !== InterviewStatus.Cancelled &&
								interview.status !== InterviewStatus.Expired,
						),
					);
				}
			}),
		);
	}

	acceptInterview(interviewId: string, event: any): void {
		this.subscriptions.add(
			this.updateInterviewHttpRequest$
				.pipe(filter((value) => value.result !== null))
				.pipe(take(1))
				.subscribe((_) => {
					this.notifierService.notify('success', 'Interview successfully updated');
					this.store.dispatch(new InterviewStoreActions.GetFlinkerInterviews());
				}),
		);
		this.store.dispatch(
			new InterviewStoreActions.UpdateInterviewStatus({
				interviewId,
				interviewStatusRequest: {
					status: this.flinkerInterviews.find((interview) => interview.id === interviewId).endDate
						? InterviewStatus.Confirmed
						: InterviewStatus.Accepted,
				},
			}),
		);
		event.stopPropagation();
	}

	rejectInterview(interviewId: string, event: any): void {
		const dialogRef = this.dialog.open(RejectInterviewDialogComponent, { data: { interviewId }, minWidth: '30%' });
		event.stopPropagation();
	}

	requestReschedule(interviewId: string, event: any): void {
		this.store.dispatch(
			new InterviewStoreActions.UpdateInterviewStatus({
				interviewId,
				interviewStatusRequest: {
					status: InterviewStatus.RescheduleRequested,
				},
			}),
		);
		event.stopPropagation();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
