import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
} from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	ControlContainer,
	AbstractControl,
	FormControl,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SelectOptionResponse } from 'projects/flink-app/src/app/models/dto/responses/select-option-response.dto';
import { getSelectOptionName } from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';

export const _filter = (opt: string[], value: string): string[] => {
	const filterValue = value.toLowerCase();

	return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
	selector: 'app-outlined-autocomplete',
	templateUrl: './outlined-autocomplete.component.html',
	styleUrls: ['./outlined-autocomplete.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedAutocompleteComponent),
			multi: true,
		},
	],
})
export class OutlinedAutocompleteComponent implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() labelText = '';
	@Input() errorMessage = '';
	@Input() formControlName = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';
	@Input() options: SelectOptionResponse[] = [];
	@Input() allowOther = false;
	@Input() hint = '';
	@Input() toolTip = '';
	@Input() showTip = false;

	value: string;
	required = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;
	myControl = new FormControl();
	filteredOptions: Observable<SelectOptionResponse[]>;
	getSelectOptionName = getSelectOptionName;
	@Output() selectionChange = new EventEmitter();

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		if (!this.allowOther) {
			this.options = this.options?.filter((selectOption) => selectOption.code !== 'OTH');
		}

		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => this._filter(value)),
			takeUntil(this.destroy$),
		);

		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;
		if (validator && validator.required) {
			this.required = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const options: SimpleChange = changes.options;
		if (options?.currentValue !== null && options?.previousValue === null) {
			this.myControl.setValue(getSelectOptionName(this.options, this.value));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private _filter(value: string): SelectOptionResponse[] {
		const filterValue = value?.toLowerCase();
		return this.options?.filter((option) => option.name?.toLowerCase().includes(filterValue));
	}

	public writeValue(value: string): void {
		this.value = value;
		this.myControl.setValue(getSelectOptionName(this.options, value));
	}

	input(event: MatAutocompleteSelectedEvent): void {
		this.value = this.options?.find((option) => option.name === event.option.value)?.code;
		this.selectionChange.emit(this.value);
		this.onChange(this.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.myControl.disable() : this.myControl.enable();
		this.disabled = isDisabled;
	}

	clearOnEmptyString() {
		this.onChange('');
	}

	clearValue() {
		this.value = '';
		this.onChange('');
		this.myControl.setValue('');
	}

	onBlur(): void {
		this.onTouched();
	}

	hasOtherOption(): boolean {
		return this.options?.some((option) => option.code == 'OTH');
	}

	onOther(): void {
		this.myControl.setValue('Other');
		this.onChange('OTH');
	}
}
