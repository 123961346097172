import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-dashboard-section-header',
	templateUrl: './dashboard-section-header.component.html',
	styleUrls: ['./dashboard-section-header.component.scss'],
})
export class DashboardSectionHeaderComponent implements OnInit {
	@Input() title = '';
	@Input() icon = '';
	@Input() editIcon: boolean;

	@Output() edit = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
}
