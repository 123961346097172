import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
	@Input() text = '';
	@Input() textSpan = '';
	@Input() link = '';
	@Input() errorMessage = '';
	@Input() checked = false;

	onChange: any = () => {};
	onTouched: any = () => {};
	@Output() onChecked = new EventEmitter<boolean>();
	disabled = false;

	constructor() {}

	ngOnInit(): void {}

	public writeValue(value: boolean): void {
		this.checked = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	onClick() {
		this.checked = !this.checked;
		this.onChange(this.checked);
		this.onChecked.emit(this.checked);
	}
}
