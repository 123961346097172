import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-thin-outlined-input',
	templateUrl: './thin-outlined-input.component.html',
	styleUrls: ['./thin-outlined-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ThinOutlinedInputComponent),
			multi: true,
		},
	],
})
export class ThinOutlinedInputComponent implements OnInit, ControlValueAccessor {
	@Input() placeholder = '';
	@Input() type = 'password';
	@Input() errorMessage = '';
	@Input() formValue = '';

	localType = '';
	value = '';
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	constructor() {}

	ngOnInit(): void {}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
