import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { getInterviewStatusColour, InterviewStatus } from 'projects/flink-app/src/app/enums/interview-status.enum';
import InterviewResponse from 'projects/flink-app/src/app/models/dto/responses/interview-response.dto';
import {
	getSelectOptionName,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { InterviewStoreActions, InterviewStoreSelectors } from 'projects/flink-app/src/app/root-store/interview-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { Subscription } from 'rxjs';
import { RejectInterviewDialogComponent } from '../../dialogs/reject-interview-dialog/reject-interview-dialog.component';
import { DictionaryWords } from '../../../../enums/dictionary-words.enum';

@Component({
	selector: 'app-interview-detailed',
	templateUrl: './interview-detailed.component.html',
	styleUrls: ['./interview-detailed.component.scss'],
})
export class InterviewDetailedComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	getFlinkerInterviewHttpPackage$ = this.store.select(InterviewStoreSelectors.selectGetFlinkerInterviewHttpPackage);
	flinkerInterview: InterviewResponse;
	updateInterviewStatusHttpPackage$ = this.store.select(InterviewStoreSelectors.selectUpdateInterviewStatusHttpPackage);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	interviewId = '';
	InterviewStatus = InterviewStatus;
	getSelectOptionName = getSelectOptionName;
	getSelectOptionNameById = getSelectOptionNameById;
	getInterviewStatusColour = getInterviewStatusColour;
	moment = moment;
	expectResult = false;
	websitelink = '';
	pattern = /^((http|https):\/\/)/;
	dictionaryWords = DictionaryWords;

	constructor(
		private store: Store<RootStoreState.State>,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		private notifierService: NotifierService,
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.route.paramMap.subscribe((paramMap: ParamMap) => {
				this.interviewId = paramMap.get('interviewId');
				this.store.dispatch(new InterviewStoreActions.GetFlinkerInterview({ interviewId: this.interviewId }));
			}),
		);

		this.subscriptions.add(
			this.updateInterviewStatusHttpPackage$.subscribe((updateInterviewStatusHttpPackage) => {
				if (updateInterviewStatusHttpPackage.loading) {
					this.expectResult = true;
				}

				if (updateInterviewStatusHttpPackage.result !== null && this.expectResult) {
					this.notifierService.notify('success', 'Interview updated successfully');
					this.expectResult = false;
				}
			}),
		);

		this.subscriptions.add(
			this.getFlinkerInterviewHttpPackage$.subscribe((getFlinkerInterviewHttpPackage) => {
				if (getFlinkerInterviewHttpPackage.result !== null) {
					this.flinkerInterview = getFlinkerInterviewHttpPackage.result;
				}
			}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				this.selectOptions = selectOptionsResponse.result;
			}),
		);
	}

	acceptInterview(): void {
		this.store.dispatch(
			new InterviewStoreActions.UpdateInterviewStatus({
				interviewId: this.interviewId,
				interviewStatusRequest: {
					status: this.flinkerInterview.endDate ? InterviewStatus.Confirmed : InterviewStatus.Accepted,
				},
			}),
		);
	}

	rejectInterview(): void {
		this.dialog.open(RejectInterviewDialogComponent, {
			data: { interviewId: this.interviewId },
			minWidth: '30%',
		});
	}

	requestReschedule(): void {
		this.store.dispatch(
			new InterviewStoreActions.UpdateInterviewStatus({
				interviewId: this.interviewId,
				interviewStatusRequest: {
					status: InterviewStatus.RescheduleRequested,
				},
			}),
		);
	}

	getJobTitle(jobTitleId: string, otherJobTitle: string): string {
		return this.selectOptions?.jobTitles.find((jobTitle) => jobTitle.id === jobTitleId)?.code === 'OTH'
			? otherJobTitle
			: this.selectOptions?.jobTitles.find((jobTitle) => jobTitle.id === jobTitleId)?.name;
	}

	showAcceptInterview(status: InterviewStatus): boolean {
		return (
			status !== InterviewStatus.Accepted &&
			status !== InterviewStatus.Confirmed &&
			status !== InterviewStatus.VerificationPending &&
			status !== InterviewStatus.VerificationFailed &&
			status !== InterviewStatus.Rejected
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
