<mat-sidenav-container autosize>
	<mat-sidenav
		#sidenav
		[opened]="!mobileQuery.matches"
		[mode]="mobileQuery.matches ? 'over' : 'side'"
		[fixedInViewport]="mobileQuery.matches"
	>
		<mat-nav-list>
			<img
				src="assets/img/flink-logo-blue-01.png"
				alt="Flink"
				class="side-nav-logo"
				(click)="isExpanded = !isExpanded"
				*ngIf="isExpanded"
			/>
			<img
				src="assets/img/flink-logo-blue-icon-01.png"
				alt="Flink"
				class="side-nav-logo-icon"
				(click)="isExpanded = !isExpanded"
				*ngIf="!isExpanded"
			/>

			<mat-list-item routerLink="profile">
				<mat-icon mat-list-icon>supervisor_account</mat-icon>
				<span class="mat-list-text" *ngIf="isExpanded">Profile</span>
			</mat-list-item>

			<mat-list-item routerLink="calendar" *ngIf="roleAuthService.accountIsActive()">
				<mat-icon mat-list-icon>question_answer</mat-icon>
				<span class="mat-list-text" *ngIf="isExpanded">Interviews</span>
			</mat-list-item>

			<mat-list-item routerLink="offers" [routerLinkActive]="'active'" *ngIf="roleAuthService.accountIsActive()">
				<mat-icon mat-list-icon>redeem</mat-icon>
				<span class="mat-list-text" *ngIf="isExpanded">Offers</span>
			</mat-list-item>

			<mat-list-item routerLink="cv" [routerLinkActive]="'active'">
				<mat-icon mat-list-icon>insert_drive_file</mat-icon>
				<span class="mat-list-text" *ngIf="isExpanded">CV</span>
			</mat-list-item>

			<mat-list-item routerLink="job-adverts" *ngIf="roleAuthService.accountIsActive()">
				<mat-icon mat-list-icon>work</mat-icon>
				<span class="mat-list-text" *ngIf="isExpanded">Jobs</span>
			</mat-list-item>
		</mat-nav-list>
	</mat-sidenav>
	<div class="content-container">
		<mat-toolbar fixed>
			<button *ngIf="mobileQuery.matches" mat-icon-button (click)="sidenav.toggle()">
				<mat-icon>menu</mat-icon>
			</button>
			<div class="spacer"></div>
			<app-notification-banner *ngIf="!roleAuthService.accountIsActive()">
				<div class="d-flex">
					<p class="front-main-5 font-regular font-size-11 d-none d-md-flex pt-2">
						Your account is inactive, most of your features are unavailable and will only be available upon reactivation
						<a
							class="front-main-5 font-bold text-underline cursor-pointer"
							theme="s1"
							value="Learn more"
							(click)="openDialog()"
							>Activate account</a
						>
					</p>
				</div>
			</app-notification-banner>

			<button mat-button [matMenuTriggerFor]="menu" class="d-flex">
				<div class="d-flex">
					<app-user-picture
						theme="mr-2 my-1 pr-3"
						*ngIf="flinker$ | async as flinker"
						[src]="flinker?.picture"
						[size]="40"
						[alt]="flinker?.name + ' ' + flinker?.surname"
						[round]="true"
					>
					</app-user-picture>
					<span class="pt-1"> Hello, {{ flinker?.name }} </span>
					<mat-icon class="pt-2 pl-1">keyboard_arrow_down</mat-icon>
				</div>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item class="menu-dropdown menu-dropdown-title" routerLink="profile">My Profile</button>
				<hr class="underline" />
				<button mat-menu-item class="menu-dropdown" (click)="logout()">Logout</button>
			</mat-menu>
		</mat-toolbar>

		<router-outlet></router-outlet>
	</div>
</mat-sidenav-container>
