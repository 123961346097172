import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { FlinkCoJobAdvertsReducer } from './reducer';
import { FlinkCoJobAdvertsStoreEffects } from './effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinkco-job-adverts', FlinkCoJobAdvertsReducer),
		EffectsModule.forFeature([FlinkCoJobAdvertsStoreEffects]),
	],
	providers: [FlinkCoJobAdvertsStoreEffects],
})
export class FlinkCoJobAdvertsModule {}
