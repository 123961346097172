<mat-icon [ngClass]="{ 'icon-error': errorMessage.length > 0 }" matPrefix>
	{{ icon }}
</mat-icon>
<input
	class="rounded-input"
	[ngClass]="theme"
	[ngClass]="{ error: errorMessage.length > 0 }"
	[type]="localType"
	[placeholder]="placeholder"
	[disabled]="disabled"
	(input)="input($event)"
	(blur)="onBlur()"
/>
<mat-icon
	class="password-visibility-icon"
	[ngClass]="{ 'icon-error': errorMessage.length > 0 }"
	*ngIf="type === 'password'"
	(click)="togglePasswordVisibility()"
>
	{{ localType === type ? 'visibility_off' : 'visibility' }}
</mat-icon>
<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
