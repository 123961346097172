<div class="ghost-input">
	<input
		matInput
		[type]="localType"
		[placeholder]="placeholder"
		[disabled]="disabled"
		(input)="input($event)"
		(blur)="onBlur()"
		[value]="value"
	/>
</div>
<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
