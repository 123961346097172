import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { delayWhen, filter, takeUntil } from 'rxjs/operators';
import { FlinkerCvPaymentDialogComponent } from '@flinker-dashboard/components/dialogs/flinker-cv-payment-dialog/flinker-cv-payment-dialog.component';
import { Subject } from 'rxjs';
import { CvStoreActions, CvStoreSelectors } from '@root-store/cv-store';
import { DownloadedFile } from '@app/services/http/http-v2.service';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { DownloadMyFlinkerCvClearState } from '@root-store/cv-store/actions';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';

@Component({
	selector: 'app-flinker-cv-download',
	templateUrl: './flinker-cv-download.component.html',
	styleUrls: ['./flinker-cv-download.component.scss'],
})
export class FlinkerCvDownloadComponent implements OnInit {
	private readonly destroy$ = new Subject<void>();

	@Input() theme = '';

	activeStep = 0;
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker).pipe(
		filter((flinker: FlinkerResponse) => flinker !== null),
		takeUntil(this.destroy$),
	);
	flinker: FlinkerResponse;

	cvDownloadHttpPackage$ = this.store.select(CvStoreSelectors.selectDownloadFlinkerCv).pipe(takeUntil(this.destroy$));
	payForCvHttPackage$ = this.store.select(CvStoreSelectors.selectPayForCv).pipe(takeUntil(this.destroy$));

	paymentSuccessReceived = false;
	readonly automaticDownloadTriggerDelay = 1000 * 10;
	readonly flinkerDashboard = '/flinker/dashboard';
	constructor(
		private store: Store<RootStoreState.State>,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private notifierService: NotifierService,
	) {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
	}

	ngOnInit(): void {
		this.route.queryParams
			.pipe(
				delayWhen(() => this.flinker$),
				takeUntil(this.destroy$),
			)
			.subscribe((params) => {
				if (params && params.paymentSuccess) {
					this.paymentSuccessReceived = true;
					this.notifierService.notify(
						NotificationType.Success,
						'Payment received! Your CV download will begin shortly.',
					);
					setTimeout(() => {
						this.store.dispatch(new CvStoreActions.DownloadMyFlinkerCv());
					}, this.automaticDownloadTriggerDelay);
				}
			});

		this.cvDownloadHttpPackage$.pipe(filter((response) => response.result !== null && !response.loading)).subscribe(
			(res) => {
				const result = res.result as DownloadedFile;
				saveAs(result.file, result.filename);
				this.store.dispatch(new DownloadMyFlinkerCvClearState());
				this.paymentSuccessReceived = false;
			},
			(error) => {
				if (this.paymentSuccessReceived) {
					this.notifierService.notify(
						NotificationType.Error,
						'Failed to download your CV. Please try again in a few minutes.',
					);
				}
			},
		);

		this.flinker$.subscribe((flinker: FlinkerResponse) => {
			this.flinker = flinker;
		});
	}

	onDownloadMyCv(): void {
		const dialogRef = this.dialog.open(FlinkerCvPaymentDialogComponent, {
			width: '550px',
		});
		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((downloadPermissions: { allowed: boolean }) => {
				// Trigger download here.
				if (!downloadPermissions.allowed) {
					return;
				}
				this.store.dispatch(new CvStoreActions.DownloadMyFlinkerCv());
			});
	}
}
