export enum DictionaryWords {
	Salary = 'CTC',
}

export enum DictionaryHints {
	ProvinceSelection = 'You can select multiple provinces.',
	CitySelection = 'You can select multiple cities. Please select at least one city for each province chosen.',
	InstitutionSelection = 'If you cannot find the institution in the list, you can create it in the Other Qualifications section.',
	CurrentSalary = 'This will not be shared with prospective employers on the platform.',
	ChangeOrderOfInterviewQuestions = 'You can change the order of the questions by dragging and dropping them.',
	AddInterviewersToSearch = 'You can add multiple interviewers.',
}

export enum DictionarySuccessMessages {
	EmploymentDetailsUpdate = 'Employment details updated successfully.',
	EmploymentJobDescriptionUpload = 'Employment job descriptions uploaded successfully.',
}
