import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { filterOnParentCodes, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import HttpPackage from '@models/http-package';

export const filterCitiesByProvincesHelper = (
	selectOptionsHttpPackage$: Observable<HttpPackage<SelectOptionsResponse>>,
	provinces$: Observable<string[]>,
) =>
	combineLatest([
		selectOptionsHttpPackage$.pipe(
			filter((response) => response.result != null),
			map((response) => response.result),
		),
		provinces$,
	]).pipe(
		map(([selectOptions, provinces]: [SelectOptionsResponse, string[]]) =>
			filterOnParentCodes(selectOptions?.cities, provinces || []),
		),
	);
