import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionAuthService {
	constructor(private authService: AuthService) {}

	isAuthorized(allowedSubscriptions: string[]): boolean {
		if (allowedSubscriptions == null || allowedSubscriptions.length === 0) return true;

		const token = this.authService.getJwtToken();
		const decodedToken = this.getDecodedAccessToken(token);

		if (!decodedToken) {
			return false;
		}

		return allowedSubscriptions.includes(decodedToken.subscription);
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	getSubscription(): any {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);
			return decodedToken.subscription;
		} catch (Error) {
			return null;
		}
	}
}
