import { Injectable } from '@angular/core';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import InterviewQuestionnaireResponse from '@models/dto/responses/interview-questionnaire-response.dto';
import { DefaultInterviewQuestionCategoriesResponse } from '@models/dto/responses/default-interview-question-categories-response.dto';
import DefaultInterviewQuestionResponse from '@models/dto/responses/default-interview-question-response.dto';
import CreateInterviewQuestionnaireRequest from '@models/dto/requests/create-interview-questionnaire-request.dto';
import UpdateInterviewQuestionnaireRequest from '@models/dto/requests/update-interview-questionnaire-request.dto';

@Injectable({ providedIn: 'root' })
export class InterviewQuestionnaireService {
	private readonly interviewQuestionnaireEndpoint = 'interview-questionnaires';
	constructor(private readonly httpService: HttpV2Service) {}

	getPaginatedInterviewQuestionnaires(pageSize: number, pageNumber: number) {
		return this.httpService.postSecure<PaginatedResponse<InterviewQuestionnaireResponse>>(
			`${this.interviewQuestionnaireEndpoint}/search`,
			{ pageSize, pageNumber },
		);
	}

	getInterviewQuestionnaire(interviewQuestionnaireId: string) {
		return this.httpService.getSecure<InterviewQuestionnaireResponse>(
			`${this.interviewQuestionnaireEndpoint}/${interviewQuestionnaireId}`,
		);
	}

	getInterviewQuestionCategories() {
		return this.httpService.getSecure<DefaultInterviewQuestionCategoriesResponse>(
			`${this.interviewQuestionnaireEndpoint}/interview-questions/categories`,
		);
	}

	getDefaultInterviewQuestionByCategory(category: string) {
		return this.httpService.getSecure<DefaultInterviewQuestionResponse[]>(
			`${this.interviewQuestionnaireEndpoint}/interview-questions?category=${category}`,
		);
	}

	createInterviewQuestionnaire(createInterviewQuestionnaireRequest: CreateInterviewQuestionnaireRequest) {
		return this.httpService.postSecure<InterviewQuestionnaireResponse>(
			`${this.interviewQuestionnaireEndpoint}`,
			createInterviewQuestionnaireRequest,
		);
	}

	updateInterviewQuestionnaire(
		interviewQuestionnaireId: string,
		updateInterviewQuestionnaireRequest: UpdateInterviewQuestionnaireRequest,
	) {
		return this.httpService.putSecure<InterviewQuestionnaireResponse>(
			`${this.interviewQuestionnaireEndpoint}/${interviewQuestionnaireId}`,
			updateInterviewQuestionnaireRequest,
		);
	}

	deleteInterviewQuestionnaire(interviewQuestionnaireId: string) {
		return this.httpService.deleteSecure<InterviewQuestionnaireResponse>(
			`${this.interviewQuestionnaireEndpoint}/${interviewQuestionnaireId}`,
		);
	}

	getAllInterviewQuestionnaires() {
		return this.httpService.getSecure<InterviewQuestionnaireResponse[]>(`${this.interviewQuestionnaireEndpoint}`);
	}
}
