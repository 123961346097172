<div
  class='text-center'
  *ngIf='
		(flinkerEducationalDetailsPackage$ | async)?.loading || (uploadQualificationDocumentsHttpPackage$ | async)?.loading
	'
>
  <app-loader></app-loader>
</div>

<form
  class='container mt-5'
  [formGroup]='form'
  *ngIf='
		!(flinkerEducationalDetailsPackage$ | async)?.loading &&
		!(uploadQualificationDocumentsHttpPackage$ | async)?.loading
	'
>
  <div class='row my-4'>
    <app-checkbox
      class='col-12 col-md-4'
      formControlName='noMatricCertificate'
      text="I don't have a matric certificate."
    >
    </app-checkbox>
    <app-checkbox
      class='col-12 col-md-4'
      formControlName='unqualified'
      text="I don't have a tertiary qualification."
      *ngIf='tertiaryQualificationsFormGroup.length == 0 && !form.controls.noMatricCertificate.value'
    >
    </app-checkbox>
    <app-checkbox
      class='col-12 col-md-4'
      formControlName='student'
      text='I am still studying.'
      *ngIf='form.controls?.unqualified?.value && !form.controls?.noMatricCertificate?.value'
    >
    </app-checkbox>
  </div>

  <app-page-header-toolbar icon='school' heading='Matric certificate'
                           *ngIf='!form.controls?.noMatricCertificate?.value'>
  </app-page-header-toolbar>

  <div class='row' *ngIf='!form.controls?.noMatricCertificate?.value'>
    <app-outlined-autocomplete
      class='col-12'
      labelText='School'
      formControlName='matricCertificateInstitution'
      [options]='(selectOptionsHttpPackage$ | async)?.result?.schools'
      [allowOther]='true'
      [errorMessage]="getErrorMessage(form.controls?.matricCertificateInstitution, 'School', shouldValidate)"
    >
    </app-outlined-autocomplete>

    <app-outlined-input
      class='col-12'
      labelText='Please specify another school'
      *ngIf="form.controls?.matricCertificateInstitution.value === 'OTH'"
      formControlName='matricCertificateOtherInstitution'
      type='text'
      [errorMessage]="getErrorMessage(form.controls?.matricCertificateOtherInstitution, 'School', shouldValidate)"
    >
    </app-outlined-input>

    <app-outlined-dropdown
      class='col-12'
      labelText='Year obtained'
      formControlName='matricCertificateYearObtained'
      [options]='yearOptions'
      [errorMessage]="getErrorMessage(form.controls.matricCertificateYearObtained, 'Year obtained', shouldValidate)"
    >
    </app-outlined-dropdown>

    <app-outlined-file-input *ngIf='allowFileUploads'
                             class='col-12'
                             labelText='Upload certificate'
                             buttonInput='Upload'
                             [showTip]='true'
                             [toolTip]="
				'Upload the document showing your matric results and pass rate - issued by SACAI or The Department of Education' +
				'Png, Pdf, Jpeg, Tiff files accepted  |  No larger than 2mb'
			"
                             [uploaded]='form.controls.matricCertificateUploaded.value'
                             (fileSelected)='matricCertificate = $event'
    >
    </app-outlined-file-input>
  </div>

  <app-page-header-toolbar
    icon='school'
    heading='Tertiary qualifications'
    *ngIf='
			!form.controls.noMatricCertificate.value && (!form.controls.unqualified.value || form.controls.student.value)
		'
  >
  </app-page-header-toolbar>

  <div
    class='row'
    *ngIf='
			!form.controls.unqualified.value || (form.controls.student.value && !form.controls.noMatricCertificate.value)
		'
  >
    <div *ngFor='let tertiaryQualification of tertiaryQualificationsFormGroup; let i = index' class='col-12'>
      <div [formGroup]='tertiaryQualification' class='row'>
        <h2 class='col-12 front-sub-4 font-medium'>Tertiary qualification {{ i + 1 }}</h2>

        <app-checkbox
          class='col-12 col-md-6 my-3'
          formControlName='tertiaryQualificationInProgress'
          text='I am still studying.'
        >
        </app-checkbox>

        <app-checkbox *ngIf='!tertiaryQualification?.controls?.tertiaryQualificationInProgress?.value'
                      class='col-12 col-md-6 my-3'
                      formControlName='tertiaryQualificationHighest'
                      text='This is my highest qualification'
        >
        </app-checkbox>

        <app-outlined-autocomplete
          class='col-12'
          labelText='Qualification category'
          formControlName='tertiaryQualificationCategory'
          [options]='(selectOptionsHttpPackage$ | async)?.result?.qualificationCategories'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationCategory,
							'Qualification category',
							shouldValidate
						)
					"
        >
        </app-outlined-autocomplete>

        <app-outlined-input
          class='col-12'
          labelText='Qualification name'
          formControlName='tertiaryQualificationName'
          type='text'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationName,
							'Qualification name',
							shouldValidate
						)
					"
        >
        </app-outlined-input>

        <app-outlined-autocomplete
          class='col-12'
          labelText='Institution'
          formControlName='tertiaryQualificationInstitution'
          [allowOther]='true'
          [toolTip]='dictionaryHints.InstitutionSelection'
          [showTip]='true'
          [options]='(selectOptionsHttpPackage$ | async)?.result?.institutions'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationInstitution,
							'Institution',
							shouldValidate
						)
					"
        >
        </app-outlined-autocomplete>

        <app-outlined-input
          class='col-12'
          labelText='Please specify another institution'
          *ngIf="tertiaryQualification.controls.tertiaryQualificationInstitution.value === 'OTH'"
          formControlName='tertiaryQualificationOtherInstitution'
          type='text'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationOtherInstitution,
							'Institution',
							shouldValidate
						)
					"
        >
        </app-outlined-input>

        <app-outlined-dropdown
          class='col-12'
          labelText='Year obtained'
          *ngIf='!tertiaryQualification.controls.tertiaryQualificationInProgress.value'
          formControlName='tertiaryQualificationYearObtained'
          [options]='yearOptions'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationYearObtained,
							'Year obtained',
							shouldValidate
						)
					"
        >
        </app-outlined-dropdown>

        <app-outlined-dropdown
          class='col-12'
          labelText='Expected completion year'
          *ngIf='tertiaryQualification.controls.tertiaryQualificationInProgress.value'
          formControlName='tertiaryQualificationCompletionYear'
          [options]='futureYearOptions'
          [errorMessage]="
						getErrorMessage(
							tertiaryQualification.controls.tertiaryQualificationCompletionYear,
							'Expected completion year',
							shouldValidate
						)
					"
        >
        </app-outlined-dropdown>

        <app-outlined-file-input
          class='col-12'
          labelText='Upload qualification'
          buttonInput='Upload'
          [showTip]='true'
          [toolTip]="
						'Tertiary education options include university, technical and further education or private universities. Upload the document showing your tertiary qualification results and pass rate - issued by the tertiary institution.\n' +
						'Png, Pdf, Jpeg, Tiff files accepted  |  No larger than 2mb'
					"
          *ngIf='!tertiaryQualification.controls.tertiaryQualificationInProgress.value && allowFileUploads'
          [uploaded]='tertiaryQualification.controls.tertiaryQualificationUploaded.value'
          (fileSelected)='highestQualificationDocument = $event'
        >
        </app-outlined-file-input>
      </div>
    </div>
  </div>

  <div class='row'>
    <div class='col-12 mt-2 mb-4'>
      <app-square-button-with-icon
        theme='m1'
        value='Add qualification'
        class='w-auto pr-0 pl-2 float-right'
        type='button'
        icon='add'
        (click)='addTertiaryQualification()'
        *ngIf='
					tertiaryQualificationsDynamicForm.valid &&
					((!form.controls.noMatricCertificate.value && !form.controls.unqualified.value) ||
						form.controls.student.value)
				'
      >
      </app-square-button-with-icon>
      <app-square-button-with-icon
        *ngIf='numberOfTertiaryQualifications > 0'
        theme='m5'
        value='Delete qualification'
        class='w-auto pr-0 float-right'
        type='button'
        icon='remove'
        (click)='removeTertiaryQualification()'
      >
      </app-square-button-with-icon>
    </div>
  </div>

  <app-page-header-toolbar icon='school' heading='Other qualifications'></app-page-header-toolbar>

  <div class='row'>
    <div *ngFor='let otherQualification of otherQualificationsFormGroup; let i = index' class='col-12'>
      <div [formGroup]='otherQualification' class='row'>
        <h2 class='col-12 front-sub-4 font-medium'>Other qualification {{ i + 1 }}</h2>

        <app-checkbox class='col-12 my-3' formControlName='otherQualificationInProgress' text='I am still studying.'>
        </app-checkbox>

        <app-outlined-input
          class='col-12'
          labelText='Qualification name'
          formControlName='otherQualificationName'
          type='text'
          [errorMessage]="
						getErrorMessage(otherQualification.controls.otherQualificationName, 'Qualification name', shouldValidate)
					"
        >
        </app-outlined-input>

        <app-outlined-input
          class='col-12'
          labelText='Institution'
          formControlName='otherQualificationInstitution'
          [errorMessage]="
						getErrorMessage(otherQualification.controls.otherQualificationInstitution, 'Institution', shouldValidate)
					"
        >
        </app-outlined-input>

        <app-outlined-dropdown
          class='col-12'
          labelText='Year obtained'
          *ngIf='!otherQualification.controls.otherQualificationInProgress.value'
          formControlName='otherQualificationYearObtained'
          [options]='yearOptions'
          [errorMessage]="
						getErrorMessage(otherQualification.controls.otherQualificationYearObtained, 'Year obtained', shouldValidate)
					"
        >
        </app-outlined-dropdown>

        <app-outlined-dropdown
          class='col-12'
          labelText='Expected completion year'
          *ngIf='otherQualification.controls.otherQualificationInProgress.value'
          formControlName='otherQualificationCompletionYear'
          [options]='futureYearOptions'
          [errorMessage]="
						getErrorMessage(
							otherQualification.controls.otherQualificationCompletionYear,
							'Expected completion year',
							shouldValidate
						)
					"
        >
        </app-outlined-dropdown>
        <app-outlined-file-input
          class='col-12'
          labelText='Upload qualification'
          buttonInput='Upload'
          [showTip]='true'
          [toolTip]="
						'Other qualifications you have done outside of the ones mentioned above. Upload the document showing your qualification - issued by the institution.\n' +
						'Png, Pdf, Jpeg, Tiff files accepted  |  No larger than 2mb'
					"
          *ngIf='!otherQualification.controls.otherQualificationInProgress.value && allowFileUploads'
          [uploaded]='otherQualification.controls.otherQualificationUploaded.value'
          (fileSelected)='otherQualificationDocument = $event'
        >
        </app-outlined-file-input>
      </div>
    </div>
  </div>

  <div class='row'>
    <div class='col-12 mt-2 mb-4'>
      <app-square-button-with-icon
        theme='m1'
        value='Add qualification'
        class='w-auto pr-0 pl-2 float-right'
        type='button'
        icon='add'
        (click)='addOtherQualification()'
        *ngIf='otherQualificationsDynamicForm.valid'
      >
      </app-square-button-with-icon>
      <app-square-button-with-icon
        *ngIf='numberOfOtherQualifications > 0'
        theme='m5'
        value='Delete qualification'
        class='w-auto pr-0 float-right'
        type='button'
        icon='remove'
        (click)='removeOtherQualification()'
      >
      </app-square-button-with-icon>
    </div>
  </div>

  <app-page-header-toolbar icon='settings_suggest' heading='Skills' *ngIf='allowSkillsUpdate'>
  </app-page-header-toolbar>

  <div class='row' *ngIf='allowSkillsUpdate'>
    <app-chips-input
      [allowOther]='true'
      class='col-12'
      [showTip]='true'
      [toolTip]="
				'Any skill or knowledge of program you have acquired throughout the course of your career \n Examples: Microsoft Excel, Microsoft Word, Sage, Power BI'
			"
      *ngIf='skills && (selectOptionsHttpPackage$ | async)?.result?.skills'
      labelText='Please select your skills'
      [options]='(selectOptionsHttpPackage$ | async)?.result?.skills'
      (chipsChange)='skills = $event'
      [prefill]='skills'
      [hint]="'You can add other skills by typing them and pressing Enter.'"
    >
    </app-chips-input>
  </div>
</form>
