import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PsychAssessmentCompletionStatusResponse } from '../../models/dto/responses/psych-assessment-completion-status-response.dtp';
import HttpPackage from '../../models/http-package';
import { PsychAssessmentState } from './state';

const getPsychAssessmentCompletionStatusHttpPackage = (
	state: PsychAssessmentState,
): HttpPackage<PsychAssessmentCompletionStatusResponse> => state.psychAssessmentCompletionStatusHttpPackage;

export const selectPsychAssessmentCompletionStatusState =
	createFeatureSelector<PsychAssessmentState>('psychAssessment');

export const selectPsychAssessmentCompletionStatusHttpPackage = createSelector(
	selectPsychAssessmentCompletionStatusState,
	getPsychAssessmentCompletionStatusHttpPackage,
);
