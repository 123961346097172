import { Injectable } from '@angular/core';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { Observable } from 'rxjs';
import CvPaymentStatusResponseDto from '@models/dto/responses/cv-payment-status-response.dto';
import PayForCvRequestDto from '@models/dto/requests/pay-for-cv-request.dto';
import PayForCvResponseDto from '@models/dto/responses/pay-for-cv-response.dto';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

@Injectable({
	providedIn: 'root',
})
export class CvService {
	apiCvEndpoint = 'cv';
	apiPaymentEndpoint = 'payment';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getCvFeatures(): Observable<CvFeatureResponseDto[]> {
		return this.httpV2Service.getSecure(`${this.apiCvEndpoint}/features`);
	}

	getCvPaymentStatus(): Observable<CvPaymentStatusResponseDto> {
		return this.httpV2Service.getSecure(`${this.apiPaymentEndpoint}/cv/status`);
	}

	payForCv(body: PayForCvRequestDto): Observable<PayForCvResponseDto> {
		return this.httpV2Service.postSecure(`${this.apiPaymentEndpoint}/cv`, body);
	}

	downloadFlinkerCv() {
		return this.httpV2Service.downloadFileGet(`${this.apiCvEndpoint}/download`);
	}
}
