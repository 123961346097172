import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { FlinkCoNamesResponse } from 'projects/flink-app/src/app/models/dto/responses/flinkCo-names-response.dto';
import { isNil } from '@app/utils/functions.util';

export const _filter = (opt: string[], value: string): string[] => {
	const filterValue = value.toLowerCase();

	return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
	selector: 'app-outlined-experience-autocomplete',
	templateUrl: './outlined-experience-autocomplete.component.html',
	styleUrls: ['./outlined-experience-autocomplete.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedExperienceAutocompleteComponent),
			multi: true,
		},
	],
})
export class OutlinedExperienceAutocompleteComponent implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() labelText = '';
	@Input() errorMessage = '';
	@Input() formControlName = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';
	@Input() options: FlinkCoNamesResponse[] = [];
	@Input() allowOther = true;
	@Input() toolTip = '';
	@Input() showTip = false;
	@Input() maxLength = '';

	value: string;
	required = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;
	myControl = new FormControl();
	filteredOptions: Observable<FlinkCoNamesResponse[]>;
	@Output() change = new EventEmitter();
	valueChangesOptions: FlinkCoNamesResponse[] = [];

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer) {}

	writeValue(value: string): void {
		this.value = value;
	}

	ngOnInit(): void {
		this.valueChangesOptions = this.options.slice();
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => this._filter(value)),
			tap((value) => {
				setTimeout(() => this.input({ option: { value: this.myControl.value || this.value } }), 300); //TODO: Refactor form input
			}),
			takeUntil(this.destroy$),
		);

		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;
		if (validator && validator.required) {
			this.required = true;
		}
		this.controlContainer?.control
			?.get(this.formControlName)
			?.valueChanges?.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (isNil(value)) {
					return;
				} else {
					if (this.options.indexOf(value) !== -1) {
						this.valueChangesOptions = this.options.slice();
						return;
					}
					this.valueChangesOptions = [...this.options, { registeredName: value } as FlinkCoNamesResponse];
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private _filter(value: string): FlinkCoNamesResponse[] {
		const filterValue = value?.toLowerCase();
		return this.valueChangesOptions?.filter((option) => option.registeredName?.toLowerCase().includes(filterValue));
	}

	input(event: any): void {
		this.value =
			this.valueChangesOptions?.find((option) => option.registeredName === event.option.value)?.registeredName ||
			event.option.value;
		this.myControl.patchValue(this.value);
		this.change.emit(this.value);
		this.onChange(this.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.myControl.disable() : this.myControl.enable();
		this.disabled = isDisabled;
	}

	clearOnEmptyString() {
		this.onChange('');
	}

	clearValue() {
		this.value = '';
		this.onChange('');
		this.myControl.setValue('');
	}

	onBlur(): void {
		this.onTouched();
	}

	hasOtherOption(): boolean {
		return this.valueChangesOptions?.some((option) => option.registeredName == 'Other');
	}

	onOther(): void {
		this.myControl.setValue('Other');
		this.onChange('Other');
	}
}
