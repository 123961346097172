import { Action } from '@ngrx/store';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { VideoUrlResponse } from '@models/dto/responses/video-url-response.dto';

export enum ActionTypes {
	CREATE_VIDEO_ROOM = '[Video] Create a new video room',
	CREATE_VIDEO_ROOM_SUCCESS = '[Video] Create a new video room success',
	CREATE_VIDEO_ROOM_ERROR = '[Video] Create a new video room error',

	GET_VIDEO_COMPOSITION = '[Video] Get video composition',
	GET_VIDEO_COMPOSITION_SUCCESS = '[Video] Get video composition success',
	GET_VIDEO_COMPOSITION_ERROR = '[Video] Get video composition error',

	GET_VIDEO_RECORDING = '[Video] Get video recording',
	GET_VIDEO_RECORDING_SUCCESS = '[Video] Get video recording success',
	GET_VIDEO_RECORDING_ERROR = '[Video] Get video recording error',

	DELETE_VIDEO_RECORDING = '[Video] delete video recording',
	DELETE_VIDEO_RECORDING_SUCCESS = '[Video] delete video recording success',
	DELETE_VIDEO_RECORDING_ERROR = '[Video] delete video recording error',

	DELETE_COMPOSITION_RECORDING = '[Video] delete composition recording',
	DELETE_COMPOSITION_RECORDING_SUCCESS = '[Video] delete composition recording success',
	DELETE_COMPOSITION_RECORDING_ERROR = '[Video] delete composition recording error',
}

export class CreateVideoRoom implements Action {
	readonly type = ActionTypes.CREATE_VIDEO_ROOM;
	constructor(public payload: { videoQuestion: string }) {}
}

export class CreateVideoRoomSuccess implements Action {
	readonly type = ActionTypes.CREATE_VIDEO_ROOM_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CreateVideoRoomError implements Action {
	readonly type = ActionTypes.CREATE_VIDEO_ROOM_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetVideoRecording implements Action {
	readonly type = ActionTypes.GET_VIDEO_RECORDING;
	constructor(public payload: { compositionSIDs: string[] }) {}
}

export class GetVideoRecordingSuccess implements Action {
	readonly type = ActionTypes.GET_VIDEO_RECORDING_SUCCESS;
	constructor(public payload: { result: VideoUrlResponse[] }) {}
}

export class GetVideoRecordingError implements Action {
	readonly type = ActionTypes.GET_VIDEO_RECORDING_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetVideoComposition implements Action {
	readonly type = ActionTypes.GET_VIDEO_COMPOSITION;
	constructor(public payload: { participantSID: string; roomSID: string }) {}
}

export class GetVideoCompositionSuccess implements Action {
	readonly type = ActionTypes.GET_VIDEO_COMPOSITION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class GetVideoCompositionError implements Action {
	readonly type = ActionTypes.GET_VIDEO_COMPOSITION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteVideoRecording implements Action {
	readonly type = ActionTypes.DELETE_VIDEO_RECORDING;
	constructor(public payload: { roomSID: string }) {}
}

export class DeleteVideoRecordingSuccess implements Action {
	readonly type = ActionTypes.DELETE_VIDEO_RECORDING_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class DeleteVideoRecordingError implements Action {
	readonly type = ActionTypes.DELETE_VIDEO_RECORDING_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteCompositionRecording implements Action {
	readonly type = ActionTypes.DELETE_COMPOSITION_RECORDING;
	constructor(public payload: { roomSID: string; compositionSID: string }) {}
}

export class DeleteCompositionRecordingSuccess implements Action {
	readonly type = ActionTypes.DELETE_COMPOSITION_RECORDING_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class DeleteCompositionRecordingError implements Action {
	readonly type = ActionTypes.DELETE_COMPOSITION_RECORDING_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateVideoRoom
	| CreateVideoRoomSuccess
	| CreateVideoRoomError
	| GetVideoRecording
	| GetVideoRecordingSuccess
	| GetVideoRecordingError
	| GetVideoComposition
	| GetVideoCompositionSuccess
	| GetVideoCompositionError
	| DeleteVideoRecording
	| DeleteVideoRecordingSuccess
	| DeleteVideoRecordingError
	| DeleteCompositionRecording
	| DeleteCompositionRecordingSuccess
	| DeleteCompositionRecordingError;
