import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getOfferStatusColour, OfferStatus } from '../../../../enums/offer-status.enum';
import OfferResponse from '../../../../models/dto/responses/offer-response.dto';
import { getSelectOptionNameById } from '../../../../models/dto/responses/select-options-response.dto';
import { RootStoreState } from '../../../../root-store';
import { OfferStoreActions, OfferStoreSelectors } from '../../../../root-store/offer-store';
import { SelectOptionsStoreSelectors } from '../../../../root-store/select-options-store';
import { AcceptOfferDialogComponent } from '../../dialogs/accept-offer-dialog/accept-offer-dialog.component';
import { RejectOfferDialogComponent } from '../../dialogs/reject-offer-dialog/reject-offer-dialog.component';

@Component({
	selector: 'app-flinker-offers',
	templateUrl: './flinker-offers.component.html',
	styleUrls: ['./flinker-offers.component.scss'],
})
export class FlinkerOffersComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = ['company', 'position', 'ctc', 'commencementDate', 'status'];

	subscriptions = new Subscription();
	getFlinkerOffersHttpPackage$ = this.store.select(OfferStoreSelectors.selectGetFlinkerOffersHttpPackage);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	pendingOffers: OfferResponse[] = [];
	dataSource = new MatTableDataSource<OfferResponse>();
	getSelectOptionNameById = getSelectOptionNameById;
	getOfferStatusColour = getOfferStatusColour;
	OfferStatus = OfferStatus;
	websitelink = '';
	pattern = /^((http|https):\/\/)/;

	constructor(private store: Store<RootStoreState.State>, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.store.dispatch(new OfferStoreActions.GetFlinkerOffers());

		this.subscriptions.add(
			this.getFlinkerOffersHttpPackage$.subscribe((getFlinkerOffersHttpPackage) => {
				if (getFlinkerOffersHttpPackage.result !== null) {
					this.pendingOffers = getFlinkerOffersHttpPackage.result.filter(
						(offer) => offer.offerStatus === OfferStatus.Pending,
					);
					this.dataSource = new MatTableDataSource<OfferResponse>(
						getFlinkerOffersHttpPackage.result.filter((offer) => offer.offerStatus !== OfferStatus.Pending),
					);
				}
			}),
		);
	}

	rejectOffer(offerId: string): void {
		this.dialog.open(RejectOfferDialogComponent, { data: { offerId } });
	}

	acceptOffer(offerId: string): void {
		this.dialog.open(AcceptOfferDialogComponent, { data: { offerId }, width: '30%' });
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
