import HttpPackage from '@models/http-package';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

export interface CvAdminState {
	cvFeaturesHttpPackage: HttpPackage<CvFeatureResponseDto[]>;
	updateCvFeaturePriceHttpPackage: HttpPackage<CvFeatureResponseDto>;
}

export const initialState: CvAdminState = {
	cvFeaturesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateCvFeaturePriceHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
