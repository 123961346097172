import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';
import { FlinkCoProfileStatus } from '@app/enums/flinkco-profile-status.enum';
import { FlinkerProfileStatus } from '@app/enums/profile-status.enum';

export interface AuthenticatedFlinkCoJWTTokenPayload {
	userId?: string;
	userAccountId: string;
	username: string; // Full name
	flinkCoId: string;
	subscription: string;
	accountStatus: FlinkCoProfileStatus;
	role: string;
	adminLoggedInAsUser: boolean;
}

export interface AuthenticatedFlinkerJWTTokenPayload {
	userId?: string;
	userAccountId: string;
	username: string; // Full name
	flinkerId: string;
	role: string;
	accountStatus: FlinkerProfileStatus;

	adminLoggedInAsUser: boolean;
}

export type AuthenticatedUserJWTTokenPayload =
	| AuthenticatedFlinkCoJWTTokenPayload
	| AuthenticatedFlinkerJWTTokenPayload;

@Injectable({
	providedIn: 'root',
})
export class RoleAuthService {
	constructor(private authService: AuthService) {}

	isAuthorized(allowedRoles: string[]): boolean {
		if (allowedRoles == null || allowedRoles.length === 0) return true;

		const token = this.authService.getJwtToken();
		const decodedToken = this.getDecodedAccessToken(token);

		if (!decodedToken) {
			return false;
		}

		return allowedRoles.includes(decodedToken.role);
	}

	getDecodedAccessToken(token: string): AuthenticatedUserJWTTokenPayload {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	getRole(): any {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);

			return decodedToken.role;
		} catch (Error) {
			return null;
		}
	}

	accountIsActive(): boolean {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);
			return decodedToken.accountStatus === FlinkCoProfileStatus.Active;
		} catch (Error) {
			return null;
		}
	}

	getAccountStatus(): string {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);

			return decodedToken.accountStatus;
		} catch (Error) {
			return null;
		}
	}

	getCurrentUserAccountId(): string {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);

			return decodedToken.userAccountId;
		} catch (Error) {
			return null;
		}
	}

	getIsAdminLoggedInAsUser(): boolean {
		try {
			const token = this.authService.getJwtToken();
			const decodedToken = this.getDecodedAccessToken(token);

			return decodedToken.adminLoggedInAsUser;
		} catch (Error) {
			return null;
		}
	}
}
