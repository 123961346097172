import { Actions, ActionTypes } from './actions';
import { initialState, SearchState } from './state';

export function searchReducer(state = initialState, action: Actions): SearchState {
	switch (action.type) {
		case ActionTypes.CREATE_SEARCH:
			return {
				...state,
				createSearchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_SEARCH_SUCCESS:
			return {
				...state,
				createSearchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_SEARCH_ERROR:
			return {
				...state,
				createSearchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_SEARCH:
			return {
				...state,
				updateSearchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_SEARCH_SUCCESS:
			return {
				...state,
				updateSearchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				fetchActiveSearchesHttpPackage: {
					...state.fetchActiveSearchesHttpPackage,
					result: state.fetchActiveSearchesHttpPackage.result?.map((activeSearch) =>
						activeSearch.id === action.payload.result.id ? action.payload.result : activeSearch,
					),
				},
			};
		case ActionTypes.UPDATE_SEARCH_ERROR:
			return {
				...state,
				updateSearchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCHES:
			return {
				...state,
				fetchActiveSearchesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCHES_SUCCESS:
			return {
				...state,
				fetchActiveSearchesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCHES_ERROR:
			return {
				...state,
				fetchActiveSearchesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES:
			return {
				...state,
				fetchActiveFilteredSearchesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES_SUCCESS:
			return {
				...state,
				fetchActiveFilteredSearchesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES_ERROR:
			return {
				...state,
				fetchActiveFilteredSearchesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH_SUCCESS:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH_ERROR:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT:
			return {
				...state,
				getSearchResultHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT_SUCCESS:
			return {
				...state,
				getSearchResultHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT_ERROR:
			return {
				...state,
				getSearchResultHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_MATCH:
			return {
				...state,
				getFlinkerMatchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_MATCH_SUCCESS:
			return {
				...state,
				getFlinkerMatchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_MATCH_ERROR:
			return {
				...state,
				getFlinkerMatchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.PIN_FLINKER_MATCH:
			return {
				...state,
				pinFlinkerMatchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
				getSearchResultHttpPackage: {
					...state.getSearchResultHttpPackage,
					result: {
						...state.getSearchResultHttpPackage.result,
						matchedFlinkers: state.getSearchResultHttpPackage?.result?.matchedFlinkers?.map((flinker) =>
							flinker.id === action.payload.flinkerId ? { ...flinker, pinned: !flinker.pinned } : flinker,
						),
					},
				},
			};
		case ActionTypes.PIN_FLINKER_MATCH_SUCCESS:
			return {
				...state,
				pinFlinkerMatchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				getSearchResultHttpPackage: {
					...state.getSearchResultHttpPackage,
					result: {
						...state.getSearchResultHttpPackage.result,
						matchedFlinkers: state.getSearchResultHttpPackage?.result?.matchedFlinkers?.map((flinker) =>
							flinker.id === action.payload.result.id ? { ...flinker, pinned: !flinker.pinned } : flinker,
						),
					},
				},
				getFlinkerMatchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.PIN_FLINKER_MATCH_ERROR:
			return {
				...state,
				pinFlinkerMatchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.GET_ADHOC_SUBSCRIPTION:
			return {
				...state,
				getAdhocSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_ADHOC_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				getAdhocSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_ADHOC_SUBSCRIPTION_ERROR:
			return {
				...state,
				getAdhocSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.UPDATE_SEARCH_FOR_SCHEDULED_INTERVIEW:
			return {
				...state,
				getSearchResultHttpPackage: {
					...state.getSearchResultHttpPackage,
					result: state.getSearchResultHttpPackage?.result
						? {
								...state.getSearchResultHttpPackage.result,
								matchedFlinkers: state.getSearchResultHttpPackage.result.matchedFlinkers.map((flinker) =>
									flinker.id === action.payload.flinkerId ? { ...flinker, interviewScheduled: true } : flinker,
								),
						  }
						: null,
				},
				getFlinkerMatchHttpPackage: {
					...state.getFlinkerMatchHttpPackage,
					result: state.getFlinkerMatchHttpPackage.result
						? {
								...state.getFlinkerMatchHttpPackage.result,
								interviewScheduled: true,
						  }
						: null,
				},
			};

		case ActionTypes.UPDATE_SEARCH_FOR_OFFER_MADE:
			return {
				...state,
				getSearchResultHttpPackage: {
					...state.getSearchResultHttpPackage,
					result: state.getSearchResultHttpPackage?.result
						? {
								...state.getSearchResultHttpPackage.result,
								matchedFlinkers: state.getSearchResultHttpPackage.result.matchedFlinkers.map((flinker) =>
									flinker.id === action.payload.flinkerId ? { ...flinker, offerMade: true } : flinker,
								),
						  }
						: null,
				},

				getFlinkerMatchHttpPackage: {
					...state.getFlinkerMatchHttpPackage,
					result: state.getFlinkerMatchHttpPackage.result
						? {
								...state.getFlinkerMatchHttpPackage.result,
								offerMade: true,
						  }
						: null,
				},
			};

		case ActionTypes.SHORTLIST_FLINKER:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.SHORTLIST_FLINKER_SUCCESS:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.SHORTLIST_FLINKER_ERROR:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.REMOVE_SHORTLISTED_FLINKER:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.REMOVE_SHORTLISTED_FLINKER_SUCCESS:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.REMOVE_SHORTLISTED_FLINKER_ERROR:
			return {
				...state,
				getShortlistHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.THUMBS_UP_FLINKER:
			return {
				...state,
				getThumbsUpHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.THUMBS_UP_FLINKER_SUCCESS:
			return {
				...state,
				getThumbsUpHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.THUMBS_UP_FLINKER_ERROR:
			return {
				...state,
				getThumbsUpHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		case ActionTypes.THUMBS_DOWN_FLINKER:
			return {
				...state,
				getThumbsDownHttpPackage: {
					loading: true,
					error: null,
					result: null,
				},
			};

		case ActionTypes.THUMBS_DOWN_FLINKER_SUCCESS:
			return {
				...state,
				getThumbsDownHttpPackage: {
					loading: false,
					error: null,
					result: action.payload.result,
				},
			};

		case ActionTypes.THUMBS_DOWN_FLINKER_ERROR:
			return {
				...state,
				getThumbsDownHttpPackage: {
					loading: false,
					error: action.payload.errorResponse,
					result: null,
				},
			};

		default: {
			return state;
		}
	}
}
