import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-heading',
	templateUrl: './heading.component.html',
	styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent implements OnInit {
	@Input() theme = '';
	@Input() heading = '';

	constructor() {}

	ngOnInit(): void {}
}
