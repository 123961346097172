import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReferenceQuestionsStoreModule } from './admin-reference-questions-store';
import { AppStoreModule } from './app-store';
import { CvStoreModule } from '@root-store/cv-store';
import { FlinkCoAdminStoreModule } from './flinkco-admin-store';
import { FlinkcoDashboardStoreModule } from './flinkco-dashboard-store';
import { FlinkCoTakeOnStoreModule } from './flinkco-take-on-store';
import { FlinkerAdminStoreModule } from './flinker-admin-store';
import { FlinkerTakeOnStoreModule } from './flinker-take-on-store';
import { InterviewStoreModule } from './interview-store';
import { OfferStoreModule } from './offer-store';
import { PsychAssessmentModule } from './psych-assessment-store';
import { QuoteStoreModule } from './quote-store';
import { ReferenceStoreModule } from './reference-store';
import { SearchStoreModule } from './search-store';
import { SelectOptionsStoreModule } from './select-options-store';
import { CvAdminStoreModule } from '@root-store/cv-admin-store';
import { FlinkCoJobAdvertsModule } from '@root-store/flinkco/job-adverts';
import { FlinkerJobsModule } from '@root-store/flinker/jobs';
import { VideoStoreModule } from '@root-store/video-store';
import { InterviewQuestionnaireStoreModule } from '@root-store/interview-questionnaire-store';
import { FlinkCoFlinkerDocumentsModule } from '@root-store/flinkco/flinker-documents/flinkco-flinker-documents.module';

@NgModule({
	imports: [
		CommonModule,
		FlinkerTakeOnStoreModule,
		FlinkCoTakeOnStoreModule,
		SelectOptionsStoreModule,
		SearchStoreModule,
		QuoteStoreModule,
		FlinkCoAdminStoreModule,
		FlinkerAdminStoreModule,
		PsychAssessmentModule,
		InterviewStoreModule,
		OfferStoreModule,
		ReferenceQuestionsStoreModule,
		ReferenceStoreModule,
		AppStoreModule,
		FlinkcoDashboardStoreModule,
		CvStoreModule,
		CvAdminStoreModule,
		FlinkCoJobAdvertsModule,
		FlinkerJobsModule,
		VideoStoreModule,
		InterviewQuestionnaireStoreModule,
		FlinkCoFlinkerDocumentsModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
	],
	providers: [],
	declarations: [],
})
export class RootStoreModule {}
