<div class="container-shadow p-4">
	<div class="d-flex justify-content-center col-12">
		<app-heading [heading]="'Congratulations!'" theme="f4"></app-heading>
	</div>

	<div class="row p-4 d-flex justify-content-center text-center">
		<p class="front-fade-4 font-medium font-size-10">
			Congratulations on completing the take-on process!<br /><br />
			<span class="font-size-8">
				Did you know that you can buy a professional CV from Flink?<br />
				Increase your chances of success with a professional CV that you can send to Employers!
			</span>
		</p>
	</div>

	<div class="row">
		<div class="col-12 w-100 d-flex justify-content-center">
			<app-square-button theme="m5 bold" value="No thank you" (click)="closeModal()"></app-square-button>
			<app-square-button theme="m1 bold" [value]="'Buy R' + amountDueForCv" (click)="goToCvPage()"></app-square-button>
		</div>
	</div>
</div>
