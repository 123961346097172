import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import moment from 'moment';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import PreviousEmployer from 'projects/flink-app/src/app/models/dto/previous-employer.dto';
import {
	getSelectOptionNameById,
	SelectOptionsResponse,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { FormControl } from '@angular/forms';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { Store } from '@ngrx/store';
import { MediaMatcher } from '@angular/cdk/layout';
import FlinkerResponse from 'projects/flink-app/src/app/models/dto/responses/flinker-response.dto';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-piechart',
	templateUrl: './piechart.component.html',
	styleUrls: ['./piechart.component.scss'],
})
export class PiechartComponent implements OnInit {
	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	mobileQuery: MediaQueryList;
	experienceControl: FormControl;
	industryLabels: Label[] = [];
	jobCategoryLabels: Label[] = [];
	industryData: SingleDataSet = [];
	jobCategoryData: SingleDataSet = [];
	doughnutChartType: ChartType = 'doughnut';
	doughnutChartColors: Color[] = [
		{
			backgroundColor: [
				'#314df0',
				'#54c280',
				'#0cd9c0',
				'#0d3691',
				'#ff4548',
				'#3b65b0',
				'#258dcc',
				'#dc3d35',
				'#ff5416',
				'#ffb200',
			],
		},
	];
	options: any = {
		legend: { position: 'bottom', align: 'center' },
	};
	mobileOptions: any = {
		legend: { position: 'bottom', align: 'left' },
	};

	@Input() flinker: FlinkerResponse;

	private _mobileQueryListener: () => void;

	constructor(
		private store: Store<RootStoreState.State>,
		private media: MediaMatcher,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.mobileQuery = media.matchMedia('(max-width: 1400px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);

		this.experienceControl = new FormControl('industry');
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				this.selectOptions = selectOptionsResponse.result;
				if (selectOptionsResponse.result !== null) {
					const industryDurations = this.groupByIndustry();
					for (const prop in industryDurations) {
						if (Object.prototype.hasOwnProperty.call(industryDurations, prop)) {
							this.industryLabels.push(
								this.getDurationString(industryDurations[prop]) +
									' experience in ' +
									getSelectOptionNameById(this.selectOptions?.industries, prop),
							);
							this.industryData.push(industryDurations[prop]);
						}
					}

					const jobCategoryDurations = this.groupByJobCategory();
					for (const prop in jobCategoryDurations) {
						if (Object.prototype.hasOwnProperty.call(jobCategoryDurations, prop)) {
							this.jobCategoryLabels.push(
								this.getDurationString(jobCategoryDurations[prop]) +
									' experience in ' +
									getSelectOptionNameById(this.selectOptions?.jobCategories, prop),
							);
							this.jobCategoryData.push(jobCategoryDurations[prop]);
						}
					}
				}
			}),
		);

		// if (!this.flinker.currentEmployer) {
		// 	this.doughnutChartColors = [
		// 		{
		// 			backgroundColor: ['#c0c0c0'],
		// 		},
		// 	];
		// 	this.industryLabels = ['No Work Experience'];
		// 	this.jobCategoryLabels = ['No Work Experience'];
		// 	this.industryData = [100];
		// 	this.jobCategoryData = [100];
		//
		// 	return;
		// }
	}

	getDurationString(months: number): string {
		return months < 12
			? months + (months > 1 ? ' mos' : ' mo')
			: months % 12 == 0
			? months / 12 + (months / 12 > 1 ? ' yrs' : ' yr')
			: (months - (months % 12)) / 12 +
			  ((months - (months % 12)) / 12 > 1 ? ' yrs' : ' yr') +
			  ' and ' +
			  (months % 12) +
			  (months % 12 > 1 ? ' mos' : ' mo');
	}

	groupByIndustry() {
		const isEmpty = !this.flinker.previousEmployers || this.flinker.previousEmployers.length === 0;
		const industries = isEmpty
			? {}
			: this.flinker.previousEmployers.reduce((p: any, employer: PreviousEmployer) => {
					const name = employer.industryId;
					if (!p.hasOwnProperty(name)) {
						p[name] = 0;
					}
					p[name] += moment(employer.endDate).diff(moment(employer.startDate), 'months');
					return p;
			  }, {});

		if (this.flinker.currentEmployer?.startDate) {
			const currentDuration = moment().diff(moment(this.flinker.currentEmployer.startDate), 'months');
			industries[this.flinker.currentEmployer.industryId]
				? (industries[this.flinker.currentEmployer.industryId] += currentDuration)
				: (industries[this.flinker.currentEmployer.industryId] = currentDuration);
		}

		return industries;
	}

	groupByJobCategory() {
		const isEmpty = !this.flinker.previousEmployers || this.flinker.previousEmployers.length === 0;

		const jobCategories = isEmpty
			? {}
			: this.flinker.previousEmployers.reduce((p: any, employer: PreviousEmployer) => {
					const name = employer.jobCategoryId;
					if (!p.hasOwnProperty(name)) {
						p[name] = 0;
					}
					p[name] += moment(employer.endDate).diff(moment(employer.startDate), 'months');
					return p;
			  }, {});

		if (this.flinker.currentEmployer?.startDate) {
			const currentDuration = moment().diff(moment(this.flinker.currentEmployer.startDate), 'months');
			jobCategories[this.flinker.currentEmployer.jobCategoryId]
				? (jobCategories[this.flinker.currentEmployer.jobCategoryId] += currentDuration)
				: (jobCategories[this.flinker.currentEmployer.jobCategoryId] = currentDuration);
		}

		return jobCategories;
	}
}
