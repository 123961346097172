<div class="row m-0 p-0">
	<div class="m-5 w-100">
		<mat-card class="col-12 col-lg-8 offset-lg-2 offer-container offer-heading mb-3" *ngFor="let offer of pendingOffers">
			<div class="user-picture">
				<app-user-picture
					theme="m-2"
					[size]="120"
					theme="mx-auto d-flex shadow-1"
					[src]="offer.flinkCo.logo"
					[alt]="offer.flinkCo.registeredName"
				>
				</app-user-picture>
			</div>
			<div class="my-5 text-center">
				<span class="text-uppercase front-main-1 font-bold font-size-6 font-size-4-md">Congratulations!</span>
				<div class="d-flex justify-content-center my-3">
					<span class="font-size-7 front-sub-3"
						><span class="font-size-7 front-sub-3 font-bold">{{ offer.flinkCo.registeredName }}</span> wants you,
						<span class="font-size-7 front-sub-3 font-bold"
							>{{ offer.flinker.name }} {{ offer.flinker.surname }}</span
						></span
					>
				</div>
			</div>

			<div class="row px-3">
				<div class="col-12 col-sm-4 text-center px-2 my-2">
					<div class="icon-container back-main-1 mx-auto">
						<mat-icon class="front-sub-1 offer-icon">work_outline</mat-icon>
					</div>
					<div class="offer-card-item-inner mt-n4">
						<div class="pt-5 pb-3">
							<span class="d-flex justify-content-center font-size-12 font-size-11-md pb-2">Your role</span>
							<span class="d-flex justify-content-center font-size-10 font-size-8-md font-bold">{{
								offer.position
							}}</span>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-4 text-center px-2 my-2">
					<div class="icon-container back-main-1 mx-auto">
						<mat-icon class="front-sub-1 offer-icon">paid</mat-icon>
					</div>
					<div class="offer-card-item-inner mt-n4">
						<div class="pt-5 pb-3">
							<span class="d-flex justify-content-center font-size-12 font-size-11-md pb-2"
								>Monthly cost to company</span
							>
							<span class="d-flex justify-content-center font-size-10 font-size-8-md font-bold"
								>R{{ offer.salary | number: '.0' }} pm</span
							>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-4 text-center px-2 my-2">
					<div class="icon-container back-main-1 mx-auto">
						<mat-icon class="front-sub-1 offer-icon">today</mat-icon>
					</div>
					<div class="offer-card-item-inner mt-n4">
						<div class="pt-5 pb-3">
							<span class="d-flex justify-content-center font-size-12 font-size-11-md pb-2">Commencement date</span>
							<span class="d-flex justify-content-center font-size-10 font-size-8-md font-size-8 font-bold">{{
								offer.startDate | dateFormat: 'D MMM YYYY'
							}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="px-5 mt-4 mb-4 text-center d-flex flex-column" *ngIf="offer.benefits">
				<span class="font-size-10 font-size-8-md font-bold">Benefits:</span>
				<span class="front-sub-5 mt-4">
					{{ offer.benefits }}
				</span>
			</div>

			<div class="row">
				<div class="button-container col-12 col-md-4">
					<app-rounded-button
						type="button"
						theme="m5 br5 shadow-1"
						value="Reject Offer"
						class="text-center"
						(click)="rejectOffer(offer.id)"
					>
					</app-rounded-button>
				</div>
				<div class="button-container col-12 col-md-4">
					<app-rounded-button
						type="button"
						theme="m1 br5 shadow-1"
						value="View offer details"
						class="text-center"
						[routerLink]="['../offer', offer.id]"
					>
					</app-rounded-button>
				</div>
				<div class="button-container col-12 col-md-4">
					<app-rounded-button
						type="button"
						theme="m2 br5 shadow-1"
						value="Accept Offer"
						class="text-center"
						(click)="acceptOffer(offer.id)"
					>
					</app-rounded-button>
				</div>
			</div>
		</mat-card>
	</div>
	<mat-card class="col-10 offset-1 mb-5" *ngIf="dataSource.data.length == 0">
		<app-page-header-toolbar icon="redeem" heading="Offers"> </app-page-header-toolbar>

		<div class="row">
			<div class="col-12 px-4">
				<h3 class="my-3 text-center" *ngIf="dataSource.data.length == 0">No offers found</h3>
			</div>
		</div>
	</mat-card>
	<mat-card class="col-10 offset-1 mb-5" *ngIf="dataSource.data.length > 0">
		<app-page-header-toolbar icon="redeem" heading="Offer history"> </app-page-header-toolbar>

		<div class="row">
			<div class="col-12 px-4">
				<table mat-table [dataSource]="dataSource" class="w-100">
					<ng-container matColumnDef="company">
						<th mat-header-cell *matHeaderCellDef>Company name</th>
						<td mat-cell *matCellDef="let offer">
							<div class="d-flex">
								<app-user-picture
									theme="m-2"
									[size]="80"
									[src]="offer.flinkCo.logo"
									[alt]="offer.flinkCo.registeredName"
								>
								</app-user-picture>
								<div class="d-flex flex-column justify-content-center pl-2 my-3">
									<div class="front-sub-3 font-size-10 font-medium">
										{{ offer.flinkCo.registeredName }}
									</div>
									<a
										class="front-sub-5 font-size-12 pt-2"
										[href]="pattern.test(offer.flinkCo.website) ? offer.flinkCo.website : '//' + offer.flinkCo.website"
										target="_blank"
									>
										{{ offer.flinkCo.website }}
									</a>
								</div>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="position">
						<th mat-header-cell *matHeaderCellDef>Position</th>
						<td mat-cell *matCellDef="let offer">
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon">
									{{ offer.position }}
								</span>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="ctc">
						<th mat-header-cell *matHeaderCellDef>Monthly cost to company</th>
						<td mat-cell *matCellDef="let offer">
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon">
									R{{ offer.salary | number: '.2' }}
								</span>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="commencementDate">
						<th mat-header-cell *matHeaderCellDef>Commencement date</th>
						<td mat-cell *matCellDef="let offer">
							<div class="d-flex">
								<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon">
									{{ offer.startDate ? (offer.startDate | dateFormat: 'DD/MM/YYYY') : '' }}
								</span>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef>Status</th>
						<td mat-cell *matCellDef="let offer">
							<app-highlighted-text [theme]="getOfferStatusColour(offer.offerStatus)">
								{{ offer.offerStatus }}
								{{
									offer.offerStatus == OfferStatus.Rejected
										? ' - ' +
                    (getSelectOptionNameById(
												(selectOptionsHttpPackage$ | async).result?.offerRejectionReasons,
												offer?.rejectionReasonId
										  ) ?? '')
										: ''
								}}
                {{
                offer.offerStatus == OfferStatus.Cancelled
                  ? ' - ' +
                  (getSelectOptionNameById(
                    (selectOptionsHttpPackage$ | async).result?.offerRejectionReasons,
                    offer?.rejectionReasonId
                  ) ?? '')
                  : ''
                }}
							</app-highlighted-text>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedColumns"
						class="cursor-pointer"
						[routerLink]="['../offer/', row.id]"
					></tr>
				</table>
			</div>
		</div>
	</mat-card>
</div>
