<div class="w-100">
	<label [ngClass]="{ required: required }">{{ labelText }}</label>
	<ng-container *ngIf="!compositionSID">
		<div class="video-container w-100 my-3 d-flex justify-content-center" #videoContainer>
			<ng-container *ngIf="!roomJoined && !connectionLoading && !responseLoading">
        <div class="d-flex justify-content-center w-100 cursor-pointer" (click)="onJoinRoom()">
          <div class="align-self-center">
            <mat-icon class="ml-5 pl-5 front-sub-8 font-size-5">radio_button_checked</mat-icon>
            <p class="text-center font-normal align-middle font-size-9 front-sub-8">Start recording answer now</p>
          </div>
        </div>
			</ng-container>
			<ng-container *ngIf="connectionLoading || responseLoading">
				<mat-spinner></mat-spinner>
			</ng-container>
		</div>
		<ng-container *ngIf="roomJoined && !connectionLoading">
			<div class="col-12 d-flex mt-3 flex-column flex-md-row">
				<app-square-button class="mb-2 mb-md-0" theme="m5" (click)="cancelRecording()" value="Cancel recording"></app-square-button>
				<app-square-button theme="m7" (click)="leaveRoom()" value="Save recording"></app-square-button>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="compositionSID">
    <ng-template #recordingCompletedLoading>
      <div class="w-100 complete-border pt-3 pb-2 my-3 d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
		<div class="w-100 complete-border pt-3 pb-2 my-3 d-flex justify-content-center" *ngIf="!responseLoading else recordingCompletedLoading">
			<div class="align-self-center">
				<ng-container *ngIf="!recordingTimedOut">
					<div class="d-flex flex-row">
						<p class="font-size-9 mt-1 mb-2 mr-3 text-center font-normal front-main-2">Video recording complete</p>
						<mat-icon
							(click)="reRecordRoom()"
							matTooltip="Click here to re-record your answer"
							class="front-main-2 font-size-5 cursor-pointer"
							>replay</mat-icon
						>
					</div></ng-container
				>
				<ng-container *ngIf="recordingTimedOut">
					<div class="d-flex flex-row">
						<p class="font-size-9 mt-1 mb-2 mr-3 text-center font-normal front-main-2">
							Video timed out, recording saved
						</p>
						<mat-icon
							(click)="reRecordRoom()"
							matTooltip="Click here to re-record your answer"
							class="front-main-2 font-size-5 cursor-pointer"
							>replay</mat-icon
						>
					</div></ng-container
				>
			</div>
		</div>
	</ng-container>
</div>
