import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PsychAssessmentEffects } from './effects';
import { psychAssessmentReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('psychAssessment', psychAssessmentReducer),
		EffectsModule.forFeature([PsychAssessmentEffects]),
	],
	providers: [PsychAssessmentEffects],
})
export class PsychAssessmentModule {}
