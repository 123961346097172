import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-popup-notification',
	templateUrl: './popup-notification.component.html',
	styleUrls: ['./popup-notification.component.scss'],
})
export class PopupNotificationComponent implements OnInit {
	@Input() theme = 'm2';
	open = true;

	constructor() {}

	ngOnInit(): void {}
}
