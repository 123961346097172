import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import FlinkCoResponse from '@models/dto/responses/flink-co-response.dto';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import { FlinkCoCompanyInformationResponse } from '@models/dto/responses/flinkco-company-information-response.dto';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import RetainerSubscriptionResponse from '@models/dto/responses/retainer-subscription-response.dto';
import FlinkCoUser from '@models/dto/flink-co-user.dto';
import HttpPackage from '@models/http-package';
import { FlinkCoTakeOnState } from './state';
import { isNil } from '@app/utils/functions.util';

const getFlinkCo = (state: FlinkCoTakeOnState): FlinkCoResponse => state.flinkCo;

const getFlinkCoUsers = (flinkCo: FlinkCoResponse): FlinkCoUser[] => (!isNil(flinkCo?.users) ? flinkCo.users : []);

const getFlinkCoCompanyInformationHttpPackage = (
	state: FlinkCoTakeOnState,
): HttpPackage<FlinkCoCompanyInformationResponse> => state.flinkCoCompanyInformationHttpPackage;

const getUploadCompanyLogoHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<string> =>
	state.uploadCompanyLogoHttpPackage;

const getUploadCIPCCompanyDocumentsHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<string> =>
	state.uploadCIPCCompanyDocumentsHttpPackage;

const getFlinkCoSubscriptionHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<FlinkCoSubscriptionResponse> =>
	state.flinkCoSubscriptionHttpPackage;

const getFlinkCoRetainerInformationHttpPackage = (
	state: FlinkCoTakeOnState,
): HttpPackage<RetainerInformationResponse> => state.flinkCoRetainerInformationHttpPackage;

const getFlinkCoAdHocInformationHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<AdHocInformationResponse> =>
	state.flinkCoAdHocInformationHttpPackage;

const getDeleteFlinkCoUserHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<string> =>
	state.deleteFlinkCoUserHttpPackage;

const getAddFlinkCoUserHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<FlinkCoUser[]> =>
	state.addFlinkCoUsersHttpPackage;

const getFlinkCoHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<FlinkCoResponse> => state.flinkCoHttpPackage;

const getFlinkCoTermsAndConditionsHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<string> =>
	state.flinkCoTermsAndConditionsPackage;

const getRetainerSubscriptionHttpPackage = (state: FlinkCoTakeOnState): HttpPackage<RetainerSubscriptionResponse> =>
	state.getRetainerSubscriptionHttpPackage;

export const selectTakeOnState = createFeatureSelector<FlinkCoTakeOnState>('flinkcoTakeOn');

export const selectFlinkCo = createSelector(selectTakeOnState, getFlinkCo);

export const selectFlinkCoUsers = createSelector(selectFlinkCo, getFlinkCoUsers);

export const selectFlinkCoCompanyInformationHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkCoCompanyInformationHttpPackage,
);

export const selectUploadCompanyLogoHttpPackage = createSelector(selectTakeOnState, getUploadCompanyLogoHttpPackage);

export const selectUploadCIPCCompanyDocumentsHttpPackage = createSelector(
	selectTakeOnState,
	getUploadCIPCCompanyDocumentsHttpPackage,
);

export const selectFlinkCoSubscriptionHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkCoSubscriptionHttpPackage,
);

export const selectFlinkCoRetainerInformationHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkCoRetainerInformationHttpPackage,
);

export const selectFlinkCoAdHocInformationHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkCoAdHocInformationHttpPackage,
);

export const selectAddFlinkCoUserHttpPackage = createSelector(selectTakeOnState, getAddFlinkCoUserHttpPackage);

export const selectDeleteFlinkCoUserHttpPackage = createSelector(selectTakeOnState, getDeleteFlinkCoUserHttpPackage);

export const selectGetFlinkCoHttpPackage = createSelector(selectTakeOnState, getFlinkCoHttpPackage);

export const selectGetFlinkCoTermsAndConditionsHttpPackage = createSelector(
	selectTakeOnState,
	getFlinkCoTermsAndConditionsHttpPackage,
);

export const selectGetRetainerSubscriptionHttpPackage = createSelector(
	selectTakeOnState,
	getRetainerSubscriptionHttpPackage,
);
