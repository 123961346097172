import ActiveSubscription from '../../models/dto/responses/active-Subscription-response.dto';
import CostResponse from '../../models/dto/responses/cost-response.dto';
import QuoteResponse from '../../models/dto/responses/quote-response.dto';
import HttpPackage from '../../models/http-package';

export interface QuoteState {
	getRetainerQuoteHttpPackage: HttpPackage<CostResponse>;
	getAdHocQuoteHttpPackage: HttpPackage<CostResponse>;
	getActiveQuoteHttpPackage: HttpPackage<QuoteResponse>;
	getActiveSubscriptionHttpPackage: HttpPackage<ActiveSubscription>;
}

export const initialState: QuoteState = {
	getRetainerQuoteHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getAdHocQuoteHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getActiveQuoteHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getActiveSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
