import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
	transform(value: string): any {
		const result = value.replace(/([A-Z])/g, ' $1');
		return result.charAt(0).toUpperCase() + result.slice(1);
	}
}
