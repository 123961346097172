import { Component, Inject, OnInit } from '@angular/core';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-progress-gauge-modal',
	templateUrl: './progress-gauge-modal.component.html',
	styleUrls: ['./progress-gauge-modal.component.scss'],
})
export class ProgressGaugeModalComponent implements OnInit {
	profileComplete: boolean;
	constructor(
		public dialogRef: MatDialogRef<ProgressGaugeModalComponent>,
		private store: Store<RootStoreState.State>,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {}

	ngOnInit(): void {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
	}

	onChangeModalTitle(profileComplete: boolean) {
		if (profileComplete) {
			this.profileComplete = true;
			this.data.message = 'You have successfully applied for this job. Please choose another job advert';
			this.data.buttonLink = '/flinker-dashboard/job-adverts';
			this.data.buttonName = 'View more job adverts';
		}
	}
}
