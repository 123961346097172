import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GoogleTagManagerTrackerService implements OnDestroy {
	private subscription = new Subscription();

	constructor(private router: Router, private gtmService: GoogleTagManagerService) {
		this.gtmService.addGtmToDom().catch((error) => console.error(`Failed to set up GTM ${error.message}`));
	}

	emitEvent(event: string, otherDetails: any = {}) {
		this.gtmService.pushTag({ ...otherDetails, event }).catch((err) => console.error(err));
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
