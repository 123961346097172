import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { getErrorMessage } from '@app/utils/form.util';
import { GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthService } from 'angularx-social-login';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import { AccountType } from '@app/enums/account-type.enum';
import { SsoType } from '@app/enums/sso-type.enum';
import { ToggleSwitchOption } from '@shared/switch-toggle/switch-toggle.component';
import { NGXLogger } from 'ngx-logger';
import { contactNumberValidator } from '@app/utils/custom-validators.util';
import { MatDialog } from '@angular/material/dialog';
import { FlinkBrandedModalComponent } from '@shared/flink-branded-modal/flink-branded-modal.component';
import { SignUpType } from '@app/enums/sign-up-type.enum';

@Component({
	selector: 'app-flink-co-register',
	templateUrl: './flink-co-register.component.html',
	styleUrls: ['./flink-co-register.component.scss'],
})
export class FlinkCoRegisterComponent implements OnInit {
	@Input() submitValue = '';
	isLoading = false;
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	shouldValidate = false;
	ssoTypeEnumValues = SsoType;
	signUpTypeEnum = SignUpType;
	toggleSwitchOptions: ToggleSwitchOption[] = [
		{
			label: 'Apply',
		},
		{
			label: 'Access & Hire Talent',
		},
	];

	constructor(
		formBuilder: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private authSocialService: SocialAuthService,
		private route: ActivatedRoute,
		private readonly logger: NGXLogger,
		public dialog: MatDialog,
	) {
		this.form = formBuilder.group({
			name: ['', [Validators.required]],
			surname: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			cellphoneNumber: ['', [Validators.required, contactNumberValidator()]],
			password: [
				'',
				[Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*?[_#?!@$%^&*-])(?=.*[A-Z]).{8,32}')],
			],
		});
	}

	ngOnInit(): void {}

	toggleSwitch(value: number): void {
		switch (value) {
			case 0:
				this.router.navigate(['/auth/flinker-registration']);
				break;
			case 1:
				this.router.navigate(['/auth/flinkco-registration']);
				break;
			default:
				this.router.navigate(['/auth/flinker-registration']);
		}
	}

	onGoogleSignIn(): void {
		this.isLoading = true;
		const formValues = this.form.value;

		this.authSocialService
			.signIn(GoogleLoginProvider.PROVIDER_ID)
			.then((data) => {
				this.authService
					.sso({
						idToken: data.idToken,
						accountType: AccountType.FlinkCompanyMaster,
						signUp: true,
						rememberMe: true,
						ssoType: SsoType.Google,
					})
					.subscribe(
						(_: SignInResponse) => {
							this.onSignInSuccess();
						},
						(err: ErrorResponse) => {
							this.isLoading = false;
							this.logger.error('Failed to register FlinkCo with Google.', err);
						},
					);
			})
			.catch((err) => {
				this.isLoading = false;
				this.logger.error('An error has occurred from the Google social login', err);
			});
	}

	onMicrosoftSignIn(): void {
		this.isLoading = true;

		this.authSocialService
			.signIn(MicrosoftLoginProvider.PROVIDER_ID)
			.then((data) => {
				this.authService
					.sso({
						idToken: data.idToken,
						authToken: data.authToken,
						accountType: AccountType.FlinkCompanyMaster,
						signUp: true,
						rememberMe: true,
						ssoType: SsoType.Microsoft,
					})
					.subscribe(
						(_: SignInResponse) => {
							this.onSignInSuccess();
						},
						(err: ErrorResponse) => {
							this.isLoading = false;
							this.logger.error('Failed to register FlinkCo with Microsoft.', err);
						},
					);
			})
			.catch((err) => {
				this.isLoading = false;
				this.logger.error('An error has occurred from the Microsoft social login', err);
			});
	}

	onSignInSuccess(): void {
		this.router.navigate(['take-on/flinkco']);
	}

	openFlinkBrandedDialog(signUpType: SignUpType, ssoType?: SsoType) {
		const dialogRef = this.dialog.open(FlinkBrandedModalComponent, {
			width: '1000px',
			data: {
				modalHeader: 'Confirm Company Registration',
				buttonName: 'Confirm',
				link: '/auth/flinker-registration',
				linkData: this.form.value,
				message:
					'You are about to register as a company who wants to recruit talent. <br> Are you sure you want to proceed?',
				linkMessage: 'If you are an employee looking for a job click here',
			},
			id: 'flink-branded-modal',
		});

		dialogRef.afterClosed().subscribe((value) => {
			if (value === 'button') {
				switch (signUpType) {
					case SignUpType.Flink:
						this.signUp();
						return;
					case SignUpType.SSO:
						if (ssoType === SsoType.Microsoft) {
							this.onMicrosoftSignIn();
						} else {
							this.onGoogleSignIn();
						}
						return;
					default:
						return;
				}
			}
		});
	}

	signUp(): void {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.isLoading = true;
		const formValues = this.form.value;

		this.authService
			.signUp({
				name: formValues.name,
				surname: formValues.surname,
				email: formValues.email,
				password: formValues.password,
				cellphoneNumber: formValues.cellphoneNumber,
				accountType: AccountType.FlinkCompanyMaster,
				rememberMe: true,
			})
			.subscribe(
				(_) => {
					this.router.navigate(['../otp'], { relativeTo: this.route, state: { email: formValues.email } });
				},
				(err: ErrorResponse) => {
					this.isLoading = false;
					this.logger.error('Failed to register FlinkCo on platform.', err);
				},
			);
	}
}
