import FlinkCoResponse from '../../models/dto/responses/flink-co-response.dto';
import HttpPackage from '../../models/http-package';

export interface FlinkcoDashboardState {
	flinkCo: FlinkCoResponse;
	flinkCoHttpPackage: HttpPackage<FlinkCoResponse>;
	cancelRetainerSubscriptionHttpPackage: HttpPackage<string>;
	upgradeAdhocSubscriptionHttpPackage: HttpPackage<string>;
	requestDemoHttpPackage: HttpPackage<string>;
}

export const initialState: FlinkcoDashboardState = {
	flinkCo: null,
	flinkCoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	cancelRetainerSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	upgradeAdhocSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},

	requestDemoHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
