import { Action } from '@ngrx/store';
import FlinkCoResponse from '@flinkadmin-dashboard/models/response/flink-co-admin-response.dto';
import { AdHocInformationRequest } from '@models/dto/requests/ad-hoc-information-request.dto';
import { RetainerInformationRequest } from '@models/dto/requests/retainer-information-request.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { FlinkCoSubscriptionRequest } from '@models/dto/requests/flink-co-subscription-request';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import FlinkcoAdminFilterRequest from '@models/dto/requests/flinkco-admin-filter-request.dto';
import SearchResponse from '@models/dto/responses/search-response.dto';
import AdminSearchResponse from '@flinkadmin-dashboard/models/response/admin-search-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import AdminSearchResultResponseDto from '@flinkadmin-dashboard/models/response/admin-search-result-response.dto';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';

export enum ActionTypes {
	FETCH_ALL_FLINKCOS = '[Flink Admin] Fetch all flinkcos',
	FETCH_ALL_FLINKCOS_SUCCESS = '[Flink Admin] Fetch all flinkcos success',
	FETCH_ALL_FLINKCOS_ERROR = '[Flink Admin] Fetch all flinkcos error',

	FETCH_ALL_QUOTES = '[Flink Admin] Fetch all quotes',
	FETCH_ALL_QUOTES_SUCCESS = '[Flink Admin] Fetch all quotes success',
	FETCH_ALL_QUOTES_ERROR = '[Flink Admin] Fetch all quotes error',

	UPDATE_FLINKCO = '[Flink Admin] Update flinkco',
	UPDATE_FLINKCO_SUCCESS = '[Flink Admin] Update flinkco success',
	UPDATE_FLINKCO_ERROR = '[Flink Admin] Update flinkco error',

	SUBMIT_FLINKCO_SUBSCRIPTION = '[Flink Admin] Submit FlinkCo subscription',
	SUBMIT_FLINKCO_SUBSCRIPTION_SUCCESS = '[Flink Admin] Submit FlinkCo subscription success',
	SUBMIT_FLINKCO_SUBSCRIPTION_ERROR = '[Flink Admin] Submit FlinkCo subscription error',

	ADD_ADHOC_SUBCRIPTION = '[Flink Admin] Add adhoc subscription',
	ADD_ADHOC_SUBCRIPTION_SUCCESS = '[Flink Admin] Add adhoc subscription success',
	ADD_ADHOC_SUBCRIPTION_ERROR = '[Flink Admin] Add adhoc subscription error',

	ADD_RETAINER_SUBCRIPTION = '[Flink Admin] Add retainer subscription',
	ADD_RETAINER_SUBCRIPTION_SUCCESS = '[Flink Admin] Add retainer subscription success',
	ADD_RETAINER_SUBCRIPTION_ERROR = '[Flink Admin] Add retainer subscription error',

	CANCEL_RETAINER_SUBCRIPTION = '[Flink Admin] Cancel retainer subscription',
	CANCEL_RETAINER_SUBCRIPTION_SUCCESS = '[Flink Admin] Cancel retainer subscription success',
	CANCEL_RETAINER_SUBCRIPTION_ERROR = '[Flink Admin] Cancel retainer subscription error',

	LOGIN_AS_FLINKCO = '[Flink Admin] Login as flinkco',
	LOGIN_AS_FLINKCO_SUCCESS = '[Flink Admin] Login as flinkco success',
	LOGIN_AS_FLINKCO_ERROR = '[Flink Admin] Login as flinkco error',

	DELETE_FLINKCO = '[Flink Admin] Delete flinkCo',
	DELETE_FLINKCO_SUCCESS = '[Flink Admin] Delete flinkCo success',
	DELETE_FLINKCO_ERROR = '[Flink Admin] Delete flinkCo error',

	GET_SELECTED_FLINKCO = '[Flink Admin] Get selected flink co',
	GET_SELECTED_FLINKCO_SUCCESS = '[Flink Admin] Get selected flink co success',
	GET_SELECTED_FLINKCO_ERROR = '[Flink Admin] Get selected flink co error',

	FETCH_FLINKCO_SEARCHES = '[Flink Admin] Fetch flinkCo searches',
	FETCH_FLINKCO_SEARCHES_SUCCESS = '[Flink Admin] Fetch flinkCo searches success',
	FETCH_FLINKCO_SEARCHES_ERROR = '[Flink Admin] Fetch flinkCo searches error',

	FETCH_ACTIVE_SEARCH = '[Flink Admin] Fetch active search',
	FETCH_ACTIVE_SEARCH_SUCCESS = '[Flink Admin] Fetch active search success',
	FETCH_ACTIVE_SEARCH_ERROR = '[Flink Admin] Fetch active search error',

	GET_SEARCH_RESULT = '[Flink Admin] Get search result',
	GET_SEARCH_RESULT_SUCCESS = '[Flink Admin] Get search result success',
	GET_SEARCH_RESULT_ERROR = '[Flink Admin] Get search result error',

	ADD_FLINKER_TO_SEARCH = '[Flink Admin] Add Flinker to search results',
	ADD_FLINKER_TO_SEARCH_SUCCESS = '[Flink Admin] Add Flinker to search results success',
	ADD_FLINKER_TO_SEARCH_ERROR = '[Flink Admin] Add Flinker to search results error',

	REMOVE_FLINKER_FROM_SEARCH = '[Flink Admin] Remove Flinker from search results',
	REMOVE_FLINKER_FROM_SEARCH_SUCCESS = '[Flink Admin] Remove Flinker from search results success',
	REMOVE_FLINKER_FROM_SEARCH_ERROR = '[Flink Admin] Remove Flinker from search results error',
}

export class FetchAllFlinkCos implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKCOS;
	constructor(public payload: { flinkCoFilter: FlinkcoAdminFilterRequest }) {}
}

export class FetchAllFlinkCosSuccess implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKCOS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<FlinkCoResponse> }) {}
}

export class FetchAllFlinkCosError implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKCOS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchAllQuotes implements Action {
	readonly type = ActionTypes.FETCH_ALL_QUOTES;
	constructor(public payload: { pageSize: number; pageNumber: number; quoteReference: string }) {}
}

export class FetchAllQuotesSuccess implements Action {
	readonly type = ActionTypes.FETCH_ALL_QUOTES_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<FlinkCoResponse> }) {}
}

export class FetchAllQuotesError implements Action {
	readonly type = ActionTypes.FETCH_ALL_QUOTES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateFlinkCo implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO;
	constructor(public payload: { flinkCo: FlinkCoResponse }) {}
}

export class UpdateFlinkCoSuccess implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UpdateFlinkCoError implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkCoSubscription implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION;
	constructor(public payload: { flinkCoId: string; flinkCoSubscriptionRequest: FlinkCoSubscriptionRequest }) {}
}

export class SubmitFlinkCoSubscriptionSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: FlinkCoSubscriptionResponse }) {}
}

export class SubmitFlinkCoSubscriptionError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class AddAdhocSubscription implements Action {
	readonly type = ActionTypes.ADD_ADHOC_SUBCRIPTION;
	constructor(public payload: { flinkCoId: string; adhocInformation: AdHocInformationRequest }) {}
}

export class AddAdhocSubscriptionSuccess implements Action {
	readonly type = ActionTypes.ADD_ADHOC_SUBCRIPTION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class AddAdhocSubscriptionError implements Action {
	readonly type = ActionTypes.ADD_ADHOC_SUBCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class AddRetainerSubscription implements Action {
	readonly type = ActionTypes.ADD_RETAINER_SUBCRIPTION;
	constructor(public payload: { flinkCoId: string; retainerInformation: RetainerInformationRequest }) {}
}

export class AddRetainerSubscriptionSuccess implements Action {
	readonly type = ActionTypes.ADD_RETAINER_SUBCRIPTION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class AddRetainerSubscriptionError implements Action {
	readonly type = ActionTypes.ADD_RETAINER_SUBCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CancelRetainerSubscription implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBCRIPTION;
	constructor(public payload: { flinkCoId: string }) {}
}

export class CancelRetainerSubscriptionSuccess implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBCRIPTION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CancelRetainerSubscriptionError implements Action {
	readonly type = ActionTypes.CANCEL_RETAINER_SUBCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteFlinkCo implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO;
	constructor(public payload: { flinkCoId: string }) {}
}
export class DeleteFlinkCoSuccess implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO_SUCCESS;
	constructor(public payload: { result: string }) {}
}
export class DeleteFlinkCoError implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class LoginAsFlinkCo implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKCO;
	constructor(public payload: { flinkCoId: string }) {}
}
export class LoginAsFlinkCoSuccess implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKCO_SUCCESS;
	constructor(public payload: { result: SignInResponse }) {}
}
export class LoginAsFlinkCoError implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKCO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetSelectedFlinkCo implements Action {
	readonly type = ActionTypes.GET_SELECTED_FLINKCO;
	constructor(public payload: { flinkCoId: string }) {}
}
export class GetSelectedFlinkCoSuccess implements Action {
	readonly type = ActionTypes.GET_SELECTED_FLINKCO_SUCCESS;
	constructor(public payload: { result: FlinkCoResponse }) {}
}
export class GetSelectedFlinkCoError implements Action {
	readonly type = ActionTypes.GET_SELECTED_FLINKCO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkCoSearches implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_SEARCHES;
	constructor(public payload: { flinkCoId: string }) {}
}
export class FetchFlinkCoSearchesSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_SEARCHES_SUCCESS;
	constructor(public payload: { result: SearchResponse[] }) {}
}
export class FetchFlinkCoSearchesError implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_SEARCHES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchActiveSearch implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH;
	constructor(public payload: { flinkCoId: string; searchId: string }) {}
}

export class FetchActiveSearchSuccess implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH_SUCCESS;
	constructor(public payload: { result: AdminSearchResponse }) {}
}

export class FetchActiveSearchError implements Action {
	readonly type = ActionTypes.FETCH_ACTIVE_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetSearchResult implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT;
	constructor(
		public payload: {
			filterFlinkerRequest: FilterFlinkerRequestDto;
		},
	) {}
}

export class GetSearchResultSuccess implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT_SUCCESS;
	constructor(public payload: { result: AdminSearchResultResponseDto }) {}
}

export class GetSearchResultError implements Action {
	readonly type = ActionTypes.GET_SEARCH_RESULT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class AddFlinkerToSearchResult implements Action {
	readonly type = ActionTypes.ADD_FLINKER_TO_SEARCH;
	constructor(
		public payload: {
			flinkerId: string;
			searchId: string;
		},
	) {}
}

export class AddFlinkerToSearchResultSuccess implements Action {
	readonly type = ActionTypes.ADD_FLINKER_TO_SEARCH_SUCCESS;
	constructor(public payload: { result: AdminMatchedFlinkerResponse }) {}
}

export class AddFlinkerToSearchResultError implements Action {
	readonly type = ActionTypes.ADD_FLINKER_TO_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class RemoveFlinkerFromSearchResult implements Action {
	readonly type = ActionTypes.REMOVE_FLINKER_FROM_SEARCH;
	constructor(
		public payload: {
			flinkerId: string;
			searchId: string;
		},
	) {}
}

export class RemoveFlinkerFromSearchResultSuccess implements Action {
	readonly type = ActionTypes.REMOVE_FLINKER_FROM_SEARCH_SUCCESS;
	constructor(public payload: { result: AdminMatchedFlinkerResponse }) {}
}

export class RemoveFlinkerFromSearchResultError implements Action {
	readonly type = ActionTypes.REMOVE_FLINKER_FROM_SEARCH_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| FetchAllFlinkCos
	| FetchAllFlinkCosSuccess
	| FetchAllFlinkCosError
	| UpdateFlinkCo
	| UpdateFlinkCoSuccess
	| UpdateFlinkCoError
	| FetchAllQuotes
	| FetchAllQuotesSuccess
	| FetchAllQuotesError
	| AddAdhocSubscription
	| AddAdhocSubscriptionSuccess
	| AddAdhocSubscriptionError
	| AddRetainerSubscription
	| AddRetainerSubscriptionSuccess
	| AddRetainerSubscriptionError
	| CancelRetainerSubscription
	| CancelRetainerSubscriptionSuccess
	| CancelRetainerSubscriptionError
	| SubmitFlinkCoSubscription
	| SubmitFlinkCoSubscriptionSuccess
	| SubmitFlinkCoSubscriptionError
	| DeleteFlinkCo
	| DeleteFlinkCoSuccess
	| DeleteFlinkCoError
	| LoginAsFlinkCo
	| LoginAsFlinkCoSuccess
	| LoginAsFlinkCoError
	| GetSelectedFlinkCo
	| GetSelectedFlinkCoSuccess
	| GetSelectedFlinkCoError
	| FetchFlinkCoSearches
	| FetchFlinkCoSearchesSuccess
	| FetchFlinkCoSearchesError
	| FetchActiveSearch
	| FetchActiveSearchSuccess
	| FetchActiveSearchError
	| GetSearchResult
	| GetSearchResultSuccess
	| GetSearchResultError
	| AddFlinkerToSearchResult
	| AddFlinkerToSearchResultSuccess
	| AddFlinkerToSearchResultError
	| RemoveFlinkerFromSearchResult
	| RemoveFlinkerFromSearchResultSuccess
	| RemoveFlinkerFromSearchResultError;
