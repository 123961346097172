import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
	getSelectOptionById,
	getSelectOptionName,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from '@models/dto/responses/select-options-response.dto';
import { RootStoreState } from '@root-store';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';

@Component({
	selector: 'app-experience-summary',
	templateUrl: './experience-summary.component.html',
	styleUrls: ['./experience-summary.component.scss'],
})
export class ExperienceSummaryComponent implements OnInit {
	@Input() flinker: any;

	subscriptions = new Subscription();
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	getSelectOptionNameById = getSelectOptionNameById;
	getSelectOptionName = getSelectOptionName;
	showFullText = false;

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsResponse) => {
				this.selectOptions = selectOptionsResponse.result;
			}),
		);
	}

	getJobTitle(jobTitleId: string, otherJobTitle: string): string {
		return getSelectOptionById(this.selectOptions?.jobTitles, jobTitleId)?.code === 'OTH'
			? otherJobTitle
			: getSelectOptionNameById(this.selectOptions?.jobTitles, jobTitleId);
	}

	showText() {
		this.showFullText = !this.showFullText;
	}
}
