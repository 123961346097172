<div class="d-flex">
	<mat-icon class="header-icon front-main-1">{{ icon }}</mat-icon>
	<mat-card-title class="pl-4 mt-0 font-size-7 font-demi-bold front-main-1">
		{{ title }}
	</mat-card-title>
	<div class="spacer"></div>
	<div *ngIf="editIcon" class="edit-icon" (click)="edit.emit()">
		<mat-icon class="font-size-8">edit</mat-icon>
		<span class="font-size-8 font-demi-bold pl-2"> Edit </span>
	</div>
</div>
<hr class="my-2" />
