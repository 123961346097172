<div class="container mw-100 min-vh-100 p-1 p-md-5">
	<mat-card class="mb-5">
		<app-page-header-toolbar
			icon="question_answer"
			[heading]="'Offer for ' + (getFlinkerOfferHttpPackage$ | async).result?.position"
		>
			<app-highlighted-text
				[theme]="getOfferStatusColour((getFlinkerOfferHttpPackage$ | async)?.result?.offerStatus)"
				class="mt-2"
			>
				{{ (getFlinkerOfferHttpPackage$ | async).result?.offerStatus }}
			</app-highlighted-text>
		</app-page-header-toolbar>

		<section *ngIf="(getFlinkerOfferHttpPackage$ | async).loading">
			<div class="row px-5">
				<div class="col-12 col-sm-6">
					<ngx-skeleton-loader count="2" appearance="line" [theme]="{ height: '50px', 'border-radius': '12px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-12 col-sm-6">
					<ngx-skeleton-loader count="2" appearance="line" [theme]="{ height: '50px', 'border-radius': '12px' }">
					</ngx-skeleton-loader>
				</div>
			</div>
			<div class="row px-5">
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="col-4 mt-3">
					<ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '50px', 'border-radius': '8px' }">
					</ngx-skeleton-loader>
				</div>
			</div>
		</section>

		<div class="container mw-100 px-1 px-md-5" *ngIf="!(getFlinkerOfferHttpPackage$ | async).loading">
			<div class="row">
				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> Position </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">work</mat-icon>
							{{ (getFlinkerOfferHttpPackage$ | async)?.result?.position }}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> {{dictionaryWords.Salary}} </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">paid</mat-icon>
							R{{ (getFlinkerOfferHttpPackage$ | async)?.result?.salary | number: '.2' }}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> Commencement date </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">event</mat-icon>
							{{ (getFlinkerOfferHttpPackage$ | async)?.result?.startDate | dateFormat: 'DD/MM/YYYY' }}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-6 d-block py-2">
					<span class="d-block font-light font-size-10"> Probation period </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">hourglass_bottom</mat-icon>
							{{ (getFlinkerOfferHttpPackage$ | async)?.result?.probationPeriod }}
						</span>
					</div>
				</div>
				<div class="col-12 d-block py-2" *ngIf="offer.benefits">
					<span class="d-block font-light font-size-10"> Benefits: </span>
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							{{ offer.benefits }}
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="row px-1 px-md-5 mt-3 mb-5" *ngIf="allowStatusUpdate()">
			<app-square-button
				matStepperNext
				theme="m5"
				value="Reject Offer"
				type="button"
				class="col-12 col-md-4 mt-2 mt-md-0"
				(click)="rejectOffer()"
			>
			</app-square-button>
      <app-square-button
        matStepperNext
        theme="m1"
        value="Download Offer Document"
        type="button"
        class="col-12 col-md-4 mt-2 mt-md-0"
        (click)="downloadOffer()"
        *ngIf="(getFlinkerOfferHttpPackage$ | async)?.result?.offerDocumentUploaded"
      >
      </app-square-button>
			<app-square-button
				matStepperNext
				theme="m2"
				value="Accept Offer"
				type="button"
				class="col-12 col-md-4 mt-2 mt-md-0"
				(click)="acceptOffer()"
			>
			</app-square-button>
		</div>

		<app-page-header-toolbar icon="work" heading="About the employer"> </app-page-header-toolbar>

		<div class="row" *ngIf="(getFlinkerOfferHttpPackage$ | async)?.loading">
			<div class="col-12 px-4 d-flex flex-column align-items-center">
				<ngx-skeleton-loader
					class="m-3"
					count="1"
					appearance="line"
					[theme]="{ 'border-radius': '10px', width: '100px', height: '100px', margin: '0px' }"
				>
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '150px', height: '20px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="my-4" count="1" appearance="line" [theme]="{ width: '130px', height: '20px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="5" appearance="line" [theme]="{ height: '20px' }">
				</ngx-skeleton-loader>
			</div>
			<div class="col-12 col-md-9 col-lg-8 col-xl-9 my-3 pr-3 pr-md-5">
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="2" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="2" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '180px', height: '30px' }">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader class="align-self-start w-100" count="1" appearance="line" [theme]="{ height: '50px' }">
				</ngx-skeleton-loader>
			</div>
		</div>

		<div class="row" *ngIf="!(getFlinkerOfferHttpPackage$ | async)?.loading">
			<div class="col-12 px-4">
				<div class="mb-3 d-flex">
					<app-user-picture
						theme="m-2"
						[size]="120"
						[src]="(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.logo"
						[alt]="(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.registeredName"
					>
					</app-user-picture>

					<div class="d-block mt-5 mt-sm-3">
						<div class="d-flex front-sub-3 font-size-9 font-bold col-12">
							{{ (getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.registeredName }}
							<app-highlighted-text theme="faded1" class="ml-3 font-normal">
								{{
									getSelectOptionNameById(
										(selectOptionsHttpPackage$ | async)?.result?.industries,
										(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.industryId
									)
								}}
							</app-highlighted-text>
						</div>
						<div class="d-block mt-2">
							<div class="d-flex details-underline pt-2">
								<app-bold-label label="Contact person:" class="col-6"></app-bold-label>
								<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon">
									{{ (getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.name }}
									{{ (getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.surname }}
								</span>
							</div>
							<div class="d-flex details-underline pt-2">
								<app-bold-label label="Location:" class="col-6"></app-bold-label>
								<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon">
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.cities,
											(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.cityId
										)
									}},
									{{
										getSelectOptionNameById(
											(selectOptionsHttpPackage$ | async)?.result?.provinces,
											(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.provinceId
										)
									}}
								</span>
							</div>
							<div class="d-flex details-underline pt-2">
								<app-bold-label label="Website:" class="col-6"></app-bold-label>
								<a
									class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-2 center-icon"
									[href]="(getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.website"
									target="blank"
								>
									{{ (getFlinkerOfferHttpPackage$ | async)?.result?.flinkCo.website }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
</div>
