<mat-form-field>
	<label [ngClass]="{ required: required }">{{ labelText }}</label>
	<div class="ghost-input">
		<input
			matInput
			[ngxMatDatetimePicker]="picker"
			[disabled]="disabled"
			(dateChange)="input($event)"
			(blur)="onBlur()"
			[value]="value"
			[min]="minDate"
		/>
	</div>
	<mat-datepicker-toggle class="mt-3 calander-icon" matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
	<ngx-mat-datetime-picker
		#picker
		[showSpinners]="true"
		[showSeconds]="false"
		[stepHour]="1"
		[stepMinute]="1"
		[color]="'primary'"
		[enableMeridian]="false"
		[disableMinute]="false"
		[hideTime]="false"
	>
	</ngx-mat-datetime-picker>
	<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
