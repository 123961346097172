import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountType } from '@app/enums/account-type.enum';
import { RoleAuthService } from '@app/services/auth/role-auth.service';

@Directive({
	selector: '[hasRole]',
})
export class HasRoleDirective {
	private requiredRoles: AccountType[];
	private hasPermission = false;

	@Input()
	set hasRole(value: AccountType[]) {
		this.requiredRoles = value;
		this.updatePermission();
	}
	constructor(
		private roleAuthService: RoleAuthService,
		private readonly viewContainer: ViewContainerRef,
		private readonly intendedTemplate: TemplateRef<any>,
	) {
		this.clearView();
		this.updatePermission();
	}

	private updatePermission() {
		const hasView = this.roleAuthService.isAuthorized(this.requiredRoles);
		if (hasView === true && this.hasPermission === false) {
			this.renderView();
		} else if (hasView === false && this.hasPermission === true) {
			this.clearView();
		}
	}

	private renderView() {
		this.viewContainer.createEmbeddedView(this.intendedTemplate);
		this.hasPermission = true;
	}

	private clearView() {
		this.viewContainer.clear();
		this.hasPermission = false;
	}
}
