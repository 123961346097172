import { MediaMatcher } from '@angular/cdk/layout';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements AfterViewInit, OnInit {
	@Input() events: any[] = [];
	@Output() onEventClick: EventEmitter<any> = new EventEmitter();
	@ViewChild('calendar') calendarComponent: FullCalendarComponent;

	mobileQuery: MediaQueryList;

	Calendar = Calendar;
	calendarOptions: CalendarOptions = {
		plugins: [dayGridPlugin, timeGridPlugin],
		handleWindowResize: true,
		headerToolbar: {
			left: 'prev,next today',
			center: 'title',
			right: 'dayGridMonth,timeGridWeek,timeGridDay',
		},
		views: {
			dayGridMonth: {
				type: 'dayGridMonth',
				buttonText: 'Month',
				dayHeaderFormat: {
					weekday: 'short',
				},
			},
			timeGridWeek: {
				type: 'timeGridWeek',
				buttonText: 'Week',
				eventDisplay: 'block',
			},
			timeGridDay: {
				type: 'timeGridDay',
				buttonText: 'Day',
			},
		},
		slotLabelFormat: 'HH:mm',
		titleFormat: 'MMMM YYYY',
		eventTimeFormat: {
			hour: '2-digit',
			minute: '2-digit',
			meridiem: false,
		},
		height: '60vh',
		eventDisplay: 'block',
		eventClick: this.onClick.bind(this),
	};

	private _mobileQueryListener: () => void;

	constructor(private media: MediaMatcher, private changeDetectorRef: ChangeDetectorRef) {
		this.mobileQuery = media.matchMedia('(max-width: 992px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
		if (this.mobileQuery.matches) {
			this.calendarOptions.initialView = 'timeGridDay';
			this.calendarOptions.headerToolbar = {
				left: 'prev,next',
				center: 'title',
				right: 'dayGridMonth,timeGridWeek,timeGridDay',
			};
		} else {
			this.calendarOptions.initialView = 'dayGridMonth';
		}
	}

	ngOnInit(): void {
		this.calendarOptions.events = this.events;
	}

	ngOnChanges(changes: SimpleChanges) {
		const events: SimpleChange = changes.events;
		if (events.currentValue !== events.previousValue) {
			this.calendarOptions.events = this.events;
		}
	}

	onClick(e: any) {
		this.onEventClick.emit(e);
	}

	ngAfterViewInit(): void {
		const calendarApi = this.calendarComponent.getApi();
		setTimeout(() => {
			calendarApi.render();
		}, 5);
	}
}
