<div class="d-flex py-4 px-0 details-underline container" *ngFor="let qualification of qualifications">
	<div class="row w-100">
		<div class="col-11 education-title-box">
			<span class="d-block font-light font-size-10">
				{{ qualification?.name }}
				<app-highlighted-text theme="faded2 font-size-6" class="ml-2" *ngIf="qualification?.highest">
					Highest
				</app-highlighted-text>
				<app-highlighted-text
					theme="faded10 font-size-6"
					class="ml-2"
					*ngIf="qualification?.type === QualificationType.Matric"
				>
					Matric
				</app-highlighted-text>
				<app-highlighted-text theme="faded1 font-size-6" class="ml-2" *ngIf="qualification?.inProgress">
					In progress
				</app-highlighted-text>
			</span>
		</div>
    <button
      mat-icon-button
      class="m1 col-1"
      *ngIf="qualification?.uploaded && interviewAccepted"
      (click)="downloadQualificationDocument(qualification?.id)"
    >
      <mat-icon>download</mat-icon>
    </button>
		<div class="col-12 col-md-6 pt-2 d-flex">
			<span class="front-sub-5 font-size-11 font-medium center-icon">
				<mat-icon class="front-main-1 font-size-11 center-icon pr-2">school</mat-icon>
				{{ getInstitution(qualification) }}
			</span>
		</div>
		<div class="col-12 pt-2 d-flex">
			<span class="font-light font-size-14">{{ getCompletionYear(qualification) }}</span>
		</div>
	</div>
</div>
