<input
  class="square-input"
  [ngClass]="{ error: errorMessage.length > 0 }"
  [type]="localType"
  [placeholder]="placeholder"
  [disabled]="disabled"
  (input)="input($event)"
  (blur)="onBlur()"
/>

<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
