import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { Subscription } from 'rxjs';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { getSelectOptionName, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { getErrorMessage } from '@app/utils/form.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import FlinkerPersonalDetailsRequest from '@models/dto/requests/flinker-personal-details-request.dto';
import { plainToInstance } from 'class-transformer';

@Component({
	selector: 'app-job-status',
	templateUrl: './job-status.component.html',
	styleUrls: ['./job-status.component.scss'],
})
export class JobStatusComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	flinkerPersonalDetailsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerPersonalDetailsHttpPackage,
	);
	getSelectOptionName = getSelectOptionName;
	form: FormGroup;
	flinker: FlinkerResponse;
	selectOptionsHttpPackage: SelectOptionsResponse;
	shouldValidate = false;
	expectUpdate = false;

	getErrorMessage = getErrorMessage;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
	) {
		this.form = this.formBuilder.group({
			availabilityStatus: ['', [Validators.required]],
			visibilityStatus: ['', [Validators.required]],
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.flinker$.subscribe((flinker) => {
				if (flinker != null) {
					this.flinker = flinker;
					this.form.patchValue({
						...flinker,
					});
				}
			}),
		);

		this.subscriptions.add(
			this.flinkerPersonalDetailsHttpPackage$.subscribe((flinkerPersonalDetailsHttpPackage) => {
				if (flinkerPersonalDetailsHttpPackage.result !== null && this.expectUpdate) {
					this.notifierService.notify(NotificationType.Success, 'Job status updated successfully');
				}
			}),
		);
	}

	submit(): void {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.expectUpdate = true;
		const formValues = this.form.value;
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetails({
				flinkerPersonalDetailsRequest: plainToInstance(
					FlinkerPersonalDetailsRequest,
					{
						...this.flinker,
						...formValues,
						preferredMethodOfContact: this.flinker.methodOfContact,
					},
					{ excludeExtraneousValues: true },
				),
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
