import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-square-button-with-icon',
	templateUrl: './square-button-with-icon.component.html',
	styleUrls: ['./square-button-with-icon.component.scss'],
})
export class SquareButtonWithIconComponent implements OnInit {
	@Input() theme = '';
	@Input() value = '';
	@Input() disabled = false;
	@Input() type = '';
	@Input() icon = '';
	@Input() loading = false;

	constructor() {}

	ngOnInit(): void {}
}
