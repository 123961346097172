<div class="row">
	<div class="col-12 p-lg-5 p-2 d-flex">
		<div class="col-3 pl-2 pl-lg-3">
			<span class="font-size-9 font-size-3-md font-bold">Plans</span>
		</div>
		<div class="col-3">
			<span class="font-size-12 font-size-6-md font-bold d-block text-center">Free Demo</span>
			<span class="font-size-12 font-size-9-md font-bold text-center pt-2 d-none d-md-block">Try it out!</span>
		</div>
		<div class="col-3">
			<span class="font-size-12 font-size-6-md font-bold d-block text-center">Pay per Placement</span>
			<span class="font-size-12 font-size-9-md font-bold text-center pt-2 d-none d-md-block">Once-off</span>
		</div>
		<div class="col-3">
			<span class="font-size-12 font-size-6-md font-bold d-block text-center">Subscription</span>
			<span class="font-size-12 font-size-9-md font-bold text-center pt-2 d-none d-md-block">Monthly</span>
			<div class="justify-content-center value d-none d-md-flex">
				<span class="px-5 py-2 back-main-2 front-sub-1 font-bold">Best Value</span>
			</div>
		</div>
	</div>
</div>

<div class="row back-sub-2 px-1 px-md-5 py-3">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 pl-2 pl-md-3 align-self-center">
			<span class="font-size-12 font-size-8-md font-bold d-block">Test Flink Platform</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
		<div class="col-3 align-self-center">
			<div class="dash mx-auto"></div>
		</div>
		<div class="col-3 align-self-center">
			<div class="dash mx-auto"></div>
		</div>
	</div>
</div>

<div class="row px-1 px-md-5 py-3">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 pl-2 pl-md-3 align-self-center">
			<span class="font-size-12 font-size-8-md font-bold d-block">Create job searches</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="font-size-12 font-size-8-md front-main-2 font-bold d-block text-center">Unlimited</span>
		</div>
	</div>
</div>

<div class="row back-sub-2 px-1 px-md-5 py-3">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 pl-2 pl-md-3 align-self-center">
			<span class="font-size-12 font-size-8-md font-bold d-block">Set up interview</span>
		</div>
		<div class="col-3 align-self-center">
			<div class="dash mx-auto"></div>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
	</div>
</div>

<div class="row px-1 px-md-5 py-3">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 pl-2 pl-md-3 align-self-center">
			<span class="font-size-11 font-size-8-md font-bold d-block">Make offer</span>
		</div>
		<div class="col-3 align-self-center">
			<div class="dash mx-auto"></div>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span class="front-main-2 d-block text-center material-icons-outlined">
				<mat-icon mat-list-icon>done</mat-icon>
			</span>
		</div>
	</div>
</div>

<div class="row px-1 px-md-5 py-3 d-none d-md-block">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 offset-3">
			<span class="font-size-12 font-size-10-md px-2 text-center d-flex">
				Take a look around to see how Flink can solve your hiring challenges.
			</span>
		</div>
		<div class="col-3">
			<span class="font-size-12 font-size-10-md px-2 text-center d-flex justify-content-center">
				You pay per placement!
			</span>
		</div>
		<div class="col-3">
			<span class="font-size-12 font-size-10-md px-2 text-center d-flex">
				For organisations needing regular placements.
			</span>
		</div>
	</div>
</div>

<div class="row px-1 px-md-5 py-3" *ngIf="(flinkco$ | async)?.takeOnComplete === true">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 offset-3 align-self-center">
			<span
				class="font-size-11 font-size-8-md front-sub-7 font-bold px-4 text-center d-flex justify-content-center"
				*ngIf="(flinkco$ | async)?.subscription === 'Demo'"
			>
				Your Plan
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span
				class="font-size-11 font-size-8-md front-sub-7 font-bold px-4 text-center d-flex justify-content-center"
				*ngIf="(flinkco$ | async)?.subscription === 'AdHoc'"
			>
				{{ adHocPlacementLimitReached ? 'Upgrade' : 'Your Plan: R' }} {{ adHocPlacementLimitReached ? '' : (flinkco$ | async)?.activeQuote.activeAmount}}
			</span>
		</div>
		<div class="col-3 align-self-center">
			<span
				class="font-size-11 font-size-8-md front-sub-7 font-bold px-4 text-center d-flex justify-content-center"
				*ngIf="(flinkco$ | async)?.subscription === 'Retainer'"
			>
				Your Plan: R {{(flinkco$ | async)?.activeQuote.activeAmount}}
			</span>
		</div>
	</div>
</div>

<div class="row px-1 px-md-5 py-2 mb-3 mb-md-5">
	<div class="col-12 px-0 d-flex">
		<div class="col-3 offset-3 align-self-center">
			<app-rounded-button
				[theme]="selectedSubscription === UserPlanSubscription.Demo ? 'm2 mobile' : 'm1 mobile'"
				type="submit"
				[value]="selectedSubscription === UserPlanSubscription.Demo ? 'Selected' : 'Select'"
				class="d-flex justify-content-center"
				(click)="userPlanSelected.emit(UserPlanSubscription.Demo); selectedSubscription = UserPlanSubscription.Demo"
				*ngIf="(flinkco$ | async)?.takeOnComplete === false"
			>
			</app-rounded-button>
		</div>

		<div class="col-3 align-self-center">
			<app-rounded-button
				[theme]="selectedSubscription === UserPlanSubscription.AdHoc ? 'm2 mobile' : 'm1 mobile'"
				type="submit"
				[value]="selectedSubscription === UserPlanSubscription.AdHoc ? 'Selected' : 'Select'"
				class="d-flex justify-content-center"
				(click)="userPlanSelected.emit(UserPlanSubscription.AdHoc); selectedSubscription = UserPlanSubscription.AdHoc"
				*ngIf="
					(flinkco$ | async)?.takeOnComplete === false ||
					adHocPlacementLimitReached ||
					((flinkco$ | async)?.subscription !== UserPlanSubscription.AdHoc &&
						(flinkco$ | async)?.subscription !== UserPlanSubscription.Retainer)
				"
			>
			</app-rounded-button>
		</div>

		<div class="col-3 align-self-center">
			<app-rounded-button
				[theme]="selectedSubscription === UserPlanSubscription.Retainer ? 'm2 mobile' : 'm1 mobile'"
				type="submit"
				[value]="selectedSubscription === UserPlanSubscription.Retainer ? 'Selected' : 'Select'"
				class="d-flex justify-content-center"
				(click)="
					userPlanSelected.emit(UserPlanSubscription.Retainer); selectedSubscription = UserPlanSubscription.Retainer
				"
				*ngIf="(flinkco$ | async)?.subscription !== 'Retainer' || (flinkco$ | async)?.takeOnComplete === false"
			>
			</app-rounded-button>

			<app-rounded-button
				theme="m5 mobile"
				type="submit"
				[value]="'Cancel'"
				class="d-flex justify-content-center"
				(click)="cancelRetainer.emit()"
				*ngIf="
					(flinkco$ | async)?.subscription === 'Retainer' &&
					(flinkco$ | async)?.takeOnComplete === true &&
					!(flinkco$ | async)?.subscriptionCancellationRequested
				"
			>
			</app-rounded-button>
		</div>
	</div>
</div>

<hr class="mx-2 mx-md-5" />

<div class="row m-0 m-md-5 d-flex justify-content-between" *ngIf="showRequestDemoBlock">
	<div class="d-flex">
		<div class="demo-icon front-main-2 mb-4 d-none d-md-flex">
			<mat-icon mat-list-icon>account_circle</mat-icon>
		</div>
		<div class="d-block pl-2 pl-md-5 align-self-center">
			<span class="font-size-7 font-size-3-md font-bold d-block">Request a demo</span>
			<span class="font-size-11 font-size-9-md font-bold d-block pt-4"
				>Book a session with one of our consultants for a full product demo.</span
			>
		</div>
	</div>
	<div class="d-flex align-self-center mt-3 mt-md-0 mx-auto mx-md-1">
		<app-rounded-button
			class="justify-content-center justify-content-sm-end"
			theme="m1 mobile"
			type="submit"
			[value]="'Request a demo'"
			(click)="requestDemoClick()"
		>
		</app-rounded-button>
	</div>
</div>
