import { AbstractControl } from '@angular/forms';

export function getErrorMessage(formControl: AbstractControl, inputDescription: string, shouldValidate = false) {
	if ((formControl?.touched || formControl?.dirty || shouldValidate) && formControl?.errors) {
		if (formControl?.errors?.required) {
			return `${inputDescription} is required`;
		} else if (formControl?.invalid && formControl?.errors?.custom) {
			return formControl.errors.custom;
		} else if (
			formControl?.invalid &&
			(inputDescription === 'Password' || inputDescription === 'Confirm Password') &&
			formControl?.value != ''
		) {
			return `${inputDescription} requires one uppercase, one digit, one special character and be atleast 8 characters`;
		} else if (formControl?.errors) {
			return `${inputDescription} is invalid`;
		}
	}

	return '';
}
