import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OfferStoreEffects } from './effects';
import { offerReducer } from './reducer';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature('offer', offerReducer), EffectsModule.forFeature([OfferStoreEffects])],
	providers: [OfferStoreEffects],
})
export class OfferStoreModule {}
