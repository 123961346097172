import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { InterviewQuestionnaireReducer } from '@root-store/interview-questionnaire-store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { InterviewQuestionnaireStoreEffects } from '@root-store/interview-questionnaire-store/effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('interview-questionnaire', InterviewQuestionnaireReducer),
		EffectsModule.forFeature([InterviewQuestionnaireStoreEffects]),
	],
	providers: [InterviewQuestionnaireStoreEffects],
})
export class InterviewQuestionnaireStoreModule {}
