import { VideoState } from '@root-store/video-store/state';
import HttpPackage from '@models/http-package';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoUrlResponse } from '@models/dto/responses/video-url-response.dto';

const getCreateVideoRoomHttpPackage = (state: VideoState): HttpPackage<string> => state.createVideoHttpPackage;
const getVideoRecordingHttpPackage = (state: VideoState): HttpPackage<VideoUrlResponse[]> =>
	state.getVideoRecordingHttpPackage;
const getVideoCompositionHttpPackage = (state: VideoState): HttpPackage<string> => state.getVideoCompositionHttpPackage;
const deleteVideoRecordingHttpPackage = (state: VideoState): HttpPackage<string> =>
	state.deleteVideoRecordingHttpPackage;
const deleteCompositionRecordingHttpPackage = (state: VideoState): HttpPackage<string> =>
	state.deleteCompositionRecordingHttpPackage;

export const selectVideoState = createFeatureSelector<VideoState>('video');

export const selectCreateVideoRoomHttpPackage = createSelector(selectVideoState, getCreateVideoRoomHttpPackage);
export const selectGetVideoRecordingHttpPackage = createSelector(selectVideoState, getVideoRecordingHttpPackage);
export const selectGetVideoCompositionHttpPackage = createSelector(selectVideoState, getVideoCompositionHttpPackage);
export const selectDeleteVideoRecordingHttpPackage = createSelector(selectVideoState, deleteVideoRecordingHttpPackage);
export const selectDeleteCompositionRecordingHttpPackage = createSelector(
	selectVideoState,
	deleteCompositionRecordingHttpPackage,
);
