import { Injectable } from '@angular/core';
import ValidationRequest from '../../models/dto/requests/validation-request.dto';
import ValidationResponse from '../../models/dto/responses/validation-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class ValidationService {
	apiValidationEndpoint = 'validation';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	validateIdNumber(validationRequest: ValidationRequest) {
		return this.httpV2Service.postSecure<ValidationResponse>(
			`${this.apiValidationEndpoint}/idNumber`,
			validationRequest,
		);
	}

	validatePassportNumber(validationRequest: ValidationRequest) {
		return this.httpV2Service.postSecure<ValidationResponse>(
			`${this.apiValidationEndpoint}/passportNumber`,
			validationRequest,
		);
	}

	validateCompanyRegistrationNumber(validationRequest: ValidationRequest) {
		return this.httpV2Service.postSecure<ValidationResponse>(
			`${this.apiValidationEndpoint}/companyRegistrationNumber`,
			validationRequest,
		);
	}

	validateVatNumber(validationRequest: ValidationRequest) {
		return this.httpV2Service.postSecure<ValidationResponse>(
			`${this.apiValidationEndpoint}/vatNumber`,
			validationRequest,
		);
	}
	validateEmailAddress(validationRequest: ValidationRequest) {
		return this.httpV2Service.postSecure<ValidationResponse>(`${this.apiValidationEndpoint}/email`, validationRequest);
	}
}
