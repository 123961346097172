import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[customSpinner]',
})
export class CustomSpinnerDirective implements AfterViewInit {
	@Input() circleColor: string;

	constructor(private elem: ElementRef) {}

	ngAfterViewInit() {
		if (this.circleColor) {
			const element = this.elem.nativeElement;
			const circle = element.querySelector('circle');
			circle.style.stroke = this.circleColor;
		}
	}
}
