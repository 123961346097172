import { Injectable } from '@angular/core';
import ActiveSubscription from '../../models/dto/responses/active-Subscription-response.dto';
import CostResponse from '../../models/dto/responses/cost-response.dto';
import QuoteResponse from '../../models/dto/responses/quote-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class QuoteService {
	apiQuoteEndpoint = 'quote';
	apiSubscriptionsEndpoint = 'subscriptions';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getRetainerQuote() {
		return this.httpV2Service.getSecure<CostResponse>(`${this.apiQuoteEndpoint}/retainerCost`);
	}

	getAdHocQuote() {
		return this.httpV2Service.getSecure<CostResponse>(`${this.apiQuoteEndpoint}/adHocCost`);
	}

	getActiveQuote() {
		return this.httpV2Service.getSecure<QuoteResponse>(`${this.apiQuoteEndpoint}/activeQuote`);
	}

	getActiveSubscription() {
		return this.httpV2Service.getSecure<ActiveSubscription>(`${this.apiSubscriptionsEndpoint}/activeSubscription`);
	}
}
