export enum CvFeatureType {
	STANDARD = 'STANDARD',
	MIE = 'MIE',
	INTEGRITY = 'INTEGRITY',
}

export default interface CvFeatureResponseDto {
	id: string;
	type: CvFeatureType;
	price: number;
}
