import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { SelectOptionsStoreActions, SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { getSelectOptionNameById, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FlinkCoJobAdvertsActions, FlinkCoJobAdvertsSelectors } from '@root-store/flinkco/job-adverts';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-view-flinkco-job-advert',
	templateUrl: './view-flinkco-job-advert.component.html',
	styleUrls: ['./view-flinkco-job-advert.component.scss'],
})
export class ViewFlinkcoJobAdvertComponent implements OnInit, OnDestroy {
	jobAdvert: JobAdvertResponseDto;
	jobAdvertId: string;
	flinkCoId: string;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	getFlinkerJobAdvertHttpPackage$ = this.store.select(FlinkCoJobAdvertsSelectors.selectPublicFlinkCoJobAdvert);
	getSelectOptionNameById = getSelectOptionNameById;
	selectOptions: SelectOptionsResponse;
	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private store: Store<RootStoreState.State>, private router: Router, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			if (params) {
				this.jobAdvertId = params.get('jobAdvertId');
				this.flinkCoId = params.get('flinkCoId');
			}
		});

		this.store.dispatch(new FlinkCoJobAdvertsActions.FetchPublicFlinkCoJobAdvert({ jobAdvertId: this.jobAdvertId }));
		this.store.dispatch(new SelectOptionsStoreActions.FetchSelectOptions());

		this.selectOptionsHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((selectOptionsResponse) => {
			this.selectOptions = selectOptionsResponse.result;
		});

		this.getFlinkerJobAdvertHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((getFlinkerJobAdvertHttpPackage) => {
			if (getFlinkerJobAdvertHttpPackage.result == null) {
				return;
			}
			this.jobAdvert = getFlinkerJobAdvertHttpPackage.result;
		});
	}

	getJobTitle(jobTitleId: string, otherJobTitle: string): string {
		return this.selectOptions?.jobTitles.find((jobTitle) => jobTitle.id === jobTitleId)?.code === 'OTH'
			? otherJobTitle
			: this.selectOptions?.jobTitles.find((jobTitle) => jobTitle.id === jobTitleId)?.name;
	}

	onApplication() {
		const url = `${environment.baseUrl}/take-on/flinker-sign-on/${this.jobAdvertId}`;
		window.open(url, '_blank');
	}
	ngOnDestroy() {}
}
