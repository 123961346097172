export interface GenericOption {
	displayName: string;
	value: any;
}

export interface GenericOptionSelect extends GenericOption {
	checked: boolean;
}

export const getGenericOptionName = (genericOptions: GenericOption[], value: string): string => {
	return genericOptions?.find((genericOption) => genericOption?.value === value)?.displayName;
};
