import { FlinkCoTakeOnState, initialState } from './state';
import { Actions, ActionTypes } from './actions';
import { act } from '@ngrx/effects';

export function flinkCoTakeOnReducer(state = initialState, action: Actions): FlinkCoTakeOnState {
	switch (action.type) {
		case ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION:
			return {
				...state,
				flinkCoCompanyInformationHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					...action.payload.result,
				},
				flinkCoCompanyInformationHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION_ERROR:
			return {
				...state,
				flinkCoCompanyInformationHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_COMPANY_LOGO:
			return {
				...state,
				uploadCompanyLogoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					logo: action.payload.result,
				},
				uploadCompanyLogoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_COMPANY_LOGO_ERROR:
			return {
				...state,
				uploadCompanyLogoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS:
			return {
				...state,
				uploadCIPCCompanyDocumentsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS_SUCCESS:
			return {
				...state,
				uploadCIPCCompanyDocumentsHttpPackage: {
					error: null,
					loading: false,
					result: 'Success',
				},
			};
		case ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS_ERROR:
			return {
				...state,
				uploadCIPCCompanyDocumentsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION:
			return {
				...state,
				flinkCoSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					subscription: action.payload.result.subscription,
				},
				flinkCoSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_ERROR:
			return {
				...state,
				flinkCoSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION:
			return {
				...state,
				flinkCoRetainerInformationHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					...action.payload.result,
				},
				flinkCoRetainerInformationHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION_ERROR:
			return {
				...state,
				flinkCoRetainerInformationHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION:
			return {
				...state,
				flinkCoAdHocInformationHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					...action.payload.result,
				},
				flinkCoAdHocInformationHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION_ERROR:
			return {
				...state,
				flinkCoAdHocInformationHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKCO_USER:
			return {
				...state,
				deleteFlinkCoUserHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKCO_USER_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					users: state.flinkCo.users.filter((flinkCoUser) => flinkCoUser.id !== action.payload.result.userId),
				},
				deleteFlinkCoUserHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result.userId,
				},
			};
		case ActionTypes.DELETE_FLINKCO_USER_ERROR:
			return {
				...state,
				deleteFlinkCoUserHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO:
			return {
				...state,
				flinkCoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_SUCCESS:
			return {
				...state,
				flinkCo: action.payload.result,
				flinkCoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_ERROR:
			return {
				...state,
				flinkCoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS:
			return {
				...state,
				flinkCoTermsAndConditionsPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					takeOnComplete: true,
				},
				flinkCoTermsAndConditionsPackage: {
					error: null,
					loading: false,
					result: 'Success',
				},
			};
		case ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_ERROR:
			return {
				...state,
				flinkCoTermsAndConditionsPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.ADD_FLINKCO_USERS:
			return {
				...state,
				addFlinkCoUsersHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.ADD_FLINKCO_USER_SUCCESS:
			return {
				...state,
				flinkCo: {
					...state.flinkCo,
					users: action.payload.result,
				},
				addFlinkCoUsersHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.ADD_FLINKCO_USER_ERROR:
			return {
				...state,
				addFlinkCoUsersHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.GET_RETAINER_SUBSCRIPTION:
			return {
				...state,
				getRetainerSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_RETAINER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				getRetainerSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_RETAINER_SUBSCRIPTION_ERROR:
			return {
				...state,
				getRetainerSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_WITH_SELF:
			return {
				...state,
				flinkCoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_WITH_SELF_SUCCESS:
			return {
				...state,
				flinkCo: action.payload.result,
				flinkCoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_WITH_SELF_ERROR:
			return {
				...state,
				flinkCoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
