import HttpPackage from '@models/http-package';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import InterviewQuestionnaireResponse from '@models/dto/responses/interview-questionnaire-response.dto';
import { DefaultInterviewQuestionCategoriesResponse } from '@models/dto/responses/default-interview-question-categories-response.dto';
import DefaultInterviewQuestionResponse from '@models/dto/responses/default-interview-question-response.dto';

export interface InterviewQuestionnaireState {
	getPaginatedInterviewQuestionnairesHttpPackage: HttpPackage<PaginatedResponse<InterviewQuestionnaireResponse>>;
	getInterviewQuestionnaireHttpPackage: HttpPackage<InterviewQuestionnaireResponse>;
	interviewQuestionCategoriesHttpPackage: HttpPackage<DefaultInterviewQuestionCategoriesResponse>;
	filteredDefaultInterviewQuestions: HttpPackage<DefaultInterviewQuestionResponse[]>;
	allDefaultInterviewQuestions: HttpPackage<DefaultInterviewQuestionResponse[]>;
	createInterviewQuestionnaireHttpPackage: HttpPackage<InterviewQuestionnaireResponse>;
	updateInterviewQuestionnaireHttpPackage: HttpPackage<InterviewQuestionnaireResponse>;
	deleteInterviewQuestionnaireHttpPackage: HttpPackage<string>;
	getAllInterviewQuestionnairesHttpPackage: HttpPackage<InterviewQuestionnaireResponse[]>;
}

export const initialState: InterviewQuestionnaireState = {
	getPaginatedInterviewQuestionnairesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getInterviewQuestionnaireHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	interviewQuestionCategoriesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	filteredDefaultInterviewQuestions: {
		error: null,
		loading: false,
		result: null,
	},
	allDefaultInterviewQuestions: {
		error: null,
		loading: false,
		result: null,
	},
	createInterviewQuestionnaireHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateInterviewQuestionnaireHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	deleteInterviewQuestionnaireHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getAllInterviewQuestionnairesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
