import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CvStoreActions, CvStoreSelectors } from '@root-store/cv-store';
import CvFeatureResponseDto, { CvFeatureType } from '@models/dto/responses/cv-feature-response.dto';
import CvPaymentStatusResponseDto from '@models/dto/responses/cv-payment-status-response.dto';
import { filter, takeUntil } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-flinker-cv-payment-dialog',
	templateUrl: './flinker-cv-payment-dialog.component.html',
	styleUrls: ['./flinker-cv-payment-dialog.component.scss'],
})
export class FlinkerCvPaymentDialogComponent implements OnInit, OnDestroy {
	cvFeaturesHttpPackage$ = this.store.select(CvStoreSelectors.selectCvFeatures);
	cvFeatures: CvFeatureResponseDto[] = [];
	cvPaymentStatusHttpPackage$ = this.store.select(CvStoreSelectors.selectGetCvPaymentStatus);
	cvPaymentStatus: CvPaymentStatusResponseDto;
	payForCvHttPackage$ = this.store.select(CvStoreSelectors.selectPayForCv);

	amountDueForCv = 0;
	readonly progressDiameter = 50;
	readonly closeTimeout = 500; //ms
	private readonly destroy$ = new Subject<void>();
	constructor(
		private store: Store<RootStoreState.State>,
		public dialogRef: MatDialogRef<FlinkerCvPaymentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		@Inject(DOCUMENT) private window: Window,
		private notifierService: NotifierService,
	) {}

	ngOnInit(): void {
		this.store.dispatch(new CvStoreActions.GetCvFeatures());
		this.store.dispatch(new CvStoreActions.GetFlinkerCVPaymentStatus());

		this.cvPaymentStatusHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				takeUntil(this.destroy$),
			)
			.subscribe((cvPaymentStatusHttpPackage) => {
				this.cvPaymentStatus = cvPaymentStatusHttpPackage.result;
				if (this.cvPaymentStatus.standard) {
					// TODO Update this to check if all features have been paid for.
					this.startDownload();
				}
			});

		this.cvFeaturesHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				takeUntil(this.destroy$),
			)
			.subscribe((cvFeaturesHttpPackage) => {
				this.cvFeatures = cvFeaturesHttpPackage.result.filter(
					(cvFeature: CvFeatureResponseDto) => cvFeature.type !== CvFeatureType.MIE,
				);
				this.amountDueForCv = this.cvFeatures.reduce(
					(amountDue: number, cvFeature: CvFeatureResponseDto) => amountDue + Number(cvFeature.price),
					0,
				);
			});

		this.payForCvHttPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				takeUntil(this.destroy$),
			)
			.subscribe((paymentForCvHttpPackage) => {
				this.notifierService.notify(NotificationType.Success, 'You will be redirected to the payment page shortly.');
				if (paymentForCvHttpPackage.result.paymentUrl) {
					this.window.location.href = paymentForCvHttpPackage.result.paymentUrl;
				} else {
					this.startDownload();
				}
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	submitPayment() {
		this.store.dispatch(
			new CvStoreActions.PayForCv({
				mieAddonSelected: false,
				integrityAddOnSelected: true,
				useLatestPaymentMethod: false,
			}),
		);
	}

	startDownload() {
		setTimeout(() => this.dialogRef.close({ allowed: true }), this.closeTimeout);
	}

	closeDialog() {
		this.dialogRef.close({ allowed: false });
	}
}
