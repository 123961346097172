import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { RootStoreState } from '@app/root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@app/root-store/flinker-take-on-store';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NotificationType } from '@app/enums/notification-type.enum';

@Component({
	selector: 'app-progress-gauge',
	templateUrl: './progress-gauge.component.html',
	styleUrls: ['./progress-gauge.component.scss'],
})
export class ProgressGaugeComponent implements OnInit {
	@Input() theme = '';
	@Input() completionItems: any[];
	completionPercentage = 0;
	numberOfCompletedItems = 0;
	percentCompletionTheme = '';
	completionLoaderTheme = '';
	profileCompletionItems$ = this.store.select(FlinkerTakeOnStoreSelectors.selectProfileCompletionHttpPackage);
	profilePictureUpload$ = this.store.select(FlinkerTakeOnStoreSelectors.selectUploadProfilePictureHttpPackage);
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	subscriptions = new Subscription();
	uploadProfilePictureHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectUploadProfilePictureHttpPackage,
	);
	Math = Math;
	readonly maxPercentage: number = 100;
	@Input() dontShowProfilePicture = false;
	@Input() showIncompleteOnly = false;
	@Output() profileComplete = new EventEmitter<boolean>();

	constructor(private store: Store<RootStoreState.State>, private notifierService: NotifierService) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.profileCompletionItems$.subscribe((profileCompletionItems) => {
				if (profileCompletionItems.result != null) {
					this.completionItems = profileCompletionItems.result;
					this.initialiseLoader();
				}
			}),
		);
	}

	initialiseLoader(): void {
		this.completionPercentage = 0;
		this.numberOfCompletedItems = 0;
		this.completionItems.forEach((completionItem) => {
			if (completionItem.profileItemComplete) this.numberOfCompletedItems += 1;
		});

		this.completionPercentage = Math.min(
			Math.ceil((this.numberOfCompletedItems / this.completionItems.length) * 100),
			this.maxPercentage,
		);

		if (this.numberOfCompletedItems <= 2) {
			this.percentCompletionTheme = 'b5 fs1';
			this.completionLoaderTheme = 'red-loader';
			this.profileComplete.emit(false);
		} else if (this.numberOfCompletedItems > 2 && this.numberOfCompletedItems <= 5) {
			this.percentCompletionTheme = 'b10 fs1';
			this.completionLoaderTheme = 'orange-loader';
			this.profileComplete.emit(false);
		} else if (this.numberOfCompletedItems === this.completionItems.length) {
			this.percentCompletionTheme = 'b2 fs1';
			this.completionLoaderTheme = 'green-loader';
			this.profileComplete.emit(true);
		}
		if (this.showIncompleteOnly) {
			this.completionItems = this.completionItems.filter(
				(completionItem) => completionItem.profileItemComplete === false,
			);
		}
	}

	uploadProfilePicture(profilePicture: File) {
		const formData = new FormData();
		formData.append('profilePicture', profilePicture, profilePicture.name);
		this.store.dispatch(new FlinkerTakeOnStoreActions.UploadProfilePicture({ formData }));
		this.subscriptions.add(
			this.profilePictureUpload$
				.pipe(filter((value) => value.result !== null))
				.pipe(take(1))
				.subscribe((_) => {
					this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
					this.notifierService.notify(NotificationType.Success, 'Profile picture successfully uploaded');
					this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
				}),
		);
	}
}
