import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-outlined-boolean-dropdown',
	templateUrl: './outlined-boolean-dropdown.component.html',
	styleUrls: ['./outlined-boolean-dropdown.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedBooleanDropdownComponent),
			multi: true,
		},
	],
})
export class OutlinedBooleanDropdownComponent implements OnInit, ControlValueAccessor {
	@Input() errorMessage = '';
	@Input() labelText = '';
	@Input() buttonInput = '';
	@Input() theme = '';
	@Input() formValue = '';
	@Input() formControlName = '';
	@Input() notApplicableOption = false;
	@Input() toolTip = '';
	@Input() showTip = false;

	@Output() onSelected: EventEmitter<any> = new EventEmitter();

	options = [
		{
			code: 'Y',
			name: 'Yes',
		},
		{
			code: 'N',
			name: 'No',
		},
	];

	required = false;
	value: string;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;
	selectedValue!: string;
	selectedCar!: string;

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}

		if (this.notApplicableOption) {
			this.options.push({
				code: 'NA',
				name: 'N/A',
			});
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.value = event.value;
		this.onChange(this.value);
		this.onSelected.emit(this.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}
}
