import { Injectable } from '@angular/core';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { Observable } from 'rxjs';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';
import UpdateCvFeaturePriceRequestDto from '@models/dto/requests/update-cv-feature-price-request.dto';

@Injectable({
	providedIn: 'root',
})
export class CvAdminService {
	apiCvFeatureAdminEndpoint = 'cv-feature-admin';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getCvFeatures(): Observable<CvFeatureResponseDto[]> {
		return this.httpV2Service.getSecure(`${this.apiCvFeatureAdminEndpoint}/features`);
	}

	updateCvFeaturePrice(
		cvFeatureId: string,
		updateCvFeaturePriceRequestDto: UpdateCvFeaturePriceRequestDto,
	): Observable<CvFeatureResponseDto> {
		return this.httpV2Service.putSecure(
			`${this.apiCvFeatureAdminEndpoint}/${cvFeatureId}`,
			updateCvFeaturePriceRequestDto,
		);
	}
}
