import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { getSelectOptionName, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import FlinkerEducationalDetailsRequest from '@models/dto/requests/flinker-educational-details-request.dto';
import Qualification from '@models/dto/qualification.dto';
import { getErrorMessage } from '@app/utils/form.util';
import { FlinkerTakeOnStoreActions } from '@root-store/flinker-take-on-store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NotificationType } from '@app/enums/notification-type.enum';
import { entityConfig } from '@models/constants';

@Component({
	selector: 'app-skills',
	templateUrl: './skills.component.html',
	styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;
	submitEducationalDetailsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerEducationalDetailsHttpPackage,
	);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	getSelectOptionName = getSelectOptionName;
	skills: string[];
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	expectUpdate = false;

	maxOtherSkills = entityConfig.otherSkills.maxSkillLength;

	constructor(
		private store: Store<RootStoreState.State>,
		private formBuilder: FormBuilder,
		private notifierService: NotifierService,
	) {
		this.form = formBuilder.group({
			skills: [[]],
		});
	}
	ngOnInit(): void {
		this.subscriptions.add(
			this.flinker$.subscribe((flinker) => {
				this.flinker = flinker;
				this.skills = flinker.skills;
				this.form.patchValue({
					skills: flinker.skills,
				});
			}),
		);

		this.subscriptions.add(
			this.submitEducationalDetailsHttpPackage$.subscribe((submitEducationalDetailsHttpPackage) => {
				if (submitEducationalDetailsHttpPackage.result && this.expectUpdate) {
					this.expectUpdate = false;
					this.notifierService.notify(NotificationType.Success, 'Skills updated successfully');
				}
			}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				this.selectOptions = selectOptionsHttpPackage.result;
			}),
		);
	}
	submit(): void {
		this.expectUpdate = true;
		const flinkerEducationalDetailsRequest = this.mapToFlinkerEducationalDetailsRequest();
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerEducationalDetails({
				flinkerEducationalDetailsRequest,
			}),
		);
	}
	mapToFlinkerEducationalDetailsRequest(): FlinkerEducationalDetailsRequest {
		const qualifications: Qualification[] = this.flinker.qualifications;
		return {
			qualifications,
			skills: (this.skills || []).filter((skill) =>
				this.selectOptions?.skills.some((skillSelectOption) => skillSelectOption.code === skill),
			),
			otherSkills: (this.skills || []).filter(
				(skill) => !this.selectOptions?.skills.some((skillSelectOption) => skillSelectOption.code === skill),
			),
			unqualifiedReason: this.flinker.unqualifiedReason,
			noMatricCertificate: this.flinker.noMatricCertificate,
		};
	}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
