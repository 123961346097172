import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlinkAdminService } from '../../services/flink-admin/flink-admin.service';
import { ReferenceQuestionActions } from '.';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UpdateReferenceQuestion, CreateReferenceQuestion, DeleteReferenceQuestion } from './actions';
import ReferenceQuestion from '../../models/dto/responses/reference-question.dto';

@Injectable()
export class ReferenceQuestionsStoreEffects {
	constructor(private actions$: Actions, private flinkAdminService: FlinkAdminService) {}

	fetchAllReferenceQuestionsEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceQuestionActions.FetchAllReferenceQuestions>(
				ReferenceQuestionActions.ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS,
			),
			switchMap((_) =>
				this.flinkAdminService.getAllReferenceQuestions().pipe(
					map(
						(result: ReferenceQuestion[]) => new ReferenceQuestionActions.FetchAllReferenceQuestionsSuccess({ result }),
					),
					catchError((error: ErrorResponse) =>
						of(new ReferenceQuestionActions.FetchAllReferenceQuestionsError({ errorResponse: error })),
					),
				),
			),
		);
	});

	updateReferenceQuestionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceQuestionActions.UpdateReferenceQuestion>(
				ReferenceQuestionActions.ActionTypes.UPDATE_REFERENCE_QUESTION,
			),
			switchMap((action: UpdateReferenceQuestion) =>
				this.flinkAdminService.updateReferenceQuestion(action.payload).pipe(
					map((result: ReferenceQuestion) => new ReferenceQuestionActions.UpdateReferenceQuestionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new ReferenceQuestionActions.UpdateReferenceQuestionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	createReferenceQuestionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceQuestionActions.CreateReferenceQuestion>(
				ReferenceQuestionActions.ActionTypes.CREATE_REFERENCE_QUESTION,
			),
			switchMap((action: CreateReferenceQuestion) =>
				this.flinkAdminService.createReferenceQuestion(action.payload).pipe(
					map((result: ReferenceQuestion) => new ReferenceQuestionActions.CreateReferenceQuestionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new ReferenceQuestionActions.CreateReferenceQuestionError({ errorResponse: error })),
					),
				),
			),
		);
	});

	deleteReferenceQuestionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<ReferenceQuestionActions.DeleteReferenceQuestion>(
				ReferenceQuestionActions.ActionTypes.DELETE_REFERENCE_QUESTION,
			),
			switchMap((action: DeleteReferenceQuestion) =>
				this.flinkAdminService.deleteReferenceQuestion(action.payload.questionId).pipe(
					map((_) => new ReferenceQuestionActions.DeleteReferenceQuestionSuccess({ result: 'success' })),
					catchError((error: ErrorResponse) =>
						of(new ReferenceQuestionActions.DeleteReferenceQuestionError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
