import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChange,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SelectOptionResponse } from 'projects/flink-app/src/app/models/dto/responses/select-option-response.dto';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-chips-input',
	templateUrl: './chips-input.component.html',
	styleUrls: ['./chips-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ChipsInputComponent),
			multi: true,
		},
	],
})
export class ChipsInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
	codes: string[] = [];
	selectable = true;
	removable = true;
	addOnBlur = true;
	filteredChips: Observable<string[]>;
	optionsChanged: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	chipControl = new FormControl();
	readonly separatorKeysCodes = [ENTER, COMMA] as const;

	private _options: SelectOptionResponse[];
	@Input() set options(options: SelectOptionResponse[]) {
		this._options = options;
		this.optionsChanged.next();
	}

	get options(): SelectOptionResponse[] {
		return this._options;
	}

	@Input() values: string[] = [];
	@Input() labelText: string;
	@Input() prefill: string[] = [];
	@Input() theme = 'chip-input';
	@Input() formControlName = '';
	@Input() errorMessage = '';
	@Input() allowOther = false;
	@Input() hint = '';
	@Input() toolTip = '';
	@Input() showTip = false;
	@Input() maxOtherLength: number = null;

	@Output() chipsChange = new EventEmitter();

	required = false;
	localType = '';
	onChange: any = () => {};
	onTouched: any = () => {};

	@ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(private controlContainer: ControlContainer) {
		this.filteredChips = combineLatest([
			this.chipControl.valueChanges.pipe(startWith(null), takeUntil(this.destroy$)),
			this.optionsChanged,
		]).pipe(
			map(([chip, _]: [string, ...any]) => (chip ? this._filter(chip) : this.options?.map((option) => option.name))),
			takeUntil(this.destroy$),
		);
	}

	ngOnInit(): void {
		this.prefill?.forEach((code) => {
			this.values.push(this.options?.find((option) => option.code == code)?.name ?? code);
		});
		this.codes = this.prefill && this.prefill.length > 0 ? this.prefill : this.codes;

		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const options: SimpleChange = changes.options;
		if (options?.currentValue !== null && options?.previousValue === null) {
			this.codes?.forEach((code) => {
				this.values.push(this.options?.find((option) => option.code == code).name);
			});
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (this.values.indexOf(event.option.viewValue) > -1) {
			return;
		}

		this.values = [...this.values, event.option.viewValue];
		this.codes = [...this.codes, this.options.find((option) => option.name == event.option.viewValue)?.code];
		this.chipInput.nativeElement.value = '';
		this.chipsChange.emit(this.codes);
		this.chipControl.setValue(null);
		this.onChange(this.codes);
	}

	onManualInput(event: any) {
		if (this.allowOther && this.values.indexOf(event.target.value) < 0 && event.target.value != '') {
			this.values = [...this.values, event.target.value];
			this.codes = [...this.codes, event.target.value];
			this.chipInput.nativeElement.value = '';
			this.chipsChange.emit(this.codes);
			this.chipControl.setValue(null);
			this.onChange(this.codes);
		}
	}

	removeDirectly(item: string, code: string): void {
		const index = this.values.indexOf(item);
		this.codes.splice(this.codes.indexOf(code), 1);
		if (index >= 0) {
			this.values.splice(index, 1);
		}
	}

	remove(item: string): void {
		const index = this.values.indexOf(item);
		const code = this.options.find((option) => option.name == this.values[index])?.code ?? this.values[index];

		const codes = [...this.codes];
		codes.splice(this.codes.indexOf(code), 1);
		this.writeValue(codes);
		this.chipsChange.emit(this.codes);
		this.onChange(this.codes);
	}

	private _filter(value: string): string[] {
		return this.options
			.filter((option) => option.name.toLowerCase().includes(value.toLowerCase()))
			.map((option) => option.name);
	}

	public writeValue(value: string[]): void {
		this.codes = value;
		this.values = this.codes?.map((code) => this.options?.find((option) => option.code == code)?.name ?? code);
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur(): void {
		this.onTouched();
	}
}
