import { Actions, ActionTypes } from './actions';
import { FlinkCoAdminState, initialState } from './state';
import AdminMatchedFlinkerResponse from '@flinkadmin-dashboard/models/response/admin-matched-flinker-response.dto';

export function FlinkCoAdminReducer(state = initialState, action: Actions): FlinkCoAdminState {
	switch (action.type) {
		case ActionTypes.FETCH_ALL_FLINKCOS:
			return {
				...state,
				fetchAllFlinkCosHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_FLINKCOS_SUCCESS:
			return {
				...state,
				fetchAllFlinkCosHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ALL_FLINKCOS_ERROR:
			return {
				...state,
				fetchAllFlinkCosHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_QUOTES:
			return {
				...state,
				fetchAllQuotesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_QUOTES_SUCCESS:
			return {
				...state,
				fetchAllQuotesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ALL_QUOTES_ERROR:
			return {
				...state,
				fetchAllQuotesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_FLINKCO:
			return {
				...state,
				updateFlinkCoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_FLINKCO_SUCCESS:
			return {
				...state,
				updateFlinkCoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPDATE_FLINKCO_ERROR:
			return {
				...state,
				updateFlinkCoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CANCEL_RETAINER_SUBCRIPTION:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CANCEL_RETAINER_SUBCRIPTION_SUCCESS:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CANCEL_RETAINER_SUBCRIPTION_ERROR:
			return {
				...state,
				cancelRetainerSubscriptionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKCO:
			return {
				...state,
				deleteFlinkCoHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKCO_SUCCESS:
			return {
				...state,
				deleteFlinkCoHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DELETE_FLINKCO_ERROR:
			return {
				...state,
				deleteFlinkCoHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.LOGIN_AS_FLINKCO_ERROR:
			return {
				...state,
				loginAsFlinkCoPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.LOGIN_AS_FLINKCO_SUCCESS:
			return {
				...state,
				loginAsFlinkCoPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKCO_SEARCHES:
			return {
				...state,
				fetchFlinkCoSearchesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKCO_SEARCHES_SUCCESS:
			return {
				...state,
				fetchFlinkCoSearchesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKCO_SEARCHES_ERROR:
			return {
				...state,
				fetchFlinkCoSearchesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_SELECTED_FLINKCO:
			return {
				...state,
				getSelectedFlinkCoPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_SELECTED_FLINKCO_SUCCESS:
			return {
				...state,
				getSelectedFlinkCoPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_SELECTED_FLINKCO_ERROR:
			return {
				...state,
				getSelectedFlinkCoPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH_SUCCESS:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ACTIVE_SEARCH_ERROR:
			return {
				...state,
				fetchActiveSearchHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT:
			return {
				...state,
				getActiveSearchResultsPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT_SUCCESS:
			return {
				...state,
				getActiveSearchResultsPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_SEARCH_RESULT_ERROR:
			return {
				...state,
				getActiveSearchResultsPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.ADD_FLINKER_TO_SEARCH:
			return {
				...state,
				addFlinkerToSearchResultsPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.ADD_FLINKER_TO_SEARCH_SUCCESS:
			return {
				...state,

				addFlinkerToSearchResultsPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				getActiveSearchResultsPackage: {
					...state.getActiveSearchResultsPackage,
					result: {
						...state.getActiveSearchResultsPackage.result,
						totalMatchedFlinkers: state.getActiveSearchResultsPackage.result.totalMatchedFlinkers + 1,
						matchedFlinkers: [...state.getActiveSearchResultsPackage.result.matchedFlinkers, action.payload.result],
					},
				},
			};
		case ActionTypes.ADD_FLINKER_TO_SEARCH_ERROR:
			return {
				...state,
				addFlinkerToSearchResultsPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.REMOVE_FLINKER_FROM_SEARCH:
			return {
				...state,
				removeFlinkerFromSearchResultsPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.REMOVE_FLINKER_FROM_SEARCH_SUCCESS:
			return {
				...state,
				removeFlinkerFromSearchResultsPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				getActiveSearchResultsPackage: {
					...state.getActiveSearchResultsPackage,
					result: {
						...state.getActiveSearchResultsPackage.result,
						totalMatchedFlinkers: state.getActiveSearchResultsPackage.result.totalMatchedFlinkers - 1,
						matchedFlinkers: state.getActiveSearchResultsPackage.result.matchedFlinkers.filter(
							(matchedFlinker: AdminMatchedFlinkerResponse) => matchedFlinker.id != action.payload.result.id,
						),
					},
				},
			};
		case ActionTypes.REMOVE_FLINKER_FROM_SEARCH_ERROR:
			return {
				...state,
				removeFlinkerFromSearchResultsPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
