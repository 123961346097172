<h3 class="my-3 text-center" *ngIf="dataSource.data.length == 0">No upcoming interviews found</h3>
<table mat-table matSort [dataSource]="dataSource" class="cursor-pointer" *ngIf="dataSource.data.length > 0">
	<ng-container matColumnDef="company">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
		<td mat-cell *matCellDef="let interview">
			<div class="d-flex">
				<app-user-picture
          class="d-none d-md-block"
					theme="m-2"
					[size]="80"
					[src]="interview.flinkCo.logo"
					[alt]="interview.flinkCo.registeredName"
				>
				</app-user-picture>
				<div class="d-block pl-2 my-3">
					<div class="d-block front-sub-3 font-size-10 font-medium">
						{{ interview.flinkCo.registeredName }}
					</div>
					<app-highlighted-text theme="faded1" class="d-flex">
						{{
							getSelectOptionNameById(
								(selectOptionsHttpPackage$ | async)?.result?.industries,
								interview.flinkCo.industryId
							)
						}}
					</app-highlighted-text>
					<a class="d-block front-sub-5 font-size-12 pt-2" [href]="interview.flinkCo.website" target="blank">
						{{ interview.flinkCo.website }}
					</a>
				</div>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="date" class="d-none d-md-table-cell">
		<th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell" mat-sort-header>Date</th>
		<td mat-cell *matCellDef="let interview" class="d-none d-md-table-cell">
			<div class="d-flex">
				<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon">
					{{ interview.startDate ? (interview.startDate | dateFormat: 'DD/MM/YYYY') : '-' }}
				</span>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="time" class="d-none d-lg-table-cell">
		<th mat-header-cell *matHeaderCellDef class="d-none d-lg-table-cell" mat-sort-header>Time</th>
		<td mat-cell *matCellDef="let interview" class="d-none d-lg-table-cell">
			<div class="d-flex">
				<span class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon">
					{{ interview.startDate ? (interview.startDate | dateFormat: 'HH:mm') : '' }} -
					{{ interview.endDate ? (interview.endDate | dateFormat: 'HH:mm') : '' }}
				</span>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="location" class="d-none d-xl-table-cell">
		<th mat-header-cell *matHeaderCellDef class="d-none d-xl-table-cell">Location</th>
		<td mat-cell *matCellDef="let interview" class="d-none d-xl-table-cell">
			<div class="d-flex">
				<mat-icon class="front-main-1 font-size-10 center-icon" *ngIf="interview.location">near_me</mat-icon>
				<span
					class="front-sub-5 font-size-11 font-medium flinker-summary-list pl-1 center-icon"
					*ngIf="!interview.meetingUrl"
				>
					{{ interview.location ?? '-' }}
				</span>
				<a *ngIf="interview.meetingUrl" [href]="interview.meetingUrl" target="_blank">Online meeting</a>
			</div>
		</td>
	</ng-container>

	<ng-container matColumnDef="status">
		<th mat-header-cell *matHeaderCellDef>Status</th>
		<td mat-cell *matCellDef="let interview">
			<app-highlighted-text [theme]="getInterviewStatusColour(interview.status)">
				{{ interview?.status | interviewStatusName:true }}
				{{
					interview.status == InterviewStatus.Rejected
						? ' - ' +
						  getSelectOptionNameById(
								(selectOptionsHttpPackage$ | async).result?.interviewRejectionReasons,
								interview.rejectionReasonId
						  )
						: ''
				}}
			</app-highlighted-text>
		</td>
	</ng-container>

	<ng-container matColumnDef="actions" class="d-none d-md-table-cell">
		<th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell">Actions</th>
		<td mat-cell *matCellDef="let interview" class="d-none d-md-table-cell">
			<div class="d-flex flex-sm-wrap flex-sm-column flex-sm-action-column">
				<app-circle-icon-button
					class="mr-3"
					icon="edit_calendar"
					theme="m1"
					iconTheme="small"
					labelText="Request reschedule"
					(click)="requestReschedule(interview.id, $event)"
					*ngIf="
						interview.status !== InterviewStatus.VerificationFailed &&
						interview.status !== InterviewStatus.WaitingForFlinker
					"
				>
				</app-circle-icon-button>
				<app-circle-icon-button
					class="mr-3"
					icon="check"
					theme="m2"
					iconTheme="small"
					labelText="Accept"
					(click)="acceptInterview(interview.id, $event)"
					*ngIf="
						interview.status !== InterviewStatus.Accepted &&
						interview.status !== InterviewStatus.Confirmed &&
						interview.status !== InterviewStatus.VerificationPending &&
						interview.status !== InterviewStatus.VerificationFailed
					"
				>
				</app-circle-icon-button>
				<app-circle-icon-button
					icon="close"
					theme="m10"
					iconTheme="small"
					labelText="Reject"
					(click)="rejectInterview(interview.id, $event)"
					*ngIf="
						interview.status !== InterviewStatus.Rejected && interview.status !== InterviewStatus.VerificationFailed
					"
				>
				</app-circle-icon-button>
			</div>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['../interview/', row.id]"></tr>
</table>
