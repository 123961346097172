<div class="p-1 p-md-4">
	<app-dashboard-section-header icon="supervisor_account" title="Profile Completion"> </app-dashboard-section-header>
	<div class="row justify-content-center">
		<div class="d-flex w-100">
			<app-progress-gauge class="mx-0 m-sm-auto"></app-progress-gauge>
		</div>
		<div class="row w-100 my-3">
			<div
				class="col-12 d-flex justify-content-center"
				*ngIf="
					!(psychAssessmentCompletionStatusHttpPackage$ | async)?.result?.completionStatus &&
					(psychAssessmentCompletionStatusHttpPackage$ | async)?.result?.bookingUrl
				"
			>
				<a
					[href]="(psychAssessmentCompletionStatusHttpPackage$ | async)?.result?.bookingUrl"
					target="_blank"
					class="w-100"
				>
					<app-square-button matStepperNext theme="m1" value="Psychometric Test" type="button" class="d-flex mw-350">
					</app-square-button>
				</a>
			</div>
		</div>
	</div>
</div>
