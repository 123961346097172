import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { getSelectOptionIdByCode, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { Subject } from 'rxjs';
import { DictionaryWords } from '@app/enums/dictionary-words.enum';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { FilterJobAdsRequestDto } from '@models/dto/requests/filter-job-ads-request.dto';
import { FlinkerJobsAction, FlinkerJobsSelectors } from '@root-store/flinker/jobs';
import { listAnimation } from '@shared/helpers/angular-animations.helpers';
import { PageEvent } from '@angular/material/paginator';
import { filter, takeUntil } from 'rxjs/operators';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';

@Component({
	selector: 'app-view-flinker-job-adverts',
	templateUrl: './view-flinker-job-adverts.component.html',
	styleUrls: ['./view-flinker-job-adverts.component.scss'],
	animations: [listAnimation],
})
export class ViewFlinkerJobAdvertsComponent implements OnInit, OnDestroy {
	private readonly destroy$: Subject<void> = new Subject<void>();

	form: FormGroup;
	currentIndex = 0;
	visible = false;
	salaryBandLower = 0;
	salaryBandUpper = 300000;
	getErrorMessage = getErrorMessage;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage).pipe(
		takeUntil(this.destroy$),
		filter((response) => !response.loading && response.result != null),
	);
	selectOptions: SelectOptionsResponse;
	dictionaryWords = DictionaryWords;
	shouldValidate = false;
	filterJobAdvertsRequest: FilterJobAdsRequestDto;

	getFilteredJobAdvertsHttpPackage$ = this.store
		.select(FlinkerJobsSelectors.selectGetFilteredJobAdverts)
		.pipe(takeUntil(this.destroy$));
	jobAdverts: JobAdvertResponseDto[];

	numberOfJobAdverts: number;
	pageNumber = 0;
	pageSize = 20;

	constructor(private formBuilder: FormBuilder, private store: Store<RootStoreState.State>) {
		this.form = this.formBuilder.group({
			cities: [[]],
			jobTitle: [''],
			industry: [''],
			jobCategory: [''],
			placement: [''],
			workspacePreference: [''],
			salaryBracket: ['0,300000'],
		});
	}

	ngOnInit(): void {
		this.fetchFilteredJobAdverts();
		this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
			this.selectOptions = selectOptionsHttpPackage.result;
		});

		this.getFilteredJobAdvertsHttpPackage$
			.pipe(filter((response) => !response.loading && response.result != null))
			.subscribe((getFilteredJobAdvertsHttpPackage) => {
				this.jobAdverts = getFilteredJobAdvertsHttpPackage.result.results;
				this.numberOfJobAdverts = getFilteredJobAdvertsHttpPackage.result.total;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	onSelectTab(i: number) {
		this.currentIndex = i;
		this.fetchFilteredJobAdverts();
	}

	fetchFilteredJobAdverts() {
		let cities: string[] = [];
		const formValues = this.form.value;

		formValues.cities.forEach((c: any) => {
			cities = cities.concat(getSelectOptionIdByCode(this.selectOptions?.cities, c));
		});

		this.filterJobAdvertsRequest = {
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
			jobCategory: getSelectOptionIdByCode(this.selectOptions?.jobCategories, formValues.jobCategory) || null,
			jobTitleId: getSelectOptionIdByCode(this.selectOptions?.jobTitles, formValues.jobTitle) || null,
			locationIds: cities.filter((v) => v),
			industry: getSelectOptionIdByCode(this.selectOptions?.industries, formValues.industry) || null,
			placement: getSelectOptionIdByCode(this.selectOptions?.placements, formValues.placement) || null,
			workspacePreference:
				getSelectOptionIdByCode(this.selectOptions?.workspaces, formValues.workspacePreference) || null,
			salaryBandLower: formValues.salaryBracket?.split(',')[0],
			salaryBandUpper: formValues.salaryBracket?.split(',')[1],
			onlyForMe: this.currentIndex === 1,
		};

		this.store.dispatch(
			new FlinkerJobsAction.GetFilteredJobAdverts({ filteredJobAdvertsDto: this.filterJobAdvertsRequest }),
		);
	}

	clearFilter() {
		this.pageNumber = 0;
		this.filterJobAdvertsRequest = {
			pageSize: this.pageSize,
			pageNumber: this.pageNumber,
			jobCategory: null,
			jobTitleId: null,
			locationIds: null,
			industry: null,
			placement: null,
			workspacePreference: null,
			salaryBandLower: '0',
			salaryBandUpper: '300000',
			onlyForMe: this.currentIndex === 1,
		};

		this.store.dispatch(
			new FlinkerJobsAction.GetFilteredJobAdverts({ filteredJobAdvertsDto: this.filterJobAdvertsRequest }),
		);

		this.form.reset({
			cities: [],
			jobTitle: '',
			industry: '',
			jobCategory: '',
			placement: '',
			workspacePreference: '',
			salaryBracket: '0,300000',
		});
	}

	onPaginationEvent(pageEvent: PageEvent): void {
		this.pageSize = pageEvent.pageSize;
		this.pageNumber = pageEvent.pageIndex;
		this.fetchFilteredJobAdverts();
	}
}
