import { Action } from '@ngrx/store';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';
import UpdateCvFeaturePriceRequestDto from '@models/dto/requests/update-cv-feature-price-request.dto';

export enum ActionTypes {
	GET_CV_FEATURES = '[CV Admin] Get CV features',
	GET_CV_FEATURES_SUCCESS = '[CV Admin] Get CV features success',
	GET_CV_FEATURES_ERROR = '[CV Admin] Get CV features error',

	UPDATE_CV_FEATURE_PRICE = '[CV Admin] Update CV feature price',
	UPDATE_CV_FEATURE_PRICE_SUCCESS = '[CV Admin] Update CV feature price success',
	UPDATE_CV_FEATURE_PRICE_ERROR = '[CV Admin] Update CV feature price error',
}

export class GetCvFeatures implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES;
	constructor() {}
}
export class GetCvFeaturesSuccess implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES_SUCCESS;
	constructor(public payload: { result: CvFeatureResponseDto[] }) {}
}
export class GetCvFeaturesError implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateCvFeaturePrice implements Action {
	readonly type = ActionTypes.UPDATE_CV_FEATURE_PRICE;
	constructor(public payload: UpdateCvFeaturePriceRequestDto) {}
}
export class UpdateCvFeaturePriceSuccess implements Action {
	readonly type = ActionTypes.UPDATE_CV_FEATURE_PRICE_SUCCESS;
	constructor(public payload: { result: CvFeatureResponseDto }) {}
}

export class UpdateCvFeaturePriceError implements Action {
	readonly type = ActionTypes.UPDATE_CV_FEATURE_PRICE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| GetCvFeatures
	| GetCvFeaturesSuccess
	| GetCvFeaturesError
	| UpdateCvFeaturePrice
	| UpdateCvFeaturePriceSuccess
	| UpdateCvFeaturePriceError;
