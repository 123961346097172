import { Actions, ActionTypes } from './actions';
import { FlinkerAdminState, initialState } from './state';

export function FlinkerAdminReducer(state = initialState, action: Actions): FlinkerAdminState {
	switch (action.type) {
		case ActionTypes.FETCH_ALL_FLINKERS:
			return {
				...state,
				fetchAllFlinkersHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_FLINKERS_SUCCESS:
			return {
				...state,
				fetchAllFlinkersHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ALL_FLINKERS_ERROR:
			return {
				...state,
				fetchAllFlinkersHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_FLINKER:
			return {
				...state,
				updateFlinkerHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_FLINKER_SUCCESS:
			return {
				...state,
				updateFlinkerHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPDATE_FLINKER_ERROR:
			return {
				...state,
				updateFlinkerHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS:
			return {
				...state,
				fetchAllReactivationRequestsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS_SUCCESS:
			return {
				...state,
				fetchAllReactivationRequestsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS_ERROR:
			return {
				...state,
				fetchAllReactivationRequestsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS:
			return {
				...state,
				updateReactivationRequestStatusHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS_SUCCESS:
			return {
				...state,
				updateReactivationRequestStatusHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS_ERROR:
			return {
				...state,
				updateReactivationRequestStatusHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_DETAILED_FLINKER:
			return {
				...state,
				getDetailedFlinkerPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_DETAILED_FLINKER_SUCCESS:
			return {
				...state,
				getDetailedFlinkerPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_DETAILED_FLINKER_ERROR:
			return {
				...state,
				getDetailedFlinkerPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKER_REFERENCE_SCORE:
			return {
				...state,
				fetchFlinkerReferenceScorePackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKER_REFERENCE_SCORE_SUCCESS:
			return {
				...state,
				fetchFlinkerReferenceScorePackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKER_REFERENCE_SCORE_ERROR:
			return {
				...state,
				fetchFlinkerReferenceScorePackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.LOGIN_AS_FLINKER_ERROR:
			return {
				...state,
				loginAsFlinkerPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.LOGIN_AS_FLINKER_SUCCESS:
			return {
				...state,
				loginAsFlinkerPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKER_DOCUMENTS:
			return {
				...state,
				fetchFlinkerDocumentsPackage: {
					error: null,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.FETCH_FLINKER_DOCUMENTS_SUCCESS:
			return {
				...state,
				fetchFlinkerDocumentsPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_FLINKER_DOCUMENTS_ERROR:
			return {
				...state,
				fetchFlinkerDocumentsPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKER:
			return {
				...state,
				deleteFlinkerPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DELETE_FLINKER_SUCCESS:
			return {
				...state,
				deleteFlinkerPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DELETE_FLINKER_ERROR:
			return {
				...state,
				deleteFlinkerPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.DOWNLOAD_FLINKER_CV:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_SUCCESS:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_ERROR:
			return {
				...state,
				downloadFlinkerCv: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_FLINKER_CV_CLEAR_STATE:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV:
			return {
				...state,
				downloadObfuscatedFlinkerCv: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_SUCCESS:
			return {
				...state,
				downloadObfuscatedFlinkerCv: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_ERROR:
			return {
				...state,
				downloadObfuscatedFlinkerCv: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_CLEAR_STATE:
			return {
				...state,
				downloadObfuscatedFlinkerCv: {
					error: null,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.DOWNLOAD_ADMIN_REPORT:
			return {
				...state,
				downloadAdminReport: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_ADMIN_REPORT_SUCCESS:
			return {
				...state,
				downloadAdminReport: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_ADMIN_REPORT_ERROR:
			return {
				...state,
				downloadAdminReport: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};

		case ActionTypes.DOWNLOAD_ADMIN_REPORT_CLEAR_STATE:
			return {
				...state,
				downloadAdminReport: {
					error: null,
					loading: false,
					result: null,
				},
			};

		default: {
			return state;
		}
	}
}
