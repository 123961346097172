import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { getSelectOptionById } from '@models/dto/responses/select-options-response.dto';

@Pipe({
	name: 'selectOptionNameById',
})
export class SelectOptionNameByIdPipe implements PipeTransform {
	transform(
		optionId: string,
		options: SelectOptionResponse[],
		otherText = '',
		allowOther = true,
		defaultValue = '',
	): string {
		const optionName = getSelectOptionById(options, optionId)?.name || defaultValue || '';
		return optionName.toLowerCase() === 'other' && allowOther ? otherText : optionName;
	}
}
