import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
	production: false,
	apiVersion: 'v2',
	apiBaseUrl: 'https://api-flink-node-dev.flink-app.com/api',
	googleClientId: '',
	microsoftClientId: '',
	baseUrl: 'https://dev.flink-app.com',
	googleTagManagerId: 'GTM-MP8WTBM',
	sentry: {
		dsn: 'https://3759ed3393d24decb4204f667eb74333@o1156987.ingest.sentry.io/6238814',
		tracingOrigins: ['https://app.flink-app.com'],
		enabled: true,
	},
	loggingLevel: NgxLoggerLevel.DEBUG,
	pagination: {
		pageSize: 50,
	},

	frontendStaticSiteUrl: 'https://flink-app.com/',
	getJobAdvertUrl: (jobAdvertId: string) => environment.frontendStaticSiteUrl + 'job?job_id=' + jobAdvertId,
};
