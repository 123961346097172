import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptionResponse } from '../../flink-app/src/app/models/dto/responses/select-option-response.dto';

@Pipe({
	name: 'sortSelectOptions',
})
export class SortSelectOptionsPipe implements PipeTransform {
	transform(value: SelectOptionResponse[]): SelectOptionResponse[] {
		if (!value || (value && value.length === 0)) {
			return value;
		}
		const otherOptionIndex: number = value.findIndex(
			(option: SelectOptionResponse) => option.name.toLowerCase() === 'other',
		);
		const optionsToReturn: SelectOptionResponse[] = value.slice();
		optionsToReturn.splice(otherOptionIndex, 1);
		optionsToReturn.sort((optionA: SelectOptionResponse, optionB: SelectOptionResponse) =>
			optionA.name < optionB.name ? -1 : 1,
		);
		if (otherOptionIndex !== -1) {
			optionsToReturn.push(value[otherOptionIndex]);
		}
		return optionsToReturn;
	}
}
