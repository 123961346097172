<div class='p-1 p-md-4'>
  <app-dashboard-section-header icon='supervisor_account' title='Job Status'></app-dashboard-section-header>
  <div>
    <div class='row pt-3'>
      <div class='col-12 d-sm-flex d-block px-0'>
        <form class='container-fluid' [formGroup]='form'>
          <div class='row'>
            <app-outlined-dropdown
              class='col-12'
              labelText='Seeking status'
              formControlName='availabilityStatus'
              [options]='(selectOptionsHttpPackage$ | async)?.result?.availabilityStatuses'
              [errorMessage]="getErrorMessage(form.controls.availabilityStatus, 'Seeking status', shouldValidate)"
            >
            </app-outlined-dropdown>

            <app-outlined-dropdown
              class='col-12'
              labelText='Visibility status'
              formControlName='visibilityStatus'
              bottomText='This controls your visibility to recruiters'
              [options]='(selectOptionsHttpPackage$ | async)?.result?.profileVisibilities'
              [errorMessage]="getErrorMessage(form.controls.visibilityStatus, 'Visibility status', shouldValidate)"
            >
            </app-outlined-dropdown>

            <div class='col-12 pt-4'>
              <app-square-button
                matStepperNext
                theme='m1'
                value='Update'
                type='button'
                class='d-flex justify-content-center'
                style='max-width: 350px'
                [disabled]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                [loading]='(flinkerPersonalDetailsHttpPackage$ | async)?.loading'
                (click)='submit()'
              >
              </app-square-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
