import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-duties-description',
	templateUrl: './duties-description.component.html',
	styleUrls: ['./duties-description.component.scss'],
})
export class DutiesDescriptionComponent implements OnInit {
	@Input() jobDutiesDescription: any;
	showFullText = false;

	constructor() {}

	ngOnInit(): void {}
	showText() {
		this.showFullText = !this.showFullText;
	}
}
