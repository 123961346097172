<mat-form-field class="outlined-dropdown-input">
	<mat-select
		class="ghost-input"
		[disabled]="disabled"
		(selectionChange)="input($event)"
		(blur)="onBlur()"
		[value]="value"
	>
		<mat-option *ngFor="let selectOption of options" [value]="selectOption.code">
			{{ selectOption.name }}
		</mat-option>
	</mat-select>
	<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
