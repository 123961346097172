import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QuestionType } from '@app/enums/question-type.enum';
import { maxQuestionTextLength } from '@models/constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericOption } from '@models/generic-option.model';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { getErrorMessage } from '@app/utils/form.util';
import { TitleCasePipe } from '@shared/pipes/title-case.pipe';

@Component({
	selector: 'app-search-question-list',
	templateUrl: './search-question-list.component.html',
	styleUrls: ['./search-question-list.component.scss'],
})
export class SearchQuestionListComponent implements OnInit, OnDestroy {
	@Input() labelText = '';
	@Input() formGroups: FormGroup[] = [];
	@Input() shouldValidate = false;
	@Input() theme = '';
	@Input() errorMessage = '';
	@Input() hint = '';
	@Output() onChange = new EventEmitter<FormGroup[]>();

	getErrorMessage = getErrorMessage;
	maxQuestionTextLength = maxQuestionTextLength.toString();
	titleCasePipe = new TitleCasePipe();
	questionTypeOptions: GenericOption[] = Object.values(QuestionType).map((questionType: string) => ({
		displayName: this.titleCasePipe.transform(questionType.replace(new RegExp('_', 'g'), ' ').toLowerCase()),
		value: questionType,
	}));
	private readonly destroy$ = new Subject<void>();
	constructor() {}
	ngOnInit(): void {}
	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.formGroups, event.previousIndex, event.currentIndex);
		this.onChange.emit(this.formGroups);
	}
	remove(index: number): void {
		this.formGroups.splice(index, 1);
	}
}
