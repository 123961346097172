import FlinkerResponse from '../../models/dto/responses/flinker-response.dto';
import ProfileCompletionItem from '../../models/dto/responses/profile-completion-item.dto';
import ReactivationRequestResponse from '../../models/dto/responses/reactivation-request-response.dto';
import HttpPackage from '../../models/http-package';
import { FlinkCoNamesResponse } from '@models/dto/responses/flinkCo-names-response.dto';

export interface FlinkerTakeOnState {
	flinker: FlinkerResponse;
	flinkerHttpPackage: HttpPackage<FlinkerResponse>;
	flinkerPersonalDetailsHttpPackage: HttpPackage<FlinkerResponse>;
	flinkerEmploymentDetailsHttpPackage: HttpPackage<FlinkerResponse>;
	flinkerEducationalDetailsHttpPackage: HttpPackage<FlinkerResponse>;
	flinkerCriminalDeclarationHttpPackage: HttpPackage<FlinkerResponse>;
	flinkerTermsAndConditionsHttpPackage: HttpPackage<FlinkerResponse>;
	uploadIdDocumentHttpPackage: HttpPackage<FlinkerResponse>;
	uploadPassportDocumentHttpPackage: HttpPackage<FlinkerResponse>;
	uploadEmploymentDocumentsHttpPackage: HttpPackage<FlinkerResponse>;
	uploadQualificationDocumentsHttpPackage: HttpPackage<FlinkerResponse>;
	uploadProfilePictureHttpPackage: HttpPackage<string>;
	profileCompletionHttpPackage: HttpPackage<ProfileCompletionItem[]>;
	createReactivationRequestHttpPackage: HttpPackage<string>;
	getReactivationRequestHttpPackage: HttpPackage<ReactivationRequestResponse>;
	getFlinkCosListHttpPackage: HttpPackage<FlinkCoNamesResponse[]>;
	setFlinkerCVPromotionDateHttpPackage: HttpPackage<FlinkerResponse>;
}

export const initialState: FlinkerTakeOnState = {
	flinker: {
		id: null,
		name: null,
		surname: null,
		idNumber: null,
		passportNumber: null,
		dateOfBirth: null,
		disabled: null,
		driversLicense: null,
		cellphoneNumber: null,
		emailAddress: null,
		willingToRelocate: null,
		willingToRelocateAbroad: null,
		nextJobRequirement: null,
		picture: null,
		criminalCheckOptIn: null,
		industryChecksOptIn: null,
		southAfricanCitizen: null,
		salaryBandLower: null,
		salaryBandUpper: null,
		currentSalary: null,
		gender: null,
		race: null,
		languageProficiency1: null,
		languageProficiency2: null,
		methodOfContact: null,
		professionalBody1: null,
		professionalBody2: null,
		profileStatus: null,
		availabilityStatus: null,
		visibilityStatus: null,
		placement: null,
		workspacePreference: null,
		idealWorkLocations: null,
		noticePeriod: null,
		additionalLeaveBenefits: null,
		takeOnComplete: null,
		currentEmployer: null,
		previousEmployers: [],
		qualifications: [],
		skills: null,
		marketingMethod: null,
		ownsVehicle: null,
		hasTakenCovidVaccine: null,
		unemployed: null,
		psychAssessmentResultId: null,
		potentialJobApplication: null,
	},
	flinkerHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkerPersonalDetailsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkerEmploymentDetailsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkerEducationalDetailsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkerCriminalDeclarationHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	flinkerTermsAndConditionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadIdDocumentHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadPassportDocumentHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadEmploymentDocumentsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadQualificationDocumentsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	uploadProfilePictureHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	profileCompletionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	createReactivationRequestHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getReactivationRequestHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkCosListHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	setFlinkerCVPromotionDateHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
