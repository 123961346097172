import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { AdminSelectOptionsResponse, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import HttpPackage from '../../models/http-package';

export interface SelectOptionsState {
	selectOptionsHttpPackage: HttpPackage<SelectOptionsResponse>;
	adminSelectOptionsHttpPackage: HttpPackage<AdminSelectOptionsResponse[]>;
	updateSelectOptionHttpPackage: HttpPackage<SelectOptionResponse>;
	createSelectOptionHttpPackage: HttpPackage<SelectOptionResponse>;
}

export const initialState: SelectOptionsState = {
	selectOptionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	adminSelectOptionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateSelectOptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	createSelectOptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
