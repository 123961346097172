import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import SelectOptionRequest from '../../flinkadmin-dashboard/models/requests/select-option-request.dto';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { AdminSelectOptionsResponse, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class SelectOptionsService {
	apiVersion = environment.apiVersion;
	apiSelectOptionsEndpoint = 'selectOptions';
	apiSelectOptionsAdminEndpoint = 'Admin/SelectOptionsAdmin';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	fetchSelectOptions() {
		return this.httpV2Service.getSecure<SelectOptionsResponse>(`${this.apiSelectOptionsEndpoint}`);
	}

	fetchAdminSelectOptions() {
		return this.httpV2Service.getSecure<AdminSelectOptionsResponse[]>(`${this.apiSelectOptionsAdminEndpoint}`);
	}

	updateSelectOption(selectOption: SelectOptionRequest) {
		return this.httpV2Service.putSecure<SelectOptionResponse>(`${this.apiSelectOptionsAdminEndpoint}`, selectOption);
	}

	createSelectOptions(selectOption: SelectOptionRequest) {
		return this.httpV2Service.postSecure<SelectOptionResponse>(`${this.apiSelectOptionsAdminEndpoint}`, selectOption);
	}
}
