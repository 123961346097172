import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap } from 'rxjs/operators';
import { ValidationService } from '@app/services/validation/validation.service';
import { isNil } from '@app/utils/functions.util';
import { getSelectOptionByCode, SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import HttpPackage from '@models/http-package';
import { RegexService } from '@shared/services/regex.service';

export const passwordConfirmValidator = (controlName: string, matchingControlName: string) => {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if (matchingControl.errors && !matchingControl.errors.mustMatch) {
			// return if another validator has already found an error on the matchingControl
			return;
		}

		// set error on matchingControl if validation fails
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ custom: 'Your passwords do not match', mustMatch: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
};

export const referencesEmailValidator = (control: FormControl): ValidationErrors | null => {
	let email1 = control?.root?.get('referenceEmail1')?.value;
	let email2 = control?.root?.get('referenceEmail2')?.value;
	let email3 = control?.root?.get('referenceEmail3')?.value;

	email1 = isNil(email1) ? '' : email1;
	email2 = isNil(email2) ? '' : email2;
	email3 = isNil(email3) ? '' : email3;

	if (
		(email1 === email2 && email1 !== '') ||
		(email1 === email3 && email1 !== '') ||
		(email2 === email3 && email2 !== '')
	) {
		return { custom: 'Your references cannot have the same email ' };
	} else {
		return null;
	}
};

export const referencesContactNumberValidator = () => {
	return (control: FormControl) => {
		const phone1 = control?.root?.get('referenceContactNumber1')?.value;
		const phone2 = control?.root?.get('referenceContactNumber2')?.value;
		const phone3 = control?.root?.get('referenceContactNumber3')?.value;
		if (
			(phone1 === phone2 && phone1 !== '') ||
			(phone1 === phone3 && phone1 !== '') ||
			(phone2 === phone3 && phone2 !== '')
		) {
			return { custom: 'Your references cannot have the same contact numbers ' };
		} else {
			return null;
		}
	};
};

export const contactNumberValidator = () => {
	const internationalPhoneNumberRegex = new RegExp(
		/(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
	);
	const localCellPhoneNumberRegex = new RegExp('^(0|\\+27)[0-9]{9}$');
	return (control: AbstractControl) => {
		if (!internationalPhoneNumberRegex.test(control.value) && !localCellPhoneNumberRegex.test(control.value)) {
			return { custom: 'Invalid contact number' };
		} else return null;
	};
};

export const percentageValidator = () => {
	return (control: FormControl) => {
		const blueCollar = parseInt(control?.parent?.get('blueCollarPercentage')?.value);
		const whiteCollar = parseInt(control?.parent?.get('whiteCollarPercentage')?.value);

		const combinedPercentage = blueCollar + whiteCollar;

		if (combinedPercentage !== 100) return { custom: 'Percentage should add up to 100' };
		else return null;
	};
};

export const dateOfBirthValidator = () => {
	return (control: FormControl) => {
		const dateOfBirth = moment(control?.parent?.get('dateOfBirth')?.value);
		if (dateOfBirth.isAfter(moment.now())) {
			return { custom: 'Date of birth cannot be a future date' };
		} else if (moment().diff(dateOfBirth, 'years') < 18) {
			return { custom: 'Flinker must be 18 years or older' };
		} else {
			return null;
		}
	};
};

export const provinceHasCityValidator = (
	selectOptionsResponse: Observable<HttpPackage<SelectOptionsResponse>>,
	provinceControlName = 'idealWorkProvince',
	citiesControlName = 'idealWorkCities',
) => {
	return (control: FormControl) => {
		let selectOptions: SelectOptionsResponse = undefined;
		selectOptionsResponse.subscribe((value) => (selectOptions = value.result));
		const provinces: string[] = control?.root?.get(provinceControlName)?.value;
		const cities: string[] = control?.root?.get(citiesControlName)?.value;

		const provincesThatAreThere: string[] = [];
		if (!isNil(cities) && !isNil(provinces)) {
			cities.forEach((city) => {
				const cityCode = getSelectOptionByCode(selectOptions.cities, city);
				if (provincesThatAreThere.indexOf(cityCode.parentSelectOption.code) === -1) {
					provincesThatAreThere.push(cityCode.parentSelectOption.code);
				}
			});
			const diff = provinces.filter((province) => !provincesThatAreThere.includes(province));
			const provincesNotIncluded: string[] = [];
			diff.forEach((province) => {
				provincesNotIncluded.push(getSelectOptionByCode(selectOptions.provinces, province).name);
			});
			if (!isNil(provincesNotIncluded) && provincesNotIncluded.length > 0) {
				return { custom: `You have not entered a city for province(s): ${provincesNotIncluded.join(', ')}` };
			}
		}
		return null;
	};
};

export const maxCTCBandValidator = (salaryBandLower = 'salaryBandLower', salaryBandUpper = 'salaryBandUpper') => {
	return (control: FormControl) => {
		const lowerSalary = control?.parent?.get(salaryBandLower)?.value;
		const upperSalary = control?.parent?.get(salaryBandUpper)?.value;

		if (isNil(lowerSalary) || isNil(upperSalary)) {
			return null;
		}

		if (+upperSalary - +lowerSalary > 20000 || +upperSalary - +lowerSalary < 0) {
			return { custom: 'Min and max salary difference cannot exceed R20 000.00.' };
		} else {
			return null;
		}
	};
};

export const startDatevalidator = (startDateControl: string) => {
	return (control: FormControl) => {
		const startDate = moment(control?.parent?.get(startDateControl)?.value);

		if (startDate.isAfter(moment.now())) {
			return { custom: 'Start date cannot be a future date' };
		} else {
			return null;
		}
	};
};

export const positiveValueValidator = (numberControl: string) => {
	return (control: FormControl) => {
		const value = control?.parent?.get(numberControl)?.value;

		if (value <= 0) {
			return { custom: 'Value must be positive' };
		} else {
			return null;
		}
	};
};

export const endDatevalidator = (startDateControl: string, endDateControl: string) => {
	return (control: FormControl) => {
		const startDate = moment(control?.parent?.get(startDateControl)?.value);
		const endDate = moment(control?.parent?.get(endDateControl)?.value);

		if (endDate.isAfter(moment.now())) {
			return { custom: 'End date cannot be a future date' };
		} else if (endDate.isBefore(startDate)) {
			return { custom: 'End date cannot be before start date' };
		} else {
			return null;
		}
	};
};

export const totalPlacementValidator = () => {
	return (control: FormControl) => {
		const blueCollarPlacements = parseInt(control?.parent?.get('numberOfWhiteCollarPlacements')?.value);
		const whiteCollarPlacements = parseInt(control?.parent?.get('numberOfBlueCollarPlacements')?.value);
		const totalPlacements = parseInt(control?.parent?.get('numberOfPlacements')?.value);

		if (blueCollarPlacements + whiteCollarPlacements !== totalPlacements)
			return { custom: 'Total of blue and white collar placements should add up to total placements specified' };
		else return null;
	};
};

export const futureDateOffsetValidator = (offset: number, unit: moment.unitOfTime.DurationConstructor = 'days') => {
	return (control: FormControl) => {
		if (moment(control.value) <= moment().add(unit, offset)) {
			return { custom: `Please select a date that is atleast ${offset} ${unit} in the future` };
		} else return null;
	};
};

export const dateAfterValidator = (date: moment.Moment) => {
	return (control: AbstractControl) => {
		if (moment(control.value) <= date) {
			return { custom: 'The end date cannot be before the start date' };
		} else return null;
	};
};

export const idNumberValidatorAsync = (validationService: ValidationService) => (control: AbstractControl) => {
	return of(control.value).pipe(
		filter((value) => !isNil(value) && value.toString().trim() != ''),
		delay(500),
		switchMap((idNumber) =>
			validationService.validateIdNumber({ value: idNumber }).pipe(
				map((response) => (response.valid ? null : { custom: response.errorMessage })),
				catchError((_) => of({ custom: 'A validation error has occurred' })),
			),
		),
	);
};

export const passportNumberValidatorAsync = (validationService: ValidationService) => (control: AbstractControl) => {
	return of(control.value).pipe(
		filter((value) => !isNil(value) && value.toString().trim() != ''),
		delay(500),
		switchMap((passportNumber) =>
			validationService.validatePassportNumber({ value: passportNumber }).pipe(
				map((response) => (response.valid ? null : { custom: response.errorMessage })),
				catchError((_) => of({ custom: 'A validation error has occurred' })),
			),
		),
	);
};

export const companyRegistrationNumberValidatorAsync =
	(validationService: ValidationService) => (control: FormControl) => {
		return of(control.value).pipe(
			filter((value) => !isNil(value) && value.toString().trim() != ''),
			delay(500),
			switchMap((registrationNumber) =>
				validationService.validateCompanyRegistrationNumber({ value: registrationNumber }).pipe(
					map((response) => (response.valid ? null : { custom: response.errorMessage })),
					catchError((_) => of({ custom: 'A validation error has occurred' })),
				),
			),
		);
	};

export const vatNumberValidatorAsync = (validationService: ValidationService) => (control: FormControl) => {
	return of(control.value).pipe(
		filter((value) => !isNil(value) && value.toString().trim() != ''),
		delay(500),
		switchMap((vatNumber) =>
			validationService.validateVatNumber({ value: vatNumber }).pipe(
				map((response) => (response.valid ? null : { custom: response.errorMessage })),
				catchError((_) => of({ custom: 'A validation error has occurred' })),
			),
		),
	);
};

export const referencesEmailValidatorAsync = (validationService: ValidationService) => (control: AbstractControl) => {
	if (
		isNil(control.value) ||
		control.value.toString().trim() == '' ||
		!RegexService.validEmailAddress().test(control.value)
	) {
		return of(null);
	}

	return of(control.value).pipe(
		delay(2000),
		switchMap((email) =>
			validationService.validateEmailAddress({ value: email }).pipe(
				map((response) => (response.valid ? null : { custom: response.errorMessage })),
				catchError((_) => of({ custom: 'A validation error has occurred' })),
			),
		),
	);
};
