interface NameAndSurname {
	name: string;
	surname: string;
}

export const flinkerNameAndSurnameFormatterHelper = (name: string, surname: string): NameAndSurname => {
	const wordsInName: string[] = name.trim().split(' ');

	if (wordsInName.length === 1) {
		return {
			name: name.trim(),
			surname: surname.trim(),
		};
	}

	if (name.trim().toLowerCase().includes(surname.trim().toLowerCase())) {
		const allWordsExceptFirst: string = wordsInName.slice(1).join(' ');
		if (
			allWordsExceptFirst.trim().toLowerCase().includes(surname.trim().toLowerCase()) &&
			allWordsExceptFirst.trim().length === surname.trim().length
		) {
			return {
				name: wordsInName[0],
				surname: surname.trim(),
			};
		}
	}

	return {
		name: name.trim(),
		surname: surname.trim(),
	};
};
