import { AdHocSubscriptionResponse } from '../../models/dto/responses/adhoc-subscription-response.dto';
import SearchFlinkerResponse from '../../models/dto/responses/search-flinker-response.dto';
import SearchResponse from '../../models/dto/responses/search-response.dto';
import SearchResultResponse from '../../models/dto/responses/search-result-response.dto';
import HttpPackage from '../../models/http-package';
import { FilterSearchResponse } from '@models/dto/responses/filter-search-response.dto';

export interface SearchState {
	createSearchHttpPackage: HttpPackage<SearchResponse>;
	updateSearchHttpPackage: HttpPackage<SearchResponse>;
	fetchActiveSearchesHttpPackage: HttpPackage<SearchResponse[]>;
	fetchActiveFilteredSearchesHttpPackage: HttpPackage<FilterSearchResponse>;
	fetchActiveSearchHttpPackage: HttpPackage<SearchResponse>;
	getSearchResultHttpPackage: HttpPackage<SearchResultResponse>;
	getFlinkerMatchHttpPackage: HttpPackage<SearchFlinkerResponse>;
	pinFlinkerMatchHttpPackage: HttpPackage<SearchFlinkerResponse>;
	getAdhocSubscriptionHttpPackage: HttpPackage<AdHocSubscriptionResponse>;
	getShortlistHttpPackage: HttpPackage<SearchFlinkerResponse>;
	getThumbsUpHttpPackage: HttpPackage<SearchFlinkerResponse>;
	getThumbsDownHttpPackage: HttpPackage<SearchFlinkerResponse>;
}

export const initialState: SearchState = {
	createSearchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateSearchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchActiveSearchesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchActiveFilteredSearchesHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	fetchActiveSearchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getSearchResultHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkerMatchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	pinFlinkerMatchHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getAdhocSubscriptionHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getShortlistHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getThumbsUpHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getThumbsDownHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
