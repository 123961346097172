import moment from 'moment';
import { SelectOptionResponse } from './select-option-response.dto';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface SelectOptionsResponse {
	availabilityStatuses: SelectOptionResponse[];
	citizenships: SelectOptionResponse[];
	cities: SelectOptionResponse[];
	genders: SelectOptionResponse[];
	industries: SelectOptionResponse[];
	institutions: SelectOptionResponse[];
	jobCategories: SelectOptionResponse[];
	jobTitles: SelectOptionResponse[];
	languages: SelectOptionResponse[];
	methodsOfContact: SelectOptionResponse[];
	noticePeriods: SelectOptionResponse[];
	placements: SelectOptionResponse[];
	professionalBodies: SelectOptionResponse[];
	profileStatuses: SelectOptionResponse[];
	profileVisibilities: SelectOptionResponse[];
	provinces: SelectOptionResponse[];
	qualificationCategories: SelectOptionResponse[];
	qualificationNames: SelectOptionResponse[];
	races: SelectOptionResponse[];
	reasonsForLeaving: SelectOptionResponse[];
	workspaces: SelectOptionResponse[];
	skills: SelectOptionResponse[];
	driversLicenseTypes: SelectOptionResponse[];
	marketingMethods: SelectOptionResponse[];
	coreAttributes: SelectOptionResponse[];
	interviewRejectionReasons: SelectOptionResponse[];
	offerRejectionReasons: SelectOptionResponse[];
	reactivationReasons: SelectOptionResponse[];
	referenceRelationships: SelectOptionResponse[];
	schools: SelectOptionResponse[];
}

export interface AdminSelectOptionsResponse {
	selectOptionType: string;
	selectOptions: SelectOptionResponse[];
}

export function getSelectOptionName(selectOptionResponses: SelectOptionResponse[], code: string): string {
	return selectOptionResponses?.find((selectOptionResponse) => selectOptionResponse?.code === code)?.name;
}

export function getSelectOptionsNames(selectOptionResponses: SelectOptionResponse[]): string[] {
	return selectOptionResponses.map((option) => option?.name);
}

export function filterOnParentCode(selectOptionResponses: SelectOptionResponse[], code?: string) {
	if (selectOptionResponses == null) return null;
	if (code?.length > 0) return selectOptionResponses.filter((x) => x.parentSelectOption?.code === code);
	return selectOptionResponses;
}

export function filterOnParentCodes(selectOptionResponses: SelectOptionResponse[], codes?: string[]) {
	if (selectOptionResponses == null) return null;
	if (codes?.length > 0)
		return selectOptionResponses.filter((x) => codes.some((code) => code === x.parentSelectOption?.code));
	return selectOptionResponses;
}

export function getSelectOptionNameById(selectOptionResponses: SelectOptionResponse[], id: string) {
	return selectOptionResponses?.find((selectOptionResponse) => selectOptionResponse?.id === id)?.name;
}

export function getSelectOptionIdByCode(selectOptionResponses: SelectOptionResponse[], code: string) {
	return selectOptionResponses?.find((selectOptionResponse) => selectOptionResponse?.code === code)?.id;
}

export function getSelectOptionByCode(selectOptionResponses: SelectOptionResponse[], code: string) {
	return selectOptionResponses?.find((selectOptionResponse) => selectOptionResponse?.code === code);
}

export function getSelectOptionById(selectOptionResponses: SelectOptionResponse[], id: string) {
	return selectOptionResponses?.find((selectOptionResponse) => selectOptionResponse?.id === id);
}

export function getYearOptions(future = false): SelectOptionResponse[] {
	const selectOptions: SelectOptionResponse[] = [];
	for (let i = 0; i < 100; i++) {
		selectOptions.push({
			code: moment()
				.add(future ? i : i * -1, 'years')
				.format('YYYY'),
			name: moment()
				.add(future ? i : i * -1, 'years')
				.format('YYYY'),
		});
	}

	return selectOptions;
}

export function asyncFilterOnParentCodes(
	selectOptionsResponse$: Observable<SelectOptionResponse[]>,
	codes$: Observable<string[]>,
): Observable<SelectOptionResponse[]> {
	return combineLatest([selectOptionsResponse$, codes$]).pipe(
		switchMap((combinedValue) => {
			const selectOptions = combinedValue[0] || [];
			const codes = combinedValue[1] || [];
			return of(filterOnParentCodes(selectOptions, codes) || []);
		}),
	);
}
