import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
	selector: 'app-session-expiry',
	templateUrl: './session-expiry.component.html',
	styleUrls: ['./session-expiry.component.scss'],
})
export class SessionExpiryComponent implements OnInit, OnDestroy {
	countDown = 60;
	logoutInterval: any;

	@Output() onAction = new EventEmitter();

	constructor(private authService: AuthService) {}

	ngOnInit(): void {
		this.logoutInterval = setInterval(() => {
			this.countDown -= this.countDown === 0 ? 0 : 1;
		}, 1000);
	}

	refresh(): void {
		clearInterval(this.logoutInterval);
		this.authService.refresh({ jwtToken: this.authService.getJwtToken(), rememberMe: true }).subscribe();
		this.onAction.emit();
	}

	logout(): void {
		clearInterval(this.logoutInterval);
		this.authService.logout().subscribe();
		this.onAction.emit();
	}

	ngOnDestroy(): void {
		clearInterval(this.logoutInterval);
	}
}
