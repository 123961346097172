import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-mat-card-container',
	templateUrl: './mat-card-container.component.html',
	styleUrls: ['./mat-card-container.component.scss'],
})
export class MatCardContainerComponent implements OnInit {
	@Input() theme = '';

	constructor() {}

	ngOnInit(): void {}
}
