<ng-container *ngIf="!(cvFeaturesHttpPackage$ | async).loading && !(cvPaymentStatusHttpPackage$ | async).loading else loading">
  <ng-template *ngIf="!cvPaymentStatus?.standard" [ngTemplateOutlet]="cvNotPaid"></ng-template>
  <ng-template *ngIf="cvPaymentStatus?.standard" [ngTemplateOutlet]="cvPaidAndDownloadWillBegin"></ng-template>
</ng-container>
<ng-template #cvNotPaid>
  <div>
    <div class="d-flex">
      <mat-icon class="front-main-1 font-size-14 center-icon">shopping_cart</mat-icon>
      <app-page-heading class="heading pl-2 pt-1" heading="{{ 'Pay for your CV' }}" [theme]="'font-family-2 m11 font-bold'"></app-page-heading>
    </div>
    <div class="d-flex flex-wrap">
      <p class="mt-2 col-12 front-sub-5">Please note that edits to your profile will reflect on the downloaded CV. You will be able to download your CV multiple times after payment.</p>
      <p class="mt-2 col-12 front-sub-5 font-size-9 font-bold">What you get?</p>

      <div class="w-100" *ngFor="let cvFeature of cvFeatures">
        <div class="d-flex">
          <mat-icon class="front-main-1 font-size-14 center-icon">check_circle</mat-icon>
          <div class="pl-2 pt-1">{{ cvFeature.type | cvFeatureTypeUserDescription }}</div>
        </div>
        <hr class="back-sub-2 m-2 p-0 w-90" />
      </div>
    </div>
    <div mat-dialog-actions class="justify-content-center pt-3 pb-3 row d-flex flex-column-reverse flex-md-row">
      <app-square-button theme="m5 fs1" class="col-12 col-md-6 mt-4" value="Cancel" (click)="closeDialog()">
      </app-square-button>
      <app-square-button theme="m1 fs1" class="col-12 col-md-6 mt-4 disabled" [value]="'Pay (R'+amountDueForCv+')'"
                         [loading]="(payForCvHttPackage$ | async).loading"
                         [disabled]="(payForCvHttPackage$ | async).loading" (click)="submitPayment()">
      </app-square-button>
    </div>
  </div>

</ng-template>

<ng-template #cvPaidAndDownloadWillBegin>
  <div class="d-flex justify-content-center align-items-center">
<!--    In future check if any of the addons haven't been selected and ask to pay for them.-->
    <mat-progress-spinner mode='indeterminate' [diameter]="progressDiameter"></mat-progress-spinner>
    <app-page-heading class="heading" heading="{{ 'Starting your download ...' }}" [theme]="'ml-4 font-family-2 m11 font-bold'"></app-page-heading>
  </div>
</ng-template>
<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center">
    <mat-progress-spinner mode='indeterminate' [diameter]="progressDiameter"></mat-progress-spinner>
    <app-page-heading class="heading" heading="{{ 'Checking if payment is required ...' }}" [theme]="'ml-4 font-family-2 m11 font-bold'"></app-page-heading>
  </div>
</ng-template>

