<div class="row d-flex">
	<div class="col-12 col-lg-6 order-first order-lg-last mt-4 mt-xl-0 pie-chart">
		<app-piechart *ngIf="flinker" [flinker]="flinker"></app-piechart>
	</div>
	<div class="col-12 col-lg-6 order-last order-md-first">
		<span class="font-bold font-size-8 pt-4 pb-2 d-flex"> Work Experience </span>
		<div class="d-flex py-4 details-underline" *ngIf="flinker?.currentEmployer">
			<div class="row w-100">
				<div class="col-12">
					<span class="font-light font-size-10">
						{{ getJobTitle(flinker?.currentEmployer?.jobTitleId, flinker?.currentEmployer?.otherJobTitle) }}
					</span>
				</div>
				<div class="col-12 pt-2 d-flex">
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">badge</mat-icon>
							{{ flinker?.currentEmployer.companyName }}
						</span>
					</div>
				</div>
				<div class="col-12 pt-2 d-flex float-right">
					<span class="front-sub-5 font-size-11 font-medium center-icon flinker-date-format">
						<mat-icon class="front-main-1 font-size-11 center-icon pr-2">event</mat-icon>
						{{ flinker?.currentEmployer.startDate | dateFormat: 'DD MMMM, YYYY' }} - Current
					</span>
				</div>
        <div class="col-12 pt-2 d-flex float-right" *ngIf='flinker?.currentEmployer.dutiesDescription'>
          <mat-icon class="front-main-1 font-size-11 center-icon pr-2 description-padding">receipt</mat-icon>
          <app-duties-description [jobDutiesDescription]='flinker?.currentEmployer.dutiesDescription'></app-duties-description>
        </div>
			</div>
		</div>
		<div class="d-flex py-4 details-underline" *ngFor="let previousEmployer of flinker?.previousEmployers">
			<div class="row w-100">
				<div class="col-12">
					<span class="d-block font-light font-size-10">
						{{ getJobTitle(previousEmployer.jobTitleId, previousEmployer.otherJobTitle) }}
					</span>
				</div>
				<div class="col-12 pt-2 d-flex">
					<div class="d-flex">
						<span class="front-sub-5 font-size-11 font-medium center-icon">
							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">badge</mat-icon>
							{{ previousEmployer.companyName }}
						</span>
					</div>
				</div>
<!--        <div class="col-12 pt-2 d-flex">-->
<!--          <div class="d-flex">-->
<!--						<span class="front-sub-5 font-size-11 font-medium center-icon">-->
<!--							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">description</mat-icon>-->
<!--              Reason for Leaving: {{ getSelectOptionNameById(selectOptions.reasonsForLeaving, previousEmployer.reasonForLeaving) }}-->
<!--						</span>-->
<!--          </div>-->
<!--        </div>-->
				<div class="col-12 pt-2 d-flex float-right">
					<span class="front-sub-5 font-size-11 font-medium center-icon flinker-date-format">
						<mat-icon class="front-main-1 font-size-11 center-icon pr-2">event</mat-icon>
						{{ previousEmployer.startDate | dateFormat: 'DD MMMM, YYYY' }} -
						{{ previousEmployer.endDate | dateFormat: 'DD MMMM, YYYY' }}
					</span>
				</div>
                <div class="col-12 pt-2 d-flex">
                  <div class="d-flex">
        						<span class="front-sub-5 font-size-11 font-medium center-icon">
        							<mat-icon class="front-main-1 font-size-11 center-icon pr-2">description</mat-icon>
                     {{ getSelectOptionNameById(selectOptions.reasonsForLeaving, previousEmployer.reasonForLeavingId) }}
        						</span>
                  </div>
                </div>
        <div class="col-12 pt-2 d-flex float-right" *ngIf='previousEmployer.dutiesDescription'>
          <mat-icon class="front-main-1 font-size-11 center-icon pr-2 description-padding">receipt</mat-icon>
          <app-duties-description [jobDutiesDescription]='previousEmployer.dutiesDescription'></app-duties-description>
        </div>
			</div>
		</div>
	</div>
</div>
