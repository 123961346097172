import { Actions, ActionTypes } from './actions';
import { initialState, CvState } from './state';

export function cvReducer(state = initialState, action: Actions): CvState {
	switch (action.type) {
		case ActionTypes.GET_CV_FEATURES:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_CV_FEATURES_SUCCESS:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_CV_FEATURES_ERROR:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS:
			return {
				...state,
				cvPaymentStatusHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS_SUCCESS:
			return {
				...state,
				cvPaymentStatusHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS_ERROR:
			return {
				...state,
				cvPaymentStatusHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.PAY_FOR_CV:
			return {
				...state,
				payForCvHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.PAY_FOR_CV_SUCCESS:
			return {
				...state,
				payForCvHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.PAY_FOR_CV_ERROR:
			return {
				...state,
				payForCvHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_MY_FLINKER_CV:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_MY_FLINKER_CV_SUCCESS:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DOWNLOAD_MY_FLINKER_CV_ERROR:
			return {
				...state,
				downloadFlinkerCv: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DOWNLOAD_MY_FLINKER_CV_CLEAR_STATE:
			return {
				...state,
				downloadFlinkerCv: {
					error: null,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
