import { Pipe, PipeTransform } from '@angular/core';
import { InterviewStatus } from '@app/enums/interview-status.enum';

@Pipe({
	name: 'hasInterviewStatus',
})
export class HasInterviewStatusPipe implements PipeTransform {
	transform(value: InterviewStatus, acceptedStatuses: InterviewStatus[]): boolean {
		return acceptedStatuses.indexOf(value) !== -1;
	}
}
