import { Injectable } from '@angular/core';
import ReferenceScoreRequest from '../../models/dto/requests/reference-score-request.dto';
import ReferenceQuestionsResponse from '../../models/dto/responses/reference-questions-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class ReferenceService {
	apiReferenceEndpoint = 'reference';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	score(referenceScoreRequest: ReferenceScoreRequest) {
		return this.httpV2Service.postSecure<void>(`${this.apiReferenceEndpoint}/score`, referenceScoreRequest);
	}

	getReferenceQuestions(referenceId: string) {
		return this.httpV2Service.getSecure<ReferenceQuestionsResponse>(
			`${this.apiReferenceEndpoint}/questions/${referenceId}`,
		);
	}
}
