import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SearchStoreActions } from '.';
import { SearchService } from '@app/services/search/search.service';
import {
	CreateSearch,
	FetchActiveSearch,
	GetFlinkerMatch,
	GetSearchResult,
	PinFlinkerMatch,
	RemoveShortlistedFlinker,
	ShortlistFlinker,
	ThumbsDownFlinker,
	ThumbsUpFlinker,
	UpdateSearch,
} from './actions';
import SearchResponse from '@models/dto/responses/search-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import SearchResult from '@models/dto/responses/search-result-response.dto';
import SearchFlinkerResponse from '@models/dto/responses/search-flinker-response.dto';
import { AdHocSubscriptionResponse } from '@models/dto/responses/adhoc-subscription-response.dto';
import { FilterSearchResponse } from '@models/dto/responses/filter-search-response.dto';

@Injectable()
export class SearchStoreEffects {
	constructor(private actions$: Actions, private searchService: SearchService) {}

	createSearchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.CreateSearch>(SearchStoreActions.ActionTypes.CREATE_SEARCH),
			switchMap((action: CreateSearch) =>
				this.searchService.createSearch(action.payload.createSearchRequest).pipe(
					map((result: SearchResponse) => new SearchStoreActions.CreateSearchSuccess({ result })),
					catchError((error: ErrorResponse) => of(new SearchStoreActions.CreateSearchError({ errorResponse: error }))),
				),
			),
		);
	});

	updateSearchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.UpdateSearch>(SearchStoreActions.ActionTypes.UPDATE_SEARCH),
			switchMap((action: UpdateSearch) =>
				this.searchService.updateSearch(action.payload.searchId, action.payload.updateSearchRequest).pipe(
					map((result: SearchResponse) => new SearchStoreActions.UpdateSearchSuccess({ result })),
					catchError((error: ErrorResponse) => of(new SearchStoreActions.UpdateSearchError({ errorResponse: error }))),
				),
			),
		);
	});

	fetchActiveSearchesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.FetchActiveSearches>(SearchStoreActions.ActionTypes.FETCH_ACTIVE_SEARCHES),
			switchMap(() =>
				this.searchService.fetchActiveSearches().pipe(
					map((result: SearchResponse[]) => new SearchStoreActions.FetchActiveSearchesSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.FetchActiveSearchesError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchActiveFilteredSearchesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.FetchActiveFilteredSearches>(
				SearchStoreActions.ActionTypes.FETCH_ACTIVE_FILTERED_SEARCHES,
			),
			switchMap((action) =>
				this.searchService.fetchActiveFilteredSearches(action.payload.searchFilter).pipe(
					map((result: FilterSearchResponse) => new SearchStoreActions.FetchActiveFilteredSearchesSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.FetchActiveSearchesError({ errorResponse: error })),
					),
				),
			),
		);
	});

	fetchActiveSearchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.FetchActiveSearch>(SearchStoreActions.ActionTypes.FETCH_ACTIVE_SEARCH),
			switchMap((action: FetchActiveSearch) =>
				this.searchService.fetchActiveSearch(action.payload.searchId).pipe(
					map((result: SearchResponse) => new SearchStoreActions.FetchActiveSearchSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.FetchActiveSearchError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getSearchResultEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.GetSearchResult>(SearchStoreActions.ActionTypes.GET_SEARCH_RESULT),
			switchMap((action: GetSearchResult) =>
				this.searchService.getSearchResults(action.payload.filterFlinkerRequest).pipe(
					map((result: SearchResult) => new SearchStoreActions.GetSearchResultSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.GetSearchResultError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getFlinkerMatchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.GetFlinkerMatch>(SearchStoreActions.ActionTypes.GET_FLINKER_MATCH),
			switchMap((action: GetFlinkerMatch) =>
				this.searchService.getFlinkerMatch(action.payload.flinkerId, action.payload.searchId).pipe(
					map((result: SearchFlinkerResponse) => new SearchStoreActions.GetFlinkerMatchSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.GetFlinkerMatchError({ errorResponse: error })),
					),
				),
			),
		);
	});

	pinFlinkerMatchEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.PinFlinkerMatch>(SearchStoreActions.ActionTypes.PIN_FLINKER_MATCH),
			switchMap((action: PinFlinkerMatch) =>
				this.searchService.pinFlinkerMatch(action.payload.flinkerId, action.payload.searchId).pipe(
					map((result: SearchFlinkerResponse) => new SearchStoreActions.PinFlinkerMatchSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.PinFlinkerMatchError({ errorResponse: error })),
					),
				),
			),
		);
	});

	shortlistFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.ShortlistFlinker>(SearchStoreActions.ActionTypes.SHORTLIST_FLINKER),
			switchMap((action: ShortlistFlinker) =>
				this.searchService.shortlistFlinker(action.payload.flinkerId, action.payload.searchId).pipe(
					switchMap((result: SearchFlinkerResponse) =>
						of(
							new SearchStoreActions.ShortlistFlinkerSuccess({ result }),
							new SearchStoreActions.GetFlinkerMatchSuccess({ result }),
						),
					),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.ShortlistFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	removeShortlistedFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.RemoveShortlistedFlinker>(SearchStoreActions.ActionTypes.REMOVE_SHORTLISTED_FLINKER),
			switchMap((action: RemoveShortlistedFlinker) =>
				this.searchService.removeShortlistedFlinker(action.payload.flinkerId, action.payload.searchId).pipe(
					switchMap((result: SearchFlinkerResponse) =>
						of(
							new SearchStoreActions.RemoveShortlistedFlinkerSuccess({ result }),
							new SearchStoreActions.GetFlinkerMatchSuccess({ result }),
						),
					),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.RemoveShortlistedFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	thumbsUpFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.ThumbsUpFlinker>(SearchStoreActions.ActionTypes.THUMBS_UP_FLINKER),
			switchMap((action: ThumbsUpFlinker) =>
				this.searchService.thumbsUpFlinker(action.payload.flinkerId, action.payload.searchId).pipe(
					switchMap((result: SearchFlinkerResponse) =>
						of(
							new SearchStoreActions.ThumbsUpFlinkerSuccess({ result }),
							new SearchStoreActions.GetFlinkerMatchSuccess({ result }),
						),
					),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.ThumbsUpFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	thumbsDownFlinkerEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.ThumbsDownFlinker>(SearchStoreActions.ActionTypes.THUMBS_DOWN_FLINKER),
			switchMap((action: ThumbsDownFlinker) =>
				this.searchService.thumbsDownFlinker(action.payload.flinkerId, action.payload.searchId).pipe(
					switchMap((result: SearchFlinkerResponse) =>
						of(
							new SearchStoreActions.ThumbsDownFlinkerSuccess({ result }),
							new SearchStoreActions.GetFlinkerMatchSuccess({ result }),
						),
					),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.ThumbsDownFlinkerError({ errorResponse: error })),
					),
				),
			),
		);
	});

	getAdhocSubscriptionEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<SearchStoreActions.GetAdhocSubscription>(SearchStoreActions.ActionTypes.GET_ADHOC_SUBSCRIPTION),
			switchMap((_) =>
				this.searchService.getAdhocSubscription().pipe(
					map((result: AdHocSubscriptionResponse) => new SearchStoreActions.GetAdhocSubscriptionSuccess({ result })),
					catchError((error: ErrorResponse) =>
						of(new SearchStoreActions.GetAdhocSubscriptionError({ errorResponse: error })),
					),
				),
			),
		);
	});
}
