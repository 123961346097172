import { Action } from '@ngrx/store';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { NewFlinkCoJobAdvertDto } from '@flinkco-dashboard/models/new-flinkco-job-advert.dto';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import { JobApplicationQuestionAnswerResponse } from '@models/dto/responses/job-application-question-answer-response.dto';
import { JobAdvertReferralRequestDto } from '@models/dto/requests/job-advert-referral-request.dto';
import { FilterJobAdsRequestDto } from '@models/dto/requests/filter-job-ads-request.dto';

export enum ActionTypes {
	FETCH_FLINKCO_JOB_ADVERTS = '[FlinkCo] Fetch FlinkCo job adverts',
	FETCH_FLINKCO_JOB_ADVERTS_SUCCESS = 'FlinkCo] Fetch FlinkCo job adverts success',
	FETCH_FLINKCO_JOB_ADVERTS_ERROR = 'FlinkCo] Fetch FlinkCo job adverts error',

	FETCH_FLINKCO_JOB_ADVERT = '[FlinkCo] Fetch FlinkCo job advert',
	FETCH_FLINKCO_JOB_ADVERT_SUCCESS = 'FlinkCo] Fetch FlinkCo job advert success',
	FETCH_FLINKCO_JOB_ADVERT_ERROR = 'FlinkCo] Fetch FlinkCo job advert error',
	FETCH_PUBLIC_FLINKCO_JOB_ADVERT = '[FlinkCo] Fetch public FlinkCo job advert',
	FETCH_PUBLIC_FLINKCO_JOB_ADVERT_SUCCESS = 'FlinkCo] Fetch public FlinkCo job advert success',
	FETCH_PUBLIC_FLINKCO_JOB_ADVERT_ERROR = 'FlinkCo] Fetch public FlinkCo job advert error',

	UPDATE_FLINKCO_JOB_ADVERT = '[FlinkCo] Update FlinkCo job advert',
	UPDATE_FLINKCO_JOB_ADVERT_SUCCESS = '[FlinkCo] Update FlinkCo job advert success',
	UPDATE_FLINKCO_JOB_ADVERT_ERROR = '[FlinkCo] Update FlinkCo job advert error',

	CREATE_FLINKCO_JOB_ADVERT = '[FlinkCo] Create FlinkCo job advert',
	CREATE_FLINKCO_JOB_ADVERT_SUCCESS = '[FlinkCo] Create FlinkCo job advert success',
	CREATE_FLINKCO_JOB_ADVERT_ERROR = '[FlinkCo] Create FlinkCo job advert error',

	CREATE_JOB_ADVERT_REFERRAL = '[Job Advert] Create job advert referral',
	CREATE_JOB_ADVERT_REFERRAL_SUCCESS = '[Job Advert] Create job advert referral success',
	CREATE_JOB_ADVERT_REFERRAL_ERROR = '[Job Advert] Create job advert referral error',

	CREATE_AUTHED_JOB_ADVERT_REFERRAL = '[Job Advert] Create authed job advert referral',

	FETCH_FLINKCO_JOB_ADPPLICATIONS = '[FlinkCo] Fetch FlinkCo job applications',
	FETCH_FLINKCO_JOB_APPLICATIONS_SUCCESS = 'FlinkCo] Fetch FlinkCo job applications success',
	FETCH_FLINKCO_JOB_APPLICATIONS_ERROR = 'FlinkCo] Fetch FlinkCo job applications error',

	FETCH_JOB_APPLICATION_QUESTION_ANSWERS = '[FlinkCo] Fetch Job application question answers',
	FETCH_JOB_APPLICATION_QUESTION_ANSWERS_SUCCESS = '[FlinkCo] Fetch Job application question answers  success',
	FETCH_JOB_APPLICATION_QUESTION_ANSWERS_ERROR = '[FlinkCo] Fetch Job application question answers error',

	UPDATE_JOB_APPLICATION_FOR_SCHEDULED_INTERVIEW = '[FlinkCo] Update job application for scheduled interview',
	UPDATE_JOB_APPLICATION_FOR_OFFER_MADE = '[FlinkCo] Update job application for offer made',

	FETCH_CAREERS_PAGE_JOB_ADVERTS = '[FlinkCo] Fetch careers page job advert',
	FETCH_CAREERS_PAGE_JOB_ADVERTS_SUCCESS = '[FlinkCo] Fetch careers page job advert success',
	FETCH_CAREERS_PAGE_JOB_ADVERTS_ERROR = '[FlinkCo] Fetch careers page job advert error',

	FETCH_FLINKCO_IFRAME_LINK = '[FlinkCo] Fetch flink co iFrame Link',
	FETCH_FLINKCO_IFRAME_LINK_SUCCESS = '[FlinkCo] Fetch flink co iFrame Link success',
	FETCH_FLINKCO_IFRAME_LINK_ERROR = '[FlinkCo] Fetch flink co iFrame Link error',
}

export class FetchFlinkCoJobAdverts implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERTS;
	constructor(public payload: { pageSize: number; pageNumber: number }) {}
}

export class FetchFlinkCoJobAdvertsSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERTS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<JobAdvertResponseDto> }) {}
}

export class FetchFlinkCoJobAdvertsError implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkCoJobAdvert implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERT;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class FetchFlinkCoJobAdvertSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERT_SUCCESS;
	constructor(public payload: { result: JobAdvertResponseDto }) {}
}

export class FetchFlinkCoJobAdvertError implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADVERT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchPublicFlinkCoJobAdvert implements Action {
	readonly type = ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT;
	constructor(public payload: { jobAdvertId: string }) {}
}

export class FetchPublicFlinkCoJobAdvertSuccess implements Action {
	readonly type = ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT_SUCCESS;
	constructor(public payload: { result: JobAdvertResponseDto }) {}
}

export class FetchPublicFlinkCoJobAdvertError implements Action {
	readonly type = ActionTypes.FETCH_PUBLIC_FLINKCO_JOB_ADVERT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateFlinkCoJobAdvert implements Action {
	readonly type = ActionTypes.CREATE_FLINKCO_JOB_ADVERT;
	constructor(public payload: NewFlinkCoJobAdvertDto) {}
}

export class CreateFlinkCoJobAdvertSuccess implements Action {
	readonly type = ActionTypes.CREATE_FLINKCO_JOB_ADVERT_SUCCESS;
	constructor(public payload: { result: JobAdvertResponseDto }) {}
}

export class CreateFlinkCoJobAdvertError implements Action {
	readonly type = ActionTypes.CREATE_FLINKCO_JOB_ADVERT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateFlinkCoJobAdvert implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO_JOB_ADVERT;
	constructor(public payload: JobAdvertResponseDto) {}
}

export class UpdateFlinkCoJobAdvertSuccess implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO_JOB_ADVERT_SUCCESS;
	constructor(public payload: { result: JobAdvertResponseDto }) {}
}

export class UpdateFlinkCoJobAdvertError implements Action {
	readonly type = ActionTypes.UPDATE_FLINKCO_JOB_ADVERT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateJobAdvertReferral implements Action {
	readonly type = ActionTypes.CREATE_JOB_ADVERT_REFERRAL;
	constructor(public payload: JobAdvertReferralRequestDto) {}
}

export class CreateJobAdvertReferralSuccess implements Action {
	readonly type = ActionTypes.CREATE_JOB_ADVERT_REFERRAL_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class CreateJobAdvertReferralError implements Action {
	readonly type = ActionTypes.CREATE_JOB_ADVERT_REFERRAL_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkCoJobApplications implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_ADPPLICATIONS;
	constructor(public payload: { searchId: string; searchFilterDto: FilterFlinkerRequestDto }) {}
}

export class FetchFlinkCoJobApplicationsSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_APPLICATIONS_SUCCESS;
	constructor(public payload: { result: JobApplicationResponse[] }) {}
}

export class FetchFlinkCoJobApplicationsError implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_JOB_APPLICATIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateJobApplicationForInterviewScheduled implements Action {
	readonly type = ActionTypes.UPDATE_JOB_APPLICATION_FOR_SCHEDULED_INTERVIEW;
	constructor(public payload: { flinkerId: string }) {}
}

export class UpdateJobApplicationForOfferMade implements Action {
	readonly type = ActionTypes.UPDATE_JOB_APPLICATION_FOR_OFFER_MADE;
	constructor(public payload: { flinkerId: string }) {}
}

export class FetchJobApplicationQuestionAnswers implements Action {
	readonly type = ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS;
	constructor(public payload: { flinkerId: string; searchId: string }) {}
}

export class FetchJobApplicationQuestionAnswersSuccess implements Action {
	readonly type = ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS_SUCCESS;
	constructor(public payload: { result: JobApplicationQuestionAnswerResponse[] }) {}
}

export class FetchJobApplicationQuestionAnswersError implements Action {
	readonly type = ActionTypes.FETCH_JOB_APPLICATION_QUESTION_ANSWERS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateAuthedJobAdvertReferral implements Action {
	readonly type = ActionTypes.CREATE_AUTHED_JOB_ADVERT_REFERRAL;
	constructor(public payload: JobAdvertReferralRequestDto) {}
}

export class FetchCareersPageJobAdverts implements Action {
	readonly type = ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS;
	constructor(public payload: { flinkCoId: string; filterJobAdvertsRequest: FilterJobAdsRequestDto }) {}
}

export class FetchCareersPageJobAdvertsSucess implements Action {
	readonly type = ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<JobAdvertResponseDto> }) {}
}

export class FetchCareersPageJobAdvertsError implements Action {
	readonly type = ActionTypes.FETCH_CAREERS_PAGE_JOB_ADVERTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkCoIframeLink implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_IFRAME_LINK;
	constructor() {}
}
export class FetchFlinkCoIframeLinkSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_IFRAME_LINK_SUCCESS;
	constructor(public payload: { result: string }) {}
}
export class FetchFlinkCoIframeLinkError implements Action {
	readonly type = ActionTypes.FETCH_FLINKCO_IFRAME_LINK_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateFlinkCoJobAdvert
	| CreateFlinkCoJobAdvertError
	| CreateFlinkCoJobAdvertSuccess
	| FetchFlinkCoJobAdvert
	| FetchFlinkCoJobAdvertError
	| FetchFlinkCoJobAdvertSuccess
	| FetchPublicFlinkCoJobAdvert
	| FetchPublicFlinkCoJobAdvertError
	| FetchPublicFlinkCoJobAdvertSuccess
	| FetchFlinkCoJobAdverts
	| FetchFlinkCoJobAdvertsError
	| FetchFlinkCoJobAdvertsSuccess
	| UpdateFlinkCoJobAdvert
	| UpdateFlinkCoJobAdvertError
	| UpdateFlinkCoJobAdvertSuccess
	| CreateJobAdvertReferral
	| CreateJobAdvertReferralSuccess
	| CreateJobAdvertReferralError
	| FetchFlinkCoJobApplications
	| FetchFlinkCoJobApplicationsSuccess
	| FetchFlinkCoJobApplicationsError
	| FetchJobApplicationQuestionAnswers
	| FetchJobApplicationQuestionAnswersSuccess
	| FetchJobApplicationQuestionAnswersError
	| UpdateJobApplicationForInterviewScheduled
	| UpdateJobApplicationForOfferMade
	| CreateAuthedJobAdvertReferral
	| FetchCareersPageJobAdverts
	| FetchCareersPageJobAdvertsSucess
	| FetchCareersPageJobAdvertsError
	| FetchFlinkCoIframeLink
	| FetchFlinkCoIframeLinkSuccess
	| FetchFlinkCoIframeLinkError;
