import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { InterviewStatus } from 'projects/flink-app/src/app/enums/interview-status.enum';
import {
	getSelectOptionIdByCode,
	SelectOptionsResponse,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { InterviewStoreActions, InterviewStoreSelectors } from 'projects/flink-app/src/app/root-store/interview-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { Subscription } from 'rxjs';
import { entityConfig } from '@models/constants';

@Component({
	selector: 'app-reject-interview-dialog',
	templateUrl: './reject-interview-dialog.component.html',
	styleUrls: ['./reject-interview-dialog.component.scss'],
})
export class RejectInterviewDialogComponent implements OnInit {
	subscriptions = new Subscription();
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	updateInterviewStatusHttpPackage$ = this.store.select(InterviewStoreSelectors.selectUpdateInterviewStatusHttpPackage);
	selectOptions: SelectOptionsResponse;
	shouldValidate = false;
	constants = entityConfig;

	constructor(
		public dialogRef: MatDialogRef<RejectInterviewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
	) {
		this.form = this.formBuilder.group({
			rejectionReason: ['', [Validators.required]],
			otherRejectionReason: [null, [Validators.maxLength(entityConfig.interview.maxOtherRejectionReasonLength)]],
		});
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.form.controls.rejectionReason.valueChanges.subscribe((value) => {
				if (value == 'OTH') {
					this.form.controls.otherRejectionReason.setValidators(Validators.required);
				} else {
					this.form.controls.otherRejectionReason.setValidators(null);
					this.form.controls.otherRejectionReason.setValue(null);
				}

				this.form.controls.otherRejectionReason.updateValueAndValidity();
			}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result !== null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);

		this.subscriptions.add(
			this.updateInterviewStatusHttpPackage$.subscribe((updateInterviewStatusHttpPackage) => {
				if (updateInterviewStatusHttpPackage.loading) {
					this.subscriptions.add(
						this.updateInterviewStatusHttpPackage$.subscribe((updateInterviewStatusHttpPackage) => {
							if (updateInterviewStatusHttpPackage.result !== null) {
								this.dialogRef.close();
							}
						}),
					);
				}
			}),
		);
	}

	cancel(): void {
		this.dialogRef.close();
	}

	submit() {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}

		const formValues = this.form.value;

		this.store.dispatch(
			new InterviewStoreActions.UpdateInterviewStatus({
				interviewId: this.data.interviewId,
				interviewStatusRequest: {
					status: InterviewStatus.Rejected,
					rejectionReasonId: getSelectOptionIdByCode(
						this.selectOptions.interviewRejectionReasons,
						formValues.rejectionReason,
					),
					otherRejectionReason: formValues.otherRejectionReason,
				},
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
