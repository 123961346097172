import { Actions, ActionTypes } from './actions';
import { initialState, CvAdminState } from './state';

export function cvAdminReducer(state = initialState, action: Actions): CvAdminState {
	switch (action.type) {
		case ActionTypes.GET_CV_FEATURES:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_CV_FEATURES_SUCCESS:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_CV_FEATURES_ERROR:
			return {
				...state,
				cvFeaturesHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_CV_FEATURE_PRICE: {
			return {
				...state,
				updateCvFeaturePriceHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		}
		case ActionTypes.UPDATE_CV_FEATURE_PRICE_SUCCESS: {
			return {
				...state,
				updateCvFeaturePriceHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
				cvFeaturesHttpPackage: {
					...state.cvFeaturesHttpPackage,
					result: state.cvFeaturesHttpPackage.result.map((cvFeature) => {
						if (cvFeature.id === action.payload.result.id) {
							return action.payload.result;
						}
						return cvFeature;
					}),
				},
			};
		}
		case ActionTypes.UPDATE_CV_FEATURE_PRICE_ERROR: {
			return {
				...state,
				updateCvFeaturePriceHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		}
		default: {
			return state;
		}
	}
}
