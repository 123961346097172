import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export interface ToggleSwitchOption {
	label: string;
}

@Component({
	selector: 'app-switch-toggle',
	templateUrl: './switch-toggle.component.html',
	styleUrls: ['./switch-toggle.component.scss'],
})
export class SwitchToggleComponent implements OnInit {
	@Input() selectedOption = 0;

	@Input() options: ToggleSwitchOption[];
	@Input() additionalClass: string;

	@Output() optionSelected = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	onSelect(index: number): void {
		this.selectedOption = index;
		this.optionSelected.emit(index);
	}
}
