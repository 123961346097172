<div class="row">
	<h3
		class="col-12 text-center my-4"
		*ngIf="!(flinker$ | async)?.currentEmployer && (flinker$ | async)?.previousEmployers?.length === 0"
	>
		You haven't added any previous or current employments.
	</h3>
	<div class="col-12 col-md-6 d-flex py-4" *ngIf="(flinker$ | async)?.currentEmployer">
		<div class="row w-100">
			<div class="col-12">
				<span class="d-block font-bold font-size-11">
					{{
						(flinker$ | async)?.currentEmployer.jobTitle === 'OTH'
							? (flinker$ | async)?.currentEmployer.otherJobTitle
							: getSelectOptionName(
									(selectOptionsHttpPackage$ | async)?.result?.jobTitles,
									(flinker$ | async)?.currentEmployer.jobTitle
							  )
					}}
				</span>
				<app-highlighted-text theme="faded2 font-size-6"> Current </app-highlighted-text>
			</div>
			<div class="col-12 col-md-6 pt-2 d-flex">
				<span class="front-sub-5 font-regular font-size-11 center-icon">
					{{ (flinker$ | async)?.currentEmployer.companyName }}
				</span>
			</div>
			<div class="col-12 pt-2 d-flex">
				<span class="font-regular font-size-11"
					>{{ (flinker$ | async)?.currentEmployer.startDate | dateFormat: 'MMMM Do YYYY' }} - Current</span
				>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6 d-flex py-4" *ngFor="let previousEmployer of (flinker$ | async)?.previousEmployers">
		<div class="row w-100">
			<div class="col-12">
				<span class="d-block font-bold font-size-11">
					{{
						previousEmployer.jobTitle === 'OTH'
							? previousEmployer.otherJobTitle
							: getSelectOptionName((selectOptionsHttpPackage$ | async)?.result?.jobTitles, previousEmployer.jobTitle)
					}}
				</span>
				<app-highlighted-text theme="faded10 font-size-6"> Previous </app-highlighted-text>
			</div>
			<div class="col-12 col-md-6 pt-2 d-flex">
				<span class="front-sub-5 font-regular font-size-11 center-icon">
					{{ previousEmployer.companyName }}
				</span>
			</div>
			<div class="col-12 pt-2 d-flex">
				<span class="font-regular font-size-11"
					>{{ previousEmployer.startDate | dateFormat: 'MMMM Do YYYY' }} -
					{{ previousEmployer.endDate | dateFormat: 'MMMM Do YYYY' }}</span
				>
			</div>
		</div>
	</div>
</div>
