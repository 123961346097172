import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { JobAdvertsReferralComponent } from '@app/job-adverts/job-adverts-referral/job-adverts-referral.component';
import { WrapperComponent } from '@app/job-adverts/wrapper/wrapper.component';
import { JobAdvertsRoutingModule } from '@app/job-adverts/job-adverts-routing.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@NgModule({
	declarations: [JobAdvertsReferralComponent, WrapperComponent],
	providers: [
		{ provide: MatDialogRef, useValue: {} },
		{ provide: MAT_DIALOG_DATA, useValue: [] },
	],
	imports: [CommonModule, SharedModule, MatCardModule, ReactiveFormsModule, MatIconModule, JobAdvertsRoutingModule],
})
export class JobAdvertsModule {}
