import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { AuthService } from '@app/services/auth/auth.service';
import { RoleAuthService } from '@app/services/auth/role-auth.service';
import { ReactivationModalComponent } from './reactivation-modal/reactivation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-wrapper',
	templateUrl: './wrapper.component.html',
	styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
	mobileQuery: MediaQueryList;
	isExpanded = true;
	subscriptions = new Subscription();
	user: any;
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	flinker: FlinkerResponse;

	private _mobileQueryListener: () => void;

	constructor(
		public dialog: MatDialog,
		private store: Store<RootStoreState.State>,
		public roleAuthService: RoleAuthService,
		private authService: AuthService,
		private router: Router,
		private media: MediaMatcher,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		this.mobileQuery = media.matchMedia('(max-width: 992px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnInit(): void {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
		this.subscriptions.add(
			this.flinker$.subscribe((flinker) => {
				if (flinker.takeOnComplete !== null) {
					this.flinker = flinker;
					if (!this.flinker.takeOnComplete) this.router.navigate(['/take-on/flinker']);
				}
			}),
		);
	}

	logout(): void {
		this.authService.logout().subscribe();
	}

	openDialog() {
		this.dialog.open(ReactivationModalComponent, {
			width: '500px',
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
