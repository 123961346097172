import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import FlinkerResponse from '@models/dto/responses/flinker-response.dto';
import {
	filterOnParentCode,
	getSelectOptionName,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from '@models/dto/responses/select-options-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import {
	contactNumberValidator,
	dateOfBirthValidator,
	maxCTCBandValidator,
	passportNumberValidatorAsync,
	provinceHasCityValidator,
} from '@app/utils/custom-validators.util';
import { ValidationService } from '@app/services/validation/validation.service';
import { idNumberValidatorAsync } from '@app/utils/custom-validators.util';
import { getErrorMessage } from '@app/utils/form.util';
import { Observable, Subject, Subscription } from 'rxjs';
import { RootStoreState } from '@root-store';
import { FlinkerTakeOnStoreActions, FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { RoleAuthService } from '@app/services/auth/role-auth.service';
import { FlinkerProfileStatus } from '@app/enums/profile-status.enum';
import * as moment from 'moment';
import { delay, delayWhen, filter, take, takeUntil } from 'rxjs/operators';
import { DictionaryHints, DictionaryWords } from '@app/enums/dictionary-words.enum';
import { RegexService } from '@shared/services/regex.service';
import { entityConfig, maxFlinkerNameOrSurnameLength, maxPassportNumberLength } from '@models/constants';
import { flinkerNameAndSurnameFormatterHelper } from '@shared/helpers/flinker-name-and-surname-formatter.helper';
import { isNil } from '@app/utils/functions.util';
import { filterCitiesByProvincesHelper } from '@shared/helpers/filter-cities-by-provinces.helper';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { NotificationType } from '@app/enums/notification-type.enum';
import HttpPackage from '@models/http-package';
import { plainToInstance } from 'class-transformer';
import FlinkerPersonalDetailsRequest from '@models/dto/requests/flinker-personal-details-request.dto';

//TODO: Add back preferred method of contact
@Component({
	selector: 'app-personal-container',
	templateUrl: './personal-container.component.html',
	styleUrls: ['./personal-container.component.scss'],
})
export class PersonalContainerComponent implements OnInit, OnDestroy {
	readonly fileTypeMessage: string = 'Png, Jpeg, Tiff, Pdf, Doc and Docx files accepted  |  No larger than 2mb';
	editState = false;
	subscriptions = new Subscription();
	flinker$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinker);
	form: FormGroup;
	flinker: FlinkerResponse;
	selectOptionsHttpPackage: SelectOptionsResponse;
	flinkerPersonalDetailsHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectFlinkerPersonalDetailsHttpPackage,
	);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);

	uploadIdDocumentHttpPackage$ = this.store.select(FlinkerTakeOnStoreSelectors.selectUploadIdDocumentHttpPackage);

	uploadPassportDocumentHttpPackage$ = this.store.select(
		FlinkerTakeOnStoreSelectors.selectUploadPassportDocumentHttpPackage,
	);

	getSelectOptionName = getSelectOptionName;
	getErrorMessage = getErrorMessage;
	getSelectOptionNameById = getSelectOptionNameById;
	filterOnParentCode = filterOnParentCode;
	moment = moment;
	expectResponse = false;
	FlinkerProfileStatus = FlinkerProfileStatus;
	shouldValidate = false;
	shouldValidateCities = false;
	cities: string[] = [];
	filteredCitiesInProvinces$: Observable<SelectOptionResponse[]>;

	provinces: string[] = [];
	selectOptions: SelectOptionsResponse;
	dictionaryWords = DictionaryWords;
	dictionaryHints = DictionaryHints;
	idDocumentUploaded = false;

	entityConfig = entityConfig;

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(
		private formBuilder: FormBuilder,
		private store: Store<RootStoreState.State>,
		public roleAuthService: RoleAuthService,
		private notifierService: NotifierService,
		private validationService: ValidationService,
	) {
		this.form = this.formBuilder.group({
			name: [
				'',
				[
					Validators.required,
					Validators.pattern(RegexService.validNameWithSpaces()),
					Validators.maxLength(maxFlinkerNameOrSurnameLength),
				],
			],
			surname: [
				'',
				[
					Validators.required,
					Validators.pattern(RegexService.validNameWithSpaces()),
					Validators.maxLength(maxFlinkerNameOrSurnameLength),
				],
			],
			email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
			idNumber: [
				{ value: '' },
				[Validators.required, Validators.pattern(RegexService.validIdNumberFormat())],
				[idNumberValidatorAsync(validationService)],
			],
			passportNumber: [
				{ value: '' },
				[Validators.pattern(RegexService.validPassportNumberFormat()), Validators.maxLength(maxPassportNumberLength)],
			],
			idUpload: [''],
			passportUpload: [''],
			dateOfBirth: ['', [Validators.required, dateOfBirthValidator()]],
			race: ['', [Validators.required]],
			gender: ['', [Validators.required]],
			languageProficiency1: ['', [Validators.required]],
			languageProficiency2: [''],
			southAfricanCitizen: [{ value: '' }, [Validators.required]],
			disabled: ['', [Validators.required]],
			driversLicense: ['', [Validators.required]],
			ownsVehicle: ['', [Validators.required]],
			cellphoneNumber: ['', [Validators.required, contactNumberValidator()]],
			preferredMethodOfContact: ['E', [Validators.required]],
			professionalBody1: [''],
			professionalBody2: [''],
			placement: ['', [Validators.required]],
			workspacePreference: ['', [Validators.required]],
			willingToRelocate: ['', [Validators.required]],
			salaryBandLower: ['', [Validators.required, maxCTCBandValidator()]],
			salaryBandUpper: ['', [Validators.required, maxCTCBandValidator()]],
			jobRequirement: ['', Validators.maxLength(entityConfig.flinker.maxNextJobRequirement)],
			additionalLeaveBenefits: ['', [Validators.required]],
			noticePeriod: ['', [Validators.required]],
			marketingMethod: ['', [Validators.required]],
			registeredWithProfessionalBody: ['', [Validators.required]],
			idealWorkProvince: [[], [Validators.required]],
			idealWorkCities: [[], [Validators.required, provinceHasCityValidator(this.selectOptionsHttpPackage$)]],
			availabilityStatus: ['', [Validators.required]],
			hasTakenCovidVaccine: [null],
			currentSalary: [0, [Validators.required]],
			visibilityStatus: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
		this.subscriptions.add(
			this.form.controls.southAfricanCitizen.valueChanges.subscribe((value) => {
				this.idDocumentUploaded =
					((value == 'Y' && !isNil(this.flinker?.idNumber)) ||
						(value == 'N' && !isNil(this.flinker?.passportNumber))) &&
					this.flinker?.idDocumentUploaded;
				if (value === 'Y') {
					this.form.controls.idNumber.setValidators([
						Validators.required,
						Validators.pattern(RegexService.validIdNumberFormat()),
					]);
					this.form.controls.idNumber.setAsyncValidators([idNumberValidatorAsync(this.validationService)]);

					this.form.controls.idUpload.setValidators([Validators.required]);
					this.form.controls.idUpload.setValue(this.idDocumentUploaded);

					this.form.controls.passportNumber.clearValidators();
					this.form.controls.passportNumber.clearAsyncValidators();
					this.form.controls.passportNumber.patchValue(this.flinker?.passportNumber);

					this.form.controls.passportUpload.clearValidators();
				} else if (value == 'N') {
					this.form.controls.passportNumber.setValidators([
						Validators.required,
						Validators.pattern(RegexService.validPassportNumberFormat()),
						Validators.maxLength(maxPassportNumberLength),
					]);
					this.form.controls.passportNumber.setAsyncValidators([passportNumberValidatorAsync(this.validationService)]);

					this.form.controls.passportUpload.setValidators([Validators.required]);
					this.form.controls.passportUpload.setValue(this.idDocumentUploaded);

					this.form.controls.idNumber.clearValidators();
					this.form.controls.idNumber.clearAsyncValidators();
					this.form.controls.idNumber.patchValue(this.flinker?.idNumber);

					this.form.controls.idUpload.clearValidators();
				}

				this.form.controls.passportNumber.updateValueAndValidity();
				this.form.controls.passportUpload.updateValueAndValidity();

				this.form.controls.idNumber.updateValueAndValidity();
				this.form.controls.idUpload.updateValueAndValidity();
				this.form.updateValueAndValidity();
			}),
		);

		this.form.controls.salaryBandUpper.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.form.controls.salaryBandLower.updateValueAndValidity({ onlySelf: true, emitEvent: false });
		});

		this.form.controls.salaryBandLower.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			this.form.controls.salaryBandUpper.updateValueAndValidity({ onlySelf: true, emitEvent: false });
		});

		this.subscriptions.add(
			this.form.controls.registeredWithProfessionalBody.valueChanges
				.pipe(takeUntil(this.destroy$))
				.subscribe((value) => {
					if (value === 'Y') {
						this.form.controls.professionalBody1.setValue('');
						this.form.controls.professionalBody1.setValidators(Validators.required);
					} else {
						this.form.controls.professionalBody1.setValue('');
						this.form.controls.professionalBody1.clearValidators();
					}
					this.form.controls.professionalBody1.updateValueAndValidity();
					this.form.updateValueAndValidity();
				}),
		);

		this.filteredCitiesInProvinces$ = filterCitiesByProvincesHelper(
			this.selectOptionsHttpPackage$,
			this.form.controls.idealWorkProvince.valueChanges,
		).pipe(takeUntil(this.destroy$));

		this.subscriptions.add(
			this.form.controls.idealWorkProvince.valueChanges.subscribe(() => {
				this.shouldValidateCities = true;
				this.form.controls.idealWorkCities.updateValueAndValidity();
				this.form.updateValueAndValidity();
			}),
		);

		this.subscriptions.add(
			this.flinker$
				.pipe(
					delayWhen(() => this.selectOptionsHttpPackage$),
					filter((flinker) => !isNil(flinker) && !isNil(flinker?.id)),
					delay(500),
					take(1),
				)
				.subscribe((flinker) => {
					this.flinker = flinker;
					this.idDocumentUploaded = this.flinker.idDocumentUploaded;
					let cities: string[] = [];
					flinker?.idealWorkLocations?.forEach((location) => (cities = cities.concat(location.city)));

					flinker?.idealWorkLocations?.forEach(
						(location) =>
							(this.provinces = this.provinces.concat(
								getSelectOptionName(this.selectOptions?.provinces, location.province),
							)),
					);
					this.provinces = this.provinces.filter((value, index, self) => self.indexOf(value) === index);
					cities.forEach(
						(city) => (this.cities = this.cities.concat(getSelectOptionName(this.selectOptions?.cities, city))),
					);
					this.cities = this.cities.filter((value, index, self) => self.indexOf(value) === index);
					this.form.patchValue({
						...flinker,
						southAfricanCitizen: !isNil(flinker?.southAfricanCitizen) ? (flinker.southAfricanCitizen ? 'Y' : 'N') : '',
						disabled: !isNil(flinker?.disabled) ? (flinker.disabled ? 'Y' : 'N') : '',
						willingToRelocate: !isNil(flinker?.willingToRelocate) ? (flinker.willingToRelocate ? 'Y' : 'N') : '',
						additionalLeaveBenefits: !isNil(flinker?.additionalLeaveBenefits)
							? flinker.additionalLeaveBenefits
								? 'Y'
								: 'N'
							: '',
						email: flinker.emailAddress,
						race: flinker.race,
						jobRequirement: flinker.nextJobRequirement,
						cellPhoneNumber: flinker.cellphoneNumber,
						registeredWithProfessionalBody: !isNil(flinker?.registeredWithProfessionalBody)
							? flinker.registeredWithProfessionalBody
								? 'Y'
								: 'N'
							: '',
						idealWorkProvince: flinker?.idealWorkLocations
							?.map((x) => x.province)
							.filter((value, index, self) => self.indexOf(value) === index),
						idealWorkCities: cities,
						ownsVehicle: !isNil(flinker?.ownsVehicle) ? (flinker.ownsVehicle ? 'Y' : 'N') : '',
						hasTakenCovidVaccine: flinker.hasTakenCovidVaccine,
					});

					if (flinker.profileStatus === FlinkerProfileStatus.Churning) {
						this.store.dispatch(new FlinkerTakeOnStoreActions.GetReactivationRequest());
					}

					if (flinker.idDocumentUploaded) {
						this.form.controls.idUpload.setValidators(null);
						this.form.controls.passportUpload.setValidators(null);
					}
				}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result != null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);

		this.subscriptions.add(
			this.flinkerPersonalDetailsHttpPackage$
				.pipe(filter((response) => response.result != null && !response.loading))
				.subscribe((response: HttpPackage<FlinkerResponse>) => {
					this.idDocumentUploaded = response.result?.idDocumentUploaded;
					if (this.expectResponse) {
						this.notifierService.notify(NotificationType.Success, 'Profile details updated successfully');
						this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
						this.store.dispatch(new FlinkerTakeOnStoreActions.GetFlinker());
					}
				}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}

	submit(): void {
		this.form.updateValueAndValidity();
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		this.expectResponse = true;
		const formValues = this.form.value;
		this.store.dispatch(
			new FlinkerTakeOnStoreActions.SubmitFlinkerPersonalDetails({
				flinkerPersonalDetailsRequest: plainToInstance(
					FlinkerPersonalDetailsRequest,
					{
						...formValues,
						...flinkerNameAndSurnameFormatterHelper(this.form.controls.name.value, this.form.controls.surname.value),
						dateOfBirth: moment(formValues.dateOfBirth).toISOString(),
						southAfricanCitizen: formValues.southAfricanCitizen.toLowerCase() == 'Y'.toLowerCase(),
						disabled: formValues.disabled == 'Y',
						willingToRelocate: formValues.willingToRelocate == 'Y',
						additionalLeaveBenefits: formValues.additionalLeaveBenefits == 'Y',
						registeredWithProfessionalBody:
							formValues.registeredWithProfessionalBody.toLowerCase() == 'Y'.toLowerCase(),
						ownsVehicle: formValues.ownsVehicle == 'Y',
						hasTakenCovidVaccine: formValues.hasTakenCovidVaccine,
						idealWorkLocations: this.form.get('idealWorkProvince').value.map((province: string) => {
							return {
								province,
								city: this.form
									.get('idealWorkCities')
									.value.filter(
										(city: string) =>
											this.selectOptions.cities.find((citySelectOption) => citySelectOption.code === city)
												.parentSelectOption.code === province,
									),
							};
						}),
					} as FlinkerPersonalDetailsRequest,
					{ excludeExtraneousValues: true },
				),
			}),
		);
	}

	filterCities(provinces: string[]) {
		const citiesList: string[] = Object.assign([], this.form.controls.idealWorkCities.value);
		const newCitiesList: string[] = [];
		citiesList.forEach((element) => {
			const citySelectOption = this.selectOptions.cities.find((x) => x.code === element);
			if (!(provinces || []).some((x) => x === citySelectOption.parentSelectOption?.code)) {
				const index = this.cities.indexOf(element);
				this.cities.splice(index, 1);
			} else {
				newCitiesList.push(citySelectOption.code);
			}
		});
		this.form.controls.idealWorkCities.reset();
		this.form.controls.idealWorkCities.setValue(newCitiesList);
	}

	uploadIdDocument(file: File): void {
		if (isNil(file)) {
			return;
		}
		const formData = new FormData();

		formData.append('idDocument', file, file.name);

		this.subscriptions.add(
			this.uploadIdDocumentHttpPackage$
				.pipe(
					filter((response) => response.result !== null && !response.loading),
					take(1),
				)
				.subscribe(() => {
					this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
					this.notifierService.notify(NotificationType.Success, 'Id document uploaded.');
					this.idDocumentUploaded = true;
				}),
		);
		this.store.dispatch(new FlinkerTakeOnStoreActions.UploadIdDocument({ formData }));
		this.form.controls.idUpload.setErrors(null);
	}

	uploadPassportDocument(file: File): void {
		if (isNil(file)) {
			return;
		}

		const formData = new FormData();

		formData.append('passportDocument', file, file.name);

		this.subscriptions.add(
			this.uploadPassportDocumentHttpPackage$
				.pipe(
					filter((response) => response.result !== null && !response.loading),
					take(1),
				)
				.subscribe(() => {
					this.store.dispatch(new FlinkerTakeOnStoreActions.GetProfileCompletion());
					this.notifierService.notify(NotificationType.Success, 'Passport document uploaded.');
					this.idDocumentUploaded = true;
				}),
		);

		this.store.dispatch(new FlinkerTakeOnStoreActions.UploadPassportDocument({ formData }));
		this.form.controls.passportUpload.setErrors(null);
		this.form.updateValueAndValidity();
	}
}
