import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReferenceQuestionsStoreEffects } from './effects';
import { ReferenceQuestionsReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('reference-questions', ReferenceQuestionsReducer),
		EffectsModule.forFeature([ReferenceQuestionsStoreEffects]),
	],
	providers: [ReferenceQuestionsStoreEffects],
})
export class ReferenceQuestionsStoreModule {}
