<mat-form-field>
	<label [ngClass]="{ required: required }">{{ labelText }}</label>
	<div class="ghost-input">
		<input
			matInput
			[matDatepicker]="picker"
			[disabled]="disabled"
			(dateChange)="input($event)"
			(blur)="onBlur()"
			[value]="value"
			(focus)="picker.open()"
		/>
	</div>
	<mat-datepicker-toggle class="date-icon" matSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-datepicker #picker></mat-datepicker>
	<app-input-error-message *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage"></app-input-error-message>
</mat-form-field>
