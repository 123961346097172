import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericOption } from '@models/generic-option.model';
import { DictionaryMap } from '@app/interfaces/dictionary-map.interface';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-outlined-view-draggable-list',
	templateUrl: './outlined-view-draggable-list.component.html',
	styleUrls: ['./outlined-view-draggable-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedViewDraggableListComponent),
			multi: true,
		},
	],
})
export class OutlinedViewDraggableListComponent implements OnInit, ControlValueAccessor, OnDestroy {
	@Input() labelText = '';
	@Input() formControlName = '';

	_listMap: DictionaryMap<GenericOption> = {};
	@Input() set list(listWithDisplayData: Array<GenericOption>) {
		this._listMap = listWithDisplayData.reduce((map, item: GenericOption) => {
			map[item.value] = item;
			return map;
		}, {} as DictionaryMap<GenericOption>);
	}
	@Input() value: string[] = [];
	@Input() theme = '';
	@Input() errorMessage = '';
	@Input() hint = '';

	required = false;

	onChange: any = () => {};
	onTouched: any = () => {};
	private readonly destroy$ = new Subject<void>();
	constructor(private readonly controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.value, event.previousIndex, event.currentIndex);
		this.onChange(this.value);
	}

	remove(index: number): void {
		this.value.splice(index, 1);
		this.onChange(this.value);
	}

	public writeValue(value: Array<string>): void {
		this.value = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onBlur(): void {
		this.onTouched();
	}
}
