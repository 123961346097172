import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobAdvertsReferralComponent } from '@app/job-adverts/job-adverts-referral/job-adverts-referral.component';
import { WrapperComponent } from '@app/job-adverts/wrapper/wrapper.component';

const routes: Routes = [
	{
		path: '',
		component: WrapperComponent,
		children: [
			{
				path: 'referral/:jobAdvertId',
				component: JobAdvertsReferralComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class JobAdvertsRoutingModule {}
