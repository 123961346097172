<div class="d-flex flex-row cursor-pointer">
	<label
		class="mt-2 mb-0 mr-2 font-medium cursor-pointer"
		[class.order-0]="!reverseOrder"
		[class.order-1]="reverseOrder"
		>{{ buttonText }}</label
	>
	<app-circle-icon-button
		class="mt-1"
		[theme]="theme"
		[icon]="icon"
		[iconTheme]="iconTheme"
		[class.order-1]="!reverseOrder"
		[class.order-0]="reverseOrder"
	>
	</app-circle-icon-button>
</div>
