import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInAuthGuard } from '../services/auth/logged-in.guard';

import { FlinkCoRegisterComponent } from './components/flink-co-register/flink-co-register.component';
import { FlinkerRegisterComponent } from './components/flinker-register/flinker-register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { OtpVerifyComponent } from './components/otp-verify/otp-verify.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOnContainerComponent } from './components/sign-on-container/sign-on-container.component';
import { TokenSignInComponent } from './components/token-sign-in/token-sign-in.component';

const routes: Routes = [
	{
		path: '',
		component: SignOnContainerComponent,
		children: [
			{
				path: 'sign-in',
				canActivate: [LoggedInAuthGuard],
				component: SignInComponent,
			},
			{
				path: 'flinker-registration',
				canActivate: [LoggedInAuthGuard],
				component: FlinkerRegisterComponent,
			},
			{
				path: 'flinkco-registration',
				canActivate: [LoggedInAuthGuard],
				component: FlinkCoRegisterComponent,
			},
			{
				path: 'forgot-password',
				canActivate: [LoggedInAuthGuard],
				component: ForgotPasswordComponent,
			},
			{
				path: 'reset',
				component: ResetPasswordComponent,
			},
			{
				path: 'otp',
				component: OtpVerifyComponent,
			},
			{
				path: 'token-sign-in',
				component: TokenSignInComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthorizationRoutingModule {}
