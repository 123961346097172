import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-sign-on-container',
	templateUrl: './sign-on-container.component.html',
	styleUrls: ['./sign-on-container.component.scss'],
})
export class SignOnContainerComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
