import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import RefreshTokenRequest from '../../models/dto/requests/refresh-token-request.dto';
import { AuthService } from './auth.service';
import { RoleAuthService } from './role-auth.service';
import { Location } from '@angular/common';
import { SubscriptionAuthService } from './subscription-auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
	constructor(
		public authService: AuthService,
		public router: Router,
		private route: ActivatedRoute,
		private roleAuthService: RoleAuthService,
		private subscriptionAuthService: SubscriptionAuthService,
		private location: Location,
	) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, _) => {
			if (!this.authService.isLoggedIn()) {
				if (this.authService.canRefresh()) {
					this.authService
						.refresh(new RefreshTokenRequest(this.authService.getJwtToken(), this.authService.getRememberMe()))
						.subscribe(
							(_) => {
								resolve(true);
							},
							(_) => {
								this.router.navigate(['auth/sign-in']);
								resolve(false);
							},
						);
				} else {
					this.router.navigate(['auth/sign-in']);
					resolve(false);
				}
			} else {
				const allowedRoles = next.data.allowedRoles;
				const isAuthorizedRole = this.roleAuthService.isAuthorized(allowedRoles);
				const allowedSubscriptions = next.data.allowedSubscriptions;
				const isAuthorizedSubscription = this.subscriptionAuthService.isAuthorized(allowedSubscriptions);
				const requireActive = next.data.requireActive ?? false;

				if (requireActive && !this.roleAuthService.accountIsActive()) {
					this.router.navigate(['/profile']);
					resolve(false);
				}
				if (isAuthorizedRole && isAuthorizedSubscription) {
					// if (this.authService.getReturnUrl()) {
					// 	this.router.navigate(['/' + this.authService.getReturnUrl()]);
					// 	this.authService.clearReturnUrl();
					// 	resolve(false);
					// }
					resolve(true);
				} else if (!isAuthorizedSubscription) {
					this.router.navigate(['/profile']);
					resolve(false);
				} else {
					this.location.back();
					resolve(false);
				}
			}
		});
	}
}
