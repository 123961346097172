<app-rounded-button [disabled]="submitDisabled" [theme]="theme ? theme : 'm1'" type="submit" [value]="submitValue" (click)="onSubmitClicked()">
</app-rounded-button>
<div class="pt-4 pb-3 d-inline-flex w-100">
	<hr class="back-sub-2 m-0 p-0 w-50" />
	<span class="px-2 mt-n2 font-size-12 front-sub-5">OR</span>
  <hr class="back-sub-2 m-0 p-0 w-50" />
</div>

<app-rounded-button type="button" [disabled]="submitDisabled" theme="google shadow-1" [value]="(isSignUp ? buttonActionSignUpText : buttonActionSignInText) + 'Google'" (click)="onGoogleClicked()">
</app-rounded-button>
<app-rounded-button
	class="mt-3"
	type="button"
  [disabled]="submitDisabled"
	theme="microsoft shadow-1"
	[value]=" (isSignUp ? buttonActionSignUpText : buttonActionSignInText) + 'Microsoft'"
	(click)="onMicrosoftClicked()">
</app-rounded-button>
<!-- TODO: Re-add once Facebook works again-->
<!-- <app-rounded-button class="mt-3" type="button" theme="facebook shadow-1" value="Sign in with Facebook">
</app-rounded-button>-->
