import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-bold-label',
	templateUrl: './bold-label.component.html',
	styleUrls: ['./bold-label.component.scss'],
})
export class BoldLabelComponent implements OnInit {
	@Input() label = '';
	constructor() {}

	ngOnInit(): void {}
}
