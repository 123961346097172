import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-cookies-detailed',
	templateUrl: './cookies-detailed.component.html',
	styleUrls: ['./cookies-detailed.component.scss'],
})
export class CookiesDetailedComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
