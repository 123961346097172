import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FlinkerAdminStoreEffects } from './effects';
import { FlinkerAdminReducer } from './reducer';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('flinker-admin', FlinkerAdminReducer),
		EffectsModule.forFeature([FlinkerAdminStoreEffects]),
	],
	providers: [FlinkerAdminStoreEffects],
})
export class FlinkerAdminStoreModule {}
