import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'projects/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { LegalRoutingModule } from './legal.routing.module';
import { MatCardModule } from '@angular/material/card';
import { CookiesPreferencesComponent } from './cookies-preferences/cookies-preferences.component';
import { MatButtonModule } from '@angular/material/button';
import { CookiesDetailedComponent } from './cookies-detailed/cookies-detailed.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisclosureConcentFormComponent } from './disclosure-concent-form/disclosure-concent-form.component';
import { ServiceLevelAgreementComponent } from './service-level-agreement/service-level-agreement.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
	declarations: [
		CookiesPreferencesComponent,
		CookiesDetailedComponent,
		TermsOfUseComponent,
		PrivacyPolicyComponent,
		DisclosureConcentFormComponent,
		ServiceLevelAgreementComponent,
		TermsAndConditionsComponent,
	],
	imports: [
		CommonModule,
		LegalRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		MatIconModule,
		HttpClientModule,
		MatCardModule,
		MatButtonModule,
	],
	exports: [CookiesPreferencesComponent],
})
export class LegalModule {}
