import { Action } from '@ngrx/store';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import CvPaymentStatusResponseDto from '@models/dto/responses/cv-payment-status-response.dto';
import PayForCvResponseDto from '@models/dto/responses/pay-for-cv-response.dto';
import PayForCvRequestDto from '@models/dto/requests/pay-for-cv-request.dto';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

export enum ActionTypes {
	GET_CV_FEATURES = '[CV] Get CV features',
	GET_CV_FEATURES_SUCCESS = '[CV] Get CV features success',
	GET_CV_FEATURES_ERROR = '[CV] Get CV features error',

	GET_FLINKER_CV_PAYMENT_STATUS = '[CV] Get flinker cv payment status',
	GET_FLINKER_CV_PAYMENT_STATUS_SUCCESS = '[CV] Get flinker cv payment status success',
	GET_FLINKER_CV_PAYMENT_STATUS_ERROR = '[CV] Get flinker cv payment status error',

	PAY_FOR_CV = '[CV] Pay for CV',
	PAY_FOR_CV_SUCCESS = '[CV] Pay for CV success',
	PAY_FOR_CV_ERROR = '[CV] Pay for CV error',

	DOWNLOAD_MY_FLINKER_CV = '[CV] Download my flinker CV',
	DOWNLOAD_MY_FLINKER_CV_SUCCESS = '[CV] Download my flinker CV success',
	DOWNLOAD_MY_FLINKER_CV_ERROR = '[CV] Download my flinker CV error',
	DOWNLOAD_MY_FLINKER_CV_CLEAR_STATE = '[CV] Download my flinker CV clear state',
}

export class GetCvFeatures implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES;
	constructor() {}
}
export class GetCvFeaturesSuccess implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES_SUCCESS;
	constructor(public payload: { result: CvFeatureResponseDto[] }) {}
}
export class GetCvFeaturesError implements Action {
	readonly type = ActionTypes.GET_CV_FEATURES_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkerCVPaymentStatus implements Action {
	readonly type = ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS;
	constructor() {}
}
export class GetFlinkerCVPaymentStatusSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS_SUCCESS;
	constructor(public payload: { result: CvPaymentStatusResponseDto }) {}
}
export class GetFlinkerCVPaymentStatusError implements Action {
	readonly type = ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DownloadMyFlinkerCv implements Action {
	readonly type = ActionTypes.DOWNLOAD_MY_FLINKER_CV;
	constructor() {}
}
export class DownloadMyFlinkerCvSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_MY_FLINKER_CV_SUCCESS;
	constructor(public payload: { result: any }) {}
}
export class DownloadMyFlinkerCvError implements Action {
	readonly type = ActionTypes.DOWNLOAD_MY_FLINKER_CV_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}
export class DownloadMyFlinkerCvClearState implements Action {
	readonly type = ActionTypes.DOWNLOAD_MY_FLINKER_CV_CLEAR_STATE;
	constructor() {}
}

export class PayForCv implements Action {
	readonly type = ActionTypes.PAY_FOR_CV;
	constructor(public payload: PayForCvRequestDto) {}
}
export class PayForCvSuccess implements Action {
	readonly type = ActionTypes.PAY_FOR_CV_SUCCESS;
	constructor(public payload: { result: PayForCvResponseDto }) {}
}
export class PayForCvError implements Action {
	readonly type = ActionTypes.PAY_FOR_CV_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| GetCvFeatures
	| GetCvFeaturesSuccess
	| GetCvFeaturesError
	| GetFlinkerCVPaymentStatus
	| GetFlinkerCVPaymentStatusSuccess
	| GetFlinkerCVPaymentStatusError
	| PayForCv
	| PayForCvSuccess
	| PayForCvError
	| DownloadMyFlinkerCv
	| DownloadMyFlinkerCvSuccess
	| DownloadMyFlinkerCvError
	| DownloadMyFlinkerCvClearState;
