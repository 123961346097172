import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { safeStorage } from '@app/utils/safe-storage';

@Component({
	selector: 'app-cookies-preferences',
	templateUrl: './cookies-preferences.component.html',
	styleUrls: ['./cookies-preferences.component.scss'],
})
export class CookiesPreferencesComponent implements OnInit {
	toggleCookies = true;
	form: FormGroup;

	constructor(private formBuilder: FormBuilder) {
		this.form = this.formBuilder.group({
			doNotShowAgain: [false],
		});
	}

	ngOnInit(): void {}

	onToggleCookies() {
		this.toggleCookies = !this.toggleCookies;

		if (this.form.controls.doNotShowAgain.value) {
			safeStorage.setItem('doNotShowCookiePreferences', 'true');
		}
	}
}
