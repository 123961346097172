<div class="container mw-100 min-vh-100 p-1 p-md-4">
  <div class="company-main-logo-container" *ngIf="jobAdverts && jobAdverts?.length > 0">
    <img class="company-main-logo-image" [src]="jobAdverts[0]?.companyLogoUrl || jobAdverts[0]?.defaultSystemCompanyLogoUrl" alt="Company Logo" />
  </div>
	<form [formGroup]="form" class="mx-md-5 mb-3">
		<div class="row mx-md-5 px-4">
			<app-outlined-dropdown
				class="col-12 col-md-6"
				labelText="Job category of position"
				formControlName="jobCategory"
				[options]="(selectOptionsHttpPackage$ | async)?.result?.jobCategories"
				[errorMessage]="getErrorMessage(form.controls.jobCategory, 'Job Category of Position', shouldValidate)"
			>
			</app-outlined-dropdown>
			<app-outlined-dropdown
				class="col-12 col-md-6"
				labelText="Placement type"
				formControlName="placement"
				[options]="(selectOptionsHttpPackage$ | async)?.result?.placements"
				[errorMessage]="getErrorMessage(form.controls.placement, 'Placement type', shouldValidate)"
			>
			</app-outlined-dropdown>
			<app-chips-input
				class="col-12 mb-3"
				labelText="Provinces"
				formControlName="provinces"
				theme="outlined-input"
				[options]="(selectOptionsHttpPackage$ | async)?.result?.provinces"
				[errorMessage]="getErrorMessage(form.controls.provinces, 'Province', shouldValidate)"
			>
			</app-chips-input>
			<app-square-button (click)="getJobAdverts()" value="Filter Job Ads" theme="gh-m1 bold"></app-square-button>
			<app-square-button (click)="clearFilter()" value="Clear Filter" theme="gh-m5 bold"></app-square-button>
		</div>
	</form>
	<div class="overflow-hidden">
		<div class="w-100 col-12">
			<ng-container *ngIf="jobAdverts?.length > 0 && !(fetchFlinkCoJobAdvertsHttpPackage$ | async)?.loading">
				<div class="w-100 col-12 grid-container" [@listAnimation]="!(fetchFlinkCoJobAdvertsHttpPackage$ | async)?.loading">
					<ng-container *ngFor="let jobAdvert of jobAdverts">
						<app-job-advert-card
							[jobAdvert]="jobAdvert"
							[flinkCoId]="flinkCoId"
              [isPublic]="true"
							class="col-sm-12 p-0 mx-0 grid-item"
						></app-job-advert-card>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="jobAdverts?.length === 0 && !(fetchFlinkCoJobAdvertsHttpPackage$ | async)?.loading">
				<div class="w-100 text-center col-12">
					<h3 class="front-fade-4 font-size-6">No job adverts available</h3>
				</div>
			</ng-container>
			<ng-container *ngIf="(fetchFlinkCoJobAdvertsHttpPackage$ | async)?.loading">
				<div class="w-100 text-center col-12">
					<app-loader></app-loader>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<mat-paginator
	[pageSizeOptions]="[3, 12, 20, 50]"
	[pageSize]="pageSize"
	[pageIndex]="pageNumber"
	[length]="numberOfJobAdverts"
	(page)="onPaginationEvent($event)"
	showFirstLastButtons
	aria-label="Select page of job adverts"
>
</mat-paginator>
