import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getErrorMessage } from '@app/utils/form.util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { FlinkerJobsAction, FlinkerJobsSelectors } from '@root-store/flinker/jobs';
import { Subject } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotificationType } from '@app/enums/notification-type.enum';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import QuestionAnswerResponseDto from '@models/dto/responses/question-answer-response.dto';
import { ErrorResponseV2 } from '@models/dto/responses/error-response.dto';
import { ProgressGaugeModalComponent } from '@flinker-dashboard/components/profile/progress-gauge-modal/progress-gauge-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-job-application',
	templateUrl: './job-application.component.html',
	styleUrls: ['./job-application.component.scss'],
})
export class JobApplicationComponent implements OnInit, OnDestroy {
	fetchJobAdvertQuestionsHttpPackage$ = this.store.select(FlinkerJobsSelectors.selectFetchJobAdvertQuestions);
	createJobApplicationHttpPackage$ = this.store.select(FlinkerJobsSelectors.selectCreateJobApplication);

	form: FormGroup;
	jobAdvertId: string;
	questions: QuestionAnswerResponseDto[];
	getErrorMessage = getErrorMessage;
	shouldValidate = false;

	readonly autoSaveAnswerDebounceTime = 1000;

	private readonly destroy$: Subject<void> = new Subject<void>();
	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly store: Store<RootStoreState.State>,
		private readonly notifierService: NotifierService,
		private readonly route: ActivatedRoute,
		private readonly elementRef: ElementRef,
		private readonly router: Router,
		public dialog: MatDialog,
	) {
		this.form = this.formBuilder.group({
			allQuestions: this.formBuilder.array([]),
		});
	}

	ngOnInit(): void {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
			this.jobAdvertId = paramMap.get('jobAdvertId');
			if (!this.jobAdvertId) {
				return;
			}
			this.store.dispatch(new FlinkerJobsAction.FetchJobAdvertQuestions({ jobAdvertId: this.jobAdvertId }));
		});

		this.fetchJobAdvertQuestionsHttpPackage$
			.pipe(takeUntil(this.destroy$))
			.subscribe((fetchJobAdvertQuestionsHttpPackage) => {
				if (fetchJobAdvertQuestionsHttpPackage.result == null) {
					return;
				}

				this.questions = fetchJobAdvertQuestionsHttpPackage.result;
				this.createFormControls();
			});
	}
	get allQuestions() {
		return this.form.controls.allQuestions as FormArray;
	}

	createFormControls() {
		for (const question of this.questions) {
			const questionForm: FormGroup = this.formBuilder.group({
				id: [question.question.id],
				answer: [question.answer, [Validators.required]],
				question: [question.question.questionText],
			});
			questionForm.valueChanges
				.pipe(debounceTime(this.autoSaveAnswerDebounceTime), takeUntil(this.destroy$))
				.subscribe((value) => {
					this.store.dispatch(
						new FlinkerJobsAction.CreateJobApplication({
							jobAdvertId: this.jobAdvertId,
							createJobApplicationDto: {
								questionAnswers: [value],
								inProgress: true,
							},
						}),
					);
				});
			this.allQuestions.push(questionForm);
		}
	}

	onSubmit() {
		const formValues = this.form.value;
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = this.elementRef.nativeElement.querySelector('form .ng-invalid');
			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}
			return;
		}

		this.store.dispatch(
			new FlinkerJobsAction.CreateJobApplication({
				jobAdvertId: this.jobAdvertId,
				createJobApplicationDto: {
					questionAnswers: formValues.allQuestions,
					inProgress: false,
				},
			}),
		);

		this.createJobApplicationHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				this.notifierService.notify(NotificationType.Success, 'Successfully created a job application');
				this.openProfileCompletionDialog();
			});

		this.createJobApplicationHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.error != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe((response) => {
				if (response.error.message.includes('already exists')) {
					this.router.navigate(['/flinker-dashboard/profile']);
				}
			});
	}

	openProfileCompletionDialog() {
		const dialogRef = this.dialog.open(ProgressGaugeModalComponent, {
			width: '600px',
			maxWidth: '1000px',
			maxHeight: window.innerHeight + 'px',
			disableClose: true,
			data: {
				dontShowProfilePicture: true,
				modalHeader: 'Job Application Completed',
				buttonName: 'Go to your profile',
				message: 'You have successfully applied for this job but your profile is incomplete.',
				buttonLink: '/flinker-dashboard/profile',
			},
			id: 'progress-gauge-modal',
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
