import { Injectable } from '@angular/core';
import CreateSearchRequest from '@models/dto/requests/create-search-request.dto';
import UpdateSearchRequest from '@models/dto/requests/update-search-request.dto';
import { AdHocSubscriptionResponse } from '@models/dto/responses/adhoc-subscription-response.dto';
import SearchFlinkerResponse from '@models/dto/responses/search-flinker-response.dto';
import SearchResponse from '@models/dto/responses/search-response.dto';
import CreateSearchResponse from '@models/dto/responses/search-response.dto';
import SearchResultResponse from '@models/dto/responses/search-result-response.dto';
import FilterFlinkerRequestDto from '@models/dto/requests/filter-flinker-request.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';
import { FilterSearchResponse } from '@models/dto/responses/filter-search-response.dto';
import { SearchFilterRequest } from '@models/dto/requests/search-filter-request.dto';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	apiSearchEndpoint = 'Search';
	apiSubscriptionsEndpoint = 'subscriptions';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	createSearch(createSearchRequest: CreateSearchRequest) {
		return this.httpV2Service.postSecure<CreateSearchResponse>(`${this.apiSearchEndpoint}`, createSearchRequest);
	}

	updateSearch(searchId: string, updateSearchRequest: UpdateSearchRequest) {
		return this.httpV2Service.putSecure<SearchResponse>(`${this.apiSearchEndpoint}/${searchId}`, updateSearchRequest);
	}

	fetchActiveSearches() {
		return this.httpV2Service.getSecure<SearchResponse[]>(`${this.apiSearchEndpoint}/flinkco-searches`);
	}

	fetchActiveFilteredSearches(searchFilter: SearchFilterRequest) {
		return this.httpV2Service.postSecure<FilterSearchResponse>(`${this.apiSearchEndpoint}/filter`, searchFilter);
	}

	fetchActiveSearch(searchId: string) {
		return this.httpV2Service.getSecure<CreateSearchResponse>(`${this.apiSearchEndpoint}/${searchId}`);
	}

	getSearchResults(filterFlinkerRequest: FilterFlinkerRequestDto) {
		return this.httpV2Service.postSecure<SearchResultResponse>(
			`${this.apiSearchEndpoint}/${filterFlinkerRequest.searchId}/results`,
			filterFlinkerRequest,
		);
	}

	getFlinkerMatch(flinkerId: string, searchId: string) {
		return this.httpV2Service.getSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/match/${flinkerId}`,
		);
	}

	pinFlinkerMatch(flinkerId: string, searchId: string) {
		return this.httpV2Service.postSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/pin/${flinkerId}`,
			{},
		);
	}

	shortlistFlinker(flinkerId: string, searchId: string) {
		return this.httpV2Service.postSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/shortlist/${flinkerId}`,
			{},
		);
	}

	removeShortlistedFlinker(flinkerId: string, searchId: string) {
		return this.httpV2Service.deleteSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/shortlist/${flinkerId}`,
		);
	}

	thumbsUpFlinker(flinkerId: string, searchId: string) {
		return this.httpV2Service.postSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/thumbs-up/${flinkerId}`,
			{},
		);
	}

	thumbsDownFlinker(flinkerId: string, searchId: string) {
		return this.httpV2Service.postSecure<SearchFlinkerResponse>(
			`${this.apiSearchEndpoint}/${searchId}/thumbs-down/${flinkerId}`,
			{},
		);
	}

	getAdhocSubscription() {
		return this.httpV2Service.getSecure<AdHocSubscriptionResponse>(
			`${this.apiSubscriptionsEndpoint}/AdhocSubscription`,
		);
	}
}
