import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { Subject, Subscription } from 'rxjs';
import { FlinkerJobsAction, FlinkerJobsSelectors } from '@root-store/flinker/jobs';
import { FlinkerJobAdvertResponse } from '@models/dto/responses/flinker-job-advert-response.dto';
import { SelectOptionsStoreSelectors } from '@root-store/select-options-store';
import { SelectOptionsResponse } from '@models/dto/responses/select-options-response.dto';
import { JobAdvertResponseDto } from '@models/dto/responses/job-advert-response.dto';
import { JobApplicationResponse } from '@models/dto/responses/job-application-response.dto';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { JobAdvertsReferralComponent } from '@app/job-adverts/job-adverts-referral/job-adverts-referral.component';
import { NotificationType } from '@app/enums/notification-type.enum';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProgressGaugeModalComponent } from '@flinker-dashboard/components/profile/progress-gauge-modal/progress-gauge-modal.component';

@Component({
	selector: 'app-view-job-advert',
	templateUrl: './view-job-advert.component.html',
	styleUrls: ['./view-job-advert.component.scss'],
})
export class ViewJobAdvertComponent implements OnInit, OnDestroy {
	getFlinkerJobAdvertHttpPackage$ = this.store.select(FlinkerJobsSelectors.selectGetJobAdvertForFlinker);
	createInitialJobAdvertHttpPackage$ = this.store.select(FlinkerJobsSelectors.selectCreateInitialJobApplication);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	subscriptions = new Subscription();
	jobAdvertId: string;
	jobAdvert: JobAdvertResponseDto;
	jobApplicationResponse: JobApplicationResponse;
	applicationComplete: boolean;
	applicationStarted: boolean;
	jobAdvertResponse: FlinkerJobAdvertResponse;

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(
		private route: ActivatedRoute,
		private store: Store<RootStoreState.State>,
		private notifierService: NotifierService,
		private router: Router,
		public dialog: MatDialog,
	) {}

	ngOnInit(): void {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
			this.jobAdvertId = paramMap.get('jobAdvertId');
			this.store.dispatch(new FlinkerJobsAction.GetJobAdvertForFlinker({ jobAdvertId: this.jobAdvertId }));
		});

		this.selectOptionsHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((selectOptionsResponse) => {
			this.selectOptions = selectOptionsResponse.result;
		});

		this.getFlinkerJobAdvertHttpPackage$.pipe(takeUntil(this.destroy$)).subscribe((getFlinkerJobAdvertHttpPackage) => {
			if (getFlinkerJobAdvertHttpPackage.result !== null) {
				this.jobAdvert = getFlinkerJobAdvertHttpPackage.result.jobAdvert;
				this.jobAdvertResponse = getFlinkerJobAdvertHttpPackage.result;
				this.applicationComplete = getFlinkerJobAdvertHttpPackage.result.applicationCompleted;
				this.applicationStarted = getFlinkerJobAdvertHttpPackage.result.applicationStarted;
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	applyToJobAdvert() {
		if (this.applicationComplete) {
			this.openProfileCompletionDialog();
			return;
		}
		this.store.dispatch(new FlinkerJobsAction.CreateInitialJobApplication({ jobAdvertId: this.jobAdvertId }));
		this.createInitialJobAdvertHttpPackage$
			.pipe(
				filter((response) => !response.loading && response.result != null),
				take(1),
				takeUntil(this.destroy$),
			)
			.subscribe((createInitialJobAdvertHttpPackage) => {
				this.jobApplicationResponse = createInitialJobAdvertHttpPackage.result;
				if (this.jobApplicationResponse.applicationCompleted) {
					this.applicationComplete = true;
					this.notifierService.notify(NotificationType.Success, 'Successfully applied for job.');
					this.router.navigate(['/flinker-dashboard/profile']);
				} else {
					this.router.navigate([`/flinker-dashboard/job-application/${this.jobAdvert.id}`]);
				}
			});
	}

	openProfileCompletionDialog() {
		const dialogRef = this.dialog.open(ProgressGaugeModalComponent, {
			width: '600px',
			maxWidth: '1000px',
			maxHeight: window.innerHeight + 'px',
			data: {
				dontShowProfilePicture: true,
				modalHeader: 'Job Application Completed',
				buttonName: 'Go to your profile',
				message: 'You have successfully applied for this job but your profile is incomplete.',
				buttonLink: '/flinker-dashboard/profile',
			},
			id: 'progress-gauge-modal',
		});
	}

	continueToJobAdvert() {
		if (!this.applicationStarted || this.applicationComplete) {
			return;
		}

		this.router.navigate([`/flinker-dashboard/job-application/${this.jobAdvert.id}`]);
	}

	openReferralDialog() {
		const dialogRef = this.dialog.open(JobAdvertsReferralComponent, {
			maxWidth: '1000px',
			maxHeight: window.innerHeight + 'px',
			data: {
				jobAdvertId: this.jobAdvertId,
				referredByAuthedUser: true,
			},
			id: 'job-referral-dialog',
		});
	}
}
