<p class="font-size-8 font-demi-bold m-0 text-center" [ngClass]="theme">{{ label }}</p>
<div
	class="rating-container d-flex justify-content-center align-items-end w-100"
	tabindex="0"
	(keyup.arrowLeft)="onArrowKey(true)"
	(keyup.arrowRight)="onArrowKey()"
  [ngClass]="containerTheme"
>
	<p class="m-0">Poor</p>
	<div class="d-flex align-items-center justify-content-around track back-sub-11 mx-4">
		<div
			class="tick d-block back-sub-11"
			*ngFor="let rating of ratings; let i = index"
			[class.active]="value === i + 1"
			(click)="onSelect(i + 1)"
		>
			<div class="thumb px-3 py-1 back-sub-11 font-size-10 font-normal front-sub-5" *ngIf="value === i + 1">
				{{ rating.value }} - {{ rating.description }}
			</div>
		</div>
	</div>
	<p class="m-0">Excellent</p>
</div>
<span class="error my-2">
	{{ errorMessage }}
</span>
