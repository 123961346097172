import { Action } from '@ngrx/store';
import ErrorResponse from '../../models/dto/responses/error-response.dto';
import ReferenceQuestion from '../../models/dto/responses/reference-question.dto';

export enum ActionTypes {
	FETCH_ALL_REFERENCE_QUESTIONS = '[Flink Admin] Fetch all reference questions',
	FETCH_ALL_REFERENCE_QUESTIONS_SUCCESS = '[Flink Admin] Fetch all reference questions success',
	FETCH_ALL_REFERENCE_QUESTIONS_ERROR = '[Flink Admin] Fetch all reference questions error',

	UPDATE_REFERENCE_QUESTION = '[Flink Admin] Update reference question',
	UPDATE_REFERENCE_QUESTION_SUCCESS = '[Flink Admin] Update reference question success',
	UPDATE_REFERENCE_QUESTION_ERROR = '[Flink Admin] Update reference question error',

	CREATE_REFERENCE_QUESTION = '[Flink Admin] Create reference question',
	CREATE_REFERENCE_QUESTION_SUCCESS = '[Flink Admin] Create reference question success',
	CREATE_REFERENCE_QUESTION_ERROR = '[Flink Admin] Create reference question error',

	DELETE_REFERENCE_QUESTION = '[Flink Admin] Delete reference question',
	DELETE_REFERENCE_QUESTION_SUCCESS = '[Flink Admin] Delete reference question success',
	DELETE_REFERENCE_QUESTION_ERROR = '[Flink Admin] Delete reference question error',
}

export class FetchAllReferenceQuestions implements Action {
	readonly type = ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS;
	constructor() {}
}

export class FetchAllReferenceQuestionsSuccess implements Action {
	readonly type = ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS_SUCCESS;
	constructor(public payload: { result: ReferenceQuestion[] }) {} //CHANGE THIS
}

export class FetchAllReferenceQuestionsError implements Action {
	readonly type = ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateReferenceQuestion implements Action {
	readonly type = ActionTypes.UPDATE_REFERENCE_QUESTION;
	constructor(public payload: ReferenceQuestion) {}
}

export class UpdateReferenceQuestionSuccess implements Action {
	readonly type = ActionTypes.UPDATE_REFERENCE_QUESTION_SUCCESS;
	constructor(public payload: { result: ReferenceQuestion }) {}
}

export class UpdateReferenceQuestionError implements Action {
	readonly type = ActionTypes.UPDATE_REFERENCE_QUESTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class CreateReferenceQuestion implements Action {
	readonly type = ActionTypes.CREATE_REFERENCE_QUESTION;
	constructor(public payload: ReferenceQuestion) {}
}

export class CreateReferenceQuestionSuccess implements Action {
	readonly type = ActionTypes.CREATE_REFERENCE_QUESTION_SUCCESS;
	constructor(public payload: { result: ReferenceQuestion }) {}
}

export class CreateReferenceQuestionError implements Action {
	readonly type = ActionTypes.CREATE_REFERENCE_QUESTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteReferenceQuestion implements Action {
	readonly type = ActionTypes.DELETE_REFERENCE_QUESTION;
	constructor(public payload: { questionId: string }) {}
}

export class DeleteReferenceQuestionSuccess implements Action {
	readonly type = ActionTypes.DELETE_REFERENCE_QUESTION_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class DeleteReferenceQuestionError implements Action {
	readonly type = ActionTypes.DELETE_REFERENCE_QUESTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| CreateReferenceQuestion
	| CreateReferenceQuestionSuccess
	| CreateReferenceQuestionError
	| DeleteReferenceQuestion
	| DeleteReferenceQuestionSuccess
	| DeleteReferenceQuestionError
	| UpdateReferenceQuestion
	| UpdateReferenceQuestionSuccess
	| UpdateReferenceQuestionError
	| FetchAllReferenceQuestions
	| FetchAllReferenceQuestionsError
	| FetchAllReferenceQuestionsSuccess;
