import { Actions, ActionTypes } from './actions';
import { initialState, FlinkerTakeOnState } from './state';

export function flinkerTakeOnReducer(state = initialState, action: Actions): FlinkerTakeOnState {
	switch (action.type) {
		case ActionTypes.UPDATE_FLINKER:
			return {
				...state,
				flinker: action.payload.flinker,
			};
		case ActionTypes.GET_FLINKER:
			return {
				...state,
				flinkerHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKER_SUCCESS:
			return {
				...state,
				flinker: action.payload.flinkerResponse,
				flinkerHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.flinkerResponse,
				},
			};
		case ActionTypes.GET_FLINKER_ERROR:
			return {
				...state,
				flinkerHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS:
			return {
				...state,
				flinkerPersonalDetailsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				flinkerPersonalDetailsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_PERSONAL_DETAILS_ERROR:
			return {
				...state,
				flinkerPersonalDetailsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS:
			return {
				...state,
				flinkerEmploymentDetailsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				flinkerEmploymentDetailsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EMPLOYMENT_DETAILS_ERROR:
			return {
				...state,
				flinkerEmploymentDetailsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS:
			return {
				...state,
				flinkerEducationalDetailsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				flinkerEducationalDetailsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_EDUCATIONAL_DETAILS_ERROR:
			return {
				...state,
				flinkerEducationalDetailsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION:
			return {
				...state,
				flinkerCriminalDeclarationHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				flinkerCriminalDeclarationHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_CRIMINAL_DECLARATION_ERROR:
			return {
				...state,
				flinkerCriminalDeclarationHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS:
			return {
				...state,
				flinkerTermsAndConditionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				flinkerTermsAndConditionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SUBMIT_FLINKER_TERMS_AND_CONDITIONS_ERROR:
			return {
				...state,
				flinkerTermsAndConditionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_ID_DOCUMENT:
			return {
				...state,
				uploadIdDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_ID_DOCUMENT_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				uploadIdDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_ID_DOCUMENT_ERROR:
			return {
				...state,
				uploadIdDocumentHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_PASSPORT_DOCUMENT:
			return {
				...state,
				uploadPassportDocumentHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_PASSPORT_DOCUMENT_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				uploadPassportDocumentHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_PASSPORT_DOCUMENT_ERROR:
			return {
				...state,
				uploadPassportDocumentHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS:
			return {
				...state,
				uploadEmploymentDocumentsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				uploadEmploymentDocumentsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_EMPLOYMENT_DOCUMENTS_ERROR:
			return {
				...state,
				uploadEmploymentDocumentsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS:
			return {
				...state,
				uploadQualificationDocumentsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS_SUCCESS:
			return {
				...state,
				flinker: action.payload.result,
				uploadQualificationDocumentsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_QUALIFICATION_DOCUMENTS_ERROR:
			return {
				...state,
				uploadQualificationDocumentsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_PROFILE_PICTURE:
			return {
				...state,
				flinker: {
					...state.flinker,
					picture: null,
				},
				uploadProfilePictureHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS:
			return {
				...state,
				flinker: {
					...state.flinker,
					picture: action.payload.result + '?' + Math.floor(Math.random() * 100) + 1,
				},
				uploadProfilePictureHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.UPLOAD_PROFILE_PICTURE_ERROR:
			return {
				...state,
				uploadProfilePictureHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_PROFILE_COMPLETION:
			return {
				...state,
				profileCompletionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_PROFILE_COMPLETION_SUCCESS:
			return {
				...state,
				profileCompletionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_PROFILE_COMPLETION_ERROR:
			return {
				...state,
				profileCompletionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CREATE_REACTIVATION_REQUEST:
			return {
				...state,
				createReactivationRequestHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_REACTIVATION_REQUEST_SUCCESS:
			return {
				...state,
				createReactivationRequestHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.CREATE_REACTIVATION_REQUEST_ERROR:
			return {
				...state,
				createReactivationRequestHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_REACTIVATION_REQUEST:
			return {
				...state,
				getReactivationRequestHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_REACTIVATION_REQUEST_SUCCESS:
			return {
				...state,
				getReactivationRequestHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_REACTIVATION_REQUEST_ERROR:
			return {
				...state,
				getReactivationRequestHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_LIST:
			return {
				...state,
				getFlinkCosListHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.GET_FLINKCO_LIST_SUCCESS:
			return {
				...state,
				getFlinkCosListHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.GET_FLINKCO_LIST_ERROR:
			return {
				...state,
				getFlinkCosListHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.SET_FLINKER_CV_PROMOTION_DATE:
			return {
				...state,
				setFlinkerCVPromotionDateHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.SET_FLINKER_CV_PROMOTION_DATE_SUCCESS:
			return {
				...state,
				setFlinkerCVPromotionDateHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.SET_FLINKER_CV_PROMOTION_DATE_ERROR:
			return {
				...state,
				setFlinkerCVPromotionDateHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
