import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	PsychAssessmentCompletionStatusActions,
	PsychAssessmentCompletionStatusSelectors,
} from '@root-store/psych-assessment-store';
import { RootStoreState } from '@root-store';

@Component({
	selector: 'app-profile-completion',
	templateUrl: './profile-completion.component.html',
	styleUrls: ['./profile-completion.component.scss'],
})
export class ProfileCompletionComponent implements OnInit {
	psychAssessmentCompletionStatusHttpPackage$ = this.store.select(
		PsychAssessmentCompletionStatusSelectors.selectPsychAssessmentCompletionStatusHttpPackage,
	);

	constructor(private store: Store<RootStoreState.State>) {}

	ngOnInit(): void {
		this.store.dispatch(new PsychAssessmentCompletionStatusActions.GetPsychAssessmentCompletionStatus());
	}
}
