import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CvStoreActions } from '.';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { CvService } from '@app/services/cv/cv.service';
import { DownloadMyFlinkerCv, GetCvFeatures, GetFlinkerCVPaymentStatus, PayForCv } from './actions';
import CvFeatureResponseDto from '@models/dto/responses/cv-feature-response.dto';

@Injectable()
export class CvStoreEffects {
	constructor(private actions$: Actions, private readonly cvService: CvService) {}

	getCvFeaturesEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvStoreActions.GetCvFeatures>(CvStoreActions.ActionTypes.GET_CV_FEATURES),
			switchMap((action: GetCvFeatures) =>
				this.cvService.getCvFeatures().pipe(
					map((response: CvFeatureResponseDto[]) => {
						return new CvStoreActions.GetCvFeaturesSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to get CV features.', error);
						return of(new CvStoreActions.GetCvFeaturesError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	getCvPaymentStatusEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvStoreActions.GetFlinkerCVPaymentStatus>(CvStoreActions.ActionTypes.GET_FLINKER_CV_PAYMENT_STATUS),
			switchMap((action: GetFlinkerCVPaymentStatus) =>
				this.cvService.getCvPaymentStatus().pipe(
					map((response: any) => {
						return new CvStoreActions.GetFlinkerCVPaymentStatusSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to get Flinker CV payment status.', error);
						return of(new CvStoreActions.GetFlinkerCVPaymentStatusError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	payForCvEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvStoreActions.PayForCv>(CvStoreActions.ActionTypes.PAY_FOR_CV),
			switchMap((action: PayForCv) =>
				this.cvService.payForCv(action.payload).pipe(
					map((response: any) => {
						return new CvStoreActions.PayForCvSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to initialise payment for Flinker CV.', error);
						return of(new CvStoreActions.PayForCvError({ errorResponse: error }));
					}),
				),
			),
		);
	});

	downloadFlinkerCvEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<CvStoreActions.DownloadMyFlinkerCv>(CvStoreActions.ActionTypes.DOWNLOAD_MY_FLINKER_CV),
			switchMap((action: DownloadMyFlinkerCv) =>
				this.cvService.downloadFlinkerCv().pipe(
					map((response: any) => {
						return new CvStoreActions.DownloadMyFlinkerCvSuccess({ result: response });
					}),
					catchError((error: ErrorResponse) => {
						console.error('Failed to download Flinker CV', error);
						return of(new CvStoreActions.DownloadMyFlinkerCvError({ errorResponse: error }));
					}),
				),
			),
		);
	});
}
