import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlinkerDashboardRoutingModule } from './flinker-dashboard-routing.module';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SharedModule } from 'projects/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//NG Material Imports
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { PersonalContainerComponent } from './components/profile/personal-container/personal-container.component';
import { JobStatusComponent } from './components/profile/job-status/job-status.component';
import { ApplyingToComponent } from './components/profile/applying-to/applying-to.component';
import { ProfileExperienceComponent } from './components/profile/experience/experience.component';
import { EducationComponent } from './components/profile/education/education.component';
import { SkillsComponent } from './components/profile/skills/skills.component';
import { ProgressGaugeComponent } from './components/profile/progress-gauge/progress-gauge.component';
import { FlinkerOffersComponent } from './components/offers/flinker-offers/flinker-offers.component';
import { InterviewsComponent } from './components/interviews/interviews/interviews.component';
import { InterviewDetailedComponent } from './components/interviews/interview-detailed/interview-detailed.component';
import { MatTableModule } from '@angular/material/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RejectInterviewDialogComponent } from './components/dialogs/reject-interview-dialog/reject-interview-dialog.component';
import { FlinkerOfferDetailedComponent } from './components/offers/flinker-offer-detailed/flinker-offer-detailed.component';
import { UpcomingInterviewsComponent } from './components/interviews/upcoming-interviews/upcoming-interviews.component';
import { PastInterviewsComponent } from './components/interviews/past-interviews/past-interviews.component';
import { ChurningStatusComponent } from './components/profile/churning-status/churning-status.component';
import { AvatarModule } from 'ngx-avatar';
import { CommonComponentsModule } from '../common/common.module';
import { ProfileCompletionComponent } from './components/profile/profile-completion/profile-completion.component';
import { ReactivationModalComponent } from './components/wrapper/reactivation-modal/reactivation-modal.component';
import { FlinkerCvDownloadComponent } from './components/cv/flinker-cv-download/flinker-cv-download.component';
import { MatRadioModule } from '@angular/material/radio';
import { FlinkerCvPaymentDialogComponent } from './components/dialogs/flinker-cv-payment-dialog/flinker-cv-payment-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { JobApplicationComponent } from './components/jobs/job-application/job-application.component';
import { ViewFlinkerJobAdvertsComponent } from './components/jobs/view-flinker-job-adverts/view-flinker-job-adverts.component';
import { ViewJobAdvertComponent } from './components/jobs/view-job-advert/view-job-advert.component';
import { VideoRecorderComponent } from './components/jobs/video-recorder/video-recorder.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CvPurchaseModalComponent } from './components/profile/cv-purchase-modal/cv-purchase-modal.component';
import { ProgressGaugeModalComponent } from './components/profile/progress-gauge-modal/progress-gauge-modal.component';

@NgModule({
	declarations: [
		WrapperComponent,
		ProfileComponent,
		CalendarComponent,
		PersonalContainerComponent,
		JobStatusComponent,
		ApplyingToComponent,
		ProfileExperienceComponent,
		EducationComponent,
		SkillsComponent,
		ProgressGaugeComponent,
		FlinkerOffersComponent,
		InterviewsComponent,
		InterviewDetailedComponent,
		RejectInterviewDialogComponent,
		FlinkerOfferDetailedComponent,
		UpcomingInterviewsComponent,
		PastInterviewsComponent,
		ChurningStatusComponent,
		ProfileCompletionComponent,
		ReactivationModalComponent,
		FlinkerCvDownloadComponent,
		FlinkerCvPaymentDialogComponent,
		JobApplicationComponent,
		ViewFlinkerJobAdvertsComponent,
		ViewJobAdvertComponent,
		VideoRecorderComponent,
		CvPurchaseModalComponent,
		ProgressGaugeModalComponent,
	],
	imports: [
		CommonModule,
		FlinkerDashboardRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		NgxSkeletonLoaderModule,

		//NG Material Modules
		MatToolbarModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		MatButtonModule,
		MatCardModule,
		MatInputModule,
		MatFormFieldModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatExpansionModule,
		MatChipsModule,
		MatTableModule,
		AvatarModule,
		CommonComponentsModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		MatSortModule,
		MatTooltipModule,
		MatPaginatorModule,
	],
	exports: [],
})
export class FlinkerDashboardModule {}
