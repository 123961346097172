<div class='p-1 p-md-4'>
  <app-dashboard-section-header icon='next_week' title='Experience' [editIcon]='true' (edit)='toggleEditState()'>
  </app-dashboard-section-header>

  <div>
    <app-experience [isTakeOn]='false' *ngIf='editState' [allowReferenceUpdate]='false'></app-experience>
    <app-experience-profile *ngIf='!editState'></app-experience-profile>
    <div class='row my-4' *ngIf='editState'>
      <app-square-button
        theme='m1'
        value='Update'
        type='button'
        class='d-flex justify-content-center col-6 offset-3'
        [loading]='(saveLoadingObservable | async)?.loading'
        [disabled]='(saveLoadingObservable | async)?.loading'
        (click)='submit()'
      >
      </app-square-button>
    </div>
  </div>
</div>
