<div class="container-row">
	<app-heading theme="m1" heading="Reset Password"></app-heading>
</div>
<app-loader *ngIf="loading"></app-loader>
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!loading && !resetSuccessful && !error">
	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			placeholder="Password"
			icon="lock"
			type="password"
			formControlName="password"
			[errorMessage]="getErrorMessage(form.controls.password, 'Password', shouldValidate)"
		></app-rounded-input>
	</div>
	<div class="container-row">
		<app-rounded-input
			theme="gh-m-1"
			placeholder="Confirm Password"
			icon="lock"
			type="password"
			formControlName="confirmPassword"
			[errorMessage]="getErrorMessage(form.controls.confirmPassword, 'Confirm Password')"
		></app-rounded-input>
	</div>
	<div class="container-row">
		<app-rounded-button theme="m1" type="submit" value="Reset Password" class="my-2"> </app-rounded-button>
		<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
	</div>
</form>
<div class="d-flex flex-column justify-content-center align-items-center mt-3" *ngIf="resetSuccessful">
	<h4>Password reset successful</h4>
	<mat-icon class="front-main-3 mb-3">check</mat-icon>
	<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
</div>
<div class="d-flex flex-column justify-content-center align-items-center mt-3" *ngIf="error">
	<h4>It looks like your password reset expired, please enter your email address to reset your password</h4>
	<mat-icon class="front-main-5 mb-3">x</mat-icon>
	<form [formGroup]="emailForm" (ngSubmit)="submitRequest()" class="w-100">
		<div class="container-row">
			<app-rounded-input
				theme="gh-m-1"
				type="email"
				placeholder="Email"
				icon="supervisor_account"
				formControlName="email"
				[errorMessage]="getErrorMessage(form.controls.email, 'Email', shouldValidate)"
			>
			</app-rounded-input>
		</div>
		<div class="container-row">
			<app-rounded-button theme="m1" type="submit" value="Send email" class="my-2"> </app-rounded-button>
		</div>
	</form>
	<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
	<div class="d-flex flex-column justify-content-center align-items-center mt-3" *ngIf="requestSuccessful">
		<h4>Password reset email sent to {{ emailForm.controls.email.value }}</h4>
		<mat-icon class="front-main-3 mb-3">check</mat-icon>
		<app-link routerLink="sign-in" theme="s3" value="Return to Sign In"></app-link>
	</div>
</div>
