import InterviewResponse from '@models/dto/responses/interview-response.dto';
import HttpPackage from '@models/http-package';
import InterviewerWithInterviewQuestionsResponse from '@models/dto/responses/interviewer-with-interview-questions-response.dto';
import InterviewQuestionsScoreTableResponse from '@models/dto/responses/interview-questions-score-table-response.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';

export interface InterviewState {
	createInviteHttpPackage: HttpPackage<string>;
	getFlinkCoInterviewsHttpPackage: HttpPackage<PaginatedResponse<InterviewResponse>>;
	getFlinkerInterviewsHttpPackage: HttpPackage<InterviewResponse[]>;
	getFlinkCoInterviewHttpPackage: HttpPackage<InterviewResponse>;
	getFlinkerInterviewHttpPackage: HttpPackage<InterviewResponse>;
	cancelInterviewHttpPackage: HttpPackage<string>;
	scheduleInterviewHttpPackage: HttpPackage<string>;
	updateInterviewStatusHttpPackage: HttpPackage<string>;
	downloadFlinkerCv: HttpPackage<any>;
	getCurrentInterviewerWithInterviewQuestionsHttpPackage: HttpPackage<InterviewerWithInterviewQuestionsResponse>;
	getInterviewQuestionsScoreTableHttpPackage: HttpPackage<InterviewQuestionsScoreTableResponse>;
}

export const initialState: InterviewState = {
	createInviteHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkCoInterviewsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkerInterviewsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkCoInterviewHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getFlinkerInterviewHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	cancelInterviewHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	scheduleInterviewHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	updateInterviewStatusHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	downloadFlinkerCv: {
		error: null,
		loading: false,
		result: null,
	},
	getCurrentInterviewerWithInterviewQuestionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	getInterviewQuestionsScoreTableHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
