import { Injectable } from '@angular/core';
import CreateReactivationRequest from '../../models/dto/requests/create-reactivation-request.dto';
import { FlinkCoNamesResponse } from '@models/dto/responses/flinkCo-names-response.dto';
import FlinkerResponse from '../../models/dto/responses/flinker-response.dto';
import ProfileCompletionItem from '../../models/dto/responses/profile-completion-item.dto';
import ReactivationRequestResponse from '../../models/dto/responses/reactivation-request-response.dto';
import { HttpV2Service } from '@app/services/http/http-v2.service';

@Injectable({
	providedIn: 'root',
})
export class FlinkerService {
	apiFlinkerEndpoint = 'flinker';

	constructor(private readonly httpV2Service: HttpV2Service) {}

	getFlinker() {
		return this.httpV2Service.getSecure<FlinkerResponse>(`${this.apiFlinkerEndpoint}`);
	}

	getProfileCompletionItems() {
		return this.httpV2Service.getSecure<ProfileCompletionItem[]>(`${this.apiFlinkerEndpoint}/profileCompletion`);
	}

	createReactivationRequest(createReactivationRequest: CreateReactivationRequest) {
		return this.httpV2Service.postSecure<void>(
			`${this.apiFlinkerEndpoint}/reactivation/request`,
			createReactivationRequest,
		);
	}

	getReactivationRequest() {
		return this.httpV2Service.getSecure<ReactivationRequestResponse>(`${this.apiFlinkerEndpoint}/reactivation`);
	}

	getFlinkCoNames() {
		return this.httpV2Service.getSecure<FlinkCoNamesResponse[]>(`${this.apiFlinkerEndpoint}/flinkCos`);
	}

	setCvPromotionDate() {
		return this.httpV2Service.putSecure<FlinkerResponse>(`${this.apiFlinkerEndpoint}/cvPromotion`, {});
	}
}
