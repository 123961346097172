<div class='container mw-100 min-vh-100 p-1 p-md-5'>
  <mat-card class='py-md-3 px-md-5'>
    <app-page-heading class='py-3' theme='f4' heading='Job Application'></app-page-heading>
    <p class='font-medium mt-1 font-size-11'>Please complete interview questions</p>

    <form class='mt-3' [formGroup]='form'>
      <ng-container formArrayName='allQuestions'>
        <ng-container *ngFor='let question of allQuestions?.controls; let i = index'>
          <div class='row' [formGroupName]='i'>
            <ng-container *ngIf='questions[i]?.question?.textQuestion !== null'>
              <app-outlined-input
                class='col-12'
                [labelText]='question?.value?.question'
                formControlName='answer'
                [errorMessage]="getErrorMessage(form.controls.answer, 'Answer', shouldValidate)"
              >
              </app-outlined-input>
            </ng-container>
            <ng-container *ngIf='questions[i]?.question?.videoQuestion !== null'>
              <div class='col-12 w-100'>
                <app-video-recorder
                  [labelText]='question?.value?.question'
                  [questionId]='question?.value?.id'
                  [existingRecording]='question?.value?.answer'
                  formControlName='answer'
                  class='w-100'
                ></app-video-recorder>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class='row col-12 m-0 p-0 justify-content-between flex-sm-column flex-md-row w-100 mt-4'>
        <div class='col-12 col-md-1 p-0'>
          <app-square-button
            value='Cancel'
            [routerLink]="['../../job-advert', jobAdvertId]"
            theme='bl-m5 bold'
          ></app-square-button>
        </div>
        <div class='col-0 col-md-7'></div>
        <div class='col-12 col-md-4'>
          <app-square-button (click)='onSubmit()' value='Send Application' theme='m1 bold'
                             [disabled]='(createJobApplicationHttpPackage$ | async)?.loading'
                             [loading]='(createJobApplicationHttpPackage$ | async)?.loading'></app-square-button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
