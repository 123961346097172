import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-rounded-checkbox',
	templateUrl: './rounded-checkbox.component.html',
	styleUrls: ['./rounded-checkbox.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RoundedCheckboxComponent),
			multi: true,
		},
	],
})
export class RoundedCheckboxComponent implements OnInit, ControlValueAccessor {
	rememberMe = false;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	constructor() {}

	ngOnInit(): void {}

	public writeValue(value: boolean): void {
		this.rememberMe = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	rememberMeToggle() {
		this.rememberMe = !this.rememberMe;
		this.onChange(this.rememberMe);
	}
}
