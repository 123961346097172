import { Directive, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { RegexService } from '@shared/services/regex.service';

@Directive({
	selector: '[appInputTransform]',
})
export class InputTransformDirective {
	@Input('shouldTrim') set shouldTrim(shouldTrim: boolean) {
		if (!shouldTrim) {
			return;
		}
		trimValueAccessor(this.ngControl.valueAccessor);
	}
	constructor(private ngControl: NgControl) {}
}

function trimValueAccessor(valueAccessor: ControlValueAccessor) {
	const original = valueAccessor.registerOnChange;

	valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
		return original.call(valueAccessor, (value: unknown) => {
			const trimmedValue: any =
				typeof value === 'string' ? value.replace(RegexService.trimWhiteSpacesOnEdges(), '') : value;
			valueAccessor.writeValue(trimmedValue);
			return fn(trimmedValue);
		});
	};
}
