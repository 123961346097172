import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '@app/services/auth/auth.service';
import { getErrorMessage } from '@app/utils/form.util';
import { MicrosoftLoginProvider, SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { FlinkerTakeOnStoreSelectors } from '@root-store/flinker-take-on-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@root-store';
import { Subscription } from 'rxjs';
import { AccountType } from '@app/enums/account-type.enum';
import { SsoType } from '@app/enums/sso-type.enum';
import { ToggleSwitchOption } from '@shared/switch-toggle/switch-toggle.component';
import { maxFlinkerNameOrSurnameLength } from '@models/constants';
import { RegexService } from '@shared/services/regex.service';
import { flinkerNameAndSurnameFormatterHelper } from '@shared/helpers/flinker-name-and-surname-formatter.helper';
import { NGXLogger } from 'ngx-logger';
import { contactNumberValidator } from '@app/utils/custom-validators.util';

@Component({
	selector: 'app-flinker-register',
	templateUrl: './flinker-register.component.html',
	styleUrls: ['./flinker-register.component.scss'],
})
export class FlinkerRegisterComponent implements OnInit, OnDestroy {
	isLoading = false;
	subscription = new Subscription();
	form: FormGroup;
	getErrorMessage = getErrorMessage;
	flinkerHttpPackage$ = this.store.select(FlinkerTakeOnStoreSelectors.selectFlinkerHttpPackage);
	referenceNumber: string;
	shouldValidate = false;
	formValues: any;
	toggleSwitchOptions: ToggleSwitchOption[] = [
		{
			label: 'Apply',
		},
		{
			label: 'Access & Hire Talent',
		},
	];

	constructor(
		formBuilder: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private authSocialService: SocialAuthService,
		private store: Store<RootStoreState.State>,
		private route: ActivatedRoute,
		private readonly logger: NGXLogger,
		private location: Location,
	) {
		this.form = formBuilder.group({
			name: [
				'',
				[
					Validators.required,
					Validators.pattern(RegexService.validNameWithSpaces()),
					Validators.maxLength(maxFlinkerNameOrSurnameLength),
				],
			],
			surname: [
				'',
				[
					Validators.required,
					Validators.pattern(RegexService.validNameWithSpaces()),
					Validators.maxLength(maxFlinkerNameOrSurnameLength),
				],
			],
			email: ['', [Validators.required, Validators.email]],
			cellphoneNumber: ['', [Validators.required, contactNumberValidator()]],
			password: ['', [Validators.required, Validators.pattern(RegexService.validPasswordFormat())]],
		});
	}

	ngOnInit(): void {
		this.subscription.add(
			this.route.queryParams.subscribe((params: Params) => {
				if (params['referenceNumber']) {
					this.referenceNumber = params['referenceNumber'];
				}
			}),
		);

		this.formValues = this.router.getCurrentNavigation()?.extras?.state?.formValue;
		if (this.formValues) {
			this.form.patchValue(this.formValues);
		}
	}

	toggleSwitch(value: number): void {
		switch (value) {
			case 0:
				this.router.navigate(['/auth/flinker-registration']);
				break;
			case 1:
				this.router.navigate(['/auth/flinkco-registration']);
				break;
			default:
				this.router.navigate(['/auth/flinker-registration']);
		}
	}

	onGoogleSignIn(): void {
		this.isLoading = true;

		this.authSocialService
			.signIn(GoogleLoginProvider.PROVIDER_ID)
			.then((data) => {
				this.authService
					.sso({
						idToken: data.idToken,
						accountType: AccountType.Flinker,
						signUp: true,
						rememberMe: true,
						ssoType: SsoType.Google,
						referenceNumber: this.referenceNumber,
					})
					.subscribe(
						(_: SignInResponse) => {
							this.onSignInSuccess();
						},
						(err: ErrorResponse) => {
							this.isLoading = false;
							this.logger.error('Google social login was not successful.', err);
						},
					);
			})
			.catch((err) => {
				this.isLoading = false;
				this.logger.error('An error has occurred from the Google social login', err);
			});
	}

	onMicrosoftSignIn(): void {
		this.isLoading = true;

		this.authSocialService
			.signIn(MicrosoftLoginProvider.PROVIDER_ID)
			.then((data) => {
				this.authService
					.sso({
						idToken: data.idToken,
						authToken: data.authToken,
						accountType: AccountType.Flinker,
						signUp: true,
						rememberMe: true,
						ssoType: SsoType.Microsoft,
						referenceNumber: this.referenceNumber,
					})
					.subscribe(
						(_: SignInResponse) => {
							this.onSignInSuccess();
						},
						(err: ErrorResponse) => {
							this.isLoading = false;
							this.logger.error('Microsoft social login was not successful.', err);
						},
					);
			})
			.catch((err) => {
				this.isLoading = false;
				this.logger.error('An error has occurred from the Microsoft social login', err);
			});
	}

	signUp(): void {
		if (!this.form.valid) {
			this.shouldValidate = true;
			const firstElementWithError = document.querySelector('form .ng-invalid');

			if (firstElementWithError) {
				firstElementWithError.scrollIntoView({ behavior: 'smooth' });
			}

			return;
		}
		const formValues = this.form.value;
		this.isLoading = true;

		this.authService
			.signUp({
				...flinkerNameAndSurnameFormatterHelper(this.form.controls.name.value, this.form.controls.surname.value),
				email: formValues.email,
				password: formValues.password,
				accountType: AccountType.Flinker,
				cellphoneNumber: formValues.cellphoneNumber,
				rememberMe: true,
				referenceNumber: this.referenceNumber,
			})
			.subscribe(
				(_) => {
					this.router.navigate(['../otp'], { relativeTo: this.route, state: { email: formValues.email } });
				},
				(err: ErrorResponse) => {
					this.isLoading = false;
					this.logger.error('Flinker sign up was not successful.', err);
				},
			);
	}

	onSignInSuccess(): void {
		this.router.navigate(['take-on/flinker']);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
