import ReferenceQuestionsResponse from '../../models/dto/responses/reference-questions-response.dto';
import HttpPackage from '../../models/http-package';

export interface ReferenceState {
	getReferenceQuestionsHttpPackage: HttpPackage<ReferenceQuestionsResponse>;
	submitReferenceScoreHttpPackage: HttpPackage<string>;
}

export const initialState: ReferenceState = {
	getReferenceQuestionsHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
	submitReferenceScoreHttpPackage: {
		error: null,
		loading: false,
		result: null,
	},
};
