import { Action } from '@ngrx/store';
import FlinkerAdminResponse from '@flinkadmin-dashboard/models/response/flinker-admin-response.dto';
import { FlinkerReferenceScoreRequest } from '@flinkadmin-dashboard/models/response/flinker-reference-score-request.dto';
import ReactivationRequestResponse from '@flinkadmin-dashboard/models/response/reactivation-requests-response.dto';
import UpdateReactivationRequestStatusRequest from '@models/dto/requests/update-reactivation-request-status.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import FlinkerReferenceScore from '@models/dto/responses/flinker-reference-score.dto';
import PaginatedResponse from '@models/dto/responses/paginated-response.dto';
import FlinkerAdminFilterRequest from '@models/dto/requests/flinker-admin-filter-request.dto';
import SignInResponse from '@models/dto/responses/sign-in-response.dto';
import DetailedFlinkerAdminResponse from '@flinkadmin-dashboard/models/response/detailed-flinker-admin-response.dto';
import { FlinkerDocumentType } from '@app/enums/flinker-document-type.enum';

export enum ActionTypes {
	FETCH_ALL_FLINKERS = '[Flink Admin] Fetch all flinkers',
	FETCH_ALL_FLINKERS_SUCCESS = '[Flink Admin] Fetch all flinkers success',
	FETCH_ALL_FLINKERS_ERROR = '[Flink Admin] Fetch all flinkers error',

	GET_DETAILED_FLINKER = '[Flink Admin] Get matched flinker',
	GET_DETAILED_FLINKER_SUCCESS = '[Flink Admin] Get matched flinker success',
	GET_DETAILED_FLINKER_ERROR = '[Flink Admin] Get matched flinker error',

	UPDATE_FLINKER = '[Flink Admin] Update flinker',
	UPDATE_FLINKER_SUCCESS = '[Flink Admin] Update flinker success',
	UPDATE_FLINKER_ERROR = '[Flink Admin] Update flinker error',

	FETCH_ALL_REACTIVATION_REQUESTS = '[Flink Admin] Fetch all reactivation requests',
	FETCH_ALL_REACTIVATION_REQUESTS_SUCCESS = '[Flink Admin] Fetch all reactivation requests success',
	FETCH_ALL_REACTIVATION_REQUESTS_ERROR = '[Flink Admin] Fetch all reactivation requests error',

	UPDATE_REACTIVATION_REQUEST_STATUS = '[Flink Admin] Update reactivation request status',
	UPDATE_REACTIVATION_REQUEST_STATUS_SUCCESS = '[Flink Admin] Update reactivation request status success',
	UPDATE_REACTIVATION_REQUEST_STATUS_ERROR = '[Flink Admin] Update reactivation request status error',

	FETCH_FLINKER_REFERENCE_SCORE = '[Flink Admin] Fetch flinker reference score',
	FETCH_FLINKER_REFERENCE_SCORE_SUCCESS = '[Flink Admin] Fetch flinker reference score success',
	FETCH_FLINKER_REFERENCE_SCORE_ERROR = '[Flink Admin] Fetch flinker reference score error',

	LOGIN_AS_FLINKER = '[Flink Admin] Login as flinker',
	LOGIN_AS_FLINKER_SUCCESS = '[Flink Admin] Login as flinker success',
	LOGIN_AS_FLINKER_ERROR = '[Flink Admin] Login as flinker error',

	FETCH_FLINKER_DOCUMENTS = '[Flink Admin] Fetch flinker documents',
	FETCH_FLINKER_DOCUMENTS_SUCCESS = '[Flink Admin] Fetch flinker documents success',
	FETCH_FLINKER_DOCUMENTS_ERROR = '[Flink Admin] Fetch flinker documents error',

	DELETE_FLINKER = '[Flink Admin] Delete flinker',
	DELETE_FLINKER_SUCCESS = '[Flink Admin] Delete flinker success',
	DELETE_FLINKER_ERROR = '[Flink Admin] Delete flinker error',

	DOWNLOAD_FLINKER_CV = '[Flink Admin] Download flinker CV',
	DOWNLOAD_FLINKER_CV_SUCCESS = '[Flink Admin] Download flinker CV success',
	DOWNLOAD_FLINKER_CV_ERROR = '[Flink Admin] Download flinker CV error',
	DOWNLOAD_FLINKER_CV_CLEAR_STATE = '[Flink Admin] Download flinker CV clear state',

	DOWNLOAD_ADMIN_REPORT = '[FLink Admin] Download admin report',
	DOWNLOAD_ADMIN_REPORT_SUCCESS = '[FLink Admin] Download admin report success',
	DOWNLOAD_ADMIN_REPORT_ERROR = '[FLink Admin] Download admin report error',
	DOWNLOAD_ADMIN_REPORT_CLEAR_STATE = '[Flink Admin] Download admin report clear state',

	DOWNLOAD_OBFUSCATED_FLINKER_CV = '[Flink Admin] Download obfuscated flinker CV',
	DOWNLOAD_OBFUSCATED_FLINKER_CV_SUCCESS = '[Flink Admin] Download obfuscated flinker CV success',
	DOWNLOAD_OBFUSCATED_FLINKER_CV_ERROR = '[Flink Admin] Download obfuscated flinker CV error',
	DOWNLOAD_OBFUSCATED_FLINKER_CV_CLEAR_STATE = '[Flink Admin] Download obfuscated flinker CV clear state',
}

export class FetchAllFlinkers implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKERS;
	constructor(
		public payload: { pageSize: number; pageNumber: number; flinkerAdminFilter: FlinkerAdminFilterRequest },
	) {}
}

export class FetchAllFlinkersSuccess implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKERS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<FlinkerAdminResponse> }) {} //CHANGE THIS
}

export class FetchAllFlinkersError implements Action {
	readonly type = ActionTypes.FETCH_ALL_FLINKERS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateFlinker implements Action {
	readonly type = ActionTypes.UPDATE_FLINKER;
	constructor(public payload: { flinker: FlinkerReferenceScoreRequest }) {}
}

export class UpdateFlinkerSuccess implements Action {
	readonly type = ActionTypes.UPDATE_FLINKER_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UpdateFlinkerError implements Action {
	readonly type = ActionTypes.UPDATE_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchAllReactivationRequests implements Action {
	readonly type = ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS;
	constructor(public payload: { pageSize: number; pageNumber: number }) {}
}

export class FetchAllReactivationRequestsSuccess implements Action {
	readonly type = ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS_SUCCESS;
	constructor(public payload: { result: PaginatedResponse<ReactivationRequestResponse> }) {}
}

export class FetchAllReactivationRequestsError implements Action {
	readonly type = ActionTypes.FETCH_ALL_REACTIVATION_REQUESTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UpdateReactivationRequestStatus implements Action {
	readonly type = ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS;
	constructor(public payload: UpdateReactivationRequestStatusRequest) {}
}

export class UpdateReactivationRequestStatusSuccess implements Action {
	readonly type = ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UpdateReactivationRequestStatusError implements Action {
	readonly type = ActionTypes.UPDATE_REACTIVATION_REQUEST_STATUS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetDetailedFlinker implements Action {
	readonly type = ActionTypes.GET_DETAILED_FLINKER;
	constructor(public payload: { flinkerId: string }) {}
}
export class GetDetailedFlinkerSuccess implements Action {
	readonly type = ActionTypes.GET_DETAILED_FLINKER_SUCCESS;
	constructor(public payload: { result: DetailedFlinkerAdminResponse }) {}
}
export class GetDetailedFlinkerError implements Action {
	readonly type = ActionTypes.GET_DETAILED_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkerReferenceScore implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_REFERENCE_SCORE;
	constructor(public payload: { flinkerId: string }) {}
}
export class FetchFlinkerReferenceScoreSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_REFERENCE_SCORE_SUCCESS;
	constructor(public payload: { result: FlinkerReferenceScore }) {}
}
export class FetchFlinkerReferenceScoreError implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_REFERENCE_SCORE_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class LoginAsFlinker implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKER;
	constructor(public payload: { flinkerId: string }) {}
}
export class LoginAsFlinkerSuccess implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKER_SUCCESS;
	constructor(public payload: { result: SignInResponse }) {}
}
export class LoginAsFlinkerError implements Action {
	readonly type = ActionTypes.LOGIN_AS_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class FetchFlinkerDocument implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_DOCUMENTS;
	constructor(public payload: { documentId: string; documentType: FlinkerDocumentType }) {}
}
export class FetchFlinkerDocumentSuccess implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_DOCUMENTS_SUCCESS;
	constructor(public payload: { result: string }) {}
}
export class FetchFlinkerDocumentError implements Action {
	readonly type = ActionTypes.FETCH_FLINKER_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteFlinker implements Action {
	readonly type = ActionTypes.DELETE_FLINKER;
	constructor(public payload: { flinkerId: string }) {}
}
export class DeleteFlinkerSuccess implements Action {
	readonly type = ActionTypes.DELETE_FLINKER_SUCCESS;
	constructor(public payload: { result: string }) {}
}
export class DeleteFlinkerError implements Action {
	readonly type = ActionTypes.DELETE_FLINKER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DownloadFlinkerCv implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV;
	constructor(public payload: { flinkerId: string }) {}
}
export class DownloadFlinkerCvSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_SUCCESS;
	constructor(public payload: { result: any }) {}
}
export class DownloadFlinkerCvError implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}
export class DownloadFlinkerCvClearState implements Action {
	readonly type = ActionTypes.DOWNLOAD_FLINKER_CV_CLEAR_STATE;
	constructor() {}
}

export class DownloadAdminReport implements Action {
	readonly type = ActionTypes.DOWNLOAD_ADMIN_REPORT;
	constructor(public payload: { searchId: string }) {}
}

export class DownloadAdminReportSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_ADMIN_REPORT_SUCCESS;
	constructor(public payload: { result: any }) {}
}
export class DownloadAdminReportError implements Action {
	readonly type = ActionTypes.DOWNLOAD_ADMIN_REPORT_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DownloadAdminReportClearState implements Action {
	readonly type = ActionTypes.DOWNLOAD_ADMIN_REPORT_CLEAR_STATE;
	constructor() {}
}

export class DownloadObfuscatedFlinkerCv implements Action {
	readonly type = ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV;
	constructor(public payload: { flinkerId: string }) {}
}
export class DownloadObfuscatedFlinkerCvSuccess implements Action {
	readonly type = ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_SUCCESS;
	constructor(public payload: { result: any }) {}
}
export class DownloadObfuscatedFlinkerCvError implements Action {
	readonly type = ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}
export class DownloadObfuscatedFlinkerCvClearState implements Action {
	readonly type = ActionTypes.DOWNLOAD_OBFUSCATED_FLINKER_CV_CLEAR_STATE;
	constructor() {}
}

export type Actions =
	| FetchAllFlinkers
	| FetchAllFlinkersSuccess
	| FetchAllFlinkersError
	| UpdateFlinker
	| UpdateFlinkerSuccess
	| UpdateFlinkerError
	| FetchAllReactivationRequests
	| FetchAllReactivationRequestsSuccess
	| FetchAllReactivationRequestsError
	| UpdateReactivationRequestStatusSuccess
	| UpdateReactivationRequestStatusError
	| UpdateReactivationRequestStatus
	| GetDetailedFlinker
	| GetDetailedFlinkerSuccess
	| GetDetailedFlinkerError
	| FetchFlinkerReferenceScore
	| FetchFlinkerReferenceScoreSuccess
	| FetchFlinkerReferenceScoreError
	| LoginAsFlinker
	| LoginAsFlinkerSuccess
	| LoginAsFlinkerError
	| FetchFlinkerDocument
	| FetchFlinkerDocumentSuccess
	| FetchFlinkerDocumentError
	| DeleteFlinker
	| DeleteFlinkerSuccess
	| DeleteFlinkerError
	| DownloadFlinkerCv
	| DownloadFlinkerCvSuccess
	| DownloadFlinkerCvError
	| DownloadFlinkerCvClearState
	| DownloadAdminReport
	| DownloadAdminReportSuccess
	| DownloadAdminReportError
	| DownloadAdminReportClearState
	| DownloadObfuscatedFlinkerCv
	| DownloadObfuscatedFlinkerCvSuccess
	| DownloadObfuscatedFlinkerCvError
	| DownloadObfuscatedFlinkerCvClearState;
