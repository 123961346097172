import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-outlined-file-input',
	templateUrl: './outlined-file-input.component.html',
	styleUrls: ['./outlined-file-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OutlinedFileInputComponent),
			multi: true,
		},
	],
})
export class OutlinedFileInputComponent implements OnInit, ControlValueAccessor {
	@Input() errorMessage = '';
	@Input() placeholder = '';
	@Input() labelText = '';
	@Input() buttonInput = '';
	@Input() uploaded = false;
	@Input() formControlName = '';
	@Input() validExtensions = '.pdf,.doc,.docx,.jpg,.jpeg,.png';
	@Input() toolTip = '';
	@Output() fileSelected = new EventEmitter();
	@Output() filesSelected = new EventEmitter();
	@Input() showTip = false;

	required = false;
	localType = '';
	value = '';
	file: File;
	onChange: any = () => {};
	onTouched: any = () => {};
	disabled = false;

	constructor(private controlContainer: ControlContainer) {}

	ngOnInit(): void {
		const validator = this.controlContainer?.control?.get(this.formControlName)?.validator
			? this.controlContainer?.control?.get(this.formControlName)?.validator({} as AbstractControl)
			: null;

		if (validator && validator.required) {
			this.required = true;
		}
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	input(event: any): void {
		this.onChange(event.target.value);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onBlur(): void {
		this.onTouched();
	}

	onFileSelected(event: any): void {
		this.file = event?.target?.files[0];
		this.fileSelected.emit(this.file);
	}

	onFilesSelected(event: any): void {
		this.file = event?.target?.files;
		this.filesSelected.emit(this.file);
	}

	getTheme(): string {
		return this.file || this.uploaded ? 'text-center w-100 m2' : 'text-center w-100 m1';
	}
}
