import { Actions, ActionTypes } from './actions';
import { ReferenceQuestionsState, initialState } from './state';

export function ReferenceQuestionsReducer(state = initialState, action: Actions): ReferenceQuestionsState {
	switch (action.type) {
		case ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS:
			return {
				...state,
				fetchAllReferenceQuestionsHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS_SUCCESS:
			return {
				...state,
				fetchAllReferenceQuestionsHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.FETCH_ALL_REFERENCE_QUESTIONS_ERROR:
			return {
				...state,
				fetchAllReferenceQuestionsHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.UPDATE_REFERENCE_QUESTION:
			return {
				...state,
				updateReferenceQuestionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.UPDATE_REFERENCE_QUESTION_SUCCESS:
			return {
				...state,
				updateReferenceQuestionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};

		case ActionTypes.UPDATE_REFERENCE_QUESTION_ERROR:
			return {
				...state,
				updateReferenceQuestionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.CREATE_REFERENCE_QUESTION:
			return {
				...state,
				createReferenceQuestionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.CREATE_REFERENCE_QUESTION_SUCCESS:
			return {
				...state,
				createReferenceQuestionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};

		case ActionTypes.CREATE_REFERENCE_QUESTION_ERROR:
			return {
				...state,
				createReferenceQuestionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		case ActionTypes.DELETE_REFERENCE_QUESTION:
			return {
				...state,
				deleteReferenceQuestionHttpPackage: {
					error: null,
					loading: true,
					result: null,
				},
			};
		case ActionTypes.DELETE_REFERENCE_QUESTION_SUCCESS:
			return {
				...state,
				deleteReferenceQuestionHttpPackage: {
					error: null,
					loading: false,
					result: action.payload.result,
				},
			};
		case ActionTypes.DELETE_REFERENCE_QUESTION_ERROR:
			return {
				...state,
				deleteReferenceQuestionHttpPackage: {
					error: action.payload.errorResponse,
					loading: false,
					result: null,
				},
			};
		default: {
			return state;
		}
	}
}
