import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { getOfferStatusColour, OfferStatus } from 'projects/flink-app/src/app/enums/offer-status.enum';
import OfferResponse from 'projects/flink-app/src/app/models/dto/responses/offer-response.dto';
import {
	getSelectOptionName,
	getSelectOptionNameById,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { OfferStoreActions, OfferStoreSelectors } from 'projects/flink-app/src/app/root-store/offer-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { getErrorMessage } from 'projects/flink-app/src/app/utils/form.util';
import { Subscription } from 'rxjs';
import { AcceptOfferDialogComponent } from '../../dialogs/accept-offer-dialog/accept-offer-dialog.component';
import { RejectOfferDialogComponent } from '../../dialogs/reject-offer-dialog/reject-offer-dialog.component';
import { DictionaryWords } from '../../../../enums/dictionary-words.enum';
import { DownloadOfferDocuments } from '@root-store/offer-store/actions';

@Component({
	selector: 'app-flinker-offer-detailed',
	templateUrl: './flinker-offer-detailed.component.html',
	styleUrls: ['./flinker-offer-detailed.component.scss'],
})
export class FlinkerOfferDetailedComponent implements OnInit, OnDestroy {
	subscriptions = new Subscription();
	getFlinkerOfferHttpPackage$ = this.store.select(OfferStoreSelectors.selectGetFlinkerOfferHttpPackage);
	updateOfferHttpPackage$ = this.store.select(OfferStoreSelectors.selectUpdateOfferHttpPackage);
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	offerId: string;
	offer: OfferResponse;
	getOfferStatusColour = getOfferStatusColour;
	getSelectOptionName = getSelectOptionName;
	getSelectOptionNameById = getSelectOptionNameById;
	getErrorMessage = getErrorMessage;
	dictionaryWords = DictionaryWords;

	constructor(private store: Store<RootStoreState.State>, private route: ActivatedRoute, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.route.paramMap.subscribe((paramMap: ParamMap) => {
				this.offerId = paramMap.get('offerId');
				this.store.dispatch(new OfferStoreActions.GetFlinkerOffer({ offerId: this.offerId }));
			}),
		);

		this.subscriptions.add(
			this.getFlinkerOfferHttpPackage$.subscribe((getFlinkerOfferHttpPackage) => {
				if (getFlinkerOfferHttpPackage.result !== null) {
					this.offer = getFlinkerOfferHttpPackage.result;
				}
			}),
		);
	}

	allowStatusUpdate(): boolean {
		return this.offer?.offerStatus === OfferStatus.Pending;
	}

	rejectOffer(): void {
		this.dialog.open(RejectOfferDialogComponent, { data: { offerId: this.offer.id } });
	}

	acceptOffer(): void {
		this.dialog.open(AcceptOfferDialogComponent, { data: { offerId: this.offer.id }, width: '30%' });
	}
	downloadOffer(): void {
		this.store.dispatch(new DownloadOfferDocuments({ offerId: this.offerId }));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
