import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CookiesDetailedComponent } from './cookies-detailed/cookies-detailed.component';
import { DisclosureConcentFormComponent } from './disclosure-concent-form/disclosure-concent-form.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServiceLevelAgreementComponent } from './service-level-agreement/service-level-agreement.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

const routes: Routes = [
	{
		path: 'cookies',
		component: CookiesDetailedComponent,
	},
	{
		path: 'terms-of-use',
		component: TermsOfUseComponent,
	},
	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent,
	},
	{
		path: 'disclosure-consent',
		component: DisclosureConcentFormComponent,
	},
	{
		path: 'service-level-agreement',
		component: ServiceLevelAgreementComponent,
	},
	{
		path: 'terms-and-conditions',
		component: TermsAndConditionsComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LegalRoutingModule {}
