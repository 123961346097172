<div class="row mx-0 mt-0 mb-4">
	<div class="{{additionalClass}} toggle-container px-0 col-12 d-flex">
		<div
			class="toggle-button float-left"
			*ngFor="let option of options; let i = index"
			[class.active]="selectedOption === i"
			(click)="onSelect(i)"
		>
			{{ option.label }}
		</div>
	</div>
</div>
