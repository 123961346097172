import { Action } from '@ngrx/store';
import { FlinkCoCompanyInformationResponse as FlinkCoCompanyInformationResponse } from '@models/dto/responses/flinkco-company-information-response.dto';
import ErrorResponse from '@models/dto/responses/error-response.dto';
import { FlinkCoSubscriptionRequest } from '@models/dto/requests/flink-co-subscription-request';
import { FlinkCoSubscriptionResponse } from '@models/dto/responses/flink-co-subscription-response';
import FlinkCoResponse from '@models/dto/responses/flink-co-response.dto';
import FlinkCoTermsAndConditionsRequest from '@models/dto/requests/flink-co-terms-and-conditions-request.dto';
import { RetainerInformationRequest } from '@models/dto/requests/retainer-information-request.dto';
import { RetainerInformationResponse } from '@models/dto/responses/retainer-information-response.dto';
import { AdHocInformationRequest } from '@models/dto/requests/ad-hoc-information-request.dto';
import { AdHocInformationResponse } from '@models/dto/responses/ad-hoc-information-response.dto';
import FlinkCoUser from 'projects/flink-app/src/app/models/dto/flink-co-user.dto';
import { FlinkCoCompanyInformationRequest } from '@models/dto/requests/flink-co-company-information-request.dto';
import RetainerSubscriptionResponse from '@models/dto/responses/retainer-subscription-response.dto';
import { DeleteFlinkCoUserResponseDto } from '@models/dto/responses/delete-flink-co-user-response.dto';

export enum ActionTypes {
	SUBMIT_FLINKCO_COMPANY_INFORMATION = '[FlinkCoTakeOn] Submit FlinkCo company information',
	SUBMIT_FLINKCO_COMPANY_INFORMATION_SUCCESS = '[FlinkCoTakeOn] Submit FlinkCo company information success',
	SUBMIT_FLINKCO_COMPANY_INFORMATION_ERROR = '[FlinkCoTakeOn] Submit FlinkCo company information error',

	UPLOAD_COMPANY_LOGO = '[FlinkCoTakeOn] Upload company logo',
	UPLOAD_COMPANY_LOGO_SUCCESS = '[FlinkCoTakeOn] Upload company logo success',
	UPLOAD_COMPANY_LOGO_ERROR = '[FlinkCoTakeOn] Upload company logo error',

	UPLOAD_CIPC_COMPANY_DOCUMENTS = '[FlinkCoTakeOn] Upload cipc company documents',
	UPLOAD_CIPC_COMPANY_DOCUMENTS_SUCCESS = '[FlinkCoTakeOn] Upload cipc company documents success',
	UPLOAD_CIPC_COMPANY_DOCUMENTS_ERROR = '[FlinkCoTakeOn] Upload cipc company documents error',

	SUBMIT_FLINKCO_SUBSCRIPTION = '[FlinkCoTakeOn] Submit FlinkCo subscription',
	SUBMIT_FLINKCO_SUBSCRIPTION_SUCCESS = '[FlinkCoTakeOn] Submit FlinkCo subscription success',
	SUBMIT_FLINKCO_SUBSCRIPTION_ERROR = '[FlinkCoTakeOn] Submit FlinkCo subscription error',

	SUBMIT_FLINKCO_RETAINER_INFORMATION = '[FlinkCoTakeOn] Submit FlinkCo retainer information',
	SUBMIT_FLINKCO_RETAINER_INFORMATION_SUCCESS = '[FlinkCoTakeOn] Submit FlinkCo retainer information success',
	SUBMIT_FLINKCO_RETAINER_INFORMATION_ERROR = '[FlinkCoTakeOn] Submit FlinkCo retainer information error',

	SUBMIT_FLINKCO_ADHOC_INFORMATION = '[FlinkCoTakeOn] Submit FlinkCo adhoc information',
	SUBMIT_FLINKCO_ADHOC_INFORMATION_SUCCESS = '[FlinkCoTakeOn] Submit FlinkCo adhoc information success',
	SUBMIT_FLINKCO_ADHOC_INFORMATION_ERROR = '[FlinkCoTakeOn] Submit FlinkCo adhoc information error',

	DELETE_FLINKCO_USER = '[FlinkCoTakeOn] Delete FlinkCo user ',
	DELETE_FLINKCO_USER_SUCCESS = '[FlinkCoTakeOn] Delete FlinkCo user success',
	DELETE_FLINKCO_USER_ERROR = '[FlinkCoTakeOn] Delete FlinkCo user error',

	GET_FLINKCO = '[FlinkCoTakeOn] Get FlinkCo',
	GET_FLINKCO_SUCCESS = '[FlinkCoTakeOn] Get FlinkCo success',
	GET_FLINKCO_ERROR = '[FlinkCoTakeOn] Get FlinkCo error',

	SUBMIT_FLINKCO_TERMS_AND_CONDITIONS = '[FlinkCoTakeOn] Submit FlinkCo terms and conditions',
	SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_SUCCESS = '[FlinkCoTakeOn] Submit FlinkCo terms and conditions success',
	SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_ERROR = '[FlinkCoTakeOn] Submit FlinkCo terms and conditions error',

	ADD_FLINKCO_USERS = '[FlinkCoTakeOn] Add Flink Co User',
	ADD_FLINKCO_USER_SUCCESS = '[FlinkCoTakeOn] Add FlinkCo User success',
	ADD_FLINKCO_USER_ERROR = '[FlinkCoTakeOn] Add FlinkCo User error',

	GET_RETAINER_SUBSCRIPTION = '[FlinkCoTakeOn] Get Retainer Subscription',
	GET_RETAINER_SUBSCRIPTION_SUCCESS = '[FlinkCoTakeOn] Get Retainer Subscription success',
	GET_RETAINER_SUBSCRIPTION_ERROR = '[FlinkCoTakeOn] Get Retainer Subscription error',

	GET_FLINKCO_WITH_SELF = '[FlinkCoTakeOn] Get FlinkCo with self',
	GET_FLINKCO_WITH_SELF_SUCCESS = '[FlinkCoTakeOn] Get FlinkCo with self success',
	GET_FLINKCO_WITH_SELF_ERROR = '[FlinkCoTakeOn] Get FlinkCo with self error',
}
export class SubmitFlinkCoCompanyInformation implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION;
	constructor(public payload: { flinkCoCompanyInformationRequest: FlinkCoCompanyInformationRequest }) {}
}

export class SubmitFlinkCoCompanyInformationSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION_SUCCESS;
	constructor(public payload: { result: FlinkCoCompanyInformationResponse }) {}
}

export class SubmitFlinkCoCompanyInformationError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_COMPANY_INFORMATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadCompanyLogo implements Action {
	readonly type = ActionTypes.UPLOAD_COMPANY_LOGO;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadCompanyLogoSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_COMPANY_LOGO_SUCCESS;
	constructor(public payload: { result: string }) {}
}

export class UploadCompanyLogoError implements Action {
	readonly type = ActionTypes.UPLOAD_COMPANY_LOGO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class UploadCIPCCompanyDocuments implements Action {
	readonly type = ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS;
	constructor(public payload: { formData: FormData }) {}
}

export class UploadCIPCCompanyDocumentsSuccess implements Action {
	readonly type = ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS_SUCCESS;
	constructor() {}
}

export class UploadCIPCCompanyDocumentError implements Action {
	readonly type = ActionTypes.UPLOAD_CIPC_COMPANY_DOCUMENTS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkCoSubscription implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION;
	constructor(public payload: { flinkCoSubscriptionRequest: FlinkCoSubscriptionRequest }) {}
}

export class SubmitFlinkCoSubscriptionSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: FlinkCoSubscriptionResponse }) {}
}

export class SubmitFlinkCoSubscriptionError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkCoRetainerInformation implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION;
	constructor(public payload: { retainerInformationRequest: RetainerInformationRequest }) {}
}

export class SubmitFlinkCoRetainerInformationSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION_SUCCESS;
	constructor(public payload: { result: RetainerInformationResponse }) {}
}

export class SubmitFlinkCoRetainerInformationError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_RETAINER_INFORMATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkCoAdHocInformation implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION;
	constructor(public payload: { adHocInformation: AdHocInformationRequest }) {}
}

export class SubmitFlinkCoAdHocInformationSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION_SUCCESS;
	constructor(public payload: { result: AdHocInformationResponse }) {}
}

export class SubmitFlinkCoAdHocInformationError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_ADHOC_INFORMATION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class DeleteFlinkCoUser implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO_USER;
	constructor(public payload: { userId: string }) {}
}

export class DeleteFlinkCoUserSuccess implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO_USER_SUCCESS;
	constructor(public payload: { result: DeleteFlinkCoUserResponseDto }) {}
}

export class DeleteFlinkCoUserError implements Action {
	readonly type = ActionTypes.DELETE_FLINKCO_USER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCo implements Action {
	readonly type = ActionTypes.GET_FLINKCO;
	constructor() {}
}

export class GetFlinkCoSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_SUCCESS;
	constructor(public payload: { result: FlinkCoResponse }) {}
}

export class GetFlinkCoError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class SubmitFlinkCoTermsAndConditions implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS;
	constructor(public payload: { flinkCoTermsAndConditionsRequest: FlinkCoTermsAndConditionsRequest }) {}
}

export class SubmitFlinkCoTermsAndConditionsSuccess implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_SUCCESS;
	constructor() {}
}

export class SubmitFlinkCoTermsAndConditionsError implements Action {
	readonly type = ActionTypes.SUBMIT_FLINKCO_TERMS_AND_CONDITIONS_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class AddFlinkCoUsers implements Action {
	readonly type = ActionTypes.ADD_FLINKCO_USERS;
	constructor(public payload: { flinkCoUsers: FlinkCoUser[] }) {}
}

export class AddRetainerUsersSuccess implements Action {
	readonly type = ActionTypes.ADD_FLINKCO_USER_SUCCESS;
	constructor(public payload: { result: FlinkCoUser[] }) {}
}

export class AddRetainerUsersError implements Action {
	readonly type = ActionTypes.ADD_FLINKCO_USER_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetRetainerSubscription implements Action {
	readonly type = ActionTypes.GET_RETAINER_SUBSCRIPTION;
	constructor() {}
}

export class GetRetainerSubscriptionSuccess implements Action {
	readonly type = ActionTypes.GET_RETAINER_SUBSCRIPTION_SUCCESS;
	constructor(public payload: { result: RetainerSubscriptionResponse }) {}
}

export class GetRetainerSubscriptionError implements Action {
	readonly type = ActionTypes.GET_RETAINER_SUBSCRIPTION_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export class GetFlinkCoWithSelf implements Action {
	readonly type = ActionTypes.GET_FLINKCO_WITH_SELF;
	constructor() {}
}

export class GetFlinkCoWithSelfSuccess implements Action {
	readonly type = ActionTypes.GET_FLINKCO_WITH_SELF_SUCCESS;
	constructor(public payload: { result: FlinkCoResponse }) {}
}

export class GetFlinkCoWithSelfError implements Action {
	readonly type = ActionTypes.GET_FLINKCO_WITH_SELF_ERROR;
	constructor(public payload: { errorResponse: ErrorResponse }) {}
}

export type Actions =
	| SubmitFlinkCoCompanyInformation
	| SubmitFlinkCoCompanyInformationSuccess
	| SubmitFlinkCoCompanyInformationError
	| UploadCIPCCompanyDocuments
	| UploadCIPCCompanyDocumentsSuccess
	| UploadCIPCCompanyDocumentError
	| UploadCompanyLogo
	| UploadCompanyLogoSuccess
	| UploadCompanyLogoError
	| SubmitFlinkCoSubscription
	| SubmitFlinkCoSubscriptionSuccess
	| SubmitFlinkCoSubscriptionError
	| SubmitFlinkCoRetainerInformation
	| SubmitFlinkCoRetainerInformationSuccess
	| SubmitFlinkCoRetainerInformationError
	| SubmitFlinkCoAdHocInformation
	| SubmitFlinkCoAdHocInformationSuccess
	| SubmitFlinkCoAdHocInformationError
	| DeleteFlinkCoUser
	| DeleteFlinkCoUserSuccess
	| DeleteFlinkCoUserError
	| GetFlinkCo
	| GetFlinkCoSuccess
	| GetFlinkCoError
	| SubmitFlinkCoTermsAndConditions
	| SubmitFlinkCoTermsAndConditionsSuccess
	| SubmitFlinkCoTermsAndConditionsError
	| AddFlinkCoUsers
	| AddRetainerUsersSuccess
	| AddRetainerUsersError
	| GetRetainerSubscriptionError
	| GetRetainerSubscription
	| GetRetainerSubscriptionSuccess
	| GetFlinkCoWithSelf
	| GetFlinkCoWithSelfSuccess
	| GetFlinkCoWithSelfError;
