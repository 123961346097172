import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { getInterviewStatusColour, InterviewStatus } from 'projects/flink-app/src/app/enums/interview-status.enum';
import InterviewResponse from 'projects/flink-app/src/app/models/dto/responses/interview-response.dto';
import {
	getSelectOptionById,
	getSelectOptionName,
	getSelectOptionNameById,
	SelectOptionsResponse,
} from 'projects/flink-app/src/app/models/dto/responses/select-options-response.dto';
import { RootStoreState } from 'projects/flink-app/src/app/root-store';
import { InterviewStoreSelectors } from 'projects/flink-app/src/app/root-store/interview-store';
import { SelectOptionsStoreSelectors } from 'projects/flink-app/src/app/root-store/select-options-store';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';

@Component({
	selector: 'app-flinker-past-interviews',
	templateUrl: './past-interviews.component.html',
	styleUrls: ['./past-interviews.component.scss'],
})
export class PastInterviewsComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort) set matSort(sort: MatSort) {
		if (!this.historyDataSource.sort) {
			this.historyDataSource.sortingDataAccessor = (item, property) => {
				switch (property) {
					case 'company':
						return item.flinkCo.registeredName;
					case 'date':
						return item.startDate;
					case 'time':
						return item.startDate ? moment(item.startDate).toISOString() : '';
					default:
						return Object.prototype.hasOwnProperty.call(item, property) ? (item as Record<string, any>)[property] : '';
				}
			};
			this.historyDataSource.sort = sort;
		}
	}

	subscriptions = new Subscription();
	historyDisplayedColumns: string[] = ['company', 'date', 'time', 'location', 'status'];
	getFlinkerInterviewsHttpPackage$ = this.store.select(InterviewStoreSelectors.selectGetFlinkerInterviewsHttpPackage);
	flinkerInterviews: InterviewResponse[];
	selectOptionsHttpPackage$ = this.store.select(SelectOptionsStoreSelectors.selectSelectOptionsHttpPackage);
	selectOptions: SelectOptionsResponse;
	historyDataSource = new MatTableDataSource<InterviewResponse>();
	InterviewStatus = InterviewStatus;
	getSelectOptionName = getSelectOptionName;
	getSelectOptionNameById = getSelectOptionNameById;
	getInterviewStatusColour = getInterviewStatusColour;
	getSelectOptionById = getSelectOptionById;

	constructor(private store: Store<RootStoreState.State>, public dialog: MatDialog) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.getFlinkerInterviewsHttpPackage$.subscribe((getFlinkerInterviewsHttpPackage) => {
				if (getFlinkerInterviewsHttpPackage.result !== null) {
					this.flinkerInterviews = getFlinkerInterviewsHttpPackage.result;
					this.historyDataSource = new MatTableDataSource<InterviewResponse>(
						getFlinkerInterviewsHttpPackage.result.filter(
							(interview) =>
								moment(interview.endDate).isBefore(moment()) ||
								interview.status === InterviewStatus.Rejected ||
								interview.status === InterviewStatus.Cancelled ||
								interview.status === InterviewStatus.Expired,
						),
					);
				}
			}),
		);

		this.subscriptions.add(
			this.selectOptionsHttpPackage$.subscribe((selectOptionsHttpPackage) => {
				if (selectOptionsHttpPackage.result !== null) {
					this.selectOptions = selectOptionsHttpPackage.result;
				}
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
