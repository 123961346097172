<div class="sign-in-container d-flex">
	<div class="sign-in-left">
		<img class="logo" src="assets/img/flink-logo-blue-02.svg" alt="Flink" />
		<img class="sign-in-image ml-4" src="assets/img/flinker-login-hero-v1-01.png" alt="Flink background" />
	</div>
	<div class="sign-in-right">
		<div class="text-center m-auto sign-in-section">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
