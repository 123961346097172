import { trigger, state, style, transition, animate, stagger, query } from '@angular/animations';

export const slideIn = trigger('slideIn', [
	transition(':enter', [
		style({
			visibility: 'hidden',
		}),
		animate(
			'100ms',
			style({
				visibility: 'visible',
				display: 'flex',
			}),
		),
	]),
	transition(':leave', [
		style({
			visibility: 'visible',
			display: 'flex',
		}),
		animate(
			'300ms',
			style({
				visibility: 'hidden',
			}),
		),
	]),
]);

export const slideInLeft = trigger('slideInLeft', [
	transition(':enter', [
		style({
			transform: 'translateX(-100%)',
		}),
		animate(
			'1s',
			style({
				transform: 'translateX(0)',
				display: 'flex',
			}),
		),
	]),
	transition(':leave', [
		style({
			transform: 'translateX(0)',
			display: 'flex',
		}),
		animate(
			'1s',
			style({
				transform: 'translateX(-100%)',
			}),
		),
	]),
]);

export const slideInRight = trigger('slideInRight', [
	transition(':enter', [
		style({
			transform: 'translateX(100%)',
		}),
		animate(
			'1s',
			style({
				transform: 'translateX(0)',
				display: 'flex',
			}),
		),
	]),
	transition(':leave', [
		style({
			transform: 'translateX(0)',
			display: 'flex',
		}),
		animate(
			'1s',
			style({
				transform: 'translateX(100%)',
			}),
		),
	]),
]);

export const listAnimation = trigger('listAnimation', [
	transition('* => *', [
		// each time the binding value changes
		query(':enter', [style({ opacity: 0 }), stagger(50, [animate('0.2s', style({ opacity: 1 }))])], { optional: true }),
	]),
]);
