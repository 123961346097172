<div class="autocomplete-label d-flex p-0 justify-content-between w-100">
  <div class="d-flex">
    <label [ngClass]="{ required: required }">{{ labelText }}</label>
    <app-tooltip class="tooltip-in-label" *ngIf="showTip && labelText" [tooltip]="toolTip"></app-tooltip>
  </div>
  <a class="font-size-12" *ngIf="allowOther" (click)="onOther()">{{ otherOptionQuestion }}</a>

</div>
<div class="select-container">
	<input
		type="text"
		class="ghost-input"
		(input)="clearOnEmptyString()"
		[formControl]="myControl"
		[matAutocomplete]="auto"
		autocomplete="none"
		placeholder="Search options"
	/>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="input($event)">
		<mat-option *ngFor="let option of filteredOptions | async" [value]="option.displayName">
			{{ option.displayName }}
		</mat-option>
	</mat-autocomplete>
	<mat-icon class="clear front-sub-9" *ngIf="value" (click)="clearValue()">cancel</mat-icon>
</div>
<app-input-error-message class="autocomplete-error" *ngIf="errorMessage.length > 0" [errorMessage]="errorMessage">
</app-input-error-message>
<mat-hint class="pb-2" *ngIf="hint.length > 0">{{ hint }}</mat-hint>
