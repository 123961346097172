<div class="container mw-100 min-vh-100 p-4 p-md-5">
	<mat-card class="p-0 pb-4">
		<div
			class="row mx-0 border-bend d-flex flex-column flex-md-row justify-content-center justify-content-xl-between back-sub-12"
		>
			<div class="col-12 col-xl-6 pb-0 pb-md-4 pl-4 pr-4 pt-0 w-100 d-flex flex-column flex-md-row flex-xl-column">
				<div class="d-flex justify-content-center align-content-center justify-content-lg-start align-content-lg-start">
					<div class="pointer" routerLink="../../">
						<span class="font-size-11 font-bold front-main-1 center-icon">
							<mat-icon class="front-main-1 center-icon mr-2 font-size-4">chevron_left</mat-icon>
							<span class="d-md-none d-lg-block">Back to Results</span>
						</span>
					</div>
				</div>

				<div class="d-flex flex-column align-items-center align-items-md-start flex-md-row mt-4">
					<div class="border-div d-flex justify-content-center p-1 mr-3">
						<ngx-avatar
							*ngIf="!jobAdvert?.companyLogoUrl"
							class="flink-image mx-auto"
							[src]="jobAdvert?.defaultSystemCompanyLogoUrl"
							name="Company Logo"
							size="100"
							[round]="true"
							[cornerRadius]="50"
						></ngx-avatar>
						<div class="company-logo-container" *ngIf="jobAdvert?.companyLogoUrl">
							<img class="company-logo-image" [src]="jobAdvert?.companyLogoUrl" alt="Company Logo" />
						</div>
					</div>
					<div class="d-flex flex-column mt-3 align-items-center align-items-md-start justify-content-center">
						<p class="front-fade-4 industry-heading font-bold font-size-4">
							{{ jobAdvert?.search?.industry }}
						</p>
						<p class="front-fade-4 font-medium font-size-11">
							{{ jobAdvert?.search?.jobTitle | uppercase }}
						</p>
						<p class="font-medium font-size-11">{{ jobAdvert?.search?.dutiesDescription }}</p>
					</div>
				</div>
			</div>

			<div
				class="col-12 col-xl-6 d-flex align-self-center align-items-center align-items-md-start flex-column pt-0 pb-5 p-md-5 justify-content-center job-details"
			>
				<span class="font-size-11 font-normal center-icon" *ngIf="jobAdvert?.isGeneralAdvert">
					<mat-icon class="front-main-1 pt-1 font-size-8">place_outlined</mat-icon>
					{{ jobAdvert?.flinkCoCity + ', ' + jobAdvert?.flinkCoProvince }}
				</span>

				<span class="font-size-11 font-normal center-icon">
					<mat-icon class="front-main-1 pt-1 font-size-8">schedule_outlined</mat-icon>
					Deadline:
					<ng-container *ngIf="jobAdvert?.applicationDeadline">{{
						jobAdvert?.applicationDeadline | date: 'dd MMM, YYYY'
					}}</ng-container>
					<ng-container *ngIf="!jobAdvert?.applicationDeadline">No deadline</ng-container>
				</span>

				<span class="font-size-11 font-normal center-icon">
					<mat-icon class="front-main-1 pt-1 font-size-8">workspace_premium_outlined</mat-icon>
					<ng-container *ngIf="jobAdvert?.search?.highestQualificationCategory.length > 0">{{
            jobAdvert?.search?.highestQualificationCategory[0]
            }} required</ng-container>
          <ng-container *ngIf="!jobAdvert?.search?.highestQualificationCategory || jobAdvert?.search?.highestQualificationCategory?.length === 0">
            <ng-container *ngIf="jobAdvert?.search?.matricCertificateRequired">Matric Certificate</ng-container>
            <ng-container *ngIf="!jobAdvert?.search?.matricCertificateRequired">No qualification needed</ng-container>
          </ng-container>
				</span>

				<div class="d-flex flex-column flex-md-row flex-wrap">
					<div class="mt-2 mt-md-3 mb-2 mb-md-0 mr-md-2">
						<app-square-button value="Apply" (click)="onApplication()" theme="m2 bold border2"></app-square-button>
					</div>
				</div>
			</div>
		</div>

		<div class="row row-container m-0">
			<div class="col-12 col-xl-6 description-container d-flex mt-5 flex-column">
				<div class="border-underline">
					<p class="front-fade-4 font-bold mt-2 font-size-4">Job Description</p>
					<p class="font-medium font-size-11 mb-5 py-3 whitespace-break-spaces">{{ jobAdvert?.jobDescription }}</p>
				</div>

				<div class="border-underline" *ngIf="!jobAdvert?.isGeneralAdvert">
					<p class="front-fade-4 line-height font-bold mt-5 font-size-4">Roles and Responsibility</p>
					<p class="font-medium font-size-11 mb-5 py-3 whitespace-break-spaces">
						{{ jobAdvert?.rolesAndResponsibilities }}
					</p>
				</div>
			</div>
			<div class="col-12 col-xl-6">
				<mat-card class="p-md-5 border-bottom-radius">
					<p class="front-fade-4 line-height mt-5 font-bold font-size-4">Employment Details</p>
					<table class="w-100 details-table">
						<tr *ngIf="!jobAdvert?.isGeneralAdvert">
							<td class="font-size-11 text-left font-demi-bold front-fade-4">Employment type</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.placement }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4">Industry</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.industry ?? 'N/A' }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4">Work space preference</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.workspacePreference }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4" *ngIf="!jobAdvert?.isGeneralAdvert">Ideal work province</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.locations[0]?.cities[0] ?? jobAdvert?.flinkCoCity }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4" *ngIf="!jobAdvert?.isGeneralAdvert">Ideal work city</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.locations[0]?.province ?? jobAdvert?.flinkCoProvince }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4" *ngIf="!jobAdvert?.isGeneralAdvert">Salary Bracket</td>
							<td class="font-normal font-size-11 text-right">
								R{{ jobAdvert?.search?.salaryBandLower }} - R{{ jobAdvert?.search?.salaryBandUpper }}
							</td>
						</tr>
						<tr>
							<td class="font-size-11 text-left font-demi-bold front-fade-4" *ngIf="!jobAdvert?.isGeneralAdvert">Drivers License</td>
							<td class="font-normal font-size-11 text-right">
								{{ jobAdvert?.search?.driversLicenseType }}
							</td>
						</tr>
					</table>
				</mat-card>
			</div>
		</div>
	</mat-card>
</div>
