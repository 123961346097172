<h2 mat-dialog-title class="text-center">Reject interview</h2>
<div mat-dialog-content>
	<form [formGroup]="form">
		<app-outlined-dropdown
			class="pt-2"
			labelText="Rejection reason"
			[options]="(selectOptionsHttpPackage$ | async)?.result?.interviewRejectionReasons"
			[errorMessage]="getErrorMessage(form.controls.rejectionReason, 'Rejection reason', shouldValidate)"
			formControlName="rejectionReason"
		>
		</app-outlined-dropdown>
		<app-outlined-input
			class="pt-2"
			labelText="Other rejection reason"
			formControlName="otherRejectionReason"
			*ngIf="form.controls.rejectionReason.value == 'OTH'"
			[maxLength]="constants.interview.maxOtherRejectionReasonLength"
			[errorMessage]="getErrorMessage(form.controls.otherRejectionReason, 'Other rejection reason', shouldValidate)"
		>
		</app-outlined-input>
	</form>
</div>

<div mat-dialog-actions class="d-flex justify-content-center mt-4 pb-3">
	<app-square-button theme="m5 ml-2" value="Cancel" type="button" (click)="cancel()"> </app-square-button>
	<app-square-button theme="m1" value="Confirm" type="button" (click)="submit()"> </app-square-button>
</div>
