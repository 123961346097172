import { Pipe, PipeTransform } from '@angular/core';
import { CvFeatureType } from '@models/dto/responses/cv-feature-response.dto';

@Pipe({
	name: 'cvFeatureTypeUserDescription',
})
export class CvFeatureTypeUserDescriptionPipe implements PipeTransform {
	transform(value: CvFeatureType): unknown {
		switch (value) {
			case CvFeatureType.STANDARD:
				return 'Standard CV, contains all your profile information provided.';
			case CvFeatureType.INTEGRITY:
				return 'Psychometric top three attributes with the Integrity International stamp.';
			case CvFeatureType.MIE:
				return 'MIE verification results.';
		}
	}
}
