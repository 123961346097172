import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptionResponse } from '@models/dto/responses/select-option-response.dto';
import { getSelectOptionName } from '@models/dto/responses/select-options-response.dto';

@Pipe({
	name: 'selectOptionNameByCode',
})
export class SelectOptionNameByCodePipe implements PipeTransform {
	transform(code: string, options: SelectOptionResponse[], defaultValue = ''): string {
		return getSelectOptionName(options, code) || defaultValue;
	}
}
