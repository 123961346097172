export enum OfferStatus {
	Accepted = 'Accepted',
	Pending = 'Pending',
	Rejected = 'Rejected',
	Cancelled = 'Cancelled',
	NegotiationRequested = 'Negotiation Requested',
}

export function getOfferStatusColour(status: OfferStatus): string {
	switch (status) {
		case OfferStatus.Accepted:
			return 'faded2';
		case OfferStatus.Pending:
		case OfferStatus.NegotiationRequested:
			return 'faded10';
		case OfferStatus.Rejected:
			return 'faded5';
		case OfferStatus.Cancelled:
			return 'faded5';
		default:
			return 'faded2';
	}
}
